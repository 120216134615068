import React from 'react';
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import ScrollUpButton from "react-scroll-up-button";

import { getBaseRouterPath } from "../utils/config";
import {
  getSuperBannerMatches
} from "../../bets/store/selectors/betData";
//import ElementHeader from "./elements/header";
//import ElementFooter from "./elements/footer";

import PrematchHome from "../views/prematch/Home";
import PrematchSport from "../views/prematch/Sport";
import PrematchCategory from "../views/prematch/Category";
import PrematchTournament from "../views/prematch/Tournament";
import PrematchFavorite from "../views/prematch/Favorite";
import PrematchMatch from "../views/prematch/Match";
import PrematchWinnerSpecial from "../views/prematch/WinnerSpecial";
import LeftNav from "../components/left-nav";
import BetSlipFull from "../components/bet-slip/BetsFull";
import VerifyTicket from "../components/verify-ticket";
import BetSlipFooter from "../components/betslip-footer";
import evBus from "../utils/evbus";

import MobileBanner from "./components/mobile-banner";

import PixiCarousel from "../../bets/components/PixiCarousel";
import FootballBanner from "../components/banner-football/FootballBanner";
import WebBannerFootball from "../../bets/assets/banners/banner-sports-web.jpg";
import MobileBannerFootball from "../../bets/assets/banners/banner-sports-mobile.jpg";

const toggledStyle = {
  right: 10,
  bottom: 60
};

const EntryPoint = (props) => {
  const { promotedMatch, location } = props;
  const basePath = getBaseRouterPath();

  const is1415px = useMediaQuery("(min-width: 1415px)");
  const is600px = useMediaQuery("(min-width: 600px)");
  const isDesktop = useMediaQuery('(min-width:1281px)');
  const isBannerWidth = useMediaQuery("(min-width: 600px)");

  const [showBanner, setShowBanner] = React.useState(false);
  React.useEffect(() => {
    if (location.pathname === `${basePath}/prematch`) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [location.pathname, basePath])

  const [state, setState] = React.useState({
    idSport: "",
    idCategory: "",
    idTournament: "",
    idMatch: "",
    show: false,
  });
  const handleEvent = (data, event) => {
    setState({
      idSport: data.idSport,
      idCategory: data.idCategory,
      idTournament: data.idTournament,
      idMatch: data.idMatch,
      show: true,
    });
  };
  const handleClose = () => {
    setState({
      idSport: "",
      idCategory: "",
      idTournament: "",
      idMatch: "",
      show: false,
    });
  };

  React.useEffect(() => {
    if (!is1415px) {
      setState({
        idSport: "",
        idCategory: "",
        idTournament: "",
        idMatch: "",
        show: false,
      });
    }
  }, [is1415px]);

  React.useEffect(() => {
    evBus.on("SHOW-MATCH-DETAILS", handleEvent);
    return () => {
      evBus.remove("SHOW-MATCH-DETAILS", handleEvent);
    }
  });

  return (
    <>
      <div className="n-page prematch mobile">
        {/*<ElementHeader />*/}
        <div className="content">
          {!is600px && window.config && window.config.betsEnabled === "1" && <MobileBanner />}
          <div className="page-header">
            <div id="page-header-filters" className="filters"></div>
            <VerifyTicket className="prematch" />
          </div>
          <div className="frame prematch">
            <LeftNav className="prematch" />
            <div className="content-wrap has-left-nav has-sidebar">
              {isBannerWidth && showBanner && <div className="banner">
                <PixiCarousel>
                  <FootballBanner
                    webImage={WebBannerFootball}
                    mobileImage={MobileBannerFootball}
                    blendColor={0x0f345a}
                    eventName="footballBannerBets"
                    match={promotedMatch}
                  />
                </PixiCarousel>
              </div>}
              <div className="flex">
                <div className="content-flex-one">
                  <Route>
                    <Switch>
                      <Route exact path={`${basePath}/prematch`} component={PrematchSport} />
                      <Route exact path={`${basePath}/prematch/sport/:idSport`} component={PrematchSport} />
                      <Route exact path={`${basePath}/prematch/category/:idSport/:idCategory`} component={PrematchCategory} />
                      <Route exact path={`${basePath}/prematch/tournament/:idSport/:idCategory/:idTournament`} component={PrematchTournament} />
                      <Route exact path={`${basePath}/prematch/match/:idSport/:idCategory/:idTournament/:idMatch`} component={PrematchMatch} />
                      <Route exact path={`${basePath}/prematch/favorite`} component={PrematchFavorite} />
                      <Route exact path={`${basePath}/prematch/winner-advantage`} component={PrematchWinnerSpecial} />
                      <Route exact path={`${basePath}/prematch/ultra-odds`} component={PrematchWinnerSpecial} />
                      <Route exact path={`${basePath}/prematch/winner-plus`} component={PrematchWinnerSpecial} />
                    </Switch>
                  </Route>
                </div>
                {is1415px && state.show && <div className="match-details">
                  <PrematchMatch
                    idSport={state.idSport}
                    idCategory={state.idCategory}
                    idTournament={state.idTournament}
                    idMatch={state.idMatch}
                    inPage={true}
                    modal={false}
                    onClose={handleClose} />
                </div>}
              </div>
            </div>
            <div className="sidebar" id="betslip-slider">
              <div>
                <BetSlipFull desktop={true} />
              </div>
            </div>
          </div>
        </div>
        <BetSlipFooter />
        {!is1415px && state.show &&
          <Dialog
            disableBackdropClick
            fullScreen={isDesktop ? false : true}
            disableEnforceFocus
            open={state.show}
            //TransitionComponent={Transition}
            maxWidth={!(isDesktop ? false : true) ? "lg" : false}
            className={`n-page prematch match-details-dialog ${isDesktop ? 'min-height' : ''}`}
            onClose={handleClose}
          >
            <PrematchMatch
              idSport={state.idSport}
              idCategory={state.idCategory}
              idTournament={state.idTournament}
              idMatch={state.idMatch}
              inPage={true}
              modal={true}
              onClose={handleClose} />
          </Dialog>
        }
      </div>
      <ScrollUpButton ToggledStyle={toggledStyle} />

    </>
  );
}

const mapStateToProps = state => {
  const pMatches = getSuperBannerMatches(state);

  return {
    promotedMatch: pMatches.length > 0 ? pMatches[0] : null,
  };
};

const actionCreators = {};

export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(EntryPoint)));