import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import Phone from '../../Register/Form/Phone/Phone';
import Email from '../../Register/Form/Email/Email';
import Password from "../Form/Password/Password";

class CreateNewAccount extends Component
{

    render() {
        return (
                <div>
                    <Phone inputValue={this.props.phone}/>
                    <Email inputValue={this.props.email}/>
                    <Password inputValue={this.props.password} />
                </div>
        );
    }
}

const mapStateToProps = state => {

    return {};//state.registerWizard.fields;
};

export default connect(mapStateToProps)(CreateNewAccount);
