import React from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import Translate from '../../utils/Translate';
import './NotFound.scss';

const NotFound = (props) => {

    /**
     * help poedit to scan and find the text
     */
    const t = (string) => {
        return string;
    }

    const homePageHandler = () => {
        document.location.href = "/";
    }

    return (
        <div className={"NotFoundScreen"}>
            <h3><Translate text={t("Ooops!")} /></h3>
            <h1><Translate text={t("404")} /></h1>
            <p><Translate text={t("Page not found.")} /></p>
            <button onClick={() => homePageHandler()}>
                <Translate text={t("Back to home page")} />
            </button>
        </div>
    );
};

export default withTranslation()(withRouter(NotFound));