import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as storeActions from "../../../store/actions";
import Utils from './../../../utils/Utils';
import Icons from "../../../assets/Icons/Icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import WalletPreview from './WalletPreview/WalletPreview';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import './WalletManagement.scss';

import { requestWallet as requestWinnerFunWallet } from "../../../winner-fun/store/actions/wallet";

const HOME = 'walletHome';
const SPORTSBOOK = 'walletSportsbook';
const CASINO = 'walletCasino';
const CASINO_LIVE = 'walletCasinoLive';
const VIRTUALS = 'walletVirtuals';
const LOTTO = 'walletLotto';
const WINNER_FUN = 'walletWinnerFun';

const CASINO_ID = 1;
const SPORTSBOOK_ID = 2;
const CASINO_LIVE_ID = 4;
const VIRTUALS_ID = 999; //To be defined
const LOTTO_ID = 3;
const WINNER_FUN_ID = 6;

const WalletManagement = (props) => {
    const { wallet, winnerFunWallet, history, /*getWallet,*/ getWinnerFunWallet, isDeposit } = props;

    const [isVisible, setIsVisible] = useState(false);
    const [currentWallet, setCurrentWallet] = useState(0);
    const [productType, setProductType] = useState(HOME);

    const getCurrentWallet = (id) => {
        if (window.config && window.config.winnerFunEnabled === "1" && id === WINNER_FUN_ID) {
            if (!winnerFunWallet.loadingWallet) {
                setCurrentWallet(winnerFunWallet ? winnerFunWallet.total : 0);
                return;
            }
        } else if (!wallet.loadingWallet) {
            if (wallet.walletManagement[id] && wallet.walletManagement[id].productAmount) {
                setCurrentWallet(wallet.walletManagement[id].productAmount);
                return;
            } else {
                setCurrentWallet(wallet.main);
                return;
            }
        }
        setCurrentWallet(0);
        return;
    }

    useEffect(() => {
        let path = '';
        let alreadySet = false;

        if (history.location.pathname) {
            path = history.location.pathname;

            if (path.search('pariuri') > 0) {
                setProductType(SPORTSBOOK);
                getCurrentWallet(SPORTSBOOK_ID);
                alreadySet = true;
            }

            if (path.search('cazino_live') > 0) {
                setProductType(CASINO_LIVE);
                getCurrentWallet(CASINO_LIVE_ID);
                alreadySet = true;
            } else if ((path.search('profile') > 0) || (path.search('promotii') > 0) || path === '/') {
                setProductType(HOME);
                setCurrentWallet(wallet.total);
                alreadySet = true;
            } else if ((path.search('cazino') > 0) || (path.search('casino') > 0) || (path.search('table-games') > 0)) {
                setProductType(CASINO);
                getCurrentWallet(CASINO_ID);
                alreadySet = true;
            }

            if (path.search('virtuale') > 0) {
                setProductType(VIRTUALS);
                getCurrentWallet(VIRTUALS_ID);
                alreadySet = true;
            }

            if (path.search('lotto') > 0) {
                setProductType(LOTTO);
                getCurrentWallet(LOTTO_ID);
                alreadySet = true;
            }

            if (path.search('winner-fun') > 0) {
                setProductType(WINNER_FUN);
                getCurrentWallet(WINNER_FUN_ID);
                alreadySet = true;
            }
        }

        if (!alreadySet) {
            setProductType(HOME);
            setCurrentWallet(wallet.total);
        }

    }, [history.location.pathname, wallet, winnerFunWallet]); // eslint-disable-line

    const toggleWalletPreview = () => {
        if (isVisible) {
            setTimeout(() => {
                setIsVisible(isV => !isV);
            }, 300);
        } else {
            // getWallet();
            if (productType === WINNER_FUN) {
                getWinnerFunWallet();
            }
            setIsVisible(!isVisible);
        }
    }

    const goToDeposit = () => {
        if (window.config && window.config.winnerFunEnabled === "1" && productType === WINNER_FUN && typeof props.onWinnerFunDrawer === "function") {
            props.onWinnerFunDrawer();
            return;
        }

        isDeposit();
        history.push('/deposit');
    };

    let mainContent = <CircularProgress className={'Loading'} />;
    if (!wallet.loadingWallet) {
        mainContent = (
            <div className="WalletTopWidget">
                <div className="WalletType" onClick={() => toggleWalletPreview()}>
                    <i>
                        {Icons.get(productType, 'PreviewIcon ' + productType)}
                        <ArrowDropDownIcon className="DropDownIcon" />
                    </i>
                </div>
                <div className="WalletAmount">
                    <span onClick={() => toggleWalletPreview()}>{Utils.formatSmallDecimals(currentWallet, null)}</span>
                    <i onClick={() => goToDeposit()}>{Icons.get('walletWidgetAdd', 'WalletViewMore')}</i>
                </div>
            </div>
        );
    }
    return (
        <div className={"WalletManagementScreen"}>
            <WalletPreview isVisible={isVisible} setIsVisible={() => toggleWalletPreview()} currentProduct={productType}/>
            {mainContent}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        wallet: state.wallet,
        winnerFunWallet: state.winnerFun && state.winnerFun.wallet ? state.winnerFun.wallet : null
    }
};

const mapDispatchToProps = dispatch => {
    return {
        isDeposit: () => dispatch(storeActions.isDeposit(true)),
        // getWallet: () => dispatch(storeActions.requestWallet()),
        getWinnerFunWallet: () => dispatch(requestWinnerFunWallet())
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(WalletManagement)));