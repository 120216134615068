import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import Drawer from "./Drawer";

import QuickBet from "./QuickBet";
import BetSummary from "./BetSummary";
import BetMini from "./BetMini";
import BetsFull from "./BetsFull";
import { ReactComponent as BetSlipPreMatch } from "./assets/betslipPrematch.svg";
import { ReactComponent as BetSlipLive } from "./assets/betslipLive.svg";
import { ReactComponent as BetSlipWinnerFun } from "./assets/betslipWinnerFun.svg";
import BetSlipWinnerFunLogo from "./assets/betSlipWinnerFunLogo.png";
import WinnerWarning from "../../elements/WinnerWarning";
import WarningModal from "./WarningModal";
import TickerSelector from "./TicketSelector";
import BetBuilderSummary from "../BetBuilder/BetBuilderSummary";

import { HappyHours } from "../../../common/hh-exports";

import EvBus from "../../../utils/evbus";

const useStyles = makeStyles({
  root: {},
  modal: {
    position: "relative",
    margin: "10px auto 0 auto",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "400px",
    minHeight: "95%",
    borderTopLeftRadius: "23px",
    borderTopRightRadius: "23px",
    boxShadow: "0px -2px 8px 0px rgba(0,0,0,0.35)",
    overflow: "visible"
  },
  mt30: {
    marginTop: `calc(30px + var(--notch-top))`
  },
  betSlip: {
    backgroundColor: "#fff",
    borderRadius: "23px 23px 0 0",
    width: "100%",
    boxShadow: "0px -2px 12px 0px rgba(0,0,0,0.75)"
  },
  modalFull: {
    backgroundColor: "#EEF0F1",
    background: "linear-gradient(to bottom, #EEF0F1 30%, #D2DBE7 30%)",
    height: `calc(100% - 30px - var(--notch-top))`
  },
  betSlipContainer: {
    padding: "40px 20px 0 20px",
    height: "100px"
  },
  toggle: {
    backgroundColor: "#B7CDD8",
    borderRadius: "3px",
    margin: "8px auto 20px auto",
    width: "67px",
    height: "5px",
    cursor: "pointer",
    position: "absolute",
    top: "0",
    left: "calc(50% - 34px)"
  },
  wrapperToggle: {
    //margin: "-5px -14px 0px -14px",
    borderTopLeftRadius: "23px",
    borderTopRightRadius: "23px",
    //padding: "0 14px",
    position: "relative"
  },
  toggleError: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "14px",
    right: "0px",
    borderTopLeftRadius: "23px",
    borderTopRightRadius: "23px"
  },
  toggleText: {
    color: "#fff",
    borderTopLeftRadius: "23px",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: "11px",
    paddingLeft: "20px",
    display: "inline-block",
    height: "24px",
    lineHeight: "24px",
    position: "relative",
    top: "-1px",
    paddingRight: "5px"
  },
  togglePreMatch: {
    //backgroundColor: "#1F84E6"
  },
  toggleLive: {
    //backgroundColor: "#ff196e"
  },
  toggleBorderSvg: {
    position: "absolute",
    height: "24px",
    width: "auto",
    top: "0",
    right: "-65px"
  },
  barBottom: {
    backgroundColor: "#fff",
    position: "absolute",
    height: "650px",
    bottom: "-640px",
    left: 0,
    right: 0
  },
  barBottomFull: {
    backgroundColor: "#D2DBE7",
    height: "90px",
    bottom: "-80px"
  },
  card: {
    padding: "5px 14px 0px 14px"
  },
  cardFull: {
    //padding: "5px 0 20px",
    height: "100%",
    "& .toggle": {
      margin: "2px auto 5px auto"
    }
  },
  h100: {
    height: "100%"
  },
  transitionHeight: {
    transition: "height 150ms ease-out"
  },
  heightQuickBet: {
    height: "124px"
  },
  heightLottoQuickBet: {
    height: "134px"
  },
  heightSummaryBet: {
    height: "62px"
  },
  heightMiniBet: {
    //height: "42px"
    height: "50px"
  },
  mb5: {
    marginBottom: "5px"
  },
  mt0: {
    marginTop: "-1px"
  },
  errorSelection: {
    color: "#ff196e",
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "center",
    padding: "6px 0 4px"
  },
  errorSelectionLive: {
    backgroundColor: "#fff0f6"
  },
  errorSelectionLiveB: {
    borderBottom: "1px solid #ffb2d3"
  },
  errorSelectionPreMatch: {
    backgroundColor: "#E8F4FF"
  },
  errorSelectionPreMatchB: {
    borderBottom: "1px solid #82B7E9"
  },
  winnerWrapper: {
    position: "absolute",
    top: "4px",
    right: "30px"
  },
  winnerWrapperMulti: {
    top: "13px",
    right: "15px"
  },
  hhClass: {
    position: "relative",
    top: "-5px"
  }
});

let lastScroll = 0;

const BetSlip = props => {
  const classes = useStyles();
  const { hhStatus, liveBets, prematchBets, selectError, errorMessage, winnerPlus, winnerPlusNeededEvents, hasMultiBets, location, isWinnerFun, betBuilder, t } = props;

  const root = document.body;
  const isDesktop = useMediaQuery('(min-width:1025px)');

  const [toggleBetBuilder, setToggleBetBuilder] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [minimized, setMinimized] = React.useState(false);
  const [scrolledDown, setScrolledDown] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState({
    state: false,
    lastError: 0,
  });
  const betBuilderLength = React.useRef(betBuilder?.selected?.length ?? 0);
  const tid = React.useRef(null);

  const handleMatchDetailsScroll = (data, event) => {
    if (data.direction === 'up') {
      setScrolledDown(false);
    } else {
      setScrolledDown(true);
    }
  }

  React.useEffect(() => {
    EvBus.on('MATCH_DETAILS_SCROLLED', handleMatchDetailsScroll);

    return () => {
      EvBus.remove('MATCH_DETAILS_SCROLLED', handleMatchDetailsScroll);
    }
  }, []);

  React.useEffect(() => {
    if (selectError > 2 && selectError !== openWarning.lastError) {
      setOpenWarning({
        state: true,
        lastError: selectError,
      });
    }
  }, [selectError]); // eslint-disable-line

  const handleWarningClose = () => {
    setOpenWarning({
      state: false,
      lastError: 0,
    });
  };

  const handleToggle = (event, newValue) => {
    setToggle(!toggle);
  };

  React.useEffect(() => {
    if (liveBets.length || prematchBets.length || selectError || (hasMultiBets && toggle)) {
      setOpened(true);
      setMinimized(false);
    } else {
      setOpened(false);
      setMinimized(false);
    }
  }, [liveBets, prematchBets, selectError, hasMultiBets, toggle]);


  /* for mobile app to hide betslip when scrolling down
  const handleScroll = () => {
    const root = document.documentElement;

    const scrollTop = (window.pageYOffset || root.scrollTop) - (root.clientTop || 0);

    if (scrollTop < 0) return;
    if (scrollTop + window.innerHeight > document.body.scrollHeight) return;

    if (lastScroll !== scrollTop && Math.abs(lastScroll - scrollTop) > 20) {
      if (liveBets.length || prematchBets.length) {
        if (lastScroll > scrollTop) {
          setOpened(true);
        } else if (lastScroll < scrollTop) {
          setOpened(false);
        }
      }
      lastScroll = scrollTop;
    }
  };
  React.useEffect(() => {
    const deb = debounce(handleScroll, 100);
    document.addEventListener("scroll", deb);
    return () => {
      document.removeEventListener("scroll", deb);
    };
  }, [liveBets, prematchBets]); // eslint-disable-line
  */

  const hideBet = () => {
    setOpened(false);
  };

  let modalClass = classes.modal;
  let barBottomClass = classes.barBottom;
  let stateWrapper = "";

  const openDrawers = {
    reduced: false,
    full: false,
    state: {
      quick: false,
      multi: false,
      mini: false,
    }
  };

  root.classList.remove(
    "no-scroll",
    "betslip-on",
    "quick-bet",
    "bet-builder",
    "lotto-quick-bet",
    "summary-bet",
    "mini-bet",
    "full-bet"
  );

  const cleanBodyStyle = () => {
    if (root.style.position === "fixed") {
      root.style.position = "";
      root.style.top = "";
      root.style.bottom = "";
      root.style.left = "";
      root.style.right = "";
    }
  };

  if (window.config && window.config.cordova) {
    if (location.pathname === "/" || location.pathname === "/index.html") {
      // noop
    } else if (location.pathname.indexOf("/pariuri") === -1 && location.pathname.indexOf("/winner-fun") === -1) {
      cleanBodyStyle();
      if (toggle) setToggle(false);
      return null;
    }
  } else {
    if (location.pathname.indexOf("/pariuri") === -1 && location.pathname.indexOf("/winner-fun") === -1) {
      cleanBodyStyle();
      if (toggle) setToggle(false);
      return null;
    }
  }
  if (isDesktop && location.pathname.indexOf("/pariuri") > -1) {
    cleanBodyStyle();
    return null;
  }
  if (isDesktop && location.pathname.indexOf("/winner-fun") > -1) {
    cleanBodyStyle();
    return null;
  }

  if (opened) {
    if ((liveBets.length || prematchBets.length) || (hasMultiBets)) {
      if (!toggle) {
        openDrawers.reduced = true;
        if (liveBets.length + prematchBets.length === 1) {
          if (minimized) {
            root.classList.add("betslip-on", "mini-bet");
            openDrawers.state.mini = true;
            stateWrapper = classes.heightMiniBet;
          } else {
            root.classList.add("betslip-on", "quick-bet");
            openDrawers.state.quick = true;
            stateWrapper = classes.heightQuickBet;
          }
        } else if (liveBets.length + prematchBets.length >= 2) {
          if (minimized) {
            root.classList.add("betslip-on", "mini-bet");
            openDrawers.state.mini = true;
            stateWrapper = classes.heightMiniBet;
          } else {
            root.classList.add("betslip-on", "summary-bet");
            openDrawers.state.multi = true;
            stateWrapper = classes.heightSummaryBet;
          }
        }
      } else if (toggle) {
        openDrawers.full = true;
        modalClass = `${classes.modal} ${classes.modalFull} ${classes.mt30}`;
        barBottomClass = `${classes.barBottom} ${classes.barBottomFull}`;
      }
    } else {
      if (toggle) {
        setToggle(!toggle);
      }
    }
  }

  if (openDrawers.full) {
    lastScroll = -1 * document.documentElement.scrollTop;
    if (!window.config.cordova && false) {
      root.style.position = "fixed";
      root.style.top = `${lastScroll}px`;
      root.style.bottom = "0";
      root.style.left = "0";
      root.style.right = "0";
      root.classList.add("no-scroll", "full-bet");
    } else {
      root.classList.add("no-scroll-bets", "full-bet");
    }
    EvBus.emit('BET-SLIP-FULL', true);
  } else {
    if (!window.config.cordova && false) {
      if (root.style.position === "fixed") {
        root.style.position = "";
        root.style.top = "";
        root.style.bottom = "";
        root.style.left = "";
        root.style.right = "";
        document.documentElement.scrollTop = -1 * lastScroll;

        EvBus.emit('BET-SLIP-FULL', false);
      }
    } else {
      if (root.classList.contains("no-scroll-bets")) {
        document.documentElement.scrollTop = -1 * lastScroll;
        root.classList.remove("no-scroll-bets");
        EvBus.emit('BET-SLIP-FULL', false);
      }
    }
  }

  const handleDragOpen = willOpen => {
    if (willOpen) {
      if (opened && (liveBets.length || prematchBets.length) && !toggle) {
        setToggle(true);
      }
    }
  };

  const handleDragOpenBetBuilder = willOpen => {
    if (willOpen && !toggleBetBuilder) {
      setToggleBetBuilder(true);
    }
  };

  const handleDragCloseBetBuilder = willClose => {
    clearTimeout(tid.current);
    tid.current = setTimeout(() => {
      if (willClose) {
        if (toggleBetBuilder) {
          setToggleBetBuilder(false);
        } else {
          setScrolledDown(true);
        }
      }
    }, 50);
  };
  const onToggleBetBuilder = (toggle) => {
    setToggleBetBuilder(!!toggle)
  };

  let canShowBetslip = true;
  if (betBuilder.open && betBuilder.selected.length > 0) {
    canShowBetslip = false;

    if (betBuilder.open && betBuilder.selected.length > 0 && !scrolledDown) {
      root.classList.add("betslip-on", "bet-builder");
    }
  }

  return (
    <React.Fragment>
      <Drawer
        open={betBuilder.open && betBuilder.selected.length > 0 && !scrolledDown}
        modalElementClass={modalClass}
        top={false}
        onRequestClose={() => { }}
        notifyWillOpen={handleDragOpenBetBuilder}
        notifyWillClose={handleDragCloseBetBuilder}
        openOffset={-50}
        closeOffset={50}
      >
        <div className={classes.wrapperToggle}>
          <div className={classes.toggleText}>
            <span>&nbsp;</span>
          </div>
          <div className={classes.toggle} onClick={onToggleBetBuilder}></div>
        </div>
        <div className={classes.card}>
          <div className={`${classes.transitionHeight} `}>
            <BetBuilderSummary expanded={toggleBetBuilder} onToggleExpanded={onToggleBetBuilder} />
          </div>
        </div>
        <div className={barBottomClass}></div>
      </Drawer>
      <Drawer
        open={openDrawers.reduced && canShowBetslip}
        modalElementClass={modalClass}
        top={false}
        onRequestClose={hideBet}
        notifyWillOpen={handleDragOpen}
        openOffset={-100}
      >
        {(openDrawers.state.quick || openDrawers.state.multi || openDrawers.state.quickLotto) && (
          <React.Fragment>
            <div className={classes.wrapperToggle}>
              {(selectError !== 0 || errorMessage !== "") && (
                <div
                  className={`${classes.toggleError} ${liveBets.length > 0
                    ? classes.errorSelectionLive
                    : classes.errorSelectionPreMatch
                    }`}
                ></div>
              )}
              <div className={classes.toggleText}>
                <span>&nbsp;</span>
              </div>
              {isWinnerFun && <div className={`wf-betslip-header wf-smaller`}>
                <BetSlipWinnerFun className={classes.toggleBorderSvg} />
                <span>
                  <img src={BetSlipWinnerFunLogo} alt="winner fun logo" />
                </span>
              </div>}
              <div className={classes.toggle} onClick={handleToggle}></div>
              {winnerPlus && <div className={classes.winnerWrapper}>
                <WinnerWarning number={prematchBets.length - 1} total={prematchBets.length + winnerPlusNeededEvents - 1} />
              </div>}
            </div>
            {selectError !== 0 && (
              <div className={`${classes.wrapperToggle} ${classes.mt0}`}>
                <div
                  className={`${classes.errorSelection} ${liveBets.length > 0
                    ? `${classes.errorSelectionLive} ${classes.errorSelectionLiveB}`
                    : `${classes.errorSelectionPreMatch} ${classes.errorSelectionPreMatchB}`
                    }`}
                >
                  {liveBets.length > 0 && <span>{t("LIVE and Pre-match events cannot be mixed on the same betslip.")}</span>}
                  {prematchBets.length > 0 && <span>{t("Pre-match and LIVE events cannot be mixed on the same betslip.")}</span>}
                </div>
              </div>
            )}
            {errorMessage !== "" && (
              <div className={`${classes.wrapperToggle} ${classes.mt0}`}>
                <div
                  className={`${classes.errorSelection} ${liveBets.length > 0
                    ? `${classes.errorSelectionLive} ${classes.errorSelectionLiveB}`
                    : `${classes.errorSelectionPreMatch} ${classes.errorSelectionPreMatchB}`
                    }`}
                >
                  {t(errorMessage)}
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        <div className={classes.card}>
          <div className={`${classes.transitionHeight} ${stateWrapper}`}>
            {openDrawers.state.quick && (
              <QuickBet
                bet={liveBets.length ? liveBets[0] : prematchBets[0]}
                onToggle={handleToggle}
              />
            )}
            {openDrawers.state.multi && <BetSummary onToggle={handleToggle} />}
            {openDrawers.state.mini && <BetMini onToggle={handleToggle} />}
          </div>
        </div>
        <div className={barBottomClass}></div>
      </Drawer>
      <Drawer
        open={openDrawers.full}
        modalElementClass={modalClass}
        top={true}
        onRequestClose={handleToggle}
        dontApplyListeners={true}
      >
        <div className={`${classes.cardFull} wf-betslip`}>
          <div className={`${classes.wrapperToggle} wf-wrapper-toggle`}>
            {!hasMultiBets && !(hhStatus.live && liveBets.length > 0 && errorMessage === "") && !(hhStatus.prematch && prematchBets.length > 0 && errorMessage === "") && <React.Fragment>
              {(errorMessage !== "") && (
                <div
                  className={`${classes.toggleError} ${liveBets.length > 0
                    ? classes.errorSelectionLive
                    : classes.errorSelectionPreMatch
                    }`}
                ></div>
              )}
              <div className={classes.toggleText}>
                <span>&nbsp;</span>
              </div>
              {isWinnerFun && <div className={`wf-betslip-header`}>
                <BetSlipWinnerFun className={classes.toggleBorderSvg} />
                <span>
                  <img src={BetSlipWinnerFunLogo} alt="winner fun logo" />
                </span>
              </div>}
              <div className={classes.toggle} onClick={handleToggle}></div>
            </React.Fragment>}
            {!hasMultiBets && hhStatus.live && liveBets.length > 0 && errorMessage === "" && <HappyHours className={`${classes.hhClass} inBetslip`} event_type={`${!isWinnerFun ? "" : "winner-fun-"}bets-live`} />}
            {!hasMultiBets && hhStatus.prematch && prematchBets.length > 0 && errorMessage === "" && <HappyHours className={`${classes.hhClass} inBetslip`} event_type={`${!isWinnerFun ? "" : "winner-fun-"}bets-prematch`} />}
            {hasMultiBets && <TickerSelector />}
            {winnerPlus && <div className={`${classes.winnerWrapper} ${hasMultiBets ? classes.winnerWrapperMulti : ''}`}>
              <WinnerWarning number={prematchBets.length - 1} total={prematchBets.length + winnerPlusNeededEvents - 1} />
            </div>}
          </div>
          {errorMessage !== "" && (
            <div className={`${classes.wrapperToggle} ${classes.mt0}`}>
              <div
                className={`${classes.errorSelection} ${liveBets.length > 0
                  ? `${classes.errorSelectionLive} ${classes.errorSelectionLiveB}`
                  : `${classes.errorSelectionPreMatch} ${classes.errorSelectionPreMatchB}`
                  }`}
              >
                {t(errorMessage)}
              </div>
            </div>
          )}
          <div className={classes.h100}>
            {((liveBets.length > 0 || prematchBets.length > 0) || hasMultiBets) && <BetsFull onToggle={handleToggle} />}
          </div>
        </div>
      </Drawer>
      <WarningModal open={openWarning.state} handleClose={handleWarningClose} type={liveBets.length > 0 ? 1 : 2} />
    </React.Fragment>
  );
};

BetSlip.propTypes = {
  liveBets: PropTypes.array,
  prematchBets: PropTypes.array,
};

BetSlip.defaultProps = {
  liveBets: [],
  prematchBets: []
};

export default withRouter(withTranslation()(BetSlip));
