import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import connect from "react-redux/es/connect/connect";
import { sprintf } from 'sprintf-js';
import TitleBar from "../../../UI/TitleBar/TitleBar";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PageTitle from '../../../UI/PageTitle/PageTitle';
import Button from '../../../UI/Button/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import './Selfexclusion.scss';
import TakeABreakConfirmation from './TakeABreakConfirmation/TakeABreakConfirmation';
import SelfexclusionConfirmation from './SelfexclusionConfirmation/SelfexclusionConfirmation';

const LIMIT_1_DAY       = 4;
const LIMIT_3_DAYS      = 5;
const LIMIT_1_WEEK      = 6;
const LIMIT_6_MONTHS    = 7;
const LIMIT_1_YEAR      = 8;
const LIMIT_3_YEARS     = 9;

class Selfexclusion extends Component {

    state = {
        activeChoice: LIMIT_1_DAY,
        agree: 0,
        needConfirmation: false,
        type: 1,
        title: '',
        subtitle: '',
        questionText: '',
    };

    componentDidMount() {
        this.handlePageContent(this.state.type);

        if(this.props.auth.auth_type !== "user"){
            this.props.history.push("/");
        }
    }

    getRealAgree() {
        return document.getElementById("confirm-checkbox").checked;
    }

    onClickHandler() {
        this.setState({
            ...this.state,
            agree: this.getRealAgree(),
        });
    }

    onClickHandlerChoice(value) {
        this.setState({
            ...this.state,
            activeChoice: value,
        })
    }

    getChoiceLabel(choice) {
        switch (choice) {
            case LIMIT_1_DAY:
                return sprintf(this.props.t("%s day"),1);
            case LIMIT_3_DAYS:
                return sprintf(this.props.t('%s days'),3);
            case LIMIT_1_WEEK:
                return sprintf(this.props.t('%s days'),7);
            case LIMIT_6_MONTHS:
                return sprintf(this.props.t('%s months'),6);
            case LIMIT_1_YEAR:
                return sprintf(this.props.t('%s year'),1);
            case LIMIT_3_YEARS:
                return sprintf(this.props.t('%s years'),3);
            default:

        }
    }

    getDeleteAccountWarning() {
        let comp = [];
        if (this.expiresIn > 0) {

            comp.push(
                <span key={'text-1'}>
                {sprintf(this.props.t("Your Identity verification period will expire after %s days."),this.expiresIn)}
                    <br/>
            </span>
            );
        }
        comp.push(
            <span key={'text-2'}>
                {this.props.t("If you self-exclude, you will be unable to complete it before the deadline and, accordingly to the legal requirements, you account will be closed and the funds will be sent to the State Budget.")}
            </span>
        );
        return comp;
    }

    getVerifiedAccountWarning() {
        let comp = [];
        comp.push(
            <span key={'text-1'}>
                {this.props.t("Your account and your funds are safe.")}
                <br/>
            </span>
        );
        comp.push(
            <span key={'text-2'}>
                {this.props.t("You can come back when the chosen period expires")}
            </span>
        );
        return comp;
    }

    triggerConfirmation(value) {
        if(this.state.agree){
            this.setState({
                ...this.state,
                needConfirmation: value,
                agree: value,
            })
        }
    }

    handlePageContent = (type) => {
        if(type === 1) {
            this.setState({
                ...this.state,
                type: 1,
                activeChoice: LIMIT_1_DAY,
                agree: false,
                needConfirmation: false,
                title: this.props.t('Take a Break'),
                subtitle: this.props.t('Steer away from gaming for a few days'),
                questionText: this.props.t('For how long do you want to take a break?'),
            })
        }
        if(type === 2){
            this.setState({
                ...this.state,
                type: 2,
                activeChoice: LIMIT_6_MONTHS,
                agree: false,
                needConfirmation: false,
                title: this.props.t('Self-exclusion'),
                subtitle: this.props.t('Got a gambling addiction?'),
                questionText: this.props.t('For how long do you want to self-exclude?'),
            })
        }
    }

    KYCWillExpire() {

        if (!this.props.accountVerified) {
            let expireKYCTimestamp = parseInt(this.props.accountCreated) + 2678400; // after 31 days
            let currentTimestamp = new Date().getTime() / 1000;
            this.expiresIn = parseInt((parseInt(expireKYCTimestamp) - currentTimestamp)/86400);
            switch (this.state.activeChoice) {
                case LIMIT_6_MONTHS:
                case LIMIT_1_YEAR:
                case LIMIT_3_YEARS:
                    return true;
                case LIMIT_1_DAY:
                    if (expireKYCTimestamp - currentTimestamp < 86400) return true;
                    break;
                case LIMIT_3_DAYS:
                    if (expireKYCTimestamp - currentTimestamp < 86400 * 3) return true;
                    break;
                case LIMIT_1_WEEK :
                    if (expireKYCTimestamp - currentTimestamp < 86400 * 7) return true;
                    break;
                default: return true;
            }
        }

        return false;
    }

    getWarningText() {

        if (this.KYCWillExpire()) {
            return this.getDeleteAccountWarning();
        } else {
            return this.getVerifiedAccountWarning();
        }
    }

    getVerifyKYC(){
        let isValidKYC = this.props.profile.verified && this.props.profile.account_status === 1 && this.props.profile.account_sub_status === 3;
        if (this.state.type === 1 || (this.state.type === 2 && isValidKYC)) {
            return (
                <div className="Terms">
                    <Checkbox
                        id={'confirm-checkbox'}
                        onClick={this.onClickHandler.bind(this)}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={this.state.agree ? 1 : 0}
                        className={"Checkbox"}
                    />
                    <label htmlFor={'confirm-checkbox'} className="ContentText">
                        {this.props.t("I agree to be restricted to my online casino access for the period specified above.")}
                    </label>
                </div>
            );
        }else{
            return (
                <div className="InvalidKYC">
                    <p>{this.props.t("Verify your account and identity before you can self-exclude")}</p>
                </div>
            );
        }
    }

    render() {

        let content = '';

        let periodButtons = '';
        let contentSwitcher = '';

        if(this.state.type === 1){
            periodButtons = (
                <div className="ButtonContainer">
                    <Button 
                        type={"button"} 
                        full 
                        click={() => this.onClickHandlerChoice(LIMIT_1_DAY)} 
                        className={this.state.activeChoice === LIMIT_1_DAY ? 'active' : ''} 
                        value={this.getChoiceLabel(LIMIT_1_DAY)}
                    />
                    <Button 
                        type={"button"} 
                        full 
                        click={() => this.onClickHandlerChoice(LIMIT_3_DAYS)} 
                        className={this.state.activeChoice === LIMIT_3_DAYS ? 'active' : ''} 
                        value={this.getChoiceLabel(LIMIT_3_DAYS)}
                    />
                    <Button 
                        type={"button"} 
                        full 
                        click={() => this.onClickHandlerChoice(LIMIT_1_WEEK)} 
                        className={this.state.activeChoice === LIMIT_1_WEEK ? 'active' : ''} 
                        value={this.getChoiceLabel(LIMIT_1_WEEK)}
                    />
                </div>
            );
            contentSwitcher = (
                <Fragment>
                    <Typography className={'PageLink'} variant="subtitle1" onClick={() => this.handlePageContent(2)}>
                        {this.props.t('More options')}
                    </Typography>
                </Fragment>
            );
        }else if(this.state.type === 2){
            periodButtons = (
                <div className="ButtonContainer">
                    <Button 
                        type={"button"} 
                        full 
                        click={() => this.onClickHandlerChoice(LIMIT_6_MONTHS)} 
                        className={this.state.activeChoice === LIMIT_6_MONTHS ? 'active' : ''} 
                        value={this.getChoiceLabel(LIMIT_6_MONTHS)}
                    />
                    <Button 
                        type={"button"} 
                        full 
                        click={() => this.onClickHandlerChoice(LIMIT_1_YEAR)} 
                        className={this.state.activeChoice === LIMIT_1_YEAR ? 'active' : ''} 
                        value={this.getChoiceLabel(LIMIT_1_YEAR)}
                    />
                    <Button 
                        type={"button"} 
                        full 
                        click={() => this.onClickHandlerChoice(LIMIT_3_YEARS)} 
                        className={this.state.activeChoice === LIMIT_3_YEARS ? 'active' : ''} 
                        value={this.getChoiceLabel(LIMIT_3_YEARS)}
                    />
                </div>
            );
            contentSwitcher = (
                <Fragment>
                    <Button 
                        type={"button"} 
                        full 
                        className={'active ShortBreak'} 
                        click={() => this.handlePageContent(1)}
                        value={this.props.t('Take a short break instead')} 
                    />
                </Fragment>
            );
        }
        
        if(this.state.needConfirmation && this.state.type === 1){
            content = (
                <TakeABreakConfirmation 
                    return={(value) => this.triggerConfirmation(value)} 
                    text={this.getWarningText()}
                    duration={this.getChoiceLabel(this.state.activeChoice)}
                    activeChoice={this.state.activeChoice}
                    title={this.state.title}
                    subtitle={this.state.subtitle}
                />
            );
        }else if(this.state.needConfirmation && this.state.type === 2){
            content = (
                <SelfexclusionConfirmation 
                    return={(value) => this.triggerConfirmation(value)} 
                    activeChoice={this.state.activeChoice}
                    title={this.state.title}
                    subtitle={this.state.subtitle}
                />
            );
        }else{
            content = (
                <div className={"SelfexclusionScreen"}>
                    <TitleBar pageTitle={this.props.t('Responsible Gaming')} />
                    
                    {/*<AppBar position="static" className="SubtitleBar">*/}
                    {/*    <Typography variant="subtitle1">{this.state.title}</Typography>*/}
                    {/*</AppBar>*/}
                    
                    <div className="SelfexclusionContent">
                        <PageTitle icon={<NotInterestedIcon className={"iconSettings"} />} title={this.state.title} subtitle={this.state.subtitle}/>
                        
                        <p className="ContentText">
                            {this.props.t("It is important for us to have fun when playing in our online casino. If you're worried about playing more than you can afford, then take a break for a short period of time. After the self-exclusion period expires, you will be able to return to play.")}
                        </p>

                        <h3 className={"ContentQuestion"}>{this.state.questionText}</h3>

                        {periodButtons}

                        {this.getVerifyKYC()}

                        <Button 
                            type={"button"} 
                            full 
                            disabled={!this.state.agree} 
                            className={this.state.agree ? 'gradient' : ''} 
                            click={() => this.triggerConfirmation(true)}
                            value={this.props.t('Lock account for ') + this.getChoiceLabel(this.state.activeChoice)} 
                        />
                        
                        {contentSwitcher}
                    </div>
                </div>
            );
        }

        return (
            <div>
                {content}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountCreated: state.profile.date_created,
        accountVerified: state.profile.verified,
        auth: state.authentication,
        profile: state.profile,
    }
};

export default withTranslation()(connect(mapStateToProps, null)(Selfexclusion));
