import { fork, call, put, delay, takeLatest, cancelled } from "redux-saga/effects";
import * as actions from "../actions";
import ClientAPI from "../../ClientAPI/ClientAPI";
import * as paths from "../../ClientAPI/paths";
import { requestGamesSaga } from "./games";
import { listTypes } from "../../utils/GamesListTypes";
import * as actionTypes from "../actions/actionTypes";

const JACKPOT_DETAILS_REFRESH_INTERVAL = 30 * 1000; // 30 seconds

function* requestJackpotDetailsSaga(force = false) {
    const state = yield ClientAPI.getStore().getState();

    // check if a request is active
    if (state.jackpot.requestActive && !force) {
        return;
    }

    // console.log("jackpot refCnt", state.jackpot.refCnt, "force", force);

    // check if we really need to fetch jackpots (any counter is visible)
    if (state.jackpot.refCnt === 0 && !force) {
        return;
    }

    // check if it's too soon to make a request
    const now = new Date().getTime();
    const elapsedSinceLastFetch = now - state.jackpot.lastFetchTime;

    if (elapsedSinceLastFetch < JACKPOT_DETAILS_REFRESH_INTERVAL) {
        /* console.log(
            "jackpot request too soon",
            elapsedSinceLastFetch,
            JACKPOT_DETAILS_REFRESH_INTERVAL
        ); */
        return;
    }

    try {
        const axios = ClientAPI.getInstance();

        const response = yield axios({
            url: paths.jackpot.JACKPOTS,
            method: "get",
        });

        if (!response) {
            throw new Error(`[ERROR] Jackpot response is empty!`);
        }

        if (!response.hasOwnProperty("result")) {
            throw new Error(`[ERROR] Server response has no jackpot result!`);
        }

        // yield console.log(response.result);
        if (response.result && response.result.egt && Object.keys(response.result.egt).length) {
            yield put(actions.receivedJackpotDetails(response.result.egt));
            yield put(actions.receivedJackpotListDetails("1", response.result.egt));
        }
        if (response.result && response.result.egt && Object.keys(response.result.egt_egypt_quest).length) {
            yield put(actions.receivedJackpotListDetails("3", response.result.egt_egypt_quest));
        }
        if (response.result && response.result.ct && Object.keys(response.result.ct).length) {
            yield put(actions.receivedJackpotListDetails("4", response.result.ct));
        }

    } catch (error) {
        console.log(error);
    }
}

const LATEST_WINNERS_REQUEST_INTERVAL = 3600 * 1000; // one hour

function* requestJackpotLatestWinnersSaga() {
    try {
        const state = yield ClientAPI.getStore().getState();

        // check if it's too soon to make a request
        const now = new Date().getTime();
        const elapsedSinceLastFetch = now - state.jackpot.latestWinnersLastFetchTime;

        if (elapsedSinceLastFetch < LATEST_WINNERS_REQUEST_INTERVAL) {
            /* console.log(
                "latest winners request too soon",
                elapsedSinceLastFetch,
                LATEST_WINNERS_REQUEST_INTERVAL
            ); */
            return;
        }

        yield put(actions.updateLatestWinnersLastFetchTime(now));

        const axios = ClientAPI.getInstance();

        const response = yield axios({
            url: paths.jackpot.LATEST_WINNERS,
            method: "post",
            data: {
                type: "slots"
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Latest Winners response is empty!`);
        }

        if (!response.hasOwnProperty("result")) {
            throw new Error(`[ERROR] Server response has no Latest Winners result!`);
        }

        // yield console.log(response.result);
        yield put(actions.receivedJackpotLatestWinners(response.result));
    } catch (error) {
        console.log(error);
    }
}

function* requestJackpotLatestJackpotWinnersSaga() {
    try {
        const state = yield ClientAPI.getStore().getState();

        // check if it's too soon to make a request
        const now = new Date().getTime();
        const elapsedSinceLastFetch = now - state.jackpot.latestJackpotWinnersLastFetchTime;

        if (elapsedSinceLastFetch < LATEST_WINNERS_REQUEST_INTERVAL) {
            /* console.log(
                "latest jackpot winners request too soon",
                elapsedSinceLastFetch,
                LATEST_WINNERS_REQUEST_INTERVAL
            ); */
            return;
        }

        yield put(actions.updateLatestJackpotWinnersLastFetchTime(now));

        const axios = ClientAPI.getInstance();

        const response = yield axios({
            url: paths.jackpot.LATEST_WINNERS,
            method: "post",
            data: {
                type: "jackpot"
            }
        });
        if (!response) {
            throw new Error(`[ERROR] Latest Jackpot Winners response is empty!`);
        }

        if (!response.hasOwnProperty("result")) {
            throw new Error(`[ERROR] Server response has no Latest Jackpot Winners result!`);
        }

        // yield console.log(response.result);
        yield put(actions.receivedJackpotLatestJackpotWinners(response.result));
    } catch (error) {
        console.log(error);
    }
}

export function* cycleRequestJackpotSaga() {
    /*
    yield fork(requestGamesSaga, {
        listType: listTypes.JACKPOT
    });
    */

    yield fork(requestJackpotDetailsSaga, true);
    yield fork(requestJackpotLatestWinnersSaga);
    yield fork(requestJackpotLatestJackpotWinnersSaga);

    yield takeLatest(actionTypes.jackpot.REQUEST_LATEST_WINNERS, requestJackpotLatestWinnersSaga);
    yield takeLatest(
        actionTypes.jackpot.REQUEST_LATEST_JACKPOT_WINNERS,
        requestJackpotLatestJackpotWinnersSaga
    );

    try {
        while (true) {
            yield delay(JACKPOT_DETAILS_REFRESH_INTERVAL);
            yield call(requestJackpotDetailsSaga);
        }
    } finally {
        if (yield cancelled()) {
            //console.log("cancelled");
        }
    }
}
