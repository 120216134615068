import { takeLatest, put } from "redux-saga/effects";


import { application} from "../actions/actionTypes";
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as actions from "../actions";



function* redeemCodeSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: `/api/profile/sendVoucher`,
            method: 'post',
            data: {
                code: action.code
            }
        });
        if (!response.status) {
            yield put(actions.redeemCodeResponse(false));
            return
        }

        if (!("OK" === response.status)) {
            yield put(actions.redeemCodeResponse(false));
            console.log(`[AUTH] App auth error`);
            return
        }
        yield put(actions.redeemCodeResponse(response.result));
    } catch (e) {
        // console.log("fetchFreeBetsSaga err", e);
        yield put(actions.redeemCodeResponse(false));
    }
}

export default function* watchVoucherSaga() {
    yield takeLatest(application.REDEEEM_CODE, redeemCodeSaga);
}
