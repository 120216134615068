import * as actionTypes from './actionTypes';

export const requestTransactionsHistory = (requestType) => {
    return {
        type: actionTypes.transactions.REQUEST_TRANSACTIONS_HISTORY,
        requestType: requestType,
    }
};

export const receivedTransactionsHistory = (data) => {
    return {
        type: actionTypes.transactions.RECEIVED_TRANSACTIONS_HISTORY,
        transactions: data,
    }
};