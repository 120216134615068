import React, { Component } from 'react';
import './Cookies.scss';
import { withRouter } from 'react-router-dom';
import Icons from "../../../../assets/Icons/Icons";
import { Button } from "@material-ui/core";
import * as storeActions from "../../../../store/actions";
import { connect } from "react-redux";
import Translate from "../../../../utils/Translate";
import { withTranslation } from "react-i18next";
import Utils from "../../../../utils/Utils";
import i18next from "i18next";

class Cookies extends Component {
    componentDidMount() {
        this.checkCookies();

        this.setCookieIfCampaign();
    }

    setCookieIfCampaign() {

        const urlParams = new URLSearchParams(window.location.search);
        const affId = urlParams.get('aff_id');

        if (affId) {
            Utils.setCookie('aff_id',affId,2);
        } /*else {
            const utm_source = urlParams.get('utm_source');
            if (utm_source) {
                Utils.setCookie('aff_id',utm_source,2);
            }
        }*/

        const gclid = urlParams.get('gclid');

        if (gclid) {
            Utils.setCookie('gclid',gclid,2);
        }
        const sub_id = urlParams.get('sub_id');

        if (sub_id) {
            Utils.setCookie('sub_id',sub_id,2);
        }
    }

    checkCookies() {
        let cookiesShown = parseInt(window.localStorage.getItem('cookies'));

        if (!cookiesShown || this.props.show) {
            this.setState({
                ...this.state,
                className: ''
            })
            this.props.showGlobalWidgets();
        } else {
            this.props.hideGlobalWidgets();
        }
    }

    state = {
        className: 'hide'
    };
    acceptHandler() {
        this.setState({
            ...this.state,
            className: 'hide'
        });
        window.localStorage.setItem('cookies', 1);
        this.props.hideGlobalWidgets();
        if (this.props.close) {
            this.props.close()
        };
    }

    linkHandler() {
        this.acceptHandler();
        const privacyPolicyUrl = window.location.protocol + "//" + window.config.cdn_address + '/documents/privacyPolicy/' + i18next.language + '/Privacy%20Policy.pdf';
        window.location.href=privacyPolicyUrl;
    }

    render() {
        return (
            <div id={'cookies'} className={this.state.className}>
                <div className="content">
                    <div className="title">
                        <span onClick={this.linkHandler.bind(this)}>{this.props.t('Review our cookie policy')}</span>
                        <div className="close" onClick={this.acceptHandler.bind(this)}>
                            {Icons.get('close-button', 'close')}
                        </div>
                    </div>
                    <div className="description">
                        <div className="description-title">{this.props.t('What do we use cookies for?')}</div>
                        <p>
                            <Translate text={"We use cookies and similar technologies to ${boldStart}recognize your repeat visits and preferences${boldEnd}, as well as to be able to ${boldStart}offer the service authentication and communication${boldEnd}."} placeholder={{ boldStart: '<b>', boldEnd: '</b>' }} />
                        </p>
                        <p onClick={this.linkHandler.bind(this)}>
                            <Translate text={"To learn more about cookies and the data we store, view our ${boldStart}Privacy Policy${boldEnd}."} placeholder={{ boldStart: '<b>', boldEnd: '</b>' }} />
                        </p>
                        <p>
                            {this.props.t('By clicking "I accept" or "X" on this banner, or using our site, you consent to the use of cookies.')}
                        </p>
                        <Button variant={'contained'} size={'large'} onClick={this.acceptHandler.bind(this)}>{this.props.t('I accept')}</Button>
                    </div>
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        hideGlobalWidgets: () => dispatch(storeActions.globalWidgets(false)),
        showGlobalWidgets: () => dispatch(storeActions.globalWidgets(true))
    };
};

const mapStateToProps = state => {
    return {
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Cookies)));
