export const getData = (data, path, def) => {
  const elements = path.split(".");
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];

      if (element.indexOf("[") > -1) { // array
        let parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== "undefined") {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (typeof tmp[element] !== "undefined") {
        tmp = tmp[element];
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
}