import React, { Fragment, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from '../../../../store/actions/index';
import connect from "react-redux/es/connect/connect";
import Icons from '../../../../assets/Icons/Icons';
import Button from '../../../UI/Button/Button';
import Moment from "react-moment";
import ConfirmPopup from './ConfirmPopup/ConfirmPopup';

const PENDING = 1;
const APPROVED =  2;
const PROCESSED =  4;

const WithdrawalStatus = props => {

    const [popupContent, setPopupContent] = useState({
        open: false,
        type: '',
        amount: 0,
        id: 0,
    });

    const handleOpenPopup = (type, amount, id, src) => {
        setPopupContent({
            ...popupContent,
            open: true,
            type: type,
            amount: amount,
            id: id,
            src: src
        });
    }

    const handleClosePopup = () => {
        setPopupContent({
            ...popupContent,
            open: false,
        });
    }

    useEffect(() => {
        props.content.map(withdrawal => {
            if(withdrawal.status_id === PENDING || withdrawal.status_id === APPROVED || withdrawal.status_id === PROCESSED){
                props.onRequestTaxService(1, withdrawal.amount, withdrawal.id);
            }
            return true;
        });

        return () => {
            props.onCleanVerificationCode();
        };
        // eslint-disable-next-line
    }, []);

    const formatCurrency = (value) => {
        return (
            value
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              .replace(',00', '')
          )
    }

    const viewHistoryHandler = () => {
        props.history.push("/profile/transactions");
    }

    const viewLocationHandler = () => {
        props.history.push("/deposit");
    }

    // const getTimeRemainingHandler = (date) => {
    //     let now = parseInt(new Date().getTime() / 1000, 10);
    //     let timestamp = date;
    //     let remaining = (timestamp - now) / 3600;

    //     let days = parseInt(remaining / 24, 10);
    //     let hours = parseInt(parseInt(remaining * 3600, 10) / 3600, 10);
    //     let minutes = parseInt((parseInt(remaining * 3600, 10) - (hours * 3600))/60, 10);
    //     let seconds = parseInt((parseInt(remaining * 3600, 10) - (minutes * 60) - hours * 3600), 10);

    //     if(remaining <= 0){
    //         return 'Expired';
    //     }

    //     if(days >= 1){
    //         if(days === 1){
    //             return days + ' ' + props.t('Day');
    //         }else{
    //             return days + ' ' + props.t('Day');
    //         }
    //     }else{
    //         return hours + "h : " + minutes + " : " + seconds;
    //     }
    // }

    const withdrawalStatus = {
        0: "Unknown",
        1: "Pending approval",
        2: "Approved",
        3: "Rejected",
        4: "Processed",
        5: "Cancelled",
    };

    const icons = {
        timer: Icons.get('timer', `TimerIcon`),
        mapRoute: Icons.get('mapRouteBtn', `MapRouteIcon`),
        trashBin: Icons.get('trashBin', `TrashBinIcon`),
    };

    let pageContent = props.content.map(withdrawal => {

        if (withdrawal.amount < 1) {
            return false;
        }

        let currentLocation = {};
        if (props.locations) {
            props.locations.map(location => {
                if(location.id === withdrawal.location_id){
                    currentLocation = location;
                }
                return currentLocation;
            });
        }

        let currentTax = props.t('Calculating...');
        if(!props.withdrawals.loadingTax && Object.entries(props.withdrawals.taxes).length > 0 && props.withdrawals.taxes !== undefined){
            if(withdrawal.status_id === PENDING || withdrawal.status_id === APPROVED || withdrawal.status_id === PROCESSED){
                if(props.withdrawals.taxes[withdrawal.id] !== undefined && Object.entries(props.withdrawals.taxes[withdrawal.id]).length > 0){
                    if (currentLocation.locationName || withdrawal.src === 'pending-viva-withdrawals') {
                        currentTax = props.withdrawals.taxes[withdrawal.id].tax + props.t('.00 Lei');
                    } else {
                        currentTax = '0' + props.t('.00 Lei');
                    }
                }
            }
        }

        /* 
            @@@@ - Resend SMS
            <Fragment><span>&nbsp;|&nbsp;</span><span className={'ResendCode'}>{props.t('Resend SMS Code')}</span></Fragment>


            @@@@ - Time Remaining
            {
                withdrawal.status_id === APPROVED
                ?   <div className={"TimeLeft"}>
                        {icons.timer}
                        <span>{getTimeRemainingHandler(1566588374)}</span>
                    </div>
                :   null
            }
        */
        return (
            <div className={"WithdrawalsContent"} key={withdrawal.id+withdrawal.src+withdrawal.date}>
                <div className={"GrayBox"}>
                    <div className={"WithdrawalsTop"}>
                        <div className={"WithdrawalsDetails"}>
                            <div className={"Date"}>
                                <Moment format={"DD / MM / YYYY"} unix>{withdrawal.date}</Moment>
                            </div>
                            <div>
                                <p>{currentLocation.locationName ? currentLocation.locationName : 'Online'}</p>
                                <span>&nbsp;|&nbsp;</span>
                            </div>
                            <div>
                                <p className={'WithdrawalsState State' + withdrawal.status_id}>{props.t(withdrawalStatus[withdrawal.status_id])}</p>
                                {
                                    withdrawal.status_id === APPROVED
                                    ?   <Fragment><span>&nbsp;|&nbsp;</span><span className={'TransactionID'}>ID-{withdrawal.transaction_id}</span></Fragment>
                                    : withdrawal.status_id !== APPROVED && withdrawal.status_id !== PENDING
                                        ?   <Fragment><span>&nbsp;|&nbsp;</span><span className={'ViewTransactions'} onClick={viewHistoryHandler}>{props.t('View')}</span></Fragment>
                                        :   null
                                }
                            </div>
                        </div>
                        <div className={"WithdrawalAmount"}>
                            {
                                withdrawal.status_id === PENDING || withdrawal.status_id === APPROVED || withdrawal.status_id === PROCESSED
                                ?   <p className={"Fail"}>{'-' + formatCurrency(withdrawal.amount) + ' Lei'}</p>
                                :   <p>{'+' + formatCurrency(withdrawal.amount) + ' Lei'}</p>
                            }
                            
                        </div>
                    </div>
                    {withdrawal.status_id === APPROVED && withdrawal.src === 'pending-withdrawals'
                        ? <div className="VerificationCode">
                            {!(props.verification && props.verification[withdrawal.id])
                                ? <Fragment>
                                    <h2 onClick={() => props.onRequestVerificationCode(withdrawal.id)}>
                                        {props.t('Show verification code')}
                                    </h2>
                                </Fragment> 
                                : <Fragment>
                                    {props.verification[withdrawal.id].remainingMinutes <= 0 
                                        ? <span>{props.t('Verification code (expires in ') + props.verification[withdrawal.id].remainingSeconds} sec)</span>
                                        : <span>{props.t('Verification code (expires in ') + props.verification[withdrawal.id].remainingMinutes} min)</span>
                                    }
                                    <p>{props.verification[withdrawal.id].validation_code}</p>
                                </Fragment>}
                            
                            </div> 
                        : null}

                    {withdrawal.status_id === PENDING || withdrawal.status_id === APPROVED || withdrawal.status_id === PROCESSED ? <div><p className="Tax">{props.t('Tax: ') + currentTax}</p></div> : null}
                    {
                        (withdrawal.status_id === PENDING || withdrawal.status_id === APPROVED )
                        && (currentLocation.locationName || withdrawal.src === 'pending-viva-withdrawals')
                        ?   <div className={"WithdrawalsBottom"}>
                                {withdrawal.status_id === APPROVED ? <button type="button" className="DirectionsButton" onClick={viewLocationHandler}>{icons.mapRoute}</button> : null}
                                <Button 
                                    className={"active"}
                                    full
                                    value={props.t('Partial Deposit')}
                                    click={() => handleOpenPopup('partial', withdrawal.amount, withdrawal.id, withdrawal.src)}
                                />
                                <button type="button" className="RemoveButton" onClick={() => handleOpenPopup('cancel', withdrawal.amount, withdrawal.id, withdrawal.src)}>{icons.trashBin}</button>
                            </div>
                        :   null
                    }
                </div>
            </div>
        );
    });
    return (
        <Fragment>
            {pageContent}
            <ConfirmPopup content={popupContent} close={handleClosePopup} />
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        verification: state.withdrawals.verificationData
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestTaxService: (taxType, amount, id) => dispatch(storeActions.requestTaxService(taxType, amount, id)),
        onRequestVerificationCode: (id) => dispatch(storeActions.requestVerificationCode(id)),
        onCleanVerificationCode: () => dispatch(storeActions.cleanVerificationCode()),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(WithdrawalStatus)));
