import React, { Component } from 'react';
import { connect } from 'react-redux';
import TitleBar from "../../../UI/TitleBar/TitleBar";
import './Withdraw.scss';
import { withTranslation } from "react-i18next";
import StepWizard from "react-step-wizard";
import Steps from "./Steps/Steps";
import * as storeActions from "../../../../store/actions";
import Icons from "../../../../assets/Icons/Icons";
import Utils from "../../../../utils/Utils";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router";
import Translate from "../../../../utils/Translate";


const backSteps = {
    1: false,
    2: 1,
    3:1,
    4:1,
    5:1,
    6:5,
    7:1,
    8:7,
}

class Withdraw extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.finished !== this.props.finished && this.props.finished) {
            this.props.history.push('/withdraw/success');
        }
    }

    componentWillUnmount() {
        this.props.amountReset();
        this.props.resetLocation();
        this.props.resetWithdraw();
    }

    backHandler() {
        if (backSteps[this.props.step] === false) {
            this.props.history.push('/');
        } else{
            this.props.setStep(backSteps[this.props.step]);
        }
    }
    t(text, placeholder) {
        return <Translate text={text} placeholder={placeholder} />
    }
    getContent() {
        let comp = [];
        let documentsOk = true;
        if (this.props.documents) {
            if (this.props.documents.documentsRequest) {
                let documents = this.props.documents.documentsRequest;
                for (let i = 0; i < documents.length; i++) {
                    if (documents[i].type === 1 && documents[i].status !== 8) {
                        documentsOk = false;
                        break;
                    }
                }
            }
        }
        if (this.props.profile.verified === 0) {
            comp.push(
                <div id={'withdraw-not-verified'} key={'withdraw-not-verified'}>
                    <div className="warning-icon">
                        {Icons.get('exWarning')}
                    </div>
                    <div className="AlertBox"><span>{this.props.t('Attention: Account not verified')}</span></div>
                    <h5>{this.props.t('Accordingly to legal requirements, please verify your identity prior to withdrawing any money.')}</h5>
                    <div className="content-withdraw">
                        <div className="buttons">
                            <Button fullWidth variant="contained" size="large" className={'later'} onClick={this.goToDocuments.bind(this)}>
                                {this.props.t('Verify')}
                            </Button>
                            <Button fullWidth variant="contained" size="large" className={'bonuses'} onClick={this.laterHandler.bind(this)}>
                                {this.props.t('Cancel')}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } else if (!documentsOk) {
            comp.push(
                <div id={'withdraw-not-verified'} key={'withdraw-not-verified'}>
                    <div className="warning-icon">
                        {Icons.get('exWarning')}
                    </div>
                    <div className="AlertBox"><span>{this.props.t('Attention: Account not verified')}</span></div>
                    <h5>{this.props.t("Your identity documents you provided are under review.")}</h5>
                    <div className="content-withdraw">
                        <div className="buttons">
                            <Button fullWidth variant="contained" size="large" className={'later'} onClick={this.goToDocuments.bind(this)}>
                                {this.props.t('Verify')}
                            </Button>
                            <Button fullWidth variant="contained" size="large" className={'bonuses'} onClick={this.laterHandler.bind(this)}>
                                {this.props.t('Cancel')}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.bonus || this.props.freeBets.length) {

            let total = this.props.bonus ? this.props.bonus : 0;
            this.props.freeBets.forEach(fb => {
                total += fb.count * (fb.amount_small === 1 ? fb.amount / 100 : fb.amount);
            });

            comp.push(
                <div id={'withdraw-bonus-active'} key={'withdraw-bonus-active'}>
                    <div className="warning-icon">
                        {Icons.get('exWarning')}
                    </div>
                    <h5>
                        {this.props.t('Attention: Active bonuses')}
                        <div><span>{this.props.t('Blocked withdrawal')}</span></div>
                    </h5>
                    <div className="content-withdraw">
                        <div className="item">
                            <div className="icon">
                                {Icons.get('moneyBag')}
                            </div>
                            <div className="title">
                                {this.props.t('Available')}
                            </div>
                            <div className="value">
                                {this.props.main}
                                <div className="currency">
                                    {Utils.formatCurrency(this.props.currency)}
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                {Icons.get('diamond')}
                            </div>
                            <div className="title">
                                {this.props.t('BONUS')}
                            </div>
                            <div className="value">
                                <span className={"bonus"}>
                                    {total}
                                    <div className="currency">
                                        {Utils.formatCurrency(this.props.currency)}
                                    </div>
                                </span>
                            </div>
                        </div>
                        <p>
                            {this.t("According to the Terms and Conditions, any withdrawal implies ${begin_red}forfeit${end_red} for bonus funds (${begin_red}-${bonus}${end_red}) and active bonuses.",
                                {
                                    begin_red: '<span class="red">',
                                    end_red: '</span>',
                                    bonus: total
                                }
                            )}
                        </p>
                        <p>
                            {this.props.t("The forfeit is permanent.")} <br />
                            {this.props.t("The bonuses cannot be reactivated, even by the subsequent cancellation of the withdrawal.")}

                        </p>

                        <div className="buttons">
                            <Button fullWidth variant="contained" size="large" className={'later'} onClick={this.laterHandler.bind(this)}>
                                {this.props.t('Withdraw later')}
                            </Button>
                            <Button fullWidth variant="contained" size="large" className={'bonuses'} onClick={this.goToBonus.bind(this)}>
                                {this.props.t('See bonuses')}
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } else {
            comp.push(<StepWizard key={'withdraw-wizard'}>
                <Steps name={'AvailableFunds'} />
                <Steps name={'Locations'} withdraw={true} />
                <Steps name={'ConfirmStep'} />
                <Steps name={'PaymentMethods'}/>
                <Steps name={'BankTransfer'} />
                <Steps name={'AddBankTransfer'} />
                <Steps name={'SkrillAccount'} />
            </StepWizard>);
        }
        return comp;
    }

    laterHandler() {
        this.props.history.push('/');
    }
    goToDocuments() {
        this.props.history.push('/profile/documents');
    }
    goToBonus() {
        this.props.history.push('/profile/wallet');
    }
    render() {
        let content = this.getContent();

        return (
            <div id={"withdraw-widget"} style={{ overflow: this.props.step === 2 ? 'hidden' : 'auto' }}>
                <TitleBar pageTitle={this.props.step === 2 ? this.props.t('Locations') : this.props.t('Withdraw')} backHandler={this.backHandler.bind(this)} />
                {content}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setStep: (step) => dispatch(storeActions.setWithdrawWizardStep(step)),
        hideGlobalWidgets: () => dispatch(storeActions.globalWidgets(false)),
        amountReset: () => dispatch(storeActions.getTax(0)),
        resetLocation: () => dispatch(storeActions.withdrawSetLocation(false)),
        resetWithdraw: () => dispatch(storeActions.resetWithdraw())
    };
};

const mapStateToProps = state => {
    return {
        opened: state.withdrawWizard.opened,
        step: state.withdrawWizard.step,
        bonus: state.wallet.bonus,
        freeBets: state.freeBets.freeBets,
        main: state.wallet.main,
        currency: state.wallet.currency,
        profile: state.profile,
        documents: state.documents,
        finished: state.withdrawWizard.finished,
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Withdraw)));
