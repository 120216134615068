import { put, delay } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';
import MarketingEvents from "../../utils/MarketingEvents";

export function* requestAccountSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.profile.ACCOUNT,
            method: 'post',
            data: {
                responseType: 'profile',
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Account response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Account response has no 'result' property`);
        }
        yield put(actions.receivedAccount(response.result));

        MarketingEvents.signUpCompleted({
            playerId: response.result.client_player_id
        })

        yield put(actions.setMarketingAgreements({
            ...response.result.marketing_agreement,
            sendToServer: false
        }));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* authRequestAccountSaga(action) {
    if (!action.hasOwnProperty('authType')) {
        return;
    }

    if (!['user', 'token'].includes(action.authType)) {
        return;
    }

    yield put(actions.requestAccount());
}

export function* requestLimitsSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.profile.RESPONSIBLY,
            method: 'get',
            data: {
                responseType: 'responsible-gaming',
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Limits response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Limits response has no 'result' property`);
        }

        yield put(actions.receivedLimits({ limits: response.result }));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* setNewLimitSaga(action) {
    const axios = ClientAPI.getInstance();
    const storeState = ClientAPI.getStore().getState();
    let limit_type = storeState.profile.limit_type;
    let limit_type_period = storeState.profile.limit_type_period;
    let limit_value = storeState.profile.limit_value;
    try {
        const response = yield axios({
            url: paths.profile.SET_LIMIT,
            method: 'post',
            data: {
                limit_type: limit_type,
                limit_type_period: limit_type_period,
                limit_value: limit_value,
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Set New Limit response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Set New Limit response has no 'result' property`);
        }

        if (Array.isArray(response.result) && response.result.length === 0) {
            yield delay(500);
            yield put(actions.requestLimits());
        }
    } catch (error) {
        console.log(error);
        return;
    }
}

/**
 * @deprecated
 * @param action
 * @returns {Generator<*, void, *>}
 */
export function* setSelfexclusionSaga(action) {
    const axios = ClientAPI.getInstance();
    const storeState = ClientAPI.getStore().getState();
    let limit_type = storeState.profile.limit_type;
    let limit_type_period = storeState.profile.limit_type_period;

    try {
        const response = yield axios({
            url: paths.profile.SET_LIMIT,
            method: 'post',
            data: {
                limit_type: limit_type,
                limit_type_period: limit_type_period,
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Set Selfexclusion response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Set Selfexclusion response has no 'result' property`);
        }

    } catch (error) {
        console.log(error);
        return;
    }
}

export function* selfExcludeSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.profile.SELF_EXCLUDE,
            method: 'post',
            data: action.data
        });

        if (!response) {
            throw new Error(`[ERROR] SelfExclude response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] SelfExclude response has no 'result' property`);
        }
        yield put(actions.receivedPasswordCheck(response.result));
    } catch (error) {
        yield put(actions.receivedPasswordCheck(false));
        console.log(error);
        return;
    }
}

export function* checkPasswordSaga(action) {
    const axios = ClientAPI.getInstance();
    const storeState = ClientAPI.getStore().getState();
    let username = storeState.profile.email;
    let password = storeState.profile.password;

    try {
        const response = yield axios({
            url: paths.authentication.AUTH,
            method: 'post',
            data: {
                username: username,
                password: password,
            }
        });

        if (!response.status) {
            console.log(`[ERROR] Check Password error`);
            return
        }

        if (!("OK" === response.status)) {
            console.log(`[ERROR] Check Password error`);
            return
        }

        yield put(actions.receivedPasswordCheck(true));

    } catch (error) {
        yield put(actions.receivedPasswordCheck(false));
    }
}

export function* changePasswordSaga(action) {
    const axios = ClientAPI.getInstance();
    const storeState = ClientAPI.getStore().getState();
    let currentPassword = storeState.profile.currentPassword;
    let newPassword = storeState.profile.newPassword;
    try {
        const response = yield axios({
            url: paths.profile.CHANGE_PASSWORD,
            method: 'post',
            data: {
                currentPassword: currentPassword,
                newPassword: newPassword,
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Change Password response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Change Password response has no 'result' property`);
        }

        yield put(actions.receivedChangePassword(response.result));

    } catch (error) {
        console.log(error);
        return;
    }
}

export function* checkPhoneSaga(action) {
    const axios = ClientAPI.getInstance();
    const storeState = ClientAPI.getStore().getState();
    let newPhone = storeState.profile.newPhone;
    try {
        const response = yield axios({
            url: paths.profile.CHECK_PHONE,
            method: 'post',
            data: {
                phone: newPhone,
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Check Phone response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Check Phone response has no 'result' property`);
        }

        // we don't need the type here!
        delete response.result.type;
        if (response.result.ResponseCode) {
            yield put(actions.receivedPhone(response.result));
        } else {
            yield put(actions.receivedPhone([]));
        }

    } catch (error) {
        console.log(error);
        return;
    }
}

export function* checkPhoneCodeSaga(action) {
    const axios = ClientAPI.getInstance();
    const storeState = ClientAPI.getStore().getState();
    let newPhone = storeState.profile.newPhone;
    let code = storeState.profile.code;
    try {
        const response = yield axios({
            url: paths.profile.CHECK_PHONE_CODE,
            method: 'post',
            data: {
                phone: newPhone,
                code: code,
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Check Phone Code response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Check Phone Code response has no 'result' property`);
        }

        yield put(actions.receivedPhoneCode(response.result));

    } catch (error) {
        console.log(error);
        return;
    }
}

export function* getChatInfoSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.profile.GET_CHAT_INFO,
            method: 'post',
            data: {
                platformType: window.config.platformType,
                responseType: 'get-chat-info',
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Check Phone Code response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Check chat response has no 'result' property`);
        }

        yield put(actions.receivedChatInfo(response));

    } catch (error) {

        return;
    }
}

export function* resendVerificationEmailSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.profile.RESEND_VERIFICATION_EMAIL,
            method: 'post',
        });

        if (!response) {
            throw new Error(`[ERROR] Resend Verification Email response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Resend Verification Email response has no 'result' property`);
        }

    } catch (error) {

        return;
    }
}

export function* sendSelfExclusionCodesSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.profile.SEND_SELF_EXCLUSION_CODES,
            method: 'post',
            type: action.notificationType ? action.notificationType : 0
        });

        if (!response) {
            throw new Error(`[ERROR] Resend Verification Email response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Resend Verification Email response has no 'result' property`);
        }

    } catch (error) {

        return;
    }
}