import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';

export function* requestDocumentsSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.documents.REQUEST_DOCUMENTS,
            method: 'post',
            data: {
                responseType: 'documents-preview',
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Documents response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Documents response has no 'result' property`);
        }

        yield put(actions.receivedDocuments(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestDocumentsUploadSaga(action) {
    const axios = ClientAPI.getInstance();
 
    let file = action.file;
    let type = action.docType;
    let documentID = action.documentID;
    let uploadForm = new FormData();

    uploadForm.append("type", type);
    uploadForm.append("id_row", documentID);
    uploadForm.append("documents[]", file);

    console.log(uploadForm);

    try {
        const response = yield axios({
            url: paths.documents.REQUEST_DOCUMENTS_UPLOAD,
            method: 'post',
            data: uploadForm,
        });

        if (!response) {
            throw new Error(`[ERROR] Documents Upload response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Documents Upload response has no 'result' property`);
        }

        yield put(actions.receivedDocumentsUpload(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestDocumentsMaxFileSizeSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.documents.REQUEST_DOCUMENTS_MAX_FILE_SIZE,
            method: 'get',
        });

        if (!response) {
            throw new Error(`[ERROR] Documents max file size response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Documents max file size response has no 'result' property`);
        }

        yield put(actions.receivedDocumentsMaxFileSize(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* authRequestDocumentsSaga(action) {
    if (!action.hasOwnProperty('authType')) {
        return;
    }

    if (!['user','token'].includes(action.authType)) {
        return;
    }

    yield put(actions.requestDocuments());
}