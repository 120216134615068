import React, { Component } from 'react';
import {connect} from "react-redux";

class ClaimBonus extends Component
{

    state= {
        events: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.player !== this.props.player && window.config.brand_name_short.toLowerCase() === 'winner') {
            this.setState({
                ...this.state,
                events: true
            })
        }
    }

    render() {
        if (!this.state.events) {
            return (
                <div></div>
            );
        }

        return (
            <iframe style={{"display":"none"}} src={"https://wlwinnerro.adsrv.eacdn.com/Processing/Pixels/Registration.ashx?Playerid=" + this.props.player.client_player_id + "&mid=2"}> </iframe>
        )
    }
}



const mapStateToProps = state => {
    return {
        player: state.profile,
    }
};

export default connect(mapStateToProps, null)(ClaimBonus);
