import * as actionTypes from '../actions/actionTypes';

let initialOktoState = {
    paymentCode: null,
    qrPayload: null,
    customer: {
        active_auto_exclusion: null,
        amount_can_deposit: null,
        account_verified: null,
        okto_terms_accepted: null,
        okto_terms_modified: null,
        okto_customer_date: null,

    },
}

const initialState = {
    loading: false,
    loadingAccessToken: false,
    loadingCardToken: false,
    access_token: null,
    amount: null,
    iframeLink: null,
    serverResponse: false,
    bonusError: false,
    okto: {...initialOktoState}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.paymentCheckout.REQUEST_PAYMENT_LINK:
            return {
                ...state,
                amount: action.amount,
                loading: true,
            };
        case actionTypes.paymentCheckout.RECEIVED_PAYMENT_LINK:
            return {
                ...state,
                iframeLink: action.response.link,
                loading: false,
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_TOKEN:
            return {
                ...state,
                loadingAccessToken: true,
            };
        case actionTypes.paymentCheckout.RECEIVED_VIVA_TOKEN:
            return {
                ...state,
                loadingAccessToken: false,
                access_token: action.data,
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_CARD_TOKEN:
            return {
                ...state,
                loadingCardToken: true,
            };
        case actionTypes.paymentCheckout.RECEIVED_VIVA_CARD_TOKEN:
            let cardData = null;
            if(action.data && action.data.result){
                cardData = action.data.result;
            }
            return {
                ...state,
                loadingCardToken: false,
                cardData: cardData
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.paymentCheckout.SEND_VIVA_CHARGE_TOKEN:
            let iframeLink = null;
            if(action.data && action.data.redirectToACSForm){
                iframeLink = action.data.redirectToACSForm;
            }
            return {
                ...state,
                loading: true,
                iframeLink: iframeLink
            };
        case actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN:
            let serverResponse = null;
            if(action.data.result && action.data.result.status){
                serverResponse = action.data.result.status;
            }

            if (action.data.result && action.data.result.ResponseCode) {
                serverResponse = `${action.data.result.ResponseMessage} (${action.data.result.ResponseCode})`;
            }

            if(action.data.result && action.data.result.http){
                serverResponse = "Something went wrong (HTTP_FAIL)";
            }

            if (!serverResponse) {
                serverResponse = 'success';
            }
            let bonusError = action.data.result.bonusError ? action.data.result.bonusError : false;
            return {
                ...state,
                loading: false,
                serverResponse: serverResponse,
                bonusError: bonusError
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN_WITH_CARD:
            let serverResponseWC = null;
            if(action.data.result && action.data.result.status){
                serverResponseWC = action.data.result.status;
            }

            if (action.data.response && action.data.response.data && action.data.response.data.message){
                serverResponseWC = action.data.response.data.message;
                if(action.data.response.status){
                    serverResponseWC = action.data.response.status + ": " + serverResponseWC;
                }
            }
            let bonusErrorWithCard = action.data.result.bonusError ? action.data.result.bonusError : false;
            return {
                ...state,
                loading: false,
                serverResponse: serverResponseWC,
                bonusError: bonusErrorWithCard
            };
        case actionTypes.paymentCheckout.RESET_PAYMENT_CHECKOUT:
            return  {
                ...state,
                loading: false,
                loadingAccessToken: false,
                loadingCardToken: false,
                access_token: null,
                amount: null,
                iframeLink: null,
                serverResponse: false
            };
        case actionTypes.paymentCheckout.OKTO_CREATE_PAYMENT_CODE:
            return {
                ...state,
                loading: true,
                okto: {...initialOktoState}
            }
        case actionTypes.paymentCheckout.OKTO_RECEIVED_PAYMENT_CODE:
            return {
                ...state,
                loading: false,
                okto: {
                    ...initialOktoState,
                    ...action.data
                }
            }
        case actionTypes.paymentCheckout.OKTO_RECEIVED_CUSTOMER:

            return {
                ...state,
                loading: false,
                okto: {
                    ...initialOktoState,
                    customer: {
                        ...initialOktoState.customer,
                        ...action.data
                    }
                },
            }
        case actionTypes.paymentCheckout.OKTO_GET_CUSTOMER:
            return {
                ...state,
                loading: true,
                okto: {
                    ...initialOktoState,
                },
            }
        case actionTypes.paymentCheckout.OKTO_RESET:
            return {
                ...state,
                okto: {
                    ...initialOktoState,
                }
            }
        case actionTypes.paymentCheckout.AIRCASH_REQUEST_PAYMENT_LINK:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};

export default reducer;