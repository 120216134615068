import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import "./main-view.scss";
import { getData } from "../utils/functions";
import DialogNickname from "../dialogs/dialog-nickname";
import DialogObjectives from "../dialogs/dialog-objectives";

const MainView = (props) => {
  const { match, missionsTournaments } = props;

  const [code, setCode] = React.useState(null);
  const [openNicknameDialog, setOpenNicknameDialog] = React.useState(false);
  const handleCloseNicknameDialog = () => {
    setOpenNicknameDialog(false);
  };

  const [openObjectivesDialog, setOpenObjectivesDialog] = React.useState(true);
  const handleCloseObjectivesDialog = () => {
    setOpenObjectivesDialog(false);
  };

  React.useEffect(() => {
    let path = "";
    if (match && match.params && match.params.path) {
      path = `/${match.params.path}`;
    }

    if (path && missionsTournaments) {
      const keys = Object.keys(missionsTournaments);
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (typeof missionsTournaments[key] !== "undefined" && missionsTournaments[key].url && missionsTournaments[key].url.toLowerCase() === path.toLowerCase()) {
          setCode(key);
        }
      }
    }
  }, [match, missionsTournaments]);

  if (!code || !missionsTournaments) return null;

  const data = missionsTournaments[code];
  console.log("*MISSIONS", data);

  let availWidth = window.screen.availWidth;
  let scale = 1;
  if (availWidth < 500) {
    scale = availWidth / 360;
  }


  return (
    <div className="treasure-maps-main-view 123">
      <div className="maps" style={{ "transform": `scale(${scale})` }}>
        <img src={getData(data, "meta.background_image", "")} alt="" className="map-data" />
        {
          getData(data, "meta.positions", []).map((pos, index) => {
            const style = { transform: `translate(${pos.left}px,${pos.top}px)` };
            return <div className={`marker ${pos.type}-marker`} key={index} style={style}>
              <img src={getData(data, `meta.${pos.type}_mission_marker`, "")} alt="" />
            </div>
          })
        }
      </div>
      {openNicknameDialog && <DialogNickname open={openNicknameDialog} onClose={handleCloseNicknameDialog} />}
      {openObjectivesDialog && <DialogObjectives open={openObjectivesDialog} onClose={handleCloseObjectivesDialog} />}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    missionsTournaments: state.config.missionsTournaments,
    authentication: state.authentication
  };
};

export default withTranslation()(connect(mapStateToProps)(MainView));