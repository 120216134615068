import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import "./RegisterFooter.scss";
import * as stateActions from "../../../store/actions";
import CircularProgress from '@material-ui/core/CircularProgress';
import Utils from "../../../utils/Utils";

class RegisterFooter extends Component {


    componentDidMount() {
        this.props.setCurrentStep(1);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.step !== this.props.step && this.props.step !== this.props.currentStep) {
            this.props.goToStep(this.props.step);
        }

        if (prevProps.signedUp !== this.props.signedUp && this.props.signedUp) {
            this.props.setCurrentStep(this.props.currentStep + 1);
        }
    }

    clickHandler() {
        if(!this.isButtonActive()) return;

        if(this.props.currentStep === 1) {
            window.localStorage.removeItem('paid');
            window.localStorage.removeItem('moid');
            window.localStorage.removeItem('lpid');
            window.localStorage.removeItem('phoneValidation');
            window.localStorage.removeItem('registerFields');
        }

        if (this.props.currentStep !== 3) {
            this.props.setCurrentStep(this.props.currentStep + 1);
            document.getElementsByClassName('content')[0].scrollTo({top:0, behavior: 'smooth'})
        }

        switch (this.props.currentStep) {
            case 2:
                this.props.sendSMS();

                break;
            case 3:
                this.props.signUp(true);
                break;
            default:
                break;
        }
        if (this.props.currentStep !== 3) {
            this.props.nextStep();
        }
    }

    sendSmsHandler(){
        this.props.sendSMS();
    }

    onMouseEnterHandler() {
        if (this.props.currentStep === 2) {
            this.props.handleCheckboxesErrors();
        }
    }

    countValidFields() {

        let counter = 0;
        let validated = this.props.validated;
        Object.keys(validated).forEach(function (key) {

            if (validated[key]) {
                counter++;
            }
        });

        return counter;
    }

    isButtonActive() {
        if (this.props.signUpInProgress) return false;

        let validFields = this.countValidFields();

        switch (this.props.currentStep) {
            case 1:
                return (validFields > 3);
            case 2:
                return (validFields > 9);
            case 3:
                return (validFields > 10);
            default:
                return false;
        }
    }
    setTimer() {
        let time = this.props.timer - 1;
        this.props.setTimer(time);
    }
    getTimer() {
        if (this.props.timer === false) return [];
        if (this.props.timer > 0) {
            Utils.delayedFunction("setTimer", this.setTimer.bind(this),1000);
            return (<p className={"timer"}>
                {this.props.t("You can resend a SMS Code in") + " " + this.props.timer + " " + this.props.t("seconds") }
            </p>)
        }

        return (<p className={"timer underlined"} onClick={this.sendSmsHandler.bind(this)}>
            {this.props.t("Click here to resend SMS Code")}
        </p>);

    }
    render() {
        let disabled = !this.isButtonActive();
        let timer = this.getTimer();
        return (

            <div className="register-footer">
                <div className={"nextSupport"}>
                    <Button variant="contained" disabled={disabled} size="large" color="primary" className={''} onMouseEnter={this.onMouseEnterHandler.bind(this)} onClick={this.clickHandler.bind(this)}>
                        {this.props.t("Next")} {this.props.signUpInProgress && <div className="loader"><CircularProgress color="primary" /></div>}
                    </Button>
                </div>
                {timer}
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCurrentStep: (step) => dispatch(stateActions.setCurrentStep(step)),
        handleCheckboxesErrors: () => dispatch(stateActions.checkboxesErrors()),
        sendSMS: () => dispatch(stateActions.sendSMS()),
        signUp: (oldVersion) => dispatch(stateActions.signUp(oldVersion)),
        setTimer: (time) => dispatch(stateActions.setTimer(time)),
    };
};
const mapStateToProps = state => {
    return {
        validated: state.register.validated,
        fields: state.register.fields,
        step: state.register.currentStep,
        signedUp: state.register.signedUp,
        signUpInProgress: state.register.signUpInProgress,
        timer: state.register.SMSTimer
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RegisterFooter));


