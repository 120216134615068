import {withTranslation} from "react-i18next";

class CNPValidator
{
    static validate(string = undefined) {

        if (typeof string === "undefined" || string.length !== 13) {
            return {
                type: 'error',
                text: 1
            }
        }
        if(isNaN(string)) {
            return {
                type: 'error',
                text: 2
            }
        }
        let aa = string.substr(1,2);
        let currentYear = new Date().getFullYear() - 2000;
        if (parseInt(aa) < currentYear) {
            aa = "20"+aa;
        } else {
            aa = "19"+aa;
        }
        let ll = string.substr(3,2);
        let zz = string.substr(5,2);
        let birthday = new Date(aa + '/' + ll + '/' + zz);
        if ( birthday === "Invalid Date") {
            return {
                type: "error",
                text: "CNP-ul este invalid",
            }
        } else {
            let ageDifMs = Date.now() - birthday.getTime(); // age difference in ms
            let ageDate = new Date(ageDifMs); // ms from epoch
            if (ageDate.getUTCFullYear() - 1970 < 18) {
                return {
                    type: "error",
                    text: 3
                }
            }
        }

        // validate control digit
        let key = "279146358279";
        let sum = 0;
        for (let i = 0; i < 12; i++) {
            sum = parseInt(sum,10) + parseInt(string[i],10) * parseInt(key[i],10);
        }
        let d = sum % 11;
        d = (d !== 10 ? d : 1);
        if (d !== parseInt(string[12],10)) {
            return {
                type: "error",
                text: "CNP-ul este invalid",
            }
        }

        return {
            type: "success"
        }
    }
}

export default CNPValidator;
