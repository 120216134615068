import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import PersonIcon from '@material-ui/icons/Person';
import {Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import * as storeActions from "../../../../../store/actions";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Utils from "../../../../../utils/Utils";
import Icons from '../../../../../assets/Icons/Icons';
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import PaymentMethods from "./PaymentMethods";

const BUTTONS = [20,30,50,100,150,200];
const MINIMUM_WITHDRAWAL_VALUE = typeof window.config.MINIMUM_WITHDRAWAL_VALUE !== "undefined" ? window.config.MINIMUM_WITHDRAWAL_VALUE : 20;
const MAXIMUM_WITHDRAWAL_VALUE = typeof window.config.MAXIMUM_WITHDRAWAL_VALUE !== "undefined" ? window.config.MAXIMUM_WITHDRAWAL_VALUE : 9000;
const CASHIN_LOCATION = 1;
const CASHIN_ONLINE = 2;
const CASHIN_VIVA = 3;
const CASHIN_NEW_PAYMENT_METHOD = 4;

class AvailableFunds extends Component {

    state = {
        amountShown: 0,
        smallButtons: false,
        checkoutPage: false,
        taxPage: 0,
        selectedCard: false,
        loading: false,
        nextStepSelectedPayment: false,
    };

    componentDidMount() {
        this.props.getCardTokens();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.withdrawalCheckout.loading && this.props.withdrawalCheckout.iframeLink) {
            if(this.props.withdrawalCheckout.iframeLink){
                window.location.href = this.props.withdrawalCheckout.iframeLink;
            }
        } else if(prevProps.cardData !== this.props.cardData) {

        }
    }

    getMaximumValue(){
        return this.props.maximum < MAXIMUM_WITHDRAWAL_VALUE ? this.props.maximum : MAXIMUM_WITHDRAWAL_VALUE;
    }

    onChangeHandler(e) {
        let field;

        if (typeof e.target === "undefined") {
            field = e;
        } else {
            field = e.target.value;
        }

        field = parseInt(field);

        if (isNaN(field)) {
            field = 0;
        }

        if (field <= this.getMaximumValue()) {

            this.props.amountChanged(field);

        } else {
            this.props.amountChanged(this.getMaximumValue());
        }
    }

    getButtons() {
        let comp = [];
        let currentValue = this.props.amount;
        BUTTONS.forEach((val,key) => {
            comp.push(
                <Grid item xs={4} key={"withdraw_buttons_"+ key}>
                    <Button className={parseInt(currentValue) === val ? "active" : ""} disabled={this.getMaximumValue() < val} onClick={this.onChangeHandler.bind(this,val)}>{val}</Button>
                </Grid>
            );
        });

        return comp;
    }

    clickHandler() {
        let skipLocation = this.props.skipLocation;
        if (this.state.taxPage === CASHIN_VIVA || this.state.taxPage === CASHIN_ONLINE) {
            skipLocation = true;
        }

        if (skipLocation) {
            let nextStep = 3;
            this.selectPaymentMethodStep(nextStep);
            this.props.skipLocationStep(false);
        } else {
            let nextStep = 2;
            this.selectPaymentMethodStep(nextStep);
        }
    }

    legacySelectPaymentMethodStep(nextStep) {
        this.clickHandler();
    }

    selectPaymentMethodStep(nextStep) {

        this.props.setStep(nextStep);
        this.props.goToStep(nextStep);
        this.setState({
            ...this.state,
            taxPage: 0,
        });
    }

    clickOnlineHandler() {


        if ((this.props.cardData && this.props.cardData.length && window.config && window.config.vivaEnabled === '1') || (window.config && window.config.disable_safecharge === '1')) {
            this.taxPageHandler(CASHIN_VIVA);
            this.clickHandler();
        } else {
            this.setState({
                ...this.state,
                loading: true,
                checkoutPage: true,
            })
            this.props.onRequestWithdrawalLink(this.props.amount);
        }
        this.setState({
            ...this.state,
            loading: false,
            checkoutPage: false,
            taxPage: 0
        })
    }

    onBlur() {
        this.setState({
            ...this.state,
            amountShown: 0,
            smallButtons: false
        })
    }

    onFocus() {
        this.setState({
            ...this.state,
            amountShown: '',
            smallButtons: true
        })
    }

    getValue() {
        return this.props.amount === 0 ? this.state.amountShown : this.props.amount;
    }

    taxPageHandler(type,nextStep) {
        if(type){
            this.setState({
                ...this.state,
                taxPage: type,
                nextStepSelectedPayment: nextStep ?? false
            })
        }
    }

    withdrawAction(page){
        if(page === CASHIN_LOCATION){
            this.setState({
                ...this.state,
                taxPage: 0,
            });
            this.clickHandler();
        } else if(page === CASHIN_ONLINE){
            this.clickOnlineHandler();
        } else if (page === CASHIN_NEW_PAYMENT_METHOD) {
            this.selectPaymentMethodStep(this.state.nextStepSelectedPayment);
        }
    }

    // safeChargeWithdraw() {
    //     this.setState({
    //         ...this.state,
    //         loading: true
    //     })
    //     this.props.onRequestWithdrawalLink(this.props.amount);
    // }

    render() {
        let buttons = this.getButtons();
        let value = this.getValue();
        let tax = (<h3>0.00 <small>Lei</small></h3>);

        if(this.props.tax){
            tax = (<h3>-{this.props.tax.toFixed(2)} <small>Lei</small></h3>);
        }
        if(this.state.taxPage === 0){
            return (
                <div>
                    <div className="content">
                        <div className="field">
                            <FormControl>
                                <PersonIcon className={"leftIcon"} />
                                <Input value={value} type={"tel"} pattern={"\d*"}  onBlur={this.onBlur.bind(this)} onFocus={this.onFocus.bind(this)} onChange={this.onChangeHandler.bind(this)}/>
                                <FormHelperText>{this.props.t("Withdraw value") + " (Lei)"}</FormHelperText>
                                <FormHelperText error={true}>
                                    {Utils.t('Minimum withdrawal: ${minVal} lei',{minVal:MINIMUM_WITHDRAWAL_VALUE})}
                                </FormHelperText>
                                <FormHelperText error={true}>{this.props.t("Maximum value is") + " " + parseInt(this.getMaximumValue()) +" (Lei)"}</FormHelperText>
                            </FormControl>
                        </div>
                        <Grid container >
                            <Grid item xs={12} className={'buttons' + (this.state.smallButtons ? ' small-buttons' :'')}>
                                <Grid container >
                                    {buttons}
                                </Grid>
                            </Grid>
                            <PaymentMethods
                                buttonsDisabled={this.props.amount < MINIMUM_WITHDRAWAL_VALUE || this.state.checkoutPage}
                                legacyLocationClickHandler={()=> this.taxPageHandler(CASHIN_LOCATION)}
                                legacyOnlineClickHandler={()=> this.taxPageHandler(CASHIN_ONLINE)}
                                selectPaymentMethodStep={(nextStep)=> this.taxPageHandler(CASHIN_NEW_PAYMENT_METHOD,nextStep)}
                                currentStep={this.props.currentStep}
                            />
                        </Grid>
                    </div>
                </div>
            );
        }else{
            if (this.state.taxPage === CASHIN_VIVA) {
                let loading = !this.state.selectedCard;
                return (
                    <div>

                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="amount">{this.props.t('Deposited amount')}</InputLabel>
                            <Input
                                id="amount"
                                type='text'
                                disabled={true}
                                value={this.props.amount + ' Lei'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => this.setState({...this.state,taxPage: 0,checkoutPage:false})}>
                                            <EditIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <div className="WithdrawalButton-viva">
                            <Button
                                className={'btn-danger'}
                                disabled={loading}
                                onClick={this.vivaWithdraw.bind(this)}
                            >{ (loading ? <CircularProgress className={'Loading'} /> : "" )}{this.props.t('Cash-in')}</Button>
                        </div>
                    </div>
                )
            }
            return (
                <div className="TaxPage">
                    <div className="warning-icon">
                        {Icons.get('exWarning')}
                    </div>
                    <h5>{this.props.t("Attention: Withholding taxes for withdrawals")}</h5>
                    <div className="TaxBox">
                        <span>{this.props.t("State tax")}</span>
                        {tax}
                    </div>
                    <div className="LegalText">
                        <p>
                            {this.props.t("According to Government Ordinance no. 16/2022, the Organizer has the obligation to withhold and remit, on behalf of the participants, the tax related to the income made by individuals as a result of participating in online gambling, depending on the value of the transaction, starting with the minimum tax threshold of 3%.")}
                        </p>
                        <p className="TaxLink" onClick={() => this.props.history.push('/customer-support/payment-methods')}>{this.props.t("Tax and fees information")}</p>
                    </div>
                    <div className="content-withdraw">
                        <div className="buttons">
                            {this.state.loading && <CircularProgress/>}
                            <Button  fullWidth={true} variant="contained" size="large" className={'firstButton' + (this.state.loading ? 'disabled': "" )} onClick={() => this.props.history.push('/')}>
                                {this.props.t('Withdraw later')}
                            </Button>
                            <Button fullWidth={true} variant="contained" size="large" className={'secondButton' + (this.state.loading ? 'disabled': "" )} onClick={() => this.withdrawAction(this.state.taxPage)}>
                                {this.props.t('Pay the fee and continue')}
                            </Button>
                            {/*{(this.props.cardData && this.props.cardData.length !== 0 && window.config && window.config.vivaEnabled === '1') && (window.config.disable_safecharge !== '1') && <Button  fullWidth={true} variant={"contained"} size={"large"} className={"old-method" + (this.state.loading ? 'disabled': "" )} onClick={this.safeChargeWithdraw.bind(this)}>*/}
                            {/*    {this.props.t("Old method")}*/}
                            {/*</Button> }*/}
                        </div>

                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        amountChanged: (amount) => dispatch(storeActions.getTax(amount)),
        setStep: (step) => dispatch(storeActions.setWithdrawWizardStep(step)),
        skipLocationStep: (skip) => dispatch(storeActions.skipLocation(skip)),
        onRequestWithdrawalLink: (amount) => dispatch(storeActions.requestWithdrawalLink(amount)),
        getCardTokens: () => dispatch(storeActions.requestVivaCardToken()),
        requestVivaWithdraw: (token,amount) => dispatch(storeActions.requestVivaWithdraw(token,amount))
    };
};

const mapStateToProps = state => {
    return {
        amount: state.withdrawWizard.amount,
        tax: state.withdrawWizard.taxes,
        maximum: state.wallet.main,
        skipLocation: state.withdrawWizard.skipLocation,
        withdrawalCheckout: state.withdrawalCheckout,
        cardData: state.paymentCheckout.cardData,
        loadingCards: state.paymentCheckout.loadingCardToken
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(AvailableFunds)));
