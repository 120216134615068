import React, { useEffect } from 'react';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import RecentWinners from './../../Jackpot/RecentWinners';
import Game from '../../CasinoGames/sections/Game/Game';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Collection.scss';
import TitleBar from '../../UI/TitleBar/TitleBar';
import Button from '../../UI/Button/Button';

import JackpotHeaderV2 from '../../Jackpot/HeaderV2';
import WinHistory from '../../Jackpot/WinHistory';

const Collection = (props) => {
    const { t } = useTranslation();

    let pType = 'cazino';
    let pCode = 'collection';

    const [showModal, setShowModal] = React.useState(false);

    useEffect(() => {
        props.pageType(pType);
        props.pageCode(pCode);
        props.isRequestGameCollections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backHandler = () => {
        props.history.push("/cazino/jocuri");
    };

    const launchHandler = (gameItem) => {
        props.openGame(typeof gameItem.game_id !== "undefined" ? gameItem.game_id : gameItem.id );
        props.setGame(gameItem);
    };

    const getCollection = () => {
        let mainContent = <CircularProgress className={'Loading'} />;
        let gameContent = [];
        let errorContent = (
            <div className="CollectionContainer">
                <TitleBar pageTitle={'Collection Unavailable'} backHandler={backHandler} />
                <div className="ErrorContainer">
                    <h1 className={"ErrorTitle"}>Oops!</h1>
                    <p className={"ErrorContent"}>{t('The game collection you are looking for is temporarily unavailable. Please try again later.')}</p>
                    <Button className="BackButton gradient" value={t('BACK TO THE CASINO')} click={backHandler} />
                </div>
            </div>
        );
        let collection = '';
        if (!props.games.loadingGameCollections && props.games.gameCollections && props.games.gameCollections.length > 0) {
            let collectionID = window.location.pathname.replace("/cazino/collection/", "");
            if (collectionID !== "undefined" && collectionID !== '' && !isNaN(collectionID)) {
                let collections = [];
                Object.values(props.games.gameCollections).forEach((item, index) => {
                    collections[item.id] = item;
                });
                if (collections[collectionID]) {
                    collection = collections[collectionID];

                    let jackpot = null;
                    let jackpotHistory = null
                    if (typeof collection.jackpot_id !== "undefined" && collection.jackpot_id) {
                        jackpot = (
                            <JackpotHeaderV2
                                integration={"mobile"}
                                jackpotId={collection.jackpot_id}
                                collectionId={collection.id}
                                front={false}
                                onClick={() => setShowModal(true)}
                                name={"EGT"}
                                value={props.jackpot.maxValue}
                                jackpot={props.jackpot}
                                currency={props.jackpot.currency}
                            />
                        );

                        jackpotHistory = (
                            <WinHistory
                                show={showModal}
                                jackpotId={collection.jackpot_id}
                                closeModal={() => setShowModal(false)}
                                name={"EGT Spades"}
                            />
                        );
                    }

                    if (collection.items) {
                        for (let i = 0; i < collection.items.length; i++) {
                            gameContent[i] = (
                                <div className={"GameItem" + (i === 0 ? ' FirstGame' : '')} key={i}>
                                    <Game item={collection.items[i]} launchHandler={launchHandler} />
                                </div>
                            )
                        }
                        // gameContent.splice(3, 0, (
                        //     <div className={"GameItem JackpotWidget"} key={collection.items.length + 1}>
                        //         <RecentWinners winners={props.jackpot.latestWinners} style={{ marginBottom: 0 }} />
                        //     </div>
                        // ));
                    }
                    mainContent = (
                        <div className="CollectionContainer">
                            <TitleBar pageTitle={collection.title} subTitleAmount={collection.items.length} backHandler={backHandler} />
                            {jackpot}
                            <div className={"GamesContainer"}>
                                {gameContent}
                            </div>
                            {jackpotHistory}
                        </div>
                    )
                } else {
                    mainContent = errorContent;
                }
            } else {
                mainContent = errorContent;
            }
        }

        return mainContent;
    }

    return (
        <div className={"CollectionScreen"}>
            <div className={props.application.topMenuHeight}></div>
            {getCollection()}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        application: state.application,
        games: state.games,
        jackpot: state.jackpot,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        isRequestGameCollections: () => dispatch(storeActions.requestGameCollections()),
        openGame: (gameId) => dispatch(storeActions.openGameLauncher(gameId)),
        setGame: (gameObject) => dispatch(storeActions.setGameOpened(gameObject)),
        pageType: (pType) => dispatch(storeActions.setPageType(pType)),
        pageCode: (pCode) => dispatch(storeActions.setPageCode(pCode)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collection));