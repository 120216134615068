import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import Icons from '../../../assets/Icons/Icons';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import './UseNewPaymentMethod.scss';


const UseNewPaymentMethod = (props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const newPaymentMethodHandler = () => {
        setLoading(true);
        props.newPaymentHandler();
    }

    const closeHandler = () => {
        setLoading(true);
        props.closeHandler();
    }

    const oldPaymentMethodHandler = () => {
        setLoading(true);
        props.oldPaymentHandler();
    }

    return (
       <Grid container id={'new-payment-step'}>
           <Grid item xs={12} className={'header'}>
                   {Icons.get('questionmarkComment')}
                   <h2>{t("New vs old payments")}</h2>
                   <div className="subtitle">{t("Benefits of each")}</div>
           </Grid>
           <Grid item xs={12} className={'table'}>
               <Grid container className={'table-row'}>
                   <Grid item xs={6} className={'left'}> </Grid>
                   <Grid item xs={3} className={'center'}>
                       {t("NEW method")}
                   </Grid>
                   <Grid item xs={3} className={'right'}>
                       {t("OLD method")}
                   </Grid>
               </Grid>
               <Grid container className={'table-row'}>
                   <Grid item xs={6} className={'left'}>
                       {Icons.get('bullet_point')} {t("Card deposits & withdrawals")}
                   </Grid>
                   <Grid item xs={3} className={'center'}>
                       <div>
                           {Icons.get('green_checkmark')}
                       </div>
                   </Grid>
                   <Grid item xs={3} className={'right'}>
                       <div>
                           {Icons.get('green_checkmark')}
                       </div>
                   </Grid>
               </Grid>
               <Grid container className={'table-row'}>
                   <Grid item xs={6} className={'left'}>{Icons.get('bullet_point')} {t("Auto-approved withdrawals! Receive your money in less than 1 MINUTE")}</Grid>
                   <Grid item xs={3} className={'center'}>
                       <div>
                           {Icons.get('green_checkmark')}
                       </div>
                   </Grid>
                   <Grid item xs={3} className={'right'}>
                       <div>{Icons.get('red_close')}</div>
                   </Grid>
               </Grid>
               <Grid container className={'table-row'}>
                   <Grid item xs={6} className={'left'}>{Icons.get('bullet_point')} {t("Cancel withdrawals at your convenience")}</Grid>
                   <Grid item xs={3} className={'center'}>
                       <div>
                           {Icons.get('green_checkmark')}
                       </div>
                   </Grid>
                   <Grid item xs={3} className={'right'}>
                       <div>{Icons.get('red_close')}</div>
                   </Grid>
               </Grid>
               <Grid container className={'table-row'}>
                   <Grid item xs={6} className={'left'}>{Icons.get('bullet_point')} {t("New exclusive bonuses")}</Grid>
                   <Grid item xs={3} className={'center'}>
                       <div>
                           {Icons.get('green_checkmark')}
                       </div>
                   </Grid>
                   <Grid item xs={3} className={'right'}>
                       <div>{Icons.get('red_close')}</div>
                   </Grid>
               </Grid>
           </Grid>
           {props.newPaymentHandler && <Grid item xs={12} className={'actions'}>
               <Grid container>
                   <Button fullWidth variant="contained" size="large"  className={'new'} onClick={newPaymentMethodHandler}>
                       {loading ? <CircularProgress className={'Loading'} /> :  t("New method")}
                   </Button>
               </Grid>
           </Grid>}
           {props.oldPaymentHandler && <Grid container>
               <Button fullWidth variant="contained" size="large"  className={'old'} onClick={oldPaymentMethodHandler}>
                   {loading ? <CircularProgress className={'Loading'} /> : t("Continue with old method")}
               </Button>
           </Grid>}
           {props.closeHandler && <Grid item xs={12} className={'actions'}>
               <Grid container>
                   <Button fullWidth variant="contained" size="large"  className={'new'} onClick={closeHandler}>
                       {loading ? <CircularProgress className={'Loading'} /> :  t("Close")}
                   </Button>
               </Grid>
           </Grid>}
       </Grid>
   );




};

export default withTranslation()(UseNewPaymentMethod);