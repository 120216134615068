import axios from 'axios';
import getStore from '../store';
import { livePlayerLogin } from '../store/actions/live';
import { prematchConnectTicketSocket } from '../store/actions/prematch';
import { authSuccess } from '../store/actions/auth';
import { appPlayerInitialize } from '../store/actions/app';
import { ticketOpenedListRequest } from '../store/actions/tickets';
//import { liveAuthSuccess, prematchAuthSuccess } from "../store/actions/auth";
import { getBetsState } from '../store/selectors/betData';

import { sportsbookTokenUrl, nSoftAuth } from './config/nsoft';
import { uuidv4 } from '../utils';

let authInProgress = false;

async function doAuth() {
	if (authInProgress) {
		console.log('auth in progress already');
		return;
	}

	authInProgress = true;

	const store = getStore();
	const state = store.getState();

	console.log('NSoft auth', state);

	const bst = getBetsState(state);

	if (bst.auth.details !== null) {
		console.log('already NSoft authenticated');
		authInProgress = false;
		return;
	}

	try {
		/*
		const bearer = await axios.post(mobileUrl + "/api/auth/login", {
			username: username,
			password: password
		});
		*/

		console.log('nsoft auth', state.authentication.access_token);

		const sm = await axios.get(sportsbookTokenUrl + '/sportsbook-token', {
			//withCredentials: true,
			headers: {
				Authorization: 'Bearer ' + state.authentication.access_token
				//"Access-Control-Allow-Origin": "*",
				//"X-Requested-With": "XMLHttpRequest"
			}
		});

		console.log('sm', sm.data);

		/*
		axios
			.post(
				smLiveUrl + "/users/b2b/authenticate.json?requestUuid=" + uuidv4(),
				{
					token: sm.data.tempToken,
					id: sm.data.id,
					authStrategy: "tempToken"
				},
				{
					headers: {
						"SEVEN-TP-TOKEN": sm.data.tempToken,
						"HTTP-X-SEVEN-CLUB-UUID": liveClubUuid
					}
				}
			)
			.then(ns => {
				console.log("live ns", ns);

				const data = { ...ns.data };
				data.accessToken = ns.headers["access-token"];
				data.tpToken = ns.headers["x-nsft-seven-tp-token"];

				console.log("NSoft live auth", data);

				store.dispatch(liveAuthSuccess(data));
				store.dispatch(livePlayerLogin(data.Uuid));
			})
			.catch(err => {
				console.log("failed to get NSOft live token", err);
			});
		*/

		const { headers, data } = await axios.post(
			nSoftAuth.serviceUrl + '/users/b2b/authenticate.json?requestUuid=' + uuidv4(),
			{
				token: sm.data.tempToken,
				id: sm.data.id,
				authStrategy: 'tempToken'
			},
			{
				headers: {
					'SEVEN-TP-TOKEN': sm.data.tempToken,
					'HTTP-X-SEVEN-CLUB-UUID': nSoftAuth.companyUuid
				}
			}
		);

		console.log('prematch data', data);

		data.accessToken = headers['access-token'];
		data.smToken = sm.data.tempToken;
		data.tpToken = headers['x-nsft-seven-tp-token'];
		data.tpUserID = sm.data.id;

		console.log('NSOft prematch auth', data);

		//store.dispatch(prematchAuthSuccess(data));

		store.dispatch(authSuccess(data));

		store.dispatch(appPlayerInitialize());
		store.dispatch(livePlayerLogin(data.Uuid));
		store.dispatch(prematchConnectTicketSocket(data.Uuid));
		store.dispatch(ticketOpenedListRequest());
	} catch (e) {
		console.log('error', e); //util.inspect(e, false, null, true));
	}

	authInProgress = false;
}

export const auth = () => {
	setTimeout(() => {
		doAuth();
	}, 0);
};
