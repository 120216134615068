import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import * as storeActions from "./../../store/actions";
import Icons from "../../assets/Icons/Icons";
import { Spring } from 'react-spring/renderprops';
import './TopMenu.scss';
import Button from "@material-ui/core/Button";
import WalletManagement from "./WalletManagement/WalletManagement";

import camera from "./assets/photo-camera.svg";
import TicketCheckModal from "../../bets/components/BetSlip/TicketCheckModal";
import TicketLottoCheckModal from "../../lotto/components/BetSlip/TicketCheckModal";
import { ticketCheckCode as lottoTicketCheckCode } from "../../lotto/store/actions/tickets";
import { ReactComponent as WinnerIcon } from "./assets/hamburger-menu-winner.svg";
import TicketsWidget from "../../bets/components/TicketsWidget";

import WFSeasonWidget from "../../bets/components/WFSeasonWidget";
import WinnerFunDrawer from "../../winner-fun/views/WinnerFunDrawer";

import PromoWidget from "./PromotionsButton";
import LayoutMessages from '../LayoutMessages/LayoutMessages';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    flex: {
        flex: 1
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    personButton: {
        marginLeft: theme.spacing(0),
        "&.flexDir .MuiIconButton-label": {
            flexDirection: "column"
        }
    },
    toolbarMargin: window.config.showTopMessageWebsiteSwitcher !== '1' ? theme.mixins.toolbar : {minHeight: '104px'},
    appTopToolbar: {
        paddingRight: "4px"
    }
});

class TopMenu extends Component {
    constructor(props) {
        super(props);

        this.topFrom = -105;
        this.topTo = 0;

        this.icons = {
            eldorado: (<img src={window.location.protocol + "//" + window.config.cdn_address + window.config.MOBILE_BRAND_LOGO} alt='logo' />),//Icons.get('casinoLogo', `casinoLogo ${this.props.classes.toolbarMargin}`),
            account: Icons.get('account', 'account accountIcon'),
            wallet: Icons.get('wallet', 'wallet walletIcon openLeftMenuIcon'),
            hamburgerIcon: Icons.get('hamburgerSimple', 'openLeftMenuIcon'),
        };

        this.pagesWithMenu = [
            'jocuri',
            'jackpot',
            'virtuale',
            'table-games',
            'home',
            'test',
            'collection',
            'cazino_live'
        ];
    }

    state = {
        topFrom: this.topFrom,
        topTo: this.topTo,
        position: 'fixed',
        openCheckModal: false,
        openLottoCheckModal: false,

        // winner-fun specific toggles
        openWinnerFun: false,
        isWinnerFun: false,
        showScan: true,
    }

    componentDidMount() {
        this.props.setMenuHeight(this.props.classes.toolbarMargin);

        if (!this.pagesWithMenu.includes(this.props.application.pageCode)) {
            this.setState({
                topFrom: -105,
                topTo: -105,
                position: 'relative'
            });
        }
    }

    componentDidUpdate(prevProps) {
        let topFrom = -105;
        let topTo = 0;

        if (!this.pagesWithMenu.includes(this.props.application.pageCode)) {
            topFrom = 0;
            topTo = -105;
        }

        // because navigation inside/to bets doesn't change application.pageCode
        // we need to detect in another way if we're in bets
        if (
            window.location.pathname.indexOf("/pariuri") > -1 ||
            window.location.pathname.indexOf("/winner-fun") > -1 ||
            window.location.pathname.indexOf("/nsoft") > -1 ||
            window.location.pathname === "/cazino_live" ||
            window.location.pathname.indexOf("/lobby/") > -1 ||
            window.location.pathname.indexOf("/lotto") > -1) {
            topFrom = -105;
            topTo = 0;
        }

        if (
            window.config.rootPaths &&
            window.config.rootPaths.casino &&
            window.location.pathname.indexOf(`/${window.config.rootPaths.casino}`) > -1
        ) {
            topFrom = -105;
            topTo = 0;
        }

        if (
            window.config.rootPaths &&
            window.config.rootPaths.casino &&
            window.location.pathname.indexOf(`/${window.config.rootPaths.casino}/promotii`) > -1
        ) {
            topFrom = 0;
            topTo = -105;
        }

        if (window.location.pathname.indexOf(`/cazino/promotii`) > -1) {
            topFrom = 0;
            topTo = -105;
        }

        if (this.state.topTo === topTo) {
            return;
        }

        this.setState({
            topFrom: topFrom,
            topTo: topTo,
            position: 'fixed',
            isWinnerFun: window.location.pathname.indexOf("/winner-fun") > -1 ? true : false,
            showScan: window.location.pathname === "/" || window.location.pathname.indexOf("/pariuri") > -1 || window.location.pathname.indexOf("/lotto") > -1
        });
    }

    handleCheckModalClose = (ticketSerial) => {
        const newState = {
            openCheckModal: false,
            openLottoCheckModal: false,
        };

        if (ticketSerial) {
            console.log("ticketSerial", ticketSerial);
            newState.openLottoCheckModal = true;
            this.props.lottoTicketCheckCode(ticketSerial);
        }
        this.setState(newState);
    }

    handleTicketCodeCheck = () => {
        this.setState({
            openCheckModal: true
        });
    }

    handleWinnerFunModal = () => {
        this.setState({
            openWinnerFun: true,
        });
    }

    handleWinnerFunModalClose = () => {
        this.setState({
            openWinnerFun: false,
        });
    }

    logoClickHandler() {
        this.props.history.push("/");
    };

    connectHandler() {
        this.props.history.push("/connect");
    };

    registerHandler() {
        this.props.history.push("/register");
    };

    logoutHandler() {
        this.props.logout();
    }
    openRightMenu() {
        // this.props.openRightMenu()
        // this.props.getWallet();
        this.props.history.push("/profile/menu");
    }

    goToWallet(e) {
        this.props.history.push('/profile/wallet');
    }

    goToDeposit(e) {
        this.props.isDeposit();
        this.props.history.push('/deposit');
    }

    showWallet() {
        this.props.toggleProfile(!this.props.menu.showProfileSection);
    }


    rightButton() {
        const ticketsEnabled = window.config && (
            window.config.betsEnabled === "1" ||
            window.config.virtualsEnabled === "1" ||
            window.config.nsoftEnabled === "1" ||
            window.config.lottoEnabled === "1"
        );
        let scanEnabled = window.config && (
            window.config.betsEnabled === "1" ||
            window.config.nsoftEnabled === "1" ||
            window.config.lottoEnabled === "1"
        ) && window.config.nsoftTheme !== "Excel";

        if (window.config.hideTicketSearch === "1") scanEnabled = false;

        if ("user" === this.props.authentication.auth_type) {
            let profileMale = `${window.config.front_url}/img/mobile/profile/icon_profile_male.svg`;
            let profileFemale = `${window.config.front_url}/img/mobile/profile/icon_profile_female.svg`;
            let avatarURL = profileMale;
            if (typeof this.props.profile !== "undefined") {
                if (typeof this.props.profile.nin !== 'undefined' && this.props.profile.nin !== null) {
                    avatarURL = this.props.profile.nin[0] % 2 ? profileMale : profileFemale;
                }
            }

            let winnerFun = this.state.isWinnerFun ? true : false;
            if (!this.state.showScan) scanEnabled = false;

            return (
                <div className={'grouped-buttons'}>
                    <WalletManagement onWinnerFunDrawer={this.handleWinnerFunModal} />
                    {ticketsEnabled && (
                        <TicketsWidget mobile={true} />
                    )}
                    <PromoWidget mobile={true} />
                    {scanEnabled && (
                        <React.Fragment>
                            <IconButton edge="end" className={`${this.props.classes.personButton} logInButton topMenuButton flexDir scanBut`} color="inherit" aria-label="Login" onClick={this.handleTicketCodeCheck}>
                                <div className="menu-scan-icon"><img src={camera} alt="camera" className="profileImg" /></div>
                                <span className={"label"}>
                                    {this.props.t("Scan")}
                                </span>
                            </IconButton>
                            <TicketCheckModal openTicketCheck={this.state.openCheckModal} onModalClose={this.handleCheckModalClose} />
                            <TicketLottoCheckModal openTicketCheck={this.state.openLottoCheckModal} onModalClose={this.handleCheckModalClose} />
                        </React.Fragment>
                    )}
                    {winnerFun && (
                        <React.Fragment>
                            <WFSeasonWidget onClick={this.handleWinnerFunModal} mobile={true} />
                            {this.state.openWinnerFun && <WinnerFunDrawer open={this.state.openWinnerFun} onModalClose={this.handleWinnerFunModalClose} />}
                        </React.Fragment>
                    )}
                    <IconButton edge="end" className={`${this.props.classes.personButton} logInButton topMenuButton mr`} color="inherit" aria-label="Login" onClick={this.openRightMenu.bind(this)}>

                        {/*<LogoutIcon className={"openLeftMenuIcon"} />*/}
                        <img src={avatarURL} alt="profileImg" className="profileImg" />
                        <span className={"label"}>
                            {this.props.t("cntLabel")}
                        </span>
                    </IconButton>
                </div>
            );
        }

        let scanButton = '';
        if (scanEnabled) {
            scanButton = (
                <Button variant="contained" size="small" color="primary" className={`scan`} onClick={this.handleTicketCodeCheck}>
                    <img src={camera} alt="camera" className="profileImg" />
                </Button>
            );
        }

        return (
            <Fragment>
                <div className="grouped-buttons not-authenticated">
                    <Button variant="contained" size="small" color="secondary" className={`register`} onClick={this.registerHandler.bind(this)}>
                        {this.props.t("Register")}
                    </Button>
                    <Button variant="contained" size="small" color="primary" className={`connect`} onClick={this.connectHandler.bind(this)}>
                        {this.props.t("Sign-in")}
                    </Button>
                    {scanButton}
                    <TicketCheckModal openTicketCheck={this.state.openCheckModal} onModalClose={this.handleCheckModalClose} />
                    <TicketLottoCheckModal openTicketCheck={this.state.openLottoCheckModal} onModalClose={this.handleCheckModalClose} />
                </div>
            </Fragment>
        );
    }

    render() {
        let logo = [];

        if ("user" !== this.props.authentication.auth_type) {
            logo.push(<IconButton className={"casinoButton"} color="inherit" key="logo_menu" onClick={this.logoClickHandler.bind(this)}>
                {this.icons.eldorado}
            </IconButton>);
        }
        return (
            <Spring className={"headerTopMenu"}
                from={{ top: this.state.topFrom }}
                to={{ top: this.state.topTo }}>
                {springProps => (
                    <div className={"headerTopMenu"} style={springProps}>
                        <div className={this.props.classes.root}>

                            <AppBar position="fixed" className={"AppTopMenuBar"}>
                                <Toolbar className={this.props.classes.appTopToolbar}>
                                    {/*(window.config.brand_name_short === 'Winner' ? <div className={"mobile-header-background"} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="56" viewBox="0 0 72 56">
                                            <g id="svg-winner-redarea" transform="translate(0 -94)">
                                                <path id="Subtraction_1" data-name="Subtraction 1" d="M1065.883-2249H1000v-56h72v.066l-7.652,0-11.459,32.208c-2.244,5.414-2.05,11.565.507,16.052,2.385,4.186,6.7,6.837,12.487,7.668Z" transform="translate(-1000 2399)" fill="#c31822" />
                                            </g>
                                        </svg>
                                    </div> : "")*/}

                                    <IconButton edge="start" className={`${this.props.classes.menuButton} topMenuButton leftMenuActionButton`} color="inherit" aria-label="Menu" onClick={this.props.openLeftMenu}>
                                        {window.config.brand_name_short === 'Winner' ? <WinnerIcon className="winner-hamburger" /> : this.icons.hamburgerIcon}
                                    </IconButton>
                                    {logo}
                                    {this.rightButton()}
                                </Toolbar>
                                <LayoutMessages />
                            </AppBar>
                            {/*<div className={classes.toolbarMargin} />*/}

                        </div>
                    </div>
                )}
            </Spring>
        );
    }
}

const mapStateToProps = state => {
    return {
        menu: state.menu,
        application: state.application,
        authentication: state.authentication,
        wallet: state.wallet,
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openLeftMenu: () => dispatch(storeActions.openLeftMenu()),
        setMenuHeight: (height) => dispatch(storeActions.setTopMenuHeight(height)),
        logout: () => dispatch(storeActions.logout()),
        openRightMenu: () => dispatch(storeActions.openRightMenu()),
        toggleProfile: (show) => dispatch(storeActions.rightProfileSection(show)),
        isDeposit: () => dispatch(storeActions.isDeposit(true)),
        // getWallet: () => dispatch(storeActions.requestWallet()),
        lottoTicketCheckCode: (code) => dispatch(lottoTicketCheckCode(code)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopMenu))));
