import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";

import { getBetsState } from "../../store/selectors/betData";
import Delete from "./assets/delete.svg";
import crests from "../../utils/crests";
import {
  betsSlipClear,
  betsSlipClearCreateStatus,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betsSlipComputeWin,
  betSlipSetFreeBetIndex,
  betSlipFreeBetClear,
} from "../../store/actions/betsSlip";

import avatar from "./../NavBar/assets/user.svg";
import { prematchCreateTicketRequest } from "../../store/actions/prematch";
import { liveCreateTicketRequest } from "../../store/actions/live";
import BounceButton from "../../elements/BounceButton";
import TicketModal from "./TicketModal";
import DeleteModal from "./DeleteModal";

import { formatCurrency, formatBetTitle, formatOddName, formatAmount2 } from "../../utils/formatters";
import { debug, formatMessage, twoDecimalsFloat } from "../../utils";

import { doLogin } from "../../../store/actions/authentication";

import evBusMain from "../../../utils/evbus";

const useStyles = makeStyles({
  root: {
    position: "relative",
    paddingBottom: "15px",
    fontFamily: "OpenSans, , 'Open Sans'",
  },
  infoWrapper: {
    width: "calc(100% - 22px)"
  },
  round: {
    fontFamily: "OpenSans, , 'Open Sans'",
    fontSize: "11px",
    fontWeight: "600",
    color: "#06D2BD",
    marginRight: "4px",
    position: "relative",
    top: "1px"
  },
  time: {
    fontFamily: "OpenSans, , 'Open Sans'",
    fontSize: "12px",
    fontWeight: "600",
    color: "#009ED9",
    marginRight: "6px"
  },
  teamFlag: {
    marginRight: "8px",
    "& > img": {
      display: "block",
      width: "11px",
      height: "11px",
      position: "relative",
      top: 3
    }
  },
  teamName: {
    fontFamily: "OpenSans, , 'Open Sans'",
    fontSize: "12px",
    fontWeight: "600",
    color: "#17214D",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
    //flex: "1 1 0px"
  },
  betHolder: {
    marginTop: "8px"
  },
  betName: {
    fontWeight: "bold",
    fontFamily: "OpenSans, , 'Open Sans'",
    fontSize: "14px",
    color: "#586682"
  },
  betWrapper: {
    minWidth: "90px"
  },
  betType: {
    fontWeight: "bold",
    fontFamily: "OpenSans, , 'Open Sans'",
    fontSize: "14px",
    color: "#586682",
    marginRight: "5px"
  },
  betValue: {
    fontWeight: "bold",
    fontFamily: "OpenSans, , 'Open Sans'",
    fontSize: "14px",
    color: "#586682"
  },
  divider: {
    color: "#17214D",
    margin: "0 7px",
    fontSize: "12px"
  },
  delete: {
    "& img": {
      width: "14px",
      height: "18px"
    }
  },
  payHolder: {
    marginTop: "8px"
  },
  but: {
    width: "28px",
    height: "28px",
    textAlign: "center",
    lineHeight: "28px",
    color: "#fff",
    backgroundColor: "#009ED9",
    borderRadius: "50%",
    fontWeight: "bold",
    fontFamily: "OpenSans, , 'Open Sans'",
    fontSize: "14px"
  },
  amount: {
    backgroundColor: "#ECF9FF",
    border: "1px solid #009ED9",
    borderRadius: "10px",
    padding: "0 5px",
    fontWeight: "bold",
    fontFamily: "OpenSans, , 'Open Sans'",
    fontSize: "14px",
    color: "#17214D",
    margin: "0 5px"
  },
  amountInput: {
    fontSize: "16px",
    textAlign: "center",
    maxWidth: "60px",
    color: "#17214D",
    height: "32px",
  },
  amountEnd: {
    marginLeft: "4px",
    paddingRight: "4px",
    "& p": {
      fontWeight: 300,
      fontSize: "11px",
      whiteSpace: "nowrap"
    }
  },
  payButton: {
    padding: "6px 20px 5px 20px",
    background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)",
    fontFamily: "OpenSans, , 'Open Sans'",
    color: "#fff",
    borderRadius: "11px",
    boxShadow: "0px 3px 6px rgba(0,0,0,.4)"
  },
  loginButton: {
    padding: "6px 10px 5px 10px",
    background: "#0430D5",
    fontFamily: "OpenSans, , 'Open Sans'",
    color: "#fff",
    borderRadius: "11px",
    boxShadow: "0px 3px 6px rgba(0,0,0,.4)",
    height: "43px"
  },
  payButtonDisabled: {
    background: "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)",
    boxShadow: "none"
  },
  hr: {
    backgroundColor: "#B7CDD8",
    height: "1px",
    width: "100%",
    marginTop: "5px",
    marginBottom: "5px"
  },
  p0: {
    padding: 0,
    height: "18px",
    "&>img": {
      height: "100%"
    }
  },
  buttonTitle: { fontWeight: "600", fontSize: "14px" },
  buttonSubtitle: { fontSize: "9px" },
  freeBetText: {
    textAlign: "center",
    fontSize: "12px",
    color: "#5A6798",
    marginBottom: "0px",
    maxWidth: "160px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  mr5: {
    marginRight: 5
  }
});

const BetLine = props => {
  const classes = useStyles();

  const {
    bets,
    matches,
    bet,
    clearBet,
    clearCreateStatus,
    amount,
    winAmount,
    stakeInc,
    stakeDec,
    stakeSet,
    onToggle,
    computeWin,
    liveCreateTicket,
    prematchCreateTicket,
    winnerPlus,
    wallet,
    auth,
    walletBonusEvaluate,
    currentTicket,
    isWinnerFun,
    winnerFunEvaluate,

    freeBets,
    selectedFreeBet,
    betSlipFreeBetClear,
    placeTicketEnabled,
    t
  } = props;

  //console.log("quickbet props", props);

  const [stakeAmount, setStakeAmount] = React.useState(amount);
  const [open, setOpen] = React.useState(false);
  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    setStakeAmount(amount);
  }, [amount]);

  useEffect(() => {
    computeWin(matches);
  }, [computeWin, matches]);

  if (!matches) return null;

  // console.log("quickbet: ", matches, bets, bet);

  let match = matches[bet.idMatch];

  if (!match) return null;

  // check for period
  if (bet.match.idMatch !== bet.idMatch) {
    if (Array.isArray(match.periods)) {
      for (const p of match.periods) {
        if (p.idMatch === bet.match.idMatch) {
          match = p;
          break;
        }
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlaceBetClose = (type = 0) => {
    setOpenPlaceBet(false);
    if (type === 1) {
      window.location.hash = "#tickets-details";
    }
    if (type !== 2) {
      clearBet();
    } else {
      clearCreateStatus();
    }
  };

  const handleYesClose = () => {
    setOpen(false);
    clearBet();
  };

  let intervalLetter = "";
  let intervalTime = "";
  let intervalNumber = "";

  if (match && match.mType === "live") {
    intervalLetter = "R";
    intervalTime = "";
    intervalNumber =
      match && match.currentStatus && match.currentStatus.setScoreDetails
        ? match.currentStatus.setScoreDetails.length
        : 0;

    if (match.currentStatus.status === "PAUSED") {
      intervalLetter = "P";
      intervalNumber = "";
    } else {
      if (match.idSport !== "1" && match.idSport !== "2") {
        intervalLetter = "S";
      } else {
        intervalTime =
          match.currentStatus && match.currentStatus.matchtime
            ? match.currentStatus.matchtime + "'"
            : "";
      }
    }
  }

  const matchBets = match && match.matchBets ? [...match.matchBets] : [];
  let oddSelectedIndex = -1;
  let betSelected = {};

  if (bet && bet.idMbo !== "") {
    for (let i = 0; i < matchBets.length; i++) {
      const bs = matchBets[i].mbOutcomes.filter(bo => bo.idMbo === bet.idMbo);
      if (bs.length > 0) {
        oddSelectedIndex = i;
        betSelected = bs[0];
        break;
      }
    }
  }

  const onStakeInc = () => {
    if (selectedFreeBet !== -1) {
      betSlipFreeBetClear();
    };
    stakeInc(-1);
  };

  const onStakeDec = () => {
    if (selectedFreeBet !== -1) {
      betSlipFreeBetClear();
    };
    stakeDec(-1);
  };
  const handleStakeChange = e => {
    let ev = e.target.value;
    if (selectedFreeBet !== -1) {
      betSlipFreeBetClear();
    };

    // let them input an empty string
    if (ev === "") {
      setStakeAmount("");
      stakeSet("");
      return;
    }

    // don't let them put an amount < 1
    if (ev === "0") {
      return;
    }

    // convert , to .
    ev = ev.split(",").join(".");

    // only one .
    const sev = ev.split(".");
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      return;
    }

    // only valid numbers
    if (isNaN(+ev)) {
      return;
    }

    let v = "";

    if (ev !== "") {
      // amounts ending with . have the same value
      if (ev.endsWith(".")) {
        setStakeAmount(ev);
        return;
      }

      // convert amount
      v = parseFloat(ev);
      if (isNaN(v)) return;
    }

    // update amount
    setStakeAmount(ev);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const createTicket = () => {
    //console.log("create ticket");
    setOpenPlaceBet(true);

    let free_bet_code = null;
    let free_bet_name = null;
    let free_bet_pid = null;
    let free_bet_redeemable = 0;
    if (selectedFreeBet > -1) {
      const freeBet = freeBets[selectedFreeBet];
      free_bet_code = freeBet.code;
      free_bet_name = freeBet.name;
      free_bet_pid = freeBet.fbid;
      free_bet_redeemable = freeBet.redeemable;
    }

    liveCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    // prematchCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    betSlipFreeBetClear();
  };

  const handleLogin = () => {
    localStorage.setItem("redirect_after_login", props.location.pathname);
    //props.doLogin();

    if (window.config.platformType === 2) {
      // mobile - do a redirect
      if (window.config.cordova) {
        evBusMain.emit("TRY-FACE-UNLOCK-LOGIN");
      } else {
        props.history.push("/connect");
      }
    } else {
      // web - show modal
      props.doLogin();
    }
  };

  let loggedIn = false;
  if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const logoTeam1 = match && match.idSport === "1" ? crests(match.team1Name, match, 1) : "";
  const logoTeam2 = match && match.idSport === "1" ? crests(match.team2Name, match, 2) : "";

  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  if (isWinnerFun) {
    bLimit = wallet.total;
    valid = bLimit >= amount;
    if (winnerFunEvaluate && winnerFunEvaluate.success && winnerFunEvaluate.data) {
      if (winnerFunEvaluate.data.valid) {
        valid = bLimit >= amount;
      } else {
        valid = winnerFunEvaluate.data.valid;
      }
    }
  } else if (window.config.useBonusEvaluation === "1" &&
    walletBonusEvaluate && walletBonusEvaluate.success && walletBonusEvaluate.data) {
    valid = walletBonusEvaluate.data.valid;
  }

  let onlineTicketDisabled = false;
  if (selectedFreeBet === -1 && !(bLimit !== 0 && bLimit >= amount)) {
    onlineTicketDisabled = true;
  } else if (!placeTicketEnabled) {
    onlineTicketDisabled = true;
  } else if (amount < 2) {
    onlineTicketDisabled = true;
  } else if (isWinnerFun && !valid) {
    onlineTicketDisabled = true;
  }

  let betTitle = formatBetTitle(matchBets[oddSelectedIndex], match, bets);
  let oddTitle = formatOddName(
    matchBets[oddSelectedIndex] ? matchBets[oddSelectedIndex].idBet : "",
    betSelected,
    match,
    bets
  );
  let oddValue = betSelected.mboOddValue;

  if (bet?.betType === 'betBuilder') {
    betTitle = t('Combined Odds');
    oddTitle = '';
    oddValue = bet?.odd;
  }

  console.log("BetBuilderQuickBet[betSelected]", { bet, betSelected });

  return (
    <div className={classes.root}>
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div
          className={`${classes.infoWrapper} d-flex flex-row flex-nowrap flex-grow-1`}
          onClick={onToggle}
        >
          {intervalNumber > 0 && typeof intervalTime !== "undefined" && (
            <React.Fragment>
              <div className={classes.round}>
                {intervalLetter}
                {intervalNumber}
              </div>
              <div className={classes.time}>{intervalTime}</div>
            </React.Fragment>
          )}
          {logoTeam1 !== "" && (
            <div className={classes.teamFlag}>
              <img src={logoTeam1} alt="" />
            </div>
          )}
          <div className={classes.teamName}>{match.team1Name}</div>
          <div className={classes.divider}>:</div>
          {logoTeam2 !== "" && (
            <div className={classes.teamFlag}>
              <img src={logoTeam2} alt="" />
            </div>
          )}
          <div className={classes.teamName}>{match.team2Name}</div>
        </div>
        <div>
          <div className={classes.p0} onClick={handleClickOpen}>
            <img src={Delete} alt="" />
          </div>
        </div>
      </div>
      <div
        className={`${classes.betHolder} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
      >
        <div className={`${classes.betName} flex-grow-1`}>
          {betTitle}
        </div>
        <div
          className={`${classes.betWrapper} d-flex flex-row flex-nowrap align-items-center  justify-content-between`}
        >
          <div className={classes.betType}>
            {oddTitle}
          </div>
          <div className={classes.betValue}>{oddValue}</div>
        </div>
      </div>
      <div className={classes.hr}></div>
      <div
        className={`{classes.payHolder} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
      >
        <div className={classes.mr5}>
          <div className={`${classes.payAmount} d-flex flex-row flex-nowrap align-items-center`}>
            <BounceButton>
              <ButtonBase onClick={onStakeDec} variant="contained" className={classes.but}>
                -
              </ButtonBase>
            </BounceButton>
            {/*<div className={classes.amount}>{amount} lei</div>*/}
            <InputBase
              type="text"
              onChange={handleStakeChange}
              className={`${classes.amount} ${amount < 2 ? classes.warning : ""}`}
              value={stakeAmount}
              inputProps={{
                className: classes.amountInput,
                "aria-label": "stake",
                pattern: "[0-9\.]*"
              }}
              endAdornment={
                <InputAdornment
                  className={`${classes.amountEnd} ${amount < 2 ? classes.warning : ""}`}
                  position="end"
                >
                  {isWinnerFun ? "W" : formatCurrency(wallet.currency)}
                </InputAdornment>
              }
            />
            <BounceButton>
              <ButtonBase onClick={onStakeInc} variant="contained" className={classes.but}>
                +
              </ButtonBase>
            </BounceButton>
          </div>
          {selectedFreeBet !== -1 && (freeBets[selectedFreeBet] && freeBets[selectedFreeBet].name) && <div className={classes.freeBetText}>
            {freeBets[selectedFreeBet].name}
          </div>}
        </div>
        <div className={classes.payButtonHolder}>
          {loggedIn && (
            <ButtonBase
              variant="contained"
              className={`${classes.payButton} ${onlineTicketDisabled ? classes.payButtonDisabled : ""}`}
              onClick={createTicket}
              disabled={onlineTicketDisabled}
            >
              <div>
                <div className={classes.buttonTitle}>{t("PLACE TICKET")}</div>
                <div className={classes.buttonSubtitle}>
                  {t("Potential winning")} {formatAmount2(isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.maxWinAmount : winAmount)} {isWinnerFun ? "W" : "lei"}
                </div>
              </div>
            </ButtonBase>
          )}
          {!loggedIn && (
            <ButtonBase
              variant="contained"
              className={`${classes.loginButton}`}
              onClick={handleLogin}
            >
              <div>
                <div className={classes.buttonTitle}>
                  <img src={avatar} alt="" className={classes.avatar} /> {t("ENTER YOUR ACCOUNT")}
                </div>
              </div>
            </ButtonBase>
          )}
        </div>
      </div>
      <DeleteModal open={open} handleClose={handleClose} handleYesClose={handleYesClose} />
      <TicketModal openPlaceBet={openPlaceBet} handlePlaceBetClose={handlePlaceBetClose} currentTicket={JSON.parse(JSON.stringify(currentTicket))} />
    </div>
  );
};

BetLine.propTypes = {
  bet: PropTypes.object
};

BetLine.defaultProps = {
  bet: {}
};

const mapStateToProps = (state, props) => {
  let mType, bet, bets, matches;

  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  if (ct.live.selected.length > 0) {
    mType = "live";
    bet = ct.live.selected[0];
    bets = bst.live.bets;
    matches = bst.live.matches;
  }

  if (ct.prematch.selected.length > 0) {
    mType = "prematch";
    bet = ct.prematch.selected[0];
    bets = bst.prematch.bets;
    matches = bst.prematch.matches;
  }

  const ret = {
    wallet: bst.app.isWinnerFun ? state.winnerFun.wallet : state.wallet,
    bet: bet,
    bets: bets,
    matches: matches,
    mType,
    amount: ct.amount,
    //stake: ct.stake,
    winAmount: ct.totalMaxWinAmount,
    winnerPlus: ct.winnerPlus,
    auth: state.authentication,
    walletBonusEvaluate: ct.bonusEvaluate,
    currentTicket: ct,
    isWinnerFun: bst.app.isWinnerFun,
    winnerFunEvaluate: ct.winnerFunEvaluate,

    freeBets: state.freeBets.freeBets,
    selectedFreeBet: bst.betsSlip.selectedFreeBet,
    placeTicketEnabled: ct.placeTicketEnabled,
  };

  return ret;
};

/*
const actionCreators = (dispatch, props) => {
  return {
        clearBet: () => dispatch(betsSlipClear()),
    clearCreateStatus: () => dispatch(betsSlipClearCreateStatus()),
    stakeInc: v => dispatch(betsSlipStakeInc(v)),
    stakeDec: v => dispatch(betsSlipStakeDec(v)),
    stakeSet: v => dispatch(betsSlipStakeSet(v)),
    computeWin: ms => dispatch(betsSlipComputeWin(ms)),
    liveCreateTicket: () => dispatch(liveCreateTicketRequest()),
    prematchCreateTicket: () => dispatch(prematchCreateTicketRequest()),
    doLogin: doLogin(dispatch, props)
  };
};
*/

const actionCreators = {
  clearBet: betsSlipClear,
  clearCreateStatus: betsSlipClearCreateStatus,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  computeWin: betsSlipComputeWin,
  liveCreateTicket: liveCreateTicketRequest,
  prematchCreateTicket: prematchCreateTicketRequest,
  doLogin: doLogin,
  betSlipSetFreeBetIndex: betSlipSetFreeBetIndex,
  betSlipFreeBetClear: betSlipFreeBetClear,
};

export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(BetLine)));

/*
export default withTranslation()(withRouter(connect(mapStateToProps, actionCreators)(React.memo(BetLine, (pp, np) => {
  console.log("quickbet props eval");

  for (let k in pp) {
    if (pp[k] !== np[k]) {
      console.log(`${k} is not equal`, typeof pp[k]);
    }
  }

  console.log("quickbet props eval end");

  return false;
}))));
*/
