import React from 'react';
import { withTranslation } from "react-i18next";
import TitleBar from "../../UI/TitleBar/TitleBar";
import DinamicImage from "../../UI/DinamicImage/DinamicImage";
import './Betting.scss';

const Betting = (props) => {

    return (
        <div className={"BettingScreen"}>
            <TitleBar pageTitle={props.t("Betting")} pageSubTitle={props.t("Coming Soon")}/>
            <div className={"BettingScreenContent"}>
                <div className={'MainContent'}>
                    <h1 className={'PageText'}>{props.t('Better odds. Bet easier.')}</h1>
                    <span className={'MedalButton'}>{props.t('coming soon')}</span>
                </div>
                <div className={'MainImage'}>
                <DinamicImage 
                        folder={"coming-soon"}
                        size={"large"}
                        placeholder
                        imgName={"betting.jpg"}
                        title={"Betting"}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(Betting);