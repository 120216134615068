import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../UI/PageTitle/PageTitle';
import Icons from '../../../../assets/Icons/Icons';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './Deposit.scss';

const Deposit = (props) => {

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setTimeout(() => {
            document.getElementById(panel).scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 700);
    };

    return (
        <div className={"PMDepositScreen"}>
            <div className={"MainTitle"}>
                <PageTitle icon={Icons.get('card', `iconSettings`)} title={props.t('Payment Methods')} />
            </div>
            <div className={"DepositAccordion"}>
                {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' &&
                    <ExpansionPanel className={"ExpansionPanel"} id={'panel1'} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <ExpansionPanelSummary className={'AccordionTitle'} expandIcon={<ExpandMoreIcon />}>
                            {Icons.get('casinoIcon', 'casinoIcon')}
                            <h3>{props.t('Cash in Location')}</h3>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={'AccordionContent'}>
                            <p>
                                {props.t("Walk into any casino location to deposit easily and quickly ONLY with your ID-card.")}
                            </p>
                            <Paper className={"TableContent"}>
                                <Table>
                                    <TableBody>
                                        <TableRow >
                                            <TableCell>{props.t('COMMISION')}</TableCell>
                                            <TableCell>{props.t('Free')}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>{props.t('PROCESS TIME')}</TableCell>
                                            <TableCell>{props.t('Instant')}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>{props.t('LIMITS')}</TableCell>
                                            <TableCell>{props.t('Min: 20 Lei')}
                                                {/*<br />*/}
                                                {/*{props.t('Max: 9.000 Lei')}*/}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                }
                {window.config && window.config.okto_enabled === '1' &&
                    <ExpansionPanel className={"ExpansionPanel"} id={'panel1'} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <ExpansionPanelSummary className={'AccordionTitle'} expandIcon={<ExpandMoreIcon />}>
                            {/*{Icons.get('casinoIcon', 'casinoIcon')}*/}
                            <h3>{props.t('OKTO.CASH')}</h3>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={'AccordionContent'}>
                            <p>
                                {props.t("Top up your betting account with cash quickly and directly through OKTO.CASH, no bank card needed.")}
                            </p>
                            <Paper className={"TableContent"}>
                                <Table>
                                    <TableBody>
                                        <TableRow >
                                            <TableCell>{props.t('COMMISION')}</TableCell>
                                            <TableCell>{props.t('Free')}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>{props.t('PROCESS TIME')}</TableCell>
                                            <TableCell>{props.t('Instant')}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>{props.t('LIMITS')}</TableCell>
                                            <TableCell>{props.t('Min: 20 Lei')}
                                                <br />
                                                {props.t('Max: 4.500 Lei')}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                }

                {window.config && window.config.aircash_enabled === '1' &&
                    <ExpansionPanel className={"ExpansionPanel"} id={'panel-abon'} expanded={expanded === 'panel-abon'} onChange={handleChange('panel-abon')}>
                        <ExpansionPanelSummary className={'AccordionTitle'} expandIcon={<ExpandMoreIcon />}>
                            {/*{Icons.get('casinoIcon', 'casinoIcon')}*/}
                            <h3>{props.t('A-Bon')}</h3>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={'AccordionContent'}>
                            <p>
                                {props.t("Top up your betting account with cash quickly and directly through A-Bon, no bank card needed.")}
                            </p>
                            <Paper className={"TableContent"}>
                                <Table>
                                    <TableBody>
                                        <TableRow >
                                            <TableCell>{props.t('COMMISION')}</TableCell>
                                            <TableCell>{props.t('Free')}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>{props.t('PROCESS TIME')}</TableCell>
                                            <TableCell>{props.t('Instant')}</TableCell>
                                        </TableRow>
                                        <TableRow >
                                            <TableCell>{props.t('LIMITS')}</TableCell>
                                            <TableCell>{props.t('Min: 25 Lei')}
                                                <br />
                                                {props.t('Max: 300 Lei')}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                }
                
                <ExpansionPanel className={"ExpansionPanel"} id={'panel2'} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <ExpansionPanelSummary className={'AccordionTitle'} expandIcon={<ExpandMoreIcon />}>
                        <h3>{props.t('Visa / MasterCard Credit Card')}</h3>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={'AccordionContent'}>
                        <p>
                            {props.t("Online payment through credit and debit cards.")}
                        </p>
                        <Paper className={"TableContent"}>
                            <Table>
                                <TableBody>
                                    <TableRow >
                                        <TableCell>{props.t('COMMISION')}</TableCell>
                                        <TableCell>{props.t('Free')}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>{props.t('PROCESS TIME')}</TableCell>
                                        <TableCell>{props.t('Instant')}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>{props.t('LIMITS')}</TableCell>
                                        <TableCell>{props.t('Min: 20 Lei')}<br />{props.t('Max: 9.000 Lei')}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
           

            </div>
            <div className={"Separator"}></div>
            <div className={"DepositScreenContent"}>
                <PageTitle icon={Icons.get('card', `iconSettings`)} title={props.t('Taxes & Commisions')} />
                <p>{props.t('Accordingly to ')}<strong>{props.t('EGO 114/2018, art. 53 ')}</strong>{props.t('and NGO Order 38 (National Gambling Office), ')}<strong>{props.t('an administration tax of 2 percent is applied to every deposit.')}</strong></p>
                <p>{props.t('EXAMPLE:')}<br />{props.t('A deposit of 100 Lei will credit the player’s wallet with 98 Lei, the other 2 Lei being directed to the State Budget.')}</p>
                <p>{props.t('Rounding of tax value is done by ')}<strong>{props.t('rounding up (ceiling) to a multiple of 1 Leu for fractions equal or above 0.5 Lei and by rounding down (floor) for fractions up to 0.49 Lei, inclusive.')}</strong></p>
                <p>{props.t('Every withdrawal will be taxed accordingly to the following table:')}</p>
                <Paper className={"TableContent"}>
                    <Table>
                        <TableHead>
                            <TableRow >
                                <TableCell>{props.t('WITHDRAWAL VALUE')}</TableCell>
                                <TableCell>{props.t('TAX')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                <TableCell>{props.t('up to')} 10.000 RON, {props.t('inclusive')}</TableCell>
                                <TableCell>3%</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{props.t('Above')} 10.000 RON - 66.750 RON, {props.t('inclusive')}</TableCell>
                                <TableCell>300 RON + 20% {props.t('applied to the amount over')} 10.000 RON</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{props.t('Above')} 66.750 RON</TableCell>
                                <TableCell>11.650 RON + 40% {props.t('applied to the amount over')} 66.750 RON</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        </div>
    );
};

export default withTranslation()(Deposit);