import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as storeActions from "../../../../store/actions";
import Utils from "../../../../utils/Utils";
import "./BonusWidget.scss";
import Slider from "react-slick";
import Icons from "../../../../assets/Icons/Icons";

import { withTranslation } from "react-i18next";
import i18next from "i18next";

export const FREEBET_LOTTO = 5;
export const FREEBET_SPORT = 9;
export const FREE_SPINS = 8;
export const BONUS_SCALAR = 1;
export const BONUS_PERCENTAGE = 2;

class BonusWidget extends Component {

    state = {
        bonus: false,
        time: 0,
    };

    componentWillMount() {
        // if (!this.props.promotions.length) {
        if (this.props.authenticated) {
            setTimeout(() => {
                this.props.getPromotions(this.props.paymentMethod);
            }, 100);
        }
        if (this.props.type) {
            if (!this.props.freeBets.length) {
                this.props.getFreeBets();
            }
            if (!this.props.freeSpins.length) {
                this.props.getFreeSpins();
            }
        }
        let hash = window.location.hash;

        if (hash.length) {
            if (this.props.onClickedBonus) {

                this.setState({
                    ...this.state,
                    bonus: parseInt(hash.substr(1), 10)
                });

                this.props.onClickedBonus(parseInt(hash.substr(1), 10), this.props.bonuses ? this.props.bonuses[hash.substr(1)] : null);
            }
        }
        // }
        // this.props.requestTransactionsHistory();
    }

    componentDidMount() {
        if (this.state.bonus) {
            window.location.hash = ''
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.authenticated !== this.props.authenticated && this.props.authenticated) {
            setTimeout(() => {
                this.props.getPromotions(this.props.paymentMethod);
            }, 100);
        }
    }

    // getCurrentStep() {
    //     let now = new Date().getTime()/1000;
    //     let registerDate = this.props.profile.date_created;
    //     let maxDiff = 86400 * MAX_DAYS;
    //     let step = false;
    //     let lastValue = 0;
    //     let time = registerDate +  maxDiff;
    //     // check register
    //     if (now - registerDate < maxDiff) {
    //         step = 0;
    //     }
    //
    //     // check bonuses for step 2
    //     if(this.props.transactions.bonuses) {
    //         // get last bonus
    //        let index = 0;
    //        let id = typeof this.props.transactions.bonuses[0] !== "undefined" ?  this.props.transactions.bonuses[0].id : 0;
    //         Object.keys(this.props.transactions.bonuses).forEach((key) => {
    //             let cB = this.props.transactions.bonuses[key];
    //             if (typeof  cB !== "undefined") {
    //                 if (cB.id > id) {
    //                     index = key;
    //                 }
    //             }
    //         })
    //         let bonus = this.props.transactions.bonuses[index];
    //
    //         if (typeof bonus !== "undefined") {
    //             if ( bonus.status !== 1 ||
    //                 (bonus.status === 1 && bonus.endDate < now) ||
    //                 (bonus.status === 1 && bonus.endDate > now && this.props.wallet.total < 1)) {
    //                 // step = this.props.transactions.bonuses.length;
    //                 lastValue = bonus.bonusId;
    //                 time = bonus.startDate  + 86400 * MAX_DAYS;
    //
    //             } else {
    //                 step = false;
    //             }
    //
    //         }
    //     }
    //     if (step !== false) {
    //         //set Time
    //         if(!this.state.time) {
    //             Utils.delayedFunction('initTimer',this.initTimer.bind(this,time+2),1000);
    //         }
    //     }
    //
    //     return step !== false ? {lastValue: lastValue} : step;
    // }

    // initTimer(time) {
    //     this.setState({
    //         ...this.state,
    //         time: time
    //     })
    // }

    // decrementTimer() {
    //     this.setState({
    //         ...this.state,
    //         time: this.state.time // we don't need to decrement it, but we need to reinit it.!!!
    //     })
    // }

    getTimer() {
        //     let time = parseInt(this.state.time);
        //     let now = new Date().getTime()/1000;
        //     let days = parseInt((time - now)/86400,10);
        //
        //     let diffSeconds =  parseInt((time-now-(days * 86400) ),10);
        //     if (diffSeconds < 0) {
        //         diffSeconds = -diffSeconds;
        //     }
        //     let hour = parseInt(diffSeconds/3600,10);
        //     let minutes = parseInt((diffSeconds - (hour*3600))/60,10);
        //     let seconds = parseInt(diffSeconds - (hour*3600) - (minutes*60),10);
        //     let daysHTML = []
        //     if (days) {
        //         daysHTML.push(<div className="days" key={"bonusdays"}>{days} zile</div>)
        //     }
        //
        //     Utils.delayedFunction('decrementTimer',this.decrementTimer.bind(this),1000);
        //
        //     return (
        //         <div className="timer" key={"bonustimer"}>
        //             {daysHTML}
        //             <div className={"time"} > {hour < 10 ? "0" + hour : hour}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds} sec</div>
        //         </div>
        //     )
        //
        return (
            <div className="timer" key={"bonustimer"}>

                <div className={"time"} >{this.props.t("Time left:")}</div> {this.props.t("Unlimited")}
            </div>
        )
    }

    clickBonus(bonus) {
        if (typeof bonus.bonus_conflicts !== "undefined") {
            if (Object.keys(bonus.bonus_conflicts).length > 0) {
                return;
            }
        }
        if (this.props.onClickedBonus) {
            this.props.onClickedBonus(bonus.bonus_available_id, bonus)
        } else {
            this.props.history.push('/deposit#' + bonus.bonus_available_id)
        }
    }

    render() {
        if (this.props.onClickedBonus && this.props.bonuses !== null
            && (this.props.bonuses.length === 0 || this.props.freeBets.length > 0 || this.props.freeSpins.length > 0)) {
            this.props.onClickedBonus(-1)
        }


        let bonusList = [];
        let bonusListConflicts = [];
        if (this.props.bonusId === -2) {
            bonusList.push(<div
                className={"BonusWidget  compact"} key={"no-bonus-widget"}>
                <div className="details" /*onClick={() => this.props.history.push('/cazino/promotii')}*/>
                    <div className="img">
                        <img
                            src={window.location.protocol + '//' + window.config.cdn_address + "/public/bonus/img-bonuswizard-banner-nobonus%403x.jpg"}
                            alt="" />
                    </div>
                    <div className="other-details"
                        style={{ background: "transparent" }}>
                        <h2 className="title">
                            {this.props.t("No bonus")}

                            {this.props.changeBonusClick &&
                                <div className={'change'}
                                    onClick={this.props.changeBonusClick.bind(this)}>{this.props.t("Change")}</div>
                            }
                        </h2>

                    </div>
                </div>

            </div>);
        }

        if (this.props.bonuses && !this.props.freeBets.length) {
            Object.keys(this.props.bonuses).forEach((key) => {
                let skip = false;
                if (this.props.type === "compact") {
                    if (this.props.bonuses?.[key]?.bonus_available_id !== this.props.bonusId) {
                        skip = true
                    }
                }
                if (!skip) {
                    let bonus = this.props.bonuses[key]
                    let products = [];
                    if (bonus) {
                        if (bonus.eligible_products) {
                            bonus.eligible_products.forEach((key) => {
                                switch (key) {
                                    case 2:
                                    case "2":
                                        products.push(this.props.t("Sportsbook"));
                                        break;
                                    case 1:
                                    case "1":
                                        products.push(this.props.t("Casino"));
                                        break;
                                    default:
                                        break;

                                }
                            })
                        }

                        let bonusConflicts = [];

                        if (bonus.bonus_conflicts) {
                            Object.keys(bonus.bonus_conflicts).forEach((key, i) => {
                                switch (key) {
                                    case 6:
                                    case "6":
                                        bonusConflicts.push(<div key={i}> * {this.props.t("Wager the active bonuses first")}</div>);
                                        break;
                                    case 19:
                                    case "19":
                                        bonusConflicts.push(<div key={i}> * {this.props.t("Requires email verification")}</div>);
                                        break;
                                    case 20:
                                    case "20":
                                        bonusConflicts.push(<div key={i}> * {this.props.t("Requires identity verification")}</div>);
                                        break;
                                    default:
                                        bonusConflicts.push(<div key={i}> * {this.props.t("Temporarily unavailable")}</div>);
                                        break
                                }
                            })
                        }

                        let bonusReceived;
                        if (!this.props.grayscale) {

                            if (this.props.type === "compact") {
                                bonusReceived = getSelectedBonusValue(bonus, this.props.depositValue,{
                                    bonusAward: this.props.bonusAward
                                });
                                bonusReceived = bonusReceived ? `+${bonusReceived} Bonus` : '';
                            } else {
                                // do not pass award amount here. we don't need it.
                                bonusReceived = getSelectedBonusValue(bonus, this.props.depositValue);
                            }
                        }

                        let bonusHTML = (<div
                            className={"BonusWidget" + (this.props.type === "compact" ? " compact" : "")/* + (this.props.grayscale ? " grayscale" : "")*/} key={'bonus-widget-' + bonus.bonus_available_id}>
                            <div className="details" /*onClick={() => this.props.history.push('/cazino/promotii')}*/>
                                <div className="img">
                                    <img
                                        src={this.props.type === "compact" ? bonus.deposit_wizard_image : bonus.icon_image}
                                        alt="" />
                                </div>
                                <div className="other-details"
                                    style={this.props.type === "compact" ? { background: "linear-gradient(90deg, transparent 30%, #f5f8fa 60%)" } : bonus.deposit_wizard_gradient ? { background: bonus.deposit_wizard_gradient } : {}}>
                                    <h2 className="title">
                                        {bonus.bonus_available_name}
                                        {this.props.type === "compact" && this.props.changeBonusClick && (
                                            <div className={'change'} onClick={this.props.changeBonusClick.bind(this)}>{this.props.t("Change bonus")}</div>
                                        )}
                                    </h2>
                                    {this.props.type === "compact" && (
                                        <div className={'deposit-details'}>
                                            {this.props.grayscale && (
                                                <div className={'alert'}>
                                                    <div className="atitle">{this.props.t("Bonus requirements:")}</div>
                                                    <div className="list">{this.props.t(this.props.t("Minimum deposit:"))} <strong>{Utils.formatAmount(bonus.bonus_available_min * 100 / bonus.bonus_available_amount, false, "Lei")}</strong></div>
                                                </div>
                                            )}
                                            {!this.props.grayscale && (
                                                <div>
                                                    <span className={'bonus-received'}>
                                                        {bonusReceived}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {this.props.type !== "compact" && (<div className="bonus">
                                        <div className="name">
                                            {bonus.bonus_available_name}
                                            <div className="type">
                                                {products.join(', ')}
                                            </div>
                                        </div>
                                        {this.props.type !== "compact" && (<div className="value">
                                            {bonusReceived}
                                        </div>)}
                                    </div>)}
                                </div>
                            </div>
                            {bonusConflicts.length > 0 && <div className="bonus-conflicts">
                                <div>{Icons.get('exWarning')} Conditii de activare neindeplinite:</div>
                                {bonusConflicts}
                            </div>}
                            {this.props.type !== "compact" && (<div className={"cta" + (bonusConflicts.length > 0 ? " disabled" : "")}>
                                {this.getTimer(key)}
                                <a className={'button'} onClick={() => this.clickBonus(bonus)}>{this.props.cta_text ? this.props.cta_text : this.props.t("GET BONUS")}</a>
                                {bonus.terms_url && <a href={bonus.terms_url} className={'bonus-terms'} target={"_blank"}>{this.props.t("Terms & conditions")}</a>}
                            </div>)}
                        </div>);
                        if (bonusConflicts.length > 0) {
                            bonusListConflicts.push(bonusHTML);
                        } else {
                            bonusList.push(bonusHTML);
                        }
                    }
                }

            })

            if (this.props.addEmpty) {
                bonusListConflicts.push(<div className={"BonusWidget empty"} key={'bonus-widget-empty'}>
                    <div className="details" /*onClick={() => this.props.history.push('/cazino/promotii')}*/>
                        <div className="img">
                            {Icons.get('diamond')}
                        </div>
                        <div className="other-details">
                            <h2 className="title">{this.props.t("No bonus")}</h2>
                        </div>
                    </div>
                    <div className="cta" onClick={this.props.onClickedBonus ? this.props.onClickedBonus.bind(this, -2) : () => this.props.history.push('/deposit')}>
                        {/*{this.getTimer()}*/}
                        <span></span>
                        <a className={'button'}>{this.props.t("Deposit without bonus")}</a>
                    </div>
                </div>);
            }
        }
        if ((!bonusList.length && !bonusListConflicts.length && this.props.type !== "compact") ||
            (this.props.freeBets.length && this.props.type !== "compact") ||
            (!bonusList.length && typeof this.props.type === "undefined")
        ) {
            bonusList = (<div className={"BonusWidget loading"}>
                <div className="details  no-bonus" /*onClick={() => this.props.history.push('/cazino/promotii')}*/>
                    <div className="img" style={{ backgroundImage: "url(" + window.location.protocol + '//' + window.config.cdn_address + "/public/bonus/img-bonuscard-nobonus-luminosity@3x.png)" }}>

                    </div>
                    <span></span>
                    <div className="message">
                        Nu poti obtine alte BONUS-uri chiar acum.
                        Incearca din nou mai tarziu.
                    </div>
                </div>
            </div>)
        }
        if (this.props.bonuses === null) {
            bonusList = (<div className={"BonusWidget loading"}>
                <div className="details" /*onClick={() => this.props.history.push('/cazino/promotii')}*/>
                    <div className="img" style={{ backgroundImage: "url(" + window.location.protocol + '//' + window.config.cdn_address + "/public/bonus/img-bonuscard-nobonus-luminosity@3x.png)" }}>

                    </div>
                    <span></span>
                    <div className="message">
                        Se verifica bonusurile....
                    </div>
                </div>
            </div>)
        }




        if (this.props.type === 'list' || this.props.type === "compact") {

            return (<div>{bonusList}{bonusListConflicts}</div>)
        }
        const slick_settings = {
            dots: true,
            arrows: false,
            autoplay: false,
            easing: "easeOutQuart",
            draggable: true,
            infinite: false,
            slidesToShow: 1.1,
            slidesToScroll: 1,
        };
        return (
            <Slider className={"bonusCarousel"} {...slick_settings} >
                {bonusList}
            </Slider>
        );
    }

};

const mapStateToProps = state => {
    return {
        bonuses: state.application.eligibleBonuses,
        profile: state.profile,
        transactions: state.transactions,
        wallet: state.wallet,
        authenticated: state.authentication.auth_type === "user",
        freeBets: state.freeBets.freeBets,
        freeSpins: state.freeSpins.freeSpins,
        bonusAward: state.application.bonusAward,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeRightMenu: () => dispatch(storeActions.closeRightMenu()),
        getPromotions: (paymentMethod) => dispatch(storeActions.getEligibleBonuses(paymentMethod)),
        getFreeBets: () => dispatch(storeActions.freeBetsFetch()),
        getFreeSpins: () => dispatch(storeActions.freeSpinsFetch())
        // requestTransactionsHistory: () => dispatch(storeActions.requestTransactionsHistory(3))
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)((BonusWidget))));

export const getSelectedBonusValue = (bonus, depositValue, opts = null) => {
    if (!bonus) {
        return false;
    }
    const t = i18next.getFixedT(i18next.language);
    let bonusReceived = false;
    if (opts?.bonusAward && opts?.bonusAward?.bonusId === bonus.bonus_available_id) {
        bonusReceived = opts?.bonusAward?.awardAmount;
    }

    switch (bonus.bonus_available_type) {
        case FREEBET_SPORT:
        case FREEBET_LOTTO:
            if (typeof bonus.bonus_type_specific !== 'undefined'
                && typeof bonus.bonus_type_specific.tokenCount !== 'undefined'
                && typeof bonus.bonus_type_specific.tokenValue !== 'undefined') {
                bonusReceived = `${bonus.bonus_type_specific.tokenCount} x ${Utils.formatAmount(bonus.bonus_type_specific.tokenValue / 100, false, 'ron')}`;
            }
            break;
        case FREE_SPINS:
            if (bonusReceived) {
                bonusReceived = `${bonusReceived} ${t("Spins")}`;
            } else if (typeof bonus.bonus_type_specific !== 'undefined' && typeof bonus.bonus_type_specific.fs_count !== 'undefined') {
                bonusReceived = `${bonus.bonus_type_specific.fs_count} ${t("Spins")}`;
            }
            break;
        case BONUS_SCALAR:
        case BONUS_PERCENTAGE:
            let maxDepositForBonus = Math.round(bonus.bonus_available_max * 100 / bonus.bonus_available_amount);
            let minDepositForBonus = Math.round(bonus.bonus_available_min * 100 / bonus.bonus_available_amount);
            if (depositValue <= maxDepositForBonus) {
                if (depositValue >= minDepositForBonus) {
                    if (bonusReceived) {
                        bonusReceived = Utils.formatAmount(bonusReceived/100, true, "lei");
                    } else {
                        bonusReceived = Utils.formatAmount(depositValue * (bonus.bonus_available_amount / 100), true, "lei")
                    }
                } else {
                    bonusReceived = "0";
                }
            } else {
                if (bonusReceived) {
                    bonusReceived = Utils.formatAmount(bonusReceived/100, true, "lei");
                } else {
                    bonusReceived = Utils.formatAmount(bonus.bonus_available_max, true, 'lei')
                }
            }
            break;
        default:
            break;
    }

    return bonusReceived;
}