import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import './GoogleMapContainer.scss';

class GoogleMapContainer extends Component
{
    constructor(props) {
        super(props);

        this.markers = [];
        this.markerClickHandler.bind(this);
    }

    state = {
        infoState: false,
        title: "",
        desc: "",
        marker: null,
        coords: null,
        etaWalking: null,
        etaDriving: null,
    };

    createMarkers() {
        if (this.markers.length > 0) {
            return;
        }

        if (!(this.props.locations.length > 0)) {
            return;
        }

        this.props.locations.forEach((location, index) => {
            if (location.active) {
                let marker = <Marker key={ `casino_marker_${index}` }
                                     title={ location.locationName }
                                     name={ location.formattedAddress }
                                     position={{
                                         lat: location.latitude,
                                         lng: location.longitude,
                                     }}
                                     onClick={ this.markerClickHandler.bind(this, location)  } />;
                this.markers.push(marker);
            }
        });
    }

    markerClickHandler(location, props, marker, e) {
        this.props.setLocation(location);
        this.setState({
            infoState: true,
            title: marker.title,
            desc: marker.name,
            marker: marker,
            coords: [`${location.latitude}, ${location.longitude}`],
            etaWalking: null,
            etaDriving: null,
        });
    }

    resetHandler() {
        this.setState({
            infoState: false,
            title: "",
            desc: "",
            marker: null,
            coords: null,
            etaWalking: null,
            etaDriving: null,
        });
    }

    render() {
        this.createMarkers();

        const mapStyle = {
            width: '100%',
            height: '100%',
        };
        return (
            <div className="MapContainer">
                <Map
                    google={ this.props.google }
                    zoom={this.props.zoom ? this.props.zoom : 10 }
                    style={mapStyle}
                    initialCenter={this.props.initialCenter ? this.props.initialCenter : {
                        lat: 44.437551,
                        lng: 26.118162,
                    }} disableDefaultUI={true} center={this.props.initialCenter ? this.props.initialCenter : {
                    lat: 44.437551,
                    lng: 26.118162,
                }}>
                    { this.markers }
                    <InfoWindow
                        visible={ this.state.infoState }
                        marker={ this.state.marker }
                        onClose={ this.resetHandler.bind(this) } >
                        <div>
                            <h4>{ this.state.title }</h4>
                            <p>{ this.state.desc }</p>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: window.config.google_maps_api_key,
    language: 'ro'
})(GoogleMapContainer);
