import * as actionTypes from './actionTypes';

export const requestPendingWithdrawals = (data) => {
    if (data && data.cooldown) {
        return {
            type: actionTypes.withdrawals.REQUEST_PENDING_WITHDRAWALS,
            cooldown: data.cooldown
        }
    } else {

        return {
            type: actionTypes.withdrawals.REQUEST_PENDING_WITHDRAWALS,
        }
    }
};

export const requestPendingVivaWithdrawals = () => {
    return {
        type: actionTypes.withdrawals.REQUEST_PENDING_VIVA_WITHDRAWALS,
    }
};

export const requestWithdrawHistory = () => {
    return {
        type: actionTypes.withdrawals.REQUEST_WITHDRAW_HISTORY,
    }
}

export const getAllPendingWithdrawals = () => {
    return dispatch => {
        dispatch(requestPendingWithdrawals());
        dispatch(requestPendingVivaWithdrawals());
        dispatch(requestWithdrawHistory());
    }
}

export const receivedPendingWithdrawals = (data) => {
    return {
        type: actionTypes.withdrawals.RECEIVED_PENDING_WITHDRAWALS,
        withdrawals: data,
    }
};

export const requestLocations = () => {
    return {
        type: actionTypes.withdrawals.REQUEST_LOCATIONS,
    }
};

export const receivedLocations = (data) => {
    return {
        type: actionTypes.withdrawals.RECEIVED_LOCATIONS,
        withdrawals: data
    }
};

export const clearTaxService = () => {
    return {
        type: actionTypes.withdrawals.CLEAR_TAX_SERVICE,
    }
};

export const requestTaxService = (taxType, amount, id) => {
    return {
        type: actionTypes.withdrawals.REQUEST_TAX_SERVICE,
        taxType: taxType,
        amount: amount,
        id: id,
    }
};

export const downloadCSV = (type) => {
    return {
        type: actionTypes.withdrawals.DOWNLOAD_CSV,
        historyType: type
    }
}

export const receivedTaxService = (data) => {
    return {
        type: actionTypes.withdrawals.RECEIVED_TAX_SERVICE,
        withdrawals: data
    }
};

export const requestCancelWithdrawal = (amount, withdrawId) => {
    return {
        type: actionTypes.withdrawals.REQUEST_CANCEL_WITHDRAWAL,
        amount: amount,
        withdrawId: withdrawId,
    }
};

export const receivedCancelWithdrawal = (data) => {
    return {
        type: actionTypes.withdrawals.RECEIVED_CANCEL_WITHDRAWAL,
        withdrawals: data
    }
};

export const requestCancelVivaWithdrawal = (amount, withdrawId) => {
    return {
        type: actionTypes.withdrawals.REQUEST_CANCEL_VIVA_WITHDRAWAL,
        amount: amount,
        withdrawId: withdrawId,
    }
};


export const downloadHistoryCSV = (response) => {

    if (!response) {
        throw new Error(`[ERROR] Request CancelWithdrawal response is empty!`);
    }

    let fileName = `history_`;
    let reportIntType = JSON.parse(response.config.data).type;

    switch (reportIntType) {
        case 1:
            fileName += `game_spins`;
            break;
        case 2:
            fileName += `deposits_and_withdrawals`;
            break;
        case 3:
            fileName += `bonuses`;
            break;
        default:
            fileName += `unknown`;
            break;
    }

    let now = Date.now();
    fileName += `_${now.toString()}.csv`;

    let csvContent = '';

    // force download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    return {
        type: "FAKE_ACTION"
    }
};

export const cleanVerificationCode = () => {
    return {
        type: actionTypes.withdrawals.CLEAN_VERIFICATION_CODE,
    }
};

export const requestVerificationCode = (withdrawID) => {
    return {
        type: actionTypes.withdrawals.REQUEST_VERIFICATION_CODE,
        withdrawID: withdrawID,
    }
};

export const receivedVerificationCode = (data, withdrawID) => {
    return {
        type: actionTypes.withdrawals.RECEIVED_VERIFICATION_CODE,
        data: data.result,
        withdrawID: withdrawID
    }
};

export const getPaymentMethods = () => {
    return {
        type: actionTypes.withdrawals.GET_PAYMENT_METHODS
    }
}

export const receivedPaymentMethods = (data) => {
    return {
        type: actionTypes.withdrawals.RECEIVED_PAYMENT_METHODS,
        data: data.result
    }
}

export const linkIban = (iban, beneficiaryName, friendlyName) => {
    return {
        type: actionTypes.withdrawals.LINK_IBAN,
        iban: iban,
        name: beneficiaryName,
        friendlyName: friendlyName
    }
}

export const withdraw = (amount, withdrawType, withdrawReference) => {
    return {
        type: actionTypes.withdrawals.WITHDRAW,
        amount: amount,
        withdrawType: withdrawType,
        withdrawTypeReference: withdrawReference
    }
}