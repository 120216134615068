import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import VerificationInput from "../Form/VerificationInput/VerificationInput";
import {withTranslation} from "react-i18next";

class Verification extends Component
{

    render() {
        return (
            <div id={"verification"}>
                <VerificationInput type={'simple'} oldRegister={true}/>
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        // onChange: (fieldInput) => dispatch(stateActions.phoneEntered(fieldInput)),
    };
};

const mapStateToProps = state => {
    return {
        // phone: state.registerWizard.fields.phone,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Verification));
