import React, { useState, useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as storeActions from "../../../../store/actions";
import TitleBar from "../../../UI/TitleBar/TitleBar";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import DocumentType from './DocumentType/DocumentType';
import DocumentSelector from './DocumentSelector/DocumentSelector';
import DocumentCamera from './DocumentCamera/DocumentCamera';
import DocumentUpload from './DocumentUpload/DocumentUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Documents.scss';
import Button from '../../../UI/Button/Button';

export const TYPE_ID = 1;
export const TYPE_UTILITIES_BILL = 2;
export const TYPE_CARD = 3;
export const TYPE_PASSPORT = 4;
export const TYPE_CUSTOM = 5;

export const STATUS_PENDING = 2;
export const STATUS_EXPIRED = 4;
export const STATUS_REQUESTED = 6;
export const STATUS_APPROVED = 8;
export const STATUS_REJECTED_CANNOT_READ = 9;
export const STATUS_REJECTED_WRONG_IMAGE = 10;
export const STATUS_REJECTED_REASON = 16;

const Documents = (props) => {
    
    const [pageAction, setPageAction] = useState({
        page: 'documents',
        documentType: null,
        documentID: null,
        newDocument: null,
    });

    const [verifyEmailClicked, isVerifyEmailClicked] = useState(false);

    useEffect(() => {
        if(props.auth.auth_type !== "user"){
            props.history.push("/");
        }else{
            props.onRequestDocuments();
            if(props.documents.documentsMaxFileSize === null){
                props.onRequestDocumentsMaxFileSize();
            }
        }
        // eslint-disable-next-line
    }, []);

    const backHandler = (documentID) => {

        if(pageAction.page === 'documents'){
            props.history.goBack();
        }
        
        if(pageAction.page === 'selector'){
            pageActionHandler('documents', pageAction.documentType, documentID);
        }
        
        if(pageAction.page === 'camera'){
            pageActionHandler('selector', pageAction.documentType, documentID);
        }
        
        if(pageAction.page === 'upload'){
            pageActionHandler('selector', pageAction.documentType, documentID);
        }
        
    }

    const pageActionHandler = (page, type, documentID, newDocument = null) => {
        if(page && type){
            setPageAction({
                ...pageAction,
                page: page,
                documentType: type,
                documentID: documentID,
                newDocument: newDocument,
            });
        }

        if(page === 'documents'){
            props.onRequestDocuments();
        }
    }

    let pageContent = <CircularProgress className={'Loading'} />;
   
    let alertBox = '';

    if(!props.documents.loadingDocuments){

        if(pageAction.page === 'documents' && props.documents.documentsRequest){
            pageContent = props.documents.documentsRequest.map(doc => {
                if(doc.type !== TYPE_CUSTOM){
                    // if(doc.type !== TYPE_CARD && props.history.location.pathname === '/profile/documents'){
                        return <DocumentType 
                            key={doc.id} 
                            type={doc.type} 
                            status={doc.status} 
                            reason={doc.reason} 
                            comment={doc.comment} 
                            expires={doc.expires} 
                            documentID={doc.id} 
                            changePage={(page, type, documentID) => pageActionHandler(page, type, documentID)}
                            name={doc.name}
                        />;
                    // }else if(doc.type === TYPE_CARD && props.history.location.pathname === '/profile/cards'){
                    //     return <DocumentType
                    //         key={doc.id}
                    //         type={doc.type}
                    //         status={doc.status}
                    //         reason={doc.reason}
                    //         comment={doc.comment}
                    //         expires={doc.expires}
                    //         documentID={doc.id}
                    //         changePage={(page, type, documentID) => pageActionHandler(page, type, documentID)}
                    //     />;
                    }
                // }
            });

            alertBox = (
                <div className="AlertBox">
                    <h2>{props.t('You are all good!')}</h2>
                    <p>{props.t('Future document requests will appear here and we shall notify you.')}</p>
                </div>
            );

            for (var i = 0; i < props.documents.documentsRequest.length; i++) {
                if(props.documents.documentsRequest[i].status !== STATUS_APPROVED && props.documents.documentsRequest[i].type !== TYPE_CUSTOM){
                    alertBox = <Fragment />;
                    break;
                }
            }

        }
    
        if(pageAction.page === 'selector'){
            pageContent = <DocumentSelector 
                documentType={pageAction.documentType} 
                documentID={pageAction.documentID} 
                changePage={(page, type, documentID, newDocument) => pageActionHandler(page, type, documentID, newDocument)}
            />;
        }
    
        if(pageAction.page === 'camera'){
            pageContent = <DocumentCamera 
                documentType={pageAction.documentType} 
                documentID={pageAction.documentID} 
                selectedDocument={pageAction.newDocument}
                changePage={(page, type, documentID, newDocument) => pageActionHandler(page, type, documentID, newDocument)}  
            />;
        }
    
        if(pageAction.page === 'upload'){
            pageContent = <DocumentUpload 
                documentType={pageAction.documentType} 
                documentID={pageAction.documentID} 
                newDocument={pageAction.newDocument} 
                changePage={(page, type, documentID, newDocument) => pageActionHandler(page, type, documentID, newDocument)}
            />;
        }
    }

    const resendVerificationEmail = () => {
        if (verifyEmailClicked) return;
        props.onResendVerificationEmail();
        isVerifyEmailClicked(true);
    }

    const getNameEmailStatus = () => {
        if(props.profile && props.profile.first_name){
            let profileData = props.profile;
            if (null === profileData || !profileData.hasOwnProperty('first_name')) {
                return;
            }

            let contentReturn = []
            contentReturn.push(<h2 key={"name"}>{profileData.first_name}&nbsp;{profileData.last_name}</h2>);
            contentReturn.push(
                <p key={"email"}>
                    <b>{props.t("Email:")}&nbsp;</b> 
                    {profileData.email}
                </p>
            );
            contentReturn.push(
                <p key={"email_status"}>
                    <b>{props.t("Email status:")}&nbsp;</b> 
                    <span className={profileData.email_verified ? 'verified' : 'not-verified'}>
                        {profileData.email_verified ? props.t('Verified') : props.t('Not Verified')}
                    </span>
                </p>
            );
            if (!profileData.email_verified) {
                contentReturn.push(
                    <Fragment key={"email_status_1"}>
                        {   !verifyEmailClicked 
                            ? <Button className={'active'} value={props.t("Resend verification email")} click={resendVerificationEmail} full/>
                            : <span className="btn SendEmailDone">{props.t("Email successfully sent")}</span>
                        }
                    </Fragment>
                );
            }
            return <div className="PlayerDetails">{contentReturn}</div>;
        }
    };

    return(
        <div className={"DocumentsScreen"}>
            <TitleBar 
                pageTitle={props.history.location.pathname === '/profile/cards' ? props.t('My Cards') : props.t('documents_title')} 
                backHandler={() => backHandler(pageAction.documentID)}
            />
            <AppBar position="static" className="SubtitleBar">
                <Typography variant="subtitle1">
                    {props.history.location.pathname === '/profile/cards' ? props.t('Verify my card') : props.t('Documents')}
                </Typography>
            </AppBar>
            
            <div className="DocumentsContent">
                {getNameEmailStatus()}
                {pageContent}
                {alertBox}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        documents: state.documents,
        auth: state.authentication,
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestDocuments: () => dispatch(storeActions.requestDocuments()),
        onRequestDocumentsMaxFileSize: () => dispatch(storeActions.requestDocumentsMaxFileSize()),
        onResendVerificationEmail: () => dispatch(storeActions.resendVerificationEmail()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Documents)));
