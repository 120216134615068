import * as actionTypes from './../actions/actionTypes';

const initialState = {
    bonuses: [],
    main: 0,
    bonus: 0,
    total: 0,
    ringFence: 0,
    currency: "RON",
    cardBonus: null,
    loadingWallet: false,
    loadingRemoveBonus: false,
    bonusRemoved: null,
    walletManagement: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.wallet.RESET_WALLET:
            return {
                ...initialState,
            };
        case actionTypes.wallet.REQUEST_WALLET: {
            return {
                ...state,
                loadingWallet: true,
            }
        }
        case actionTypes.wallet.RECEIVED_WALLET:
            let cardBonus = null;
            let rawWallet = action.wallet;
            if (!rawWallet.hasOwnProperty(state.currency)) {
                console.log(`[ERROR] Wallet is missing currency: ${state.currency}`)
                return state;
            }

            let wallet = rawWallet[state.currency];

            let main = 0.00;
            let total = main;
            let bonus = main;
            let ringFence = main;

            let walletManagement = [];

            if (wallet.hasOwnProperty('main')) {
                main += parseFloat(wallet.main);
                total += main;
            }

            let bonuses = [];
            let totalBonusPerProducts = {};
            if (wallet.hasOwnProperty('bonus') && wallet.bonus) {
                bonuses = wallet.bonus;

                wallet.bonus.forEach((bonusRow) => {
                    let bAmount = (bonusRow.amount) ? bonusRow.amount : '0';
                    if (!(1 === Number.parseInt(bonusRow.status, 10))) {
                        return;
                    }

                    if (1 === Number.parseInt(bonusRow.type, 10)) {
                        cardBonus = bonusRow;
                    }
                    if (bonusRow.hasOwnProperty('eligibleProducts') && bonusRow.eligibleProducts) {
                        bonusRow.eligibleProducts.forEach((eligibleProduct) => {
                            let bonusAmount = bAmount;
                            if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].bonusAmount) {
                                bonusAmount = parseFloat(walletManagement[eligibleProduct].bonusAmount) + parseFloat(bAmount);
                            }

                            let bProductAmount = 0;

                            if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                                bProductAmount = parseFloat(walletManagement[eligibleProduct].productAmount) + parseFloat(bAmount);
                            } else {
                                bProductAmount = bonusAmount;
                            }
                            walletManagement[eligibleProduct] = {
                                ...walletManagement[eligibleProduct],
                                'bonusAmount': Number(parseFloat(bonusAmount).toFixed(2)),
                                'productAmount': Number(parseFloat(bProductAmount).toFixed(2)),
                            };
                        });
                    }
                    bonus += Number.parseFloat(bAmount);
                });

                total += bonus;
            }

            let ringFences = [];
            if (wallet.hasOwnProperty('ringFence') && wallet['ringFence']) {
                ringFences = wallet.ringFence;

                wallet['ringFence'].forEach((ringFenceRow) => {
                    let rfAmount = (ringFenceRow.amount) ? ringFenceRow.amount : '0';
                    if (!(1 === Number.parseInt(ringFenceRow.status, 10))) {
                        return;
                    }

                    if (ringFenceRow.hasOwnProperty('eligibleProducts') && ringFenceRow.eligibleProducts) {
                        ringFenceRow.eligibleProducts.forEach((eligibleProduct) => {
                            let ringFenceAmount = rfAmount;
                            if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].ringFenceAmount) {
                                ringFenceAmount = parseFloat(walletManagement[eligibleProduct].ringFenceAmount) + parseFloat(rfAmount);
                            }

                            let rfProductAmount = ringFenceAmount;
                            if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                                rfProductAmount = parseFloat(walletManagement[eligibleProduct].productAmount) + parseFloat(rfAmount);
                            }

                            walletManagement[eligibleProduct] = {
                                ...walletManagement[eligibleProduct],
                                'ringFenceAmount': Number(parseFloat(ringFenceAmount).toFixed(2)),
                                'productAmount': Number(parseFloat(rfProductAmount).toFixed(2)),
                            };
                        });
                    }

                    ringFence += Number.parseFloat(rfAmount);
                });

                total += ringFence;
            }


            Object.keys(walletManagement).forEach((eligibleProduct) => {
                if(walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                   walletManagement[eligibleProduct].productAmount += main;
                }
            })

            if (bonuses.length > 0 && ringFences.length > 0) {
                bonuses.forEach((bonus) => {
                    ringFences.forEach((ringFence) => {
                        if (ringFence.id === bonus.id) {
                            bonus.ringFenceAmount = ringFence.amount;
                        }
                    });
                });
            }

            return {
                ...state,
                bonuses: bonuses,
                ringFences: ringFences,
                main: main,
                bonus: bonus,
                total: total,
                ringFence: ringFence,
                walletManagement: walletManagement,
                cardBonus: cardBonus,
                loadingWallet: false,
                totalBonusPerProducts: totalBonusPerProducts
            };
        case actionTypes.wallet.REQUEST_REMOVE_BONUS:
            return {
                ...state,
                loadingRemoveBonus: true,
                bonusRemoved: null,
            };
        case actionTypes.wallet.RECEIVED_REMOVE_BONUS:
            let bonusRemoved = false;
            if (Array.isArray(action.result) && action.result.length === 0) {
                bonusRemoved = true;
            }
            return {
                ...state,
                loadingRemoveBonus: false,
                bonusRemoved: bonusRemoved,
            };
        case actionTypes.wallet.CORE_SET_BALANCE: {
            //console.log("wallet core set balance", state, action.data);

            let main = action.data.balance_amount / 100;

            let bonus = state.bonus;

            if (action.data.bonus_amount !== null) {
                bonus = action.data.bonus_amount / 100;
            }

            return {
                ...state,
                main,
                total: main + bonus,
                bonus,
            };
        }
        default:
            return state;
    }
};

export default reducer;
