import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as EmailValidator from 'email-validator';
import Utils from "../../../../utils/Utils";
import { withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import * as storeActions from "../../../../store/actions";
import Icons from "../../../../assets/Icons/Icons";
import GTM from "../../../../utils/GTM";

class Email extends Component {
    constructor(props) {
        super(props);
        this.timerId = 'emailComponent';
    }

    state = {
        message: "",
        ok: false,
        fieldInput: false,
    };

    componentDidMount() {
        this.infoMessage = this.props.t("Insert a valid email address");
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.error !== prevProps.error) {

            if (this.props.error && this.props.error.props && this.props.error.props.text) {
                GTM.pushGAEvent('Register_' + this.props.lang, 'Field - Core Error', "Email - " + this.props.error.props.text);
            }

            this.setState({
                ...this.state,
                message: this.props.error,
                serverSideValidationSent: false
            })
        }
    }

    triggerHandler() {

        let message = "";
        let valid = EmailValidator.validate(this.state.fieldInput);
        let ok = this.state.ok;

        if (valid) {
            this.props.validate(this.state.fieldInput);
            if (!ok) {
                ok = true;
            }
        } else {
            message = this.props.t("Email invalid");
            if (ok) {
                ok = false;
            }
        }

        this.setState({
            ...this.state,
            message: message,
            ok: ok,
        });
        this.props.onChange({email: this.state.fieldInput ? this.state.fieldInput.toLowerCase() : false,validated: ok});
    }

    sendGAEvent() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Email" + (this.state.ok ? "" : " - " + this.state.message));
    }

    blurHandler() {

        Utils.delayedFunction('sendGAEvent',this.sendGAEvent.bind(this),50);

        Utils.fixRegisterInputs('email');
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this),0);
        this.props.setSpineSize('big');
    }

    changeHandler(event){
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.email;
        } else {
            fieldInput = event.target.value;
        }
        if (fieldInput !== this.state.fieldInput && this.state.fieldInput !== '') {
            window.localStorage.removeItem('phoneValidationCode');
        }
        fieldInput = fieldInput.trim();

        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput,
        });
        this.props.onChange({email: fieldInput,validated: false});
    }

    onFocusHandler() {
        Utils.fixRegisterInputs('email');
        this.props.setSpineSize('small');
        this.setState({
            ...this.state,
            message: this.infoMessage,
        })
    }

    render() {
        let errorMessage = this.state.message;
        if (typeof errorMessage !== "undefined") {
            if (errorMessage.type === "" && this.props.error !== "") {
                errorMessage = this.props.error; // error from server
            }
        }
        let props = {
            error: (errorMessage !== "" && errorMessage !== this.infoMessage && errorMessage !== false),
        };
        if(this.state.fieldInput === false && this.props.email) {
            props.value = this.props.email ? this.props.email : "";
        }

        let icon = Icons.get('register-email');

        return (
            <div className={'field'}>
                {icon}
                <TextField
                    {...props}
                    label={this.props.t("Email")}
                    id={"email"}
                    className={''}
                    type={"email"}
                    onChange={this.changeHandler.bind(this)}
                    onFocus={this.onFocusHandler.bind(this)}
                    onBlur={this.blurHandler.bind(this)}
                    helperText={this.state.message}
                    // InputProps={{
                    //     startAdornment: (
                    //         <InputAdornment position="start">
                    //             <PhoneIcon />
                    //         </InputAdornment>
                    //     ),
                    // }}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered({field: "email",value: fieldInput.email})),
        validate: (fieldInput) => dispatch(storeActions.validateField(fieldInput,'email')),
        setSpineSize: (size) => dispatch(storeActions.setSpineSize(size))
    };
};

const mapStateToProps = state => {
    return {
        email: state.register.fields.email,
        error: state.register.errors.email,
        register: state.register,
        lang: state.application.language
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Email));
