import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import { withRouter } from 'react-router-dom';
import TitleBar from '../UI/TitleBar/TitleBar';
import { useTranslation } from "react-i18next";

import "./NotificationsPreference.scss";
import "./NotificationsSettings.scss";
import { ReactComponent as WantPromotionsIcon } from "./assets/want-promotions.svg";
import Switch from "./components/Switch";

import * as storeActions from "../../store/actions";

const NotificationsPreference = (props) => {
  const { t } = useTranslation();

  //console.log('NotificationsPreference[props.marketing_agreements]', props.marketing_agreements);

  const [state, setState] = React.useState({
    marketing: !!(props.marketing_agreements.marketing_receive_sms || props.marketing_agreements.marketing_receive_email || props.marketing_agreements.marketing_receive_phone || props.marketing_agreements.marketing_partners),
    marketing_sms: !!props.marketing_agreements.marketing_receive_sms,
    marketing_email: !!props.marketing_agreements.marketing_receive_email,
    marketing_phone: !!props.marketing_agreements.marketing_receive_phone,
    marketing_partners: !!props.marketing_agreements.marketing_partners,
  });

  React.useEffect(() => {
    setState({
      marketing: !!(props.marketing_agreements.marketing_receive_sms || props.marketing_agreements.marketing_receive_email || props.marketing_agreements.marketing_receive_phone || props.marketing_agreements.marketing_partners),
      marketing_sms: !!props.marketing_agreements.marketing_receive_sms,
      marketing_email: !!props.marketing_agreements.marketing_receive_email,
      marketing_phone: !!props.marketing_agreements.marketing_receive_phone,
      marketing_partners: !!props.marketing_agreements.marketing_partners,
    });
  }, [props.marketing_agreements]);

  //console.log('NotificationsPreference[state]', state);

  const handleChange = (e) => {
    let newState = { ...state };
    newState[e.target.value] = e.target.checked;

    switch (e.target.value) {
      case 'marketing':
        newState.marketing_sms = e.target.checked;
        newState.marketing_email = e.target.checked;
        newState.marketing_phone = e.target.checked;
        newState.marketing_partners = e.target.checked;

        break;
      case 'marketing_sms':
      case 'marketing_phone':
      case 'marketing_email':
      case 'marketing_partners':
        newState.marketing = (newState.marketing_sms || newState.marketing_email || newState.marketing_phone || newState.marketing_partners);
        break;
      default:
    }

    const postData = {
      all: true,
      value: 0,
    };

    if (newState.marketing_sms && newState.marketing_email && newState.marketing_phone && newState.marketing_partners) {
      postData.value = 1;
    } else {
      if (newState.marketing_sms) postData.marketing_receive_sms = 1;
      if (newState.marketing_email) postData.marketing_receive_email = 1;
      if (newState.marketing_phone) postData.marketing_receive_phone = 1;
      if (newState.marketing_partners) postData.marketing_partners = 1;
    }

    //setState(newState);
    props.setMarketingAgreements(postData);
  };

  const backHandler = () => {
    props.history.goBack();
  };

  return (
    <div className="notifications-preference">
      <TitleBar pageTitle={t("Preferences")} backHandler={backHandler} />
      <div className="notifications-preference-content">
        <div className="header">
          {t("Preferinte de marketing")}
        </div>
        <div className="settings notifications-settings">
          <div className="notification-row">
            <div className="row-icon">
              <WantPromotionsIcon />
            </div>
            <div className="row-content">
              <div className="row-title">
                {t("Vreau promotii")}
              </div>
              <div className="row-text">
                {t("Te putem contacta pentru a-ti oferi promotii si bonusuri")}
              </div>
            </div>
            <div className="row-switch">
              {/*
              <Switch
                checked={state.marketing}
                value="marketing"
                onChange={handleChange} />
              */}
            </div>
          </div>
          <div className="row-sep"></div>
          <div className="notification-row">
            <div className="row-icon">
            </div>
            <div className="row-content">
              <div className="row-title">
                {t("SMS")}
              </div>
              <div className="row-text">
              </div>
            </div>
            <div className="row-switch">
              <Switch
                checked={state.marketing_sms}
                value="marketing_sms"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row-sep"></div>
          <div className="notification-row">
            <div className="row-icon">
            </div>
            <div className="row-content">
              <div className="row-title">
                {t("Email")}
              </div>
              <div className="row-text">
              </div>
            </div>
            <div className="row-switch">
              <Switch
                checked={state.marketing_email}
                value="marketing_email"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row-sep"></div>
          <div className="notification-row">
            <div className="row-icon">
            </div>
            <div className="row-content">
              <div className="row-title">
                {t("Phone calls")}
              </div>
              <div className="row-text">
              </div>
            </div>
            <div className="row-switch">
              <Switch
                checked={state.marketing_phone}
                value="marketing_phone"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row-sep"></div>
          <div className="notification-row">
            <div className="row-icon">
            </div>
            <div className="row-content">
              <div className="row-title">
                {t("Partners")}
              </div>
              <div className="row-text">
              </div>
            </div>
            <div className="row-switch">
              <Switch
                checked={state.marketing_partners}
                value="marketing_partners"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row-sep"></div>
        </div>
      </div>
    </div>
  );
}

NotificationsPreference.propTypes = {
  data: PropTypes.object
};

NotificationsPreference.defaultProps = {
  data: {}
};

const mapStateToProps = state => {
  return {
    marketing_agreements: state.alerts.settings.marketing_agreements,
  }
};
const mapDispatchToProps = dispatch => {
  return {
    setMarketingAgreements: params => dispatch(storeActions.setMarketingAgreements(params)),
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationsPreference));