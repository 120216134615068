import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid} from "@material-ui/core";
import Icons from "../../../../../../assets/Icons/Icons";
import {withTranslation} from "react-i18next";


class Details extends Component {
    render() {
        return (
            <Grid container >
                <Grid item xs={12} className={'item'}>
                    <Grid container >
                        <Grid item xs={11}>
                            <div className="icon">
                                {Icons.get('moneyBag')}
                            </div>
                            <div className={'details'}>
                                <div className={"money-type"}>
                                    {this.props.t('Available Funds')}
                                </div>
                                <div className="total">
                                    {this.props.maximum} Lei
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="icon">
                                {Icons.get('green-info')}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={'item'}>
                    <Grid container >
                        <Grid item xs={11}>
                            <div className="icon">
                                {Icons.get('moneyBag')}
                            </div>
                            <div className={'details'}>
                                <div className={"money-type"}>
                                    {this.props.t('Taxes and Fees')}
                                </div>
                                <div className="total">
                                    {this.props.taxes} Lei
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="icon">
                                {Icons.get('green-info')}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

const mapStateToProps = state => {
    return {
        taxes: state.withdrawWizard.taxes,
        maximum: state.wallet.main
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Details));
