import React, { useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import * as storeActions from '../../../store/actions/index';
import './PendingWithdrawals.scss';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import Icons from '../../../assets/Icons/Icons';
import TitleBar from "../../UI/TitleBar/TitleBar";
import PageTitle from '../../UI/PageTitle/PageTitle';
import Button from '../../UI/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import WithdrawalStatus from './WithdrawalStatus/WithdrawalStatus';
import Translate from "../../../utils/Translate";
import {getAllPendingWithdrawals} from "../../../store/actions/index";

const PendingWithdrawals = props => {

    // don't remove this. JS is very stupid and it doesn't work all the translations scripts without it!
    const t = (text, forceTranslation) => {
        if (forceTranslation) {
            return <Translate text={text} />;
        }
        return text;
    };

    useEffect(() => {
        if(props.auth.auth_type !== "user"){
            props.history.push("/");
        }

        props.onRequestLocations();
        props.getAllPendingWithdrawals()

        if (window.config.cordova && window.config.native_platform === "android") {
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!props.withdrawals.loadingCancel) {
            if (Array.isArray(props.withdrawals?.cancelWithdrawal?.result)
                && props.withdrawals.cancelWithdrawal.result.length === 0) {
                switch (props.withdrawals.cancelWithdrawal?.src) {
                    case 'cancel-viva-withdraw':
                        props.onRequestPendingVivaWithdrawals();
                        break;
                    case 'cancel-withdraw':
                        props.onRequestPendingWithdrawals();
                        break;
                    case 'cancel-withdraw-new':
                        props.onRequestPendingWithdrawalsNew()
                        break;
                    default:
                        break;
                }
            }
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [props.withdrawals.loadingCancel]);

    const formatCurrency = (value) => {
        return (
            value
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              .replace(',00', '')
          )
    }

    const viewHistoryHandler = () => {
        props.history.push("/profile/transactions");
    }

    const icons = {
        exclamation: Icons.get('exWarning', `ExclamationIcon`),
        approved: Icons.get('pwSuccess', `ApprovedIcon`),
        pending: Icons.get('wPending', `PendingIcon`),
        history: Icons.get('pwTransactions', `HistoryIcon`),
    };

    let pageContent = <CircularProgress className={'Loading'} />;

    if(!props.withdrawals.loading && !props.withdrawals.loadingLocations){
        
        let noWithdrawals = '';
        let approvedWithdrawals = '';
        let pendingWithdrawals = '';
        let historyWithdrawals = '';

        if(props.withdrawals.approved.length > 0 || props.withdrawals.pending.length > 0 || props.withdrawals.history.length > 0){
            if(props.withdrawals.approved.length > 0){
                approvedWithdrawals = (
                    <Fragment>
                        <PageTitle icon={icons.approved} title={props.t('Ready for cash-in')} subtitle={props.t('Walk into the selected location with your ID')} />
                        <WithdrawalStatus content={props.withdrawals.approved} locations={props.withdrawals.locations} />
                    </Fragment>
                );
            }
            
            if(props.withdrawals.pending.length > 0){
                pendingWithdrawals = (
                    <Fragment>
                        <PageTitle icon={icons.pending} title={props.t('Waiting for approval')} subtitle={props.t('Your requests will be reviewed shortly')} />
                        <WithdrawalStatus content={props.withdrawals.pending} locations={props.withdrawals.locations} />
                    </Fragment>
                );
            }
            
            if(props.withdrawals.history.length > 0){
                historyWithdrawals = (
                    <Fragment>
                        <PageTitle icon={icons.history} title={props.t('Recent transactions')} subtitle={props.t('Withdrawals history')} />
                        <WithdrawalStatus content={props.withdrawals.history} locations={props.withdrawals.locations} />
                    </Fragment>
                );
            }
        }else{
            noWithdrawals = <h5 className={"NoWithdrawals"}>{props.t("No pending withdrawals")}.</h5>;
        }

        pageContent = (
            <Fragment>
                <div className={"TotalAmount"}>
                    {/* <i className={'ExclamationIcon'}>{icons.exclamation}</i> */}
                    <div>
                        <h1>{formatCurrency(props.wallet.main)}<span>&nbsp;Lei</span></h1>
                    </div>
                    <h5>{t('Total Available Funds for Withdrawal',true)}</h5>
                    <Button className={'gradient WithdrawalButton'} full value={props.t('START A WITHDRAWAL')} click={() => props.history.push("/withdraw")}/>
                </div>

                    <div className={"Withdrawals"}>
                        {approvedWithdrawals}
                        {pendingWithdrawals}
                        {historyWithdrawals}
                        {noWithdrawals}
                    </div>
                    <span className={'TransactionsHistory'} onClick={viewHistoryHandler}>{t("See Transaction History",true)}</span>
            </Fragment>
        );
    }



    return (
        <div className={"PendingWithdrawalsScreen"}>
            <TitleBar pageTitle={t('Pending withdrawals')}/>
            <div className={"PendingWithdrawalsContent"}>
                {pageContent}
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        wallet: state.wallet,
        auth: state.authentication,
        currentLanguage: state.application.language,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestPendingWithdrawals: () => dispatch(storeActions.requestPendingWithdrawals()),
        onRequestPendingVivaWithdrawals: () => dispatch(storeActions.requestPendingVivaWithdrawals()),
        getAllPendingWithdrawals: () => dispatch(storeActions.getAllPendingWithdrawals()),
        onRequestLocations: () => dispatch(storeActions.requestLocations()),
        onRequestPendingWithdrawalsNew: () => dispatch(storeActions.requestWithdrawHistory()),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingWithdrawals)));
