import React, { Component } from 'react';
import './PageTitle.scss';

class PageTitle extends Component {
    render () {
        return (
            <div className={"PageTitle"}>
                <div>
                    {this.props.icon ? this.props.icon : null}
                </div>
                <div>
                    <h3>{this.props.title}</h3>
                    {this.props.subtitle ? <h5>{this.props.subtitle}</h5> : null}
                </div>
            </div>
        );
    };
};

export default PageTitle;