import React from 'react';
import { connect } from "react-redux";

import { getMomentumBonuses } from "../store/actions/momentum";

import GameComponent from "../components/game-component/index";

/*
import Animation1 from "../components/animations/star-animation/star-animation";
import Animation2 from "../components/animations/test-animation/test-animation";
*/

const PlayGroundTest = ({ loaded, authentication, getMomentumBonuses }) => {
  React.useEffect(() => {
    if (!loaded && ["user", "token"].indexOf(authentication.auth_type) > -1) {
      getMomentumBonuses();
    }
  }, [loaded, authentication, getMomentumBonuses]);

  return <div>
    {loaded && <GameComponent />}
  </div>;
}

const mapStateToProps = (state, props) => {
  return {
    loaded: state.momentum.prize.loaded,
    authentication: state.authentication,
  }
};

const mapActions = {
  getMomentumBonuses,
};

export default connect(mapStateToProps, mapActions)(PlayGroundTest);