import "typeface-open-sans";
import "../index.scss";
import "../styles/animate.min.css";
//import "../styles/flexUtils.css";

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import NavBar from "../components/NavBar";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Home from "../views/Home";
import PreMatchLayout from "../layout/PreMatchLayout";
import LiveMatchLayout from "../layout/LiveMatchLayout";
import Error404 from "../views/Error404";
import Modals from "../layout/Modals";
import SoundPLayer from "../components/SoundPlayer";

import { prematchInitialize } from "../store/actions/prematch";
import { betsSlipClear } from "../store/actions/betsSlip";
import { liveInitialize } from "../store/actions/live";
import { appInitialize, appBasePath } from "../store/actions/app";
import { requestWallet } from "../../winner-fun/store/actions/wallet";

import { HappyHoursWin, HappyHoursFinal } from "../../common/hh-exports";

const toggledStyle = {
  right: 10
};

const useStyles = makeStyles({
  borderTop: {
    paddingTop: "56px"
  }
});

function DefaultLayout(props) {
  const classes = useStyles();

  const { prematchInitialize, liveInitialize, web, noNavBar, topMenuHeight, basePath, appBasePath, betsSlipClear, requestWallet } = props;

  useEffect(() => {
    document.documentElement.style.overflow = "";
    document.body.classList.add("bets");

    if (basePath === "/winner-fun") {
      document.body.classList.add("winner_fun");
    }

    prematchInitialize();

    if (basePath === "/winner-fun") {
      requestWallet();
    }

    return () => {
      document.body.classList.remove("bets");
      document.body.classList.remove("winner_fun");
      document.body.classList.remove("no-scroll-bets");
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    appBasePath(basePath);
    liveInitialize();
  }, [basePath, appBasePath, liveInitialize]);

  let navbar = null;
  if (!noNavBar) {
    if (!web) {
      navbar = <NavBar />;
    }
  }

  let contentLayout = (
    <React.Fragment>
      {navbar}
      <div className={`page-wrapper bets-entry`}>
        <div className={`${noNavBar ? (topMenuHeight ? topMenuHeight : classes.borderTop) : ""}`}></div>
        <Switch>
          <Route exact path={basePath} component={Home} />
          <Route path={`${basePath}/lobby/:path+`} component={Home} />
          <Route path={`${basePath}/pre-match`} component={PreMatchLayout} />
          <Route path={`${basePath}/live-match`} component={LiveMatchLayout} />
          <Route component={Error404} />
        </Switch>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <HappyHoursWin event_type={`${basePath === "/pariuri" ? "" : "winner-fun-"}bets-prematch`} />
      <HappyHoursWin event_type={`${basePath === "/pariuri" ? "" : "winner-fun-"}bets-live`} />
      {contentLayout}
      {!window.config.cordova && <Modals />}
      <ScrollUpButton ToggledStyle={toggledStyle} />
      <SoundPLayer />
      <HappyHoursFinal event_type={`${basePath === "/pariuri" ? "" : "winner-fun-"}bets-live`} />
      <HappyHoursFinal event_type={`${basePath === "/pariuri" ? "" : "winner-fun-"}bets-prematch`} />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  topMenuHeight: state.application.topMenuHeight,
});

const actionCreators = {
  appInitialize,
  prematchInitialize,
  liveInitialize,
  betsSlipClear,
  appBasePath,
  requestWallet
};

export default connect(mapStateToProps, actionCreators)(DefaultLayout);
