import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import * as paths from './../../ClientAPI/paths';
import Utils from "../../utils/Utils";

export function* getMarketingOfferSaga(action) {
    const axios = yield Utils.getAxiosWithToken();
    try {

        let data = {
            productId: action.productId ? action.productId : 1
        };

        if (action.moid) {
            data.moid = action.moid
        }

        const response = yield axios({
            url: paths.marketing_offer.GET,
            method: 'post',
            data: data,
        });

        if (!response) {
            throw new Error(`[ERROR] Send Email response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Send Email response has no 'result' property`);
        }

        yield put(actions.setMarketingOffer(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}