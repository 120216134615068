import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TitleBar from '../../../UI/TitleBar/TitleBar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import CurrentPassword from './CurrentPassword/CurrentPassword';
import NewPassword from './NewPassword/NewPassword';
import './ChangePassword.scss';

class ChangePassword extends Component {

    state = {
        currentStep: 1,
        error: null,
        currentPassword: null,
    }

    nextScreenHandler = (value, currentPassword = null) => {
        if(value === true){
            this.setState({
                ...this.state,
                currentStep: 2,
                currentPassword: currentPassword,
            });
        }
    }

    validatePasswordHandler = (value, error = null) => {
        if(value === false){
            this.setState({
                ...this.state,
                currentStep: 1,
                error: error,
            });
        }
    }

    render() {

        let pageContent = <CurrentPassword next={(value, currentPassword) => this.nextScreenHandler(value, currentPassword)} error={this.state.error}/>;

        if(this.state.currentStep === 2){
            pageContent = <NewPassword validate={(value, error) => this.validatePasswordHandler(value, error)} currentPassword={this.state.currentPassword}/>
        }

        return (
            <div className={"ChangePasswordScreen"}>
                <TitleBar pageTitle={this.props.t('Change Password')} />
                <AppBar position="static" className="SubtitleBar">
                    <Typography variant="subtitle1">{this.props.t('STEP ') + this.state.currentStep + this.props.t(' OF 2')}</Typography>
                </AppBar>
                <div className="ChangePasswordContent">
                    {pageContent}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));