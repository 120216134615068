import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Grid, List } from '@material-ui/core';
import { connect } from 'react-redux';
import * as storeActions from "./../../store/actions";
import './LeftMenu.scss';

import LeftMenuItem from "./LeftMenuItem/LeftMenuItem";
import LanguageSwitcher from "../GlobalWidgets/Both/LanguageSwitcher/LanguageSwitcher";
import Betting from "../ComingSoon/Betting/Betting";
import BetsLayoutSwitcher from "../../bets/components/BetsLayout";
import Button from "@material-ui/core/Button";
import Icons from "../../../src/assets/Icons/Icons";
import LiveChatContainer from "../GlobalWidgets/Both/LiveChatContainer/LiveChatContainer";

const styles = theme => ({
    root: {
        zIndex: "1302!important",
    },
    paper: {
        background: "#121921",
        color: "white",
    }
});

const LeftMenu = (props) => {
    const winterStatus = props.winterStatus;
    const classes = props.classes;
    const { t } = useTranslation();
    const variant = 'temporary';

    const closeHandler = () => event => {
        console.log(t("Closing left menu!"));
        props.closeLeftMenu();
    };

    const handleChange = () => {
        props.closeLeftMenu();
    };

    const icons = {
        eldorado: (<img src={window.location.protocol + "//" + window.config.cdn_address + window.config.MOBILE_BRAND_LOGO} alt="" />),//Icons.get('casinoLogo', `casinoLogo`),
    };

    const logoClickHandler = () => {
        props.history.push("/");
        props.closeLeftMenu();
    };

    let logo = [];

    if (window.config && window.config.old_site && window.config.old_site !== "false") {
        logo.push(
            <div className={`leftMenuHeader ${props.application.topMenuHeight} columns-2 ${window.config.showTopMessageWebsiteSwitcher !== '1' ? '' : 'websiteSwitcher'}`} key={"logo_old_site"}>
                <div className={'logoCell'} onClick={logoClickHandler}>
                    {icons.eldorado}
                </div>
                <div>
                    <Button variant="contained" size="small" color="secondary" className={`old_site`} onClick={() => { window.location = window.config.old_site }}>
                        {t("Old site")}
                    </Button>
                </div>
            </div>
        );
    } else {
        logo.push(
            <div className={`leftMenuHeader ${props.application.topMenuHeight} ${window.config.showTopMessageWebsiteSwitcher !== '1' ? '' : 'websiteSwitcher'}`} onClick={logoClickHandler} key={"logo_no_old_site"}>
                {icons.eldorado}
            </div>
        );
    }

    let cazino = [];
    if (window.config && window.config.lobbyEnabled === "1") {
        let rootPath = "cazino";
        if (window.config.rootPaths && window.config.rootPaths.casino) {
            rootPath = window.config.rootPaths.casino;
        }
        cazino.push(<LeftMenuItem key="cazino-link" pageType={'root-level'} pageCode={rootPath} />);
        if (window.config && typeof window.config.spribe_promo_side_menu !== 'undefined' && window.config.spribe_promo_side_menu !== '0') {
            cazino.push(<LeftMenuItem key="spribe-aviator" widgetType={'aviator'} pageType={'spribe-aviator'} pageCode={'play/' + window.config.spribe_promo_side_menu} />);
        }
        cazino.push(<LeftMenuItem key="cazino-link-jackpot" pageType={rootPath} pageCode={'jackpot'} />);
    } else {
        cazino.push(<LeftMenuItem key="cazino-link" pageType={'root-level'} pageCode={'cazino'} />);
        if (window.config && typeof window.config.spribe_promo_side_menu !== 'undefined' && window.config.spribe_promo_side_menu !== '0') {
            cazino.push(<LeftMenuItem key="spribe-aviator" widgetType={'aviator'} pageType={'spribe-aviator'} pageCode={'play/' + window.config.spribe_promo_side_menu} />);
        }
        cazino.push(<LeftMenuItem key="cazino-link-jackpot" pageType={'cazino'} pageCode={'jackpot'} />);
    }

    let itemBF = [];
    let now = parseInt(new Date().getTime() / 1000)
    if (now > 1636668000 && now < 1637963999 && window.config.brand_name_short === 'Winner') {
        itemBF.push(<LeftMenuItem key="bf" pageType={'black-friday'} pageCode={'/casino/promotii'} buttonText={t("Black Friday")} />);
    }
    if (window.config && window.config.promoEnabled === "1" && winterStatus && winterStatus.campaign_settings && winterStatus.campaign_settings.winter) {
        if (winterStatus.campaign_settings.winter.start_date && winterStatus.campaign_settings.winter.end_date) {
            const now = new Date().getTime();
            const start_start = winterStatus.campaign_settings.winter.start_date;
            const end_date = winterStatus.campaign_settings.winter.end_date;

            if (start_start <= now && now <= end_date) {
                itemBF.push(<LeftMenuItem key="wt" pageType={'winter-time'} pageCode={'/winter-time'} buttonText={t("X-Mas Tree Promo")} />);
            }
        }
    }

    let winnerFun = [];
    if (window.config && window.config.winnerFunEnabled === "1" && window.config.brand_name_short === "Winner") {
        winnerFun.push(<LeftMenuItem key="winnerfun" pageType={'winner-fun'} pageCode={'winner-fun'} buttonText={t("Winner FUN")} />);
    }

    let spribe = [];
    if (window.config && typeof window.config.spribe_promo_side_menu !== 'undefined' && window.config.spribe_promo_side_menu !== '0') {
        spribe.push(<LeftMenuItem key="spribe-aviator" widgetType={'aviator'} pageType={'spribe-aviator'} pageCode={'play/' + window.config.spribe_promo_side_menu} />);
    }

    let betting = [];
    if (window.config && window.config.betsEnabled === "1" && window.config.brand_name_short === "Winner") {
        betting.push(<LeftMenuItem key="pariuri_prematch" pageType={'root-level'} pageCode={'pariuri'} buttonText={t("Sports")} />);
        betting.push(<LeftMenuItem key="pariuri_live" pageType={'root-level'} pageCode={'pariuri/live-match/highlights'} buttonText={t("LIVE Betting")} />);
        betting.push(<BetsLayoutSwitcher key={'pariuri_layout_switcher'} onChange={handleChange} />);
    } else if (window.config && window.config.nsoftEnabled === "1") {
        betting.push(<LeftMenuItem key="pariuri_prematch" pageType={'root-level'} pageCode={'pariuri/prematch'} buttonText={t("Sports")} />);
        betting.push(<LeftMenuItem key="pariuri_live" pageType={'root-level'} pageCode={'pariuri/live'} buttonText={t("LIVE Betting")} />);
    } else {
        betting.push(<LeftMenuItem key={'pariuri_link'} pageType={'root-level'} pageCode={'pariuri'} buttonText={t("Betting")} />);
        if (window.config && window.config.betsEnabled === "1") {
            betting.push(<BetsLayoutSwitcher key={'pariuri_layout_switcher'} onChange={handleChange} />);
        }
    }

    if (window.config && window.config.lottoEnabled === "1") {
        betting.push(<LeftMenuItem key="lotto" pageType={'lotto'} pageCode={'lotto'} buttonText={t("Lotto")} />)
    }
    // window.config.iframe_display_status = 'on';
    // window.config.iframe_name_display = 'Test';
    // window.config.iframe_display_link = 'http://lp.winner.ro/bonus-de-bun-venit-pariuri/';
    return (
        <Grid item>
            <Drawer anchor="left" variant={variant} open={props.menu.leftMenu} onClose={closeHandler()} classes={{ root: classes.root, paper: classes.paper }}>

                {logo}

                <button className={"menuLeftCloseButton"} onClick={props.closeLeftMenu}>x</button>
                <div className="left-menu-content">
                    <List className={"leftMenuDrawer"}>
                        <LanguageSwitcher />
                    </List>
                    <List className={"leftMenuDrawer"}>
                        {itemBF}
                        {winnerFun}
                        {cazino}

                        {betting}
                        {window.config.liveCasinoEnabled === '1' &&
                            (<LeftMenuItem pageType={'root-level'} pageCode={'cazino_live'} buttonText={t("LIVE Casino")} />)}
                        {/*<LeftMenuItem pageType={'root-level'} pageCode={'table-games'} anchor={'roulette'} buttonText={t("Table Games")} />*/}
                        {window.config && window.config.virtualsEnabled === "1" && (
                            <LeftMenuItem pageType={'root-level'} pageCode={'virtuale'} buttonText={t("Virtual Games")} />
                        )}
                    </List>
                    <List className={"leftMenuDrawer"}>
                        <LeftMenuItem pageType={'cazino'} pageCode={'promotii'} buttonText={t('Promotions')} />
                        {/*<LeftMenuItem pageType={'cazino'} pageCode={'comoara_piratului'} buttonText={"Comoara Piratilor"} customTextClass={"secColor"} />*/}
                        {/*<LeftMenuItem pageType={'cazino_live'} pageCode={'live-games'} buttonText={"VIP Club"} customTextClass={"secColor"} />*/}
                    </List>
                    <List className={"leftMenuDrawer"}>
                        {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <LeftMenuItem pageType={'root-level'} pageCode={'locations'} buttonText={t("Locations")} />}
                        {window.config && window.config.okto_enabled === '1' && <LeftMenuItem pageType={'root-level'} noIcon={true} pageCode={'deposit-oktocash'} buttonText={t("Cash Deposit")} customTextClass={"thinName secColor"} />}
                        <LeftMenuItem pageType={'customer-support'} pageCode={'faq'} buttonText={t("FAQ")} noIcon={true} customTextClass={"thinName secColor"} />
                        {/*<LeftMenuItem pageType={'customer-support'} pageCode={'complaint-form'} buttonText={t("Have an issue?")} noIcon={true} customTextClass={"thinName secColor"} />*/}
                        <LeftMenuItem pageType={'customer-support'} pageCode={'terms&conditions'} buttonText={t("Terms & Conditions")} noIcon={true} customTextClass={"thinName secColor"} />
                        <LeftMenuItem pageType={'customer-support'} pageCode={'politicadeconfidentialitate'} buttonText={t("Privacy Policy")} noIcon={true} customTextClass={"thinName secColor"} />
                        <LeftMenuItem pageType={'customer-support'} pageCode={'responsible-gaming'} buttonText={t("Responsible Gaming")} noIcon={true} customTextClass={"thinName secColor"} />
                        <LeftMenuItem pageType={'customer-support'} pageCode={'payment-methods'} buttonText={t("Payment Methods")} noIcon={true} customTextClass={"thinName secColor"} />
                        <LeftMenuItem pageType={'customer-support'} pageCode={'bonus-rules'} buttonText={t("Bonus rules")} noIcon={true} customTextClass={"thinName secColor"} />
                        <LeftMenuItem pageType={'customer-support'} pageCode={'game-rules'} buttonText={t("Game Rules")} noIcon={true} customTextClass={"thinName secColor"} />
                        <LeftMenuItem pageType={'customer-support'} pageCode={'about-us'} buttonText={t("About us")} noIcon={true} customTextClass={"thinName secColor"} />
                        <LiveChatContainer />
                        <br />
                        <LeftMenuItem pageType={'connect'} pageCode={'authenticate'} buttonText={t("Log In")} customTextClass={"thinName secColor underLine"} widgetType={"auth"} />

                    </List>
                </div>
            </Drawer>
        </Grid>
    );
};

const mapStateToProps = state => {
    return {
        menu: state.menu,
        application: state.application,
        winterStatus: state && state.promo && state.promo.winter && state.promo.winter.status ? state.promo.winter.status : null,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openLeftMenu: () => dispatch(storeActions.openLeftMenu()),
        closeLeftMenu: () => dispatch(storeActions.closeLeftMenu()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeftMenu)));
