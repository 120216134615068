import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StepWizard from 'react-step-wizard';
import './Login.scss';
import Username from './Username/Username';
import Password from './Password/Password';
import TitleBar from '../UI/TitleBar/TitleBar';
import { withTranslation } from 'react-i18next';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import TableGamesImage from '../../assets/Images/Home/tile_tablegames.jpg';
import Card from '@material-ui/core/Card';
import * as storeActions from "../../store/actions";

class Login extends Component {
	constructor(props) {
		super(props);
		this.redirect = localStorage.getItem('redirect_after_login');
		localStorage.setItem("redirect_after_login", "");
		this.allow = true;
	}

	componentDidMount() {
		this.checkAuthType();
	}

	componentDidUpdate(prevProps) {
		this.checkAuthType();
	}

	componentWillUnmount() {
		this.props.resetLoginErrors();
	}

	checkAuthType() {
		if ('user' === this.props.authentication.auth_type) {

			this.props.history.push(this.redirect && this.redirect !== '/connect' ? this.redirect : '/');
			this.allow = false;
			return;
		}

		this.allow = true;
	}

	clickHandler() {
		this.props.history.push('/');
	}

	backHandler() {
		// console.log('test1234', this.props);
		this.clickHandler();
	}

	render() {
		if (!this.allow) {
			return null;
		}

		return (
			<div className={'loginWizard'}>
				<TitleBar
					pageTitle={this.props.t('Log in')}
					closeButton={true}
					backHandler={this.backHandler.bind(this)}
				/>
				<Card className={'promo'}>
					<CardActionArea onClick={this.clickHandler.bind(this)}>
						<CardMedia
							image={`${window.config.front_url}/img/mobile/login/login_banner.jpg`}
							className={`cardImage`}
							src={TableGamesImage}
							title={'table games'}
							component={'img'}
						/>
					</CardActionArea>
				</Card>
				<StepWizard className='StepWizard'>
					<Username />
					<Password />
				</StepWizard>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		resetLoginErrors: () => {
			dispatch(storeActions.loginUsernameError(false));
			dispatch(storeActions.loginPasswordError(false));
		}
	};
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
