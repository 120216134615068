import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import  "./Countdowns.scss";
/*
    CustomProps:
        date - timestamp - Obrigatory
        className - string - Optional
        expiredMessage - string - Optional
        icon - svg - Optional
*/
export const TYPE_COMPACT = 1;
export const TYPE_COMPACT_LAST_CHANCE = 2;

const Countdown = (props) => {

    const [timer, setTimer] = useState({
        expired: false,
        loading: true,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        if(props.date){
            let countdownDate = props.date * 1000;
        
            let x = setInterval(() => {
                let now = new Date().getTime();
                let distance = countdownDate - now;

                let days = zeroHandler(Math.floor(distance / (1000 * 60 * 60 * 24)));
                let hours = zeroHandler(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                let minutes = zeroHandler(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
                let seconds = zeroHandler(Math.floor((distance % (1000 * 60)) / 1000));

                if (distance < 0) {
                    clearInterval(x);
                    setTimer({expired: true});
                }else{
                    setTimer({
                        expired: false,
                        loading: false,
                        days: days,
                        hours: hours,
                        minutes: minutes,
                        seconds: seconds,   
                    });
                }
            }, 1000);
            
            return () => {
                clearInterval(x);
            }
        }
        // eslint-disable-next-line
    }, []);

    const zeroHandler = (number) => {
        if(number >=0 && number <= 9){
            return number.toString().padStart(2, '0');
        }
        return number;
    }

    let pageContent = ''; 

    if(!timer.expired && !timer.loading){
        let dayText = timer.days === "01" ? props.t("day") : props.t("days");
        switch (props.type) {
            case TYPE_COMPACT:
                pageContent = (
                    <time className={"CountdownTimer"}>
                        <span className={"Days"}>{timer.days + " " + dayText}</span>
                        <span className="time">
                            <span className={"Hours"}>{timer.hours}</span>
                            <span className="time-separator">:</span>
                            <span className={"Minutes"}>{timer.minutes}</span>
                            <span className="time-separator">:</span>
                            <span className={"Seconds"}>{timer.seconds}</span>
                        </span>
                    </time>
                );
                break;
            case TYPE_COMPACT_LAST_CHANCE:
                let lastChance = timer.days === "00";
                pageContent = (
                    <time className={"CountdownTimer type-" + parseInt(props.type) + " " + (lastChance ? "last-chance" : "") }>
                        <span className={"Days"}>{lastChance ? props.t("Last chance") : timer.days + " " + dayText}</span>
                        <span className="time">
                            <span className={"Hours"}>{timer.hours}</span>
                            <span className="time-separator">:</span>
                            <span className={"Minutes"}>{timer.minutes}</span>
                            <span className="time-separator">:</span>
                            <span className={"Seconds"}>{timer.seconds}</span>
                        </span>
                    </time>
                );
                break;
            default:
                pageContent = (
                    <time className={"CountdownTimer"}>
                        {
                            timer.days === "00"
                                ?   <span className={"Days"}>{timer.days + " " + dayText}</span>
                                :   timer.days !== "00"
                                ?   <span className={"Days"}>{timer.days + " " + dayText}</span>
                                :   null
                        }
                        <span className={"Hours"}>{timer.hours + " : "}</span>
                        <span className={"Minutes"}>{timer.minutes + " : "}</span>
                        <span className={"Seconds"}>{timer.seconds + " " + props.t("sec")}</span>
                    </time>
                );
                break;
        }
    } else if(timer.loading){
        pageContent = (
            <time className={"CountdownTimer"}>
                <span className={"Loading"}>{props.t("Loading")}</span>
            </time>
        ); 
    }else{
        pageContent = (
            <time className={"CountdownTimer"}>
                <span className={"Expired"}>{props.expiredMessage ? props.t(props.expiredMessage) : props.t("Expired")}</span>
            </time>
        ); 
    }

    return (
        <div className={"CountdownScreen " + (props.className ? props.className : "")}>
            <i className={"CountdownIcon"}>{props.icon ? props.icon : null}</i>
            {pageContent}
        </div>
    );
};

export default withTranslation()(Countdown);