import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";

import JackpotCounter from "../NewCounter";
import TitleBar from "./../../UI/TitleBar/TitleBar";

import BackgroundImage from "./../../../assets/Images/Jackpot/img-jackpots-pagebg@3x.png";
import BackgroundImageHeader from "./../../../assets/Images/Jackpot/img-bg-jackpot-header@3x.png";
import Utils from "../../../utils/Utils";
import { ucFirst } from "./../utils/formatters";

import "./JackpotHeader.scss";

class JackpotHeader extends Component {
    constructor(props) {
        super(props);
        this.jackpotBg = React.createRef();
        this.jackpotHeader = React.createRef();
        this.jackpotHeaderContent = React.createRef();

        this.scrolling = false;
        window.addEventListener("scroll", this.handleScroll, true);
        const loop = () => {
            if (this.scrolling) {
                this.scrolling = false;
                this.updateBg();
            }
            this.animationFrameId = window.requestAnimationFrame(loop);
        };
        this.animationFrameId = window.requestAnimationFrame(loop);
        this.updateBg();
    }

    handleScroll = () => {
        this.scrolling = true;
    };

    updateBg = () => {
        const lastScrollY = window.scrollY;

        if (this.jackpotHeaderContent && this.jackpotBg && this.jackpotHeader) {
            const jackpotHeaderContent = this.jackpotHeaderContent.current;
            const jackpotBg = this.jackpotBg.current;
            const jackpotHeader = this.jackpotHeader.current;

            if (!jackpotHeaderContent && !jackpotBg && !jackpotHeader) {
                return;
            }

            const limit = 146;
            const bgHeight = jackpotBg.offsetHeight;

            if (lastScrollY <= limit) {
                jackpotHeaderContent.style.backgroundColor = ``;
                jackpotHeader.style.zIndex = ``;
                /*
                 bgHeight * .6 - calculate on based on 60% width of the bgHeight 
                        - from 60% vertical the white background begins
                 (lastScrollY * 100 / limit) - calculated percent based on scroll 
                        - how much to move up the bg before enabling the white background of the jackpot holder
                */
                const newTop = 48 + -((bgHeight * 0.6 * ((lastScrollY * 100) / limit)) / 100);
                jackpotBg.style.top = `${newTop}px`;
                jackpotBg.style.opacity = ``;
            } else if (lastScrollY > limit) {
                jackpotHeaderContent.style.backgroundColor = `#f2f2f2`;
                jackpotHeader.style.zIndex = 51;
                // needs to use opacity instead of display:none because when hidden (display:none)
                // the element height is zero
                jackpotBg.style.opacity = `0`;
            }
        }
    };

    componentWillUnmount() {
        window.cancelAnimationFrame(this.animationFrameId);
        window.removeEventListener("scroll", this.handleScroll);
    }

    backHandler = () => {
        this.props.history.push("/");
    };

    // don't remove this. It helps with translation scripts.
    t = text => {
        return text;
    };

    render() {
        let value = this.props.value;
        const currency = ucFirst(this.props.currency);
        return (
            <div className="jackpot-header" ref={this.jackpotHeader}>
                <div className="jackpot-bg" ref={this.jackpotBg}>
                    <img src={Utils.getImageUrl(BackgroundImage)} alt="" />
                </div>
                <TitleBar pageTitle={this.t("All Jackpots")} backHandler={this.backHandler} />
                <div
                    className="jackpot-header-content"
                    onClick={this.props.onClick}
                    ref={this.jackpotHeaderContent}
                >
                    <div
                        className="wrapper"
                        style={{
                            backgroundImage: `url(${Utils.getImageUrl(BackgroundImageHeader)})`
                        }}
                    >
                        <div className="jackpot-img">
                            <img src={Utils.getImageUrl(this.props.logo)} alt="Jackpot Logo" />
                        </div>
                        <div className="jackpot-name">{this.props.name}</div>
                        {!!value && (
                            <div className="jackpot-counter">
                                <JackpotCounter value={value / 100} storageKey="EGT_0" />
                                <span className="currency">{currency}</span>
                            </div>
                        )}
                        {!!!value && (
                            <div className="loader">
                                <CircularProgress color="primary" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

JackpotHeader.propTypes = {
    onClick: PropTypes.func,
    logo: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
    currency: PropTypes.string
};

JackpotHeader.defaultProps = {
    onClick: () => {},
    logo: "",
    name: "",
    currency: "lei"
};

export default withRouter(JackpotHeader);
