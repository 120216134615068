import * as actionTypes from './actionTypes';

export const openLeftMenu = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.menu.LEFT_OPEN,
        });
    };
};

export const closeLeftMenu = () => {
    return {
        type: actionTypes.menu.LEFT_CLOSE,
    };
};

export const requestMenu = () => {
    return {
        type: actionTypes.menu.REQUEST_MENU,
    }
};

export const receivedMenu = (items) => {
    return {
        type: actionTypes.menu.RECEIVED_MENU,
        items: items,
    };
};

export const createPageTypes = (items) => {
    return {
        type: actionTypes.menu.CREATE_PAGE_TYPES,
        items: items
    };
};

export const toggleTopMenu = (show = false) => {
    return {
        type: actionTypes.menu.TOP_MENU_SHOW,
        value: show,
    }
};

export const openRightMenu = () => {
    return {
        type: actionTypes.menu.RIGHT_OPEN
    };
};

export const closeRightMenu = () => {
    return {
        type: actionTypes.menu.RIGHT_CLOSE
    }
};

export const rightProfileSection = (show = true) => {
    return {
        type: actionTypes.menu.SHOW_PROFILE_SECTION,
        show: show,
    }
};