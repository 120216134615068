import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import Password from "../../Form/Password/Password";
import ConfirmButton from "../elements/ConfirmButton";
import {withTranslation} from "react-i18next";
import * as storeActions from "../../../../store/actions";
import "./Step3.scss";
import * as stateActions from "../../../../store/actions";
import Loading from "../../../CasinoGames/sections/Loading/Loading";

class Step3 extends Component
{
    state = {
        signUpStarted: false
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.signedUpErrors !== prevProps.signedUpErrors) {
            this.setState({
                ...this.state,
                signUpStarted: false
            })
        }
    }

    signUp() {
        this.setState({
            signUpStarted: true
        });
        this.props.signUp();
    }

    render() {

        return (
            <div className={'fields step-3'}>
                <div className="title">{this.props.t("Alege o parolă")}</div>
                <div className="subtitle">{this.props.t("Și esti gata să activezi bonusul")}</div>
                <div className={'email-placeholder'}>{this.props.email}</div>
                <Password/>
                {this.state.signUpStarted &&
                <div className={'loading-screen'} >
                    <Loading/>
                </div>}
                {!this.state.signUpStarted && <ConfirmButton {...this.props} onClickFunction={this.signUp.bind(this)} preventNext={true}/>}
            </div>
        );
    }
}

const mapStateToProps = state => {

    // fields for all brands
    let error = !(state.register.validated.password  && state.register.validated.terms);

    // confirm password field
    if (window.config.register_disable_confirm_password !== '1' && !error) {
        error = !(state.register.validated.confirmPassword);
    }

    if(typeof error === 'undefined') {
        error = true;
    }

    return {
        error: error,
        email: state.register.fields.email,
        signedUpErrors: state.register.signedUpErrors
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered(fieldInput)),
        signUp: () => dispatch(stateActions.signUp()),
    };
};

export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(Step3));
