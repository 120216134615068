/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 20/02/2019
 * Time: 14:11
 */
import React, {Component} from 'react';
import Utils from "../../../../../../src/utils/Utils";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as storeActions from "../../../../../store/actions";

class ChatBubble extends Component {

    constructor(props) {
        super(props);
        this.scriptId = 'la_x2s6df8d';
        this.bubbleId = props.parentId;
        this.renderer = props.buttonId;
    }

    state = {
        loaded: false
    };

    componentDidMount() {
        this.loadChat();
        this.props.history.listen(this.onRouteChange.bind(this));
    }

    onRouteChange() {
        if (this.isWhitelisted()) {
            this.loadChat();
        } else {
            this.destroyBubble();
        }
    }

    isWhitelisted() {

        if (!(["user", "token"].indexOf(this.props.authentication.auth_type) > -1)) {
            return false;
        }

        if (this.props.whitelist) {
            return this.props.whitelist.includes(this.props.history.location.pathname);
        }

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.player !==  this.props.player  ) {
            this.reset();
        }
    }

    componentWillUnmount() {
        this.unloadChat();
    }

    getPlatform() {
        switch (window.config.platformType) {
            case "1":
            case 1:
                return 'Web';
            case "2":
            case 2:
                return 'Mobile';
            case "3":
            case 3:
                return 'iOS';
            case "4":
            case 4:
                return 'Android';
            default:
                return 'Unknown';
        }
    }

    shouldBeLoaded() {
        if (!this._chat) {
            if (window.LiveAgent) {
                this._chat = window.LiveAgent;
            } else {
                this.loadChat();
                return true;
            }
        }
        return false;
    }

    setPlayerDetails() {
        if (this.shouldBeLoaded()) {
            return;
        }

        if (this.props.player !== null && this.props.player.client_player_id) {

            this._chat.setUserDetails(this.props.player.email,this.props.player.first_name,this.props.player.last_name,this.props.player.phone);
        } else {
            this._chat.setUserDetails();
        }
    }

    setPlatformDetails() {
        if (this.shouldBeLoaded()) {
            return;
        }
        this._chat.addTicketField('platform_type', this.getPlatform());
        this._chat.addTicketField('brand', window.config.brand_name_short);
        this._chat.addContactField('brand', window.config.brand_name_short);
        this._chat.addTicketField('client_player_id',this.props.player.client_player_id);
        this._chat.addTicketField('language',this.props.language);
    }

    clearEverything() {
        if (!this._chat) {
            if (window.LiveAgent) {
                this._chat = window.LiveAgent;
            } else {
                return this.loadChat();
            }
        }

        this._chat.clearAllUserDetails();
    }

    reset() {

        this.clearEverything();

        this.setPlayerDetails();
        this.setPlatformDetails();
    }

    destroyBubble() {
        console.log('bubble destroyed!');
        if(document.getElementById(this.bubbleId)) {
            if (document.getElementById(this.bubbleId).childNodes) {
                if(typeof document.getElementById(this.bubbleId).childNodes[0] !== "undefined") {
                    document.getElementById(this.bubbleId).childNodes[0].remove();
                }
            }
        }
        if(document.getElementById("ic" + this.bubbleId)) {
            document.getElementById("ic" + this.bubbleId).parentNode.removeChild(document.getElementById("ic" + this.bubbleId));
        }
        if(document.getElementById("if" + this.bubbleId)) {
            document.getElementById("if" + this.bubbleId).parentNode.removeChild(document.getElementById("if" + this.bubbleId));
        }

        if(this._chat && !this._chat.instance) {

            this._chat.instance.elements = [];
            this._chat.instance.widgets = [];
        } else {
            // what to do?
        }

        this.setState({
            ...this.state,
            loaded: false
        })
    }

    showBubble() {

        if(document.getElementById(this.bubbleId + "-inner")) {
            document.getElementById(this.bubbleId + "-inner").remove();
        }

        let newElement = document.createElement('div');
        newElement.id = this.bubbleId + '-inner';
        document.getElementById(this.bubbleId).innerHTML = "";
        document.getElementById(this.bubbleId).appendChild(newElement);
        let el = document.getElementById(this.bubbleId + "-inner");
        let button = this._chat.createButton(this.renderer, el);
        button.oldOnClick = button.onClick;
        button.oldOnCloseFunction_ = button.onCloseFunction_;

        let _self = this;
        button.onClick = function() {
            button.oldOnClick();
            _self.props.closeLeftMenu();
            console.log('bubble clciked!',window.LiveAgent.instance.hasOpenedWidget());
        };
        button.onCloseFunction_ = function() {
            button.oldOnCloseFunction_();
            //put here your javascript code executed after chat is closed
            console.log('bubble closed!',window.LiveAgent.instance.hasOpenedWidget());

        };
        console.log('bubble initialized!');
    }

    init() {
        if (window.LiveAgent) {
            this._chat = window.LiveAgent;
            this.setPlayerDetails();
            this.setPlatformDetails();
            this.showBubble();
            this.setState({
                ...this.state,
                loaded: true
            })
        } else {
            setTimeout(this.init.bind(this),500);
        }
    }


    unloadChat() {
        let el = document.getElementById(this.scriptId);
        if (el) {
            el.remove();
        }
        this.clearEverything();
    }

    loadChat() {
        if (this.state.loaded) return;

        if (!this.isWhitelisted()) {
            return this.destroyBubble();
        }
        let el = document.getElementById(this.scriptId);
        if(!el) {
            const src = 'https://playonline.ladesk.com/scripts/track.js';
            let _self = this;
            let lastScript = document.scripts[document.scripts.length - 1];
            let script = document.createElement('script');
            script.id = this.scriptId;
            script.async = true;
            script.src = src;
            script.onload = script.onreadystatechange = function() {

                if(this.readyState && (this.readyState!=='complete') && (this.readyState!=='loaded')){
                    return;
                }
                _self.init();
            };
            lastScript.parentElement.insertBefore(script,lastScript.nextSibling)
        } else {
            Utils.delayedFunction('initLA',this.init.bind(this));
        }

    }

    render() {
        return (
            <> </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeLeftMenu: () => dispatch(storeActions.closeLeftMenu()),
    }
}

const mapStateToProps = state => {
    return {
        player: state.profile,
        language: state.application.language,
        authentication: state.authentication
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatBubble));