import React, {Component} from 'react';
// import Withdraw from "./Withdraw/Withdraw";

class LoggedIn extends Component {
    render() {

        return (
            <div>
                {/*<Withdraw/>*/}
            </div>
        );
    }
}

export default LoggedIn;
