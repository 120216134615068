import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import * as storeActions from "../../../../../../store/actions";
import {Grid} from "@material-ui/core";
import {PAYMENT_ADD_IBAN, PAYMENT_IBAN, WITHDRAW_STEPS} from "../PaymentMethods";
import Button from "@material-ui/core/Button";

class BankTransfer extends Component {

    state = {
        paymentMethods: [],
        selectedIBAN: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.paymentMethods !== this.props.paymentMethods) {
            this.generatePaymentMethods();
        }
    }

    generatePaymentMethods() {
        let payments = [];
        console.log(this.props.paymentMethods);
        Object.keys(this.props.paymentMethods).forEach((key) => {
            if (this.props.paymentMethods[key].withdrawType === PAYMENT_IBAN) {
                payments.push(this.props.paymentMethods[key]);
            }
        });

        if (payments.length > 0) {
            this.setState({
                ...this.state,
                paymentMethods: payments,
                selectedIBAN: payments.length === 1 ? payments[0] : this.state.selectedIBAN

            });
        }

    }

    selectIBAN(iban) {
        this.setState({
            ...this.state,
            selectedIBAN: iban
        });
    }

    setStep(step) {
        this.props.setStep(step);
        this.props.goToStep(step);
    }

    renderPayments() {
        let buttons = [];
        let payments = this.state.paymentMethods;

            buttons.push(<li
                key={'add-iban'}
                className={'payment-method'} onClick={() => this.setStep(WITHDRAW_STEPS[PAYMENT_ADD_IBAN])}>
                Adauga IBAN
            </li>);
        payments.map((payment) => {
            buttons.push(<li
                key={payment.withdrawMethodDetails.iban}
                className={'payment-method' + (payment.withdrawMethodDetails?.iban === this.state.selectedIBAN?.withdrawMethodDetails?.iban ? ' selected' : '')} onClick={() => this.selectIBAN(payment)}>
                <div className={'name'}>
                    {payment.withdrawMethodDetails.beneficiaryName}
                    <small>{payment.withdrawMethodDetails.iban}</small>
                </div>
            </li>);
        });
        return (<ul className={'payment-method-reference-list'} key={'iban-list'}>
            {buttons}
        </ul>);
    }

    withdraw() {
        // get amount
        this.props.withdraw(this.props.amount, this.state.selectedIBAN.withdrawType, this.state.selectedIBAN.withdrawTypeReference);
    }

    render() {
        return (
            <Grid item xs={12} className={'content-step'}>
                <Grid container>
                    {this.renderPayments()}
                    <Grid item xs={12} className={'buttons'}>
                        <Button
                            variant="contained"
                            disabled={this.state.selectedIBAN === null}
                            className={'w-100 nextButtonOnline'}
                            onClick={() => this.withdraw()}
                            >
                            {this.props.t('Withdraw')}

                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setStep: (step) => dispatch(storeActions.setWithdrawWizardStep(step)),
        withdraw: (amount, withdrawType,withdrawTypeReference) => dispatch(storeActions.withdraw(amount,withdrawType,withdrawTypeReference)),
    };
};

const mapStateToProps = state => {
    return {
        paymentMethods: state.withdrawals.paymentMethods,
        amount: state.withdrawWizard.amount,
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(BankTransfer)));
