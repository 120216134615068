import React, { PureComponent, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Button from '../../../../UI/Button/Button';
import './DocumentCamera.scss';

class DocumentCamera extends PureComponent {
    
    state = {
        src: null,
    };
    
    onTakePhoto = (dataUri) => {
        document.getElementById('Video').remove();
        this.setState({ src: dataUri })
        document.getElementById('Camera').style.transform = 'scale(.9)';
    }
    
    onCameraError = (error) => {
        console.error('onCameraError', error);
    }

    async uploadImageHandler(src){
        let blob = await fetch(src).then(res => res.blob());
        let image = new File([blob], 'document.jpg', {type: "image/jpeg"});
        this.props.changePage('upload', this.props.documentType, this.props.documentID, image);
    }

    render() {
        const { src } = this.state;
        let cameraContent = '';
        if(this.props.selectedDocument){
            const reader = new FileReader();
            reader.addEventListener("load", () => this.setState({ src: reader.result }));
            reader.readAsDataURL(this.props.selectedDocument);
            setTimeout(() => {
                document.getElementById('Camera').style.transform = 'scale(.9)';
            }, 100);
        }else{
            cameraContent = (
                <div id="Video">
                    <Camera
                        onTakePhotoAnimationDone={(dataUri) => {this.onTakePhoto(dataUri)}}
                        onCameraError={(error) => {this.onCameraError(error)}}
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        imageType={IMAGE_TYPES.JPG}
                        imageCompression={1}
                        isImageMirror={false}
                        isSilentMode={true}
                        sizeFactor={1}
                    />
                </div>
            );
        }
        return(
            <div className={"DocumentCameraScreen"}>
                <div id="Camera">
                    {cameraContent}
                    {src && (
                    <Fragment>
                        <img alt="document" src={src} />
                        <Button 
                            className={'gradient'} 
                            full
                            value={this.props.t('Upload document')}
                            click={() => this.uploadImageHandler(src)}
                        />
                    </Fragment>
                )}
                </div>
            </div>
        );
    };
};


export default withTranslation()(DocumentCamera);