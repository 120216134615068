import React, { useEffect, useState, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import './WalletBonus.scss';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import Icons from '../../../assets/Icons/Icons';
import TitleBar from "../../UI/TitleBar/TitleBar";
import PageTitle from '../../UI/PageTitle/PageTitle';
import ProgressBar from '../../UI/ProgressBar/ProgressBar';
import TimeAgo from 'timeago-react';
import * as storeActions from "../../../store/actions";
import ConfirmRemove from './ConfirmRemove/ConfirmRemove';
import CircularProgress from '@material-ui/core/CircularProgress';
import Utils from '../../../utils/Utils';
import VoucherCode from "../VoucherCode/VoucherCode";
import { launchSlotGame } from "../../../lobby/utils/functions";
import { claimPrizeById, loadPrizes } from "../../../tournaments-missions/store/actions/history";
import Translate from "../../../utils/Translate";
import { DialogTemplate } from "../../UI/Dialog/DialogTemplate";

import CalendarTicketsStatus from "../../../promo-calendar/components/menu_tickets_status";
import { getMomentumBonuses } from "../../../momentum/store/actions/momentum";
import SimpleTimer from "./SimpleTimer";

const WalletBonus = props => {
    useEffect(() => {
        if (props.auth.auth_type !== "user") {
            props.history.push("/");

        } else {
            props.onRequestWallet();
            props.onRequestPendingWithdrawals();
            props.fetchFreeSpins();
            props.loadPrizes(fetchPrizeCallback);
            if (props.momentumBonus.value === null) {
                props.fetchMomentumBonuses();
            }

        }

        // eslint-disable-next-line
    }, []);

    const fetchPrizeCallback = (success) => {

    }
    const claimPrizeCallback = (success) => {

        if (!success) {
            setClaimError(true);
        } else {
            setClaimSuccess(true);
        }

    }

    const [popupOpen, setPopupOpen] = useState(false);
    const [removeBonusID, setRemoveBonusID] = useState('');
    const [isFreeBet, setIsFreeBet] = useState(false);
    const [isFreeSpin, setIsFreeSpin] = useState(false);
    const [isAfterWager, setIsAfterWager] = useState(false);
    const [claimError, setClaimError] = useState(false);
    const [claimSuccess, setClaimSuccess] = useState(false);

    const statusNameHandler = (status) => {
        switch (status) {
            case 'active': return 'active'; // Active
            case 'accepted': return 'active'; // Active
            case 1: return 'active'; // Active
            case 2: return 'pending'; // Inactive
            case 3: return 'expired'; // Expired
            case 4: return 'forfeited'; // Forfeited
            case 5: return 'wagered'; // Wagered
            default: return '';
        }
    }

    const handleOpenPopup = (bonusID, type) => {
        setRemoveBonusID(bonusID);
        setIsFreeBet(type === 'bet');
        setIsFreeSpin(type === 'spin');
        setIsAfterWager(type === 'bonus_after_wager');
        setPopupOpen(true);
    }

    const handleClosePopup = (bool) => {
        setPopupOpen(false);
        setRemoveBonusID('');
        setIsFreeBet(false);
        setIsFreeSpin(false);
        setIsAfterWager(false);
        if (bool) {
            props.onRequestWallet();
            props.onRequestPendingWithdrawals();
        }
    }

    const icons = {
        horseShoe: Icons.get('horseShoe', `iconSettings`),
        wallet: Icons.get('walletOutlined', `WalletIcon`),
        moneyPurse: Icons.get('moneyPurse', `MoneyPurseIcon`),
        diamond: Icons.get('diamond', `DiamondIcon`),
        deposit: Icons.get('depositCoin', `DepositIcon`),
        withdraw: Icons.get('withdrawCoin', `WithdrawIcon`),
        getMore: Icons.get('horseShoe', `GetMoreIcon`),
        exclamation: Icons.get('exclamationFilled', `ExclamationIcon`),
        checkmark: Icons.get('checkmarkFilled', `CheckmarkIcon`),
        operation: Icons.get('operation', `iconSettings`),
        timer: Icons.get('timer', `TimerIcon`),
        trashBin: Icons.get('trashBin', `TrashBinIcon`),
        approved: Icons.get('pwSuccess', `ApprovedIcon`),
        pending: Icons.get('wPending', `PendingIcon`),
        lockedMoney: Icons.get('lockedMoney', `LockedMoneyIcon`),
    };

    const calcPercentHandler = (valueA, valueB) => {
        return Math.round((valueA / valueB) * 100);
    }

    const getTimeRemainingHandler = (timestamp) => {
        return <SimpleTimer timestamp={timestamp} />;
    }

    const goToDeposit = () => {
        props.isDeposit();
        props.history.push('/deposit');
    };

    const goToWithdraw = () => {
        props.history.push('/withdraw');
    };

    const goToPendingWithdrawals = () => {
        props.history.push('/profile/pending-withdrawals');
    };

    const goToPromotions = () => {
        props.history.push('/cazino/promotii');
    };
    const goToTermsConditions = (bonus) => {

        console.log(bonus);

        let cms_data = bonus.cms_data && bonus.cms_data.length ? bonus.cms_data[0] : false;

        let url = cms_data && cms_data.terms_url ? cms_data.terms_url : "https://" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';

        if (url.indexOf("pdf") > -1) {
            if (window.config.cordova) {
                window.cordova.InAppBrowser.open(url, '_system');
                return;
            }
        }

        let win = window.open(url, '_blank');
        if (win !== null) {
            win.focus();
        }
    };

    const handleGameLaunch = (gameId) => (e) => {
        e.stopPropagation();
        const gameData = {};
        const gameInfo = props.lobbyGames.find(el => gameId && el.game_id.toString() === gameId.toString());
        if (gameInfo) {
            gameData.id = gameInfo.id;
            gameData.name = gameInfo.name;
            gameData.gameId = gameInfo.game_id;
            gameData.providerId = gameInfo.provider;
        } else {
            gameData.id = gameId;
            gameData.gameId = gameId;
        }
        props.launchSlot(gameData);
    };

    let bonuses = (
        <h5 className={"NoBonus"}>
            {props.t("There is no bonus active yet")}.
            <br />
            {props.t("Visit ")}<span onClick={goToPromotions}> {props.t('Promotions')}</span>{props.t(" to get one.")}
        </h5>
    );

    let hasBonuses = false;

    if (props.wallet.bonuses.length > 0) {
        bonuses = props.wallet.bonuses.map(bonus => {
            hasBonuses = true;

            let timeLeft = '';
            if (bonus.status === 1) {
                let time = getTimeRemainingHandler(bonus.endDate);
                timeLeft = (
                    <div className={"TimeLeft"}>
                        {icons.timer}
                        <span>{time}</span>
                    </div>
                );
            }

            let currentPrize = '';
            if ((bonus.status === 1 || bonus.status === 2) && bonus.amountGranted) {
                let ringFenceAmount = 0;
                if (bonus.ringFenceAmount) { ringFenceAmount = bonus.ringFenceAmount }
                currentPrize = (
                    <div className={"CurrentPrize"}>
                        <h3>{props.t(bonus.name)}</h3>
                        <div className="BonusValues">
                            <p>
                                {icons.diamond}
                                {Utils.formatSmallDecimals(bonus.amountGranted, null)}
                                <span className="Currency">{Utils.formatCurrency(props.wallet.currency)}</span>
                            </p>
                            <p>
                                {icons.lockedMoney}
                                {Utils.formatSmallDecimals(ringFenceAmount, null)}
                                <span className="Currency">{Utils.formatCurrency(props.wallet.currency)}</span>
                            </p>
                        </div>
                    </div>
                );
            }

            let contentType = '';
            if (bonus.status === 1 || bonus.status === 2) {
                contentType = (
                    <div className={"ContentBox"}>
                        <div className="BonusType">
                            <p>{props.t('Wager requirements')}</p>
                            <div className="WagerData">
                                <div className="WagerAmount">
                                    <div className="BonusWager">
                                        {Utils.formatSmallDecimals(bonus.wager, null)}
                                        <span className="Currency">{Utils.formatCurrency(props.wallet.currency)}</span>
                                    </div>
                                    <span>&nbsp;/&nbsp;</span>
                                    <div className="BonusWagerTarget">
                                        {Utils.formatSmallDecimals(bonus.wagerTarget, null)}
                                        <span className="Currency">{Utils.formatCurrency(props.wallet.currency)}</span>
                                    </div>
                                </div>
                                {timeLeft}
                            </div>
                        </div>
                        <div className="ProgressState">
                            <ProgressBar value={calcPercentHandler(bonus.wager, bonus.wagerTarget)} />
                            <h5>{calcPercentHandler(bonus.wager, bonus.wagerTarget)}%</h5>
                        </div>
                        <div className="BoxActions">
                            <span onClick={() => goToTermsConditions(bonus)}>{props.t('Terms and Conditions')}</span>
                            <span onClick={() => handleOpenPopup(bonus.id)}>{icons.trashBin}</span>
                        </div>
                    </div>
                );
            }

            return (
                <div className={"BonusContent"} key={bonus.id}>
                    <span className={"BonusState " + statusNameHandler(bonus.status)}>{props.t(statusNameHandler(bonus.status))}</span>

                    <div className={"GrayBox"}>
                        <div className={"BonusTop"}>
                            {
                                (bonus.status === 1 || bonus.status === 2) ?
                                    currentPrize
                                    :
                                    <div className={"BonusDetails"}>
                                        <div>
                                            <p>{props.t(bonus.name)}</p>
                                            {
                                                (bonus.status === 5) ?
                                                    <span>&nbsp;|&nbsp;{Utils.formatSmallDecimals(bonus.wagerTarget, props.wallet.currency)}</span> : null
                                            }
                                            <span>&nbsp;|&nbsp;{Utils.formatSmallDecimals(bonus.wager, props.wallet.currency)}</span>
                                        </div>
                                        {
                                            (bonus.status === 3 || bonus.status === 4) ?
                                                <div className={"BonusExpired"}>
                                                    {icons.exclamation}
                                                    <h5><TimeAgo datetime={new Date().getTime() - bonus.endDate} />{' (' + props.t('Expired') + ')'}</h5>

                                                </div>
                                                :
                                                <div className={"BonusWagered"}>
                                                    {icons.checkmark}
                                                    <h5><TimeAgo datetime={new Date().getTime() - bonus.endDate} /></h5>
                                                </div>
                                        }
                                    </div>
                            }
                            {
                                (bonus.status === 1 || bonus.status === 2) && (
                                    <div className={"BonusDescription"}>
                                        <h3 className={"BonusAmount"}>+ {Utils.formatSmallDecimals(bonus.amount, props.wallet.currency)}</h3>
                                        <span className={"Description"}>{props.t('Current Prize')}</span>
                                    </div>
                                )
                            }
                        </div>
                        {contentType}
                    </div>
                </div>
            );
        });
    }

    let freeBets = null;

    if (props.freeBets.length > 0) {
        if (!hasBonuses) {
            bonuses = null; // remove the "no bonus bit" from above because we have free bets 
        }
        freeBets = props.freeBets.map((bonus, i) => {
            let timeLeft = '';
            let style = { "marginTop": "24px" };
            if (bonus.status === 1) {
                let time = getTimeRemainingHandler(bonus.expires);
                timeLeft = (
                    <div className={"TimeLeft"} style={style}>
                        {icons.timer}
                        <span>{time}</span>
                    </div>
                );
            }

            let name = bonus.name;

            return (
                <div className={"BonusContent"} key={bonus.code}>
                    <span className={"BonusState " + statusNameHandler(bonus.status)}>{props.t(statusNameHandler(bonus.status))}</span>

                    <div className={"GrayBox"}>
                        <div className={"BonusTop"}>
                            <div className={"CurrentPrize"}>
                                <h3>{props.t(name)}</h3>
                            </div>
                            {
                                <div className={"BonusDescription"}>
                                    <h3 className={"BonusAmount"}>{bonus.count} x {Utils.formatSmallDecimals(bonus.amount_small === 1 ? bonus.amount / 100 : bonus.amount, props.wallet.currency)}</h3>
                                    <span className={"Description"}>{props.t('Current Prize')}</span>
                                </div>
                            }
                        </div>
                        <div className={"ContentBox"}>
                            <div className="BonusType">
                                <div className="WagerData">
                                    <div className="WagerAmount">

                                    </div>
                                    {timeLeft}
                                </div>
                            </div>
                            <div className="BoxActions">
                                <span onClick={() => goToTermsConditions(bonus)}>{props.t('Terms and Conditions')}</span>
                                <span onClick={() => handleOpenPopup(bonus.code, 'bet')}>{icons.trashBin}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });


    }

    let freeSpins = null;

    if (props.freeSpins.length > 0) {
        if (!hasBonuses) {
            bonuses = null; // remove the "no bonus bit" from above because we have free bets
        }
        freeSpins = props.freeSpins.map((bonus, i) => {
            let timeLeft = '';
            let style = { "marginTop": "24px" };
            if (bonus.bonus_status === 1) {
                let time = getTimeRemainingHandler(bonus.end_date);
                timeLeft = (
                    <div className={"TimeLeft"} style={style}>
                        {icons.timer}
                        <span>{time}</span>
                    </div>
                );
            }

            let name = bonus.name;
            return (
                <div className={"BonusContent"} key={bonus.code + i}>
                    <span className={"BonusState " + statusNameHandler(bonus.bonus_status)}>{props.t(statusNameHandler(bonus.bonus_status))}</span>

                    <div className={"GrayBox"}>
                        <div className={"BonusTop"}>
                            <div className={"CurrentPrize"}>
                                <h3>{props.t(name)}</h3>
                            </div>
                            {
                                <div className={"BonusDescription"}>
                                    <h3 className={"BonusAmount"}>{bonus.remaining_free_spins}<small>/{bonus.total_free_spins}</small> {props.t("Spins")}</h3>
                                </div>
                            }
                        </div>
                        <div className={"ContentBox"}>
                            <div className="BonusType">
                                <div className="WagerData">
                                    <div className="WagerAmount">

                                    </div>
                                    {timeLeft}
                                </div>
                            </div>
                            <div className="BoxActions">
                                <span onClick={() => goToTermsConditions(bonus)}>{props.t('Terms and Conditions')}</span>
                                <span onClick={handleGameLaunch(bonus.games[0])} className={'play-now'}>{props.t("PLAY NOW")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });


    }

    let prizes = null;

    if (props.prizes.length > 0) {
        if (!hasBonuses) {
            bonuses = null; // remove the "no bonus bit" from above because we have free bets
        }
        prizes = props.prizes.map((bonus, i) => {
            let timeLeft = '';
            let style = { "marginTop": "24px" };
            // if (bonus.bonus_status === 1) {
            let time = getTimeRemainingHandler(bonus.claim_end_time);
            if (time) {
                timeLeft = (
                    <div className={"TimeLeft"} style={style}>
                        {icons.timer}
                        <span>{time}</span>
                    </div>
                );
            }
            // }
            let name = '';
            let currency = '';
            let value = bonus.value;
            switch (bonus.class_type) {
                case 1:
                    name += 'Bonus';
                    currency = 'Lei';

                    // eslint-disable-next-line default-case
                    switch (bonus.bonus_type) {
                        case 1:
                            name += " Funds";
                            value /= 100;
                            break;
                        case 2:
                            name += " SPORT Free Bet";
                            currency = 'FB Sport';
                            break;
                        case 3:
                            name += " LOTTO Free Bet";
                            currency = 'FB LOTTO';

                            break;
                        case 4:
                            name += " Free Spin";
                            currency = 'FS';

                            break;
                        case 5:
                            name += " Golden Chip";
                            currency = 'GC';
                            break;
                        default:
                            value /= 100;
                            break;
                    }
                    break;
                case 2:
                    name += 'Currency';
                    break;
                case 3:
                    name += 'Token';
                    break;
                default:
                    return null;
            }
            return (
                <div className={"BonusContent tournament"} key={bonus.code + i}>

                    <div className={"GrayBox"}>
                        <div className={"BonusTop"}>
                            <div className={"CurrentPrize"}>
                                <h3>{props.t(name)}</h3>
                            </div>
                            <div className="BonusType">
                                <div className="WagerData">
                                    <div className="WagerAmount">

                                    </div>
                                    {timeLeft}
                                </div>
                            </div>
                        </div>

                        <div className={"ContentBox"}>
                            <div className="BonusType">
                                <div className={"BonusDescription"}>
                                    {typeof bonus.meta !== "undefined" && typeof bonus.meta.mission_name !== "undefined" && <div className="SourceName">{bonus.meta.mission_name}</div>}
                                    <h3 className={"BonusAmount"}>{value} {currency}</h3>
                                </div>
                            </div>
                            <div className="BoxActions">
                                <span onClick={() => { props.claimPrize(bonus.id, claimPrizeCallback) }} className={'play-now'}>{props.t("Claim")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });


    }

    let momentumBonuses = null;
    let is_expired = false;
    if (props.momentumBonus.value !== null && props.momentumBonus.value.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(props.momentumBonus.value.bonus_expires_at).valueOf();
        is_expired = now - prizeExpiresAt >= 0;
    }

    if (props.momentumBonus.value !== null && props.momentumBonus.value?.active && !is_expired) {
        const bonus = props.momentumBonus.value;
        if (bonus.bonus_type === 'bonus_after_wager') {
            if (!hasBonuses) {
                bonuses = null; // remove the "no bonus bit" from above because we have momentum bonuses
            }
            let timeLeft = '';
            let style = { "marginTop": "24px" };
            if (bonus.active === true) {
                let time = getTimeRemainingHandler(new Date(bonus.bonus_expires_at).getTime() / 1000);
                timeLeft = (
                    <div className={"TimeLeft"} style={style}>
                        {icons.timer}
                        <span>{time}</span>
                    </div>
                );
            }

            let name = bonus.display_name;
            momentumBonuses = (
                <div className={"BonusContent"} key={bonus.uuid}>
                    <span className={"BonusState " + statusNameHandler(bonus.status)}>{props.t(statusNameHandler(bonus.status))}</span>

                    <div className={"GrayBox"}>
                        <div className={"BonusTop"}>
                            <div className={"CurrentPrize"}>
                                <h3>{props.t(name)}</h3>
                            </div>
                            {/*{*/}
                            {/*    <div className={"BonusDescription"}>*/}
                            {/*        <h3 className={"BonusAmount"}>{bonus.remaining_free_spins}<small>/{bonus.total_free_spins}</small> {props.t("Spins")}</h3>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                        <div className={"ContentBox"}>
                            <div className="BonusType">
                                <div className="WagerData">
                                    <div className="WagerAmount">
                                        <div className="BonusWager">

                                            {Utils.formatSmallDecimals((bonus.wager_progress / 100) * (bonus.wager_target / 100), null)}
                                            <span
                                                className="Currency">{Utils.formatCurrency(bonus.wager_currency)}</span>
                                        </div>
                                        <span>&nbsp;/&nbsp;</span>
                                        <div className="BonusWagerTarget">
                                            {Utils.formatSmallDecimals(bonus.wager_target / 100, null)}
                                            <span
                                                className="Currency">{Utils.formatCurrency(bonus.wager_currency)}</span>
                                        </div>
                                    </div>
                                    {timeLeft}
                                </div>
                            </div>
                            <div className="ProgressState">
                                <ProgressBar value={bonus.wager_progress} />
                                <h5>{Math.round((bonus.wager_progress + Number.EPSILON) * 100) / 100}%</h5>
                            </div>
                            <div className="BoxActions">
                                <span
                                    onClick={() => goToTermsConditions(bonus)}>{props.t('Terms and Conditions')}</span>
                                <span onClick={() => handleOpenPopup(bonus.uuid, bonus.bonus_type)}>{icons.trashBin}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    let pageContent = <CircularProgress className={'Loading'} />;

    let pendingContent = '';
    let approved = 0;
    let pending = 0;

    if (!props.wallet.loadingWallet && !props.withdrawals.loading) {
        if (props.withdrawals.approved.length > 0 || props.withdrawals.pending.length > 0) {
            if (props.withdrawals.approved.length > 0) {
                props.withdrawals.approved.map(valueApproved => approved += valueApproved.amount);
            }

            if (props.withdrawals.pending.length > 0) {
                props.withdrawals.pending.map(valuePending => pending += valuePending.amount);
            }

        }
        if (approved > 0 || pending > 0) {
            pendingContent = (
                <Fragment>
                    <div className="ProductItem">
                        <p>+{Utils.formatSmallDecimals(approved + pending, props.wallet.currency)}</p>
                    </div>
                    <div className="ProductItem Icons">
                        {approved > 0 && icons.approved}
                        {pending > 0 && icons.pending}
                    </div>
                    <div className="ProductItem">
                        <span className="LinkStyle" onClick={goToPendingWithdrawals}>{props.t('Pending withdrawals')}</span>
                    </div>
                </Fragment>
            );
        } else {
            pendingContent = (
                <div className="ProductItem">
                    <span className="LinkStyle" onClick={goToPendingWithdrawals}>{props.t('There are no pending witdrawals')}</span>
                </div>
            );
        }

        pageContent = (
            <div className={"WalletBonusContent"}>

                <PageTitle icon={icons.horseShoe} title={props.t('Sold & Bonuses')} subtitle={props.t('Account Balance')} />

                <div className={"TotalAmount"}>
                    <i>{icons.wallet}</i>
                    <div>
                        <h1>{Utils.formatSmallDecimals(props.wallet.total, props.wallet.currency)}</h1>
                    </div>
                    <h5>{props.t('Total balance available for play')}</h5>
                </div>
                <div className={"SpecificAmount"}>
                    <div className={"MoneyBox RealMoney"}>
                        <i>
                            {icons.moneyPurse}
                            {/* <span>{icons.exclamation}</span> */}
                        </i>
                        <h5>{props.t('Cash')}</h5>
                        <div className={"Amount"}>
                            <h3>{Utils.formatSmallDecimals(props.wallet.main + (props.wallet.ringFence ? props.wallet.ringFence : 0), props.wallet.currency)}</h3>
                        </div>
                        <div className="PendingContent">
                            <div className="PendingProducts">
                                {pendingContent}
                            </div>
                        </div>
                    </div>
                    <div className={"MoneyBox BonusMoney"}>
                        <i>{icons.diamond}</i>
                        <h5>{props.t('Bonus')}</h5>
                        <div className={"Amount"}>
                            <h3>{Utils.formatSmallDecimals(props.wallet.bonus, props.wallet.currency)}</h3>
                        </div>
                        <div className="BonusContent">
                            <div className="BonusProducts">
                                <div className="ProductItem">
                                    <p>{props.t('Casino')}</p>
                                    <p>{Utils.formatSmallDecimals(props.wallet.walletManagement[1] && props.wallet.walletManagement[1].bonusAmount ? props.wallet.walletManagement[1].bonusAmount : 0, props.wallet.currency)}</p>
                                </div>
                                <div className="ProductItem">
                                    <p>{props.t('Sportsbook')}</p>
                                    <p>{Utils.formatSmallDecimals(props.wallet.walletManagement[2] && props.wallet.walletManagement[2].bonusAmount ? props.wallet.walletManagement[2].bonusAmount : 0, props.wallet.currency)}</p>
                                </div>
                                {/*<div className="ProductItem">
                                    <p>{props.t('LIVE')}</p>
                                    <p>{Utils.formatSmallDecimals(0, props.wallet.currency)}</p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <CalendarTicketsStatus />
                <div className={"ActionsButtons"}>
                    <button className={"WithdrawButton"} onClick={goToWithdraw}>
                        {icons.withdraw}
                        <span>{props.t('Withdraw')}</span>
                    </button>
                    <button className={"DepositButton"} onClick={goToDeposit}>
                        {icons.deposit}
                        <span>{props.t('Deposit')}</span>
                    </button>
                </div>
                <VoucherCode />
                <div className={"UserBonuses"}>
                    <PageTitle icon={icons.operation} title={props.t('Your Bonuses')} subtitle={props.t('Current Status')} />
                    {bonuses}
                    {prizes}
                    {freeSpins}
                    {freeBets}
                    {momentumBonuses}
                    {popupOpen && <ConfirmRemove open={popupOpen} bonusID={removeBonusID} isFreeBet={isFreeBet} isAfterWager={isAfterWager} close={(bool) => handleClosePopup(bool)} />}
                </div>

            </div>
        );
    }

    return (
        <div className={"WalletBonusScreen"}>
            <TitleBar pageTitle={props.t('Wallet & Bonuses')} />
            {pageContent}

            <DialogTemplate
                open={claimError}
                className={`claim-prize-modal error`}
                title={props.t('Error')}
                icon={Icons.get('exWarning')}
                actions={[
                    {
                        text: props.t('OK'),
                        className: "error",
                        onClick: () => props.history.push('/')
                    },

                ]}
            >
                <div className="claim-prize-modal-message">
                    <p>{props.t("We apologize for this inconvenience. We have run into a problem and the bonus has not been added to your account. Please try again later. If the issue continues, please contact the Customer Support.")}</p>
                </div>
            </DialogTemplate>
            <DialogTemplate
                open={claimSuccess}
                className={`claim-prize-modal success`}
                title={props.t('Success')}
                icon={Icons.get('pwSuccess')}
                actions={[
                    {
                        text: props.t("LET'S PLAY"),
                        className: "success full-width",
                        onClick: () => props.history.push('/')
                    },
                ]}
            >
                <div className="claim-prize-modal-message">

                    <p>
                        <Translate text={"${bonusName} has been successfully been awarded to your account! GOOD LUCK!"}
                            placeholder={{
                                bonusName: props.t("The prize")
                            }}
                        />
                    </p>
                </div>
            </DialogTemplate>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        wallet: state.wallet,
        freeBets: state.freeBets && state.freeBets.freeBets ? state.freeBets.freeBets : [],
        freeSpins: state.freeSpins && state.freeSpins.freeSpins ? state.freeSpins.freeSpins : [],
        withdrawals: state.withdrawals,
        auth: state.authentication,
        totalBonus: state.wallet.totalBonusPerProducts,
        lobbyGames: state.lobby ? state.lobby.games.items : [],
        prizes: state.tournamentsMissions && state.tournamentsMissions.history.prizes ? state.tournamentsMissions.history.prizes : [],
        momentumBonus: state.momentum.prize,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        isDeposit: () => dispatch(storeActions.isDeposit(true)),
        onRequestWallet: () => dispatch(storeActions.requestWallet()),
        onRequestPendingWithdrawals: () => dispatch(storeActions.requestPendingWithdrawals()),
        fetchFreeSpins: () => dispatch(storeActions.freeSpinsFetch()),
        fetchMomentumBonuses: () => dispatch(getMomentumBonuses()),
        launchSlot: (game) => launchSlotGame(game, { clientX: 0, clientY: 0 }, storeActions),
        loadPrizes: (cb) => dispatch(loadPrizes(cb)),
        claimPrize: (id, cb) => {
            dispatch(claimPrizeById({ id: id, cb: cb }))
        }
    }
}
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(WalletBonus)));
