import * as actionTypes from './../actions/actionTypes';
import * as LoginMethods from './../../utils/LoginMethods';

const initialState = {
    username: null,
    password: null,
    method: LoginMethods.IS_UNDEFINED,
    steps: ['username', 'password', 'result'],
    step: 0,
    errorUsername: false,
    errorPassword: false,
    allowUsernameButton: false,
    allowLoginButton: false,
    inRequest: false,
    allowSMS: true,
    etaSmsRequest: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.login.SET_USERNAME:
            return {
                ...state,
                username: action.username,
                errorUsername: action.error,
                method: action.method,
                allowUsernameButton: action.allowUsernameButton,
            };
        case actionTypes.login.ERROR_USERNAME:
            return {
                ...state,
                errorUsername: action.error,
                allowUsernameButton: true,
            };
        case actionTypes.login.ERROR_PASSWORD:
            return {
                ...state,
                errorPassword: action.error,
            };
        case actionTypes.login.STEP:
            return {
                ...state,
                step: action.step,
            };
        case actionTypes.login.SET_PASSWORD:
            return {
                ...state,
                password: action.password,
                errorPassword: action.errorPassword,
                allowLoginButton: action.allowLoginButton,
            };
        case actionTypes.login.IN_REQUEST:
            let allowButton = false;
            if (!action.value) {
                allowButton = true;
            }
            return {
                ...state,
                inRequest: action.value,
                allowLoginButton: allowButton,
            };
        case actionTypes.login.ALLOW_SMS_REQUEST:
            return {
                ...state,
                allowSMS: action.value,
            };
        case actionTypes.login.SMS_ETA:
            return {
                ...state,
                etaSmsRequest: action.eta,
            };
        default:
            return state;
    }
};

export default reducer;
