import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import connect from "react-redux/es/connect/connect";
import { animated, Spring } from 'react-spring/renderprops';
import * as easings from "d3-ease";
import './Loading.scss';

const styles = theme => ({
    progressColorPrimary: {
        color: '#ffb400',
    }
});

const Loading = (props) => {
    const classes = props.classes;

    return (
        <Spring config={{ easing: easings.easeCubicOut, duration: 600 }}
            from={{ top: (props.games.showLoading) ? -72 : 40 }}
            to={{ top: (props.games.showLoading) ? 40 : -72 }}
            delay={500}>
            {springProps => (
                <animated.div className={"gamesLoadingProgress"} style={springProps}>
                    <div className={"loadingBox"}>
                        <CircularProgress className={classes.progressColorPrimary} color="secondary" />
                    </div>
                </animated.div>
            )}
        </Spring>
    );
};

const mapStateToProps = state => {
    return {
        games: state.games
    }
};

export default connect(mapStateToProps)(withStyles(styles)(Loading));