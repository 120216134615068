import { all, call, takeEvery, takeLatest } from "redux-saga/effects";
// import { takeEveryWithCooldown } from "./effects";
import { requestMenuSaga, receivedMenuSaga } from "./menu";
import { authenticateAppSaga, checkAuthTimeoutSaga, refreshAuthTokenSaga } from "./authentication";
import {
    routeListenerSaga,
    changeLanguageSaga,
    getPromotionsSaga,
    getEligibleBonusesSaga,
    getPreEuroSaga,
    getBonusAvailableSaga, claimBonusSaga, getBonusesSaga,
    getBonusCalculatorSaga,
} from "./application";
import {
    loginUsernameSaga,
    loginPasswordSaga,
    requestUserAuthentication,
    logoutSaga,
    requestSmsSaga,
    updateSmsEta,
    stepChangeSmsRequest
} from "./login";
import { requestWalletSaga, authRequestWalletSaga, requestRemoveBonusSaga } from "./wallet";
import * as actionTypes from "./../actions/actionTypes";
import {
    validateFieldSaga,
    sendSMSSaga,
    signUpSaga,
    validateEmailSaga,
    getBonusCoinsSaga,
    setPartialAccountSaga, getPartialAccountSaga, validatePhoneSaga, saveRegisterFieldsSaga
} from "./register";
import { resetPasswordSendEmailSaga, resetPasswordSaga } from "./resetPassword";
import {
    authRequestAccountSaga,
    requestAccountSaga,
    requestLimitsSaga,
    setNewLimitSaga,
    setSelfexclusionSaga,
    checkPasswordSaga,
    changePasswordSaga,
    checkPhoneSaga,
    checkPhoneCodeSaga,
    getChatInfoSaga,
    resendVerificationEmailSaga, sendSelfExclusionCodesSaga, selfExcludeSaga
} from "./profile";
import { requestTransactionsHistorySaga } from "./transactions";

import {
    requestPaymentLinkSaga,
    requestVivaTokenSaga,
    sendVivaTokenSaga,
    requestVivaCardTokenSaga,
    requestVivaChargeTokenSaga,
    requestVivaChargeTokenWithCardSaga,
    requestVivaWithdrawSaga, requestOktoCreatePaymentCodeSaga, oktoGetCustomerSaga, aircashGetPaymentLinkSaga
} from "./paymentCheckout";

import { requestWithdrawalLinkSaga } from "./withdrawalCheckout";
import {
    requestPendingWithdrawalsSaga,
    requestLocationsSaga,
    requestTaxServiceSaga,
    requestCancelWithdrawalSaga,
    requestVerificationCodeSaga,
    downloadCSVSaga, getPaymentMethodsSaga, linkIBANSaga, withdrawSaga, requestWithdrawHistorySaga
} from "./withdrawals";
import {
    requestDocumentsSaga,
    requestDocumentsUploadSaga,
    requestDocumentsMaxFileSizeSaga,
    authRequestDocumentsSaga
} from "./documents";
import { requestGamesSaga, openGameLauncherSaga, requestGameUrlSaga, closeGameLauncherSaga, closeRedirectGameLauncherSaga, requestGameCollectionsSaga } from "./games";
import { getLocationsSaga, getTaxSaga, sendWithdraw } from "./withdraw_wizard";
import { sendComplaintFormSaga, requestGameRulesSaga } from "./customer_support";
import { cycleRequestJackpotSaga } from "./jackpot";
import { websocketPlayerLoginSaga, websocketPlayerLogoutSaga } from "./ws";
import watchHappyHourSaga from "./happy_hour";
import watchConfigSaga from "./config";

import { betsSaga } from "../../bets/store/sagas";
import { virtualsSaga } from "../../virtuals/store/sagas";
import { lottoSaga } from "../../lotto/store/sagas";

import { winnerFunSaga } from "../../winner-fun/store/sagas";

import { liveCasinoSaga } from "../../live-casino/store/sagas";
import freeBetsSaga from "./free_bets";
import freeSpinsSaga from "./free_spins";
import druid from "./druidSaga";
import alertsSaga from './alerts';

import { maintenanceSaga } from "../../maintenance/store/sagas";
import watchVoucherSaga from "./voucherCode";

import { wheelSaga } from "../../wheel/store/sagas";
import { promoSaga } from "../../promo/store/sagas";
import { promoCalendarSaga } from "../../promo-calendar/store/sagas";

import { tournamentsMissionsSaga } from "../../tournaments-missions/store/sagas";

import { lobbySaga } from "../../lobby/store/sagas";

import { takeEveryWithCooldown } from "./effects";
import { getMarketingOfferSaga } from "./marketing_offer";

import { depositTicketSaga } from "../../deposit-ticket/store/sagas";
import { momentumSaga } from "../../momentum/store/sagas";

export function* watchAuth() {
    yield takeEvery(actionTypes.authentication.CHECK_TOKEN_EXPIRATION, checkAuthTimeoutSaga);
    yield takeEvery(actionTypes.authentication.REFRESH_AUTH_TOKEN, refreshAuthTokenSaga);
    yield takeEvery(actionTypes.authentication.START_APP_AUTH, authenticateAppSaga);
}

export function* watchMenu() {
    // yield takeEvery(actionTypes.menu.REQUEST_MENU, requestMenuSaga);
    yield takeEvery(actionTypes.menu.RECEIVED_MENU, receivedMenuSaga);
    yield call(requestMenuSaga);
}

export function* watchApplication() {
    yield takeEvery(actionTypes.application.ROUTE_CHANGED, routeListenerSaga);
    yield takeEvery(actionTypes.application.CHANGE_ACCOUNT_LANGUAGE, changeLanguageSaga);
    yield takeEvery(actionTypes.application.GET_PROMOTIONS, getPromotionsSaga);
    yield takeEvery(actionTypes.application.GET_ELIGIBLE_BONUSES, getEligibleBonusesSaga);
    yield takeEvery(actionTypes.application.GET_BONUS_AVAILABLE, getBonusAvailableSaga);
    yield takeEvery(actionTypes.bonuses.GET, getBonusesSaga);
    yield takeEvery(actionTypes.bonuses.GET_BONUS_CALCULATOR, getBonusCalculatorSaga);
    yield takeEvery(actionTypes.application.CLAIM_BONUS, claimBonusSaga);
    yield takeEvery(actionTypes.application.GET_PREEURO, getPreEuroSaga);
}

export function* watchLogin() {
    yield takeEvery(actionTypes.login.USERNAME, loginUsernameSaga);
    yield takeEvery(actionTypes.login.PASSWORD, loginPasswordSaga);
    yield takeEvery(actionTypes.login.REQUEST_AUTHENTICATION, requestUserAuthentication);
    yield takeEvery(actionTypes.login.LOGOUT_USER, logoutSaga);
    yield takeEvery(actionTypes.login.STEP, stepChangeSmsRequest);
    yield takeEvery(actionTypes.login.START_SMS_COUNTDOWN, updateSmsEta);
    yield takeEvery(actionTypes.login.REQUEST_SMS_CODE, requestSmsSaga);
}

export function* watchWallet() {
    yield takeEvery(actionTypes.wallet.REQUEST_WALLET, requestWalletSaga);
    yield takeEvery(actionTypes.authentication.AUTHENTICATE, authRequestWalletSaga);
    yield takeEvery(actionTypes.wallet.REQUEST_REMOVE_BONUS, requestRemoveBonusSaga);
}

export function* watchRegister() {
    yield takeEvery(actionTypes.register.VALIDATE_FIELD, validateFieldSaga);
    yield takeEvery(actionTypes.register.SEND_SMS, sendSMSSaga);
    yield takeEvery(actionTypes.register.SIGN_UP, signUpSaga);
    yield takeEvery(actionTypes.register.VALIDATE_EMAIL, validateEmailSaga);
    yield takeEvery(actionTypes.register.VALIDATE_PHONE, validatePhoneSaga);
    yield takeLatest(actionTypes.register.SAVE_REGISTER_FIELDS, saveRegisterFieldsSaga);
    yield takeEvery(actionTypes.register.GET_BONUS_COINS, getBonusCoinsSaga);
    yield takeLatest(actionTypes.register.SET_PARTIAL_ACCOUNT, setPartialAccountSaga);
    yield takeLatest(actionTypes.register.GET_PARTIAL_ACCOUNT, getPartialAccountSaga);
}

export function* watchResetPassword() {
    yield takeEvery(actionTypes.resetPassword.SEND_EMAIL, resetPasswordSendEmailSaga);
    yield takeEvery(actionTypes.resetPassword.SEND_NEW_PASSWORD, resetPasswordSaga);
}

export function* watchProfile() {
    yield takeEvery(actionTypes.authentication.AUTHENTICATE, authRequestAccountSaga);
    yield takeEvery(actionTypes.profile.REQUEST_ACCOUNT, requestAccountSaga);
    yield takeEvery(actionTypes.profile.REQUEST_LIMITS, requestLimitsSaga);
    yield takeEvery(actionTypes.profile.SET_NEW_LIMIT, setNewLimitSaga);
    yield takeEvery(actionTypes.profile.SET_SELFEXCLUSION, setSelfexclusionSaga);
    yield takeEvery(actionTypes.profile.CHECK_PASSWORD, checkPasswordSaga);
    yield takeEvery(actionTypes.profile.CHANGE_PASSWORD, changePasswordSaga);
    yield takeEvery(actionTypes.profile.CHECK_PHONE, checkPhoneSaga);
    yield takeEvery(actionTypes.profile.CHECK_PHONE_CODE, checkPhoneCodeSaga);
    yield takeEvery(actionTypes.profile.GET_CHAT_INFO, getChatInfoSaga);
    yield takeEvery(actionTypes.profile.RESEND_VERIFICATION_EMAIL, resendVerificationEmailSaga);
    yield takeEvery(actionTypes.profile.SEND_SELF_EXCLUSION_CODES, sendSelfExclusionCodesSaga);
    yield takeEvery(actionTypes.profile.SELF_EXCLUDE, selfExcludeSaga);
}

export function* watchTransactions() {
    yield takeEvery(
        actionTypes.transactions.REQUEST_TRANSACTIONS_HISTORY,
        requestTransactionsHistorySaga
    );
}

export function* watchPaymentCheckout() {
    yield takeEvery(actionTypes.paymentCheckout.REQUEST_PAYMENT_LINK, requestPaymentLinkSaga);
    yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_TOKEN, requestVivaTokenSaga);
    yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_CARD_TOKEN, requestVivaCardTokenSaga);
    yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_WITHDRAW, requestVivaWithdrawSaga);
    yield takeEvery(actionTypes.paymentCheckout.SEND_VIVA_CHARGE_TOKEN, sendVivaTokenSaga);
    yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN, requestVivaChargeTokenSaga);
    yield takeEvery(actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD, requestVivaChargeTokenWithCardSaga);
    yield takeEvery(actionTypes.paymentCheckout.OKTO_CREATE_PAYMENT_CODE, requestOktoCreatePaymentCodeSaga);
    yield takeEvery(actionTypes.paymentCheckout.OKTO_GET_CUSTOMER, oktoGetCustomerSaga);
    yield takeEvery(actionTypes.paymentCheckout.AIRCASH_REQUEST_PAYMENT_LINK, aircashGetPaymentLinkSaga);
}

export function* watchWithdrawalCheckout() {
    yield takeEvery(
        actionTypes.withdrawalCheckout.REQUEST_WITHDRAWAL_LINK,
        requestWithdrawalLinkSaga
    );
}

export function* watchWithdrawals() {
    yield takeEveryWithCooldown(
        actionTypes.withdrawals.REQUEST_PENDING_WITHDRAWALS,
        requestPendingWithdrawalsSaga
    );
    yield takeEvery(actionTypes.withdrawals.REQUEST_PENDING_VIVA_WITHDRAWALS, requestPendingWithdrawalsSaga)
    yield takeEvery(actionTypes.withdrawals.REQUEST_LOCATIONS, requestLocationsSaga);
    yield takeEvery(actionTypes.withdrawals.REQUEST_TAX_SERVICE, requestTaxServiceSaga);
    yield takeEvery(actionTypes.withdrawals.REQUEST_CANCEL_WITHDRAWAL, requestCancelWithdrawalSaga);
    yield takeEvery(actionTypes.withdrawals.REQUEST_CANCEL_VIVA_WITHDRAWAL, requestCancelWithdrawalSaga);
    yield takeEvery(actionTypes.withdrawals.REQUEST_VERIFICATION_CODE, requestVerificationCodeSaga);
    yield takeEvery(actionTypes.withdrawals.DOWNLOAD_CSV, downloadCSVSaga);
    yield takeEvery(actionTypes.withdrawals.GET_PAYMENT_METHODS, getPaymentMethodsSaga);
    yield takeEvery(actionTypes.withdrawals.LINK_IBAN, linkIBANSaga);
    yield takeEvery(actionTypes.withdrawals.WITHDRAW, withdrawSaga);
    yield takeEvery(actionTypes.withdrawals.REQUEST_WITHDRAW_HISTORY, requestWithdrawHistorySaga);
}

export function* watchDocuments() {
    yield takeEvery(actionTypes.authentication.AUTHENTICATE, authRequestDocumentsSaga);
    yield takeEvery(actionTypes.documents.REQUEST_DOCUMENTS, requestDocumentsSaga);
    yield takeEvery(actionTypes.documents.REQUEST_DOCUMENTS_UPLOAD, requestDocumentsUploadSaga);
    yield takeEvery(
        actionTypes.documents.REQUEST_DOCUMENTS_MAX_FILE_SIZE,
        requestDocumentsMaxFileSizeSaga
    );
}

export function* watchGames() {
    yield takeEvery(actionTypes.games.REQUEST_GAMES_LIST, requestGamesSaga);
    yield takeEvery(actionTypes.games.OPEN_GAME_LAUNCHER, openGameLauncherSaga);
    yield takeEvery(actionTypes.games.REQUEST_GAME_URL, requestGameUrlSaga);
    yield takeEvery(actionTypes.games.CLOSE_GAME_LAUNCHER, closeGameLauncherSaga);
    yield takeEvery(actionTypes.games.CLOSE_REDIRECT_GAME_LAUNCHER, closeRedirectGameLauncherSaga);
    yield takeEvery(actionTypes.games.REQUEST_GAME_COLLECTIONS, requestGameCollectionsSaga);
}

export function* watchWithdrawWizard() {
    yield takeEvery(actionTypes.withdraw_wizard.REQUEST_TAXES, getTaxSaga);
    yield takeEvery(actionTypes.withdraw_wizard.GET_LOCATIONS, getLocationsSaga);
    yield takeEvery(actionTypes.withdraw_wizard.SEND_DATA, sendWithdraw);
}

export function* watchCustomerSupport() {
    yield takeEvery(actionTypes.customer_support.SEND_COMPLAINT_FORM, sendComplaintFormSaga);
    yield takeEvery(actionTypes.customer_support.REQUEST_GAME_RULES, requestGameRulesSaga);
}

export function* watchJackpot() {
    yield takeEvery(actionTypes.authentication.AUTHENTICATE, cycleRequestJackpotSaga);
}

export function* watchPlayerWebsocket() {
    yield takeEvery(actionTypes.authentication.AUTHENTICATE, websocketPlayerLoginSaga);
    yield takeEvery(actionTypes.authentication.CLEAR, websocketPlayerLogoutSaga);
}

export function* watchMarketingOffer() {
    yield takeLatest(actionTypes.marketingOffer.GET, getMarketingOfferSaga);
}

export function* rootSaga() {
    const sagas = [
        call(watchMenu),
        call(watchAuth),
        call(watchApplication),
        call(watchLogin),
        call(watchWallet),
        call(watchRegister),
        call(watchResetPassword),
        call(watchProfile),
        call(watchTransactions),
        call(watchPaymentCheckout),
        call(watchWithdrawalCheckout),
        call(watchWithdrawals),
        call(watchDocuments),
        call(watchGames),
        call(watchWithdrawWizard),
        call(watchCustomerSupport),
        call(watchJackpot),
        call(watchPlayerWebsocket),
        call(watchHappyHourSaga),
        call(watchConfigSaga),
        call(freeBetsSaga),
        call(freeSpinsSaga),
        call(watchVoucherSaga),
        call(alertsSaga),
        call(druid),
        call(watchMarketingOffer)
    ];

    if (
        window.config &&
        (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")
    ) {
        sagas.push(call(betsSaga));
    }

    if (window.config && window.config.virtualsEnabled === "1") {
        sagas.push(call(virtualsSaga));
    }

    if (window.config && window.config.lottoEnabled === "1") {
        sagas.push(call(lottoSaga));
    }

    if (window.config && window.config.winnerFunEnabled === "1") {
        sagas.push(call(winnerFunSaga));
    }

    if (window.config && window.config.liveCasinoEnabled === "1") {
        sagas.push(call(liveCasinoSaga));
    }

    if (window.config && window.config.maintenanceModuleEnabled === "1") {
        sagas.push(call(maintenanceSaga));
    }

    if (window.config && window.config.wheelEnabled === "1") {
        sagas.push(call(wheelSaga));
    }

    if (window.config && window.config.promoEnabled === "1") {
        sagas.push(call(promoSaga));
    }

    if (window.config && window.config.promoCalendarEnabled === "1") {
        sagas.push(call(promoCalendarSaga));
    }

    if (window.config && window.config.depositTicketEnabled === "1") {
        sagas.push(call(depositTicketSaga));
    }

    if (window.config && window.config.tournamentsMissionsEnabled === "1") {
        sagas.push(call(tournamentsMissionsSaga));
    }

    if (window.config && window.config.lobbyEnabled === "1") {
        sagas.push(call(lobbySaga));
    }

    if (window.config && window.config.momentumEnabled === "1") {
        sagas.push(call(momentumSaga));
    }

    yield all(sagas);
}
