import React, { Component } from 'react';
import { connect } from 'react-redux';
//import GoogleAddress from "./GoogleAddress";
import * as stateActions from "../../../../store/actions";
import { withTranslation } from "react-i18next";
import Icons from "../../../../assets/Icons/Icons";
import Utils from "../../../../utils/Utils";
import TextField from "@material-ui/core/TextField";
//import CNPValidator from "../../../../utils/CNPValidator";
//import SocialSecurityNumberValidator from "../../../../utils/SocialSecurityNumberValidator";

class Address extends Component {
    constructor(props) {
        super(props);
        this.timerId = 'AddressValidation';
    }

    state = {
        fieldInput: '',
        message: "",
        ok: false,
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.address !== prevProps.address && this.props.address !== this.state.fieldInput) {
            this.setState({
                ...this.state,
                fieldInput: this.props.address
            })
            this.blurHandler();
        }
    }
    blurHandler() {
        this.props.setSpineSize('big');
        Utils.fixRegisterInputs('address');
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this), 0);
    }
    componentDidMount() {
        this.infoMessage = this.props.t("Insert a valid address");
    }
    onFocusHandler() {
        Utils.fixRegisterInputs('address');
        this.props.setSpineSize('small');
        this.setState({
            ...this.state,
            fieldInput: this.state.fieldInput === '' && this.props.address ? this.props.address : this.state.fieldInput
        })
    }

    changeHandler(event) {
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.firstName;
        } else {
            fieldInput = event.target.value;
        }
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this));
        this.setState({
            ...this.state,
            fieldInput: fieldInput
        });
        this.props.onChange({ field: "address", value: fieldInput, validated: false });
    }

    triggerHandler() {
        let message = "";
        let valid = this.state.fieldInput.length > 9;
        let ok = this.state.ok;

        if (!valid) {

            message = this.props.t("Inserted value is invalid");

            if (ok) {
                ok = false;
            }
        } else {
            if (!ok) {
                ok = true;
            }
        }

        this.setState({
            ...this.state,
            message: message,
            ok: valid
        });

        this.props.onChange({ field: "address", value: this.state.fieldInput, validated: ok });
    }

    render() {
        let errorMessage = this.state.message;
        if (errorMessage.type === "" && this.props.error !== "") {
            errorMessage = this.props.error; // error from server
        }
        let props = {
            error: (errorMessage !== "" && errorMessage !== this.infoMessage && errorMessage !== false),
            value: this.props.address,
        };
        return (
            <div className={'field'}>
                {Icons.get('register-address')}
                <TextField
                    {...props}
                    label={this.props.t("Address")}
                    id={"address"}
                    className={''}
                    onChange={this.changeHandler.bind(this)}
                    onFocus={this.onFocusHandler.bind(this)}
                    onBlur={this.blurHandler.bind(this)}
                    helperText={this.state.message}
                />
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered(fieldInput)),
        setSpineSize: (size) => dispatch(stateActions.setSpineSize(size))
    };
};

const mapStateToProps = state => {
    return {
        address: state.register.fields.address,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Address));
