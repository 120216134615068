import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes'
import ClientAPI from '../../ClientAPI/ClientAPI';
import * as paths from '../../ClientAPI/paths';
import Utils from "../../utils/Utils";
import {
    PAYMENT_IBAN,
    WITHDRAW_STEPS
} from "../../components/GlobalWidgets/LoggedIn/Withdraw/Steps/PaymentMethods";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";

export function* requestPendingWithdrawalsSaga(action) {
    const axios = ClientAPI.getInstance();
    let url = paths.withdrawals.PENDING_WITHDRAWALS;
    if (action.type === actionTypes.withdrawals.REQUEST_PENDING_VIVA_WITHDRAWALS) {
        url = paths.withdrawals.PENDING_VIVA_WITHDRAWALS
    }
    try {
        const response = yield axios({
            url: url,
            method: 'get',
        });
        if (!response) {
            throw new Error(`[ERROR] Pending Withdrawals response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Pending Withdrawals response has no 'result' property`);
        }

        yield put(actions.receivedPendingWithdrawals(response));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestLocationsSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.withdrawals.REQUEST_LOCATIONS,
            method: 'get',
        });

        if (!response) {
            throw new Error(`[ERROR] Request Locations response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Locations response has no 'result' property`);
        }

        yield put(actions.receivedLocations(response));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestTaxServiceSaga(action) {
    const axios = ClientAPI.getInstance();
    let amount = action.amount;
    let taxType = action.taxType;
    let id = action.id;

    let data  = {
        type: taxType,
        amount: amount,
    };

    if (id) {
        data.id = id;
    }

    if (!(amount > 0)) {
        return;
    }

    if (!(taxType)){
        taxType = 1;
    }

    amount = parseFloat(amount).toFixed(2);

    try {
        const response = yield axios({
            url: paths.withdrawals.REQUEST_TAX_SERVICE,
            method: 'post',
            data: data
        });

        if (!response) {
            throw new Error(`[ERROR] Request TaxService response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request TaxService response has no 'result' property`);
        }

        if(id){
            response.result.id = id;
        }

        if(taxType === 2){
            response.result.type = taxType;
        }

        yield put(actions.receivedTaxService(response));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestCancelWithdrawalSaga(action) {
    const axios = ClientAPI.getInstance();
    let amount = action.amount;
    let withdrawId = action.withdrawId;
    let url = paths.withdrawals.REQUEST_CANCEL_WITHDRAWAL;
    let data = {
        amount: amount,
        withdrawId: withdrawId,
    };
    if (action.type === actionTypes.withdrawals.REQUEST_CANCEL_VIVA_WITHDRAWAL) {
        url = paths.withdrawals.REQUEST_CANCEL_VIVA_WITHDRAWAL;
        data = {
            amount: amount,
            vivaWithdrawId: withdrawId,
        }
    }
    try {
        const response = yield axios({
            url: url,
            method: 'post',
            data: data
        });

        if (!response) {
            throw new Error(`[ERROR] Request CancelWithdrawal response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request CancelWithdrawal response has no 'result' property`);
        }
        yield put(actions.receivedCancelWithdrawal(response));
        yield put(actions.requestWallet());
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* downloadCSVSaga(action) {
    const axios = ClientAPI.getInstance();
    try {
        const response = yield axios({
            url: paths.withdrawals.DOWNLOAD_CSV,
            method: 'post',
            responseType: 'blob',
            data: {
                type: action.historyType,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                export: true,
            }
        })

    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestVerificationCodeSaga(action) {
    const axios = ClientAPI.getInstance();
    let withdrawID = action.withdrawID;

    try {
        const response = yield axios({
            url: paths.withdrawals.REQUEST_VERIFICATION_CODE,
            method: 'post',
            data: {
                withdrawID: withdrawID,
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Request Verification Code response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Verification Code response has no 'result' property`);
        }

        yield put(actions.receivedVerificationCode(response, withdrawID));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* getPaymentMethodsSaga(action) {
    const axios = yield Utils.getAxiosWithToken();

    try {
        const response = yield axios({
            url: paths.withdrawals.GET_PAYMENT_METHODS,
            method: 'get',
        });
        if (!response) {
            throw new Error(`[ERROR] Get Payment Methods response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Get Payment Methods response has no 'result' property`);
        }
        yield put(actions.receivedPaymentMethods(response));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* linkIBANSaga(action) {
    const axios = yield Utils.getAxiosWithToken();
    let data = {
        iban: action.iban,
        beneficiaryName: action.name,
    }
    if (action.friendlyName) {
        data = {
            ...data,
            friendlyName: action.friendlyName,
        }
    }

    try {
        const response = yield axios({
            url: paths.withdrawals.LINK_IBAN,
            method: 'post',
            data: data,
    });
        if (!response) {
            throw new Error(`[ERROR] Link IBAN response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Link IBAN response has no 'result' property`);
        }
        yield put(actions.getPaymentMethods());
        yield put(actions.setWithdrawWizardStep(WITHDRAW_STEPS[PAYMENT_IBAN]));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* withdrawSaga(action) {
    const axios = yield Utils.getAxiosWithToken();

    let data = {
        amount: action.amount * 100, // send in cents
        currencyCode: 1,
        withdrawType: action.withdrawType,
        withdrawTypeReference: action.withdrawTypeReference,
    };

    let pac = PlayerAbuseChecker.getInfo();
    data = {
        ...data,
        ...pac,
    };

    try {
        const response = yield axios({
            url: paths.withdrawals.WITHDRAW,
            method: 'post',
            data: data,
        });
        if (!response) {
            throw new Error(`[ERROR] Withdraw response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Withdraw response has no 'result' property`);
        }
        yield put(actions.requestWallet());
        yield put(actions.receivedWithdraw({receivedData:response.result}));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestWithdrawHistorySaga(action) {
    const axios = yield Utils.getAxiosWithToken();

    try {
        const response = yield axios({
            url: paths.withdrawals.GET_WITHDRAW_HISTORY,
            method: 'get',
        });
        if (!response) {
            throw new Error(`[ERROR] Pending Withdrawals response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Pending Withdrawals response has no 'result' property`);
        }

        yield put(actions.receivedPendingWithdrawals(response));
    } catch (error) {
        console.log(error);
        return;
    }
}