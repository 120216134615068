import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from "../../../../../store/actions/index";
import connect from "react-redux/es/connect/connect";
import TitleBar from "../../../../UI/TitleBar/TitleBar";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PageTitle from '../../../../UI/PageTitle/PageTitle';
import Button from '../../../../UI/Button/Button';

import './TakeABreakConfirmation.scss';
import ConfirmPassword from './ConfirmPassword/ConfirmPassword';

class TakeABreakConfirmation extends Component {

    state = {
        open: false,
    }

    constructor(props) {
        super(props);
        this.redirectURL = '/';
    }

    saveChoice(limit_type, limit_type_period) {
        this.props.onSaveChoice(limit_type, limit_type_period);
        this.logoutHandler();
    }

    logoutHandler() {
        this.props.history.push(this.redirectURL);
        this.props.onLogout();
    }

    handleOpenPopup = () => {
        this.setState({
            ...this.state,
            open: true,
        });
    }

    handleClosePopup = (bool) => {
        this.setState({
            ...this.state,
            open: false,
        });

        if(bool){
            this.saveChoice(2, this.props.activeChoice);
        }
    }

    render() {

        return (
            <div className={"TakeABreakConfirmationScreen"}>
                <TitleBar pageTitle={this.props.t('Responsible Gaming')} backHandler={() => this.props.return(false)}/>
                
                <AppBar position="static" className="SubtitleBar">
                    <Typography variant="subtitle1">{this.props.t('Take a Break')}</Typography>
                </AppBar>
                
                <div className="TakeABreakConfirmationContent">
                    <PageTitle icon={<NotInterestedIcon className={"iconSettings"} />} title={this.props.title} subtitle={this.props.subtitle} />
                    
                    <p className="ContentText">
                        {this.props.t('Duration') + ' ' + this.props.duration}<br />
                        {this.props.text}
                    </p>
    
                    <Button 
                        type={"button"} 
                        full 
                        className={"gradient"} 
                        value={this.props.t('Lock the account for ') + ' ' + this.props.duration}
                        click={() => this.handleOpenPopup()}
                    />

                    <Button 
                        type={"button"} 
                        full 
                        className={"gray"} 
                        value={this.props.t('Back')} 
                        click={() => this.props.return(false)}
                    />
                    { this.state.open && <ConfirmPassword open={this.state.open} close={(bool) => this.handleClosePopup(bool)} /> }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveChoice: (limit_type, limit_type_period) => dispatch(storeActions.setSelfexclusion(limit_type, limit_type_period)),
        onLogout: () => dispatch(storeActions.logout()),
    };
};
export default withTranslation()(withRouter(connect(null, mapDispatchToProps)(TakeABreakConfirmation)));
