import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Div100vh from 'react-div-100vh';

import Games from "./games";
import "./MainPageMob.scss";
import Game from '../../components/CasinoGames/sections/Game/Game';
import TitleBar from '../../components/UI/TitleBar/TitleBar';
import TopWithdrawals from "../components/TopWidrawals";

class MainPage extends Component {
  backHandler = () => {
    this.props.history.push("/");
  }
  launchHandler = (gameItem) => {
    this.props.history.push(`/virtuale/jocuri/${gameItem.id}`);
  }

  render() {
    const games1 = Games.map((game, i) => {
      if (i > 2) return null;
      const config = {
        id: game.id,
        name: game.name,
        type: "virtual",
        promoted: false,
        provider: 0,
        img: game.img,
        showName: true,
      }
      return (
        <div className={`game ${i === 0 ? 'big' : ''}`} key={`game_x_${i}`}>
          <Game item={config} launchHandler={this.launchHandler} />
        </div>);
    });

    const games2 = Games.map((game, i) => {
      if (i < 3) return null;
      const config = {
        id: game.id,
        name: game.name,
        type: "virtual",
        promoted: false,
        provider: 0,
        img: game.img,
        showName: true,
      }
      return (
        <div className={`game ${i === 3 ? 'big' : ''}`} key={`game_x_${i}`}>
          <Game item={config} launchHandler={this.launchHandler} />
        </div>);
    });

    return (
      <Div100vh className={`casinoGamesPage ${window.config.showTopMessageWebsiteSwitcher !== '1' ? '' : 'website_switcher'}`}>
        <div className={this.props.application.topMenuHeight}></div>
        <TitleBar pageTitle={this.props.t("Virtual")} backHandler={this.backHandler} />
        <div className={"virtuals-main-cs"}>
          <div id="GamesList">
            <div className="GamesCards">
              {games1}
            </div>
          </div>
          <TopWithdrawals className={"mb20"} />
          <div id="GamesList">
            <div className="GamesCards">
              {games2}
            </div>
          </div>
        </div>
      </Div100vh>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    application: state.application,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //contentLoaded: () => dispatch(stateActions.hideContentLoader()),
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPage)));

