import * as actionTypes from './actionTypes';

export const requestAccount = () => {
    return {
        type: actionTypes.profile.REQUEST_ACCOUNT,
    }
};

export const receivedAccount = (receivedData) => {
    return dispatch => {

        dispatch({
            type: actionTypes.application.CHANGE_LANGUAGE,
            language: receivedData.language
        });

        dispatch({
            type: actionTypes.profile.RECEIVED_ACCOUNT,
            account: receivedData,
        });
    }
};

export const resetAccount = () => {
    return {
        type: actionTypes.profile.RESET_ACCOUNT,
    }
};

export const requestLimits = () => {
    return {
        type: actionTypes.profile.REQUEST_LIMITS,
    }
};

export const receivedLimits = (receivedData) => {
    return {
        type: actionTypes.profile.RECEIVED_LIMITS,
        limits: receivedData,
    }
};

export const setNewLimit = (limit_type, limit_type_period, limit_value) => {
    return {
        type: actionTypes.profile.SET_NEW_LIMIT,
        limit_type: limit_type,
        limit_type_period: limit_type_period,
        limit_value: limit_value,
    }
};
/**
 * @deprecated
 * @param limit_type
 * @param limit_type_period
 * @returns {{limit_type, type: string, limit_type_period}}
 */
export const setSelfexclusion = (limit_type, limit_type_period) => {
    return {
        type: actionTypes.profile.SET_SELFEXCLUSION,
        limit_type: limit_type,
        limit_type_period: limit_type_period,
    }
};
export const selfExclude = (data) => {
    return {
        type: actionTypes.profile.SELF_EXCLUDE,
        data: data
    }
}
export const clearPasswordCache = () => {
    return {
        type: actionTypes.profile.CLEAR_PASSWORD_CACHE,
    }
};

export const checkPassword = (password) => {
    return {
        type: actionTypes.profile.CHECK_PASSWORD,
        password: password,
    }
};

export const receivedPasswordCheck = (receivedData) => {
    return {
        type: actionTypes.profile.RECEIVED_PASSWORD_CHECK,
        data: receivedData,
    }
};

export const changePassword = (currentPassword, newPassword) => {
    return {
        type: actionTypes.profile.CHANGE_PASSWORD,
        currentPassword: currentPassword,
        newPassword: newPassword,
    }
};

export const receivedChangePassword = (receivedData) => {
    return {
        type: actionTypes.profile.RECEIVED_CHANGE_PASSWORD,
        receivedChangePassword: receivedData,
    }
};

export const checkPhone = (newPhone) => {
    return {
        type: actionTypes.profile.CHECK_PHONE,
        newPhone: newPhone,
    }
};

export const receivedPhone = (receivedData) => {
    return {
        type: actionTypes.profile.RECEIVED_PHONE,
        receivedPhone: receivedData,
    }
};

export const checkPhoneCode = (newPhone, code) => {
    return {
        type: actionTypes.profile.CHECK_PHONE_CODE,
        newPhone: newPhone,
        code: code,
    }
};

export const receivedPhoneCode = (receivedData) => {
    return {
        type: actionTypes.profile.RECEIVED_PHONE_CODE,
        receivedPhoneCode: receivedData,
    }
};

export const getChatInfo = () => {
    return {
        type: actionTypes.profile.GET_CHAT_INFO
    }
};

export const receivedChatInfo = (response) => {
    return {
        type: actionTypes.profile.RECEIVED_CHAT_INFO,
        value: response
    }
}

export const resendVerificationEmail = () => {
    return {
        type: actionTypes.profile.RESEND_VERIFICATION_EMAIL
    }
};

export const sendSelfExclusionCodes = (notificationType = 0) => {
    return {
        type: actionTypes.profile.SEND_SELF_EXCLUSION_CODES,
        notificationType: notificationType

    }
}