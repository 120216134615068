import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../../store/actions";
import Moment from "react-moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import './Bonus.scss';
import Icons from "../../../../assets/Icons/Icons";
import Button from "@material-ui/core/Button";

const REQUEST_TYPE = 3; // Bonus

const visibleStyle = {
    maxHeight: "0px",
    overflow: "visible"
};

class Bonus extends Component {

    constructor(props) {
        super(props);

        this.bonusStatus = {
            0: props.t("other"),
            1: props.t("active"),
            2: props.t("inactive"),
            3: props.t("expired"),
            4: props.t("forfeited"),
            5: props.t("wagered"),
            6: props.t("consumed"),
        };
    }

    state = {
        exportDisabled: false,
    };

    componentDidMount() {
        if (this.props.auth.auth_type !== "user") {
            this.props.history.push("/");
        } else {
            this.props.onRequestTransactionsHistory(REQUEST_TYPE);
        }
    }

    formatCurrency = (value) => {
        value = parseFloat(value);
        return (
            value
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
                .replace(',00', '')
        )
    }
    downloadCsv = () => {
        this.setState({
            ...this.state,
            exportDisabled: true,
        });
        this.props.downloadCSV();
    };

    render() {

        let bonusList = <CircularProgress className={'Loading'} />;

        if (this.props.transactions.bonuses !== null && this.props.transactions.bonuses.length > 0) {
            bonusList = this.props.transactions.bonuses.map(bonus => {
                let status = bonus.status;
                let now = Date.now()/1000;
                if (status === 1 && (bonus.startDate > now || bonus.endDate < now)) {
                    status = 3;
                }

                return (
                    <div key={bonus.id} className={"SingleBonus"}>
                        <span className={"BonusDate"}><Moment format={"DD/MM/YYYY"} unix>{bonus.startDate}</Moment></span>
                        <div className="BonusDescription">
                            <span>{this.props.t(bonus.name)}</span>
                            <div className="BonusTotals">
                                <span className={"BonusAmount"}>{this.formatCurrency(bonus.amount) + " RON"}</span>
                                {bonus.amountGranted > 0 ? <span className={"BonusGranted"}>{this.formatCurrency(bonus.amountGranted) + " RON"}</span> : null}
                            </div>
                        </div>
                        <div className={"BonusStatus"}>
                            <span className={"BonusStatus-" + status}>{this.props.t(this.bonusStatus[status])}</span>
                        </div>
                    </div>
                );
            });
        } else if (this.props.transactions.show) {
            bonusList = <h5 className={"NoBonus"}>{this.props.t("There is no bonus history at this moment")}.</h5>;
        }

        let style = null;
        if (this.props.tabIndex !== 0) {
            style = visibleStyle;
        }

        return (
            <div className={"BonusScreen"} style={style}>
                {bonusList}
                <Button className={'export'} onClick={this.downloadCsv.bind(this)} disabled={this.state.exportDisabled}>
                    {Icons.get("historyCsvDownload", "historyCsvDownload")} {this.props.t("Download History for last 90 days")}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.transactions,
        auth: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestTransactionsHistory: (requestType) => dispatch(storeActions.requestTransactionsHistory(requestType)),
        downloadCSV: () => dispatch(storeActions.downloadCSV(3))
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Bonus)));
