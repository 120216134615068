// eslint-disable-next-line
export const en = {
	"404": "404",
	"Self exclusion period": "Self exclusion period",
	"from": "from",
	"to": "to",
	"If you want to cancel this action, please contact support.": "If you want to cancel this action, please contact support.",
	"Cancel": "Cancel",
	"Open support": "Open support",
	"50 LEI FREE": "50 LEI FREE",
	"Friends with benefits": "Friends with benefits",
	"Money for free, once we know each other": "Money for free, once we know each other",
	"TAKE 1,000 LEI BONUS": "TAKE 1,000 LEI BONUS",
	"What's your number?": "What's your number?",
	"Mega Jackpots. Prizes. Tournaments.": "Mega Jackpots. Prizes. Tournaments.",
	"Start Game": "Start Game",
	"register": "Register",
	"Sign-in": "Sign-in",
	"Betting": "Betting",
	"Better odds. Bet easier.": "Better odds. Bet easier.",
	"coming soon": "coming soon",
	"Live Casino": "Live Casino",
	"VIP experience": "VIP experience",
	"Pirates Treasure": "Pirates Treasure",
	"Here you will embark on an adventure": "Here you will embark on an adventure",
	"Legal": "Legal",
	"If you need any help, do not hesitate to get in touch with us using one of the methods below.": "If you need any help, do not hesitate to get in touch with us using one of the methods below.",
	"LIVE CHAT": "LIVE CHAT",
	"Service not available": "Service not available",
	"Average waiting time:": "Average waiting time",
	"E-MAIL": "E-MAIL",
	"CALL ME BACK": "CALL ME BACK",
	"About Us": "Contact",
	"PlayOnline is operated by ${companyName}, registered in ${countryName} with number ${registerNumber} at the address listed below. In Romania, ${companyName} operates under license number ${onjnLicenseNumber} obtained on ${date} by the Romanian Gambling Office (ONJN), valid though ${licenseExpirationNumber}.": "PlayOnline is operated by ${companyName}, registered in Malta with number ${registerNumber} at the address listed below. In Romania, ${companyName} operates under license number ${onjnLicenseNumber} obtained on ${date} by the Romanian Gambling Office (ONJN), valid though ${licenseExpirationNumber}.",
	"Gambling is prohibited under age of 18. Gambling might be prohibited in your area. It is the player’s responsibility to comply with the law regulations.": "Gambling is prohibited under age of 18. Gambling might be prohibited in your area. It is the player’s responsibility to comply with the law regulations.",
	"Gambling may become addictive and impact your financials. Read about responsible gaming": "Gambling may become addictive and impact your financials. Read about responsible gaming",
	"here": "here",
	"Address:": "Address:",
	"Invalid email": "Invalid email",
	"Message sent": "Message sent",
	"Success": "Success",
	"Our Customer Support will contact you shortly. Thank you for your paticence.": "Our Customer Support will contact you shortly. Thank you for your paticence.",
	"Back to casino": "Back to casino",
	"Message not sent": "Message not sent",
	"Failed": "Failed",
	"Sorry! I could not send your email.": "Sorry! I could not send your email.",
	"Please try again later.": "Please try again later.",
	"Post a Complaint": "Post a Complaint",
	"Complaint Form": "Complaint Form",
	"Name": "Name",
	"Email": "Email",
	"Subject": "Subject",
	"Body": "Body",
	"Submit": "Submit",
	"Customer Support": "Customer Support",
	"FAQ": "FAQ",
	"What is \"Deposits & Withdrawals in Location\"?": "What is \"Deposits & Withdrawals in Location\"?",
	"We made it simpler so you can play as you want. If you want to stay away from Credit Cards and other digital payment systems, you are free to deposit and withdraw in any casino location using only your ID card.": "We made it simpler so you can play as you want. If you want to stay away from Credit Cards and other digital payment systems, you are free to deposit and withdraw in any casino location using only your ID card.",
	"Can I cash-in in any Location?": "Can I cash-in in any Location?",
	"You can cash-in up to 200 Lei per day in any location. Withdrawal amounts greater than 200 Lei can only be cashed-in in the selected location.": "You can cash-in up to 200 Lei per day in any location. Withdrawal amounts greater than 200 Lei can only be cashed-in in the selected location.",
	"How do I Deposit in Location?": "How do I Deposit in Location?",
	"Visit any casino location with the money and your ID card. Your account will receive the funds instantly and you will receive confirmation by SMS.": "Visit any casino location with the money and your ID card. Your account will receive the funds instantly and you will receive confirmation by SMS.",
	"How do I initiate a Withdrawal?": "How do I initiate a Withdrawal?",
	"How do I cash-in my withdrawn money in location?": "How do I cash-in my withdrawn money in location?",
	"You will receive a SMS Code once your withdrawn has been approved. Go in the chosen location": "You will receive a SMS Code once your withdrawn has been approved. Go in the chosen location",
	"I want to cancel a withdrawal and return the funds to my play account.": "I want to cancel a withdrawal and return the funds to my play account.",
	"I'm trying to initiate a withdrawal without success.": "I'm trying to initiate a withdrawal without success.",
	"In most of the cases there will be an error message explaining the reason. The main causes are (1) you have not completed your Identity Verification process or (2) you have active BONUSES that have wager requirements in progress.": "In most of the cases there will be an error message explaining the reason. The main causes are (1) you have not completed your Identity Verification process or (2) you have active BONUSES that have wager requirements in progress.",
	"What kind of currency can be used to deposit and withdraw?": "What kind of currency can be used to deposit and withdraw?",
	"Right now, the following currency can be used": "Right now, the following currency can be used",
	"Lei (RON)": "Lei (RON)",
	"What taxes apply on withdrawals?": "What taxes apply on withdrawals?",
	"Starting on the 23rd of March 2018, the following taxes apply for any withdrawal": "Starting on the 23rd of March 2018, the following taxes apply for any withdrawal",
	"From": "From",
	"inclusive": "inclusive",
	"applied to the amount over": "applied to the amount over",
	"Over": "Over",
	"I have cashed-in less money than the amount requested in the withdrawal.": "I have cashed-in less money than the amount requested in the withdrawal.",
	"We do not tax you in any way; the difference comes from the state taxes accordingly to the law.": "We do not tax you in any way; the difference comes from the state taxes accordingly to the law.",
	"My wallet received less money than the deposit value.": "My wallet received less money than the deposit value.",
	"Based on the Government’s Emergency Decree 114/2018, every deposit is taxed 2% of its value, transferred to the State Budget.": "Based on the Government’s Emergency Decree 114/2018, every deposit is taxed 2% of its value, transferred to the State Budget.",
	"Privacy Policy": "Privacy Policy",
	"Introduction": "Introduction",
	"As an on-line gambling Organizer, PLAY ONLINE SOLUTIONS LTD (“We”) will process the personal data of the participants by registering the game account on the website and is committed to protecting your personal information and processing it fairly and transparently in accordance with the provisions of EU Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (“GDPR/the Regulation”).": "As an on-line gambling Organizer, PLAY ONLINE SOLUTIONS LTD (“We”) will process the personal data of the participants by registering the game account on the website and is committed to protecting your personal information and processing it fairly and transparently in accordance with the provisions of EU Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (“GDPR/the Regulation”).",
	"Above all, we are faithful to the following key data processing principles:": "Above all, we are faithful to the following key data processing principles:",
	"Lawfulness, fairness and transparency": "Lawfulness, fairness and transparency",
	"we process personal data on legal grounds, fairly and in a transparent manner;": "we process personal data on legal grounds, fairly and in a transparent manner;",
	"Purpose limitation": "Purpose limitation",
	"we collect personal data for specified, explicit and legitimate purposes;": "we collect personal data for specified, explicit and legitimate purposes;",
	"Data minimization": "Data minimization",
	"we only collect and keep personal data that is adequate, relevant and limited to what is necessary in relation to the purposes for which it is processed and according to the legal provisions in force;": "we only collect and keep personal data that is adequate, relevant and limited to what is necessary in relation to the purposes for which it is processed and according to the legal provisions in force;",
	"Accuracy": "Accuracy",
	"we ensure that the personal data we keep is accurate, kept up to date or otherwise erased or rectified;": "we ensure that the personal data we keep is accurate, kept up to date or otherwise erased or rectified;",
	"Storage limitation": "Storage limitation",
	"we ensure that personal data is stored only for the period of time that is strictly necessary for the fulfilment of our purposes or is otherwise erased or anonymized;": "we ensure that personal data is stored only for the period of time that is strictly necessary for the fulfilment of our purposes or is otherwise erased or anonymized;",
	"Integrity and confidentiality": "Integrity and confidentiality",
	"we ensure appropriate security by implementing organizational measures and adequate technical solutions which are harmoniously combined as to guard personal data against unauthorized or unlawful processing and against accidental loss, destruction or damage;": "we ensure appropriate security by implementing organizational measures and adequate technical solutions which are harmoniously combined as to guard personal data against unauthorized or unlawful processing and against accidental loss, destruction or damage;",
	"Accountability": "Accountability",
	"we recognize our responsibility for ensuring the lawful processing of personal data.": "we recognize our responsibility for ensuring the lawful processing of personal data.",
	"Your personal information belongs to you and we respect this. It is your right to be fully informed about the processing operations we perform with the personal data you provide us or we collect about you. In order to make available to you all this information in a way that is as accessible and concise as possible, we have drafted this privacy policy (“Privacy Policy”) applicable to online personal data processing operations.": "Your personal information belongs to you and we respect this. It is your right to be fully informed about the processing operations we perform with the personal data you provide us or we collect about you. In order to make available to you all this information in a way that is as accessible and concise as possible, we have drafted this privacy policy (“Privacy Policy”) applicable to online personal data processing operations.",
	"Thus, this Privacy Policy gives you detailed information on the personal data we process, how we collect it, the purposes for which we use personal data, and how we keep it safe. This Privacy Policy also describes what your rights as data subject are, so please review it alongside the Terms and Conditions section.": "Thus, this Privacy Policy gives you detailed information on the personal data we process, how we collect it, the purposes for which we use personal data, and how we keep it safe. This Privacy Policy also describes what your rights as data subject are, so please review it alongside the Terms and Conditions section.",
	"To facilitate your understanding of this Privacy Policy, please find below definitions and explanations of the specific notions used:": "To facilitate your understanding of this Privacy Policy, please find below definitions and explanations of the specific notions used:",
	"Personal data": "Personal data",
	"any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.": "any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.",
	"Data subject": "Data subject",
	"an identified or identifiable natural person whose personal data is processed.": "an identified or identifiable natural person whose personal data is processed.",
	"Processing": "Processing",
	"means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.": "means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.",
	"Controller": "Controller",
	"the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.": "the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.",
	"Processor": "Processor",
	"a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.": "a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.",
	"Recipient": "Recipient",
	"a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not.": "a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not.",
	"Consent": "Consent",
	"Freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.": "Freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.",
	"Online identifiers": "Online identifiers",
	"internet protocol addresses, cookie identifiers or other identifiers such as radio frequency identification tags provided by data subject devices, applications, tools and protocols. These may leave traces which, in particular when combined with unique identifiers and other information received by the servers, may be used to create profiles of natural persons and identify them.": "internet protocol addresses, cookie identifiers or other identifiers such as radio frequency identification tags provided by data subject devices, applications, tools and protocols. These may leave traces which, in particular when combined with unique identifiers and other information received by the servers, may be used to create profiles of natural persons and identify them.",
	"Who we are?": "Who we are?",
	"Your personal data are being processed by PLAY ONLINE SOLUTIONS LTD, acting as controller of your personal data:": "Your personal data are being processed by PLAY ONLINE SOLUTIONS LTD, acting as controller of your personal data:",
	"PLAY ONLINE SOLUTIONS LTD": "PLAY ONLINE SOLUTIONS LTD",
	"is a Maltese limited liability company, registered in ${countryName} under number C59636, having its registered office in 3 Advance Business Centre, Triq G. Flores, St. Venera SVR1950, Malta": "is a Maltese limited liability company, registered in Malta under number C59636, having its registered office in 3 Advance Business Centre, Triq G. Flores, St. Venera SVR1950, Malta",
	"How can you contact us?": "How can you contact us?",
	"In order to ask us questions about this Privacy Policy or to submit us request for the exercise of your rights as data subject, please write to us or call us using the following contact details": "In order to ask us questions about this Privacy Policy or to submit us request for the exercise of your rights as data subject, please write to us or call us using the following contact details",
	"E-mail address": "E-mail address:",
	"Headquarters address": "Headquarters address",
	"4 Șoseaua Pipera| 1st District | Bucharest| Romania": "4 Șoseaua Pipera| 1st District | Bucharest| Romania",
	"Phone number": "Phone number",
	"Contact person": "Contact person",
	"Mrs. Irina Crăciun, appointed Data Protection Officer": "Mrs. Irina Crăciun, appointed Data Protection Officer",
	"What personal data do we process, when and for what purposes?": "What personal data do we process, when and for what purposes?",
	"PERSONAL DATA PROCESSED WHEN YOU CARRY OUT YOUR ACTIVITY ON OUR WEBSITE": "PERSONAL DATA PROCESSED WHEN YOU CARRY OUT YOUR ACTIVITY ON OUR WEBSITE",
	"We collect your personal information when you register your game account by using online forms and when you provide your details and documents by email. We also collect information about the transactions you make, excluding the details of the cards used to make the payments:": "We collect your personal information when you register your game account by using online forms and when you provide your details and documents by email. We also collect information about the transactions you make, including the details of the cards used to make the payments. Moreover, we collect such personal information through studies or during periodic reviews that we or the companies we employ for this purpose carry out.",
	"The data included in your ID card,": "The data included in your ID card,",
	"Copy of your ID card,": "Copy of your ID card,",
	"Copy of your Utility Bill,": "Copy of your Utility Bill,",
	"When registering on our website, your browser could automatically send us information about:": "When registering on our website, your browser could automatically send us information about:",
	"IP address of your device,": "IP address of your device,",
	"Date and time of access,": "Date and time of access,",
	"used browser,": "used browser,",
	"the operating system of your device,": "the operating system of your device,",
	"information in relation to your Internet service provider,": "information in relation to your Internet service provider,",
	"status and amount of data transferred during the visit of our websites.": "status and amount of data transferred during the visit of our websites.",
	"We process the mentioned data for the following purposes:": "We process the mentioned data for the following purposes:",
	"to comply with legal requirements applicable to online gambling,": "to comply with legal requirements applicable to online gambling,",
	"to set up and manage your account and data (including processing data on deposits and withdrawals),": "to set up and manage your account and data (including processing data on deposits and withdrawals),",
	"receive and answer to communications and requests from you or Legal Authorities,": "receive and answer to communications and requests from you or Legal Authorities,",
	"to notify you of any updates about the Software and / or Services,": "to notify you of any updates about the Software and / or Services,",
	"to ensure, including through periodic reviews of the information you provide us, that the activity on the gaming platform is conducted in accordance with mandatory legal provisions,": "to ensure, including through periodic reviews of the information you provide us, that the activity on the gaming platform is conducted in accordance with mandatory legal provisions,",
	"investigate and assist the competent authorities in analyzing any activity that is suspected of being illegal, fraudulent or inappropriate in relation to the Services,": "investigate and assist the competent authorities in analyzing any activity that is suspected of being illegal, fraudulent or inappropriate in relation to the Services,",
	"to conduct promotional campaigns and market studies,": "to conduct promotional campaigns and market studies,",
	"to draft statistics on your use of the Services by you and other clients,": "to draft statistics on your use of the Services by you and other clients,",
	"to periodically notify you on information on the available Services and Campaigns, change of terms applicable to Casino activity, technical updates and changes to Terms & Conditions,": "to periodically notify you on information on the available Services and Campaigns, change of terms applicable to Casino activity, technical updates and changes to Terms & Conditions,",
	"to ensure the fulfillment of our contractual obligations towards the participants,": "to ensure the fulfillment of our contractual obligations towards the participants,",
	"to ensure a smooth connection to our website and proper use of our website,": "to ensure a smooth connection to our website and proper use of our website,",
	"for evaluating system security and stability,": "for evaluating system security and stability,",
	"for further administrative purposes.": "for further administrative purposes.",
	"The grounds of processing such data are the performance of the contract for providing you our services and our legitimate interest to ensure that our website functions adequately.": "The grounds of processing such data are the performance of the contract for providing you our services and our legitimate interest to ensure that our website functions adequately.",
	"Also, when visiting our website, we install cookies and other tracking technologies on your device and use analysis services. For further details, please refer to section 10 hereto representing our Cookie Policy.": "Also, when visiting our website, we install cookies and other tracking technologies on your device and use analysis services. For further details, please refer to section 10 hereto representing our Cookie Policy.",
	"PERSONAL DATA PROCESSED WHEN REGISTERING AND LEAVING COMMENTS ON OUR CHAT": "PERSONAL DATA PROCESSED WHEN REGISTERING AND LEAVING COMMENTS ON OUR CHAT",
	"When registering or leaving comments on our online chat your browser will provide us with the following information:": "When registering or leaving comments on our online chat your browser will provide us with the following information:",
	"username": "username",
	"name,": "name,",
	"e-mail address,": "e-mail address,",
	"date": "date",
	"time": "time",
	"location": "location",
	"comment,": "comment,",
	"your site URL.": "your site URL.",
	"IP address": "Address",
	"We process such data exclusively for operating the chat section of our website and for granting you the possibility to interact with our support team, based on our legitimate interests to obtain feedback and to help you in your online activity, by interacting with our staff.": "We process such data exclusively for operating the chat section of our website and for granting you the possibility to interact with our support team, based on our legitimate interests to obtain feedback and to help you in your online activity, by interacting with our staff.",
	"We draw your attention to the fact the comments are a public section, therefore you should avoid disclosing personal information in your comments.": "We draw your attention to the fact the comments are a public section, therefore you should avoid disclosing personal information in your comments.",
	"We shall keep the personal data above up to a period of 1 year.": "We shall keep the personal data above up to a period of 1 year.",
	"PROCESSING OF SENSITIVE DATA OR MINORS’ DATA": "PROCESSING OF SENSITIVE DATA OR MINORS’ DATA",
	"We shall not collect through our website sensitive information, unless legally required for specific purposes.": "We shall not collect through our website sensitive information, unless legally required for specific purposes.",
	"Our website and its content is not intended for or addressed to minors. Thus, we shall not deliberately collect or maintain personal data about minors.": "Our website and its content is not intended for or addressed to minors. Thus, we shall not deliberately collect or maintain personal data about minors.",
	"On what grounds do we process personal data?": "On what grounds do we process personal data?",
	"When processing your personal data, we rely on the following legal grounds:": "When processing your personal data, we rely on the following legal grounds:",
	"Consent, as per article 6 (1) a) GDPR": "Consent, as per article 6 (1) a) GDPR",
	"– we may (but usually do not) need your consent to use your personal information. You can exercise your right of consent withdrawal by contacting us as described below.": "– we may (but usually do not) need your consent to use your personal information. You can exercise your right of consent withdrawal by contacting us as described below.",
	"Performance of a contract ": "Performance of a contract ",
	"or to take steps at your request prior to entering into a contract with us, as per article 6 (1) b) GDPR – we may need to collect and use your personal information in order to take steps for the conclusion of a contract, to conclude a contract with you (Terms and Conditions), to perform our obligations under a contract with you or otherwise execute the contract. The Contract referred to herein is the Terms and Conditions on our website.": "or to take steps at your request prior to entering into a contract with us, as per article 6 (1) b) GDPR – we may need to collect and use your personal information in order to take steps for the conclusion of a contract, to conclude a contract with you (Terms and Conditions), to perform our obligations under a contract with you or otherwise execute the contract. The Contract referred to herein is the Terms and Conditions on our website.",
	"Compliance with law or regulation, as per article 6 (1) c) GDPR": "Compliance with law or regulation, as per article 6 (1) c) GDPR",
	"– we may use your personal data in order to comply with an applicable law or regulation.": "– we may use your personal data in order to comply with an applicable law or regulation.",
	"Legitimate interest, as per article 6 (1) f) GDPR": "Legitimate interest, as per article 6 (1) f) GDPR",
	"– we may use your personal information for our legitimate interests, some examples of which are given above.": "– we may use your personal information for our legitimate interests, some examples of which are given above.",
	"To whom do we disclose or transfer personal data?": "To whom do we disclose or transfer personal data?",
	"Your personal information will be mainly disclosed to our employees from specific departments and to the companies that are providers of PLAY ONLINE SOLUTIONS LTD, as it will prove to be necessary.": "Your personal information will be mainly disclosed to our employees from specific departments and to the companies that are providers of PLAY ONLINE SOLUTIONS LTD, as it will prove to be necessary.",
	"When justified and/or necessary, we may also share your personal information outside our company. This may include:": "When justified and/or necessary, we may also share your personal information outside our company. This may include:",
	"Third party agents/suppliers or contractors bound by obligations of confidentiality. This may include, without limitation, IT and communications service providers.": "Third party agents/suppliers or contractors bound by obligations of confidentiality. This may include, without limitation, IT and communications service providers.",
	"Third parties relevant to the services that we provide. This may include, without limitation, counterparties to online gaming, public authorities and institutions, as empowered by the law to request information on personal data collected during PLAY ONLINE SOLUTIONS LTD’s specific activity.": "Third parties relevant to the services that we provide. This may include, without limitation, counterparties to online gaming, public authorities and institutions, as empowered by the law to request information on personal data collected during PLAY ONLINE SOLUTIONS LTD’s specific activity.",
	"To the extent required by law, search warrant or court order, for example, if we are under a duty to disclose your personal information in order to comply with any legal obligation.": "To the extent required by law, search warrant or court order, for example, if we are under a duty to disclose your personal information in order to comply with any legal obligation.",
	"In case it will be necessary to transfer your personal information outside the European Economic Area, we will ensure that it is protected and transferred in a manner consistent with legal requirements, including the following:": "In case it will be necessary to transfer your personal information outside the European Economic Area, we will ensure that it is protected and transferred in a manner consistent with legal requirements, including the following:",
	"the European Commission has issued a decision recognizing the adequate character of data protection in the envisaged third country or where the recipient is located in the US, it may be a certified member of the EU-US Privacy Shield scheme;": "the European Commission has issued a decision recognizing the adequate character of data protection in the envisaged third country or where the recipient is located in the US, it may be a certified member of the EU-US Privacy Shield scheme;",
	"the recipient has signed a contract based on “standard contractual clauses” approved by the European Commission, obliging them to protect your personal information, or": "the recipient has signed a contract based on “standard contractual clauses” approved by the European Commission, obliging them to protect your personal information, or",
	"we have obtained your prior explicit consent.": "we have obtained your prior explicit consent.",
	"In all cases, however, any transfer of your personal information will be compliant with applicable data protection law.": "In all cases, however, any transfer of your personal information will be compliant with applicable data protection law.",
	"You can obtain more details of the protection given to your personal information in case of transfer outside the European Economic Area (including a sample copy of the standard contractual clauses) by contacting us using the details set in section 3 above.": "You can obtain more details of the protection given to your personal information in case of transfer outside the European Economic Area (including a sample copy of the standard contractual clauses) by contacting us using the details set in section 3 above.",
	"Where and for how long do we store personal data?": "Where and for how long do we store personal data?",
	"Your personal data is stored by PLAY ONLINE SOLUTIONS LTD mainly on servers located within the European Economic Area.": "Your personal data is stored by PLAY ONLINE SOLUTIONS LTD mainly on servers located within the European Economic Area.",
	"We process and retain personal data only for as long as is necessary to fulfil our purposes, contractual obligations and other legal obligations of storage / archiving, as the case may be.": "We process and retain personal data only for as long as is necessary to fulfil our purposes, contractual obligations and other legal obligations of storage / archiving, as the case may be.",
	"We shall retain the data only for as long as is necessary and / or prescribed by law for that purpose. For example:": "We shall retain the data only for as long as is necessary and / or prescribed by law for that purpose. For example:",
	"Data processed for anti-money laundering purposes and supporting documents will be kept for a period of 5 years, as the case may be, according to the Anti-money LaunderingLaw no. 589/18.07.2019;": "Data processed for anti-money laundering purposes and supporting documents will be kept for a period of 5 years, as the case may be, according to the Anti-money LaunderingLaw no. 589/18.07.2019;",
	"Data processed under your consent will be processed during the validity period of your consent or until you choose to withdraw your consent, or the data is no longer necessary;": "Data processed under your consent will be processed during the validity period of your consent or until you choose to withdraw your consent, or the data is no longer necessary;",
	"Data processed under our legitimate interest will be processed for a maximum period of 1 year, after which it will be anonymized and processed for statistical purposes.": "Data processed under our legitimate interest will be processed for a maximum period of 1 year, after which it will be anonymized and processed for statistical purposes.",
	"What are your rights as data subject?": "What are your rights as data subject?",
	"RIGHT OF ACCESS": "RIGHT OF ACCESS",
	"Upon your request, we will confirm that we process your personal data and, if so, we will provide you with a copy of your personal data that is subject to our processing and the following information:": "Upon your request, we will confirm that we process your personal data and, if so, we will provide you with a copy of your personal data that is subject to our processing and the following information:",
	"the purposes of the processing;": "the purposes of the processing;",
	"the categories of personal data concerned;": "the categories of personal data concerned;",
	"the recipients or categories of recipients to whom personal data has been or is to be disclosed, in particular recipients from third countries or international organizations;": "the recipients or categories of recipients to whom personal data has been or is to be disclosed, in particular recipients from third countries or international organizations;",
	"where possible, the period for which personal data are to be stored or, if that is not possible, the criteria used to determine that period;": "where possible, the period for which personal data are to be stored or, if that is not possible, the criteria used to determine that period;",
	"the existence of the right to require the operator to rectify or erase personal data or to restrict the processing of personal data relating to the data subject or the right to object to processing;": "the existence of the right to require the operator to rectify or erase personal data or to restrict the processing of personal data relating to the data subject or the right to object to processing;",
	"the right to lodge a complaint with a supervisory authority;": "the right to lodge a complaint with a supervisory authority;",
	"where personal data are not collected from the data subject, any available information on their source;": "where personal data are not collected from the data subject, any available information on their source;",
	"the existence of an automated decision-making process including the creation of profiles and, in those cases, relevant information on the logic used and the significance and expected consequences of such a processing for the data subject.": "the existence of an automated decision-making process including the creation of profiles and, in those cases, relevant information on the logic used and the significance and expected consequences of such a processing for the data subject.",
	"If we transfer your data outside of the European Economic Area or to an international organization you have the right to be informed of the appropriate safeguards applied.": "If we transfer your data outside of the European Economic Area or to an international organization you have the right to be informed of the appropriate safeguards applied.",
	"The first copy of your personal data is provided free of charge. For additional specimens, we may charge a reasonable additional charge, taking into account the related administrative costs.": "The first copy of your personal data is provided free of charge. For additional specimens, we may charge a reasonable additional charge, taking into account the related administrative costs.",
	"RIGHT TO RECTIFICATION AND/OR COMPLETION OF PERSONAL DATA": "RIGHT TO RECTIFICATION AND/OR COMPLETION OF PERSONAL DATA",
	"If the data we hold about you is inaccurate or incomplete, you are entitled to correct it or to complete. In order to do so, you can submit a request using the contact details provided above. Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your rectification or completion of your data. Upon your request, we will inform you of those recipients.": "If the data we hold about you is inaccurate or incomplete, you are entitled to correct it or to complete. In order to do so, you can submit a request using the contact details provided above. Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your rectification or completion of your data. Upon your request, we will inform you of those recipients.",
	"In order to keep personal data accurate, we may request you to reconfirm/renew your personal data from time to time.": "In order to keep personal data accurate, we may request you to reconfirm/renew your personal data from time to time.",
	"RIGHT TO ERASURE OF PERSONAL DATA (“RIGHT TO BE FORGOTTEN”)": "RIGHT TO ERASURE OF PERSONAL DATA (“RIGHT TO BE FORGOTTEN”)",
	"You may ask us to delete your personal information and we will respond to your request without undue delay, if one of the following situation applies:": "You may ask us to delete your personal information and we will respond to your request without undue delay, if one of the following situation applies:",
	"Data are no longer required for the purposes for which they were collected or processed;": "Data are no longer required for the purposes for which they were collected or processed;",
	"You withdraw consent to the processing of your data when your data processing is based on your consent and there is no other legal basis on which to process your personal data;": "You withdraw consent to the processing of your data when your data processing is based on your consent and there is no other legal basis on which to process your personal data;",
	"You oppose the processing of your data on our legitimate interest, including the creation of profiles based on this ground, or you oppose data processing for direct marketing purposes, including the creation of profiles for direct marketing purposes;": "You oppose the processing of your data on our legitimate interest, including the creation of profiles based on this ground, or you oppose data processing for direct marketing purposes, including the creation of profiles for direct marketing purposes;",
	"your data has been processed unlawfully;": "your data has been processed unlawfully;",
	"Personal data should be deleted to comply with a legal obligation under Union law or national law;": "Personal data should be deleted to comply with a legal obligation under Union law or national law;",
	"Personal data have been collected in connection with the provision of information services to children and the basis of processing is consent.": "Personal data have been collected in connection with the provision of information services to children and the basis of processing is consent.",
	"Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your deletion of your data. Upon your request, we will inform you of those recipients.": "Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your deletion of your data. Upon your request, we will inform you of those recipients.",
	"We reserve the right to refuse deletion your data when processing is required for:": "We reserve the right to refuse deletion your data when processing is required for:",
	"For the exercise of the right to free expression and information;": "For the exercise of the right to free expression and information;",
	"In order to comply with a legal obligation that applies to us as a personal data operator;": "In order to comply with a legal obligation that applies to us as a personal data operator;",
	"for purposes of archiving in the public interest, scientific or historical research or for statistical purposes, insofar as the deletion of the data is likely to render impossible or seriously impair the achievement of the objectives of the processing;": "for purposes of archiving in the public interest, scientific or historical research or for statistical purposes, insofar as the deletion of the data is likely to render impossible or seriously impair the achievement of the objectives of the processing;",
	"To establish, exercise or defend a right in court.": "To establish, exercise or defend a right in court.",
	"RIGHT TO RESTRICTION OF PROCESSING": "RIGHT TO RESTRICTION OF PROCESSING",
	"You may ask us to block and restrict the processing of your personal information if one of the situations below applies:": "You may ask us to block and restrict the processing of your personal information if one of the situations below applies:",
	"Contest the accuracy of the data – in this case, at your request, we will restrict the processing for the period we perform the necessary checks on the accuracy of your data;": "Contest the accuracy of the data – in this case, at your request, we will restrict the processing for the period we perform the necessary checks on the accuracy of your data;",
	"data processing is illegal, and you do not want to delete your data;": "data processing is illegal, and you do not want to delete your data;",
	"We no longer need your data for processing, but processed data about you is necessary to establish, exercise or defend a right in court;": "We no longer need your data for processing, but processed data about you is necessary to establish, exercise or defend a right in court;",
	"You opposed your processing of your data under our legitimate interest, including the creation of profiles based on this basis – in this case, at your request, we will restrict the processing for the period in which we verify that our legitimate rights do not prevail over your rights.": "You opposed your processing of your data under our legitimate interest, including the creation of profiles based on this basis – in this case, at your request, we will restrict the processing for the period in which we verify that our legitimate rights do not prevail over your rights.",
	"If your data processing has been restricted, we will only be able to store your data. Any other way of processing out of storage will be done only:": "If your data processing has been restricted, we will only be able to store your data. Any other way of processing out of storage will be done only:",
	"after obtaining your consent;": "after obtaining your consent;",
	"for finding, exercising or defending a right in court;": "for finding, exercising or defending a right in court;",
	"to protect the rights of another natural or legal person;": "to protect the rights of another natural or legal person;",
	"for reasons of public interest of the Union or of a Member State.": "for reasons of public interest of the Union or of a Member State.",
	"We will inform you before lifting any processing restriction as set out above.": "We will inform you before lifting any processing restriction as set out above.",
	"Unless this proves impossible or involves disproportionate efforts, we will communicate to each recipient to whom your data has been disclosed restricting the processing of such data. At your request, we will inform you of those recipients.": "Unless this proves impossible or involves disproportionate efforts, we will communicate to each recipient to whom your data has been disclosed restricting the processing of such data. At your request, we will inform you of those recipients.",
	"RIGHT TO DATA PORTABILITY": "RIGHT TO DATA PORTABILITY",
	"You have the right to receive the data that concerns you and that you have provided us with in order to transmit such data to another controller, in the following circumstances:": "You have the right to receive the data that concerns you and that you have provided us with in order to transmit such data to another controller, in the following circumstances:",
	"Your data processing is based on your consent or on a contract between us and you;": "Your data processing is based on your consent or on a contract between us and you;",
	"Your data is processed by automatic means.": "Your data is processed by automatic means.",
	"We will provide your personal data in a structured, commonly used and machine-readable format.": "We will provide your personal data in a structured, commonly used and machine-readable format.",
	"If technically feasible, you can request that your personal data be transmitted directly to the controller indicated by you.": "If technically feasible, you can request that your personal data be transmitted directly to the controller indicated by you.",
	"RIGHT TO OBJECT AND AUTOMATED INDIVIDUAL DECISION-MAKING": "RIGHT TO OBJECT AND AUTOMATED INDIVIDUAL DECISION-MAKING",
	"You may request us not to further process your personal information for reasons relating to your particular circumstances and if the processing of your data is based on our legitimate interest. We will cease processing of your data unless we demonstrate that we have legitimate and compelling reasons that justify processing and those reasons prevail over your interests, rights and freedoms, or whether the purpose of the processing is to establish, exercise or defend a right in court.": "You may request us not to further process your personal information for reasons relating to your particular circumstances and if the processing of your data is based on our legitimate interest. We will cease processing of your data unless we demonstrate that we have legitimate and compelling reasons that justify processing and those reasons prevail over your interests, rights and freedoms, or whether the purpose of the processing is to establish, exercise or defend a right in court.",
	"In addition to the above, you may request that we no longer process your personal data for direct marketing purposes, including the creation of profiles related to that direct marketing.": "In addition to the above, you may request that we no longer process your personal data for direct marketing purposes, including the creation of profiles related to that direct marketing.",
	"RIGHTS IN RELATION TO AUTOMATED INDIVIDUAL DECISION-MAKING, INCLUDING PROFILING": "RIGHTS IN RELATION TO AUTOMATED INDIVIDUAL DECISION-MAKING, INCLUDING PROFILING",
	"You have the right not to be subject to a decision when it is based on automatic processing, including not being profiled, if the automatic decision or profiling has legal effects or significantly affects you, except in the following cases:": "You have the right not to be subject to a decision when it is based on automatic processing, including not being profiled, if the automatic decision or profiling has legal effects or significantly affects you, except in the following cases:",
	"automatic decision is required to conclude or execute a contract between you and us;": "automatic decision is required to conclude or execute a contract between you and us;",
	"the automatic decision is authorized by European Union or national law applicable to PLAY ONLINE SOLUTIONS LTD and also provides for appropriate measures to protect the legitimate rights, freedoms and interests of the data subject;": "the automatic decision is authorized by European Union or national law applicable to PLAY ONLINE SOLUTIONS LTD and also provides for appropriate measures to protect the legitimate rights, freedoms and interests of the data subject;",
	"Automatic decision is based on your express consent.": "Automatic decision is based on your express consent.",
	"If the cases indicated in (1) and (3) above apply, you may request that you exercise the following correlative rights:": "If the cases indicated in (1) and (3) above apply, you may request that you exercise the following correlative rights:",
	"the right to obtain human intervention on our part;": "the right to obtain human intervention on our part;",
	"the right to express your point of view;": "the right to express your point of view;",
	"the right to challenge the automatic decision.": "the right to challenge the automatic decision.",
	"RIGHT TO WITHDRAW CONSENT": "RIGHT TO WITHDRAW CONSENT",
	"If we rely on your consent (or explicit consent) as the legal basis for processing your personal information, you have the right to withdraw your consent at any time and we will cease processing your personal data.": "If we rely on your consent (or explicit consent) as the legal basis for processing your personal information, you have the right to withdraw your consent at any time and we will cease processing your personal data.",
	"Withdrawal of consent does not affect the lawfulness of the processing of your personal data on the basis of your consent prior to its withdrawal.": "Withdrawal of consent does not affect the lawfulness of the processing of your personal data on the basis of your consent prior to its withdrawal.",
	"RIGHT TO LODGE A COMPLAINT WITH NATIONAL SUPERVISORY AUTHORITY FOR PERSONAL DATA PROCESSING OF ROMANIA (“ANSPDCP”)": "RIGHT TO LODGE A COMPLAINT WITH NATIONAL SUPERVISORY AUTHORITY FOR PERSONAL DATA PROCESSING OF ROMANIA (“ANSPDCP”)",
	"You have the right to contact the ANSPDCP if you believe the processing of your data is not in compliance with the applicable law. More information about ANSPDCP can be obtained by visiting http://www.dataprotection.ro/.": "You have the right to contact the ANSPDCP if you believe the processing of your data is not in compliance with the applicable law. More information about ANSPDCP can be obtained by visiting http://www.dataprotection.ro/.",
	"RIGHT TO SEEK JUDICIAL REMEDY": "RIGHT TO SEEK JUDICIAL REMEDY",
	"Without prejudice to any other administrative or non-judicial remedy, you have the right to pursue an effective judicial remedy against a legally binding decision of ANSPDCP.": "Without prejudice to any other administrative or non-judicial remedy, you have the right to pursue an effective judicial remedy against a legally binding decision of ANSPDCP.",
	"How can you exercise your rights?": "How can you exercise your rights?",
	"Submitting a request.": "Submitting a request.",
	"For the exercise of any rights above, please submit your request in writing or by phone, using the contact details indicated in section 3 above.": "For the exercise of any rights above, please submit your request in writing or by phone, using the contact details indicated in section 3 above.",
	"Identification of the applicant.": "Identification of the applicant.",
	"In order to be able to properly manage your request, we urge you to identify yourself as completely as possible. In case we have reasonable doubts as to the identity of the applicant, we will ask for further information to confirm your identity.": "In order to be able to properly manage your request, we urge you to identify yourself as completely as possible. In case we have reasonable doubts as to the identity of the applicant, we will ask for further information to confirm your identity.",
	"Response time.": "Response time.",
	"We will respond to your requests without undue delay, and in any case within one month from the receipt of the request. Insofar as your application is complex or we are in a position to process a large number of requests, we may reasonably postpone the delivery of your response for up to two months with your prior notice.": "We will respond to your requests without undue delay, and in any case within one month from the receipt of the request. Insofar as your application is complex or we are in a position to process a large number of requests, we may reasonably postpone the delivery of your response for up to two months with your prior notice.",
	"Providing our answer.": "Providing our answer.",
	"We will provide you with our response and any requested information in electronic format, unless you request them to be provided in another format.": "We will provide you with our response and any requested information in electronic format, unless you request them to be provided in another format.",
	"Refusal.": "Refusal.",
	"In so far as we refuse to meet your request, we will inform you of the reasons which led to such a decision and of the possibility to submit a complaint to ANSPDCP and to apply for a judicial remedy.": "In so far as we refuse to meet your request, we will inform you of the reasons which led to such a decision and of the possibility to submit a complaint to ANSPDCP and to apply for a judicial remedy.",
	"Taxes. Exercising your rights as a data subject is free. However, to the extent that your claims are manifestly unfounded or excessive, especially in the light of their repetitiveness, we reserve the right to levy a fee or to refuse the fulfilment of the request.": "Taxes. Exercising your rights as a data subject is free. However, to the extent that your claims are manifestly unfounded or excessive, especially in the light of their repetitiveness, we reserve the right to levy a fee or to refuse the fulfilment of the request.",
	"Cookies Policy": "Cookies Policy",
	"WHAT ARE COOKIES?": "WHAT ARE COOKIES?",
	"Cookies are small files of letters and numbers that are stored on your computer, mobile terminal, or other equipment that you use to access the internet. There are two main types of cookies:": "Cookies are small files of letters and numbers that are stored on your computer, mobile terminal, or other equipment that you use to access the internet. There are two main types of cookies:",
	"Session cookies – temporary cookies which allow website operators to link the actions of a visitor during a browser session. They are activated when the browser window is opened. Once you close the browser, all session cookies are deleted.": "Session cookies – temporary cookies which allow website operators to link the actions of a visitor during a browser session. They are activated when the browser window is opened. Once you close the browser, all session cookies are deleted.",
	"Persistent cookies – remain on a user’s device for a set period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.": "Persistent cookies – remain on a user’s device for a set period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.",
	"Cookies are installed through the request of our web-server to your browser (eg Internet Explorer, Chrome) and do not contain software, viruses or spyware, and cannot access information from your hard drive.": "Cookies are installed through the request of our web-server to your browser (eg Internet Explorer, Chrome) and do not contain software, viruses or spyware, and cannot access information from your hard drive.",
	"WHAT TYPES OF COOKIES DO WE USE AND FOR WHAT PURPOSES?": "WHAT TYPES OF COOKIES DO WE USE AND FOR WHAT PURPOSES?",
	"Strictly necessary cookies – are essential to navigate around our website and to use its features. These cookies do not gather personal information about you.": "Strictly necessary cookies – are essential to navigate around our website and to use its features. These cookies do not gather personal information about you.",
	"Performance cookies – collect data for statistical purposes on how visitors use our website. They do not contain personal information such as names and email addresses and are used to improve your experience on our website. Information supplied by performance cookies can help us understand how you use the website; for example, whether or not you have visited before, what you looked at or clicked on and how you found us. Such data will be used to further improve our services.": "Performance cookies – collect data for statistical purposes on how visitors use our website. They do not contain personal information such as names and email addresses and are used to improve your experience on our website. Information supplied by performance cookies can help us understand how you use the website; for example, whether or not you have visited before, what you looked at or clicked on and how you found us. Such data will be used to further improve our services.",
	"Analytics cookies – cookies generated by the Google Analytics software to account the activity of visitors, and the frequency of accessing the Site. We have taken care that the data transmitted by these cookies does not lead to your identification.": "Analytics cookies – cookies generated by the Google Analytics software to account the activity of visitors, and the frequency of accessing the Site. We have taken care that the data transmitted by these cookies does not lead to your identification.",
	"Purpose": "Purpose",
	"Expiry": "Expiry",
	"Analytics": "Analytics",
	"months": "months",
	"month": "month",
	"years": "years",
	"Functionality cookies – remember usernames, language preferences and regions, thus allowing user to customize how our website looks for them.": "Functionality cookies – remember usernames, language preferences and regions, thus allowing user to customize how our website looks for them.",
	"language": "language",
	"year": "year",
	"Advertising and targeting cookies – are used to deliver more relevant advertisements to you, but can also limit the number of times you see an advertisement, and be used to chart the effectiveness of an ad campaign by tracking users’ clicks.": "Advertising and targeting cookies – are used to deliver more relevant advertisements to you, but can also limit the number of times you see an advertisement, and be used to chart the effectiveness of an ad campaign by tracking users’ clicks.",
	"HOW CAN YOU REFUSE OR DEACTIVATE COOKIES?": "HOW CAN YOU REFUSE OR DEACTIVATE COOKIES?",
	"With the opening pop-up message, you can select which cookies you want to use, except for the strictly necessary cookies. Deactivating strictly necessary cookies will disable essential website services rendering it unusable.": "With the opening pop-up message, you can select which cookies you want to use, except for the strictly necessary cookies. Deactivating strictly necessary cookies will disable essential website services rendering it unusable.",
	"You can also disable cookies by changing your browser settings. For more information about this, please visit your browser’s settings page.": "You can also disable cookies by changing your browser settings. For more information about this, please visit your browser’s settings page.",
	"SOCIAL MEDIA TECHNOLOGIES": "SOCIAL MEDIA TECHNOLOGIES",
	"Facebook Like and Share plugins – allow visitors to Like the PLAY ONLINE SOLUTIONS LTD Facebook page or share PLAY ONLINE SOLUTIONS LTD websites with friends. If a person is connected to Facebook and visits the Site, the browser sends this information to Facebook in order for Facebook upload content on that page. Among the data that can be sent to Facebook are: the user ID, the site you visit, the date and time, and other browser-related information. If you are disconnected from your Facebook account less information shall be transferred to Facebook.": "Facebook Like and Share plugins – allow visitors to Like the PLAY ONLINE SOLUTIONS LTD Facebook page or share PLAY ONLINE SOLUTIONS LTD websites with friends. If a person is connected to Facebook and visits the Site, the browser sends this information to Facebook in order for Facebook upload content on that page. Among the data that can be sent to Facebook are: the user ID, the site you visit, the date and time, and other browser-related information. If you are disconnected from your Facebook account less information shall be transferred to Facebook.",
	"This personal information can be used wholly or in part by Facebook to improve Facebook products and services and to provide personalized ads. For more information you can consult Facebook privacy policy.": "This personal information can be used wholly or in part by Facebook to improve Facebook products and services and to provide personalized ads. For more information you can consult Facebook privacy policy.",
	"Google+ plugin – when you visit a site that has a Google Plug-in, your browser sends a series of information to Google, such as the URL of the page you’re visiting and your IP address. Google can access and read the cookies stored in the visitor’s device. Apps that use Google’s advertising services also share information with Google, such as the app’s name and a unique identifier for advertising.": "Google+ plugin – when you visit a site that has a Google Plug-in, your browser sends a series of information to Google, such as the URL of the page you’re visiting and your IP address. Google can access and read the cookies stored in the visitor’s device. Apps that use Google’s advertising services also share information with Google, such as the app’s name and a unique identifier for advertising.",
	"“Share with LinkedIn” plugin – when a person visits a site that has integrated such a plugin, LinkedIn receives the following visitor information: the URL of the aside from which it came and the one to which it is going. We also receive information about IP address, proxy server, operating system, web browser and add-ons, device ID and / or ISP and / or mobile phone identifier and features. If the site is accessed from a mobile device, the device will send us your location data according to the visitor’s phone settings.": "“Share with LinkedIn” plugin – when a person visits a site that has integrated such a plugin, LinkedIn receives the following visitor information: the URL of the aside from which it came and the one to which it is going. We also receive information about IP address, proxy server, operating system, web browser and add-ons, device ID and / or ISP and / or mobile phone identifier and features. If the site is accessed from a mobile device, the device will send us your location data according to the visitor’s phone settings.",
	"Tweet plugin – when a person visits a site that has integrated such a plugin, Tweeter can receive the following personal information about the visitor: Visited Web page, IP address, browser type, operating system, and cookie information. This information is collected to improve Tweeter products and services, including personalized customized ads and personalized ads.": "Tweet plugin – when a person visits a site that has integrated such a plugin, Tweeter can receive the following personal information about the visitor: Visited Web page, IP address, browser type, operating system, and cookie information. This information is collected to improve Tweeter products and services, including personalized customized ads and personalized ads.",
	"Ensuring personal data security": "Ensuring personal data security",
	"For ensuring safety of this website we use the SSL (Secure Socket Layer) method in connection with the website visit, in conjunction with the highest encryption level supported by your browser. In general, this is a 256-bit encryption.  Whether a single page of our website is encrypted is indicated by the closed representation of the key or lock icon in the status bar of your browser.": "For ensuring safety of this website we use the SSL (Secure Socket Layer) method in connection with the website visit, in conjunction with the highest encryption level supported by your browser. In general, this is a 256-bit encryption.  Whether a single page of our website is encrypted is indicated by the closed representation of the key or lock icon in the status bar of your browser.",
	"At organizational level, we have adopted and instructed our employees to follow internal procedures aimed at preventing loss or unauthorized access or disclosure. All persons, including our employees, processing your personal data under our authority have pledged to preserve the confidentiality and security of all such personal data.": "At organizational level, we have adopted and instructed our employees to follow internal procedures aimed at preventing loss or unauthorized access or disclosure. All persons, including our employees, processing your personal data under our authority have pledged to preserve the confidentiality and security of all such personal data.",
	"We have also implemented adequate security measures to protect your data against informatics treats and other potential data breaches. Such security measures shall be continuously improved in line with technological developments.": "We have also implemented adequate security measures to protect your data against informatics treats and other potential data breaches. Such security measures shall be continuously improved in line with technological developments.",
	"Final provisions": "Final provisions",
	"This Privacy Policy is applicable starting with 25th of May 2018.": "This Privacy Policy is applicable starting with 25th of May 2018.",
	"PLAY ONLINE SOLUTIONS LTD reserves the right to modify or amend this Privacy Policy at any time by publishing an updated version here.": "PLAY ONLINE SOLUTIONS LTD reserves the right to modify or amend this Privacy Policy at any time by publishing an updated version here.",
	"Game Rules": "Game Rules",
	"Functionality Description": "Functionality Description",
	"This page contains information about the rules of the games that are sorted in alphabetical order. If you have any questions or suggestions, please contact us": "This page contains information about the rules of the games that are sorted in alphabetical order. If you have any questions or suggestions, please contact us",
	"Slots": "Slots",
	"Payment Methods": "Payment Methods",
	"Cash in Location": "Can I cash-in in any Location?",
	"Walk into any casino location to deposit easily and quickly ONLY with your ID-card.": "Walk into any casino location to deposit easily and quickly ONLY with your ID-card.",
	"COMMISION": "COMMISION",
	"Free": "Free",
	"PROCESS TIME": "PROCESS TIME",
	"Instant": "Instant",
	"LIMITS": "LIMITS",
	"Min: 20 Lei": "Min: 20 Lei",
	"Max: 9.000 Lei": "Max: 9.000 Lei",
	"Visa / MasterCard Credit Card": "Visa / MasterCard Credit Card",
	"Online payment through credit and debit cards.": "Online payment through credit and debit cards.",
	"Taxes & Commisions": "Terms & Conditions",
	"Accordingly to ": "Accordingly to ",
	"EGO 114/2018, art. 53 ": "EGO 114/2018, art. 53 ",
	"and NGO Order 38 (National Gambling Office), ": "and NGO Order 38 (National Gambling Office), ",
	"an administration tax of 2 percent is applied to every deposit.": "an administration tax of 2 percent is applied to every deposit.",
	"EXAMPLE:": "EXAMPLE:",
	"A deposit of 100 Lei will credit the player’s wallet with 98 Lei, the other 2 Lei being directed to the State Budget.": "A deposit of 100 Lei will credit the player’s wallet with 98 Lei, the other 2 Lei being directed to the State Budget.",
	"Rounding of tax value is done by ": "Rounding of tax value is done by ",
	"rounding up (ceiling) to a multiple of 1 Leu for fractions equal or above 0.5 Lei and by rounding down (floor) for fractions up to 0.49 Lei, inclusive.": "rounding up (ceiling) to a multiple of 1 Leu for fractions equal or above 0.5 Lei and by rounding down (floor) for fractions up to 0.49 Lei, inclusive.",
	"Every withdrawal will be taxed accordingly to the following table:": "Every withdrawal will be taxed accordingly to the following table:",
	"WITHDRAWAL VALUE": "WITHDRAWAL VALUE",
	"TAX": "TAX",
	"up to": "up to",
	"Above": "Above",
	"Withdrawal": "Withdrawal",
	"Walk into any location to withdraw easily and quickly ONLY with your ID-card and your phone.": "Walk into any location to withdraw easily and quickly ONLY with your ID-card and your phone.",
	"0-4 days after approval": "0-4 days after approval",
	"Responsible Gaming": "Responsible Gaming",
	"We are committed to deliver the best time you can have in our casino. Placing stakes and winning is a lot of fun. But losing is also part of the gambling thrill. Many people believe that gamble gaming is high-reward activity with low risk; it cannot be further from the truth. While Jackpots do hit and huge winnings occur, remember that mathematically the odds favor the house. People come up with strategies and lucky totems that guarantee the wins, some games involve some element of skill, but slots, blackjack, roulette and sports betting are all games of chance and no one can influence the outcome. ": "We are committed to deliver the best time you can have in our casino. Placing stakes and winning is a lot of fun. But losing is also part of the gambling thrill. Many people believe that gamble gaming is high-reward activity with low risk; it cannot be further from the truth. While Jackpots do hit and huge winnings occur, remember that mathematically the odds favor the house. People come up with strategies and lucky totems that guarantee the wins, some games involve some element of skill, but slots, blackjack, roulette and sports betting are all games of chance and no one can influence the outcome. ",
	"We strongly encourage that you play only when you feel 100% confident in your decisions and only the budget that you can afford to lose. Never play money that you need for a healthy life, for your bills and your loving ones. If you ever feel the urge to keep playing to recoup your loss, then STOP! If you can’t tell or feel guilty to tell other people how much money you have gambled, please consider you have some gambling addiction.": "We strongly encourage that you play only when you feel 100% confident in your decisions and only the budget that you can afford to lose. Never play money that you need for a healthy life, for your bills and your loving ones. If you ever feel the urge to keep playing to recoup your loss, then STOP! If you can’t tell or feel guilty to tell other people how much money you have gambled, please consider you have some gambling addiction.",
	"To find out to which degree you are addicted to gambling, please take the anonymous test at": "To find out to which degree you are addicted to gambling, please take the anonymous test at",
	"If you think you have a gambling addiction or you think a family member or a friend has a gambling problem, consider getting qualified help from": "If you think you have a gambling addiction or you think a family member or a friend has a gambling problem, consider getting qualified help from",
	"Looking for immediate help? Call the free number": "Looking for immediate help? Call the free number",
	"Monday to Friday": "Monday to Friday",
	"and a specialist will answer and help you!": "and a specialist will answer and help you!",
	"Also, have a look at the following tips that may help you have a healthy, pleasing gaming.": "Also, have a look at the following tips that may help you have a healthy, pleasing gaming.",
	"Play for fun, not to make money.": "Play for fun, not to make money.",
	"Gaming is never a solution to any financial problems. Remember that the odds favor the house.": "Gaming is never a solution to any financial problems. Remember that the odds favor the house.",
	"Stick to a budget.": "Stick to a budget.",
	"Set a budget, stick to it and never play for more than you can safely afford. You should never play more than your disposable income; that is the remaining money after you deduct from your income the daily-living expenses, rent, food, your loving ones’ needs, bills, etc. Also, never play with other people’s money and never burrow money to gamble.": "Set a budget, stick to it and never play for more than you can safely afford. You should never play more than your disposable income; that is the remaining money after you deduct from your income the daily-living expenses, rent, food, your loving ones’ needs, bills, etc. Also, never play with other people’s money and never burrow money to gamble.",
	"Use limits.": "Use limits.",
	"Don’t get carried away in the heat of play and deposit more than you planned initially. Use the built-in tools to set deposit limits to maximize the fun and minimize your frustration. If you are connected, you can set up": "Don’t get carried away in the heat of play and deposit more than you planned initially. Use the built-in tools to set deposit limits to maximize the fun and minimize your frustration. If you are connected, you can set up",
	"Deposit Limits from here.": "Deposit Limits from here.",
	"Play when you are 100% clear headed.": "Play when you are 100% clear headed.",
	"Never play when you had alcohol or drugs because they will affect your decision making. Never play when you are sad or tired because you will not be able to think clearly.": "Never play when you had alcohol or drugs because they will affect your decision making. Never play when you are sad or tired because you will not be able to think clearly.",
	"Don’t raise the stakes to artificially create excitement.": "Don’t raise the stakes to artificially create excitement.",
	"If you get bored of gaming, raising stakes to artificially inflate the thrill is never a good idea; it will only lead to losing your funds faster. It’s much better to stand up and have a walk or meet some friends. The excitement will return when you place the original stake after a break.": "If you get bored of gaming, raising stakes to artificially inflate the thrill is never a good idea; it will only lead to losing your funds faster. It’s much better to stand up and have a walk or meet some friends. The excitement will return when you place the original stake after a break.",
	"Spend your time wisely!": "Spend your time wisely!",
	"Remember to get out in the real world and spend time and money with your friends and your loving ones as well. The key to happiness is a balanced life. way you can enjoy all the activities more.": "Remember to get out in the real world and spend time and money with your friends and your loving ones as well. The key to happiness is a balanced life. This way you can enjoy all the activities more.",
	"It is illegal for anyone under the age of 18 to gamble in our casino.": "It is illegal for anyone under the age of 18 to gamble in our casino.",
	"Terms & Conditions": "Terms & Conditions",
	"You have reached your deposit limit.": "You have reached your deposit limit.",
	"You can deposit minimum ${minVal} Lei and maximum ${maxVal} Lei": "You can deposit minimum ${minVal} Lei and maximum ${maxVal} Lei",
	"You can deposit minimum ${minVal} Lei": "You can deposit minimum ${minVal} Lei",
	"Deposit value": "Deposit value",
	"checkout": "checkout",
	"checkout (SafeCharge)": "checkout (SafeCharge)",
	"checkout (Viva Wallet)": "checkout (Viva Wallet)",
	"Deposit Successful!": "Deposit Successful!",
	"Time to sit back, play and relax. Good luck!": "Time to sit back, play and relax. Good luck!",
	"Everything is well! Your account has been credited with the amount of ": "Everything is well! Your account has been credited with the amount of ",
	"as 2% deposit tax": "as 2% deposit tax",
	". Time to sit back, play and relax. Good luck!": ". Time to sit back, play and relax. Good luck!",
	"Wallet & Bonus": "Wallet & Bonus",
	"Time to play": "Time to play",
	"Operation Failed": "Operation Failed",
	"An error has occured:": "An error has occured:",
	"(Error 202,1) This is an example of error description. The bank refused the transaction with this card. It may be expired or reported as stolen.": "(Error 202,1) This is an example of error description. The bank refused the transaction with this card. It may be expired or reported as stolen.",
	"Customer Service": "Customer Service",
	"I got it": "I got it",
	"In Review": "In Review",
	"For the safety of your account, this deposit requires review from our Risk & Payments department.": "For the safety of your account, this deposit requires review from our Risk & Payments department.",
	"Your funds are safe. You will be notified when they will be added to your account.": "Your funds are safe. You will be notified when they will be added to your account.",
	"My pending funds": "My pending funds",
	"Okay": "Okay",
	"Checkout": "Checkout",
	"About us": "Contact",
	"Cookies": "Cookies",
	"Support": "Support",
	"Have an issue?": "Have an issue?",
	"Customer support": "Open support",
	"Locations": "Locations",
	"Only for players over 18 years old": "Only for players over 18 years old",
	"Gambling may create addiction. Play Responsibly!": "Gambling may create addiction. Play Responsibly!",
	"This website is operated by ${companyName}, registered in ${countryName} under number ${companyRegNumber}, at ${companyAddress}. In Romania, ${companyName} owns license number ${license_number}, issued on ${license_date}, by the National Gambling Office (${openTagOnjn}http://onjn.gov.ro/${closeTagOnjn}), valid until ${license_expiration_date}. Please be informed that gambling might be prohibited in your area. It is your responsibility to obey the law. Gambling may be addictive and impact your financials. Please play responsibly!": "This website is operated by ${companyName}, registered in ${countryName} under number ${companyRegNumber}, at ${companyAddress}. In Romania, ${companyName} owns license number ${license_number}, issued on ${license_date}, by the National Gambling Office (${openTagOnjn}http://onjn.gov.ro/${closeTagOnjn}), valid until ${license_expiration_date}. Please be informed that gambling might be prohibited in your area. It is your responsibility to obey the law. Gambling may be addictive and impact your financials. Please play responsibly!",
	"Review our cookie policy": "Review our cookie policy",
	"What do we use cookies for?": "What do we use cookies for?",
	"By clicking \"I accept\" or \"X\" on this banner, or using our site, you consent to the use of cookies.": "By clicking \"I accept\" or \"X\" on this banner, or using our site, you consent to the use of cookies.",
	"I accept": "I accept",
	"Closest location": "Please select a location",
	"Maximum 4000 Lei": "Maximum 4000 Lei",
	"Search location": "Search location",
	"Go in any ${brand} Agency and you can deposit any sum with your ID. No headaches, no cards.": "Go in any ${brand} Agency and you can deposit any sum with your ID. No headaches, no cards.",
	"Just with": "Just with",
	"ID card": "ID card",
	"You decide. Anywhere. Anytime": "You decide. Anywhere. Anytime",
	"Deposit Online": "Deposit Online",
	"Map with locations": "Map with locations",
	"Withdraw value": "Withdraw value",
	"Minimum withdrawal: ${minVal} lei": "Minimum withdrawal: ${minVal} lei",
	"Maximum value is": "Maximum value is",
	"withdraw_available_funds_button_text": "Cash-in location",
	"cash-in online": "cash-in online",
	"Location": "Location",
	"Change": "Change",
	"Details": "Details",
	"Withdrawal amount": "Withdrawal amount",
	"Withdrawal amount that will be received": "Withdrawal amount that will be received",
	"Taxes due to the state": "Taxes due to the state",
	"Confirm withdrawal": "Confirm withdrawal",
	"Success!": "Success!",
	"Your request for withdrawal has been registered. Soon the Payments Department will review it for approval.": "Your request for withdrawal has been registered. Soon the Payments Department will review it for approval.",
	"You will be notified when the money is ready to pick up from the chosen agency": "You will be notified when the money is ready to pick up from the chosen agency",
	"Pending withdrawals": "Pending withdrawals",
	"Close": "Close",
	"Request Failed": "Request Failed",
	"We’ve encountered this error:": "We’ve encountered this error:",
	"Please retry later or - should the error keeps appearing - contact the Customer Support.": "Please retry later or - should the error keeps appearing - contact the Customer Support.",
	"Contact Support": "Contact Us",
	"Try Again": "Try Again",
	"Withdrawal Successful!": "Withdrawal Successful!",
	"Available Funds": "Available funds",
	"Taxes and Fees": "Taxes & Fees",
	"Attention: Account not verified": "Attention: Account not verified",
	"Accordingly to legal requirements, please verify your identity prior to withdrawing any money.": "Accordingly to legal requirements, please verify your identity prior to withdrawing any money.",
	"Your identity documents you provided are under review.": "Your identity documents you provided are under review.",
	"Verify": "Verify",
	"Attention: Active bonuses": "Attention: Active bonuses",
	"Blocked withdrawal": "Blocked withdrawal",
	"BONUS": "BONUS",
	"According to the Terms and Conditions, any withdrawal implies ${begin_red}forfeit${end_red} for bonus funds (${begin_red}-${bonus}${end_red}) and active bonuses.": "According to the Terms and Conditions, any withdrawal implies ${begin_red}forfeit${end_red} for bonus funds (${begin_red}-${bonus}${end_red}) and active bonuses.",
	"The forfeit is permanent.": "This loss is permanent.",
	"The bonuses cannot be reactivated, even by the subsequent cancellation of the withdrawal.": "The bonuses cannot be reactivated, even by the subsequent cancellation of the withdrawal.",
	"See bonuses": "See bonuses",
	"Withdraw": "Withdraw",
	"60 Minutes": "60 Minutes",
	"60 minutes spent on website from last notification": "60 minutes spent on website from last notification",
	"Welcome": "Welcome",
	"LIVE": "LIVE",
	"homepage_table_games_big_font": "Table",
	"homepage_table_games_small_font": "games",
	"RECEIVE 1000 LEI BONUS!": "RECEIVE 1000 LEI BONUS!",
	"FREE 50 LEI": "FREE 50 LEI",
	"Don't miss the hottest": "Don't miss the hottest",
	"Promotions": "Promotions",
	"You receive 50% more": "You receive 50% more",
	"More": "More",
	"All Jackpots": "All Jackpots",
	"Jackpots": "Jackpots",
	"Latest winners": "Latest winners",
	"Closing left menu!": "Closing left menu!",
	"Log in": "Log in",
	"Log In": "Log In",
	"E-mail or Phone Number": "E-mail or Phone Number",
	"Ready for cash-in": "Ready for cash-in",
	"Walk into the selected location with your ID": "Walk into the selected location with your ID",
	"Waiting for approval": "Waiting for approval",
	"Your requests will be reviewed shortly": "Your requests will be reviewed shortly",
	"Recent transactions": "Recent transactions",
	"Withdrawals history": "Withdrawals history",
	"No pending withdrawals": "No pending withdrawals",
	"Total Available Funds for Withdrawal": "Total Available Funds for Withdrawal",
	"START A WITHDRAWAL": "START A WITHDRAWAL",
	"See Transaction History": "See Transaction History",
	"Exceeds max amount allowed": "Exceeds max amount allowed",
	"Must enter some value": "Must enter some value",
	"Partial Deposit": "Partial deposit",
	"Deposit from withdrawal request": "Deposit and Withdrawals",
	"Available amount:": "Available amount:",
	"Cancel Withdrawal": "Cancel Withdrawal",
	"Are you sure that you want to cancel this withdrawal request?": "Are you sure that you want to cancel this withdrawal request?",
	"Amount to cancel:": "Amount to cancel:",
	"No": "No",
	"Yes": "Yes",
	"Calculating...": "Calculating...",
	".00 Lei": ".00 Lei",
	"View": "View",
	"Tax: ": "Tax: ",
	"ID Card": "ID Card",
	"Verified": "Verified",
	"Requires verification": "Requires verification",
	"Edit": "Edit",
	"Password": "Password",
	"Mobile number": "Mobile number",
	"Address": "Address",
	"Language": "Language",
	"Change Password": "Change Password",
	"STEP ": "STEP ",
	" OF 2": " OF 2",
	"Wrong password, try again!": "Wrong password, try again!",
	"Current Password": "Current passowrd",
	"Next": "Next",
	"Passwords don't match": "Passwords don't match",
	"Password changed": "Password changed",
	"New Password": "New password",
	"Confirm Password": "Confirm Password",
	"Finish": "Finish",
	"Change Phone Number": "Change Phone Number",
	"New phone number": "New phone number",
	"SMS Code must be more than 3 characters": "This field must have minimum 3 characters",
	"SMS Code": "SMS Code",
	"Validate code": "Validate code",
	"check number and resend code": "check number and resend code",
	"Upload document": "Upload document",
	"Please upload a picture of your id card.": "Please upload a picture of your id card.",
	"Utilities Bill": "Utilities Bill",
	"Please upload a picture of your utilities bill.": "Please upload a picture of your utilities bill.",
	"Bank Card": "Bank Card",
	"Please upload a picture of your bank card.": "Please upload a picture of your bank card.",
	"Passport": "Passport",
	"Please upload a picture of your passport.": "Please upload a picture of your passport.",
	"Use the camera": "Use the camera",
	"Select document from gallery": "Select document from gallery",
	"or": "or",
	"Max size: ": "Maximum file size:",
	"Please upload a picture of your ${title}.": "Please upload a photo of your ${title}.",
	"Average waiting time": "Average waiting time",
	"${number_of_minutes} minutes": "${number_of_minutes} minutes",
	"The document will be reviewed soon": "The document will be reviewed soon",
	"Upload a new photo": "Upload a new photo",
	"Estimated time left": "Estimated time left",
	"The document was rejected": "The document's photo has been rejected!",
	"File cannot be opened": "File cannot be opened",
	"Photo does not match the required document": "Photo does not match the required document",
	"Validity date has expired": "Validity date has expired",
	"Details are not visible": "Details are not visible",
	"Document cannot read": "Document cannot be read",
	"Document wrong image": "Photo does not match the required document",
	"Document should be fully visible in the photo": "Document should be fully visible in the photo",
	"Upload Success": "Upload Success",
	"Upload Failed": "Upload Failed",
	"Upload another document": "Upload another document",
	"You are all good!": "You are all good!",
	"Future document requests will appear here and we shall notify you.": "Future document requests will appear here and we shall notify you.",
	"My Cards": "My Cards",
	"documents_title": "Verify Your ID",
	"Verify my card": "Verify my card",
	"Documents": "Documents",
	"Preferences": "Preferences",
	"Auto-accept odds changes": "Auto-accept odds changes",
	"None, only higher, any change": "None, only higher, any change",
	"Enable all": "Enable all",
	"Receive news and promotions": "Receive news and promotions",
	"Personalized bonus and offers": "Personalized bonus and offers",
	"Enabled": "Enabled",
	"Contact channels": "Contact channels",
	"E-mail, Phone, SMS, Push Notification": "E-mail, Phone, SMS, Push Notification",
	"GDPR My personal data": "GDPR My personal data",
	"Daily": "Daily",
	"Weekly": "Weekly",
	"Monthly": "Monthly",
	"Consumed": "consumed",
	"Unlimited": "Unlimited",
	"Set the Limit to ": "Set the limit to ",
	"Remove Limit": "Remove Limit",
	"Your Limits": "Your Limits",
	"Current settings": "Current settings",
	"Wagered Amount": "Wager",
	"Loss": "Loss",
	"Limits": "Limits",
	"%s day": "%s day",
	"%s days": "%s days",
	"%s months": "%s months",
	"%s year": "%s year",
	"%s years": "%s years",
	"Your Identity verification period will expire after %s days.": "Your Identity verification period will expire after %s days.",
	"If you self-exclude, you will be unable to complete it before the deadline and, accordingly to the legal requirements, you account will be closed and the funds will be sent to the State Budget.": "If you self-exclude, you will be unable to complete it before the deadline and, accordingly to the legal requirements, you account will be closed and the funds will be sent to the State Budget.",
	"Your account and your funds are safe.": "Your account and your funds are safe.",
	"You can come back when the chosen period expires": "You can come back when the chosen period expires",
	"Take a Break": "Take a break",
	"Steer away from gaming for a few days": "Steer away from gaming for a few days",
	"For how long do you want to take a break?": "For how long do you want to take a break?",
	"Self-exclusion": "Self-exclusion",
	"Got a gambling addiction?": "Got a gambling addiction?",
	"For how long do you want to self-exclude?": "For how long do you want to self-exclude?",
	"More options": "More options",
	"Take a short break instead": "Take a short break instead",
	"Self-Exclusion": "Self-exclusion",
	"It is important for us to have fun when playing in our online casino. If you're worried about playing more than you can afford, then take a break for a short period of time. After the self-exclusion period expires, you will be able to return to play.": "It is important for us to have fun when playing in our online casino. If you're worried about playing more than you can afford, then take a break for a short period of time. After the self-exclusion period expires, you will be able to return to play.",
	"I agree to be restricted to my online casino access for the period specified above.": "I agree to be restricted to my online casino access for the period specified above.",
	"Lock account for ": "Lock account for ",
	"Confirm exclusion": "Confirm exclusion",
	"To complete your action, please confirm your password:": "To complete your action, please confirm your password:",
	"Confirm": "Confirm",
	"Restrict Access": "Restrict Access",
	"Auto - Exclusion": "Auto - Exclusion",
	"Duration": "Duration",
	"Lock the account for ": "Lock the account for",
	"Back": "Back",
	"other": "others",
	"active": "active",
	"inactive": "inactive",
	"expired": "Expired",
	"forfeited": "forfeited",
	"wagered": "wagered",
	"consumed": "consumed",
	"There is no bonus history at this moment": "There is no bonus history at this moment",
	"Download History for last 90 days": "Download history for last 90 days",
	"Pending": "Pending",
	"All": "All",
	"There is no transactions history at this moment": "There is no transactions history at this moment",
	"Unknown": "Unknown",
	"Pending Approval": "Pending withdrawals",
	"Ready For Cash-in": "Ready For Cash-in",
	"Collected": "Collected",
	"Cancelled": "Cancelled",
	"Tax": "Taxes",
	"Result": "Result",
	"without winning": "without winning",
	"There is no games history at this moment": "There is no games history at this moment",
	"IN & OUT": "IN & OUT",
	"transaction_history_bonus_title": "Bonus",
	"Game": "Game",
	"Transactions History": "Transaction history",
	"Remove bonus": "Remove bonus",
	"Are you sure you want to remove your bonus?": "Are you sure you want to remove your bonus?",
	"Day ago": "Day ago",
	"Days ago": "Days ago",
	"ago": "ago",
	"Day": "day",
	"Days": "Days",
	"There is no bonus active yet": "There is no bonus active yet",
	"Visit ": "Visit ",
	" to get one.": " to get one.",
	"Current Prize": "Current Prize",
	"Wager requirements": "Wager requirements",
	"Terms and Conditions": "Terms & Conditions",
	"Expired": "Expired",
	"There are no pending witdrawals": "There are no pending witdrawals",
	"Sold & Bonuses": "Wallet & Bonuses",
	"Total balance available for play": "Total balance available for play",
	"Cash": "Cash",
	"Your Bonuses": "Your Bonuses",
	"Current Status": "Current Status",
	"Wallet & Bonuses": "Wallet & Bonuses",
	"SEE ALL RULES, T&C": "SEE ALL RULES, T&C",
	"Deposit with this bonus": "Deposit with this bonus",
	"Account": "Account",
	"Verification": "Verification",
	"Address from Identity Card": "Address from Identity Card",
	"CNP must be 13 chars long": "CNP must be 13 chars long",
	"CNP must contain only numbers": "CNP must contain only digits",
	"Minimum age accepted for registration is 18 years": "Minimum age accepted for registration is 18 years",
	"Social Security Number is too short": "Passport Number is too short",
	"Inserted value is invalid": "Inserted value is invalid",
	"CNP": "CNP",
	"Social Security Number": "Passport Number",
	"Citizenship": "Citizenship",
	"Insert a valid email address": "Insert a valid email address",
	"Email invalid": "Invalid email",
	"First Name from Identity Card": "Your first name",
	"This field contains invalid characters.": "This field contains invalid characters.",
	"This field must have minimum 3 characters": "This field must have minimum 3 characters",
	"Inserted value is not valid": "Inserted value is not valid",
	"First Name": "First Name",
	"Last Name": "Last Name",
	"This field contains invalid characters": "This field contains invalid characters",
	"This field must have minimum 2 characters": "This field must have minimum 2 characters",
	"Inserted field is invalid": "Inserted value is invalid",
	"Change number?": "Change number?",
	"Save": "Save",
	"Resend SMS Code": "Resend SMS Code",
	"This phone number is already registered": "This phone number is already registered",
	"You can request a new SMS code in %s seconds": "You can request a new SMS code in %s seconds",
	"The passwords doesn't match": "The passwords doesn't match",
	"The password doesn't respect the minimum criteria": "The password doesn't respect the minimum criteria",
	"Password Requirements:": "Password Requirements:",
	"Include at least 1 letter": "Include at least 1 letter",
	"Have at least 6 characters in length": "Have at least 6 characters in length",
	"Include at least 1 number": "Include at least 1 number",
	"Security Level:": "Security Level:",
	"Low": "Low",
	"Medium": "Medium",
	"Maximum": "Maximum",
	"Very Low": "Very Low",
	"Insert your phone number": "Insert your phone number",
	"Phone number invalid": "Phone number is invalid",
	"Phone": "Phone",
	"Reference Code": "Reference Code",
	"Your Deposit Bonus": "Your Deposit Bonus",
	"You are moments away from collecting your bonus and immediately start playing at the": "You are moments away from collecting your bonus and immediately start playing at the",
	"and": "and",
	"LIVE Casino": "LIVE Casino",
	"See Terms & Conditions": "See Terms & Conditions",
	"PLAY": "PLAY",
	"SAVE Bonus for Later": "SAVE Bonus for Later",
	"Your BONUS will remain available for 30 days": "Your BONUS will remain available for 30 days",
	"DEPOSIT": "DEPOSIT",
	"CLAIM Bonus Now": "CLAIM Bonus Now",
	"Something went wrong": "Something went wrong",
	"Insert the verification code received via SMS": "Insert the verification code received via SMS",
	"This field is mandatory": "This field is mandatory",
	"I am older than 18 years": "I am older than 18 years",
	"I want to receive bonuses and marketing materials that are relevant to me.": "I want to receive bonuses and marketing materials that are relevant to me.",
	"I read and I agree with ${linkStartTC}T&C${linkEnd} and ${linkStartGDPR}Privacy Policy${linkEnd}.": "I read and I agree with ${linkStartTC}T&C${linkEnd} and ${linkStartGDPR}Privacy Policy${linkEnd}.",
	"You can resend a SMS Code in": "You can resend a SMS Code in",
	"seconds": " seconds",
	"Click here to resend SMS Code": "Click here to resend SMS Code",
	"Register": "Register",
	"Play": "Play",
	"Save bonus for later": "Save bonus for later",
	"get BONUS now!": "get BONUS now!",
	"Account Verification": "Verification",
	"Email verified!": "Email validated",
	"Congratulations! Your account is now more secure.": "Congratulations! Your account is now more secure.",
	"Good luck and have fun!": "Good luck and have fun!",
	"Go to homepage": "Go to homepage",
	"The email couldn't be verified": "The email couldn't be verified",
	"Email Validation": "Email Validation",
	"Reset Password": "Reset Password",
	"Type in the field the email address associated with the account": "Type in the field the email address associated with the account",
	"You will receive a link to the email associated with the account. You can reset your password from it.": "You will receive a link to the email associated with the account. You can reset your password from it.",
	"We sent you an email with the link to change password": "We sent you an email with the link to change password",
	"Verification Code": "Verification Code",
	"Send email": "Send email",
	"Type in the field the verification code sent to you through email": "Type in the field the verification code sent to you through email",
	"After identity verification you can choose a new password. Please, check your email.": "After identity verification you can choose a new password. Please, check your email.",
	"Welcome Bonus": "Welcome Bonus",
	"Pending funds": "Pending funds",
	"LEARN MORE": "LEARN MORE",
	"Profile details": "Profile details",
	"Sold": "Sold",
	"GET BONUS": "GET BONUS",
	"Missing Page Card": "Missing Page Card",
	"This page is missing from the system!": "This page is missing from the system!",
	"days": "days",
	"day": "day",
	"sec": "sec",
	"Loading": "Loading...",
	"Partial cancellation": "Partial cancellation",
	"Full cancellation": "Full cancellation",
	"Pending approval": "Pending approval",
	"Processed": "Processed",
	"This account is closed. Please contact support.": "This account is closed. Please contact support.",
	"Your new limit is now set to ${value} RON": "Your new limit is now set to ${value} RON",
	"New Limit of ${value} will be activated over 48H": "New Limit of ${value} will be activated over 48H",
	"Your limit already is ${value} RON": "Your limit already is ${value} RON",
	"Your limit will be removed over 48H": "Your limit will be removed over 48H",
	"Visit the section Account ${pathSymbol} Wallet & Bonus ${pathSymbol} Withdraw or directly from the “Locations” section. Your request will be processed as soon as possible.": "Visit the section Account ${pathSymbol} Wallet & Bonus ${pathSymbol} Withdraw or directly from the “Locations” section. Your request will be processed as soon as possible.",
	"Visit the section Account ${pathSymbol} Withdrawals. For every withdrawal there is an option to cancel funds partially or completely. This will not affect the approval time.": "Visit the section Account ${pathSymbol} Withdrawals. For every withdrawal there is an option to cancel funds partially or completely. This will not affect the approval time.",
	"The subject should have minimum 5 characters": "The subject should have minimum 5 characters",
	"The body should have minimum 30 characters": "The body should have minimum 30 characters",
	"Withdrawals": "Withdrawals",
	"Transaction History": "Transaction history",
	"My Locations": "My Locations",
	"Take a break": "Take a break",
	"Log Out": "Log Out",
	"Table Games": "Table Games",
	"Don't miss the greatest": "Don't miss the greatest",
	"PROMOTIONS": "PROMOTIONS",
	"slots_lobby_title_ticker": "Casino",
	"slots_lobby_subtitle_ticker": "Latest wins",
	"Welcome to Elite! We know that a high stake generates high earnings. For you to enjoy your betting without any restraints, ${boldStart}we give you a BONUS, for each deposit, up to a total limit of 1,000 Lei.${boldEnd} Make a deposit now, we give you a prize and you can start playing, right away!": "Welcome to Elite! We know that a high stake generates high earnings. For you to enjoy your betting without any restraints, ${boldStart}we give you a BONUS, for each deposit, up to a total limit of 1,000 Lei.${boldEnd} Make a deposit now, we give you a prize and you can start playing, right away!",
	"First deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}500 lei BONUS.${boldEnd}": "First deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}500 lei BONUS.${boldEnd}",
	"Second deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}200 Lei BONUS.${boldEnd}": "Second deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}200 Lei BONUS.${boldEnd}",
	"Third deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}300 Lei BONUS.${boldEnd}": "Third deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}300 Lei BONUS.${boldEnd}",
	"We use cookies and similar technologies to ${boldStart}recognize your repeat visits and preferences${boldEnd}, as well as to be able to ${boldStart}offer the service authentication and communication${boldEnd}.": "We use cookies and similar technologies to ${boldStart}recognize your repeat visits and preferences${boldEnd}, as well as to be able to ${boldStart}offer the service authentication and communication${boldEnd}.",
	"To learn more about cookies and the data we store, view our ${boldStart}Privacy Policy${boldEnd}.": "To learn more about cookies and the data we store, view our ${boldStart}Privacy Policy${boldEnd}.",
	"Invalid code.": "Invalid code.",
	"What will you play?": "What will you play?",
	"Forgot password?": "Forgot password?",
	"No account yet?": "No account yet?",
	"If you want to post a complaint, please click": "If you want to post a complaint, please click",
	"If we are unable to solve your complaint within 14 days, you will have the right to contact the National Gambling Office (ONJN).": "If we are unable to solve your complaint within 14 days, you will have the right to contact the National Gambling Office (ONJN).",
	"Account Details": "Account details",
	"Top Rated": "Top",
	"Roulette & Cards": "Roulette & Cards",
	"wins": "wins",
	"This category is empty!": "This category is empty!",
	"The application has encountered an unknown error.": "The application has encountered an unknown error.",
	"This player is already registered.": "This player is already registered.",
	"Invalid phone number.": "Invalid phone number.",
	"Invalid email address.": "Invalid email address.",
	"The minimum age requirement is not met.": "The minimum age requirement is not met.",
	"The cnp is not valid.": "The cnp is not valid.",
	"Email is already registered": "Email is already registered",
	"Phone number is already registered.": "This phone number is already registered",
	"The application has encountered a saving error.": "The application has encountered a saving error.",
	"Inserted code is invalid.": "Inserted value is invalid",
	"Current password is invalid.": "Current password is invalid.",
	"Authentication expired! Please login and try again.": "Authentication expired! Please login and try again.",
	"Invalid token.": "Invalid token.",
	"Phone number SMS limit was reached.": "Phone number SMS limit was reached.",
	"Insufficient funds.": "Insufficient funds.",
	"Invalid request.": "Invalid request.",
	"Duplicate request.": "Duplicate request.",
	"Negative amount.": "Negative amount.",
	"Cannot withdraw while having active bonuses.": "Cannot withdraw while having active bonuses.",
	"This email address is not registered": "This email address is not registered",
	"This account is suspended. Please contact support.": "This account is suspended. Please contact support.",
	"This account is self excluded.": "This account is self excluded.",
	"Decide who goes home with the trophy": "Decide who goes home with the trophy",
	"Not Available": "Not Available",
	"FREE BONUS 50 Lei": "FREE BONUS 50 Lei",
	"How much do you want to take with you?": "For how long do you want to take a break?",
	"Mega Jackpots. Special prizes. Competitions.": "Mega Jackpots. Special prizes. Competitions.",
	"TAKE BONUS 1.000 LEI": "TAKE BONUS 1.000 LEI",
	"search \"final\" or \"goluri\"": "search \"final\" or \"goluri\"",
	"Recommended": "Recommended",
	"Total Odds": "Total Odds",
	"Stake": "Stake",
	"Winning": "Winning",
	"Unavailable event - Remove": "Unavailable event - Remove",
	"Odds changed - Click to accept the changes": "Odds changed - Click to accept the changes",
	"Delete the bet?": "Delete the bet?",
	"All the bets from the ticket will be deleted": "All the bets from the ticket will be deleted",
	"Are you sure?": "Are you sure?",
	"NO": "NO",
	"YES": "YES",
	"Toate pariurile de pe bilet vor fi sterse": "Toate pariurile de pe bilet vor fi sterse",
	"DA": "DA",
	"Your ticket will be placed in a few moments. Please wait.": "Your ticket will be placed in a few moments. Please wait.",
	"The ticket was placed successfully and you can find it in the \"My Tickets\" section.": "The ticket was placed successfully and you can find it in the \"My Tickets\" section.",
	"The ticket was placed successfully.": "The ticket was placed successfully.",
	"ONLINE TICKET": "ONLINE TICKET",
	"TICKET IN SHOP": "TICKET IN SHOP",
	"LIVE and Pre-match events cannot be mixed on the same betslip.": "LIVE and Pre-match events cannot be mixed on the same betslip.",
	"All days": "All days",
	"By League": "By League",
	"By Hour": "By Hour",
	"PLAY NOW": "PLAY NOW",
	"BLOCKED": "BLOCKED",
	"EVENT BETS LOCKED": "EVENT BETS LOCKED",
	"Show more": "Show more",
	"Tickets": "Tickets",
	"Scan": "Scan",
	"Total odds": "Total odds",
	"Potential Winning": "Potential Winning",
	"Ticket Details": "Ticket Details",
	"Opened": "Opened",
	"Settled": "Settled",
	"bets": "bets",
	"Home": "Home",
	"Pre-Match": "Pre-Match",
	"Highlights": "Highlights",
	"Favorite": "Favorite",
	"My Filter": "My Filter",
	"Top League": "Top League",
	"navigare": "navigare",
	"Live": "Live",
	"PRE - MATCH": "PRE - MATCH",
	"Calendar": "Calendar",
	"Avantaj": "Avantaj",
	"Winner+": "Winner+",
	"Goals": "Goals",
	"Own Goals": "Own Goals",
	"Goals by Head": "Goals by Head",
	"Goals by Penalty": "Goals by Penalty",
	"Yellow Cards": "Yellow Cards",
	"Red Cards": "Red Cards",
	"Yellow/Red Cards": "Yellow/Red Cards",
	"Shots On Target": "Shots On Target",
	"Shots Off Target": "Shots Off Target",
	"Assists": "Assists",
	"Points": "Points",
	"Top Scorers": "Top Scorers",
	"Top Penalty Cards": "Top Penalty Cards",
	"Top Shots": "Top Shots",
	"Top Other Details": "Top Other Details",
	"Information is not available right now": "Information is not available right now",
	"Matches day": "Matches day",
	"Offer": "Offer",
	"Ranking": "Ranking",
	"Player Stats": "Player Stats",
	"Results": "Results",
	"Important Players": "Important Players",
	"Odds": "Odds",
	"H2H": "H2H",
	"Teams": "Teams",
	"The match doesn't exists": "The match doesn't exists",
	"[Today]": "[Today]",
	"[Tomorrow]": "[Tomorrow]",
	"[Yesterday]": "[Yesterday]",
	"There are no tickets": "There are no tickets",
	"There are no opened tickets": "There are no opened tickets",
	"There are no reserved tickets": "There are no reserved tickets",
	"There are no settled tickets": "There are no settled tickets",
	"Show More": "Show More",
	"There are no Winner Advantage matches": "There are no Winner Advantage matches",
	"There are no Winner Plus matches": "There are no Winner Plus matches",
	"withdraw here": "withdraw here",
	"Just with ID card": "Just with ID card",
	"NEXT": "NEXT",
	"Ooops!": "Ooops!",
	"Page not found.": "Page not found.",
	"Back to home page": "Back to home page",
	"Verify your account and identity before you can self-exclude": "Verify your account and identity before you can self-exclude",
	"We sent you an email with the next steps required to activate your self-exclusion.": "We sent you an email with the next steps required to activate your self-exclusion.",
	"By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}Elite Slots${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.": "By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}Elite Slots${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.",
	"To complete the process, ${customBoldStart}please confirm your identity below. The exclusion begins immediately after.${customBoldEnd}": "To complete the process, ${customBoldStart}please confirm your identity below. The exclusion begins immediately after.${customBoldEnd}",
	"Exclusion ends:": "Exclusion ends:",
	"Confirm password": "Confirm Password",
	"Confirm email code": "Confirm email code",
	"Confirm SMS Security Code": "Confirm SMS Security Code",
	"Resend SMS code": "Resend SMS Code",
	"I agree to be restricted to my online casino account for the period specified above.": "I agree to be restricted to my online casino account for the period specified above.",
	"Self-exclude now!": "Self-exclude now!",
	"By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}${brandFull}${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.": "By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}${brandFull}${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.",
	"Inform yourself to full extent about this decision. You will not be able to revert it.": "Inform yourself to full extent about this decision. You will not be able to revert it.",
	"! CANNOT BE REVERTED !": "! CANNOT BE REVERTED !",
	"! You will be excluded from all Play Online Network casinos !": "! You will be excluded from all Play Online Network casinos !",
	"! You will not regain access to your account, without exceptions !": "! You will not regain access to your account, without exceptions !",
	"Identity Confirmation": "Identity Confirmation",
	"Confirm self-exclusion": "Confirm self-exclusion",
	"Sports": "Sports",
	"Ticket verification in progress": "Ticket verification in progress",
	"Ticket Info": "Ticket Info",
	"Verification failed": "Verification failed",
	"We're checking the ticket status. Please wait.": "We're checking the ticket status. Please wait.",
	"SIMPLE": "SIMPLE",
	"SYSTEM": "SYSTEM",
	"Winnings": "Winnings",
	"Payout": "Payout",
	"Cashbacked": "Cashbacked",
	"Ticket Code": "Ticket Code",
	"Match": "Match",
	"Date/Hour": "Date/Hour",
	"Bet": "Bet",
	"Outcome": "Outcome",
	"Odd": "Odd",
	"Bets": "Bets",
	"Default Layout": "Default Layout",
	"UK Layout": "UK Layout",
	"Event View": "Event View",
	"Search in Sports Betting": "Search in Sports Betting",
	"Pariuri": "Betting",
	"Cashback": "Cashback",
	"Start": "Start",
	"Today": "Today",
	"Tomorrow": "Tomorrow",
	"Yesterday": "Yesterday",
	"See details": "See details",
	"Next ball color": "Next ball color",
	"Next ball even or odd": "Next ball even or odd",
	"Even": "Even",
	"My tickets": "My tickets",
	"virtual games": "virtual games",
	"Placing the ticket in progress...": "Placing the ticket in progress...",
	"Match details": "Match details",
	"Waiting for events...": "Waiting for events...",
	"Search bet": "Search bet",
	"Placing failed": "Placing failed",
	"An error occurred": "An error occurred",
	"If the problem persists, please contact the Customer Support departement": "If the problem persists, please contact the Customer Support departement",
	"Live tickets can only be played online": "Live tickets can only be played online",
	"There are no top leagues": "There are no top leagues",
	"Most played": "Most played",
	"By Kick-off": "By Kick-off",
	"By league": "By league",
	"My Filtru": "My Filtru",
	"Event bets locked": "Event bets locked",
	"All Championships": "All Championships",
	"You haven't selected any bets": "You haven't selected any bets",
	"Balance": "Balance",
	"Choose Stake": "Choose Stake",
	"Min. Winning": "Min. Winning",
	"Place ticket online": "Place ticket online",
	"Automatically accept odds changed": "Automatically accept odds changed",
	"Online ticket": "Online ticket",
	"Ticket in shop": "Ticket in shop",
	"Enter now": "Enter now",
	"The tournament begins": "The tournament begins",
	"High Odds matches": "High Odds matches",
	"Pull and come into play": "Pull and come into play",
	"Cazino": "Cazino",
	"Use old site": "Use old site",
	"CDA 2% (GEO 114)": "CDA 2% (GEO 114)",
	"Net deposit": "Net deposit",
	"Attention: Withholding taxes for withdrawals": "Attention: Withholding taxes for withdrawals",
	"State tax": "State tax",
	"Tax and fees information": "Tax and fees information",
	"Withdraw later": "Withdraw later",
	"If you did not receive the SMS code, you can request another one in ": "If you did not receive the SMS code, you can request another one in ",
	"seconds.": "seconds.",
	"Pay the fee and continue": "Pay the fee and continue",
	"See all": "See all",
	"Collection Unavailable": "Collection Unavailable",
	"The game collection you are looking for is temporarily unavailable. Please try again later.": "The game collection you are looking for is temporarily unavailable. Please try again later.",
	"BACK TO THE CASINO": "BACK TO THE CASINO",
	"Product Balance": "Product Balance",
	"Account Balance": "Account Balance",
	"Available": "Available",
	"Reserved": "Reserved",
	"Bonus": "Bonus",
	"Deposit": "Deposit",
	"Balance per Product": "Balance per Product",
	"Sportsbook": "Sportsbook",
	"Casino": "Casino",
	"Casino LIVE": "Casino LIVE",
	"Virtuals": "Virtuals",
	"Lotto": "Lotto",
	"No bonus": "No bonus",
	"Coming Soon": "Coming Soon",
	"Pending Transactions": "Pending Transactions",
	"Withdrawls": "Withdrawls",
	"Deposits": "Deposits",
	"Time left:": "Time left:",
	"Deposit without bonus": "Deposit without bonus",
	"Change bonus": "Change bonus",
	"Bonus requirements:": "Bonus requirements:",
	"Minimum deposit": "Minimum deposit",
	"I want to receive a SMS code for login.": "I want to receive a SMS code for login.",
	"Show verification code": "Show verification code",
	"Verification code (expires in ": "Verification code (expires in ",

	// lotto
	"luckyBetWarning1": "Minimum 3 numbers to use <1>Lucky Bet/Free Bet</1>",
	"luckyBetWarning2": "You need a simple ticket for <1>Lucky Bet/Free Bet</1>",
	"luckyNumberWarning1": "Minimum 3 numbers to unlock <1>Lucky Number</1>",
	"luckyNumberWarning2": "You need a simple ticket for <1>Lucky Number</1>",

	"New card": "New card",
	"Payment Details": "Payment Details",
	"Proceeding will imply the acceptance of secure storing of your card data.": "Proceeding will imply the acceptance of secure storing of your card data.",
	"CARD DETAILS": "CARD DETAILS",
	"Name as shown of the card": "Name as shown of the card",
	"Card number": "Card number",
	"MM/YY": "MM/YY",
	"Deposited amount": "Deposited amount",
	"Deposit Bonus": "Deposit Bonus",
	"DEPOSIT": "DEPOSIT",
	"Simple and convenient. Complete your transaction just with the CVV.": "Simple and convenient. Complete your transaction just with the CVV.",
	"CVV CODE": "CVV CODE",
	"The 3 or 4 digit code on the back of the card.": "The 3 or 4 digit code on the back of the card.",

	// Winter Fun
	"wf.day": "Day",
	"wf.days": "days",

	"TELL ME MORE ABOUT THE BENEFITS 2": "TELL ME MORE ABOUT THE BENEFITS?",

	"CLAIM LATER 2": "CLAIM LATER",
	"Funds hidden": "Funds hidden",

	"excelbet_slots_menu": "Slots",
	"Excelbet_slots_menu": "Slots",

	"cntLabel": "Account",
	"MORE_PROMOTIONS": "More",

	"Preferinte de marketing": "Marketing Preferences",
	"Vreau promotii": "I want promotions",
	"Te putem contacta pentru a-ti oferi promotii si bonusuri": "We can contact you to offer promotions and bonuses",

	"wheelKeysStatus1": " <0>Play on {{brand}}</0> your preferred product.",
	"wheelKeysStatus2": " Earn <1>EXTRA PROGRESS</1> for playing Sportsbook tickets.",
	"wheelKeysStatus3": " <1>Only real money play</1> is considered within the usual rules (no roulette, min odds 1.5, etc).",
	"wheelKeysStatus4": " <0>Unlock the win multiplier</0> by spending a few keys every {{brand}} Wheel spin.",
	"wheelKeysStatus5": " <0>Play and bet your way at {{brand}}.</0>.",
	"wheelKeysStatus6": " <1>Spend keys</1> to activate the Multiplier Wheel.",
	"wheelKeysStatus7": " <1>Every three spins with multipliers</1> activated, unlock one of the puzzle special prize.",
	"wheelKeysStatus8": " <1>The puzzle prize is one of</1>: increased multiplier values, spin and win twice or using a wildcard to try again for another prize. The puzzle prize is applied automatically to the spin that triggered it.",
	"luckyBetBonusWarning": " <1>Lucky Bet</1> is available only for the real-money tickets.",

	"Tranzactie securizata": "Secure transaction",
	"Deschidere in tab nou": "Open in a new tab",
	"Pagina pentru finalizarea depunerii cu ${boldStart}PaysafeCard se va deschide intr-un tab nou${boldEnd}.": "The deposit page with ${boldStart}PaysafeCard will open in a new tab${boldEnd}.",

	// register-v2
	"Nu vreau să ratez niciun bonus": "I don't wanna miss out on any bonus",
	"Mai puțin": "Show less",
	"Mai mult": "Show more",
	"Vei primi un SMS pentru verificare": "You will receive an SMS verification code",
	"Alege o parolă": "Set a password",
	"Proces rapid de verificare": "Quick verification process",
	"Citire Document": "Reading Document",
	"Se verifică documentul...": "The document is being processed",
	"Documentul a fost citit cu success": "The document has been uploaded successfully",
	"EROARE DOCUMENT": "Document error",
	"Documentul este neclar sau are informații acoperite. Încearcă cu o altă poză": "The document is unclear or has information covered. Try with another picture.",
	"Sau introduci manual": "Or manually insert",
	"Și esti gata să activezi bonusul": "And you are ready to activate the bonus",
	"Securizare cont": "Account Security",
	"Vreau să fiu informat despre promoții și bonusuri": "I want to be informed about the promotions and bonuses",
	"Scan_2": "Take Picture",

	// PO-5976 - Retail Tickets
	"agreeRetailTicketsTerms": "I agree to the <1>terms</1> of payment of the ticket",

	// PO-6682
	"dCancel": "Cancel",

	// ticket of the day
	"seeAllTicket": "See all (+{{COUNT}} bets)",

	"Bet Pending": "Pending",

	"momentumInfo1": "If you want to know the complete details about this bonus, please read the General Terms and Conditions section below carefully, or access this",
	"momentumInfo2": "{{BONUS_CASH}} is a bonus granted in the form of cash, usable on any section of the platform. Bonus cash funds can be converted into real money (available for withdrawal) only after meeting the wagering requirements, communicated within the specific conditions of the campaign.",
	"momentumInfo3": "General wagering conditions for Bonuses offered in the CASINO section: • The wagering factor applied to the bonus value in money is between 0.01X and 100X. • The wagering factor applied to the net winnings generated by Free Spins is between 1X and 100X. • The games eligible for using Free Spins, the wagering of the cash bonus, or the net winnings generated by Free Spins, are specified in the description of each bonus campaign, along with other special conditions in this regard. • Some games may have different wagering requirements, so the value contributing to the bonus wagering can range between 1% and 100%, of the bet value placed. Specific wagering conditions will be communicated in advance to players on the platform.",
	"momentumInfo4": "For more details, please access the <1>terms and conditions</1> of the campaign.",

	//Popup Webswitch translations
	"POPUP_TXT_1": "THE <0>NEW</0> <1>WINNER</1>",
	"POPUP_TXT_2": "Participate in activities with prizes and bonuses in the <0>New Rewards Center.</0>",
	"POPUP_TXT_3": "Try the <0>New Smart Search!</0>",

	//Banner Webswitch translations
	"BANNER_TXT_1": "NEW WINNER <0>NEW</0>",

	//Layout Messages translations
	"MSG_TXT_1": "<0>DISCOVER</0> <1>THE NEW WINNER</1>"

};