import React from 'react';
import { useTranslation } from "react-i18next";
import Icons from '../../../../assets/Icons/Icons';
import Slider from "react-slick";
import Moment from "react-moment";
import './CardList.scss';

const slick_settings = {
    className: "CardListContainer variable-width",
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    variableWidth: true
};

const CardList = (props) => {
    const { t } = useTranslation();
  
    const getFlagIcon = (id) => {
        switch (id) {
            case "0": return Icons.get("ccVisa", 'CardFlag');
            case "1": return Icons.get("ccMasterCard", 'CardFlag');
            case "6": return Icons.get("ccMaestro", 'CardFlag');
            default: return Icons.get("ccNotAvailable", 'CardFlag');
        }
    }

    let cardList = null;
    if(props.cardData){
        cardList = props.cardData.map(card => {

            if(card.token_active === 1){
                return (
                    <div key={card.id} className={"CardInfo" + (props.selectedCard === card.card_token ? ' Selected' : '')} onClick={props.disabled ? null : () => props.setCard(card.card_token)}>
                        <p className="CardNo">
                            {card.card_number ? card.card_number.substring(0, 4) : '0000'}
                            <span className="HiddenNo">**** ****</span>
                            {card.card_number ? card.card_number.substring(card.card_number.length - 4, card.card_number.length) : '0000'}
                        </p>
                        <div className="CardRow">
                            <span className="ExpirationDate">
                                Exp.&nbsp;
                                {card.expiration_date 
                                    ? <Moment format={"MM/YY"} unix>{card.expiration_date}</Moment>
                                    : '--/--' }
                            </span>
                            {getFlagIcon(card.card_type_id)}
                        </div>
                    </div>
                );
            }else{
                return;
            }
        });
    }
        
    if(cardList) {
        return (
            <Slider {...slick_settings} >
                {!props.noAddCard  && <div key={'AddCard'} className={"CardInfo NewCard" + (props.selectedCard === null ? ' Selected' : '')} onClick={() => props.setCard(null)}>
                    {props.selectedCard === null ? <p>{t('New card')}</p> : <p>+</p>}
                </div>}
                {cardList}
            </Slider>
        )
    }else{
        return null;
    }
};

export default CardList;