import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from '../../../../../store/actions/index';
import Button from '../../../../UI/Button/Button';
import './NewPhoneNumber.scss';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import PhoneNumberValidator from '../../../../../utils/PhoneNumberValidator';
import ResponseErrorMessages from "../../../../../store/errors/ResponseErrorMessages";
import CircularProgress from '@material-ui/core/CircularProgress';

class NewPhoneNumber extends Component {

    state = {
        newPhoneNumber: '',
        groupClassName: "notValid",
        success: false,
        message: '',
    }

    componentDidMount(){
        if(this.props.auth.auth_type !== "user"){
            this.props.history.push("/");
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.profile !== prevProps.profile) {
            if(!this.props.profile.loading){
                if(this.state.newPhoneNumber !== ''){
                    if(Array.isArray(this.props.profile.receivedPhone) && this.props.profile.receivedPhone.length === 0){
                        this.setState({
                            ...this.state,
                            success: true,
                            message: 'Phone number is valid',
                        });
    
                        setTimeout(() => {
                            this.props.next(true, this.state.newPhoneNumber);
                        }, 2000);
                    }else{
                        if(this.props.profile.receivedPhone){
                            this.setState({
                                ...this.state,
                                message: ResponseErrorMessages.get(this.props.profile.receivedPhone.ResponseCode),
                            });
                        }
                    }
                }
            }
        }
    }

    handleChange = prop => event => {
        if (!PhoneNumberValidator.validate(event.target.value)) {
            this.setState({
                ...this.state,
                [prop]: event.target.value,
                groupClassName: "notValid",
            });
            return;
        }

        this.setState({
            ...this.state,
            [prop]: event.target.value,
            groupClassName: "valid",
        });
    };

    validateHandler() {
        this.props.onCheckPhone(this.state.newPhoneNumber);
    }

    render() {

        let infoMessage = '';

        if(this.state.success && this.state.message !== ''){
            infoMessage = (
                <div className={'SuccessMessage'}>
                    <h2>{this.props.t(this.state.message)}!</h2>
                </div>
            );
        }else if(!this.state.success && this.state.message !== ''){

            infoMessage = (
                <div className={'ErrorMessage'}>
                    <h2>{this.props.t(this.state.message)}</h2>
                </div>
            );
        }

        return (
            <div className={"NewPhoneNumberScreen"}>
                <FormControl className="NewPhoneNumberInput">
                    <InputLabel htmlFor="NewPhoneNumberField">{this.props.t('New phone number')}</InputLabel>
                    <Input
                        id="NewPhoneNumberField"
                        type='tel'
                        value={this.state.newPhoneNumber}
                        onChange={this.handleChange('newPhoneNumber')}
                        endAdornment={
                            <InputAdornment position="end">
                                <CheckCircleOutline className={"checkIcon " + this.state.groupClassName}/>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {infoMessage}
                <Button 
                    className={'active'} 
                    disabled={this.state.groupClassName === 'notValid' || this.props.profile.loading || this.state.success}
                    full 
                    click={this.validateHandler.bind(this)} 
                    value={
                        this.props.profile.loading 
                        ? <CircularProgress className={'Loading'} /> 
                        : this.props.t('Next')
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        auth: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckPhone: (phoneNumber) => dispatch(storeActions.checkPhone(phoneNumber)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPhoneNumber)));
