import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from "../../../../store/actions";
import Utils from "../../../../utils/Utils";
import {withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Icons from "../../../../assets/Icons/Icons";
import GTM from "../../../../utils/GTM";


const ERR_LENGTH = 0;
const ERR_CHAR = -1;
const IS_UNDEFINED = undefined;
const VALID = 1;

class LastName extends Component {
    constructor(props) {
        super(props);
        this.timerId = 'LastNameValidation';

    }
    componentDidMount() {
        this.infoMessage = this.props.t('Last Name');
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.lastName !== prevProps.lastName && this.props.lastName !== this.state.fieldInput) {
            this.setState({
                ...this.state,
                fieldInput: this.props.lastName
            })
            this.blurHandler();
        }
    }
    state = {
        message: "",
        ok: false,
        fieldInput:'',
    };

    validate(lastName) {
        if (typeof lastName === 'undefined') return IS_UNDEFINED;

        if (lastName.length < 2) return ERR_LENGTH;

        if (lastName.match(/^[A-z\s-]+$/) == null) return ERR_CHAR; // accept letters, spaces and "-"

        return VALID;
    }

    sendGAEvent() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Last Name" + (this.state.ok ? "" : " - " + this.state.message));
    }

    blurHandler() {


        Utils.delayedFunction('sendGAEvent',this.sendGAEvent.bind(this),50);
        Utils.delayedFunction(this.timerId, this.triggerChangeHandler.bind(this), 0);
        this.props.setSpineSize('big');
        Utils.fixRegisterInputs('last-name');
    }

    triggerChangeHandler() {
        let message = "";
        let valid = this.validate(this.state.fieldInput);
        let ok = this.state.ok;

        switch (valid) {
            case ERR_CHAR:
                message = this.props.t("This field contains invalid characters");
                if (ok) {
                    ok = false;
                }
                break;
            case ERR_LENGTH:
                message = this.props.t("This field must have minimum 2 characters");
                if (ok) {
                    ok = false;
                }
                break;
            case VALID:
                if (!ok) {
                    ok = true;
                }
                break;
            default:
                message = this.props.t("Inserted field is invalid");
                if (ok) {
                    ok = false;
                }
                break;
        }

        this.setState({
            ...this.state,
            message: message,
            ok: ok,

        });
            this.props.onChange({lastName: this.state.fieldInput, validated: ok});
    }

    changeHandler(event){
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.lastName;
        } else {
            fieldInput = event.target.value;
        }
        fieldInput = fieldInput.trim();
        if (fieldInput !== this.state.fieldInput && this.state.fieldInput !== '') {
            this.props.resetOCR();
        }
        Utils.delayedFunction(this.timerId, this.triggerChangeHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput
        });

        this.props.onChange({lastName: fieldInput, validated: false});

    }

    onFocusHandler() {
        this.setState({
            ...this.state,
            message: this.infoMessage,
            fieldInput: this.state.fieldInput === '' && this.props.lastName ? this.props.lastName : this.state.fieldInput
        });
        this.props.setSpineSize('small');
        Utils.fixRegisterInputs('last-name');
    }

    keyPressedHandler(e) {
        // accept just letters, space and "-"
        if(!((e.charCode < 123 && e.charCode > 64) || e.charCode === 32 || e.charCode === 45 )) {
            e.preventDefault();
        }
    }

    render() {
        let errorMessage = this.state.message;
        let props = {
            error: (errorMessage !== "" && errorMessage !== this.infoMessage),
            value: this.props.lastName
        };
        return (
            <div className={'field'}>
                {Icons.get('register-user')}
                <TextField
                    label={this.props.t("Last Name")}
                    id={"last-name"}
                    className={''}
                    onKeyPress={this.keyPressedHandler.bind(this)}
                    onChange={this.changeHandler.bind(this)}
                    onFocus={this.onFocusHandler.bind(this)}
                    onBlur={this.blurHandler.bind(this)}
                    helperText={this.state.message}
                    {...props}
                />
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered({field: "lastName",value: fieldInput.lastName, validated: fieldInput.validated})),
        setSpineSize: (size) => dispatch(stateActions.setSpineSize(size)),
        resetOCR: () => dispatch(stateActions.fieldEntered({field: "ocr_scan", value: "", validated: true}))
    };
};

const mapStateToProps = state => {
    return {
        lang: state.application.language,
        lastName: state.register.fields ? state.register.fields.lastName : "",
        // reward: state.register.rewards.lastName,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LastName));
