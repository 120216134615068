import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';

export function* requestMenuSaga(action) {

    let mainMenuDataElement = document.getElementById("menu-data");
    let menuData = false;

    try {
        menuData = JSON.parse(mainMenuDataElement.firstElementChild.innerHTML);
    } catch (e) {
        // silence
    }

    if (!menuData) {
        const axios = ClientAPI.getInstance();

        try {
            const response = yield axios({
                url: paths.menu.ALL,
                method: 'get'
            });

            if (!response) {
                throw new Error(`[ERROR] No menu received!`);
            }

            if (!response.hasOwnProperty('content')) {
                throw new Error(`[ERROR] Received MENU is missing "content" property!`);
            }
            yield put(actions.receivedMenu(response.content));
        } catch (error) {
            console.log(error);
            return;
        }
    } else {
        yield put(actions.receivedMenu(menuData));
    }

}

export function* receivedMenuSaga(action) {
    if (!action.hasOwnProperty('items')) {
        return;
    }

    if (!(Object.keys(action.items).length > 0)) {
        return;
    }

    const pageTypes = Object.keys(action.items);
    yield put(actions.createPageTypes(pageTypes));

    // check if page is already set
    const reduxState = ClientAPI.getStore().getState();
    if (reduxState.application.pageType && reduxState.application.pageCode) {
        return;
    }

    let firstPageType = pageTypes[0];
    let firstPageCode = firstPageType;
    let pageObject = action.items[firstPageType];
    if (pageObject.hasOwnProperty('submenus') && pageObject.submenus.length > 0) {
        let subPageObject = pageObject.submenus[0];
        firstPageCode = subPageObject.code;
    }
    yield put(actions.setPageType(firstPageType));
    yield put(actions.setPageCode(firstPageCode));
}