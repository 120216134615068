import React, { Component, Fragment } from 'react';
import connect from "react-redux/es/connect/connect";
import i18next from 'i18next';
import * as stateActions from "../../../store/actions";
import { withRouter } from 'react-router-dom';
import Utils from "../../../utils/Utils";
import { withTranslation } from "react-i18next";
import './RegisterHeader.scss';
import TitleBar from "../../UI/TitleBar/TitleBar";
import GTM from "../../../utils/GTM";
import DinamicImage from "../../UI/DinamicImage/DinamicImage";
import Icons from "../../../assets/Icons/Icons";
import {productID} from "../../../constants/Products";
import MarketingEvents from "../../../utils/MarketingEvents";

const TIMER_ANIMATION_DELAY = 0;
const TIMER_ANIMATION_TIME = 1500;

class RegisterHeader extends Component {
    constructor(props) {
        super(props);
        this.Id = "line-progress";
        this.keyPressedWithBind = this.keyPressed.bind(this);
        this.brandNameShort = 'Winner';

        this.attachEvents();

        MarketingEvents.pageView({
            path: '/virtual/pasul1/',
            title: 'Register - pasul 1'
        })

    }

    attachEvents() {
        window.addEventListener('keyup', this.keyPressedWithBind);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.keyPressedWithBind);
    }


    keyPressed(event) {

        // if Enter is Pressed
        // if (event.keyCode === 13) {
        //     // just steps with next button
        //     if (this.props.currentStep < 3) {
        //         // just when the next button is enabled
        //         if (this.props.nextButton.disabled === 'false' || !this.props.nextButton.disabled) {
        //             // go to next step
        //             this.goToStepHandler(this.props.currentStep + 1, true)
        //         }
        //     }
        // }
    }

    state = {
        beginReward: 0,
        reward: 0,
        totalReward: 0,
        timerAnimationTime: false,
        increment: 0,
        ripple: false,
        winnerFun: false
    };

    goToStepHandler(step, force = false) {

        if (step === 0) {
            this.props.history.push('/');
            this.props.reset();
        }

        if (this.props.currentStep > step || force) {
            this.props.setCurrentStep(step);
        }
    }

    componentDidMount() {

        // Utils.delayedFunction("handle",this.handleFocus.bind(this),1500);
        if (!this.props.promotions.length) {
            this.props.getPromotions();
        }
        let winnerFun = Utils.getQueryParam('winner_fun');
        if (winnerFun === 'true') {

            this.setState({
                ...this.state,
                winnerFun: true
            });
            this.props.setBonusCoins(1);

        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.showWinnerFun()) {
            if (this.props.rewardValue !== this.state.totalReward) {
                Utils.delayedFunction("rewardRegister", this.reward.bind(this), 200);
            } else {
                if (this.state.reward < this.state.totalReward) {
                    if (this.state.timerAnimationTime !== false) {
                        let delay = prevState.timerAnimationTime === false ? TIMER_ANIMATION_DELAY : this.state.timerAnimationTime;
                        setTimeout(this.animate.bind(this), delay);
                    }
                }
            }
        }

        if (this.props.currentStep !== prevProps.currentStep) {
            if(this.props.currentStep === 4) {
                MarketingEvents.pageView({
                    path: '/virtual/confirmare/',
                    title: 'Register - Confirmare'
                });

                Utils.delayedFunction('registerAfterLogin',this.login.bind(this),100);
            } else {
                MarketingEvents.pageView({
                    path: '/virtual/pasul' + this.props.currentStep + '/',
                    title: 'Register - pasul ' + this.props.currentStep
                })
            }

        }
    }

    login() {
        this.props.setLoginUsername(this.props.username);
        this.props.setLoginPassword(this.props.password);
        this.props.startLogin();
    }

    deactivateRippleEffect() {
        this.setState({
            ...this.state,
            ripple: false,
        });
    }

    reward() {

        let diffReward = parseInt(this.props.rewardValue - this.state.reward, 10);
        Utils.delayedFunction('deactivateRippleEffectJs', this.deactivateRippleEffect.bind(this), TIMER_ANIMATION_TIME);
        this.setState({
            ...this.state,
            totalReward: this.props.rewardValue,
            timerAnimationTime: TIMER_ANIMATION_TIME / diffReward,
            increment: 1,
            beginReward: this.state.reward,
            ripple: true,
        });
    }
    animate() {

        let newReward = this.state.reward + this.state.increment;
        if (newReward > this.state.totalReward) {
            newReward = this.state.totalReward;
        }
        let hasFinished = newReward === this.state.totalReward;
        this.setState({
            ...this.state,
            reward: newReward,
            increment: hasFinished ? 0 : this.state.increment,
            timerAnimationTime: hasFinished ? false : this.state.timerAnimationTime
        });
    }


    handleTermsAndConditions = () => {
        let url = "https://" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
    }

    showWinnerFun = () => {
        return window.config.winnerFunEnabled === '1' && (Utils.getQueryParam('winner_fun') || this.props.productId === productID.WINNER_FUN);
    };

    goToCasino() {
        GTM.pushGAEvent("Register","Solicita_Bonus", "Bonus_Mai_Tarziu");
        this.props.history.push('/cazino/jocuri');
    }

    goToDeposit = () => {
        GTM.pushGAEvent("Register","Solicita_Bonus", "Bonus_Acum");
        this.props.history.push('/deposit');
    };
    goToWinnerFun = () => {
        this.props.history.push('/winner-fun');
    };

    winnerFunClick() {
        GTM.pushGAEvent("Register","Solicita_Bonus", "Winner_Fun");

        this.props.history.push('/winner-fun');
    }

    render() {

        let final = this.props.currentStep === 4;

        return (
            <Fragment>
                <TitleBar backButton={this.props.currentStep !== 1} image={"https://" + window.config.cdn_address + window.config.MOBILE_BRAND_LOGO} pageTitle={this.showWinnerFun() ? false : this.props.t("Register")} backHandler={() => this.goToStepHandler(this.props.currentStep - 1)} closeButton={true} closeHandler={() => this.goToStepHandler(0)} />
                {!this.showWinnerFun() && !(window.config && window.config.register_disabled === '1') && <div className={"giftTitle" + (final ? ' big' : '')}>
                     <div className={"background" + (this.state.ripple ? ' ripple' : '')}>
                        <div className="help">
                            ?
                        </div>
                        <div className="value">
                            {this.state.reward}
                            <span className="currency">
                                lei <span>Bonus</span>
                            </span>
                        </div>
                    </div>

                    <img src={window.config.brand_name_short !== 'Eldorado'? `${window.config.front_url}/img/mobile/register/img_register_giftbox@3x.png` : `${window.config.front_url}/img/mobile/register/eldorado_giftbox.png`} alt="" />
                    <div className={"buttons" + (!final ? ' hide' : '')}>
                        <div className="play" onClick={this.goToCasino.bind(this)}>
                            <strong>{this.props.t("Play")}</strong>
                            <span>{this.props.t("Save bonus for later")}</span>
                        </div>
                        <div className="deposit" onClick={this.goToDeposit.bind(this)}>
                            <strong>{this.props.t("Deposit")}</strong>
                            <span>{this.props.t("get BONUS now!")}</span>
                        </div>
                        <span className="TCLink" onClick={() => this.handleTermsAndConditions()}>{this.props.t("See Terms & Conditions")}</span>
                    </div>
                </div> }
                {this.showWinnerFun() && !(window.config && window.config.register_disabled === '1') && <div className={'winner-fun'}>
                    <div className={"begin" + (final ? ' hide' : '')}>
                        <DinamicImage imgLink={'https://' + window.config.cdn_address + '/public/winnerfun/banner-register.png'} />
                        <div className="coins">
                            {Icons.get('bonusCoins','bonusCoins')}
                            <div className="wrapper">
                                <div className="wrapper-top">
                                    <div className="icon">{Icons.get('winnerCoin','winner-coin')}</div>
                                    <div className="value">{this.props.winCoins}</div>
                                </div>
                                <div className="description">Startul tau in Winner FUN</div>
                            </div>
                        </div>
                    </div>
                    <div className={"end" + (!final ? ' hide' : '')}>
                        <DinamicImage imgLink={'https://' + window.config.cdn_address + '/public/winnerfun/img-promo-skywind-promoted.png'}/>
                        <div className="wincoins">
                            <span className="value">{this.props.winCoins}</span> <small>WinCoins</small> START
                        </div>
                        <div className="to-winner-fun winner-fun-gtm-class" onClick={this.winnerFunClick.bind(this)}>
                            <strong>{this.props.t("Mergi la winner fun")}</strong>
                        </div>
                    </div>
                </div>}
            </Fragment>

        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCurrentStep: (step) => dispatch(stateActions.setCurrentStep(step)),
        setBonusCoins: (bonusCoins) => dispatch(stateActions.setBonusCoins(bonusCoins)),
        reset: () => dispatch(stateActions.resetRegister()),
        startLogin: () => dispatch(stateActions.loginStart()),
        setLoginPassword: (password) => dispatch(stateActions.loginPassword(password)),
        setLoginUsername: (username) => dispatch(stateActions.loginUsername(username)),
        getPromotions: () => dispatch(stateActions.getPromotions())
        // goToStep: (step) => dispatch(stateActions.goToStep(step)),
        // goToHomepage: () => dispatch(stateActions.changePage('homepage')),
    };
};
const mapStateToProps = state => {

    return {
        // backButton: state.registerWizard.backButton,
        currentStep: state.register.currentStep,
        rewardValue: state.register.bonusValue,
        username: state.register.fields.email,
        password: state.register.fields.password,
        promotions: state.application.promotions,
        winCoins: state.register.winCoins,
        productId: state.application.lastProductId
        // breadcrumb: state.registerWizard.breadcrumb,
        // signedUp: state.registerWizard.signedUp,
        // nextButton: state.registerWizard.nextButton
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterHeader)));


