import React, { useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Switch, Route, useLocation, withRouter, Redirect } from "react-router-dom";
//import TopMenu from "./../components/TopMenu/TopMenu";
import TopMenu from "./../components/TopMenu/TopMenu";
import "./App.scss";
import "./../bets/styles/flexUtils.css";
import LeftMenu from "../components/LeftMenu/LeftMenu";
import * as storeActions from "../store/actions";
import PageContent from "./PageContent";
import TimeSpendPopup from "../components/GlobalWidgets/TimeSpendPopup/TimeSpendPopup";
import LanguageSwitcher from "../components/GlobalWidgets/Both/LanguageSwitcher/LanguageSwitcher";
import GameLauncher from "../components/CasinoGames/sections/GameLauncher/GameLauncher";
import GlobalWidgets from "../components/GlobalWidgets/GlobalWidgets";
import { withTranslation } from "react-i18next";
import NotFound from "../components/NotFound/NotFound";

import Bets from "../bets/layout/DefaultLayout";
import BetSlip from "../bets/layout/BetSlipLayout";
import betsGetStore from "../bets/store";
import { appInitialize as betsAppInitialize } from "../bets/store/actions/app";

import Lotto from "../lotto/layout/DefaultLayoutMobile";
import LottoSlip from "../lotto/layout/BetSlipLayout";
import lottoGetStore from "../lotto/store";
import { appInitialize as lottoAppInitialize } from "../lotto/store/actions/app";

import MyTickets from "../tickets/TicketsListener";

import { connectPlayerWebsocket } from "../store/sagas/ws";
import Footer from "../components/Footer/Footer";
import NSoftLayout from "../nsoft/layout/mobile";
import { getBaseRouterPath as getNSoftBaseRouterPath } from "../nsoft/utils/config";

import { createIntervalTicker } from "../utils/intervals";
import WinnerFunLandingPage from "../winner-fun/pages/LandingPage";
import ClientDetection from "../utils/ClientDetection";
import Head from "../components/Head/Head";

import Jackpot from "../components/Jackpot/Jackpot";
import Collection from "../components/Collections/Collection/Collection";
import Promotions from "../components/Promotions/Promotions";
import Promotion from "../components/Promotions/Promotion/Promotion";
import LobbyV2 from "../lobby/index";
import { productID, productMainURL } from "../constants/Products";
import PopupBonusEntry from "../components/PopupBonus/PopupBonusEntry";

import TreasureMaps from "../treasure-maps/views/main-view";
import TournamentsMissions from "../tournaments-missions/views/main-views";
import MarketingEvents from "../utils/MarketingEvents";
import RouterHistory from "../router-history";

// refresh the page if the app watch timer is stuck
// this means the page was sent to background and brought back after some time
const APP_REFRESH_THRESHOLD = 70 * 1000;
let appWatcherTime = 0;

function App(props) {

    useEffect(() => props.history.listen((location) => {
        urlChanged(location.pathname);
    }), []);
    useEffect(() => {
        MarketingEvents.init();

        urlChanged(props.history.location.pathname);

    }, []);

    const urlChanged = (pathname) => {
        MarketingEvents.pageView({
            path: pathname,
            title: pathname
        })

        let productId = false;

        if (pathname === productMainURL.HOME) {
            productId = productID.HOME
        } else if (pathname.indexOf(productMainURL.LIVE_CASINO) === 0 || pathname.indexOf('cazino-live') === 1) {
            productId = productID.LIVE_CASINO
        } else if (pathname.indexOf(productMainURL.CASINO) === 0 || pathname.indexOf('/play') === 0) {
            productId = productID.CASINO
        } else if (pathname.indexOf(productMainURL.LOTTO) === 0) {
            productId = productID.LOTTO
        } else if (pathname.indexOf(productMainURL.LIVE_SPORT) === 0) {
            productId = productID.LIVE_SPORT
        } else if (pathname.indexOf(productMainURL.SPORT) === 0) {
            productId = productID.SPORT
        } else if (pathname.indexOf(productMainURL.LOTTO) === 0) {
            productId = productID.LOTTO
        } else if (pathname.indexOf(productMainURL.WINNER_FUN) === 0) {
            productId = productID.WINNER_FUN
        } else if (pathname.indexOf(productMainURL.CUSTOMER_SUPPORT) === 0 || pathname.indexOf('locations') === 1) {
            productId = productID.CUSTOMER_SUPPORT
        }
        if (productId) {
            props.setProductId(productId)
        }
    }



    const betsEnabled = window.config && window.config.betsEnabled === "1";
    const nsoftEnabled = window.config && window.config.nsoftEnabled === "1";
    const nsoftRouterPath = getNSoftBaseRouterPath();
    const virtualsEnabled = window.config && window.config.virtualsEnabled === "1";
    const lottoEnabled = window.config && window.config.lottoEnabled === "1";
    const winnerFunEnabled = window.config && window.config.winnerFunEnabled === "1";
    const excelBetLandingPage = nsoftEnabled && window.config.nsoftTheme === "Excel";
    const lobbyEnabled = window.config && window.config.lobbyEnabled === "1";
    const treasureMapsEnabled = window.config && window.config.treasureMapsEnabled === "1";
    const tournamentsMissionsEnabled = window.config && window.config.tournamentsMissionsEnabled === "1";

    useEffect(() => {
        // delete the initial app loader (this is injected from PHP online_front_web\module\Application\view\layout\layout.phtml)
        const loader = document.getElementById("Loading");
        if (loader !== null) {
            loader.parentNode.removeChild(loader);
        }

        // only connect player websocket if we should receive balance updates
        // that currently happens for Bets, Lotto and Virtuals
        //if (betsEnabled || nsoftEnabled || virtualsEnabled || lottoEnabled) {
        connectPlayerWebsocket();
        //}

        if (betsEnabled || nsoftEnabled) {
            betsGetStore().dispatch(betsAppInitialize());
        }

        if (lottoEnabled) {
            lottoGetStore().dispatch(lottoAppInitialize());
        }

        // for forcing a refresh is the app is stale
        createIntervalTicker({
            name: "app hide watcher",
            interval: 1000,
            callback: () => {
                const now = (new Date()).getTime();

                if (appWatcherTime !== 0) {
                    if (now - appWatcherTime > APP_REFRESH_THRESHOLD) {
                        props.reinitializeApp();
                    }
                }
                appWatcherTime = now;
            },
            options: {
                replace: true,
            },
        });

        props.appInitialize();
        props.startAuth();
        window.addEventListener("resize", props.appResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps

        // if (excelBetLandingPage && localStorage.getItem("excelbet_landing_page_shown") !== "true") {
        //     props.history.push("/lp/lp-1");
        // }
    }, []);

    useEffect(() => {
        return function cleanup() {
            window.removeEventListener("resize", props.appResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });


    let rootPath = "/lobby";
    let defPath = "/cazino";

    if (window.config.rootPaths && window.config.rootPaths.casino) {
        rootPath = "/" + window.config.rootPaths.casino;
        defPath = "/" + window.config.rootPaths.casino;
    }

    //console.log({ rootPath, defPath });

    if (typeof window.config !== "undefined" && window.config.is404 === 1) {
        return <NotFound />;
    } else {
        return (
            <>
                <Head />
                <PopupBonusEntry />
                <RouterHistory />
                {betsEnabled && <BetSlip />}
                {lottoEnabled && <LottoSlip />}
                <Switch>
                    {treasureMapsEnabled && <Route path={['/treasure-maps/:path+']} exact key={'route_treasure_maps'} component={TreasureMaps} />}
                    {tournamentsMissionsEnabled && <Route path={['/tournaments/:path+']} exact key={'route_tournaments_missions'} component={TournamentsMissions} />}

                    {lobbyEnabled && <Route exact path="/cazino">
                        <Redirect to={rootPath + "/slots"} />
                    </Route>}
                    {lobbyEnabled && <Route exact path="/cazino/jocuri">
                        <Redirect to={rootPath + "/slots"} />
                    </Route>}
                    {lobbyEnabled && <Route exact path="/cazino/promotii">
                        <Redirect to={defPath + "/promotii"} />
                    </Route>}
                    {lobbyEnabled && <Route exact path="/table-games">
                        <Redirect to={rootPath + "/roulette"} />
                    </Route>}

                    {lobbyEnabled && (rootPath === defPath) && (
                        <Route path={rootPath} key={"route_lobby"}>
                            <div
                                className={
                                    "--equal App" +
                                    (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
                                        ? " ios"
                                        : " android")
                                }
                            >
                                <TimeSpendPopup />
                                <TopMenu />
                                <LeftMenu />
                                <Switch>
                                    <Route path={[`${defPath}/jocuri`, defPath]} exact key={'route_casino'} component={LobbyV2} />
                                    <Route path={`${defPath}/jackpot`} exact key={'route_jackpot'} component={Jackpot} />
                                    <Route path={[`${defPath}/collection`, `${defPath}/collection/:id?`]} exact key={'route_collection'} component={Collection} />
                                    <Route path={`${defPath}/promotii`} exact key={'route_promotions'} component={Promotions} />
                                    <Route path={`${defPath}/promotii/:id/:name?`} exact key={'route_promotions_with_id'} component={Promotion} />
                                    <Route path={`${rootPath}/:path+`} key={'route_lobby'} component={LobbyV2} />
                                </Switch>
                                <MyTickets />
                            </div>
                        </Route>
                    )}
                    {lobbyEnabled && (rootPath !== defPath) && (
                        <Route path={defPath} key={"route_lobby_#1"}>
                            <div
                                className={
                                    "App" +
                                    (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
                                        ? " ios"
                                        : " android")
                                }
                            >
                                <TimeSpendPopup />
                                <TopMenu />
                                <LeftMenu />
                                <Route path={[`${defPath}/jocuri`, defPath]} exact key={'route_casino'} component={LobbyV2} />
                                <Route path={`${defPath}/jackpot`} exact key={'route_jackpot'} component={Jackpot} />
                                <Route path={[`${defPath}/collection`, `${defPath}/collection/:id?`]} exact key={'route_collection'} component={Collection} />
                                <Route path={`${defPath}/promotii/:id/:name?`} exact key={'route_promotions_with_id'} component={Promotion} />
                                <Route path={`${defPath}/promotii`} exact key={'route_promotions'} component={Promotions} />
                                <MyTickets />
                            </div>
                        </Route>
                    )}
                    {lobbyEnabled && (rootPath !== defPath) && (
                        <Route path={rootPath} key={"route_lobby_#2"}>
                            <div
                                className={
                                    "App" +
                                    (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
                                        ? " ios"
                                        : " android")
                                }
                            >
                                <TimeSpendPopup />
                                <TopMenu />
                                <LeftMenu />
                                <Route path={`${rootPath}`} exact key={'route_lobby_home'} component={LobbyV2} />
                                <Route path={`${rootPath}/:path+`} key={'route_lobby'} component={LobbyV2} />
                                <MyTickets />
                            </div>
                        </Route>
                    )}

                    {/*excelBetLandingPage && <Route exact path={"/lp/lp-1"} key={"route_landing_pages"}>
                        <ExcelBetLadingPage />
                    </Route>*/}
                    {betsEnabled && (
                        <Route path={"/pariuri"} key={"route_bets"}>
                            <div
                                className={
                                    "App AppBets" +
                                    (ClientDetection.getOS().name.toLowerCase())
                                }
                            >
                                <TimeSpendPopup />
                                <TopMenu />
                                <LeftMenu />
                                <Bets noNavBar={true} basePath="/pariuri" />
                                <Footer />
                                <MyTickets />
                            </div>
                        </Route>

                    )}
                    {winnerFunEnabled && (
                        <Route path={"/winner-fun"} key={"route_winner_fun"}>
                            <div
                                className={
                                    "App AppBets" +
                                    (!!navigator.platform &&
                                        /iPad|iPhone|iPod/.test(navigator.platform)
                                        ? " ios"
                                        : " android")
                                }
                            >
                                <TimeSpendPopup />
                                <TopMenu />
                                <LeftMenu />
                                <Bets noNavBar={true} basePath="/winner-fun" />
                                <Footer />
                                <MyTickets />
                            </div>
                        </Route>
                    )}
                    {winnerFunEnabled && (
                        <Route path={"/welcome-to-winner-fun"} key={"winner-welcome"}>
                            <div
                                className={
                                    "App" +
                                    (!!navigator.platform &&
                                        /iPad|iPhone|iPod/.test(navigator.platform)
                                        ? " ios"
                                        : " android")
                                }
                            >
                                <WinnerFunLandingPage />
                            </div>
                        </Route>
                    )}
                    {nsoftEnabled && (
                        <Route path={nsoftRouterPath} key={"route_nsoft"}>
                            <div
                                className={
                                    "App AppBets nsoft" +
                                    (!!navigator.platform &&
                                        /iPad|iPhone|iPod/.test(navigator.platform)
                                        ? " ios"
                                        : " android")
                                }
                            >
                                <TimeSpendPopup />
                                <TopMenu />
                                <LeftMenu />
                                <NSoftLayout />
                                <Footer />
                                <MyTickets />
                            </div>
                        </Route>
                    )}
                    {lottoEnabled && (
                        <Route path={"/lotto"} key={"route_lotto"}>
                            <div
                                className={
                                    "App AppBets lotto" +
                                    (!!navigator.platform &&
                                        /iPad|iPhone|iPod/.test(navigator.platform)
                                        ? " ios"
                                        : " android")
                                }
                            >
                                <TimeSpendPopup />
                                <TopMenu />
                                <LeftMenu />
                                <Lotto />
                                <Footer />
                                <MyTickets />
                            </div>
                        </Route>
                    )}

                    <Route>
                        <div
                            className={
                                "App" +
                                (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
                                    ? " ios"
                                    : " android")
                            }
                        >
                            <TimeSpendPopup />
                            <TopMenu />
                            <Grid container justifyContent="space-between" className="App-body">
                                <LeftMenu />
                                {/*<RightMenu />*/}
                                <PageContent />
                                <LanguageSwitcher initializationOnly={true} />
                                <GlobalWidgets />
                                <div className={"gameLauncherSupport"}>
                                    <GameLauncher />
                                </div>
                            </Grid>
                            {(betsEnabled || nsoftEnabled || virtualsEnabled || lottoEnabled) && (
                                <MyTickets />
                            )}
                        </div>
                    </Route>
                </Switch>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        startAuth: () => dispatch(storeActions.startAppAuth()),
        appInitialize: () => dispatch(storeActions.appInitialize()),
        reinitializeApp: () => dispatch(storeActions.reinitializeApp()),
        appResize: () => dispatch(storeActions.appResized()),
        setProductId: (productId) => dispatch(storeActions.setLastProductId(productId))
    };
};

export default withTranslation()(withRouter(connect(null, mapDispatchToProps)(App)));
