import * as actions from "../actions";
import ClientAPI from './../../ClientAPI/ClientAPI';
import {put} from 'redux-saga/effects';
import * as paths from "../../ClientAPI/paths";
import Utils from "../../utils/Utils";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";
import MarketingEvents from "../../utils/MarketingEvents";

const parameters = {
    email: 'mail',
    phone: 'phone',
    cnp: 'cnp',
    nin: 'cnp'
}
const REGISTER_MAP = {
    attributes: {
        cnp: "nin",
        phone: "phone",
        firstName: "first_name",
        lastName: "last_name",
        ocr_scan: "ocr_scan",
        idExpired: "id_expired",
        marketing: "marketing",
        marketing_sms: "marketing_sms",
        marketing_phone: "marketing_phone",
        marketing_email: "marketing_email",
        moid: "moid",
        refferenceCode: "aff_id",
        lpid: "lpid",
        address: "address"
    },
};
export function* validateFieldSaga(action) {

    const axios = ClientAPI.getInstance();

    try {
        let data = {};
        data[parameters[action.field]] = action.value;

        const response = yield axios({
            url: paths.validation[action.field],
            method: 'post',
            data: data
        });
        yield console.log('******validateFieldResponse', response);
        if (!response.status) {
            throw new Error(`[ERROR] ${action.field} Validation missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] ${action.field} Validation not triggered!`);
        }
        yield put(actions.validateFieldResponse(response,action.field))
    } catch (error) {
        console.log(error);
    }

}

export function* sendSMSSaga(action) {
    const storeState = ClientAPI.getStore().getState();

    const axios = ClientAPI.getInstance();

    try {
        yield put(actions.setTimer(window.config.register_sms_timer ? window.config.register_sms_timer : 14));

        const response = yield axios({
            url: 'api/register/verifyPhone',
            method: 'post',
            data: {
                phone: storeState.register.fields.phone,
            }
        });

        if (!response.status) {
            throw new Error(`[ERROR] SEND SMS missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] SEND SMS Validation not triggered!`);
        }

        if (typeof response.result !== 'undefined' && typeof response.result.type !== "undefined") {
            // yield put()
        }
    } catch (error) {
        console.log(error);
    }
}

export function* validatePhoneSaga(action) {

    // const axios = ClientAPI.getInstance();

    try {
        // const phone = ClientAPI.getStore().getState().register.fields.phone;
        // const currentStep = ClientAPI.getStore().getState().register.currentStep;
        // const phoneValidationCode = ClientAPI.getStore().getState().register.fields.phoneValidationCode;
        // yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: phoneValidationCode, validated: false}));
        //
        // const response = yield axios({
        //     url: 'api/register/verifyPhone',
        //     method: 'post',
        //     data: {
        //         phone: phone,
        //         code: phoneValidationCode
        //     }
        // });
        //
        // if (!response.status) {
        //     throw new Error(`[ERROR] SEND SMS missing status!`);
        // }
        //
        // if (!("OK" === response.status)) {
        //     throw new Error(`[ERROR] SEND SMS Validation not triggered!`);
        // }

        yield put(actions.setPartialAccount(true));
        // ExpiringLocalStorage.set('phoneValidation',JSON.stringify({step: currentStep, phone: phone}),1800);
        // yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: phoneValidationCode, validated: true}));
        // yield put(actions.saveRegisterFields());
    } catch (error) {
        // window.localStorage.removeItem('phoneValidationCode');
        // yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: '', validated: false}));
        //
        // console.log(error);
    }
}

export function* saveRegisterFieldsSaga(action) {
    let fields = ClientAPI.getStore().getState().register.fields;
    let validated = ClientAPI.getStore().getState().register.validated;

    let toSave = {};

    Object.keys(fields).forEach((key)  => {
        if (validated[key] && key !== 'password' && key !== 'confirmPassword' && key !== 'phoneValidationCode') {
            toSave[key] = fields[key];
        }
    })

    // // regenerate paid for fraud reasons.
    let oldRegisterFields = ExpiringLocalStorage.get('registerFields');
    if (oldRegisterFields) {
        oldRegisterFields = JSON.parse(oldRegisterFields);
        if ((oldRegisterFields.nin && oldRegisterFields.nin !== toSave.nin) || (oldRegisterFields.phone && oldRegisterFields.phone !== toSave.phone)) {
            const uuid = yield generateUUID();
            yield ExpiringLocalStorage.set('paid',uuid);
        }
    }

    yield ExpiringLocalStorage.set('registerFields',JSON.stringify(toSave));
    if (!action.onlyLocal) {
        yield put(actions.setPartialAccount())
    }
}

export function* validateEmailSaga(action) {
    yield put(actions.startAppAuth());
    const axios = yield Utils.getAxiosWithToken();

    try {

        const response = yield axios({
            url: '/api/register/token',
            method: 'post',
            data: {token: action.token},
        });

        if (!response.status) {
            throw new Error(`[ERROR] REGISTER missing status!`);
        }



        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] Email Validation not triggered!`);
        }
        yield put(actions.emailValidated((typeof response.result.ResponseCode === "undefined")));

    } catch (error) {
        yield put(actions.emailValidated(false));
    }
}

export function* signUpSaga(action) {

    let fields = ClientAPI.getStore().getState().register.fields;
    let lastProductId = ClientAPI.getStore().getState().application.lastProductId;
    const axios = ClientAPI.getInstance();

    if (typeof fields.cnp !== "undefined") {
        fields.nin = fields.cnp;
    }


    if (window.config && window.config.cordova) {
        const apps_flyer_af_sub1 = localStorage.getItem("apps_flyer_af_sub1");
        if (apps_flyer_af_sub1) {
            fields.refferenceCode = apps_flyer_af_sub1;
        }
    }

    fields.refferenceCode = Utils.getCookie('aff_id');
    fields.sub_id = yield Utils.getCookie('sub_id');
    fields.gclid = Utils.getCookie('gclid');
    fields.marketingAgreement = fields.marketing ? 1 : 0;
    fields.marketing_sms = fields.marketing_sms ? 1 : 0;
    fields.marketing_email = fields.marketing_email ? 1 : 0;
    fields.marketing_phone = fields.marketing_phone ? 1 : 0;
    fields.marketing_partners = fields.marketing_partners ? 1 : 0;


    fields.lpid = ExpiringLocalStorage.get('lpid');
    fields.moid = ExpiringLocalStorage.get('moid');
    if (action.oldVersion) {
        fields.paid = null;
    } else {
        fields.paid = ExpiringLocalStorage.get('paid');
    }

    let winnerFun = Utils.getQueryParam('winner_fun');
    if (winnerFun === 'true') {
        fields.bonusCoins = 1;
        let winner_referral_id = Utils.getQueryParam('winner_referral_id');
        if (winner_referral_id) {
            fields.winner_referral_id = winner_referral_id;
        }
    }

    if (lastProductId) {
        fields.product_id = lastProductId
    }

    let pac = PlayerAbuseChecker.getInfo();
    fields = {
        ...pac,
        ...fields
    }

    yield put(actions.startSignUpProcess());

    try {

        const response = yield axios({
            url: '/api/register',
            method: 'post',
            data: fields,
        });

        if (!response.status) {
            yield put(actions.signUpOK(false,response));
            return ;
        }

        if (!("OK" === response.status)) {
            yield put(actions.signUpOK(false,response));
            return ;
        }

        if (typeof response.result !== "undefined" && typeof response.result.ResponseCode !== "undefined") {
            yield put(actions.signUpOK(false,response));
            return ;
        }

        yield put(actions.signUpOK(true,response));

        // used for signup tracking after account received:
        MarketingEvents.signUp();

        // login
        yield put(actions.setLoginUsername(fields.email));
        yield put(actions.setLoginPassword(fields.password));
        yield put(actions.loginStart());

        try {
            if (window.config && window.config.cordova && window.plugins && window.plugins.appsFlyer && window.plugins.appsFlyer.logEvent) {
                window.plugins.appsFlyer.logEvent("af_complete_registration", { "af_registration_method": "Email" }, () => { }, () => { });
            }
        } catch (err) { }


    } catch (error) {
        yield put(actions.signUpOK(false));
    }
}
function* generateUUID() {
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return yield 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}
export function* setPartialAccountSaga(action) {

    let fields = ClientAPI.getStore().getState().register.fields;
    const axios = ClientAPI.getInstance();

    fields.paid = yield ExpiringLocalStorage.get('paid');
    if (!fields.paid) {
        // just in case. shouldn't trigger this
        fields.paid = yield generateUUID();
        ExpiringLocalStorage.set('paid',fields.paid);
    }
    fields.aff_id = yield Utils.getCookie('aff_id');
    fields.sub_id = yield Utils.getCookie('sub_id');
    fields.gclid = yield Utils.getCookie('gclid');
    fields.lpid = yield ExpiringLocalStorage.get('lpid');
    fields.moid = yield ExpiringLocalStorage.get('moid');

    let lastProductId = yield ClientAPI.getStore().getState().application.lastProductId;
    if (lastProductId) {
        fields.product_id = yield lastProductId;
    }

    try {

        Object.keys(REGISTER_MAP.attributes).forEach(attr => {
            if (fields[attr]) {
                fields[REGISTER_MAP.attributes[attr]] =  fields[attr];
            }
        })

        if(action.phoneValidation) {
            fields.phoneValidationCode = yield fields.phoneValidationCode;
        } else {
            fields.phoneValidationCode = null;
        }

        const response = yield axios({
            url: '/api/register/setPartial',
            method: 'post',
            data: fields,
        });

        if (!response.status) {

            throw new Error(`[ERROR] REGISTER missing status!`);
        }

        if (!("OK" === response.status)) {

            throw new Error(`[ERROR] REGISTER Validation not triggered!`);
        }
        if (action.phoneValidation) {
            yield put(actions.fieldEntered({ field: 'paid', value: response.result.paid, validated: true}));
            ExpiringLocalStorage.set('paid',response.result.paid);

            const currentStep = ClientAPI.getStore().getState().register.currentStep;
            ExpiringLocalStorage.set('phoneValidation',JSON.stringify({step: currentStep, phone: fields.phone}),1800);
            yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: fields.phoneValidationCode, validated: true}));
            yield put(actions.saveRegisterFields(true));
            yield put(actions.setCurrentStep(3));

        }
    } catch (error) {
        window.localStorage.removeItem('phoneValidationCode');
        yield put(actions.fieldEntered({ field: 'phoneValidationCode', value: '', validated: false}));
    }
}

export function* getPartialAccountSaga() {

    let data = {};
    const axios = yield Utils.getAxiosWithToken();

    data.paid = yield ExpiringLocalStorage.get('paid');

    if (!data.paid) {
        data.paid = yield generateUUID();
        ExpiringLocalStorage.set('paid', data.paid);
    }

    try {
        const response = yield axios({
            url: '/api/register/getPartial',
            method: 'post',
            data: data,
        });

        if (!response.status) {
            throw new Error(`[ERROR] REGISTER missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] REGISTER Validation not triggered!`);
        }

        let fields = [];
        Object.keys(REGISTER_MAP.attributes).forEach(attr => {
            fields[attr] = response.result[REGISTER_MAP.attributes[attr]] ? response.result[REGISTER_MAP.attributes[attr]] : false;
        })
        fields.time = new Date().getTime();
        if (response.result.product_id) {
            yield put(actions.setLastProductId(response.result.product_id));
        }
        yield put(actions.updateRegisterFields(fields));
    } catch (error) {
        // do nothing
    }



}

export function* getBonusCoinsSaga(action) {
    const axios = yield Utils.getAxiosWithToken();

    try {
        let winnerFunURL = process.env.CFW.betsApiUrl + "/winner-fun/info/register";
        let winnerReferralId = Utils.getQueryParam('winner_referral_id');
        if (winnerReferralId) {
            winnerFunURL +="?winner_referral_id=" + winnerReferralId;
        }
        const response = yield axios({
            url: winnerFunURL,
        });

        if (typeof response.coins === 'undefined') return;
        if (response.coins > 1) {
            yield put(actions.setBonusCoins(response.coins));
        }

    } catch (error) {
        console.log("[ERROR WINNER FUN]", error);
    }
}