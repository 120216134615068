import * as actionTypes from "./actionTypes";
import ResponseErrorMessages from "../errors/ResponseErrorMessages";
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";

export const validateField = (value, field) => {

    return {
        type: actionTypes.register.VALIDATE_FIELD,
        value: value,
        field: field
    }
};

export const validateFieldResponse = (data, field) => {
    let error = false;


    if (typeof data.result !== 'undefined' && typeof data.result.ResponseCode !== "undefined") {
        error = ResponseErrorMessages.get(data.result.ResponseCode);
    }
    return {
        type: actionTypes.register.VALIDATE_FIELD_RESPONSE,
        message: error,
        field: field
    }
};

export const fieldEntered = (fieldInfo) => {
    // Utils.delayedFunction("getNextButtonState",getNextButtonState,250);
    return {
        type: actionTypes.register.FIELD_CHANGED,
        fieldInfo: fieldInfo
    }
};

export const sendSMS = () => {
    return {
        type: actionTypes.register.SEND_SMS
    }
};

export const validatePhone = () => {
    return {
        type: actionTypes.register.VALIDATE_PHONE
    }
};

export const setCurrentStep = (step) => {
    return {
        type: actionTypes.register.SET_CURRENT_STEP,
        step: step
    }
};
export const setBonusCoins = (bonusCoins) => {
    return dispatch => {
        if (bonusCoins === 1) {
            dispatch({
                type: actionTypes.register.GET_BONUS_COINS,
                bonusCoins: bonusCoins
            });
        }
            dispatch({
                type: actionTypes.register.SET_BONUS_COINS,
                bonusCoins: bonusCoins
            });


    };
};



export const resetRegister = (resetLocalstorage = true) => {
    if (resetLocalstorage) {
        window.localStorage.removeItem('registerFields');
        window.localStorage.removeItem('paid');
        window.localStorage.removeItem('moid');
        window.localStorage.removeItem('lpid');
    }
    return {
        type: actionTypes.register.RESET,
    }
}

export const signUp = (oldVersion = false) => {
    return {
        type: actionTypes.register.SIGN_UP,
        oldVersion: oldVersion

    }
};

export const validateEmail = (token) => {
    return {
        type: actionTypes.register.VALIDATE_EMAIL,
        token: token
    }
};

export const emailValidated = (validated) => {
    return {
        type: actionTypes.register.ACCOUNT_EMAIL_VALIDATED,
        validated: validated
    }
}

export const signUpOK = (ok,errors) => {
    return dispatch => {
        if (!ok) {
            dispatch({
                type: actionTypes.register.SET_CURRENT_STEP,
                step: ExpiringLocalStorage.get('moid') ? 1 : 3
            })
        } else {
            resetRegister(true);
        }
        dispatch({
            type: actionTypes.register.SIGN_UP_RESPONSE,
            value: ok,
            errors: errors
        })
    }

};

export const startSignUpProcess = () => {
    return {
        type: actionTypes.register.START_SIGN_UP
    }
};

export const setTimer = (time) => {
    return {
        type: actionTypes.register.SET_TIMER,
        value: time
    }
};

export const setVerificationType = (type) => {
    return {
        type: actionTypes.register.SET_VERIFICATION_TYPE,
        value: type
    }
};

export const setSpineSize = (size) => {
    return {
        type: actionTypes.register.SET_SPINE_SIZE,
        size: size
    }
};

export const checkboxesErrors = () => {

    return {
        type: actionTypes.register.CHECKBOX_ERROR
    }

};

export const setPartialAccount = (phoneValidation = false) => {
    return {
        type: actionTypes.register.SET_PARTIAL_ACCOUNT,
        phoneValidation:phoneValidation
    }
}

export const saveRegisterFields = (onlyLocal = false) => {
    return {
        type: actionTypes.register.SAVE_REGISTER_FIELDS,
        onlyLocal: onlyLocal
    }
}

export const getPartialAccount = () => {
    return {
        type: actionTypes.register.GET_PARTIAL_ACCOUNT
    }
}

export const updateRegisterFields= (data) => {
    return {
        type: actionTypes.register.UPDATE_REGISTER_FIELDS,
        data: data
    }
}