import React, {Component} from 'react';
import {connect} from 'react-redux';


class LoggedOut extends Component {
    render() {
        return (
            <div></div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

const mapStateToProps = state => {
    return {
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(LoggedOut);
