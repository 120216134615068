import React from 'react';
import connect from "react-redux/es/connect/connect";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "./../Grids.scss";

import { makeGetComponentEvents, getConfig } from "../../../../bets/store/selectors/betData";
import { styleToObj } from "../utils/functions";
import { chooseTextLanguage } from "../utils/functions";
import Scroller from "../Scroller";

import { ReactComponent as FireIcon } from "./../assets/of-interes.svg";

import SkeletonLoader from "./SkeletonLoader";

const TopLive = (props) => {
  const { component, history, className, limitTopLiveMatches } = props;
  const { i18n } = useTranslation();

  const navigateTo = () => {
    if (component && component.link && component.link.url) {
      const url = component.link.url;
      if (url.toLowerCase().indexOf("http") > -1) {
        window.location = url;
      } else {
        history.push(url);
      }
      return;
    }
    history.push("/pariuri/live-match/highlights");
  }
  const navigateCard = (url) => () => {
    if (url) {
      if (url.toLowerCase().indexOf("http") > -1) {
        window.location = url;
      } else {
        history.push(url);
      }
    }
  };

  const matches = [];
  if (component && component.rows) {
    const matchesToShow = [];

    component.rows.forEach((row) => {
      if (!row.match.loaded) return;
      matchesToShow.push(row);
    });

    let first = true;

    matchesToShow.splice(0, limitTopLiveMatches).forEach((row, i) => {
      const m = row.match;

      if (!row.match.loaded) return;

      matches.push(
        <SkeletonLoader idMatch={m.idMatch} mType={m.mType} row={row} navigateCard={navigateCard} preloaded={first} key={`matches_${i}`} />
      );

      first = false;
    });
  }

  if (!matches || matches.length === 0) return null;

  let title = "Top LIVE";
  let titleStyle = {};
  if (component.title) {
    if (component.title.text) {
      title = chooseTextLanguage(component.title.text, i18n.language);
    }
    if (component.title.style) {
      titleStyle = styleToObj(component.title.style);
    }
  }

  let link = "All";
  let linkStyle = {};
  if (component.link) {
    if (component.link.text) {
      link = chooseTextLanguage(component.link.text, i18n.language);
    }
    if (component.link.style) {
      titleStyle = styleToObj(component.link.style);
    }
  }

  return (
    <div className={`grid-wrapper top-live ${className ? className : ''}`}>
      <div>
        <h1 className={"text-header"}>
          <span className="icon"><FireIcon /></span>
          <span className="txt" style={titleStyle}>{title}</span>
          <div className="all-matches" style={linkStyle} onClick={navigateTo}>{link}</div>
        </h1>
        <Scroller className="grid grid-top-live">
          {matches}
        </Scroller>
      </div>
    </div>
  );
}

const mapStateToProps = () => {
  const getComponentEvents = makeGetComponentEvents();

  return (state, props) => {
    const cfg = getConfig(state);

    return {
      limitTopLiveMatches: cfg.settings.topLiveShowDefault ? cfg.settings.topLiveShowDefault : 5,
      component: getComponentEvents(state, props),
    };
  }
};

export default withRouter(connect(mapStateToProps)(TopLive));