class GoogleAds {

    static init(debug) {
        if (debug) {
            if (!this.isActive()) {
                window.MarketingEvents.push('GoogleAds not enabled');
            }
        }
    }

    static isActive() {
        return !!window.config.google_ads_id;
    }

    static conversion_callback (url) {
        // if (typeof(url) != 'undefined') {
        //     window.location = url;
        // }
    }

    static pushConversion(data) {
        if (!this.isActive()) {
            return false;
        }
        // if (typeof data.event_callback === 'undefined') {
        //     data.event_callback = this.conversion_callback;
        // }

        window.gtag('event','conversion',data);
    }

    static deposit(data) {
        if (!this.isActive()) {
            return false;
        }
        if (window.config?.google_ads_deposit_label) {
            GoogleAds.pushConversion({
                'send_to': `${window.config.google_ads_id}/${window.config.google_ads_deposit_label}`,
                'value': data.amount,
                'currency': data.currency,
                'transaction_id': ''
            })
        }
    }

    static signUp() {
        if (!this.isActive()) {
            return false;
        }
        if (window.config?.google_ads_register_label) {
            GoogleAds.pushConversion({
                'send_to': `${window.config.google_ads_id}/${window.config.google_ads_register_label}`
            })
        }
    }
}


export default GoogleAds;
