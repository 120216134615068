import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Spring } from "react-spring/renderprops";

import myFilter from "./assets/menu_search.svg";
import logo from "./assets/logo_winner@2x.png";
import bluePath from "./assets/bluePath.svg";
import user from "./assets/user.svg";
import bilete from "./assets/bilete.svg";
import avatar from "./assets/avatar.svg";
import camera from "./assets/photo-camera.svg";

import getStore from "../../store";
import { auth as nSoftAuth } from "../../api/auth";
import { formatCurrency, formatAmount } from "../../utils/formatters";
import * as storeActions from "./../../../store/actions";
import TicketCheckModal from "../BetSlip/TicketCheckModal";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "56px",
    backgroundColor: "#C31822",
    color: "#fff",
    border: "0px",
    fontFamily: "OpenSans"
  },
  optionsRoot: {
    width: "100%",
    height: "55px"
  },
  optionsWrapper: {
    height: "55px",
    paddingLeft: "20px"
  },
  options: {
    height: "55px",
    backgroundColor: "#001D8A",
    paddingRight: "10px"
  },
  filter: {
    padding: "16px 8px 0 16px"
  },
  logo: {
    width: "84px",
    height: "auto"
  },
  newAccount: {
    fontSize: "10px",
    padding: "6px 8px",
    color: "#fff",
    fontFamily: "OpenSans",
    fontWeight: "bold",
    background: "linear-gradient(90deg, rgba(255,119,0,1) 0%, rgba(255,196,0,1) 100%)"
  },
  myAccount: {
    fontSize: "10px",
    backgroundColor: "#0430D5",
    fontFamily: "OpenSans",
    fontWeight: "bold",
    padding: "6px 8px",
    color: "#fff",
    marginLeft: "5px",
    "&:hover": {
      backgroundColor: "#0430D5"
    }
  },
  user: {
    width: "10px",
    marginRight: "5px"
  },
  amount: {
    backgroundColor: "#070D29",
    borderRadius: "12px"
  },
  currency: {
    display: "inline-block",
    fontSize: "11px",
    fontWeight: "600",
    color: "#fff",
    opacity: ".58",
    margin: "11px 5px 10px 8px",
    textTransform: "uppercase"
  },
  value: {
    display: "inline-block",
    fontSize: "14px",
    fontWeight: "600",
    color: "#fff",
    margin: "7px 10px 10px 0px"
  },
  addWrapper: {
    display: "inline-block",
    margin: "6px 8px 0 0"
  },
  add: {
    width: "23px",
    height: "23px",
    backgroundColor: "#FFC400",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "23px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#fff",
    display: "block"
  },
  bilete: {
    color: "#fff",
    marginTop: "4px",
    marginRight: "17px",
    padding: "3px"
  },
  camera: {
    color: "#fff",
    marginTop: "4px",
    marginRight: "17px",
    padding: "3px"
  },
  avatar: {
    color: "#fff",
    marginTop: "4px",
    marginRight: "5px",
    padding: "3px"
  },
  block: {
    height: "100%"
  },
  label: {
    display: "block",
    fontSize: "10px",
    textTransform: "none"
  },
  pb: {
    marginBottom: "4px",
    height: "21px"
  },
  anchorOriginBottomLeftRectangular: {
    transform: "scale(1) translate(-50%, 0)",
    left: "0",
    bottom: "0",
    transformOrigin: "0% 100%"
  },
  badge: {
    height: "15px",
    display: "flex",
    padding: "0 5px 2px 3px",
    zIndex: "1",
    position: "absolute",
    flexWrap: "wrap",
    fontSize: "0.65rem",
    minWidth: "15px",
    boxSizing: "border-box",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    fontWeight: "500",
    lineHeight: "1",
    alignContent: "center",
    borderRadius: "10px",
    flexDirection: "row",
    justifyContent: "center"
  },
  topMenu: {
    position: "fixed",
    width: "100%",
    zIndex: "102",
    top: "0"
  },
  toolbarMargin: theme.mixins.toolbar
}));

const pagesWithMenu = ["jocuri", "jackpot", "table-games", "home", "test", "virtuale"];

const NavBar = props => {
  const classes = useStyles();
  const { t, auth, wallet, ticketsOpened, simplified, setMenuHeight, pageCode } = props;

  const history = useHistory();

  useEffect(() => {
    if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
      if (!simplified) {
        //nSoftAuth();
      }
    }
  }, [auth]);

  const [position, setPosition] = React.useState({
    topFrom: -100,
    topTo: 0,
    position: "fixed"
  });

  useEffect(() => {
    setMenuHeight(classes.toolbarMargin);
  }, []); // eslint-disable-line

  useEffect(() => {
    let topFrom = -100;
    let topTo = 0;

    if (!pagesWithMenu.includes(pageCode)) {
      topFrom = 0;
      topTo = -100;
    }

    if (position.topTo === topTo) {
      return;
    }
    setPosition({
      topFrom: topFrom,
      topTo: topTo,
      position: "fixed"
    });
  }, [position, pageCode]);

  const handleCallback = type => () => {
    if (type === "tickets") {
      window.location.hash = "#tickets-details";
    }
  };

  const handleOpenMenu = () => {
    props.openLeftMenu();
  };

  const loginHandler = () => {
    history.push("/connect");
  };

  const registerHandler = () => {
    history.push("/register");
  };

  const accountHandler = () => {
    props.setPageCode("menu");
    history.push("/profile/menu");
  };

  const handleDeposit = () => {
    props.isDeposit();
    props.setPageCode("deposit");
    history.push("/deposit");
  };

  const [openCheckModal, setOpenCheckModal] = React.useState(false);
  const handleCheckModalClose = () => {
    setOpenCheckModal(false);
  };

  const handleTicketCodeCheck = () => {
    setOpenCheckModal(true);
  };

  let options = (
    <React.Fragment>
      <div>
        <img src={logo} alt="" className={classes.logo} />
      </div>
      <div>
        <Button variant="contained" className={classes.newAccount} onClick={registerHandler}>
          {t("Register")}
        </Button>
        <Button variant="contained" className={classes.myAccount} onClick={loginHandler}>
          <img src={user} alt="" className={classes.user} /> {t("Sign-in")}
        </Button>
      </div>
    </React.Fragment>
  );
  if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
    options = (
      <React.Fragment>
        <div className={classes.amount}>
          <div className={classes.currency}>{formatCurrency(wallet.currency)}</div>
          <div className={classes.value}>{formatAmount(wallet.total, false, false)}</div>
          <div className={classes.addWrapper}>
            <ButtonBase className={classes.add} onClick={handleDeposit}>
              +
            </ButtonBase>
          </div>
        </div>
        <div>
          {!simplified && (
            <React.Fragment>
              <ButtonBase className={classes.bilete} onClick={handleCallback("tickets")}>
                <div>
                  <div className={classes.pb}>
                    <Badge
                      classes={{
                        badge: classes.badge,
                        anchorOriginBottomLeftRectangular: classes.anchorOriginBottomLeftRectangular
                      }}
                      color="secondary"
                      badgeContent={ticketsOpened.length}
                      anchorOrigin={{
                        horizontal: "left",
                        vertical: "bottom"
                      }}
                      className={classes.margin}
                    >
                      <img src={bilete} alt="" className={classes.block} />
                    </Badge>
                  </div>
                  <div className={classes.label}>{t("Tickets")}</div>
                </div>
              </ButtonBase>
              <ButtonBase className={classes.camera} onClick={handleTicketCodeCheck}>
                <div>
                  <div className={classes.pb}>
                    <img src={camera} alt="" className={classes.block} />
                  </div>
                  <div className={classes.label}>{t("Scan")}</div>
                </div>
              </ButtonBase>
            </React.Fragment>
          )}
          <ButtonBase className={classes.avatar} onClick={accountHandler}>
            <div>
              <div className={classes.pb}>
                <img src={avatar} alt="" className={classes.block} />
              </div>
              <div className={classes.label}>{t("Account")}</div>
            </div>
          </ButtonBase>
        </div>
        <TicketCheckModal openTicketCheck={openCheckModal} onModalClose={handleCheckModalClose} />
      </React.Fragment>
    );
  }

  const bgImage = { backgroundImage: `url(${bluePath})` };

  return (
    <Spring from={{ top: position.topFrom }} to={{ top: position.topTo }}>
      {springProps => (
        <div className={`${classes.root} ${simplified ? classes.topMenu : ""}`} style={springProps}>
          <div className="d-flex flex-row flex-nowrap">
            <div className={classes.filter}>
              <ButtonBase onClick={handleOpenMenu}>
                <img src={myFilter} alt="" />
              </ButtonBase>
            </div>
            <div className={classes.optionsRoot} style={bgImage}>
              <div className={classes.optionsWrapper}>
                <div
                  className={`${classes.options} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
                >
                  {options}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spring>
  );
};

NavBar.propTypes = {
};

NavBar.defaultProps = {
};

const mapStateToProps = state => ({
  auth: state.authentication,
  wallet: state.wallet,
  application: state.application,
  pageCode: state.application.pageCode,
  ticketsOpened: state.bets.tickets.ticketsOpened,
});

const mapDispatchToProps = dispatch => {
  return {
    setPageCode: pCode => dispatch(storeActions.setPageCode(pCode)),
    setMenuHeight: height => dispatch(storeActions.setTopMenuHeight(height)),
    toggleProfile: show => dispatch(storeActions.rightProfileSection(show)),
    openLeftMenu: () => dispatch(storeActions.openLeftMenu()),
    isDeposit: () => dispatch(storeActions.isDeposit(true))
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NavBar));
