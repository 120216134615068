import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import * as storeActions from "../../../../../store/actions";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "./PaymentMethods.scss";

import paysafe from '../../../../../assets/Images/Deposit/paysafe.png';
// import okto from '../../assets/Images/Deposit/oktocash.png';

export const PAYMENT_VIVA = '3';
export const PAYMENT_IBAN = '4';
export const PAYMENT_SKRILL = '5';
export const PAYMENT_SELECT = 1;

export const PAYMENT_ADD_IBAN = 9995;

const PAYMENT_AGENCIES_LEGACY = '1';
const PAYMENT_ONLINE_LEGACY = '3';

export const WITHDRAW_STEPS = {
    [PAYMENT_VIVA]: 3,
    [PAYMENT_IBAN]: 5,
    [PAYMENT_ADD_IBAN]: 6,
    [PAYMENT_SKRILL]: 7,
    [PAYMENT_SELECT]: 1,
}
class PaymentMethods extends Component {

    state = {
        paymentMethods: [],
    };

    componentDidMount() {
        this.props.getPaymentMethods();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currentStep !== prevProps.currentStep) {
            if (this.props.currentStep === WITHDRAW_STEPS[PAYMENT_SELECT]) {
                this.props.getPaymentMethods(); // refresh payment methods
            }
        }
        if (prevProps.paymentMethods !== this.props.paymentMethods) {
            this.generatePaymentMethods();
        }
    }

    generatePaymentMethods() {
        let payments = [];
        if (window.config.paymentMethodsActive === '1' && this.props.paymentMethods) {

            Object.keys(this.props.paymentMethods).forEach((key) => payments[this.props.paymentMethods[key].withdrawType] = 1);
            if (payments[PAYMENT_IBAN] !== 1) {
                if (window.config.withdrawIBANEnabled === '1') {
                    payments[PAYMENT_IBAN] = 1;
                }
            }
        }

        // legacy
        if (window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1') {
            if (window.config.withdrawLegacyEnabled === '1') {
                payments[PAYMENT_AGENCIES_LEGACY] = 1;
            }
        }

        // if (payments[PAYMENT_ONLINE_LEGACY] !== 1 ) {
        //     if (window.config.withdrawLegacyEnabled === '1') {
        //
        //         payments[PAYMENT_ONLINE_LEGACY] = 1;
        //     }
        // }

        this.setState({
            ...this.state,
            paymentMethods: payments
        });
    }

    renderPayments() {
        let buttons = [];
        let payments = this.state.paymentMethods;

        if (payments[PAYMENT_ONLINE_LEGACY] === 1) {
            if (window.config.withdrawLegacyEnabled === '1') {

                buttons.push(<Button
                    variant="contained"
                    disabled={this.props.buttonsDisabled}
                    className={'w-100 nextButtonOnline'}
                    onClick={() => this.props.legacyOnlineClickHandler()} key={'online-legacy-withdraw'}>
                    {this.props.t("cash-in online")}
                </Button>);
            }
        }
        if (payments[PAYMENT_SKRILL] === 1) {
            if (window.config.withdrawSkrillEnabled === '1') {
                buttons.push(<Button
                    variant="contained"
                    color={'primary'}
                    disabled={this.props.buttonsDisabled}
                    className={'w-100 nextButton'}
                    onClick={() => this.props.selectPaymentMethodStep(WITHDRAW_STEPS[PAYMENT_SKRILL])}
                    key={'skrill-withdraw'}>
                    <img src={paysafe} alt={'paysafe'} />
                </Button>);
            }
        }
        if (payments[PAYMENT_IBAN] === 1) {
            if (window.config.withdrawIBANEnabled === '1') {
                buttons.push(<Button
                    variant="contained"
                    disabled={this.props.buttonsDisabled}
                    className={'w-100 nextButton'}
                    onClick={() => this.props.selectPaymentMethodStep(WITHDRAW_STEPS[PAYMENT_IBAN])}
                    key={'iban-withdraw'}>
                    {this.props.t("Bank Transfer")}
                </Button>);
            }
        }
        if (payments[PAYMENT_AGENCIES_LEGACY] === 1) {
            if (window.config.withdrawLegacyEnabled === '1') {
                buttons.push(<Button
                    variant="contained"
                    disabled={this.props.buttonsDisabled}
                    className={'w-100 nextButton'}
                    onClick={() => this.props.legacyLocationClickHandler()} key={'agencies-legacy-withdraw'}>
                    {this.props.t("withdraw_available_funds_button_text")}
                </Button>);
            }
        }
        return buttons;
    }

    render() {
        return (
            <Grid item xs={12} className={'wa sBigButton'}>
                <Grid container>
                    {this.renderPayments()}
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setStep: (step) => dispatch(storeActions.setWithdrawWizardStep(step)),
        getPaymentMethods: () => dispatch(storeActions.getPaymentMethods()),
    };
};

const mapStateToProps = state => {
    return {
        paymentMethods: state.withdrawals.paymentMethods,
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentMethods)));
