import React from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Icons from '../../../assets/Icons/Icons';
import TitleBar from "../../UI/TitleBar/TitleBar";
import PageTitle from '../../UI/PageTitle/PageTitle';
import Button from '../../UI/Button/Button';
import "./AboutUs.scss";
import Translate from '../../../utils/Translate';
import ChatBubble from "../../GlobalWidgets/Both/LiveChatContainer/Parts/ChatBubble";

const AboutUs = (props) => {
    /**
     * help poedit to scan and find the text
     */
    const t = (string) => {
        return string;
    }

    const openChat = () => {
        document.getElementById('reamaze-widget').click();
    };
    const liveChatButton = [];
    const emailButton = [];
    const callUs = [];
    if(window.config && window.config.live_chat_contact_MOBILE) {
        liveChatButton.push(<div id={'live-chat-contact-button'} key={'livechat'}>
            <ChatBubble parentId={'live-chat-contact-button'} buttonId={window.config.live_chat_contact_MOBILE}/>
        </div>)
    } else {
        liveChatButton.push(<>
            <Button leftIcon={Icons.get('liveChatButton', `iconSettings`)} className="active"
                    disabled={!props.profile.chat.active} full value={props.t('LIVE CHAT')} click={openChat}/>
            <p>{!props.profile.chat.active ? props.t('Service not available') : ' ' + props.t('Average waiting time:') + ' 3 mins'}</p>
        </>);
    }
    /*if (window.config.brand_name_short.toLowerCase() === 'winner') {*/
        emailButton.push(
            <>
                <Button click={() => window.location.href = `mailto:${window.config.NOG_license_email}`} leftIcon={Icons.get('email', `iconSettings`)} className="active" full value={`${window.config.NOG_license_email}`} />
                <p>{props.t('Average waiting time:')} 24h - 48h</p>
            </>

        );
        callUs.push(
            <>
                <Button click={() => window.location.href = `tel:${window.config.NOG_license_phone_number}`} leftIcon={Icons.get('phone', `iconSettings`)} className="active" full value={props.t('CALL US')} />
                <p>{props.t("Available from 10:00 to 22:00")}</p>
            </>
        );
    /*} else {*/
        // emailButton.push(
        //     <>
        //         <Button click={() => props.history.push("/customer-support/complaint-form")} leftIcon={Icons.get('email', `iconSettings`)} className="active" full value={props.t('E-MAIL')} />
        //         <p>{props.t('Average waiting time:')} 24h - 48h</p>
        //     </>
        // )
        /*callUs.push(
            <>
                <Button leftIcon={Icons.get('phone', `iconSettings`)} className="active" disabled full value={props.t('CALL ME BACK')} />
                <p>{props.t('Service not available')}</p>
            </>
        )
    }*/
    return (
        <div className="AboutUsScreen">
            <TitleBar pageTitle={props.t('Contact')} />
            <div className="Banner">
                {Icons.get('customerSupportLogo', `customerSupportLogo`)}
                <div className="BannerDescription">
                    <p>{props.t('If you need any help, do not hesitate to get in touch with us using one of the methods below.')}</p>
                </div>
                <div className="ActionButtons">
                    {liveChatButton }
                    {emailButton}
                    {callUs}
                    {/* <p>{props.t('Average waiting time:')} 5 mins</p> */}
                </div>
            </div>
            <div className={"AboutUsContent"}>
                <PageTitle icon={Icons.get('default', `iconSettings`)} title={props.t('About Us')} />
                <div className="pageContent">
                    <p>
                        <Translate text={props.t("This website is operated by ${NOG_company_name}, registered in ${NOG_country}  with number ${NOG_company_cui}  at the address listed below. ${NOG_company_name} operates under license number ${NOG_license_number}, issued by the Romanian Gambling Office (ONJN), valid from ${NOG_license_date_start} until ${NOG_license_date_end}.")}
                                   placeholder={{
                                       NOG_company_cui: window.config.NOG_company_cui ?? process.env.CFW.company.register_number,
                                       NOG_company_name: window.config.NOG_company_name ?? process.env.CFW.company.name,
                                       NOG_country: window.config.NOG_country ?? '',
                                       NOG_license_date_start: window.config.NOG_license_date_start,
                                       NOG_license_number: window.config.NOG_license_number,
                                       NOG_license_date_end: window.config.NOG_license_date_end
                                   }} />
                    </p>
                    <p>
                        <Translate text={props.t("Gambling is prohibited under age of 18. Gambling might be prohibited in your area. It is the player’s responsibility to comply with the law regulations.")} />
                    </p>
                    <p><Translate text={props.t("Gambling may become addictive and impact your financials. Read about responsible gaming")} placeholder={false} />&nbsp;<span onClick={() => props.history.push("/customer-support/responsible-gaming")}>{props.t("here")}</span>.</p>
                    <p><strong>{props.t("Address:")}</strong><br />{`${window.config.NOG_company_address ?? process.env.CFW.company.address}`}</p>
                    <p><strong>{props.t("Phone number:")}</strong><br />{`${window.config.NOG_license_phone_number}`}</p>
                    <p><strong>{props.t("Email address:")}</strong><br />{`${window.config.NOG_license_email}`}</p>
                    {/* <p>{props.t("If you want to post a complaint, please click")}&nbsp;<span onClick={() => props.history.push("/customer-support/complaint-form")}>{props.t("here")}</span>.&nbsp;{props.t("If we are unable to solve your complaint within 14 days, you will have the right to contact the National Gambling Office (ONJN).")}</p> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

export default withTranslation()(connect(mapStateToProps, null)(withRouter(AboutUs)));
