import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';

export function* sendComplaintFormSaga(action) {
    const axios = ClientAPI.getInstance();
    let name = action.name;
    let email = action.email;
    let subject = action.subject;
    let body = action.body;

    try {
        const response = yield axios({
            url: paths.customer_support.SEND_COMPLAINT_FORM,
            method: 'post',
            data: {
                conversation: {
                    subject: subject,
                    category: "support",
                    tag_list: [
                        'complaint',
                        new Date().getTime().toString(36).toUpperCase(), // It needs to be second on the list.
                        window.config.platformType === 1 ? 'web' : 'mobile',
                        window.config.brand_name_short,
                    ],
                    message: {
                        body: body,
                    },
                    user: {
                        name: name,
                        email: email,
                    }
                }

            }
        });

        if (!response) {
            throw new Error(`[ERROR] Send Complaint Form response is empty!`);
        }

        if (!response.hasOwnProperty('data')) {
            throw new Error(`[ERROR] Send Complaint Form response has no 'data' property`);
        }

        yield put(actions.complaintFormResponse(response.data));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestGameRulesSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.customer_support.REQUEST_GAME_RULES,
            method: 'post',
        });

        if (!response) {
            throw new Error(`[ERROR] Request Game Rules response is empty!`);
        }

        if (!response.hasOwnProperty('groups')) {
            throw new Error(`[ERROR] Request Game Rules response has no 'groups' property`);
        }

        yield put(actions.receivedGameRules(response.items, response.groups));
    } catch (error) {
        console.log(error);
        return;
    }
}
