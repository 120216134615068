import React, { Component } from 'react';
import i18next from 'i18next';
import connect from "react-redux/es/connect/connect";
import Address from "../Form/Address/Address";
import CNP from "../Form/CNP/CNP";
import FirstName from "../Form/FirstName/FirstName";
import LastName from "../Form/LastName/LastName";
import Citizenship from "../Form/Citizenship/Citizenship";
// import CheckboxInput from "../Form/CheckboxInput/CheckboxInput";
import * as storeActions from "../../../store/actions";
import { withTranslation } from "react-i18next";
import { Checkbox, FormControlLabel, FormHelperText, FormControl } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Translate from '../../../utils/Translate';
// import Menus from "../../Utils/Menus/Menus";

class PersonalDetails extends Component {
    constructor(props) {
        super(props);

        this.y18Id = "checkbox" + Math.floor((Math.random() * 9999) + 1);
        this.termsId = "checkbox" + Math.floor((Math.random() * 9999) + 1);
        this.marketingAgreementId = "checkbox" + Math.floor((Math.random() * 9999) + 1);
    }

    state = {
        y18: {
            checked: false,
            message: '',
        },
        terms: {
            checked: false,
            message: '',
        },
        marketingAgreement: {
            checked: false,
            message: '',
        }
    };

    componentDidUpdate(prevProps) {
        // if (this.props.checkboxError !== prevProps.checkboxError) {
        //     let y18 = document.getElementById(this.y18Id).checked;
        //     let terms = document.getElementById(this.termsId).checked;
        //     let marketingAgreement = document.getElementById(this.marketingAgreementId).checked;
        //
        //     if (this.props.checkboxError) {
        //
        //         if (!y18) {
        //             y18 = {
        //                 type: "error",
        //                 text: this.props.t("You must be 18 years old"),
        //             };
        //         } else {
        //             y18 = {};
        //         }
        //         if (!terms) {
        //             terms = {
        //                 type: "error",
        //                 text: this.props.t("You must agree with terms and conditions"),
        //             }
        //         }
        //         if (!marketingAgreement) {
        //             marketingAgreement = {
        //                 type: "error",
        //                 text: this.props.t("You must agree with privacy policy"),
        //             }
        //         }
        //         this.setState({
        //             ...this.state,
        //             y18: {
        //                 ...this.state.y18,
        //                 message: {
        //                     ...this.state.y18.message,
        //                     ...y18,
        //                 }
        //             },
        //             terms: {
        //                 ...this.state.terms,
        //                 message: {
        //                     ...this.state.terms.message,
        //                     ...terms
        //                 }
        //             },
        //             marketingAgreement: {
        //                 ...this.state.marketingAgreement,
        //                 message: {
        //                     ...this.state.marketingAgreement.message,
        //                     ...marketingAgreement
        //                 }
        //             }
        //         })
        //     }
        // }
    }

    toggleCheckbox(e) {
        let state = { ...this.state };
        state[e.target.value] = {
            checked: e.target.checked,
            message: e.target.checked ? '' : this.props.t('This field is mandatory')
        };

        this.setState(state);

        // if (e.target.value !== 'marketingAgreement') {
            this.props.onChange({ field: e.target.value, value: e.target.checked, validated: e.target.value !== 'marketingAgreement' ? e.target.checked : false });
        // }
    }
    t(text) {
        return text;
    }
    
    handlePdfOpen = (e, link) => {
        if (e && typeof e.preventDefault === "function") e.preventDefault();
        if (window.config.cordova) {
            window.cordova.InAppBrowser.open(link, '_system');
            return;
        }

        let win = window.open(link, '_blank');
        if (win !== null) {
            win.focus();
        }
    }
    
    render() {
        let tcLink = "https://" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        const y18 = (
            <div>{this.props.t("I am older than 18 years")}</div>
        );
        const marketingAgreement = (
            <div>
                <Translate text={this.t("I want to receive bonuses and marketing materials that are relevant to me.")} />
            </div>
        );
        const privacyPolicyUrl = window.location.protocol + "//" + window.config.cdn_address + '/documents/privacyPolicy/' + i18next.language + '/Privacy%20Policy.pdf';

        const terms = (
            <div>
                <Translate
                    text={this.t("I read and I agree with ${linkStartTC}T&C${linkEnd} and ${linkStartGDPR}Privacy Policy${linkEnd}.")}
                    placeholder={
                        {
                            linkStartTC: '<a href="' + tcLink + '" target="_blank">',
                            linkStartGDPR: `<a href="${privacyPolicyUrl}" target="_blank">`,
                            linkEnd: '</a>',
                        }
                    }
                />
            </div>
        );
        return (
            <div>
                <Citizenship />
                <FirstName />
                <LastName />
                <Address />
                <CNP />
                {/*<RefferenceCode/>*/}
                <div className="CheckboxArea">
                    <FormControl>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.y18.checked} onChange={this.toggleCheckbox.bind(this)} value="y18" />}
                            label={y18}
                        />
                        {(!this.state.y18.checked && this.state.y18.message !== '') && <FormHelperText error className={'CheckBoxError'}>{this.state.y18.message}</FormHelperText>}
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.marketingAgreement.checked} onChange={this.toggleCheckbox.bind(this)} value="marketingAgreement" />}
                            label={marketingAgreement}
                        />
                    </FormControl>
                    <FormControl>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.terms.checked} onChange={this.toggleCheckbox.bind(this)} value="terms" />}
                            label={terms}
                        />
                        {(!this.state.terms.checked && this.state.terms.message !== '') && <FormHelperText error className={'CheckBoxError'}>{this.state.terms.message}</FormHelperText>}
                    </FormControl>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered(fieldInput)),
        handleCheckboxesErrors: () => dispatch(storeActions.checkboxesErrors()),
    };
};

const mapStateToProps = state => {
    return {
        // validated: state.registerWizard.validated,
        checkboxError: state.register.errors.checkbox,
        currentStep: state.register.currentStep,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PersonalDetails));
