import React, { Component, useEffect } from 'react';
import './MobileAppBanner.scss';
import ClientDetection from "../../utils/ClientDetection";
import Icons from "../../assets/Icons/Icons";
import DinamicImage from "../UI/DinamicImage/DinamicImage";

class MobileAppBanner extends Component {

    constructor(props) {
        super(props);
        this.state.closed = window.sessionStorage.getItem('hideAppBanner');
        this.type = this.getBannerType();
        if (this.type) {
            this.opened();
        }
    }

    state = {
        closed: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.closed !== prevState.closed) {
            if (this.state.closed) {
                this.closed();
            } else {
                this.opened();
            }
        }
    }

    componentWillUnmount() {
        this.closed();
    }

    opened() {
        let el = document.getElementById('FloatingActions');

        if (el) {
            if (!el.classList.contains('with-mobilebanner')) {
                el.className += 'with-mobilebanner';
            }
        }
    }
    closed() {
        let el = document.getElementById('FloatingActions');

        if (el) {
            if (el.classList.contains('with-mobilebanner')) {
                el.className = el.className.replace('with-mobilebanner', '');
            }
        }
    }

    getBannerType() {
        let type = ClientDetection.getOS().name.toLowerCase();

        if (type === 'android') {
            if (ClientDetection.isHuawei()) {
                let ms = ClientDetection.getMobileServicesType();
                if (ms === 'HMS') {
                    type = 'huawei';
                }
            }
        }
        if (window.config && window.config["img_banner_" + type] && window.config["link_banner_" + type]
            && window.config["link_name_banner_" + type] && window.config["rank_banner_" + type]
            && window.config["text_L1_banner_" + type] && window.config["text_L2_banner_" + type]
            && window.config["text_L3_banner_" + type] && window.config["background_banner_" + type]
            && window.config["text_L4_banner_" + type] && window.config["background_down_banner_" + type]) {
            return type;
        }
        return false;
    }

    onClickHandler() {
        window.sessionStorage.setItem('hideAppBanner', 1);
        this.setState({
            ...this.state,
            closed: true
        })
    }

    getRating() {

        let i = parseInt(window.config["rank_banner_" + this.type]);
        let decimals = parseFloat(window.config["rank_banner_" + this.type]) % i * 10;
        let difference = 5 - i - (decimals ? 1 : 0);
        let rating = [];

        while (i > 0) {
            rating.push(Icons.get('star-full', null, i));
            i--;
        }
        if (decimals) {
            rating.push(Icons.get('star-half', null, i))
        }
        for (let i = 0; i < difference; i++) {
            rating.push(Icons.get('star-empty', null, i));
        }
        return (<div className="rating">{rating}</div>)
    }

    render() {
        if (!this.type || this.state.closed) {
            return null;
        }
        return (
            <div id={'MobileAppBanner'}>
                <div className="wrapper" style={{ background: window.config["background_banner_" + this.type] }}>
                    <div className="close" onClick={this.onClickHandler.bind(this)}>
                        {Icons.get('closeIcon')}
                    </div>
                    <div className="inner">
                        <div className="image">
                            <DinamicImage imgLink={window.config['img_banner_' + this.type]} />
                        </div>
                        <div className="details">
                            <div className="title">{window.config["text_L1_banner_" + this.type]}</div>
                            <div className="category">{window.config["text_L2_banner_" + this.type]}</div>
                            {this.getRating()}
                            <div className="description">{window.config["text_L3_banner_" + this.type]}</div>
                        </div>
                    </div>
                    <a href={window.config["link_banner_" + this.type]} className={'open-app'}>{window.config["link_name_banner_" + this.type]}</a>
                </div>
                <div className="second-description" style={{ background: window.config["background_down_banner_" + this.type] }}>
                    {window.config["text_L4_banner_" + this.type]}
                </div>
            </div>
        );
    }
}

export default (MobileAppBanner);
