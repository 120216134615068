import React, {Component} from "react";
import AvailableFunds from "./AvailableFunds";
import Locations from "../../../Both/Locations/Locations";
import ConfirmStep from "./ConfirmStep";
import {connect} from "react-redux";
import PaymentMethods from "./PaymentMethods";
import BankTransfer from "./BankTransfer/BankTransfer";
import AddBankTransfer from "./BankTransfer/AddBankTransfer";
import SkrillAccount from "./Skrill/SkrillAccount";


class Steps extends Component {


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.step !== this.props.step) {
            document.getElementById('withdraw-widget').scrollTo(0, 0);
            this.props.goToStep(this.props.step);
        }
    }

    clickHandler() {

    }

    render() {

        const AllComponents = {
            AvailableFunds: AvailableFunds,
            Locations: Locations,
            ConfirmStep: ConfirmStep,
            PaymentMethods: PaymentMethods,
            BankTransfer: BankTransfer,
            AddBankTransfer: AddBankTransfer,
            SkrillAccount: SkrillAccount,
        };
        const Type = AllComponents[this.props.name];
        if (!Type) {
            return ;
        }
        return (
            <div>
                <Type nextStep={this.props.nextStep} currentStep={this.props.currentStep} goToStep={this.props.goToStep}/>
                {/*<WizardFooter nextStep={this.props.nextStep} currentStep={this.props.currentStep} goToStep={this.props.goToStep} />*/}
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {

    };
};

const mapStateToProps = state => {
    return {
        step: state.withdrawWizard.step
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
