import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as storeActions from '../../../../store/actions/index';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '../../../UI/Button/Button';
import * as EmailValidator from 'email-validator';
import './EmailConfirmation.scss';
import { CircularProgress } from '@material-ui/core';

const EmailConfirmation = (props) => {
	const [email, setEmail] = useState('');
	const [hasErrors, setHasErrors] = useState(true);
	const [error, setError] = useState('');
	const [emailSent, setEmailSent] = useState(false);

	useEffect(() => {
		if (!props.resetPassword.loading && props.resetPassword && email) {
			if (props.resetPassword.token) {
				setEmailSent(true);
			}
		}

		if (!props.resetPassword.loading && props.resetPassword.errorMessage !== '') {
			setError(props.resetPassword.errorMessage);
			setHasErrors(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.resetPassword.loading]);


	useEffect(() => {
		getToken();

		return () => {
			setEmailSent(false);
			setHasErrors(false);
			setError('');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getToken = () => {
		let token = window.location.pathname.replace("/reset-password/","");
		if (token !== "undefined" && token !== '' && token.length > 30) {
			props.setToken(token);
			props.nextStep(2);
		} else {
			return;
		}
	}

	const validateEmailHandler = () => {
		if (!EmailValidator.validate(email)) {
			setError(props.t('Invalid email'));
			setHasErrors(true);
		} else {
			setError('');
			setHasErrors(false);
		}
	};

	const handleChange = (e) => {
		setEmail(e.target.value.trim().split(' ').join(''));
		validateEmailHandler();
	};
	
	const handleKeyDown = e => {
		if (e.keyCode === 32) {
			e.preventDefault();
		}
	};
	
	const sendEmailHandler = () => {
		props.onSendEmail(email);
	};

	const getEmailSent = () => {
		let message = '';
		if(emailSent){
			message = (
				<div className="SuccessMessage">
					<h2>{props.t('We sent you an email with the link to change password')}</h2>
				</div>
			);
		}
		return message;
	};

	return (
		<div className={'EmailConfirmationScreen'}>
			<FormControl className='EmailInput'>
				<InputLabel htmlFor='CurrentEmailField' error={hasErrors && error !== ''}>
					{props.t('Email')}
				</InputLabel>
				<Input
					id='CurrentEmailField'
					type={'email'}
					value={email}
					onKeyDown={handleKeyDown}
					onChange={handleChange}
					onBlur={validateEmailHandler}
					error={hasErrors && error !== ''}
				/>
				<FormHelperText error={hasErrors && error !== ''}>
					{error ? error : props.t('Type in the field the email address associated with the account')}
				</FormHelperText>
			</FormControl>
			<p className={'EmailText'}>{props.t('You will receive a link to the email associated with the account. You can reset your password from it.')}</p>
			{getEmailSent()}
			<Button
				className={'active'}
				disabled={hasErrors || props.resetPassword.loading}
				full
				click={sendEmailHandler}
				value={props.resetPassword.loading ? (<CircularProgress className='Loading' />) : (props.t('Send email'))}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		resetPassword: state.resetPassword,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setToken: (token) => dispatch(storeActions.resetPasswordSetToken(token)),
		onSendEmail: (email) => dispatch(storeActions.resetPasswordSendEmail(email)),
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation));
