import * as actionTypes from "./actionTypes";

export const requestJackpotDetails = () => ({
	type: actionTypes.jackpot.REQUEST_DETAILS
});

export const receivedJackpotDetails = data => ({
	type: actionTypes.jackpot.RECEIVED_DETAILS,
	data
});

export const requestJackpotLatestWinners = () => ({
	type: actionTypes.jackpot.REQUEST_LATEST_WINNERS
});

export const receivedJackpotLatestWinners = data => ({
	type: actionTypes.jackpot.RECEIVED_LATEST_WINNERS,
	data
});

export const requestJackpotLatestJackpotWinners = () => ({
	type: actionTypes.jackpot.REQUEST_LATEST_JACKPOT_WINNERS
});

export const receivedJackpotLatestJackpotWinners = data => ({
	type: actionTypes.jackpot.RECEIVED_LATEST_JACKPOT_WINNERS,
	data
});

export const startJackpotFetchCycle = () => ({
	type: actionTypes.jackpot.START_FETCH_CYCLE
});

export const incJackpotRefCount = () => ({
	type: actionTypes.jackpot.INC_REF_CNT
});

export const decJackpotRefCount = () => ({
	type: actionTypes.jackpot.DEC_REF_CNT
});

export const updateLatestWinnersLastFetchTime = time => ({
	type: actionTypes.jackpot.UPDATE_LATEST_WINNERS_LAST_FETCH_TIME,
	time
});

export const updateLatestJackpotWinnersLastFetchTime = time => ({
	type: actionTypes.jackpot.UPDATE_LATEST_JACKPOT_WINNERS_LAST_FETCH_TIME,
	time
});
