import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import StepWizard from "react-step-wizard";
import './RegisterV2.scss';
import { withTranslation } from "react-i18next";
import RegisterBonus from "./RegisterBonus/RegisterBonus";
import TitleBar from "../../UI/TitleBar/TitleBar";
import Utils from "../../../utils/Utils";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import * as storeActions from "../../../store/actions";
import { DRUID_CONFIDENCE, scanOk } from "../../DruidScanner/DruidScanner";
import * as EmailValidator from "email-validator";
import PhoneNumberValidator from "../../../utils/PhoneNumberValidator";
import Icons from "../../../assets/Icons/Icons";
import { DialogTemplate } from "../../UI/Dialog/DialogTemplate";
import ExpiringLocalStorage from "../../../utils/ExpiringLocalStorage";
import Step5 from "./Steps/Step5";
import { withRouter } from "react-router-dom";
import { getStyle, styleToObj } from "../../../lobby/utils/functions";

class RegisterV2 extends Component {

    constructor(props) {
        super(props);
        this.winnerFun = Utils.getQueryParam('winner_fun') === 'true';
        this.moid = Utils.getQueryParam('moid');
        if (this.moid) {
            ExpiringLocalStorage.set('moid', this.moid);
            this.props.onChange({ field: 'moid', value: this.moid, validated: true })

        }
        this.lpid = Utils.getQueryParam('lpid');
        if (this.lpid) {
            ExpiringLocalStorage.set('lpid', this.lpid);
            this.props.onChange({ field: 'lpid', value: this.lpid, validated: true })

        }
        this.paid = Utils.getQueryParam('paid');

        if (this.paid) {
            if (this.paid !== ExpiringLocalStorage.get('paid')) {
                ExpiringLocalStorage.set('paid', this.paid);
                window.localStorage.removeItem('registerFields');
                this.props.onChange({ field: 'paid', value: this.paid, validated: true })

            }
        }
    }

    state = {
        closeDialog: false,
        signUpErrors: false,
        signedUp: false,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.druid !== this.props.druid) {
            if (scanOk(this.props.druid)) {
                if (this.props.druid.Data["Nume.Confidence"] >= DRUID_CONFIDENCE) {
                    this.props.onChange({ field: 'lastName', value: this.props.druid.Data.Nume, validated: true })
                }
                if (this.props.druid.Data["Prenume.Confidence"] >= DRUID_CONFIDENCE) {
                    this.props.onChange({ field: 'firstName', value: this.props.druid.Data.Prenume, validated: true })
                }
                if (this.props.druid.Data["CNP.Confidence"] >= DRUID_CONFIDENCE) {
                    this.props.onChange({ field: 'nin', value: this.props.druid.Data.CNP, validated: true })
                }
                if (this.props.druid.Data["Domiciliu.Confidence"] >= DRUID_CONFIDENCE) {
                    this.props.onChange({ field: 'address', value: this.props.druid.Data.Domiciliu, validated: true })
                }
                this.props.onChange({ field: 'ocr_scan', value: this.props.druid.ResultId, validated: true });
            } else {
                this.props.onChange({ field: 'ocr_scan', value: '', validated: true });
            }
        } else {
            if (prevProps.signedUp !== this.props.signedUp) {
                // this.props.goToStep(this.props.currentStep+1);
                if (this.props.signedUp) {
                    // this.props.history.push('/');

                    this.setState({
                        ...this.state,
                        signUpErrors: false,
                        signedUp: true
                    })
                } else {
                    this.goToStepHandler(4);
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.resetRegister(false);

    }

    componentDidMount() {
        const email = Utils.getQueryParam('email');
        const phone = Utils.getQueryParam('phone');
        // autocomplete email from parameters
        if (email) {
            this.props.onChange({ field: 'email', value: email, validated: EmailValidator.validate(email) });
        }
        if (phone) {
            this.props.onChange({ field: 'phone', value: phone, validated: PhoneNumberValidator.validate(phone) })
        }
        let savedState = ExpiringLocalStorage.get('registerFields');
        if (savedState) {
            savedState = JSON.parse(savedState);

            if (savedState) {
                // autocomplete state if no email was set
                // or if email is exactly like it was stored in localstorage
                if (!email || email === savedState.email) {
                    Object.keys(savedState).forEach((key, value) => {
                        if (savedState[key]) {
                            this.props.onChange({ field: key, value: savedState[key], validated: true })
                        }
                    })
                } else {
                    // otherwise remove info saved in localstorage
                    window.localStorage.removeItem('registerFields')
                }
            }
        }
    }

    showWinnerFun = () => {
        return this.winnerFun && window.config && window.config.winnerFunEnabled === "1";
    };
    backHandler = () => {
        let phoneValidation = ExpiringLocalStorage.get('phoneValidation');
        let phoneStep = false;

        if (phoneValidation) {
            try {
                phoneValidation = JSON.parse(phoneValidation);
                if (phoneValidation.phone === this.props.phone) {
                    phoneStep = phoneValidation.step;
                }
            } catch (e) { }
        }

        let nextStep = this.props.currentStep - 1;

        if (phoneStep) {
            if (parseInt(phoneStep) === nextStep) {
                nextStep = nextStep - 1;
            }
        }
        this.goToStepHandler(nextStep);
    }
    getTitleBar() {
        return (
            <TitleBar backButton={this.props.currentStep !== 1}
                image={"https://" + window.config.cdn_address + window.config.MOBILE_BRAND_LOGO}
                pageTitle={false}
                backHandler={() => this.backHandler()}
                closeButton={true}
                closeHandler={() => this.goToStepHandler(0)}
                className={!this.state.signedUp ? "big" : ""}
            />
        );

    }

    goToStepHandler(step) {
        if (step === 0) {
            this.setState({
                closeDialog: true
            })
        } else if (this.props.currentStep > step) {
            this.props.setStep(step);
        }
    }

    getCloseModal() {
        return (
            <DialogTemplate
                open={this.state.closeDialog}
                icon={Icons.get('remove')}
                title={this.props.t("Quit registration?")}
                className={`register-modal`}
                actions={[
                    {
                        text: this.props.t('Quit'),
                        className: "error ghost",
                        onClick: () => {
                            this.props.setStep(1);
                            this.props.history.push('/')
                        },

                    },
                    {
                        text: this.props.t("Continue"),
                        onClick: () => { this.setState({ ...this.state, closeDialog: false }) }
                    }
                ]}
            >
                <div>{this.props.t("Are you sure you want to give up on the progress made so far?")}</div>

            </DialogTemplate>
        )
    }

    render() {
        if (window.config && window.config.register_disabled === '1') {
            return (
                <div className="registerV2">
                    {this.getTitleBar()}
                    <div className={"content register-disabled"} >
                        {this.props.t("Register is temporarily unavailable")}
                    </div>
                </div>
            );
        }

        let formStyle = {};
        if (this.props.marketingOffer && this.props.marketingOffer.ui_elements) {
            try {
                let elements = JSON.parse(this.props.marketingOffer.ui_elements);
                if (elements.container_style) formStyle = styleToObj(getStyle(elements, "container_style", {}));
            } catch (err) {/* noop */ }
        }

        return (
            <div className="registerV2"  style={formStyle}>
                {this.getTitleBar()}
                <RegisterBonus moid={this.moid} />
                <div className="content">
                    <div className="form" id={"register"}>
                        <StepWizard>
                            <Step1 />
                            <Step4 />
                            <Step2 />
                            <Step3 />
                            <Step5 />
                        </StepWizard>
                    </div>
                </div>
                <div className="clearfix"> </div>
                {this.getCloseModal()}
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        druid: state.druid.data,
        signedUp: state.register.signedUp,
        // signedUpErrors: state.register.signedUpErrors,
        currentStep: state.register.currentStep,
        username: state.register.fields.email,
        password: state.register.fields.password,
        phone: state.register.fields.phone,
        marketingOffer: state.marketingOffer.marketingOffer,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered(fieldInput)),
        setStep: (step) => dispatch(storeActions.setCurrentStep(step)),
        resetRegister: (resetLocalstorage) => dispatch(storeActions.resetRegister(resetLocalstorage))
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterV2)));
