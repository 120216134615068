import React, {useEffect, useRef, useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {withRouter, Link} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import connect from "react-redux/es/connect/connect";
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import IconWrong from '@material-ui/icons/HighlightOff';
import IconCorrect from '@material-ui/icons/CheckCircle';
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import IconNext from '@material-ui/icons/TrendingFlat';
import * as storeActions from "../../../store/actions";
import {Trail, animated} from 'react-spring/renderprops';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    margin: {
        margin: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
});

const Username = (props) => {
    const classes = props.classes;
    const {t} = useTranslation();
    const inputElementRef = useRef(null);
    const formElementRef = useRef(null);
    const stepNumber = 1;

    const [values, setValues] = useState({
        focused: false,
        fTimer: 0,
        after: true,
        buttonDisabled: true
    });

    useEffect(() => {

        // on componentDidMount focus input field
        clearTimeout(values.fTimer);
        let fTimer = setTimeout(focusField, 1800);
        setValues({ ...values, fTimer: fTimer, focused: true });
        // inputElementRef.current.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        // if (props.login.allowUsernameButton && values.buttonDisabled) {
        //     props.onUsername(props.login.username);
        //     setValues({...values, buttonDisabled: !props.login.allowUsernameButton});
        // }

        if (!props.isActive) {
            return;
        }

        if (values.focused) {
            return;
        }

        if (values.after) {
            return;
        }


        clearTimeout(values.fTimer);
        let fTimer = setTimeout(focusField, 1000);
        setValues({ ...values, focused: true, fTimer: fTimer });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isActive, values]);

    const focusField = () => {
        if (!inputElementRef.current) {
            clearTimeout(values.fTimer);
            return;
        }

        if (null === props.login.username) {
            if (localStorage.getItem('username')) {
                props.onUsername(localStorage.getItem('username'));
                return;
            }
        }
        return;
        // inputElementRef.current.click();
        // setTimeout(() => {
        //     if (!props.isActive) {
        //         return;
        //     }
        //
        //     if(!inputElementRef.current) {
        //         return;
        //     }
        //
        //     inputElementRef.current.focus();
        // }, 200);
        // setValues({ ...values, after: false});
    };

    const nextButtonHandler = (event) => {
        props.nextStep();
        props.changeStep(2);
        setValues({ ...values, focused: false });
    };

    const usernameHandler = (event) => {
        let username = event.target.value;
        if (!username) {
            username = "";
        }

        props.onUsername(username);
    };

    const clearInputHandler = (event) => {
        props.onUsername("");
        inputElementRef.current.focus();
    };

    let usernameValue = "";
    if (props.login.username) {
        usernameValue = props.login.username;
    }


    if (props.login.allowUsernameButton && values.buttonDisabled) {
        setValues({...values,buttonDisabled: false});
    }

    let error = false;
    if (props.login.errorUsername) {
        error = true;
    }

    let clearIcon = <IconWrong className={"normal"} />;
    if (error) {
        clearIcon = <IconWrong className={"error"} />;
    }

    let supportClassName = ["usernameSupport"];
    if (!error && !values.buttonDisabled) {
        clearIcon = <IconCorrect className={"success"} />
        supportClassName.push("success");
    }

    const enterKeyHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!inputElementRef.current) {
            return;
        }

        if (!(stepNumber === props.currentStep)) {
            return;
        }

        if (values.buttonDisabled) {
            return;
        }

        nextButtonHandler(event);
    };

    const items = [
        <div className={"inputArea"} key={"fu1"}>
            <form className={classes.container} noValidate autoComplete="off" ref={formElementRef} onSubmit={enterKeyHandler}>
                <PersonIcon className={"loginFieldLeftIcon"} />
                <TextField
                    error={error}
                    label={t("E-mail or Phone Number")}
                    id={"username"}
                    inputRef={inputElementRef}
                    className={`${classes.textField} usernameField`}
                    onChange={ usernameHandler }
                    value={usernameValue}
                    helperText={props.login.errorUsername ? t("Invalid username or password") : ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    aria-label="Username status"
                                    onClick={ clearInputHandler }
                                    className={"clearInputUsername"}
                                >
                                    { clearIcon }
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </form>
        </div>,
        <div className={"resetPasswordSupport"} key={"fu2"}>
            <Link to={"/reset-password"}>{ t('Forgot password?') }</Link>
        </div>,
        <div className={"nextSupport"} key={"fu3"}>
            <Button disabled={!props.login.allowUsernameButton} variant="contained" size="large" color="primary" className={classes.margin} onClick={ nextButtonHandler }>
                { t("NEXT") }
                <IconNext className={classes.rightIcon}/>
            </Button>
        </div>,
        <div className={"toRegister"} key={"fu4"}>
            <Link to={"/register"}>{ t('No account yet?') }</Link>
        </div>
    ];

    return (
        <div className={"usernameStep"}>
            <div className={supportClassName.join(" ")}>

                <Trail items={items} from={{top: 300, opacity: 0}} to={{ top: 0, opacity: 1 }} keys={items.map((_, i) => i)} delay={300}>
                    { item => animProps => <animated.div style={animProps} className={"item"}>
                            { item }
                    </animated.div> }
                </Trail>

            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        login: state.login,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUsername: (username) => dispatch(storeActions.loginUsername(username)),
        changeStep: (stepNumber) => dispatch(storeActions.loginStep(stepNumber)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Username))));
