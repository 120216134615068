import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import * as paths from '../../ClientAPI/paths';

export function* requestTransactionsHistorySaga(action) {
    const axios = ClientAPI.getInstance();
    const storeState = ClientAPI.getStore().getState();
    let requestType = storeState.transactions.requestType;

    try {
        const response = yield axios({
            url: paths.transactions.TRANSACTIONS_HISTORY,
            method: 'post',
            data: {
                type: requestType,
                responseType: 'transactions-history',
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Transactions History response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Transactions History response has no 'result' property`);
        }

        yield put(actions.receivedTransactionsHistory(response));
    } catch (error) {
        console.log(error);
        return;
    }
}