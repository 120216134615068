import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Icons from '../../../../../assets/Icons/Icons';
import PageTitle from '../../../../UI/PageTitle/PageTitle';
import './Loss.scss';

class Loss extends Component {

    render() {

        const icons = {
            loss: Icons.get('default', `iconSettings`),
        };
        
        return (
            <div className={"LossScreen"}>
                <PageTitle icon={icons.loss} title={this.props.t('Loss')} subtitle={this.props.t('Coming Soon')} />
            </div>
        );
    }
}

export default withTranslation()(Loss);