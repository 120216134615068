import React, {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import './ClaimBonus.scss';
import Icons from "../../../src/assets/Icons/Icons";
import {connect} from "react-redux";
import * as storeActions from "../../store/actions";
import BonusWidget from "../GlobalWidgets/LoggedIn/Bonus/BonusWidget";
import TitleBar from "../UI/TitleBar/TitleBar";
import {CircularProgress} from "@material-ui/core";
import Utils from "../../utils/Utils";
import ClaimPopup from "./ClaimPopup/ClaimPopup";
import BonusAvailableWidget from "../GlobalWidgets/LoggedIn/Bonus/BonusAvailableWidget";

const ClaimBonus = (props) => {

    const { t } = useTranslation();

    const [bonusCounter, setBonusCounter] = useState(0);
    const [bonus,setBonus] = useState(false);
    const [loadingPage,setLoadingPage] = useState(false);
    const [selectedBonus,setSelectedBonus] = useState(false);
    const [bonusId, setBonusId] = useState(false);
    useEffect(() => {
        if (props.availableBonuses) {
            setBonusCounter(Object.keys(props.availableBonuses).length)

            // props.bonusClaimed([]);
            // props.bonusClaimed({ResponseCode: 1})
            // props.bonusClaimed({details: {canRetry: false,amount: 200}});
            // props.bonusClaimed({details: {canRetry: true,amount: 200}});
        } else {
            if (!bonus) {
                // props.history.push('/');
            }
        }
    },[props.availableBonuses])

    useEffect(() => {
        setLoadingPage(false)
    },[props.claimError])

    useEffect(() => {
        if (props.claimError !== null) {
            props.resetClaimError();
        }
        const {bonus} = props.match.params;
        setBonus(bonus);
        if(bonus) {
            // setBonusByHash(bonus);
        }

    },[])

    const setBonusByHash = (hash) => {
        let info = Utils.decodeString(hash);
        console.log(info,'test123')
    }

    const getHashByBonus = (id,name) => {
        let hash = Utils.encodeString(id + ';' + name);

    }

    const onClickedBonus = (bonusId,bonus) => {
        if(bonusId > 0) {
                setBonusId(bonusId);
                props.claimBonus(bonusId,false);
                setLoadingPage(true);
                setSelectedBonus(bonus);
            // props.history.push(Utils.encodeString(bonusId + ';' + bonus.name));
        }
    }

    const forceBonus = (bonusId) => {
        if(bonusId > 0) {
            setBonusId(bonusId);
            props.claimBonus(bonusId,true);
        }
    }

    const getContent = () => {
        if (props.claimError !== null) {
            return (<ClaimPopup selectedBonus={selectedBonus} getBonus={() => forceBonus(bonusId)}/>)
        } else if(loadingPage || bonusCounter === 0) {
            return (<CircularProgress className={"Loading"}/>)
        } else if (props.claimError !== null) {
            return (<ClaimPopup selectedBonus={selectedBonus} getBonus={() => forceBonus(bonusId)}/>)
        } else if (bonus) {

        } else if (bonusCounter > 0) {
            return (<BonusAvailableWidget type={'list'} onClickedBonus={onClickedBonus.bind(this)} cta_text={props.t("CLAIM NOW")}/>)
        } else {
            return ( <BonusAvailableWidget type={'compact'} bonusAvailableId={props.availableBonuses[Object.keys(props.availableBonuses)[0]].bonus_group_id} onClickedBonus={onClickedBonus.bind(this)} />)
        }
    }

    return (
        <div id={"ClaimBonus"}>
            <TitleBar pageTitle={props.t('Bonus for you')}/>
            {getContent()}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        availableBonuses: state.application.availableBonuses,
        claimError: state.application.bonusClaimError
    }
};

const mapDispatchToProps = dispatch => {
    return {
        claimBonus: (bonusId,force) => dispatch(storeActions.claimBonus(bonusId,force)),
        resetClaimError: () => dispatch(storeActions.resetBonusClaimStatus()),
        bonusClaimed: (details) => dispatch(storeActions.bonusClaimed(details))
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ClaimBonus)));
