import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TabContainer.scss';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabList from './TabList/TabList';
 
class TabContainer extends Component {
  state = {
    activeTab: 1,
  };
 
  handleChange = (event, activeTab) => {
    this.setState( (state) => ({activeTab}));
  };
 
  render() {
    const { activeTab } = this.state;
 
    return (
      <div>
        <AppBar position="static" className={"TabContainer"}>
          <Tabs 
              className={"Tabs"}
              value={activeTab} 
              onChange={this.handleChange} 
            variant="scrollable"
              TabIndicatorProps={{className: 'TabIndicator'}}
            >
            {
            this.props.tabs.map( tab => (
              <Tab
                className={"Tab"}
                key={tab.id}
                disabled={tab.disabled}
                label={tab.label}
                value={tab.id}
                icon={tab.icon}
              />
            ))
            }
          </Tabs>
        </AppBar>
            {
              this.props.tabs.map( tab => (
                activeTab === tab.id
                  ? <TabList className={"wrapper"} key={tab.id}>{tab.component}</TabList>
                  : null
              ))
            }
      </div>
    );
  }
}
 
TabContainer.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      component: PropTypes.object.isRequired,
    }).isRequired)
    .isRequired,
};
 
export default TabContainer;