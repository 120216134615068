let debug = process.env.NODE_ENV === "development" ? console.log.bind(console) : () => { };

const DEFAULT_EXPIRATION_TIME = 4 * 3600 * 1000;

export const loadLocalData = (key, options) => {
	let expiration = DEFAULT_EXPIRATION_TIME;
	if (options && options.expiration) {
		expiration = parseInt(options.expiration, 10);
	}

	debug(`loading '${key}' local data with expiration ${expiration}`);

	const jd = localStorage.getItem(key);

	if (jd === null) return null;

	try {
		const d = JSON.parse(jd);

		if (!(d && d.issuedAt)) {
			throw ("missing issuedAt");
		}

		const ct = new Date().getTime();

		if (d.issuedAt + expiration < ct) {
			throw (`expired ${((ct - d.issuedAt - expiration) / 1000).toFixed(0)} seconds ago`);
		}

		debug(`loaded '${key}' local data, still valid for ${((d.issuedAt + expiration - ct) / 1000).toFixed(0)} seconds`, d);

		return d.data;
	} catch (e) {
		debug(`${key} local data load error`, e);
		localStorage.removeItem(key);
	}

	return null;
}

export const saveLocalData = (key, data) => {
	const ct = new Date().getTime();

	const d = {
		issuedAt: ct,
		data
	}

	localStorage.setItem(key, JSON.stringify(d));

	debug(`saved ${key} local data`, data);
}

export const clearLocalData = key => {
	localStorage.removeItem(key);

	debug(`cleared ${key} local data`);
}
