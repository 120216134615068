import * as actionTypes from "./actionTypes";

export const taxResponseReceived = (value) => {

    return {
        type: actionTypes.withdraw_wizard.RECEIVED_TAXES,
        value: value.result.tax,
    }
};

export const getTax = (amount) => {

    return dispatch => {
        dispatch({
            type: actionTypes.withdraw_wizard.SET_AMOUNT,
            value: amount,
        });
        dispatch({
            type: actionTypes.withdraw_wizard.REQUEST_TAXES,
            value: amount,
        });
    };
};

export const setWithdrawWizardStep = (step) => {
    return {
        type: actionTypes.withdraw_wizard.SET_STEP,
        value: step
    }
};

export const isDeposit = (value) => {
    return {
        type: actionTypes.withdraw_wizard.IS_DEPOSIT,
        value: value
    }
}

export const isWithdraw = (value) => {
    return {
        type: actionTypes.withdraw_wizard.IS_WITHDRAW,
        value: value
    }
}

export const skipLocation = (skip) => {
    return {
        type: actionTypes.withdraw_wizard.SKIP_LOCATION,
        value: skip
    }
}

export const sendWithdraw = (data) => {
    return {
        type: actionTypes.withdraw_wizard.SEND_DATA,
        value: data
    }
};

export const receivedWithdraw = (receivedData) => {
    return {
        type: actionTypes.withdraw_wizard.RECEIVED_WITHDRAW,
        receivedData: receivedData
    }
};
export const resetWithdraw = () => {
    return {
        type: actionTypes.paymentCheckout.RESET_PAYMENT_CHECKOUT
    }
};
export const withdrawWizardReceivedLocations = (response) => {
    return {
        type: actionTypes.withdraw_wizard.INIT_LOCATIONS,
        value: response.result.locations
    }
};

export const withdrawGetLocations = () => {
    return { type: actionTypes.withdraw_wizard.GET_LOCATIONS }
};

export const withdrawSetLocation = (location) => {
    return {
        type: actionTypes.withdraw_wizard.SET_LOCATION,
        value: location
    }
};

export const showWithdrawModal = (show) => {
    return {
        type: actionTypes.withdraw_wizard.OPEN_MODAL,
        value: show
    }
}
