import * as actionTypes from './actionTypes';

export const resetPasswordSendEmail = (email) => {
    return {
        type: actionTypes.resetPassword.SEND_EMAIL,
        email: email,
    }
}

export const resetPasswordSendEmailResponse = (data) => {
    return {
        type: actionTypes.resetPassword.SEND_EMAIL_RESPONSE,
        emailResponse: data,
    }
}

export const resetPasswordSendNewPassword = (token, newPassword) => {
    return {
        type: actionTypes.resetPassword.SEND_NEW_PASSWORD,
        token: token,
        newPassword: newPassword,
    }
}

export const resetPasswordSendNewPasswordResponse = (data) => {
    return {
        type: actionTypes.resetPassword.NEW_PASSWORD_RESPONSE,
        receivedResetPassword: data,
    }
}

export const resetPasswordSetToken = (token) => {
    return {
        type: actionTypes.resetPassword.SET_TOKEN,
        token: token
    }
}
