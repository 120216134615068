import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, NavLink, Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import { prematchShowTopOffer } from "../store/actions/prematch.js";
import { navSportsUpdate } from "../store/actions/navSports";
import {
  getBetsState,
  getPrematchSportsList,
  getTopSportsList,
  getFavoritePMatches
} from "../store/selectors/betData";
import { filterMatchData } from "../store/actions/preMatchFilter";
import Page from "./Page";
import { withRouter } from "react-router-dom";

import TabsMenu from "../components/TabsMenu";
import LeftNav from "../components/LeftNav";

import PageToolbar from "../components/PageToolbar";
import PageToolbarSection from "../components/PageToolbar/PageToolbarSection";
import PageToolbarButton from "../components/PageToolbar/PageToolbarButton";
import BetsFull from "../components/BetSlip/BetsFull";

import { ReactComponent as HomeIcon } from "../assets/icons/footballIcon.svg";
import { ReactComponent as CalendarIcon } from "../assets/icons/calendarIcon.svg";
import { ReactComponent as FilterIcon } from "../assets/icons/filterIcon.svg";
import { ReactComponent as AllLeagueIcon } from "../assets/icons/allLeagueIcon.svg";
import { ReactComponent as TopLeagueIcon } from "../assets/icons/topLeagueIcon.svg";
import { ReactComponent as FavoriteIcon } from "../assets/icons/home-favorite.svg";
import { ReactComponent as AvantajIcon } from "../assets/icons/icon-avantaj-link.svg";
import { ReactComponent as WinnerIcon } from "../assets/icons/icon-winner-link.svg";
import { ReactComponent as MultiBetOfTheDayIcon } from "../../day-multi-bet/assets/multibetoftheday.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/searchIcon.svg";
import CasinoIcon from "../assets/icons/icon-casino.svg";
import { ReactComponent as AgentiiIcon } from "../assets/icons/icon-agentii.svg";
import LiveIcon from "../elements/LiveIcon";

import PreMatch from "../views/PreMatch";
import PreMatchFilter from "../views/PreMatchFilter";
import PreMatchTopLeague from "../views/PreMatchTopLeague";
import PreMatchAvantaj from "../views/PreMatchAvantaj";
import PreMatchPlus from "../views/PreMatchPlus";
import OddsPlus from "../views/PreMatchOddsPlus";
import PreMatchFavorites from "../views/PreMatchFavorites";
import InfoMatch from "../views/InfoMatch";
import webStyle from "../utils/webStyle";
import FilterLeague from "../views/FilterLeague";

import { saveCalendarSelection } from "../store/actions/calendar";
import { getText } from "../../lobby/utils/functions";
import BetsLobby from "../components/bets-lobby/bets-lobby.js";
import BetBuilderSummary from "../components/BetBuilder/BetBuilderSummary.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const positionRelative = {
  position: "relative"
};

const dialogStyles = makeStyles({
  rootFilters: {
    "& .MuiPaper-root": {
      backgroundColor: "#EDF0F0"
    },
    "&.MuiDialog-root": {
      zIndex: "1303!important"
    }
  },
  minHeight: {
    "& .MuiPaper-root.MuiDialog-paper": {
      minHeight: "calc(100vh - 64px)",
      width: "1024px"
    }
  }
});

function PreMatchLayout(props) {
  const {
    prematchIdSport,
    prematchSports,
    topIdSport,
    topSports,
    favMatches,
    updateSportsIndex,
    liveMatchesNum,
    setMatchData,
    allData,
    location,
    history,
    baseUrlPath,
    isWinnerFun,
    navElements,
    dayMultiBets,
    betBuilder,
  } = props;
  const classes = webStyle();
  const dialogCls = dialogStyles();

  const isDesktop1024 = useMediaQuery("(min-width:1025px)");
  const isDesktop1280 = useMediaQuery("(min-width:1281px)");

  const [sports, setSports] = React.useState(prematchSports);
  const [idSport, setIdSport] = React.useState(prematchIdSport);
  const [sportIndex, setSportIndex] = React.useState(0);
  const [showTopOffer, setShowTopOffer] = React.useState(false);
  const [showLeagueSelector, setShowLeagueSelector] = React.useState(false);

  React.useEffect(() => {
    let si = 0;

    if (showTopOffer) {
      const topSportsValues = Object.values(topSports);
      setSports(topSportsValues);
      setIdSport(topIdSport);
      si = topSportsValues.findIndex(s => s.idSport === topIdSport);
    } else {
      setSports(prematchSports);
      setIdSport(prematchIdSport);
      si = prematchSports.findIndex(s => s.idSport === prematchIdSport);
    }

    if (si === -1) si = 0;
    setSportIndex(si);
  }, [topIdSport, topSports, prematchIdSport, prematchSports, showTopOffer]);

  const handleSportChange = index => {
    updateSportsIndex(showTopOffer ? "prematchTopLeagues" : "prematch", sports[index].idSport);
  };

  const routes = (
    <React.Fragment>
      <Route exact path={`${baseUrlPath}/pre-match/calendar`}>
        <PreMatch />
      </Route>
      <Route exact path={`${baseUrlPath}/pre-match/favorites`}>
        <PreMatchFavorites />
      </Route>
      <Route exact path={`${baseUrlPath}/pre-match/filter`}>
        <PreMatchFilter />
      </Route>
      <Route exact path={`${baseUrlPath}/pre-match/top-league`}>
        <PreMatchTopLeague />
      </Route>
      <Route exact path={`${baseUrlPath}/pre-match/ultra-odds`}>
        <PreMatchAvantaj />
      </Route>
      <Route exact path={`${baseUrlPath}/pre-match/winner-plus`}>
        <PreMatchPlus />
      </Route>
      <Route exact path={`${baseUrlPath}/pre-match/odds-plus`}>
        <OddsPlus />
      </Route>
      <Route exact path={`${baseUrlPath}/pre-match/match/:mType/:idMatch`}>
        <InfoMatch />
      </Route>
      <Route path={`${baseUrlPath}/pre-match/lobby/:path+`} component={BetsLobby} />
    </React.Fragment>
  );

  React.useEffect(() => {
    if (location.pathname === `${baseUrlPath}/pre-match/top-league`) {
      setShowTopOffer(true);
    } else {
      setShowTopOffer(false);
    }
  }, [location.pathname, baseUrlPath]);

  const handleAllLinksDialog = () => {
    setMatchData(allData[idSport], "prematch", idSport);
    setShowLeagueSelector(true);
  };
  const closeAllLinksDialog = () => {
    setShowLeagueSelector(false);
  };
  const handleLeagueSelection = (leagueData, leagueName) => {
    //console.log("leagueData", leagueData);
    setShowLeagueSelector(false);
    history.push(`${baseUrlPath}/pre-match/calendar?f=${leagueData}&l=${encodeURIComponent(leagueName)}`);
  };

  const handleOnSearch = () => {
    window.location.hash = `#filter`;
  };

  const filteredNavElements = navElements.filter(el => {
    return el.type && el.type === 'prematch';
  });
  const handleNavElementsLink = (i) => (e) => {
    if (filteredNavElements && filteredNavElements[i] && filteredNavElements[i].url) {
      let url = filteredNavElements[i].url;
      if (url && url.indexOf("http") > -1) {
        if (window.config && window.config.cordova) {
          window.cordova.InAppBrowser.open(url, '_system');
          return;
        }
        window.location.href = url;
      } else {
        if (url.indexOf("sd=0") > - 1) {
          props.saveCalendarSelection(0);
        }
        if (url.indexOf("/pariuri/lobby/") > -1) {
          // special lobby content displayed on this bet promoted page
          url = url.replace("/pariuri/lobby/", "/pariuri/pre-match/lobby/");
        }
        history.push(url);
      }
    }
  };

  const lang = props.i18n.language;

  const paths = {
    home: {
      pathname: baseUrlPath,
      state: { prev: false }
    },
    live: { pathname: `${baseUrlPath}/live-match/highlights` },
    calendar: { pathname: `${baseUrlPath}/pre-match/calendar` },
    favorites: { pathname: `${baseUrlPath}/pre-match/favorites` },
    filter: { pathname: `${baseUrlPath}/pre-match/filter` },
    topLeague: { pathname: `${baseUrlPath}/pre-match/top-league` },
    odds: { pathname: `${baseUrlPath}/pre-match/odds` },
    winnerAvantaj: { pathname: `${baseUrlPath}/pre-match/ultra-odds` },
    winnerPlus: { pathname: `${baseUrlPath}/pre-match/winner-plus` },
    oddsPlus: { pathname: `${baseUrlPath}/pre-match/odds-plus` },
  };

  let hasOddsPlus = false;
  if (window.config.enableDayMultiBet === '1' && dayMultiBets && dayMultiBets.length > 0) {
    hasOddsPlus = true;
  }

  const pageToolbar = (
    <PageToolbar type="prematch" className="pre-match-toolbar">
      <PageToolbarSection title={props.t("bets")} titleBg="#C31822">
        {!window.config.cordova && <NavLink to={paths.home} exact className="nav__link">
          <PageToolbarButton text={props.t("Promoted")}>
            <HomeIcon />
          </PageToolbarButton>
        </NavLink>}
        <NavLink to={paths.live} className="nav__link">
          <PageToolbarButton text={props.t("Live")}>
            <LiveIcon number={liveMatchesNum} />
          </PageToolbarButton>
        </NavLink>
        <div onClick={handleOnSearch} className={`nav__link`}>
          <PageToolbarButton text={props.t("Search")}>
            <SearchIcon />
          </PageToolbarButton>
        </div>
      </PageToolbarSection>
      <PageToolbarSection title={props.t("PRE - MATCH")} titleBg="#06D2BD">
        <NavLink to={paths.calendar} className="nav__link">
          <PageToolbarButton text={props.t("Calendar")}>
            <CalendarIcon />
          </PageToolbarButton>
        </NavLink>
        {hasOddsPlus && <NavLink to={paths.oddsPlus} className="nav__link">
          <PageToolbarButton text={props.t("Odds+")}>
            <MultiBetOfTheDayIcon />
          </PageToolbarButton>
        </NavLink>}
        <div onClick={handleAllLinksDialog} className={`nav__link`}>
          <PageToolbarButton text={props.t("All Leagues")}>
            <AllLeagueIcon />
          </PageToolbarButton>
        </div>
        {favMatches.length > 0 && (
          <NavLink to={paths.favorites} className="nav__link">
            <PageToolbarButton text={props.t("Favorite")}>
              <FavoriteIcon />
            </PageToolbarButton>
          </NavLink>
        )}
        {filteredNavElements.map((item, i) => {
          let url = item.url;

          let active = false;
          if (url && url.indexOf(`${baseUrlPath}/lobby/`) === 0 && location.pathname.indexOf(`${baseUrlPath}/pre-match/lobby/`) === 0) {
            let u1 = url.replace(`${baseUrlPath}/lobby/`, '');
            let u2 = location.pathname.replace(`${baseUrlPath}/pre-match/lobby/`, '');
            if (u1 === u2) active = true;
          } else if (url === location.pathname) {
            active = true;
          }

          return <div onClick={handleNavElementsLink(i)} className={`nav__link custom-nav-element ${active ? "active" : ""}`} key={`custom_link_${i}`}>
            <PageToolbarButton image={item.icon} text={getText(item, `title.text.${lang}`, "")} />
          </div>
        })}
        <NavLink to={paths.topLeague} className="nav__link">
          <PageToolbarButton text={props.t("Top League")}>
            <TopLeagueIcon />
          </PageToolbarButton>
        </NavLink>

        <NavLink to={paths.winnerAvantaj} className="nav__link">
          <PageToolbarButton text={props.t("Ultra Odds")}>
            <AvantajIcon />
          </PageToolbarButton>
        </NavLink>
        {/*
        <NavLink to={paths.winnerPlus} className="nav__link">
          <PageToolbarButton text={props.t("Winner+")}>
            <WinnerIcon />
          </PageToolbarButton>
        </NavLink>
        */}
        <NavLink to={paths.filter} className="nav__link">
          <PageToolbarButton text={props.t("My Filter")}>
            <FilterIcon />
          </PageToolbarButton>
        </NavLink>
      </PageToolbarSection>
      {baseUrlPath === "/pariuri" && <PageToolbarSection title={props.t("navigare")} titleBg="#162151">
        <Link to={"/cazino/jocuri"} className="nav__link">
          <PageToolbarButton image={CasinoIcon} text={props.t("Casino")} />
        </Link>
        {/*
        {!window.config.cordova && <Link to={"/locations"} className="nav__link">
          <PageToolbarButton text={props.t("Locations")}>
            <AgentiiIcon />
          </PageToolbarButton>
        </Link>}
      */}
      </PageToolbarSection>}
    </PageToolbar>
  );
  const pageContent = (
    <div style={positionRelative}>
      <Switch>{routes}</Switch>
    </div>
  );

  let cnt = null;
  let tabsMenu = (
    <TabsMenu tabs={sports} value={sportIndex} type="preMatch" onChange={handleSportChange} />
  );

  if (isDesktop1280) {
    tabsMenu = <LeftNav />;
    //tabsMenu = (<TabsMenu tabs={sports} value={sportIndex} type="preMatch" onChange={handleSportChange} vertical={true} />);
    cnt = (
      <div className={`${classes.wrapper} d-flex flex-row flex-norwap`}>
        <div className={classes.tabsDesktop}>{tabsMenu}</div>
        <div className={`${classes.cnt} ${classes.px}`}>
          <div className={classes.over}>{pageToolbar}</div>
          {pageContent}
        </div>
        <div className={classes.betSlip}>
          <div>
            {betBuilder.selected.length > 0 && betBuilder.open && <BetBuilderSummary expanded={true} isDesktop={true} />}
            {!(betBuilder.selected.length > 0 && betBuilder.open) && <BetsFull desktop={true} />}
          </div>
        </div>
      </div>
    );
  } else if (isDesktop1024) {
    cnt = (
      <div className={`${classes.wrapper} d-flex flex-row flex-norwap`}>
        <div className={`${classes.cnt} ${classes.px}`}>
          {tabsMenu}
          {pageToolbar}
          {pageContent}
        </div>
        <div className={classes.betSlip}>
          <div>
            {betBuilder.selected.length > 0 && betBuilder.open && <BetBuilderSummary expanded={true} isDesktop={true} />}
            {!(betBuilder.selected.length > 0 && betBuilder.open) && <BetsFull desktop={true} />}
          </div>
        </div>
      </div>
    );
  } else {
    cnt = (
      <React.Fragment>
        {tabsMenu}
        {pageToolbar}
        {pageContent}
        <div className="spacer">&nbsp;</div>
      </React.Fragment>
    );
  }

  const fullScreen = isDesktop1024 ? false : true;

  return (
    <Page
      doRender={[
        idSport,
        favMatches,
        sports,
        isDesktop1024,
        isDesktop1280,
        showLeagueSelector,
        liveMatchesNum,
        baseUrlPath,
        navElements,
        isWinnerFun,
        betBuilder,
      ]}
    >
      {cnt}
      <Dialog
        fullScreen={fullScreen}
        disableEnforceFocus
        open={showLeagueSelector}
        TransitionComponent={Transition}
        maxWidth={!fullScreen ? "lg" : false}
        className={`${dialogCls.rootFilters} ${isDesktop1024 ? dialogCls.minHeight : ""} betsModal allLeaguesSelector`}
        onClose={closeAllLinksDialog}
      >
        <FilterLeague singleMode={true} singleSelection={handleLeagueSelection} />
      </Dialog>
    </Page>
  );
}

PreMatchLayout.propTypes = {
  idSport: PropTypes.string,
  sports: PropTypes.array,
  updateSportsIndex: PropTypes.func
};

PreMatchLayout.defaultProps = {
  idSport: "0",
  sports: [],
  updateSportsIndex: () => { }
};

const mapStateToProps = (state, props) => {
  const bst = getBetsState(state);

  return {
    allData: bst.prematch.data,
    prematchIdSport: bst.navSports.prematch,
    prematchSports: getPrematchSportsList(state),
    topIdSport: bst.navSports.prematchTopLeagues,
    topSports: getTopSportsList(state),
    favMatches: getFavoritePMatches(state),
    liveMatchesNum: Object.keys(bst.live.matches).length,
    baseUrlPath: bst.app.basePath,
    isWinnerFun: bst.app.isWinnerFun,
    navElements: bst.config.navElements,
    dayMultiBets: bst.config.dayMultiBets,
    betBuilder: state.bets.betBuilder,
  };
};

const actionCreators = {
  updateSportsIndex: navSportsUpdate,
  showTopOffer: prematchShowTopOffer,
  setMatchData: filterMatchData,
  saveCalendarSelection: saveCalendarSelection
};

export default withTranslation()(
  withRouter(connect(mapStateToProps, actionCreators)(PreMatchLayout))
);
