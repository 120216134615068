import React, { useEffect, useState, Fragment } from 'react';
import i18next from 'i18next';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as storeActions from "../../../store/actions";
import Icons from '../../../assets/Icons/Icons';
import TitleBar from "../../UI/TitleBar/TitleBar";
import PageTitle from '../../UI/PageTitle/PageTitle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./GameRules.scss";

const CDN_STORAGE_URL = window.location.protocol + '//'  + window.config.cdn_address;

const GameRules = (props) => {

    useEffect(() => {
        props.onRequestGameRules();
        // eslint-disable-next-line
    }, []);

    const [expanded, setExpanded] = useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setTimeout(() => {
            document.getElementById('panel' + panel).scrollIntoView({behavior: 'smooth', block: 'start'})
        }, 700);
    };

    let pageContent = <CircularProgress className={'Loading'} />;

    const getGameRules = (type) => {
        if(!props.customerSupport.gameRulesLoading || !i18next.language){
            if(props.customerSupport.groupedRules){
                let groupedDocuments = props.customerSupport.groupedRules;
                let stateGroups = null;

                let lang = i18next.language === 'en' ? 'en' : 'ro';
                if (groupedDocuments[type]) {
                    if (groupedDocuments[type][lang]) {
                        stateGroups = groupedDocuments[type][lang];
                    }
                }

                if(!stateGroups){
                    return null;
                }
                let groupsKey = Object.keys(stateGroups);
                let groups = [];
                groupsKey.forEach((groupKey, index) => {
                    let stateGroup = stateGroups[groupKey];
    
                    let gameList = stateGroup.map((game, i) => (
                        <ul key={i}>
                            <li>
                                <a className={'GameName'} href={CDN_STORAGE_URL + '/' + game.path} target="_blank" rel="noopener noreferrer">{game.name}</a>
                            </li>
                        </ul>
                    ));
    
                    let group = (
                        <ExpansionPanel key={groupKey + index} className={"ExpansionPanel"} id={'panel' + groupKey + index} expanded={expanded === groupKey + index} onChange={handleChange(groupKey + index)}>
                            <ExpansionPanelSummary className={'AccordionTitle'} expandIcon={<ExpandMoreIcon />}>
                                <h3 className={'GroupName'}>{groupKey === '0' ? '#' : groupKey.toUpperCase()}</h3>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={'AccordionContent'}>
                                {gameList}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                    groups.push(group);
                });
    
                return groups;
            }
        }
    }

    return (
        <div className="GameRulesScreen">
            <TitleBar pageTitle={props.t('Legal')}/>
            <div className={"GameRulesContent"}>
                <PageTitle icon={Icons.get('documents', `iconSettings`)} title={props.t('Game Rules')} subtitle={props.t('Functionality Description')}/>
                <p className={'IntrodutcionText'}>{props.t('This page contains information about the rules of the games that are sorted in alphabetical order. If you have any questions or suggestions, please contact us')}&nbsp;
                    {/*<span onClick={() => props.history.push("/customer-support/complaint-form")}>{props.t("here")}</span>.*/}</p>
                {window.config && (window.config.liveCasinoEnabled === "1") && (
                    <Fragment>
                        <h3 className={'GroupTitle'}>{props.t('Live Casino rules')}</h3>
                        {getGameRules('liveCasino')}
                    </Fragment>
                )}                
                {window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1") && (
                    <Fragment>
                        <h3 className={'GroupTitle'}>{props.t('Sport book rules')}</h3>
                        {getGameRules('bets')}
                    </Fragment>
                )}
                {window.config && window.config.virtualsEnabled === "1" && (
                    <Fragment>
                        <h3 className={'GroupTitle'}>{props.t('Virtuals rules')}</h3>
                        {getGameRules('virtuals')}
                    </Fragment>
                )}
                <h3 className={'GroupTitle'}>{props.t('Slots rules')}</h3>
                {getGameRules('slots')}
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        customerSupport: state.customerSupport,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestGameRules: () => dispatch(storeActions.requestGameRules()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(GameRules)));
