import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import SimpleCounter from "../NewCounter";

import BackgroundImage from "./../../../assets/Images/Jackpot/img-bg-jackpot-header-front@3x.png";
import "./SubHeader.scss";
import Utils from "../../../utils/Utils";

class SubHeader extends Component {
  render() {
    let content = <>&nbsp;</>;

    const names = {
      "1": "Hearts",
      "2": "Diamonds",
      "3": "Clubs"
    };

    if (this.props.jackpot.levelsValues.length >= 4) {
      content = this.props.jackpot.levelsValues.map((number, index) => {
        if (index > 0 && index <= 3) {
          return (
            <div
              className="wrapper"
              style={{ backgroundImage: `url(${Utils.getImageUrl(BackgroundImage)})` }}
              key={index}
            >
              <div className="title">
                {this.props.name} {names[index]}
              </div>
              <div className="counter">
                <SimpleCounter
                  theme="simple-counter"
                  digits={5}
                  value={number / 100}
                  storageKey={`EGT_${index}`}
                />
              </div>
            </div>
          );
        }
        return null;
      });
    }

    return (
      <div className="jackpot-subheader">
        <div className="all">{content}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    jackpot: state.jackpot
  };
};

SubHeader.propTypes = {
  jackpot: PropTypes.object,
  name: PropTypes.string
};

SubHeader.defaultProps = {
  name: ""
};

export default connect(mapStateToProps)(SubHeader);
