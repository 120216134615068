import React, { useState, useEffect } from "react";
import connect from "react-redux/es/connect/connect";
import * as storeActions from "./../../../../store/actions";
import { Spring, animated } from "react-spring/renderprops";
import * as easings from "d3-ease";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

import "./GameLauncher.scss";
import LogMessage from "./../LogMessage/LogMessage";
//import TitleBar from "../../../UI/TitleBar/TitleBar";
import TitleBar from "./utils/TitleBar";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import HappyHoursMobile from "../../../HappyHours/views/index-mobile";
import HappyHoursWin from "../../../HappyHours/views/win-panel";
import HappyHoursEnded from "../../../HappyHours/views/final";
import HappyHoursInfo from "../../../HappyHours/views/info";

const styles = theme => ({
    progressColorPrimary: {
        color: "#ffb400"
    },
    gameLaunchError: {
        color: '#ffffff',
        padding: '4rem 1rem 0 1rem',
    }

});

class MyGameIframe extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.gameUrl !== this.props.gameUrl) {
            return true;
        }
        return false;
    }

    render() {
        const { gameUrl, onGameLoaded } = this.props;

        return (
            <iframe
                id="game-iframe"
                src={gameUrl}
                onLoad={() => {
                    onGameLoaded();
                }}
                onError={() => {
                    console.log(`[LAUNCHER] Game error!`);
                }}
                className="flash-game"
                autoPlay
                allow={"autoplay"}
                title="Game Slot"
            />
        );
    }
}

const GameLauncher = props => {
    const classes = props.classes;
    const { authentication, openGame, games, hhRunning, tmRunning, mmRunning, lobbyGames, routerHistory, authType } = props;
    const { openGameId, gameURL, gameLauncher } = games;
    //const { t } = useTranslation();

    //console.log("GameLauncher", { hhRunning, tmRunning, openGameId, gameURL, gameLauncher });

    const isLandscape = useMediaQuery("(orientation: landscape)");
    //const [hide, setHide] = useState(false);
    const [started, setStarted] = useState(false);
    const [gameUrl, setGameUrl] = useState(null);
    const [gameLoaded, setGameLoaded] = useState(false);
    const [sizeState, setSizeState] = useState({
        oldHeight: window.innerHeight,
        innerHeight: window.innerHeight
    });

    /*
    useEffect(() => {
        if (location.pathname.indexOf("/play/") > -1) {
            setHide(false);
        } else {
            setHide(true);
        }
    }, [location.pathname]);
    */

    useEffect(() => {
        window.addEventListener("message", egtReceivedMessage, false);
        window.addEventListener("message", greentubeReceivedMessage, false);
        document.body.classList.add("in-game");

        return () => {
            window.removeEventListener("message", egtReceivedMessage);
            window.removeEventListener("message", greentubeReceivedMessage);
            props.close(); // on android BACK button doesn't properly closes the game so we need to call close again
            document.body.classList.remove("in-game");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //console.log("GameLauncher", { authType, openGameId, gameURL });
        if (!openGameId && window.location.pathname.indexOf("/play/") === 0) {
            let url = window.location.pathname.split("/");
            if (
                !openGameId &&
                typeof url === "object" &&
                typeof url[1] !== "undefined" &&
                url[1] === "play"
            ) {
                console.log("GameLauncher[openGame]", url[2]);
                openGame(url[2]);
            }
        } else if (!(["user", "token"].indexOf(authentication.auth_type) === -1) && openGameId && !gameURL) {
            props.requestUrl(props.games.openGameId, "egt");
        }
    }, [authType, openGame, openGameId, gameURL]); // eslint-disable-line react-hooks/exhaustive-deps

    const oldIntervalID = React.useRef(0);
    useEffect(() => {
        if (props.games.gameURL) {
            document.getElementsByTagName('html')[0].classList.add("OverflowHidden");
            setGameUrl(props.games.gameURL + "&closeurl=com.egt-bg.postMessage");

            clearInterval(oldIntervalID.current);
            let intervalID = setInterval(() => {
                const height = window.innerHeight;
                setSizeState(s => {
                    if (s.innerHeight !== height) {
                        setTimeout(() => {
                            window.scrollTo(0, 0)
                        }, 500);
                        return {
                            oldHeight: s.innerHeight,
                            innerHeight: height
                        };
                    }
                    return s;
                });
            }, 500);
            oldIntervalID.current = intervalID;
        } else {
            setGameUrl(null);
            clearInterval(oldIntervalID.current);
        }

        return () => {
            clearInterval(oldIntervalID.current);
            document.getElementsByTagName('html')[0].classList.remove("OverflowHidden");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameURL]);

    let gameSupportClass = ["playGameSupport"];
    if (gameUrl || (!gameUrl && props.games.gameLaunchError)) {
        gameSupportClass.push("show");
    }

    const closeHandler = (redirectToDeposit) => {
        props.close();
        localStorage.removeItem("fromGame");
        if (routerHistory?.pages > 1 && !redirectToDeposit) {
            let gotoPage = '';
            routerHistory?.pagesHistory?.length && routerHistory.pagesHistory.find((page, index) => {
                if (
                    page.indexOf("/play/") === -1 &&
                    page.indexOf("/deposit") === -1 &&
                    page.indexOf("/connect") === -1 &&
                    page.indexOf("/payment/") === -1
                ) {
                    gotoPage = page;
                    return true;
                }
                return false;
            });
            if (gotoPage) {
                props.history.push(gotoPage);
                return;
            }
        }
        if (redirectToDeposit) {
            props.history.push("/deposit#nb");
            return;
        }
        props.history.push("/casino");
    };
    const onGameLoaded = () => {
        setGameLoaded(true);
    };
    const getGameElement = () => {
        if (props.games.gameLaunchError) {
            return (<div className={classes.gameLaunchError}>
                A aparut o eroare. Te rugam sa incerci mai tarziu.
            </div>);
        }
        if (!gameUrl) {
            return null;
        }

        return (
            <MyGameIframe gameUrl={gameUrl} onGameLoaded={onGameLoaded} />
        );
    };

    const calculateIframeScale = () => {
        if (!gameUrl) {
            return null;
        }

        //console.log("***GAMELAUNCHER", "isLandscape", isLandscape);
        if (isLandscape) return "";

        let gameProvider = 0;

        const gameInfo = lobbyGames.find(el => openGameId && el.game_id.toString() === openGameId.toString());
        if (gameInfo) {
            gameProvider = gameInfo.provider;
        }

        //console.log("***GAMELAUNCHER", "gameProvider", gameProvider);

        if (!gameProvider) return "";

        if (gameProvider.toString() !== "1" && gameProvider.toString() !== "9" && gameProvider.toString() !== "11") return "";

        const hp = document.querySelector('.hp');
        let somethingIsTakingSpace = false;
        if (hp && hp.clientHeight > 10) {
            somethingIsTakingSpace = true;
        }

        const initialValues = {
            "0": { p: { w: 540, h: 960 } }, // default
            "1": { p: { w: 540, h: 960 } }, // egt aspect ratio
            "3": { p: { w: 1125, h: 2244 } }, // Skywind aspect ratio
            "4": { p: { w: 375, h: 667 } }, // CT Gaming aspect ratio
            "5": { p: { w: 935, h: 1300 } }, // Green tube aspect ratio
            "7": { p: { w: 750, h: 1368 } }, // Pragmatic aspect ratio
            "9": { p: { w: 540, h: 960 } }, // iSoftBet aspect ratio
            "10": { p: { w: 540, h: 960 } }, // Relax aspect ratio
            "11": { p: { w: 540, h: 960 } }, // EGT Digital aspect ratio
        };

        let w = initialValues[gameProvider].p.w; // aspect ratio width when in portrait mode for gameProvider
        let h = initialValues[gameProvider].p.h; // aspect ratio height when in portrait mode for gameProvider
        let ratio = w / h;

        // subtract HappyHour component height (42px - collapsed; 89px expanded) and the close bar (39px)
        let innerHeight = window.innerHeight - (props.hhRunning ? (isCollapsed ? 42 : 89) : somethingIsTakingSpace ? 89 : 0) - (!hhRunning && !tmRunning && !mmRunning ? 39 : 0);

        // calculate iframe width based on available height
        let width = innerHeight * ratio;

        // if calculated width is bigger than the screen width adjusted (the new height will be smaller that the above innerHeight so it's safe)
        if (width > window.innerWidth) {
            width = window.innerWidth;
        }

        let margin = (window.innerWidth - width) / 2;

        //console.log("***GAMELAUNCHER innerWidth", window.innerWidth, "currentWidth", width, "margin", margin, "gameProvider", gameProvider);

        if (margin > 0 && margin < 50 && gameProvider !== 7) {
            const newWidth = width + (2 * margin);

            return `scale(${(newWidth * 100) / width / 100}, ${gameProvider === 1 ? 1.065 : 1})`;
            //return `scale(${(newWidth * 100) / currentWidth / 100}, 1.065)`;
        }
        return "";
    };

    const launchGameByProviderGameId = (providerGameId) => {
        let game = props.lobbyGames.find((element) => {

            if (element.provider_game_id === providerGameId) {
                return true
            }
            return false;
        });

        if (game && game.game_id) {
            props.history.push('/play/' + game.game_id);
            props.openGame(game.game_id);
            // props.requestUrl(game.game_id,game.provider)
        }
    }

    const egtReceivedMessage = event => {

        //if (event?.origin?.indexOf("localhost") === -1) console.log("***EGT Received message", event);

        if (!event.data) {
            return;
        }

        if (!event.data.hasOwnProperty("command")) {
            return;
        }

        if ("com.egt-bg.exit" === event.data.command || "com.egt-bg.launchLobby" === event.data.command) {
            return closeHandler();
        }

        // EGT Digital
        // event.origin: https://hotspinsro-gc-prod-bgsp.egt-ong.com
        // event.data: { "command": "openGame", "type": "SCSlot" }
        // event.data: { "command": "autoPlayStopped", "type": "SCSlot" }
        // event.data: { "command": "reelsStopped", "type": "TBHSlot" }
        // event.data: { "command": "exit" }
        // event.data: { "command": "openHTML", "type": "paytable" }
        // event.data: { "command": "closeHTML", "type": "paytable" }
        // event.data: { "command": "openHTML", "type": "help" }
        // event.data: { "command": "closeHTML", "type": "help" }
        // event.data: { "command": "openLobby" }
        if ("exit" === event.data.command) {
            return closeHandler();
        }

        if ("com.egt-bg.launchGame" === event.data.command) {
            try {
                let providerGameId = event.data.gin;
                if (window.loadedGame && window.loadedGame !== providerGameId) {
                    // switch functionality from EGT game
                    props.close();
                    window.loadedGame = false;
                    return launchGameByProviderGameId(providerGameId);
                } else {
                    window.loadedGame = providerGameId;
                }
            } catch (e) { }
        }


        if ('com.egt-bg.reload' === event.data.command) {
            try {
                let url = window.location.pathname.split("/");
                if (
                    !openGameId &&
                    typeof url === "object" &&
                    typeof url[1] !== "undefined" &&
                    url[1] === "play"
                ) {
                    props.close();
                    return props.requestUrl(url[2]);
                }
            } catch (e) { }
        }
    };

    const greentubeReceivedMessage = event => {

        if (!event.data) {
            return;
        }
        let data = '';
        try {
            data = JSON.parse(event.data);

        } catch (e) {
            return false;
        }


        if (typeof data.event !== "undefined") {
            if ('gameClosing' === data.event.name) {
                return closeHandler();
            }
        }
    };

    const getLogMessage = () => {
        // if auth is not app, return a page with 2 buttons: login and register
        if ("user" === props.authType) {
            return null;
        }

        return (
            <div className={"messageSupport"}>
                <LogMessage />
            </div>
        );
    };

    const requestUrl = () => {
        console.log("GameLauncher[requestUrl]: props.authType", props.authType);
        if (!("user" === props.authType)) {
            return;
        }

        if (props.games.openGameId && !props.games.gameURL) {
            props.requestUrl(props.games.openGameId, "egt");
        }
    };

    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const handleHHChange = collapsed => {
        setIsCollapsed(collapsed);
    };

    const heightStyle = { height: "100%" };

    const scaleStyle = {
        background: props.gamesColors[props.games.openGameId]
            ? props.gamesColors[props.games.openGameId]
            : props.gamesColors["default"]
                ? props.gamesColors["default"]
                : "#000000"
    };
    const scaleInfo = calculateIframeScale();
    if (scaleInfo) {
        scaleStyle.transform = scaleInfo;
        scaleStyle.transformOrigin = "top";
    }

    //if (hide) return null;
    //console.log('GameLauncher', { hhRunning, tmRunning, mmRunning });

    return (
        <div className={"gameLauncherBackdrop"}>
            <Spring
                config={{ easing: easings.easeElasticOut }}
                from={{ height: props.games.gameLauncher ? "0px" : started ? `${window.innerHeight}px` : "0px" }}
                to={{ height: props.games.gameLauncher ? `${window.innerHeight}px` : "0px" }}
                onRest={() => {
                    if (started && props.games.gameLauncher) {
                        setStarted(false);
                        console.log(`[LAUNCHER] Request game in launcher!`);
                        //requestUrl();
                        return;
                    }
                }}
                onStart={() => {
                    if (props.games.gameLauncher) {
                        setStarted(true);
                        console.log(`[LAUNCHER] Showing game launcher!`);
                        return;
                    }
                }}
            >
                {springProps => {
                    return (
                        <animated.div className={"GameLauncher"} style={springProps}>
                            {!gameLoaded && !props.games.gameLaunchError && (
                                <CircularProgress className={classes.progressColorPrimary} />
                            )}

                            <div
                                className={gameSupportClass.join(" ")}
                                id={"play-game"}
                                style={heightStyle}
                            >
                                <div
                                    className={`re-flow ${isCollapsed && isLandscape ? "column" : ""}`}
                                    style={heightStyle}
                                >
                                    {(!hhRunning && !tmRunning && !mmRunning) && <TitleBar close={props.close} closeHandler={closeHandler} gameId={openGameId} />}
                                    <div className="hp">
                                        {gameUrl && <HappyHoursMobile onChange={handleHHChange} closeHandler={closeHandler} />}
                                    </div>
                                    <div className="if" style={scaleStyle}>
                                        {getGameElement()}
                                    </div>
                                    {gameUrl && <React.Fragment>
                                        <HappyHoursInfo />
                                        <HappyHoursWin />
                                        <HappyHoursEnded />
                                    </React.Fragment>}
                                </div>
                            </div>
                            {getLogMessage()}
                        </animated.div>
                    )
                }}
            </Spring>
        </div>
    );
};

const emptyArray = [];

const mapStateToProps = state => {

    const groups = state.tournamentsMissions ? state.tournamentsMissions.groups.list : emptyArray;

    let tmRunning = false;
    let mmRunning = false;

    let parts = window.location.pathname.split("/");
    let gameId = parseInt(parts[parts.length - 1], 10);

    if (groups.length) {
        let canPlay = false;

        let hasActive = false;
        let hasEnrolled = false;

        if (gameId && !isNaN(gameId)) {
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                if (!group.block_enroll) {
                    for (let j = 0; j < group.tournaments.length; j++) {
                        if (group.tournaments[j].activated) {
                            if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                                canPlay = true;
                                hasActive = true;
                            }
                        }
                    }
                }
            }

            if (!hasActive) {
                const sorted = [...groups].sort((a, b) => {
                    const priorityA = a.priority ? a.priority : 0;
                    const priorityB = b.priority ? b.priority : 0;
                    return priorityB - priorityA;
                });

                for (let i = 0; i < sorted.length; i++) {
                    const group = sorted[i];
                    if (!group.block_enroll) {
                        for (let j = 0; j < group.tournaments.length; j++) {
                            if (group.tournaments[j].enrolled) {
                                if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                                    canPlay = true;
                                    hasEnrolled = true;
                                }
                            }
                        }
                    }
                }
            }

            if (!hasActive && !hasEnrolled) {
                const now = +new Date();
                const sorted = [...groups].sort((a, b) => {
                    const priorityA = a.priority ? a.priority : 0;
                    const priorityB = b.priority ? b.priority : 0;
                    return priorityB - priorityA;
                });

                for (let i = 0; i < sorted.length; i++) {
                    const group = sorted[i];
                    if (!group.block_enroll) {
                        for (let j = 0; j < group.tournaments.length; j++) {

                            if (group.tournaments[j].start_date <= now && now < group.tournaments[j].end_date) {
                                if (group.tournaments[j].meta && group.tournaments[j].meta.games && group.tournaments[j].meta.games.indexOf(gameId) > -1) {
                                    canPlay = true;
                                }
                            }
                        }
                    }
                }
            }

            if (canPlay) {
                tmRunning = true;
            }
        }
    }

    if (state?.momentum?.prize?.value?.active) {
        let is_expired = false;
        if (state?.momentum?.prize?.value !== null && state?.momentum?.prize?.value?.bonus_expires_at) {
            const now = new Date().valueOf();
            const prizeExpiresAt = new Date(state?.momentum?.prize?.value?.bonus_expires_at).valueOf();
            is_expired = now - prizeExpiresAt >= 0;
        }
        if (!is_expired && !isNaN(gameId) && state.momentum.prize.eligibleGames.indexOf(gameId) > -1) {
            mmRunning = true;
        }
    }

    return {
        routerHistory: state.application.routerHistory,
        games: state.games,
        lobbyGames: state.lobby ? state.lobby.games.items : emptyArray,
        authentication: state.authentication,
        authType: state.authentication.auth_type,
        hhRunning: state.happyHour.running && state.happyHour.running["online-slots"] ? state.happyHour.running["online-slots"] : false,
        tmRunning: tmRunning,
        mmRunning: mmRunning,
        gamesColors: state.config.gamesColors
    };
};

const mapDispatchToProps = dispatch => {
    return {
        close: () => dispatch(storeActions.closeGameLauncher()),
        requestUrl: (gameId, gameProvider) =>
            dispatch(storeActions.requestGameUrl(gameId, gameProvider)),
        openGame: gameId => dispatch(storeActions.openGameLauncher(gameId))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GameLauncher))
);
