import openSocket from "socket.io-client";
import axios from "axios";
import ClientAPI from "./../../ClientAPI/ClientAPI";

import { walletReceived } from "../actions/wallet";
import { walletReceived as winnerFunWalletReceived } from "../../winner-fun/store/actions/wallet";
import {
	happyHourUpdateStatus,
	happyHourPrizeWon,
	happyHourStatusCompleted,
	happyHourBetInfo
} from "../actions/happy_hour";
import { betsSlipComputeWin } from "./../../bets/store/actions/betsSlip";
import { freeBetsLoaded, freeBetsFetchFilters } from "../actions/free_bets";

import { tournamentCreated, tournamentEnded } from "../../tournaments-missions/store/actions/tournaments_groups";
import { updatePlayerLeaderBoard } from "../../tournaments-missions/store/actions/leader_board";
import { receivedMomentumMessage } from "../../momentum/store/sagas/websocket";

let socket = null;

export const connectPlayerWebsocket = () => {
	if (socket !== null) {
		return;
	}

	//console.log("player ws to ", process.env.CFW, process.env.CFW.playerWsUrl + process.env.CFW.playerWsPath);

	const query = {
		protocol: "sio1"
	};

	if (window.config && window.config.clientId) {
		query["tenantId"] = window.config.clientId;
	}

	socket = openSocket(process.env.CFW.playerWsUrl, {
		path: process.env.CFW.playerWsPath,
		forceNew: true,
		transports: ["websocket"],
		query
	});

	//console.log("socket", socket);

	socket.on("connect", () => {
		//console.log("player ws connected");

		const state = ClientAPI.getStore().getState();
		const { authentication } = state;

		if (["user", "token"].indexOf(authentication.auth_type) > -1) {
			socket.emit("login", {
				token: "Bearer " + authentication.access_token
			});
		}
	});

	socket.on("set_balance", data => {
		if (data && data.balance) {
			//console.log("SET_BALANCE", data);
			if (data.currency === "WINCOIN") {
				ClientAPI.getStore().dispatch(winnerFunWalletReceived(data.balance));
			} else {
				ClientAPI.getStore().dispatch(walletReceived(data.balance));
			}

			if (window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")) {
				ClientAPI.getStore().dispatch(betsSlipComputeWin());
			}
		} else {
			console.error("invalid balance data received: ", data);
		}
	});

	socket.on("set_free_bets", data => {
		if (data && data.free_bets) {
			ClientAPI.getStore().dispatch(freeBetsLoaded(data.free_bets));
			ClientAPI.getStore().dispatch(freeBetsFetchFilters(data.free_bets));
		} else {
			console.error("invalid free_bets data received: ", data);
		}
	});

	// happy hour messages
	socket.on("happy_hour_status", data => {
		ClientAPI.getStore().dispatch(happyHourUpdateStatus(data));
	});

	socket.on("happy_hour_completed", data => {
		ClientAPI.getStore().dispatch(happyHourStatusCompleted(data));
	});

	socket.on("happy_hour_bet_info", data => {
		ClientAPI.getStore().dispatch(happyHourBetInfo(data));
	});

	socket.on("happy_hour_prize_won", data => {
		ClientAPI.getStore().dispatch(happyHourPrizeWon(data));
	});

	socket.on("tournament_end", data => {
		ClientAPI.getStore().dispatch(tournamentEnded({ tournamentId: data.id, missionGroupId: data.mission_group_id }));
	});

	socket.on("tournament_created", data => {
		ClientAPI.getStore().dispatch(tournamentCreated({ tournamentId: data.id, missionGroupId: data.mission_group_id, data: data }));
	});

	socket.on("tournament_position", data => {
		ClientAPI.getStore().dispatch(updatePlayerLeaderBoard({ tournamentId: parseInt(data.id), data: data }));
	});

	socket.on("momentum_award_created", data => {
		receivedMomentumMessage("momentum_award_created", data);
	});

	socket.on("momentum_award_canceled", data => {
		receivedMomentumMessage("momentum_award_canceled", data);
	});

	socket.on("momentum_award_expired", data => {
		receivedMomentumMessage("momentum_award_expired", data);
	});

	socket.on("momentum_award_completed", data => {
		receivedMomentumMessage("momentum_award_completed", data);
	});

	socket.on("momentum_award_timeout", data => {
		receivedMomentumMessage("momentum_award_timeout", data);
	});

	socket.on("momentum_award_done", data => {
		receivedMomentumMessage("momentum_award_done", data);
	});

	socket.on("momentum_award_progress", data => {
		receivedMomentumMessage("momentum_award_progress", data);
	});

	socket.on("momentum_status", data => {
		receivedMomentumMessage("momentum_status", data);
	});

};

export function* websocketPlayerLoginSaga(action) {
	//console.log("doing player login", action);

	if (socket === null) {
		return;
	}

	if (["user", "token"].indexOf(action.authType) > -1) {
		yield socket.emit("login", {
			token: "Bearer " + action.accessToken
		});
	}
}

export function* websocketPlayerLogoutSaga() {
	//console.log("doing player logout");

	if (socket === null) {
		return;
	}

	socket.emit("logout");
}
