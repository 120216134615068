import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';

import "./dialog-objectives.scss";
import Objective from "../components/objective";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogObjectives = (props) => {
  const { open, onClose, t } = props;

  let missionNo = 10;
  let objectives = [
    {
      type: "1",
      title: "Win at <strong>Free Spins</strong>",
      progress: 2532,
      total: 5000,
      currency: "Lei",
    },
    {
      type: "2",
      title: "Win stake <strong>x5</strong>",
      progress: 15,
      total: 30,
      currency: "",
    },
    {
      type: "3",
      title: "Win <strong>3000</strong> Lei in total. Only non-bonus stakes are qualifying.",
      progress: 100,
      total: 100,
      currency: "",
    }
  ];

  // don't insert anything into the DOM if we're not opened
  if (!open) return null;

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className="missions-tournaments-dialog-objectives">
      <div className="mt-body">
        <div className="mt-header">
          <div className="mt-mission">
            <div className="mt-icon">
              <div className="mt-info">
                <div className="mt-subtitle">{t("Mission")}</div>
                <div className="mt-mission-no">{missionNo}</div>
              </div>
            </div>
          </div>
          <div className="mt-close" onClick={onClose}>
            <div className="mt-close-wrapper">
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="mt-title">{t("Objectives")}</div>
        {objectives.map((objective, index) => (<Objective key={index} index={index} total={objectives.length} objective={objective} />))}
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state, props) => {
  return {
  };
};

export default withTranslation()(connect(mapStateToProps)(DialogObjectives));