import React, { useState, useEffect, createRef } from "react";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../store/actions";
import "./Home.scss";
import Icons from "./../../assets/Icons/Icons";
//import BannerImage from "./../../assets/Images/Home/banner_main.png";
import SlotImage from "./../../assets/Images/Home/tile_slots.jpg";
import TableGamesImage from "./../../assets/Images/Home/tile_tablegames.jpg";
import BettingImage from "./../../assets/Images/Home/tile_betting.jpg";
import LiveBettingImage from "./../../assets/Images/Home/tile_livebetting.jpg";
import SportsImage from "./../../assets/Images/Home/tile_sports.jpg";
import WinnerSportsImage from "./../../assets/Images/Home/tile_winner_sports.jpg";
import WinnerFunImage from "./../../assets/Images/Home/tile_winner_fun.jpg";
import WinnerFunImageSmall from "./../../assets/Images/Home/img_homescreen_tile_winnerfun_small.png";
import LiveGamesImage from "./../../assets/Images/Home/tile_livegames.jpg";
import JackpotsImage from "./../../assets/Images/Home/tile_jackpots.jpg";
import JackpotsImageVariant7 from "./../Jackpot/HeaderFront/assets/category-mask-small@3x.jpg";
import JackpotsImageVariant20 from "./../Jackpot/HeaderFront/assets/type-four-bg.jpg";
import VirtualsBannerLarge from "./../../assets/Images/Home/img-virtuals-large.jpg";
import VirtualsBanner from "./../../assets/Images/Home/img-virtuals-medium.jpg";
import LottoBannerLarge from "./../../assets/Images/Home/img-loto-large.jpg";
import LottoBanner from "./../../assets/Images/Home/img-loto-small.jpg";
import HeaderFront from "./../Jackpot/HeaderFront";
import PromotionCards from "./../PromotionCards";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Utils from "../../utils/Utils";

/** Eldorado **/
import EldoradoSlotImage from "./../../assets/Images/Home/eldorado_tile_slots.png";
import EldoradoTableGamesImage from "./../../assets/Images/Home/tile_tablegames.jpg";
import EldoradoBettingImage from "./../../assets/Images/Home/eldorado_tile_betting.png";
import EldoradoLiveGamesImage from "./../../assets/Images/Home/eldorado_tile_livegames.png";
// import EldoradoVirtualsBannerLarge from "./../../assets/Images/Home/img-virtuals-large.jpg";
import EldoradoPromotionsImage from "./../../assets/Images/Home/eldorado_tile_promotions.png";

// import PixiCarousel from "../PixiCarousel";
// import WebBannerWelcome from "../../assets/Banners/banner-welcomebonus-web.jpg";
// import MobileBannerWelcome from "../../assets/Banners/banner-welcomebonus-mobile.jpg";
// import WebBannerNewGames from "../../assets/Banners/banner-newgames-web.jpg";
// import MobileBannerNewGames from "../../assets/Banners/banner-newgames-mobile.jpg"
// import SlotBanner from "../Banners/SlotBanner";
// import NewGamesBanner from "../Banners/NewGamesBanner";

import Slider from "../Slider";

import HappyHourCarousel from "../HappyHours/views/carousel";
import DinamicImage from "../UI/DinamicImage/DinamicImage";
import Tile1 from "../../assets/Images/PromotionCards/img---homepage---tile---promo---welcomebonus@3x.jpg";
import MobileAppBanner from "../MobileAppBanner/MobileAppBanner";

import { showMaintenanceModal } from "../../maintenance/store/actions/config";

import WheelStatus from "../../wheel/components/cta-lobby";
import WebsiteSwitcher from "../../lobby/components/website_switcher/website_switcher";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  /*
  banner: {
    backgroundImage: `url(${BannerImage})`
  },
  */
  container: {
    padding: theme.spacing(0.5, 0)
  },
  slotMedia: {
    // height: theme.spacing(26),
    width: "100%"
  },
  twoTiles: {
    height: "165px",
    width: "100%"
  },
  twoTilesSmall: {
    height: "67px",
    width: "100%"
  },
  bettingMedia: {
    // height: theme.spacing(24),
    width: "100%"
  },
  card: {
    padding: theme.spacing(0)
  },
  cardContent: {
    position: "absolute",
    bottom: 0
  },
  progressColorPrimary: {
    color: "#ffb400"
  },
  liveBettingText: {
    lineHeight: "0.95 !important",
    fontSize: "1.05rem"
  },
  textHeader: {
    textAlign: "left",
    color: "#000",
    fontSize: "22px",
    lineHeight: "22px",
    paddingRight: "2rem",
    marginBottom: "0px",
    paddingBottom: "15px",
    "& .icon": {
      paddingRight: "5px",
      display: "block",
      marginRight: "0px",
      float: "left",
      "& svg": {
        width: "20px",
        height: "20px"
      }
    },
    "& .text": {
      fontWeight: "bold",
      position: "relative",
      top: "2px"
    }
  },
  mReset: {
    paddingBottom: "5px",
    paddingTop: "20px"
  },
  greyedOut: {
    filter: "grayscale(100%)"
  }
});

const iconsHome = {
  slots: Icons.get("cherries", "cherries home"),
  jackpots: Icons.get("jackpots", "jackpots home"),
  betting: Icons.get("betting", "betting home"),
  live: Icons.get("live_casino", "live_casino home"),
  liveBetting: Icons.get("nsoft_live", "nsoft_live home"),
  sports: Icons.get("nsoft_prematch", "nsoft_prematch home"),
  fire: Icons.get("deposit", "deposit home"),
  fireFront: Icons.get("fire_front", "home"),
  watchFront: Icons.get("watch_front", "home"),
  lotto: Icons.get("lotto", "live_casino home"),
  virtuals: Icons.get("live_casino", "jackpots home"),
  winnerFun: Icons.get("deposit", "winner-fun-icon home"),
};

let pageMounted = false;

let scaleTimer = 0;
let resetScaleTimer = 0;
let messageTimer = 0;

const Home = props => {
  const { t } = useTranslation();
  const classes = props.classes;

  if (window.config && window.config.landing_page) {
    let storage = window.config.landing_page_session_redirect === '1' ? window.sessionStorage : window.localStorage;
    let landingPage = storage.getItem("landing_page");
    if (landingPage !== window.config.landing_page) {
      if (window.location.pathname === "/") {
        props.history.push(window.config.landing_page);
      }
      storage.setItem("landing_page", window.config.landing_page);
    }
  }

  //const [showBanner, setBannerState] = useState(false);
  const [showMessage, setMessageState] = useState(false);
  const [scale, setScale] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);

  const [slotsBackdrops, setSlots] = useState("");
  const [jackpotsBackdrops, setJackpots] = useState("");
  const [bettingBackdrops, setBetting] = useState("");
  const [liveBettingBackdrops, setLiveBetting] = useState("");
  const [sportsBackdrops, setSports] = useState("");
  const [lottoBackdrops, setLotto] = useState("");
  const [virtualsBackdrops, setVirtuals] = useState("");
  const [liveBackdrops, setLive] = useState("");
  const [tournamentsBackdrops, setTournaments] = useState("");

  const slotImgRef = createRef();
  const jpImgRef = createRef();
  const betsImgRef = createRef();
  const liveBettingImgRef = createRef();
  const promotionsImgRef = createRef();
  const sportsImgRef = createRef();
  const lottoImgRef = createRef();
  const virtualsImgRef = createRef();
  const liveImgRef = createRef();
  const tImgRef = createRef();
  //const bannerRef = createRef();

  let pType = "root-level";
  let pCode = "home";

  useEffect(() => {
    pageMounted = true;
    props.pageType(pType);
    props.pageCode(pCode);

    setMessageState(false);
    //setBannerState(false);

    props.isRequestGameCollections();

    if (slotImgRef.current.complete) {
      setSlots("loaded");
    } else {
      slotImgRef.current.onload = () => {
        if (!pageMounted) {
          return;
        }
        setSlots("loaded");
      };
    }

    if (jpImgRef.current.complete) {
      setJackpots("loaded");
    } else {
      jpImgRef.current.onload = () => {
        if (!pageMounted) {
          return;
        }
        setJackpots("loaded");
      };
    }

    if ((window.config.brand_name_short !== "Winner") &&
      ((window.config && window.config.betsEnabled === "1") ||
        !(window.config && window.config.nsoftEnabled === "1"))
    ) {
      if (betsImgRef.current.complete) {
        setBetting("loaded");
      } else {
        betsImgRef.current.onload = () => {
          if (!pageMounted) {
            return;
          }
          setBetting("loaded");
        };
      }
    } else {
      if (liveBettingImgRef && liveBettingImgRef.current && liveBettingImgRef.current.complete) {
        setLiveBetting("loaded");
      } else {
        if (liveBettingImgRef && liveBettingImgRef.current) {
          liveBettingImgRef.current.onload = () => {
            if (!pageMounted) {
              return;
            }
            setLiveBetting("loaded");
          };
        }
      }

      if (sportsImgRef && sportsImgRef.current && sportsImgRef.current.complete) {
        setSports("loaded");
      } else {
        if (sportsImgRef && sportsImgRef.current) {
          sportsImgRef.current.onload = () => {
            if (!pageMounted) {
              return;
            }
            setSports("loaded");
          };
        }
      }
    }

    if (window.config && window.config.lottoEnabled === "1") {
      if (lottoImgRef.current.complete) {
        setLotto("loaded");
      } else {
        lottoImgRef.current.onload = () => {
          if (!pageMounted) {
            return;
          }
          setLotto("loaded");
        };
      }
    }

    if (window.config && window.config.virtualsEnabled === "1") {
      if (virtualsImgRef.current.complete) {
        setVirtuals("loaded");
      } else {
        virtualsImgRef.current.onload = () => {
          if (!pageMounted) {
            return;
          }
          setVirtuals("loaded");
        };
      }
    }

    if (liveImgRef.current.complete) {
      setLive("loaded");
    } else {
      liveImgRef.current.onload = () => {
        if (!pageMounted) {
          return;
        }
        setLive("loaded");
      };
    }
    if (window.config.brand_name_short !== 'Eldorado' && window.config.brand_name_short !== 'Excelbet') {
      if (tImgRef.current.complete) {
        setTournaments("loaded");
      } else {
        tImgRef.current.onload = () => {
          if (!pageMounted) {
            return;
          }
          setTournaments("loaded");
        };
      }
    }


    if (!props.application.promotions.length) {
      props.getPromotions();
    }

    /*
    bannerRef.current.onload = () => {
      if (!pageMounted) {
        return;
      }
      // console.log(`[BANNER] Image banner loaded!`);
      setBannerState(true);
    };
    */

    return () => {
      pageMounted = false;
      clearTimeout(scaleTimer);
      clearTimeout(resetScaleTimer);
      clearTimeout(messageTimer);
      //setBannerState(false);
      setMessageState(false);
      setScale(false);
      window._CanGoBack = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!pageMounted) {
      return;
    }
    let gridElemsHeight = Number.parseInt(window.innerWidth / 2.3);
    slotImgRef.current.height = gridElemsHeight;
    jpImgRef.current.height = gridElemsHeight;
    liveImgRef.current.height = gridElemsHeight;
    if (window.config.brand_name_short !== 'Eldorado' && window.config.brand_name_short !== 'Excelbet') {
      tImgRef.current.height = gridElemsHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const hideWelcomeMessage = () => {
    if (!pageMounted) {
      return;
    }
    setShowWelcome(false);
  };

  const createUrl = (pageCode, pageType = null) => {
    let url = "/";

    if (!pageType) {
      pageType = "root-level";
    }

    if (!props.menu.allowedPageTypes.includes(pageType)) {
      return url;
    }

    if ("root-level" === pageType) {
      url += pageCode;
      return url;
    }

    let page = props.menu.items[pageType];
    if (!page) {
      return url;
    }

    url += page.code;
    if (!page.hasOwnProperty("submenus")) {
      return url;
    }

    let subpage = page.submenus.find(sItem => pageCode === sItem.code);
    if (!subpage) {
      return url;
    }

    url += `/${subpage.code}`;
    return url;
  };

  const clickPageHandler = (pageCode, pageType = null) => {
    const pageCodeToProductName = {
      "jocuri": "product_slots",
      "jackpot": "product_slots",
      "pariuri/live-match/highlights": "product_bets_live",
      "pariuri": "product_bets",
      "lotto": "product_lotto",
      "virtuale": "product_virtuals",
      "cazino_live": "product_casino_live",
    };

    if (pageCodeToProductName[pageCode] && typeof props.productsMaintenance[pageCodeToProductName[pageCode]] !== "undefined") {
      props.showMaintenanceModal(props.productsMaintenance[pageCodeToProductName[pageCode]]);
      return;
    }

    console.log(`[CARD CLICK] Pressed on pageCode: ${pageCode}`);
    let url = "";
    if (pageCode === "lotto") {
      url = "/lotto/home";
    } else if (pageCode === "winner-fun") {
      url = "/winner-fun";
    } else if (pageCode === "cazino_live") {
      url = "/casino/livecasino";
    } else if (pageCode === "jackpot") {
      if (window.config.lobbyEnabled === "1") {
        let defPath = 'lobby';
        if (window.config.rootPaths && window.config.rootPaths.casino) {
          defPath = window.config.rootPaths.casino;
        }

        url = `/${defPath}/slots/egt-jackpot-cards`;
      } else {
        if (!props.games.loadingGameCollections && props.games.gameCollections && props.games.gameCollections.length > 0) {
          let collections = props.games.gameCollections.filter(Boolean);
          for (let i = 0; i < collections.length; i++) {
            if (collections[i].jackpot_id) {
              url = `/cazino/collection/` + collections[i].id;
              break;
            }
          }
        }
      }
    } else {
      // clickPageHandler.bind(this, "jocuri", "cazino")
      let pType = pageType;

      if (pType === "cazino") {
        if (window.config.lobbyEnabled === "1") {
          if (window.config.rootPaths && window.config.rootPaths.casino) {
            pType = window.config.rootPaths.casino;
          }
        }
      }

      url = createUrl(pageCode, pType);
    }
    props.history.push(url);
  };

  const validURL = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const decodeHTML = (string) => {
    let txt = document.createElement('textarea');
    txt.innerHTML = string;
    return txt.value;
  };

  const getBadges = (type, hasLogo = false) => {

    let tileBG = window.config['tile_' + type + '_label_bg'];
    let tileTextColor = window.config['tile_' + type + '_label_text_color'];
    let tileTextEN = window.config['tile_' + type + '_label_text_en'];
    let tileTextRO = window.config['tile_' + type + '_label_text_ro'];
    if (i18next.language && tileBG) {
      let labelTXT = '';
      if (validURL(tileBG)) {

        if ((i18next.language === 'en' && tileTextEN)) {
          labelTXT = tileTextEN;
        } else if (i18next.language === 'ro' && window.config.tile_betting_label_text_ro) {
          labelTXT = tileTextRO;
        } else if (tileTextRO || tileTextEN) {
          if (tileTextRO) {
            labelTXT = tileTextRO;
          } else if (tileTextEN) {
            labelTXT = tileTextEN;
          }
        } else {
          return '';
        }

        return (
          <div style={{ backgroundImage: 'url(' + tileBG + ')', color: tileTextColor ? tileTextColor : '#000000' }} className={"badge"}>
            {decodeHTML(labelTXT)}
            {hasLogo ? <div className="logo">{Icons.get("egt_logo")}</div> : ''}
          </div>
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const animItems = [
    <Grid key="g_1" container spacing={1} className={classes.container}>
      <Grid item xs={12} className={"gridTitle" + (showWelcome ? "" : " hide")}>
        <p>{t("Welcome")}</p>
        <h1>{Utils.t("What will you play?")}</h1>
      </Grid>
    </Grid>,
    <Grid key="g_happy_hours_1" container spacing={1} className={classes.container}>
      <Grid item xs={12}>
        <HappyHourCarousel />
      </Grid>
      {props.hhRunning && (
        <Grid item xs={12}>
          <h1 className={`${classes.textHeader} ${classes.mReset}`}>
            <span className="icon">{iconsHome.watchFront}</span>
            <span className="text">{Utils.t("What will you play?")}</span>
            <div
              style={{
                clear: "both"
              }}
            ></div>
          </h1>
        </Grid>
      )}
    </Grid>
  ];

  const slots = <Grid item xs={7}>
    <Card>
      <CardActionArea
        className={`${classes.card} ${typeof props.productsMaintenance['product_slots'] !== "undefined" ? classes.greyedOut : ''}`}
        onClick={clickPageHandler.bind(this, "", "cazino")}
      >
        <CardMedia
          // image={`${window.config.front_url}/img/mobile/home/tile_slots.png`}
          className={`${classes.slotMedia} cardImage`}
          src={Utils.getImageUrl(window.config.brand_name_short !== 'Eldorado' ? SlotImage : EldoradoSlotImage)}
          title={"slot games"}
          component={"img"}
          ref={slotImgRef}
        />
        <CardContent className={`${classes.cardContent} cardDesc`}>
          <ListItemIcon>{iconsHome.slots}</ListItemIcon>
          <Typography component="p">{t("Slots")}</Typography>
          {getBadges('slots', true)}
        </CardContent>
        <div className={`cardBackdrop ${slotsBackdrops}`}>
          <CircularProgress className={classes.progressColorPrimary} />
        </div>
      </CardActionArea>
    </Card>
  </Grid>;

  const jackpot = <Grid item xs={5}>
    <Card>
      <CardActionArea
        className={`${classes.card} ${typeof props.productsMaintenance['product_slots'] !== "undefined" ? classes.greyedOut : ''}`}
        onClick={clickPageHandler.bind(this, "jackpot", "cazino")}
        type={"button"}
      >
        <CardMedia
          // image={`${window.config.front_url}/img/mobile/home/tile_slots.png`}
          className={`${classes.slotMedia} cardImage`}
          src={Utils.getImageUrl(
            window.config && window.config.jackpotFrontVariant === "7" ? JackpotsImageVariant7 :
              window.config && window.config.jackpotFrontVariant === "20" ? JackpotsImageVariant20 : JackpotsImage
          )}
          title={"jackpots"}
          component={"img"}
          ref={jpImgRef}
        />
        <div className={`${classes.cardContent} cardContent`} style={{ paddingBottom: "43px" }}>
          <HeaderFront name="EGT" />
        </div>
        <CardContent className={`${classes.cardContent} cardDesc`}>
          <ListItemIcon>{iconsHome.jackpots}</ListItemIcon>
          <Typography component="p">{t("Jackpots")}</Typography>
        </CardContent>
        <div className={`cardBackdrop ${jackpotsBackdrops}`}>
          <CircularProgress className={classes.progressColorPrimary} />
        </div>
      </CardActionArea>
    </Card>
  </Grid>;

  const betsEnabled = window.config && window.config.betsEnabled === "1";
  const nsoftEnabled = window.config && window.config.nsoftEnabled === "1";
  const winnerFunEnabled = window.config && window.config.winnerFunEnabled === "1";
  const wheelEnabled = window.config && window.config.wheelEnabled === "1";

  if (wheelEnabled) {

    const wheelData = {
      "id": "id_wheel_status",
      "name": "Wheel Status",
      "type": "wheel_status",
      "data_source": null,
      "data_source_type": "",
      "data_source_style": "",
      "data_source_style_mobile": {
        "style": ""
      },
      "data_source_style_desktop": {
        "style": ""
      },
      "data_source_items": {},
      "style": "",
      "where": [
        "all"
      ],
      "active": true,
      "timeEnabled": false,
      "startTime": 0,
      "endTime": 4102437600
    };

    animItems.push(<Grid key="g_wheel-status" container spacing={1} className={classes.container}>
      <Grid item xs={12}>
        <WheelStatus key={"wheel-status"} data={wheelData} />
      </Grid>
    </Grid>);
  }


  if (betsEnabled && window.config.brand_name_short === "Winner") {

    let winnerFunContent = null;
    if (winnerFunEnabled) {
      winnerFunContent = (<Grid key="g_wf" container spacing={1} className={classes.container}>
        <Grid item xs={12}>
          <Card>
            <CardActionArea
              className={`${classes.card}`}
              onClick={clickPageHandler.bind(this, "winner-fun", null)}
              type={"button"}
            >
              <CardMedia
                className={`${classes.twoTilesSmall} cardImage`}
                src={Utils.getImageUrl(WinnerFunImageSmall)}
                title={"sports"}
                component={"img"}
                ref={sportsImgRef}
              />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>)
    }

    // special case just for Winner
    animItems.push(
      winnerFunContent,
      <Grid key="g_3" container spacing={1} className={classes.container}>
        <Grid item xs={7}>
          <Card>
            <CardActionArea
              className={`${classes.card} ${typeof props.productsMaintenance['product_bets_prematch'] !== "undefined" ? classes.greyedOut : ''}`}
              onClick={clickPageHandler.bind(this, "pariuri", null)}
              type={"button"}
            >
              <CardMedia
                className={`${classes.twoTiles} cardImage`}
                src={Utils.getImageUrl(WinnerSportsImage)}
                title={"sports"}
                component={"img"}
                ref={sportsImgRef}
              />
              <CardContent className={`${classes.cardContent} cardDesc`}>
                <ListItemIcon>{iconsHome.sports}</ListItemIcon>
                <Typography component="p">{t("Sports")}</Typography>
                {/*<div className={"badge"}>{t("Coming Soon")}</div>*/}
              </CardContent>
              <div className={`cardBackdrop ${sportsBackdrops}`}>
                <CircularProgress className={classes.progressColorPrimary} />
              </div>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card>
            <CardActionArea
              className={`${classes.card} ${typeof props.productsMaintenance['product_bets_live'] !== "undefined" ? classes.greyedOut : ''}`}
              onClick={clickPageHandler.bind(this, "pariuri/live-match/highlights", null)}
              type={"button"}
            >
              <CardMedia
                className={`${classes.twoTiles} cardImage`}
                src={Utils.getImageUrl(LiveBettingImage)}
                title={"live betting"}
                component={"img"}
                ref={liveBettingImgRef}
              />
              <CardContent className={`${classes.cardContent} cardDesc`}>
                <ListItemIcon>{iconsHome.liveBetting}</ListItemIcon>
                <Typography component="p">
                  <span className={classes.liveBettingText}>{t("LIVE\nBetting")}</span>
                </Typography>
                {/*<div className={"badge"}>{t("Coming Soon")}</div>*/}
              </CardContent>
              <div className={`cardBackdrop ${liveBettingBackdrops}`}>
                <CircularProgress className={classes.progressColorPrimary} />
              </div>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>,
      <Grid key="g_2" container spacing={1} className={classes.container}>
        {jackpot}
        {slots}
      </Grid>,
    );
  } else {
    animItems.push(
      <Grid key="g_2" container spacing={1} className={classes.container}>
        {slots}
        {jackpot}
      </Grid>
    );

    // if modern bets are enabled or no bets are enabled at all we add one tile
    if (betsEnabled || !nsoftEnabled) {
      animItems.push(
        <Grid key="g_3" container spacing={1} className={classes.container}>
          <Grid item xs={12}>
            <Card>
              <CardActionArea
                className={`${classes.card} ${typeof props.productsMaintenance['product_bets'] !== "undefined" ? classes.greyedOut : ''}`}
                onClick={clickPageHandler.bind(this, "pariuri", null)}
                type={"button"}
              >
                <CardMedia
                  className={`${classes.bettingMedia} cardImage`}
                  src={Utils.getImageUrl(window.config.brand_name_short !== 'Eldorado' ? BettingImage : EldoradoBettingImage)}
                  title={"betting"}
                  component={"img"}
                  ref={betsImgRef}
                />
                <CardContent className={`${classes.cardContent} cardDesc`}>
                  <ListItemIcon>{iconsHome.betting}</ListItemIcon>
                  <Typography component="p">{t("Betting")}</Typography>
                  {window.config &&
                    window.config.betsEnabled !== "1" &&
                    window.config.nsoftEnabled !== "1" && (
                      getBadges('betting')
                    )}
                </CardContent>
                <div className={`cardBackdrop ${bettingBackdrops}`}>
                  <CircularProgress className={classes.progressColorPrimary} />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      );
    } else {
      // classic bets are enabled and modern bets are disabled
      animItems.push(
        <Grid key="g_3" container spacing={1} className={classes.container}>
          <Grid item xs={5}>
            <Card>
              <CardActionArea
                className={classes.card}
                onClick={clickPageHandler.bind(this, "pariuri/live", null)}
                type={"button"}
              >
                <CardMedia
                  className={`${classes.twoTiles} cardImage`}
                  src={Utils.getImageUrl(LiveBettingImage)}
                  title={"live betting"}
                  component={"img"}
                  ref={liveBettingImgRef}
                />
                <CardContent className={`${classes.cardContent} cardDesc`}>
                  <ListItemIcon>{iconsHome.liveBetting}</ListItemIcon>
                  <Typography component="p">
                    <span className={classes.liveBettingText}>{t("LIVE\nBetting")}</span>
                  </Typography>
                  {/*<div className={"badge"}>{t("Coming Soon")}</div>*/}
                </CardContent>
                <div className={`cardBackdrop ${liveBettingBackdrops}`}>
                  <CircularProgress className={classes.progressColorPrimary} />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={7}>
            <Card>
              <CardActionArea
                className={classes.card}
                onClick={clickPageHandler.bind(this, "pariuri/prematch", null)}
                type={"button"}
              >
                <CardMedia
                  className={`${classes.twoTiles} cardImage`}
                  src={Utils.getImageUrl(SportsImage)}
                  title={"sports"}
                  component={"img"}
                  ref={sportsImgRef}
                />
                <CardContent className={`${classes.cardContent} cardDesc`}>
                  <ListItemIcon>{iconsHome.sports}</ListItemIcon>
                  <Typography component="p">{t("Sports")}</Typography>
                  {/*<div className={"badge"}>{t("Coming Soon")}</div>*/}
                </CardContent>
                <div className={`cardBackdrop ${sportsBackdrops}`}>
                  <CircularProgress className={classes.progressColorPrimary} />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      );
    }
  }

  // if virtuals and lotto are both disabled don't add anything
  if (
    window.config &&
    (window.config.virtualsEnabled === "1" || window.config.lottoEnabled === "1")
  ) {
    // if both are enabled
    if (window.config.virtualsEnabled === "1" && window.config.lottoEnabled === "1") {
      animItems.push(
        <Grid key="g_3_5" container spacing={1} className={classes.container}>
          <Grid item xs={5}>
            <Card>
              <CardActionArea
                className={`${classes.card} ${typeof props.productsMaintenance['product_lotto'] !== "undefined" ? classes.greyedOut : ''}`}
                onClick={clickPageHandler.bind(this, "lotto", null)}
                type={"button"}
              >
                <CardMedia
                  className={`${classes.twoTiles} cardImage`}
                  src={Utils.getImageUrl(LottoBanner)}
                  title={"lotto games"}
                  component={"img"}
                  ref={lottoImgRef}
                />
                <CardContent className={`${classes.cardContent} cardDesc`}>
                  <ListItemIcon>{iconsHome.lotto}</ListItemIcon>
                  <Typography component="p">{t("Lotto")}</Typography>
                  {/*<div className={"badge"}>{t("Coming Soon")}</div>*/}
                </CardContent>
                <div className={`cardBackdrop ${lottoBackdrops}`}>
                  <CircularProgress className={classes.progressColorPrimary} />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={7}>
            <Card>
              <CardActionArea
                className={`${classes.card} ${typeof props.productsMaintenance['product_virtuals'] !== "undefined" ? classes.greyedOut : ''}`}
                onClick={clickPageHandler.bind(this, "virtuale", null)}
                type={"button"}
              >
                <CardMedia
                  className={`${classes.twoTiles} cardImage`}
                  src={Utils.getImageUrl(VirtualsBanner)}
                  title={"virtual games"}
                  component={"img"}
                  ref={virtualsImgRef}
                />
                <CardContent className={`${classes.cardContent} cardDesc`}>
                  <ListItemIcon>{iconsHome.virtuals}</ListItemIcon>
                  <Typography component="p">{t("Virtuals")}</Typography>
                  {/*<div className={"badge"}>{t("Coming Soon")}</div>*/}
                </CardContent>
                <div className={`cardBackdrop ${virtualsBackdrops}`}>
                  <CircularProgress className={classes.progressColorPrimary} />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      );
    } else if (window.config.lottoEnabled === "1") {
      // only lotto enabled
      animItems.push(
        <Grid key="g_3_5" container spacing={1} className={classes.container}>
          <Grid item xs={12}>
            <Card>
              <CardActionArea
                className={`${classes.card} ${typeof props.productsMaintenance['product_lotto'] !== "undefined" ? classes.greyedOut : ''}`}
                onClick={clickPageHandler.bind(this, "lotto", null)}
                type={"button"}
              >
                <CardMedia
                  className={`${classes.twoTiles} cardImage`}
                  src={Utils.getImageUrl(LottoBannerLarge)}
                  title={"lotto games"}
                  component={"img"}
                  ref={lottoImgRef}
                />
                <CardContent className={`${classes.cardContent} cardDesc`}>
                  <ListItemIcon>{iconsHome.lotto}</ListItemIcon>
                  <Typography component="p">{t("Lotto")}</Typography>
                  {/*<div className={"badge"}>{t("Coming Soon")}</div>*/}
                </CardContent>
                <div className={`cardBackdrop ${lottoBackdrops}`}>
                  <CircularProgress className={classes.progressColorPrimary} />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      );
    } else if (window.config.virtualsEnabled === '1') {
      // only virtuals enabled
      animItems.push(
        <Grid key="g_3_5" container spacing={1} className={classes.container}>
          <Grid item xs={12}>
            <Card>
              <CardActionArea
                className={`${classes.card} ${typeof props.productsMaintenance['product_virtuals'] !== "undefined" ? classes.greyedOut : ''}`}
                onClick={clickPageHandler.bind(this, "virtuale", null)}
                type={"button"}
              >
                <CardMedia
                  className={`${classes.twoTiles} cardImage`}
                  src={Utils.getImageUrl(VirtualsBannerLarge)}
                  title={"virtual games"}
                  component={"img"}
                  ref={virtualsImgRef}
                />
                <CardContent className={`${classes.cardContent} cardDesc`}>
                  <ListItemIcon>{iconsHome.virtuals}</ListItemIcon>
                  <Typography component="p">{t("Virtuals")}</Typography>
                  {/*<div className={"badge"}>{t("Coming Soon")}</div>*/}
                </CardContent>
                <div className={`cardBackdrop ${virtualsBackdrops}`}>
                  <CircularProgress className={classes.progressColorPrimary} />
                </div>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      );
    }
  }
  // add rest
  animItems.push(
    <Grid key="g_4" container spacing={1} className={classes.container}>
      <Grid item xs={window.config.brand_name_short !== 'Eldorado' && window.config.brand_name_short !== 'Excelbet' ? 7 : 12}>
        <Card>
          <CardActionArea
            className={`${classes.card} ${typeof props.productsMaintenance['product_casino_live'] !== "undefined" ? classes.greyedOut : ''}`}
            onClick={clickPageHandler.bind(this, "cazino_live", null)}
            type={"button"}
          >
            <CardMedia
              // image={`${window.config.front_url}/img/mobile/home/tile_slots.png`}
              className={`${classes.slotMedia} cardImage`}
              src={Utils.getImageUrl(window.config.brand_name_short !== 'Eldorado' && window.config.brand_name_short !== 'Excelbet' ? LiveGamesImage : EldoradoLiveGamesImage)}
              title={"live games"}
              component={"img"}
              ref={liveImgRef}
            />
            <CardContent className={`${classes.cardContent} cardDesc`}>
              <ListItemIcon>{iconsHome.live}</ListItemIcon>
              <Typography component="p">{t("LIVE")}</Typography>
            </CardContent>
            <div className={`cardBackdrop ${liveBackdrops}`}>
              <CircularProgress className={classes.progressColorPrimary} />
            </div>
          </CardActionArea>
        </Card>
      </Grid>
      {window.config.brand_name_short !== 'Eldorado' && window.config.brand_name_short !== 'Excelbet' && <Grid item xs={5}>
        <Card>
          <CardActionArea
            className={classes.card}
            onClick={clickPageHandler.bind(this, "table-games#roulette", null)}
            type={"button"}
          >
            <CardMedia
              // image={`${window.config.front_url}/img/mobile/home/tile_slots.png`}
              className={`${classes.slotMedia} cardImage`}
              src={Utils.getImageUrl(window.config.brand_name_short !== 'Eldorado' ? TableGamesImage : EldoradoTableGamesImage)}
              title={t("homepage_table_games_big_font") + t("homepage_table_games_small_font")}
              component={"img"}
              ref={tImgRef}
            />
            <CardContent className={`${classes.cardContent} cardDesc`}>
              <ListItemIcon>{iconsHome.fire}</ListItemIcon>
              <Typography component="p">
                <span>{t("homepage_table_games_big_font")}</span>
                <span className="small">{t("homepage_table_games_small_font")}</span>
              </Typography>
              {getBadges('table_games')}
            </CardContent>
            <div className={`cardBackdrop ${tournamentsBackdrops}`}>
              <CircularProgress className={classes.progressColorPrimary} />
            </div>
          </CardActionArea>
        </Card>
      </Grid>}
    </Grid>,
    <Grid key="g_5" container spacing={4} className={`${classes.container} promotions`}>
      <Grid item xs={12} style={{ padding: 0 }}>

        {window.config.brand_name_short !== 'Eldorado' && <PromotionCards
          title1={
            props.application.language === 'en' ? ("RECEIVE " + (window.config.WELCOME_BONUS_VALUE ? window.config.WELCOME_BONUS_VALUE : -1) + " LEI BONUS!")
              : t("IA " + (window.config.WELCOME_BONUS_VALUE ? window.config.WELCOME_BONUS_VALUE : -1) + " LEI BONUS!")
          }
          title2={t("FREE 50 LEI")}
        />}
        {window.config.brand_name_short === 'Eldorado' &&
          <div className="promotions-cards">
            <div className="wrapper" /*style={{ backgroundImage: `url(${Utils.getImageUrl(Bg)})` }}*/>
              {<div className="header">
                <div className="title">{t("Don't miss the greatest")}</div>
                <div className="subtitle">{t("PROMOTIONS")}</div>
              </div>}
              <DinamicImage imgLink={Utils.getImageUrl(EldoradoPromotionsImage)} onClick={() => props.history.push('/cazino/promotii')} />
            </div>
          </div>
        }
      </Grid>
    </Grid>
  );

  const handleMessage = value => {
    clearTimeout(messageTimer);
    if (!pageMounted) {
      return;
    }

    messageTimer = setTimeout(() => {
      setMessageState(value);
    }, 500);
  };

  const handleScale = value => {
    clearTimeout(scaleTimer);
    if (!pageMounted) {
      return;
    }

    scaleTimer = setTimeout(() => {
      setScale(value);
    }, 600);
  };

  const resetScale = () => {
    clearTimeout(resetScaleTimer);
    if (!scale) {
      return;
    }

    if (!pageMounted) {
      return;
    }

    resetScaleTimer = setTimeout(() => {
      setScale(false);
    }, 300);
  };

  const goToRegister = () => {
    props.history.push("/register");
  };

  const goToDeposit = () => {
    props.isDeposit();
    props.history.push("/deposit");
  };

  const auth = props.auth;
  const getButtons = () => {
    if (typeof auth !== "undefined" && auth.auth_type !== "user") {
      return (
        <Button
          style={props}
          variant="contained"
          size="large"
          color="primary"
          className={`${classes.margin} depositButton register`}
          onClick={goToRegister}
        >
          {t("Bonus 1000 Lei")}
        </Button>
      );
    } else {
      return (
        <Button
          style={props}
          variant="contained"
          size="large"
          color="primary"
          className={`${classes.margin} depositButton animate`}
          onClick={goToDeposit}
        >
          {t("DEPOSIT")}
        </Button>
      );
    }
  };

  Utils.delayedFunction("hideWelcomeMessage", hideWelcomeMessage, 2000);

  return (
    <div className={`homeScreen ${classes.root}`}>
      <div className={props.application.topMenuHeight}></div>
      {/*
      <Spring
        config={{ easing: easings.easeCubicIn, duration: 800 }}
        from={{ top: showBanner ? -1100 : 0, opacity: 0 }}
        to={{ top: showBanner ? 0 : -1100, opacity: 1 }}
        onRest={() => {
          handleMessage(showBanner);
        }}
      >
        {springProps => (
          <animated.div className={`homeBanner`} style={springProps}>
            <img
              src={Utils.getImageUrl(BannerImage)}
              className={"bannerImage"}
              ref={bannerRef}
              alt={t("Casino animated banner")}
            />
            <Spring
              config={{ easing: easings.easeBackOut, duration: 600 }}
              from={{ bottom: showMessage ? -200 : 0, opacity: 0 }}
              to={{ bottom: showMessage ? 0 : -200, opacity: 1 }}
              onRest={() => {
                handleScale(showMessage);
              }}
            >
              {msgAnimProps => (
                <animated.div
                  className={"homeBannerMessage"}
                  style={msgAnimProps}
                >
                  <div className={"supportBannerText"}>
                    <Typography component="p">
                      {t("HOW MUCH DO YOU WANT TO WIN?")}
                    </Typography>
                  </div>
                  <div className={"supportBannerButt"}>
                    <Spring
                      config={{ easing: easings.easeElasticOut }}
                      from={{ transform: scale ? "scale(1)" : "scale(1.2)" }}
                      to={{ transform: scale ? "scale(1.2)" : "scale(1)" }}
                      // onRest={() => { handleScale(false) } }
                      onStart={() => {
                        resetScale();
                      }}
                    >
                      {props => getButtons()
                      // typeof auth !== 'undefined' && auth.auth_type !== 'user'?
                      // <Button style={props} variant="contained" size="large" color="primary" className={`${classes.margin} depositButton register`} onClick={goToRegister}>
                      //
                      // {t("Register")}
                      // </Button>
                      // :
                      // <Button style={props} variant="contained" size="large" color="primary" className={`${classes.margin} depositButton`} onClick={goToDeposit}>
                      //     {t("DEPUNE")}
                      // </Button>
                      }
                    </Spring>
                  </div>
                </animated.div>
              )}
            </Spring>
          </animated.div>
        )}
      </Spring>
      */}
      <MobileAppBanner />
      <Slider config={props.superBanner} eventPrefix="home" />
      {/*<PixiCarousel>
        <NewGamesBanner
          webImage={WebBannerNewGames}
          mobileImage={MobileBannerNewGames}
          blendColor={0x613029}
          eventName="newGamesBanner"
        />
        <SlotBanner
          webImage={WebBannerWelcome}
          mobileImage={MobileBannerWelcome}
          blendColor={0x613029}
          eventName="slotBanner"
        />
        <KYCBanner
          webImage={WebBannerKYC}
          mobileImage={MobileBannerKYC}
          blendColor={0x8c066d}
          eventName="kycBanner"
        />
        <BetBanner
          webImage={WebSecond}
          mobileImage={MobileSecond}
          blendColor={0x0f345a}
          eventName="betBanner"
        />
      </PixiCarousel>*/}
      {window?.config?.showWebsiteSwitcher === '1' ? <WebsiteSwitcher page={'home'} darkMode={true} /> : null}
      <div className={"gridSupport"}>
        {/*<Trail*/}
        {/*items={animItems}*/}
        {/*from={{ top: 300, opacity: 0 }}*/}
        {/*to={{ top: 0, opacity: 1 }}*/}
        {/*keys={animItems.map((_, i) => i)}*/}
        {/*delay={500}*/}
        {/*>*/}
        {/*{item => animProps => (*/}
        {animItems}
        {/*{item}*/}

        {/*)}*/}
        {/*</Trail>*/}
      </div>
    </div>
  );
};

const mapStateToProps = state => {

  const isRunning = Object.keys(state.happyHour.running).length > 0;
  const maintenance = state.maintenance && state.maintenance.config ? state.maintenance.config : null;

  return {
    application: state.application,
    menu: state.menu,
    games: state.games,
    auth: state.authentication,
    hhRunning: isRunning,
    superBanner: state.config.superBannerCasino || [],
    register: state.register,
    productsMaintenance: maintenance && maintenance.products ? maintenance.products : {},
  };
};

const mapDispatchToProps = dispatch => {
  return {
    pageType: pType => dispatch(storeActions.setPageType(pType)),
    pageCode: pCode => dispatch(storeActions.setPageCode(pCode)),
    isRequestGameCollections: () => dispatch(storeActions.requestGameCollections()),
    isDeposit: () => dispatch(storeActions.isDeposit(true)),
    getPromotions: () => dispatch(storeActions.getPromotions()),
    showMaintenanceModal: (data) => dispatch(showMaintenanceModal(data))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home)));
