import React, { useEffect, useState, Fragment } from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import { withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../store/actions";
import Utils from "../../../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const Aircash = (props) => {
    const {aircashGetPaymentLink,bonusId,loading} = props;

    const { t } = useTranslation();

    const [amount, setAmount] = useState(0);
    const [buttons, setButtons] = useState([]);
    const [isDefaultValue, setIsDefaultValue] = useState(false);


    const { onRequestTaxService,minimumLimit,type, dailyLimit,bonusMax,bonusMin,maximumLimit, taxes } = props;

    const values = [25, 50, 100, 150, 200, 300];

    const buttonsAreaType = type === 'deposit' ? 2 : 1; // 1 - all buttons, 2 - only min and max buttons

    const initButtons = (parentAmount) => {

        if (buttonsAreaType === 1) {
            setButtons(values);
            let amnt = minimumLimit <= maximumLimit ? minimumLimit : maximumLimit;
            Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, amnt, 1),1);
            setAmount(amnt * 100);
            return false;
        }

        // find if amount is in values
        let current =  parentAmount ? parentAmount/100 : amount/100;
        // prevent selecting bigger value than maximumLimit
        if (maximumLimit !== false && maximumLimit < current) {
            current = maximumLimit;
        }
        let preselected = current;
        let preselectedIndex = 0;
        let tempButtons = [];
        let def = false;
        values.every((val,index) => {

            if (current === val) {
                // if is default value stop and do nothing else;
                def=true;
                preselectedIndex = index;
                return false;
            } else if (current > val && current < values[index+1]) {
                // if is between two values, select 6 buttons from current value
                tempButtons = values.slice(index,index+6);
                // preselect button with the next value, if exists;
                if (typeof values[index+1] === "undefined") {
                    preselectedIndex = index;
                } else {
                    preselectedIndex = index+1;
                }
                // if there are not enough buttons, select 6 buttons from previous value
                if (tempButtons.length < 6) {
                    tempButtons = values.slice(index-(6 - tempButtons.length),index+(tempButtons.length));
                }
                return false;
            } else if (current < val) {
                preselectedIndex = index;
                tempButtons = values.slice(index,index+6);

                return false;
            }

            return true;
        } );

        if (typeof tempButtons[0] === "undefined" && !def) {
            tempButtons = values.slice(values.length-6,values.length);
            preselectedIndex = values.length-1;
        }

        while(values[preselectedIndex] > maximumLimit && preselectedIndex > 0) {
            preselectedIndex--;
        }

        preselected = values[preselectedIndex];

        setButtons(tempButtons);
        Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, preselected, 1),1);
        setAmount(preselected*100);
        setIsDefaultValue(def);
    }

    useEffect(() => {
        initButtons();
    }, []);

    useEffect(() => {
        setAmount(props.amount);
        initButtons(props.amount);
    }, [props.amount]);


    useEffect(() => {
        if (isDefaultValue) {
            onConfirmHandler();
        }
    },[isDefaultValue]);


    const onConfirmHandler = () => {
        aircashGetPaymentLink(amount,bonusId > 0 ? bonusId: null);
    }

    const onChangeHandler = (e) => {
        let field;
        let time = 1500;
        if (typeof e.target === "undefined") {
            field = e;
            time = 100;
        } else {
            field = e.target.value;
        }

        field = parseInt(field);

        if (isNaN(field)) {
            field = 0;
        }

        if (maximumLimit !== false && maximumLimit < field) {
            field = maximumLimit;
        }

        if(field > 0) {
            Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, field, 1),time);
        }

        setAmount(field*100);
    }

    if (window?.config?.aircash_enabled !== '1' ) {
        return (<div className={"content register-disabled"} >
            {props.t("Deposit is temporarily unavailable")}
        </div>)
    } else if (loading) {
        return (<div className={"content "} >
            <CircularProgress  className={'Loading'} />
        </div>)
    }

    return (
        <>
                <Grid container
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      key={"aircash-deposit-buttons-container"}
                >
                    <Grid item xs={12} className={'DepositAmount'}>
                        <FormControl className="PlayerDataInput text-center">
                            <InputLabel htmlFor="amount">{t('Deposited amount')}</InputLabel>
                            <Input
                                id="amount"
                                type='text'
                                disabled={true}
                                value={(amount/100) + ' Lei'}
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                <Grid item xs={12} className={'accepted-values'} key={"aircash-deposit-title"}>
                    {t("Amounts available via aircash:")}
                </Grid>
                <Grid container
                      alignItems="center"
                      justifyContent="center"
                      className={'buttons  small-buttons'}
                      spacing={2}
                      key={"aircash-deposit-buttons"}>


                    {buttons && buttons.map(val => (
                        <Grid item xs={4} key={"deposit_buttons_aircash" + val}>
                            <Button
                                className={"depositButton " + (parseInt(amount/100,10) === parseInt(val,10) ? "active" : "")}
                                disabled={(dailyLimit < val && dailyLimit !== null) || minimumLimit > val || maximumLimit < val}
                                onClick={() => onChangeHandler(val)}
                            >
                                <div>{val}</div>
                                {val === bonusMin && (
                                    <div className={'desc'}>MIN BONUS</div>
                                )}
                                {val === bonusMax && bonusMin !== bonusMax && (
                                    <div className={'desc'}>MAX BONUS</div>
                                )}
                            </Button>
                        </Grid>))}



                </Grid>
                <Grid item xs={12} className={'taxes'}>
                    <Grid container>
                        <Grid item xs={6} className={'left'}>
                            <p>{Utils.formatAmount(taxes,0,'RON')}</p>
                            <span>{props.t("CDA 2% (GEO 114)")}</span>
                        </Grid>
                        <Grid item xs={6} className={'right'}>
                            <p>{Utils.formatAmount(amount/100 - taxes,0,'RON')}</p>
                            <span>{props.t("Net deposit")}</span>
                        </Grid>
                    </Grid>
                </Grid>


            <Grid item xs={12} className={'actions'}>
                <Grid container>
                    <Button fullWidth variant="contained" size="large"  className={'active gradient btn-danger w-100 nextButton'} onClick={ onConfirmHandler}>
                        Confirm
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = state => {
    return {
        responsePayload: state.paymentCheckout?.iframeLink,
        loading: state.paymentCheckout.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        aircashGetPaymentLink: (amount,bonusId) => dispatch(storeActions.aircashGetPaymentLink(amount,bonusId)),
        onRequestTaxService: (taxType, amount, id) => dispatch(storeActions.requestTaxService(taxType, amount, id)),

    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Aircash)));