import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { connect } from "react-redux";

import LatestWinnersImage from "../../assets/banner-latest-winners-top.png";
import "./slick.scss";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#151d26",
    margin: "0 1rem",
    borderRadius: "8px",
    minHeight: "100px",
    maxWidth: "calc(100vw - 2rem)"
  },
  image: {
    "& img": {
      display: "block",
      width: "auto",
      height: "100px",
      borderRadius: "8px"
    }
  },
  textHolder: {
    padding: "0 10px"
  },
  header: {
    color: "#FFB400",
    fontSize: "12px",
    fontWeight: "bold",
    borderBottom: "1px solid rgba(255, 180, 0,.3)",
    textTransform: "uppercase",
    marginBottom: "5px",
    paddingBottom: "5px",
    paddingTop: "10px",
    fontFamily: '"Open Sans", "OpenSans"'
  },
  amount: {
    color: "#FFF",
    fontSize: "22px",
    fontWeight: "600",
    fontFamily: '"Open Sans", "OpenSans"'
  },
  player: {
    opacity: ".7",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "300",
    fontFamily: '"Open Sans", "OpenSans"'
  }
});

const formatNumber = value => {
  if (typeof value === "string") return value;
  if (!value) return "0.00";

  const decimals = 2;

  let res = Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(decimals);
  res = res.replace(/\./g, ",");
  res = res.replace(/\d(?=(\d{3})+,)/g, "$&.");
  return res.replace(/,00$/, "");
};

const TopWithdrawals = props => {
  const classes = useStyles();
  const { className, latestWinners, t } = props;

  if (!latestWinners || !latestWinners.length) {
    return null;
  }

  latestWinners.sort((a, b) => b.spin_timestamp - a.spin_timestamp);

  const slick_settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    easing: 'easeOutQuart',
    draggable: false,
    autoplaySpeed: props.delay,
    infinite: true,
    speed: props.speed,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const winners = latestWinners.map(
    (element, idx) =>
      element !== null && (
        <div className="element" key={"virtuals_top_winner_ " + idx}>
          <div>
            <div className={classes.amount}>
              <strong>{formatNumber(Number(element.amount))}</strong> Lei&nbsp;
            </div>
            <div className={classes.player}>
              {t("withdrawn by")} {element.first_name}
            </div>
          </div>
        </div>
      )
  );

  return (
    <div className={`${classes.root} ${className ? className : ""}`}>
      <div className={classes.image} style={{ width: "50%" }}>
        <img src={LatestWinnersImage} alt="" />
      </div>
      <div style={{ width: "50%" }}>
        <div className={classes.textHolder}>
          <div className={classes.header}>{t("top withdrawals")}</div>
        </div>
        <div style={{ overflow: "hidden" }}>
          <div>
            <Slider {...slick_settings}>
              {winners}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

TopWithdrawals.propTypes = {
  speed: PropTypes.number,
  delay: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object
};

TopWithdrawals.defaultProps = {
  speed: 1000,
  delay: 5000,
  width: window.screen.width,
  height: window.screen.height
};

const mapStateToProps = state => {
  const latestWinners = [ // eslint-disable-line
    {
      "spin_timestamp": 1597327094,
      "amount": "4267",
      "currency": "RON",
      "game": "Shining Crown",
      "first_name": "Constantin cosmin",
      "last_name": "M.",
      "jackpot": "EGT"
    },
    {
      "spin_timestamp": 1597324256,
      "amount": "5970",
      "currency": "RON",
      "game": "40 Super Hot",
      "first_name": "Ecaterina Camelia",
      "last_name": "C.",
      "jackpot": "EGT"
    },
    {
      "spin_timestamp": 1597322350,
      "amount": "8141",
      "currency": "RON",
      "game": "Shining Crown",
      "first_name": "Sorin",
      "last_name": "N.",
      "jackpot": "EGT"
    },
    {
      "spin_timestamp": 1597316843,
      "amount": "4798",
      "currency": "RON",
      "game": "5 Dazzling Hot",
      "first_name": "Raul Mariis",
      "last_name": "S.",
      "jackpot": "EGT"
    },
    {
      "spin_timestamp": 1597313327,
      "amount": "5942",
      "currency": "RON",
      "game": "40 Super Hot",
      "first_name": "Dragos Nicolae",
      "last_name": "U.",
      "jackpot": "EGT"
    },
    {
      "spin_timestamp": 1597306755,
      "amount": "2682",
      "currency": "RON",
      "game": "40 Super Hot",
      "first_name": "Ionel",
      "last_name": "C.",
      "jackpot": "EGT"
    }
  ];


  return {
    //latestWinners,
    latestWinners: state.jackpot.latestWinners,
  };
};

export default withTranslation()(connect(mapStateToProps)(TopWithdrawals));
