import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Countdown, { TYPE_COMPACT_LAST_CHANCE } from "../../UI/Countdown/Countdown";
import '../Promotions.scss';
import './Promotion.scss';
import { connect } from "react-redux";
import DinamicImage from "../../UI/DinamicImage/DinamicImage";
import { withRouter } from "react-router";
import TitleBar from "../../UI/TitleBar/TitleBar";
import * as storeActions from "../../../store/actions";
import { doLogin } from "../../../store/actions/authentication";
import DOMPurify from 'dompurify';

class Promotion extends Component {

	state = {
		promotion: false
	};

	constructor(props) {
		super(props);
		const { id } = props.match.params;
		this.id = id;
	}

	decodeHTML(encodedString) {
		let textArea = document.createElement('textarea');
		textArea.innerHTML = encodedString;
		return DOMPurify.sanitize(textArea.value);
	}

	setPromotion(promo) {
		if (typeof promo !== "undefined") {
			this.setState({
				...this.state,
				promotion: promo
			});
		}
	}

	componentDidMount() {
		if (typeof this.props.dummy === "undefined") {
			if (this.props.promotion) {
				this.setPromotion(this.props.promotion);
			} else if (!this.props.promotions.length && this.id) {
				this.props.getPromotions();
			} else {
				let promotionFound = this.props.promotions.find(item => item.id == this.id);
				this.setPromotion(promotionFound);
			}
		} else {
			if (!this.props.promotions.length) {
				this.props.getPromotions();
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.promotions !== this.props.promotions && this.id) {
			let promotionFound = this.props.promotions.find(item => item.id == this.id);
			this.setPromotion(promotionFound);
		}
	}

	promoUrl() {
		let permalink = this.state.promotion.permalink ? this.state.promotion.permalink : encodeURI(this.state.promotion.title.replace(/ /g, '-'));

		let url = `/cazino/promotii/${this.state.promotion.id}/${permalink}`;
		if (window.config.lobbyEnabled === "1") {
			if (window.config.rootPaths && window.config.rootPaths.casino) {
				url = `/${window.config.rootPaths.casino}/promotii/${this.state.promotion.id}/${permalink}`;
			}
		}
		return url;
	}

	handleGetBonus() {
		if (["user", "token"].indexOf(this.props.authentication.auth_type) === -1 || this.props.authentication.access_token === null) {
			if (window.config.platformType !== 1) {
				if (this.state.promotion.call_to_action_url) {
					if (this.state.promotion.call_to_action_url[0] === '/') {
						localStorage.setItem("redirect_after_login", this.state.promotion.call_to_action_url);
					}
				}
				this.props.history.push("/connect");
			} else {
				// desktop - show modal
				this.props.doLogin();
			}
			return;
		}

		if (this.state.promotion.call_to_action_url) {
			if (this.state.promotion.call_to_action_url[0] === '/') {
				this.props.history.push(this.state.promotion.call_to_action_url);
			} else {
				window.open(this.state.promotion.call_to_action_url, "_blank");
			}
		} else {

			this.props.history.push("/deposit");
		}
	}

	render() {

		let content = <div className={`wrapper ${this.props.featured ? "featured" : ""}`}
			key={'dummy' + Math.random()}>
			<div className="wrapper-cell">
				<div className="image"> </div>
				<div className={"text-line"}> </div>
			</div>
		</div>;

		if (this.state.promotion) {
			let timestamp = new Date().getTime() / 1000;
			content = <article className={`${!this.id ? 'promotion-card' : "promotion-detail"} ${this.props.featured ? "featured" : ""}`}
				key={"promotion" + this.state.promotion.id}>
				<div className="content">
					<div className="top" onClick={() => this.props.history.push(this.promoUrl())}>
						{this.state.promotion.title_category && !this.id && <div className="label">{this.decodeHTML(this.state.promotion.title_category)}</div>}
						<DinamicImage imgLink={this.id ? this.state.promotion.path_to_banner : (this.props.featured ? this.state.promotion.path_to_thumbnail_mobile : this.state.promotion.path_to_thumbnail)} title={this.decodeHTML(this.state.promotion.title)} placeholder />
					</div>
					{this.state.promotion.availability && <Countdown date={this.state.promotion.availability} type={TYPE_COMPACT_LAST_CHANCE} expiredMessage={this.props.t("Unavailable")} />}

					<div className="bottom">
						<h2 onClick={() => this.props.history.push(this.promoUrl())}>
							{this.decodeHTML(this.state.promotion.title)}
						</h2>
						{this.id && <div className={"description"} dangerouslySetInnerHTML={{ __html: this.decodeHTML(this.state.promotion.long_description) }}></div>}
						<div className={"get-bonus"} onClick={this.handleGetBonus.bind(this)}>{this.state.promotion.call_to_action_text ? this.state.promotion.call_to_action_text : this.props.t('GET BONUS')}</div>
					</div>
				</div>
			</article>;
		}
		if (!this.id) {
			return content;
		}

		return (
			<div className={'PromotionsScreenContent'}>
				<TitleBar pageTitle={this.props.t('Promotions')} />
				{content}
			</div>
		);
	}
};
const mapStateToProps = state => {
	return {
		language: state.application.language,
		promotions: state.application.promotions,
		player: state.profile.client_player_id,
		authentication: state.authentication,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		getPromotions: () => dispatch(storeActions.getPromotions()),
		doLogin: () => dispatch(doLogin()),
	};
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Promotion)));
