import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TitleBar from '../../../UI/TitleBar/TitleBar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import SmsVerification from './SmsVerification/SmsVerification';
import NewPhoneNumber from './NewPhoneNumber/NewPhoneNumber';
import './ChangePhoneNumber.scss';

class ChangePhoneNumber extends Component {

    state = {
        currentStep: 1,
        newPhoneNumber: '',
    }

    stepHandler = (value, newPhoneNumber = null) => {
        if(value === true){
            this.setState({
                ...this.state,
                currentStep: 2,
                newPhoneNumber: newPhoneNumber,
            });
        }else{
            this.setState({
                ...this.state,
                currentStep: 1
            });
        }
    }

    render() {

        let pageContent = <NewPhoneNumber next={(value, newPhoneNumber) => this.stepHandler(value, newPhoneNumber)} />;

        if(this.state.currentStep === 2){
            pageContent = <SmsVerification newPhoneNumber={this.state.newPhoneNumber} next={(value) => this.stepHandler(value)} />
        }

        return (
            <div className={"ChangePhoneNumberScreen"}>
                <TitleBar pageTitle={this.props.t('Change Phone Number')} />
                <AppBar position="static" className="SubtitleBar">
                    <Typography variant="subtitle1">{this.props.t('STEP ') + this.state.currentStep + this.props.t(' OF 2')}</Typography>
                </AppBar>
                <div className="ChangePhoneNumberContent">
                    {pageContent}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChangePhoneNumber));