import React from 'react';
import { Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";


import "../style/nsoft-theme.scss";

import { getBaseRouterPath } from "../utils/config";
import PrematchLayoutMobile from "./prematch-layout-mobile";
import LiveLayout from "./live-layout";
import Error404 from "./error404";

import { prematchInitialize } from "../../bets/store/actions/prematch";
import { liveInitialize } from "../../bets/store/actions/live";

const EntryPoint = (props) => {
  const basePath = getBaseRouterPath();
  const { prematchInitialize, liveInitialize } = props;

  React.useEffect(() => {
    document.body.classList.add("nsoft-bets");
    if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme) {
      document.body.classList.add(`nsoft-${window.config.nsoftTheme.toString().toLowerCase()}`);
    }
    prematchInitialize();
    liveInitialize();

    const classNames = [];
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
    if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

    var html = document.getElementsByTagName('html')[0];

    if (classNames.length) classNames.push('on-device');
    html.classList.add(...classNames);

    return () => {
      document.body.classList.remove("nsoft-bets");
      html.classList.remove(...classNames);
      if (typeof window.config.nsoftTheme !== "undefined" && window.config.nsoftTheme) {
        document.body.classList.remove(`nsoft-${window.config.nsoftTheme.toString().toLowerCase()}`);
      }
    };
  }, []); // eslint-disable-line

  return (
    <Route>
      <Switch>
        <Route path={`${basePath}/prematch`} component={PrematchLayoutMobile} />
        <Route path={`${basePath}/live`} component={LiveLayout} />
        <Route component={Error404} />
      </Switch>
    </Route>
  );
}

const mapStateToProps = state => ({
  auth: state.auth
});

const actionCreators = {
  prematchInitialize,
  liveInitialize
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(EntryPoint));