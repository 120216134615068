import React from 'react';
import { withTranslation } from "react-i18next";
import moment from "moment";

const SimpleTimer = (props) => {
    const { timestamp, className, t } = props;

    const [counter, setCounter] = React.useState(false);
    const [state, setState] = React.useState({
        days: "",
        hours: "--",
        mins: "--",
        secs: "--"
    });

    React.useEffect(() => {
        let mounted = true;
        let intervalID = 0;

        if (mounted) {
            if (timestamp) {
                const calcTime = () => {
                    const dt = moment(new Date(timestamp * 1000));
                    const dtNow = moment();
                    let delta = dt.diff(dtNow, 'seconds');;

                    // calculate (and subtract) whole days
                    let days = Math.floor(delta / 86400);
                    delta -= days * 86400;

                    // calculate (and subtract) whole hours
                    let hours = Math.floor(delta / 3600) % 24;
                    delta -= hours * 3600;

                    // calculate (and subtract) whole minutes
                    let mins = Math.floor(delta / 60) % 60;
                    delta -= mins * 60;

                    // what's left is seconds
                    let secs = delta % 60;

                    if (hours < 0 || mins < 0 || secs < 0) {
                        setCounter(false);
                        clearInterval(intervalID);
                        return;
                    }

                    if (hours > 24) {
                        setCounter(false);
                        return;
                    }

                    setState({
                        days: days,
                        hours: ("0" + hours).slice(-2),
                        mins: ("0" + mins).slice(-2),
                        secs: ("0" + secs).slice(-2),
                    });
                    setCounter(true);
                };

                clearInterval(intervalID);
                intervalID = setInterval(() => {
                    calcTime();
                }, 1000);
                calcTime();
            }
        }

        return () => {
            mounted = false;
            clearInterval(intervalID);
        }
    }, [timestamp]); // eslint-disable-line

    if (!timestamp) return null;

    return (
        <div className={`${className ? className : ""}`}>
            {counter && <React.Fragment>
                {state.days !== 0 && <span className="days">
                    {state.days} {state.days === 1 ? t("day") : t("days")}
                </span>}
                <span className={`time ${!state.days ? 'no-days' : ''}`}>
                    <span className={state.hours === "00" ? "muted" : ''}>{state.hours}&nbsp;:&nbsp;</span><span className={state.hours === "00" && state.mins === "00" ? "muted" : ''}>{state.mins}&nbsp;:&nbsp;</span>{state.secs}
                </span>
            </React.Fragment>}
        </div>
    );
}

export default withTranslation()(SimpleTimer);