import React from 'react';
import { withTranslation } from "react-i18next";
import TitleBar from "../../UI/TitleBar/TitleBar";
import DinamicImage from "../../UI/DinamicImage/DinamicImage";
import './PiratesTreasure.scss';

const PiratesTreasure = (props) => {

    return (
        <div className={"PiratesTreasureScreen"}>
            <TitleBar pageTitle={props.t("Pirates Treasure")} pageSubTitle={props.t("Coming Soon")}/>
            <div className={"PiratesTreasureScreenContent"}>
                <div className={'MainContent'}>
                    <h1 className={'PageText'}>{props.t('Here you will embark on an adventure')}</h1>
                    <span className={'MedalButton'}>{props.t('coming soon')}</span>
                </div>
                <div className={'MainImage'}>
                    <DinamicImage 
                        folder={"coming-soon"}
                        size={"large"}
                        placeholder
                        imgName={"pirates-treasure.jpg"}
                        title={"Pirates Treasure"}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(PiratesTreasure);