import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';

export function* requestWalletSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.wallet.GET,
            method: 'post',
            data: {
                responseType: 'wallet-preview',
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Wallet response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Wallet response has no 'result' property`);
        }

        yield put(actions.walletReceived(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* authRequestWalletSaga(action) {
    if (!action.hasOwnProperty('authType')) {
        return;
    }

    if (!['user', 'token'].includes(action.authType)) {
        return;
    }

    yield put(actions.requestWallet());
    yield put(actions.requestPendingWithdrawals());
}

export function* requestRemoveBonusSaga(action) {
    const axios = ClientAPI.getInstance();
    const bonusID = action.bonusID;

    try {
        const response = yield axios({
            url: paths.wallet.REQUEST_REMOVE_BONUS,
            method: 'post',
            data: {
                bonusId: [bonusID],
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Request Remove Bonus response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Remove Bonus response has no 'result' property`);
        }

        yield put(actions.receivedRemoveBonus(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}