import React, { useEffect, useState, Fragment } from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../store/actions";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../UI/Button/Button';
import CardList from './CardList/CardList';
import './Viva.scss';
import Utils from "../../../utils/Utils";
import {paymentCheckout} from "../../../ClientAPI/paths"
import VivaAPI from "../../../ClientAPI/VivaAPI";
import UseNewPaymentMethod from "../NewPaymentMethod/UseNewPaymentMethod";
import Icons from "../../../assets/Icons/Icons";
import ButtonBase from "@material-ui/core/ButtonBase";
import ExpiringLocalStorage from "../../../utils/ExpiringLocalStorage";
import MarketingEvents from "../../../utils/MarketingEvents";
import ApplePay from "./ApplePay/ApplePay";

const Viva = (props) => {
    const { t } = useTranslation();

    const [selectedCard, setSelectedCard] = useState(null);
    // const [showCVV, setShowCVV] = useState(false);
    const [checked, setChecked] = useState(true);
    const [active3D, setActive3D] = useState(false);
    const [serverResponse,setServerResponse] = useState(false);
    const [showPaymentComparison,setShowPaymentComparison] = useState(false);
    const [playerData, setPlayerData] = useState( {
        amount: props.amount,
        cvc: "",
        number: '',
        holderName: "",
        monthYear: "",
        month: "",
        year: "",
        bonusId: props.bonusId
        // cvc: 123,
        // number: '5188340000000060',
        // holderName: "Jhon Doe",
        // monthYear: "12/25",
        // month: "12",
        // year: "25",
        // bonusGroupId: props.bonusGroupId
    });

    useEffect(() => {
        props.onRequestVivaToken();
        props.logEvent('Request VIVA token');
        if(props.tempPlayerData){
            setPlayerData(props.tempPlayerData);
        }
        Utils.injectScript('https://code.jquery.com/jquery-1.12.4.min.js','viva-jquery',jqueryInserted,{
            integrity: "sha256-ZosEbRLbNQzLpnKIkEdrPv7lOy9C27hHQ+Xp8a4MxAQ=",
            crossorigin: 'anonymous'
        });
    }, []);

    useEffect(() => {
        props.tempPlayerDataHandler(playerData);

    }, [playerData]);

    useEffect(() => {
        const loading = props.paymentCheckout.loading || props.paymentCheckout.loadingAccessToken || active3D;
        if (loading) {
            props.logEvent('Add VIVA semaphor');
            ExpiringLocalStorage.set('loading_vctok','1',30)
        } else {
            window.localStorage.removeItem('loading_vctok')
        }
    },[props.paymentCheckout.loading, props.paymentCheckout.loadingAccessToken, active3D])

    useEffect(() => {
        if(!props.paymentCheckout.loadingAccessToken && props.paymentCheckout.access_token){
            props.onRequestVivaCardToken();
            props.logEvent('Request VIVA card token');
        }
    }, [props.paymentCheckout.access_token]);
    useEffect( () => {
        if(props.paymentCheckout.loadingCardToken || props.paymentCheckout.loadingAccessToken) {
            return;
        }
        let cardFound = false;
        if (typeof props.paymentCheckout.cardData !== 'undefined') {
            if (typeof props.paymentCheckout.cardData[0] !== 'undefined') {
                cardFound = true;
            }
        }
        if (cardFound) {
            Utils.delayedFunction('selectCard',delayedSelectCard,0);
        } else {
            props.logEvent('No card selected');
            Utils.delayedFunction('selectCard',noCard);
        }
    },[props.paymentCheckout.cardData])

    const delayedSelectCard = () => {

        selectCard(props.paymentCheckout.cardData[0].card_token)
    }
    const noCard = () => {
        selectCard(null)
    }

    useEffect(() => {
        if (props.paymentCheckout.serverResponse === 'success') {
            props.getWallet();
            props.history.push('/payment/success?currency=RON&totalAmount=' + (playerData.amount))
        }
    }, [props.paymentCheckout.serverResponse]);

    const jqueryInserted = () => {
        //Utils.injectScript(process.env.CFW.vivaUrl + paymentCheckout.JS_VIVA_PAYMENT,'viva-script',vivaScriptInserted,{async: true});
    }

    const vivaScriptInserted = () => {

        setTimeout(function () {
            let setupParams = {
                authToken: VivaAPI.getToken(),
                baseUrl: process.env.CFW.vivaApiUrl,
                cardHolderAuthOptions: {
                    cardHolderAuthPlaceholderId: 'validation3ds',
                    cardHolderAuthInitiated: function() {
                        document.getElementById('validation3ds').setAttribute('class','show');
                    },
                    cardHolderAuthFinished: function() {
                        document.getElementById('validation3ds').setAttribute('class','');
                    }
                }
            };
            window.VivaPayments.cards.setup(setupParams)
            window.VivaPayments.setBaseURL(process.env.CFW.vivaApiUrl);
        },3500)
    }

    const handleChange = prop => event => {
        let value = event.target.value;
        setServerResponse(false);
        switch (prop) {
            case 'holderName':
                if (value.length <= 50) {
                    if(value.match(/^[a-zA-Z-\s]*$/)){
                        setPlayerData({
                            ...playerData,
                            [prop]: value,
                        });
                    } else {
                        if (value.length === 1) {
                            setPlayerData({
                                ...playerData,
                                [prop]: '',
                            });
                        }
                    }
                }
                break;
            case 'number':
                if(value.match(/^[0-9\s]*$/) && value.length <= 19){
                    setPlayerData({
                        ...playerData,
                        [prop]: value.replace(/\D/g, "")
                            .replace(/(\d{4})(\d)/, "$1 $2")
                            .replace(/(\d{4})(\d)/, "$1 $2")
                            .replace(/(\d{4})(\d)/, "$1 $2"),
                    });
                }
                break;
            case 'monthYear':
                value = value.replace(/\D/g,'');
                let month = '';
                let year = '';
                switch (value.length) {
                    case 1:
                        if (value.length === 1 && parseInt(value) > 1) {
                            value = '0' + value;
                        }
                        month = value;
                        break;
                    case 2:
                        if (parseInt(value[0]) === 1 && parseInt(value[1]) > 2) {
                            month = '0' + value[0];
                            year = value[1];
                            value = '0'+ value[0] + '/' + value[1];
                        } else {
                            month = value;
                        }
                        break;
                    case 3:
                        month = value[0] + value[1];
                        year = value[2];
                        value = value[0] + value[1] + '/' + value[2] ;
                        break;
                    case 4:
                        month = value[0] + value[1];
                        year = value[2] + value[3];
                        value = value[0] + value[1] + '/' + value[2] + value[3];
                        break;
                    default:
                        break;
                }
                setPlayerData({
                    ...playerData,
                    [prop]: value,
                    month: month,
                    year: year
                });
                break;
            case 'cvc':
                if(value.match(/^[0-9]*$/) && value.length <= 4){
                    setPlayerData({
                        ...playerData,
                        [prop]: value,
                    });
                }
                break;
            default:
                return;
        }
        return;
    };

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    // const handleClickShowCVV = () => {
    //     setShowCVV(!showCVV);
    // };

    // const handleMouseDownCVV = (event) => {
    //     event.preventDefault();
    // };

    const onRequestVivaChargeToken = (playerData) => {
        if (reqInProgress()) {
            return;
        }
        setActive3D(true);
        setServerResponse(false);
        try {
            window.VivaPayments.cards.requestToken({
                amount: playerData.amount*100,

            }).done(function (data) {
                setActive3D(false);

                async function digestMessage(message) {
                    // encode as UTF-8
                    const msgBuffer = new TextEncoder().encode(message);
                    // hash the message
                    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
                    // convert ArrayBuffer to Array
                    const hashArray = Array.from(new Uint8Array(hashBuffer));
                    // convert bytes to hex string
                    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                    return hashHex;
                }

                digestMessage(props.email)
                    .then(encodedEmail => MarketingEvents.addPaymentMethod({
                        metaPixel: {
                                user_data: {
                                    email: encodedEmail
                                }
                        }
                    }))

                props.sendDeposit(playerData.amount*100,data,playerData.bonusId ? playerData.bonusId : -1,playerData.holderName);
            }).fail( function () {
                let message = props.t('Something went wrong. Please verify the card data.');
                setServerResponse(message)
                setActive3D(false);
            })
        } catch (e) {
        }
    }

    const selectCard = (card) => {
        setSelectedCard(card);
        setServerResponse(false);
        props.logEvent('Card '+ card);

        if (!card) {

            Utils.injectScript(process.env.CFW.vivaUrl + paymentCheckout.JS_VIVA_PAYMENT,'viva-script',vivaScriptInserted,{async: true});
        }
    }
    const reqInProgress = () => {
        return ExpiringLocalStorage.get('loading_vctok') === '1';
    }
    const onRequestVivaChargeTokenWithCard = (selectedCard, playerData) => {
        if (reqInProgress()) {
            return;
        }
        props.logEvent('Request VIVA charge token with card');

        setServerResponse(false);
        props.onRequestVivaChargeTokenWithCard(selectedCard, playerData)

    }

    let mainContent = <CircularProgress className={'Loading'} />;
    if (showPaymentComparison) {
        mainContent = (<UseNewPaymentMethod closeHandler={() => setShowPaymentComparison(false)}/>);
    } else if(!props.paymentCheckout.loadingCardToken && !props.paymentCheckout.loadingAccessToken){
        let mainForm = null;
        if(selectedCard === null){
            mainForm = (
                <Fragment>
                    <p className="PageDescription">{t('Proceeding will imply the acceptance of secure storing of your card data.')}</p>
                    <div className={'PageForm'}>
                        <h2 className="FormTitle">
                            {t('CARD DETAILS')}
                        </h2>
                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="holderName">{t('Name')}</InputLabel>
                            <Input
                                id="holderName"
                                type='text'
                                autoComplete='off'
                                value={playerData.holderName}
                                onChange={handleChange('holderName')}
                            />
                            <input
                                id="holderNameHidden"
                                type='hidden'
                                value={playerData.holderName}
                                data-vp={'cardholder'}
                            />
                        </FormControl>
                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="number">{t('Card number')}</InputLabel>
                            <Input
                                id="number"
                                type={"tel"} pattern={"\d*"}
                                autoComplete='off'
                                inputProps={{ maxLength: 19 }}
                                value={playerData.number}
                                onChange={handleChange('number')}
                            />
                            <input
                                id="numberhidden"
                                type='hidden'
                                value={playerData.number}
                                data-vp={'cardnumber'}
                            />
                        </FormControl>
                        <div className="SingleRow">
                            <FormControl className="PlayerDataInput">
                                <InputLabel htmlFor="monthYear">{t('MM/YY')}</InputLabel>
                                <Input
                                    id="monthYear"
                                    type={"tel"} pattern={"\d*"}
                                    autoComplete='off'
                                    inputProps={{ maxLength: 5 }}
                                    value={playerData.monthYear}
                                    onChange={handleChange('monthYear')}
                                />
                                <input
                                    id="year"
                                    type='hidden'
                                    value={playerData.year}
                                    data-vp={'year'}
                                />
                                <input
                                    id="month"
                                    type='hidden'
                                    value={playerData.month}
                                    data-vp={'month'}
                                />
                            </FormControl>
                            <FormControl className="PlayerDataInput">
                                <InputLabel htmlFor="cvc">{t('CVV')}</InputLabel>
                                <Input
                                    id="cvc"
                                    type={"tel"} pattern={"\d*"}
                                    autoComplete='off'
                                    inputProps={{ maxLength: 4 }}
                                    value={playerData.cvc}
                                    onChange={handleChange('cvc')}
                                    // endAdornment={
                                    //     <InputAdornment position="end">
                                    //         <IconButton onClick={handleClickShowCVV} onMouseDown={handleMouseDownCVV} >
                                    //             {showCVV ? <Visibility /> : <VisibilityOff />}
                                    //         </IconButton>
                                    //     </InputAdornment>
                                    // }
                                />
                                <input
                                    id="cvchidden"
                                    type={'hidden'}
                                    value={playerData.cvc}
                                    data-vp={'cvv'}
                                />
                            </FormControl>
                        </div>
                        
                    </div>
                </Fragment>
            );
        }else{
            mainForm = (
                <Fragment>
                    <p className="PageDescription">{t('Simple and convenient. Complete your transaction just with the amount.')}</p>
                    <div className={'PageForm'}>
                        <h2 className="FormTitle">{t('AMOUNT CONFIRM')}</h2>
                    </div>
                    <input
                        id="tokenHidden"
                        type='hidden'
                        value={selectedCard}
                        data-vp={'token'}
                    />
                </Fragment>
            );
        }

        let loadingButton = props.paymentCheckout.loading || props.paymentCheckout.loadingAccessToken || active3D;
        mainContent = (
            <div>
                <div className="VivaScreenContainer">
                    {!(typeof props.paymentCheckout.cardData !== "undefined" && props.paymentCheckout.cardData.length) && props.forceSafecharge &&
                        <div className={'cards-not-found'}>
                            <ButtonBase className={'safecharge'} onClick={() => props.forceSafecharge()}>
                                {Icons.get('exWarning')}{props.t("Nu-ti gasesti card-ul? Apasa aici pentru metoda veche.")}
                            </ButtonBase>
                        </div>
                    }
                    <CardList
                        cardData={props.paymentCheckout.cardData}
                        setCard={(card) => selectCard(card)}
                        selectedCard={selectedCard}
                    />
                    {mainForm}
                    <div className="DepositAmount">
                        <FormControl className="PlayerDataInput">
                            <InputLabel htmlFor="amount">{t('Deposited amount')}</InputLabel>
                            <Input
                                id="amount"
                                type='text'
                                disabled={true}
                                value={props.amount + ' Lei'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => props.backHandler()}>
                                            <EditIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <div className="BonusAmount">
                            <span className="BonusTitle">{t('Deposit Bonus')}</span>
                            <p className="BonusValue">{props.bonusAmount}</p>
                        </div>
                    </div>
                    {loadingButton && <div className={'loading-screen'}><CircularProgress/></div>}
                    <div className="DepositButton">
                        <Button
                            className={'active gradient'}
                            full={true}
                            disabled={loadingButton}
                            value={loadingButton ? <CircularProgress className={'Loading'}/> : t("DEPOSIT WITH CARD")}
                            click={selectedCard === null
                                ? () => onRequestVivaChargeToken(playerData)
                                : () => onRequestVivaChargeTokenWithCard(selectedCard, playerData)
                            }
                        />
                        <ApplePay amount={props.amount}
                                  bonusId={props.bonusId}
                        />
                    </div>

                    {props.paymentCheckout.serverResponse || serverResponse
                        ? (<div className="ReturnMessage">
                            <p>{props.paymentCheckout.serverResponse ? props.paymentCheckout.serverResponse : serverResponse}</p>
                        </div>)
                        : null
                    }

                    <div id={'validation3ds'}></div>

                </div>
            </div>
        );
    }
    return (
        <div className={"VivaScreen"}>
            {mainContent}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        paymentCheckout: state.paymentCheckout,
        email: state.profile.email,
        transactions: state.transactions.depositsWithdrawals,
        accountId: state.profile.client_player_id,
        isFTD: state.profile?.ftd === null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestVivaToken: () => dispatch(storeActions.requestVivaToken()),
        onRequestVivaCardToken: () => dispatch(storeActions.requestVivaCardToken()),
        onRequestVivaChargeTokenWithCard: (cardToken, playerData) => dispatch(storeActions.requestVivaChargeTokenWithCard(cardToken, playerData)),
        sendDeposit: (amount,data,bonusId,holderName) => dispatch(storeActions.sendVivaChargeToken(data,amount,bonusId,holderName)),
        getWallet: () => dispatch(storeActions.requestWallet()),
        logEvent: (event) => dispatch(storeActions.logEvent(event))
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Viva)));