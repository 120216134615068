import "typeface-open-sans";
import "../index.scss";
import "../styles/animate.min.css";
//import "../styles/flexUtils.css";

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ScrollUpButton from "react-scroll-up-button";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import LottoLayout from "../layout/LottoLayout";
import Error404 from "../views/Error404";

const rank = {
  "/lotto/home": 1,
  "/lotto/details": 2,
};

const toggledStyle = {
  right: 10
};

const handleEnterExitOrder = nodeEnter => {
  if (nodeEnter && (nodeEnter.parentNode.children.length === 2 || nodeEnter.parentNode.children.length === 3)) {
    let idEnter = nodeEnter.dataset.id;
    let idExit = "";
    let nodeExit = null;

    for (let i = 0; i < nodeEnter.parentNode.children.length; i++) {
      const child = nodeEnter.parentNode.children[i];
      if (child.dataset.id !== idEnter) {
        idExit = child.dataset.id;
        nodeExit = child;
      }
    }

    nodeEnter.classList.remove("page--fixed", "page--prev", "page-exit", "page-enter-done", "page-enter-active");
    nodeExit.classList.remove("page--fixed", "page--prev", "page-exit", "page-enter-done", "page-exit-active");
    nodeEnter.classList.add("page--fixed");
    nodeExit.classList.add("page--fixed");

    if (idEnter.indexOf("/lotto/details/") > -1) {
      idEnter = "/lotto/details";
    }
    if (idExit.indexOf("/lotto/details/") > -1) {
      idExit = "/lotto/details";
    }

    if (rank[idEnter] > rank[idExit]) {
      nodeEnter.classList.add("page--prev");
    } else if (rank[idEnter] < rank[idExit]) {
      nodeExit.classList.add("page--prev");
    }
  }

  if (nodeEnter && nodeEnter.parentNode) {
    document.body.classList.add("hide-footer-language");
  }
};

const handleExit = nodeEnter => {
  if (nodeEnter && nodeEnter.parentNode) {
    document.body.classList.remove("hide-footer-language");
  }

  if (nodeEnter.parentNode.children.length === 2 || nodeEnter.parentNode.children.length === 3) {
    for (let i = 0; i < nodeEnter.parentNode.children.length; i++) {
      const child = nodeEnter.parentNode.children[i];
      child.classList.remove("page--prev", "page--fixed");
    }
  }
};

const useStyles = makeStyles({
  borderTop: {
    paddingTop: "56px"
  }
});

function DefaultLayout(props) {
  const { web, noNavBar, topMenuHeight } = props;

  const classes = useStyles();

  let isDesktop = useMediaQuery('(min-width:1024px');

  useEffect(() => {
    document.body.classList.add("bets", "lotto-module");

    return () => {
      document.body.classList.remove("bets", "lotto-module");
    };
  }, []);

  const tt = {
    enter: 1200,
    exit: 500
  };

  let contentLayout = (
    <React.Fragment>
      <div className={`page-wrapper lotto-entry`}>
        <div className={`${(topMenuHeight ? topMenuHeight : classes.borderTop)}`}></div>
        <Route
          render={({ location }) => {
            const { pathname } = location;
            let key = pathname.split("/");
            if (key.length > 3) {
              key.pop();
            }
            key = key.join("/");
            return (
              <TransitionGroup component={null}>
                <CSSTransition
                  key={key}
                  classNames="page"
                  timeout={tt}
                  onEnter={handleEnterExitOrder}
                  onExited={handleExit}
                >
                  <Route
                    location={location}
                    render={() => (
                      <Switch location={location}>
                        <Route path="/lotto" component={LottoLayout} />
                        <Route component={Error404} />
                      </Switch>
                    )}
                  />
                </CSSTransition>
              </TransitionGroup>
            );
          }}
        />
      </div>
    </React.Fragment>
  );
  if (isDesktop) {
    contentLayout = (
      <React.Fragment>
        <div className={`page-wrapper lotto-entry`}>
          <div className={`${noNavBar ? (topMenuHeight ? topMenuHeight : classes.borderTop) : ""}`}></div>
          <Route
            render={({ location }) => (<Route
              location={location}
              render={() => (
                <Switch location={location}>
                  <Route path="/lotto" component={LottoLayout} />
                  <Route component={Error404} />
                </Switch>
              )}
            />
            )}
          />
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {contentLayout}
      <ScrollUpButton ToggledStyle={toggledStyle} />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  topMenuHeight: state.application.topMenuHeight,
});

const actionCreators = {
};

export default connect(mapStateToProps, actionCreators)(DefaultLayout);
