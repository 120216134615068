import React from "react";
import { connect } from "react-redux";

import GameComponent from "../components/game-component";
import { loadTournamentsGroups } from "../store/actions/tournaments_groups";

const GamePage = (props) => {
  const { groupsLoaded, loadTournamentsGroups } = props;

  React.useEffect(() => {
    if (!groupsLoaded) {
      loadTournamentsGroups();
    }
  }, [groupsLoaded, loadTournamentsGroups]);

  return (
    <div className="game-page">
      <GameComponent />
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    groupsLoaded: state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
  }
};

const mapActions = {
  loadTournamentsGroups,
};

export default connect(mapStateToProps, mapActions)(GamePage);