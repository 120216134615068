import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../store/actions";
import './Jackpot.scss';
import { listTypes, listsMap } from './../../utils/GamesListTypes';
import Game from '../CasinoGames/sections/Game/Game';
import GameLauncher from '../CasinoGames/sections/GameLauncher/GameLauncher';

import TitleBar from "./../UI/TitleBar/TitleBar";
import JackpotHeader from './Header';
import JackpotHeaderV2 from './HeaderV2';
// import TickerWinners from './TickerWinners';
import WinHistory from './WinHistory';
import SubHeader from './SubHeader';

import EgtLogo from './../../assets/Images/Jackpot/img-jackpotlogo-egt@3x.png';
import WinHistoryEgtLogo from './../../assets/Images/Jackpot/img-jackpotlogo-winhistory-egt@3x.png';
import { useTranslation, withTranslation } from "react-i18next";

const styles = theme => ({
    paper: {
        padding: theme.spacing(4),
    },
});

const GAMES_PROMO_SIZE = 6;
const GAMES_BATCH_SIZE = 18;

let allGames = [];

const Jackpot = (props) => {
    //window.scrollTo(0, 0);
    //const classes = props.classes;
    const t = useTranslation;

    const [games, setGames] = useState([]);
    const [gamesPromo, setGamesPromo] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const loadMoreRef = useRef();

    let pType = 'cazino';
    let pCode = 'jackpot';

    useEffect(() => {
        props.pageType(pType);
        props.pageCode(pCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const unsubscribe = props.history.block(({ pathname }) => {
            // console.log(pathname);
            // console.log(props.games.gameLauncher);
            if (props.games.gameLauncher) {
                props.closeGameLauncher();
                return false;
            }

            return true;
        });

        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.games.gameLauncher]);

    const actualListType = listsMap[listTypes.JACKPOT].param;

    const backHandler = () => {
        props.history.push("/");
    };

    useEffect(() => {
        // make a copy
        allGames = [...props.games.items[actualListType]];

        let gp = [];

        // go through the store and get promoted 6 games
        for (let i = 0; i < allGames.length && gp.length < GAMES_PROMO_SIZE; i++) {
            if (allGames[i].promoted) {
                // store promo game
                gp.push(allGames[i]);

                // and extract it
                allGames.splice(i, 1);
                i--
            }
        }

        // if there are not enough promoted games, just add first ones
        for (let i = 0; i < allGames.length && gp.length < GAMES_PROMO_SIZE; i++) {
            gp.push(allGames.shift());
        }

        setGamesPromo(gp);
        setGames(allGames.splice(0, GAMES_BATCH_SIZE - GAMES_PROMO_SIZE))
    }, [props.games.items[actualListType]]);

    const loadMoreGames = () => {
        setGames(g => ([...g, ...allGames.splice(0, GAMES_BATCH_SIZE)]));
    }

    useEffect(() => {
        let moreDomRef = loadMoreRef.current;

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadMoreGames();
                    return;
                }
            })
        });
        observer.observe(moreDomRef);

        return () => {
            observer.unobserve(moreDomRef);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const launchHandler = (gameItem, cardRef) => {
        console.log(`[GAME] Launch game ${gameItem.name}`);
        console.log(cardRef);

        props.openGame(gameItem.id);
    };

    return (
        <div className="jackpotScreen">
            <div className={props.application.topMenuHeight}></div>
            <TitleBar pageTitle={props.t("All Jackpots")} backHandler={backHandler} />

            <JackpotHeaderV2
                integration={"mobile"}
                onClick={() => setShowModal(true)}
                name={"EGT"}
                value={props.jackpot.maxValue}
                jackpot={props.jackpot}
                currency={props.jackpot.currency} />

            <div className="games">
                {gamesPromo.map((game, index) => (
                    <div className="game animatedGame" key={index}>
                        <Game item={game} launchHandler={launchHandler} />
                    </div>
                ))}
            </div>

            {/*<TickerWinners title={t('Jackpots')} subtitle={t('Latest winners')} />*/}

            <div className="games">
                {games.map((game, index) => (
                    <div className="game animatedGame" key={index}>
                        <Game item={game} launchHandler={launchHandler} />
                    </div>
                ))}
            </div>

            <div className={"loadMore"} ref={loadMoreRef}>&nbsp;</div>

            <WinHistory
                show={showModal}
                closeModal={() => setShowModal(false)}
                logo={WinHistoryEgtLogo}
                name={"EGT Spades"}
            />

        </div>
    );
};

const mapStateToProps = state => {
    return {
        application: state.application,
        games: state.games,
        jackpot: state.jackpot,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        pageType: (pType) => dispatch(storeActions.setPageType(pType)),
        pageCode: (pCode) => dispatch(storeActions.setPageCode(pCode)),
        openGame: (gameId) => dispatch(storeActions.openGameLauncher(gameId)),
        closeGameLauncher: () => dispatch(storeActions.closeGameLauncher()),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Jackpot)));
