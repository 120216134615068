import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as storeActions from "../../../../../store/actions";
import Icons from '../../../../../assets/Icons/Icons';
import PageTitle from '../../../../UI/PageTitle/PageTitle';
import Button from '../../../../UI/Button/Button';
import ProgressBar from '../../../../UI/ProgressBar/ProgressBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Deposit.scss';
import Translate from '../../../../../utils/Translate';

const PERIOD_TYPE_DAILY = 1;
const PERIOD_TYPE_WEEKLY = 2;
const PERIOD_TYPE_MONTHLY = 3;
const TYPE_DEPOSIT = 1; // Deposit
const TYPE_WAGE = 2; // Wagered
const TYPE_LOST = 3; // Loss

class Deposit extends Component {

    state = {
        limitType: this.getType(TYPE_DEPOSIT),
        instantiated: false,
        limits: {},
        activeLimit: {
            limitAmount: 0,
            accumulatedAmount: 0,
            periodType: 0,
            limitEnd: 0,
            targetAmount: 50,
            newLimitAmount: 0,
            newLimitMessage: false,
        },
        limitSubType: this.getSubType(PERIOD_TYPE_DAILY),
    };

    componentDidMount() {
        this.setState({
            ...this.state,
            instantiated: true
        })

        if (this.props.auth.auth_type !== "user") {
            this.props.history.push("/");
        } else {
            this.props.onRequestLimits();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.limits !== prevProps.limits) {
            this.createLimitVariable();
        }
    }

    createLimitVariable() {
        let limits = {};
        let propLimits = this.props.limits;
        // for each type
        Object.keys(propLimits).forEach((key) => {
            let type = this.getType(propLimits[key].type);
            let subtype = this.getSubType(propLimits[key].period_type);
            if (typeof limits[type] === "undefined") {
                limits[type] = {};
            }

            let newLimitMessage = false;
            if (propLimits[key].new_limit_amount || propLimits[key].limit_end) {
                newLimitMessage = true;
            }

            limits[type][subtype] = {
                limitAmount: propLimits[key].limit_amount ? propLimits[key].limit_amount : 0,
                accumulatedAmount: propLimits[key].accumulated_amount ? propLimits[key].accumulated_amount : 0,
                periodType: propLimits[key].period_type,
                limitEnd: propLimits[key].limit_end ? propLimits[key].limit_end : 0,
                targetAmount: propLimits[key].limit_amount ? propLimits[key].limit_amount : 50,
                newLimitAmount: propLimits[key].new_limit_amount ? propLimits[key].new_limit_amount : 0,
                newLimitMessage: newLimitMessage,
            }
        });

        this.setState({
            ...this.state,
            limits: limits,
            activeLimit: limits[this.getType(TYPE_DEPOSIT)][this.getSubType(PERIOD_TYPE_DAILY)],
        })
    }

    getSubType(subtype) {
        switch (parseInt(subtype, 10)) {
            case PERIOD_TYPE_DAILY:
                return "daily";
            case PERIOD_TYPE_WEEKLY:
                return "weekly";
            case PERIOD_TYPE_MONTHLY:
                return "monthly";
            default:
                return "others";
        }
    }

    getType(type) {
        switch (parseInt(type, 10)) {
            case TYPE_DEPOSIT:
                return "deposit";
            case TYPE_WAGE:
                return "rolling";
            case TYPE_LOST:
                return "losses";
            default:
                return "others";
        }
    }

    limitChangedHandler = (action) => {

        switch (action) {
            case 'inc':
                this.setState((prevState) => {
                    if (prevState.activeLimit.targetAmount >= 0 && prevState.activeLimit.targetAmount < 50) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: prevState.activeLimit.targetAmount + 10,
                            },
                        }
                    } else if (prevState.activeLimit.targetAmount >= 50 && prevState.activeLimit.targetAmount < 100) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: prevState.activeLimit.targetAmount + 25,
                            },
                        }
                    } else if (prevState.activeLimit.targetAmount >= 100 && prevState.activeLimit.targetAmount < 300) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: prevState.activeLimit.targetAmount + 50,
                            },
                        }
                    } else if (prevState.activeLimit.targetAmount >= 300) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: prevState.activeLimit.targetAmount + 100,
                            },
                        }
                    }
                });
                break;
            case 'dec':
                this.setState((prevState) => {
                    if (prevState.activeLimit.targetAmount < 11) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: 1,
                            },
                        }
                    } else if (prevState.activeLimit.targetAmount <= 50) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: prevState.activeLimit.targetAmount - 10,
                            },
                        }
                    } else if (prevState.activeLimit.targetAmount > 50 && prevState.activeLimit.targetAmount <= 100) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: prevState.activeLimit.targetAmount - 25,
                            },
                        }
                    } else if (prevState.activeLimit.targetAmount >= 100 && prevState.activeLimit.targetAmount <= 300) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: prevState.activeLimit.targetAmount - 50,
                            },
                        }
                    } else if (prevState.activeLimit.targetAmount >= 300) {
                        return {
                            ...this.state,
                            activeLimit: {
                                ...this.state.activeLimit,
                                targetAmount: prevState.activeLimit.targetAmount - 100,
                            },
                        }
                    }
                });
                break;
            default: break;
        }
    }
    inputBlurHandler = () => {
        if(this.state.activeLimit.targetAmount < 1 ) {
            this.setState( {
                    ...this.state,
                    activeLimit: {
                        ...this.state.activeLimit,
                        targetAmount: 1,
                    }
                }
            )
        }
    }
    inputChangedHandler = (event) => {
        if (event.target.value === '') {
            this.setState({
                ...this.state,
                activeLimit: {
                    ...this.state.activeLimit,
                    targetAmount: 0,
                },
            });
        } else {
            this.setState({
                ...this.state,
                activeLimit: {
                    ...this.state.activeLimit,
                    targetAmount: parseInt(event.target.value, 10),
                },
            });
        }
    }

    calcPercentHandler = (valueA, valueB) => {
        let math = Math.round((valueA / valueB) * 100);
        if (!isNaN(math) && isFinite(math)) {
            return math;
        } else {
            return 0;
        }
    }

    changeLimitSubTypeHandler = (type) => {
        let limitSubType = this.getSubType(type);
        this.setState({
            ...this.state,
            limitSubType: limitSubType,
            activeLimit: this.state.limits[this.state.limitType][limitSubType],
        });
    }

    submitLimit() {
        this.applyLimit(TYPE_DEPOSIT, this.state.activeLimit.periodType, this.state.activeLimit.targetAmount);
    }

    removeLimit() {
        this.applyLimit(TYPE_DEPOSIT, this.state.activeLimit.periodType, -1);
    }

    applyLimit(limit_type, limit_type_period, limit_value) {
        if (!isNaN(limit_value) && limit_value !== this.state.activeLimit.limitAmount) {

            this.props.onSetNewLimit(limit_type, limit_type_period, limit_value);
            this.setState({
                ...this.state,
                activeLimit: {
                    ...this.state.activeLimit,
                    limitEnd: limit_value > 0 ? this.state.activeLimit.limitEnd : 1,
                    newLimitAmount: limit_value > 0 ? limit_value : 0,
                    newLimitMessage: true,
                },
            });
        } else if (limit_value === this.state.activeLimit.limitAmount) {
            this.setState({
                ...this.state,
                activeLimit: {
                    ...this.state.activeLimit,
                    newLimitAmount: limit_value,
                    newLimitMessage: true,
                },
            });
        }
    }

    limitMessageHandler() {
        let limitAmount = this.state.activeLimit.limitAmount;
        let newLimitAmount = this.state.activeLimit.newLimitAmount;
        let limitEnd = this.state.activeLimit.limitEnd;

        if ((limitAmount === 0 && newLimitAmount > 0) || (limitAmount > newLimitAmount && newLimitAmount !== 0)) {
            return <Translate text={'Your new limit is now set to ${value} RON'} placeholder={{ value: this.state.activeLimit.newLimitAmount }} />;
        } else if (limitAmount < newLimitAmount) {
            return <Translate text={'New Limit of ${value} will be activated over 48H'} placeholder={{ value: newLimitAmount }} />;
        } else if (limitAmount === newLimitAmount) {
            return <Translate text={'Your limit already is ${value} RON'} placeholder={{ value: newLimitAmount }} />;
        } else if ((newLimitAmount === 0 && limitEnd !== 0) || (newLimitAmount && limitEnd)) {
            return <Translate text={'Your limit will be removed over 48H'} />;
        }
    }

    render() {

        const icons = {
            settings: Icons.get('settings', `iconSettings`),
            exclamation: Icons.get('exclamationFilled', `exclamationIcon`),
        };

        let pageContent = <CircularProgress className={'Loading'} />;

        if (!this.props.profile.loading && !(Object.entries(this.state.limits).length === 0 && this.state.limits.constructor === Object)) {
            pageContent = (
                <div>
                    <div className={"Selectors"}>

                        <div className={"Buttons"}>
                            <Button
                                type={"button"}
                                click={() => this.changeLimitSubTypeHandler(PERIOD_TYPE_DAILY)}
                                className={this.state.limitSubType === this.getSubType(PERIOD_TYPE_DAILY) ? 'active' : 'default'}
                                value={this.props.t('Daily')}
                            />
                            <Button
                                type={"button"}
                                click={() => this.changeLimitSubTypeHandler(PERIOD_TYPE_WEEKLY)}
                                className={this.state.limitSubType === this.getSubType(PERIOD_TYPE_WEEKLY) ? 'active' : 'default'}
                                value={this.props.t('Weekly')}
                            />
                            <Button
                                type={"button"}
                                click={() => this.changeLimitSubTypeHandler(PERIOD_TYPE_MONTHLY)}
                                className={this.state.limitSubType === this.getSubType(PERIOD_TYPE_MONTHLY) ? 'active' : 'default'}
                                value={this.props.t('Monthly')}
                            />
                        </div>

                        <div className={"SelectorContent"}>
                            <div className={"ContentProgress"}>
                                <p>{this.state.activeLimit.limitAmount} RON |<span>{this.state.activeLimit.limitAmount - this.state.activeLimit.accumulatedAmount} RON {this.props.t('Available')}</span></p>
                                <ProgressBar value={this.calcPercentHandler(this.state.activeLimit.accumulatedAmount, this.state.activeLimit.limitAmount)} />
                                <h5>{this.calcPercentHandler(this.state.activeLimit.accumulatedAmount, this.state.activeLimit.limitAmount)}%<span>{this.props.t('Consumed')}</span></h5>
                            </div>
                        </div>

                    </div>
                    <div className={"LimitControls"}>
                        <div className={"LimitContent"}>
                            <div className={"Controls"}>
                                <div className={"Decrease"} onClick={() => this.limitChangedHandler('dec')}><span className="ControlIcon"></span></div>
                                <div className={"LimitSelector"}>
                                    <span>{(this.state.activeLimit.limitAmount ? this.state.activeLimit.limitAmount : this.props.t("Unlimited")) + " - " + this.state.activeLimit.targetAmount}</span>
                                    <input type="text" name="limit" className={"InputLimit"} onChange={this.inputChangedHandler} onBlur={this.inputBlurHandler.bind(this)} value={this.state.activeLimit.targetAmount} />
                                </div>
                                <div className={"Increase"} onClick={() => this.limitChangedHandler('inc')}><span className="ControlIcon"></span></div>
                            </div>
                            <div className={"LimitMessage " + (this.state.activeLimit.newLimitMessage ? 'show' : '')}>
                                {
                                    this.state.activeLimit.newLimitMessage
                                        ? <div>
                                            {icons.exclamation}
                                            <h5>{this.limitMessageHandler()}</h5>
                                        </div>
                                        : null
                                }
                            </div>
                            <div>
                                <Button
                                    type={"button"}
                                    className={"active"}
                                    full
                                    value={this.props.t('Set the Limit to ') + ' ' + this.state.activeLimit.targetAmount + ' RON'}
                                    click={this.submitLimit.bind(this)}
                                />
                                <Button
                                    type={"button"}
                                    className={"gradient"}
                                    full
                                    value={this.props.t('Remove Limit')}
                                    click={this.removeLimit.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={"DepositScreen"}>
                <PageTitle icon={icons.settings} title={this.props.t('Your Limits')} subtitle={this.props.t('Current settings')} />
                {pageContent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        limits: state.profile.limits,
        profile: state.profile,
        auth: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSetNewLimit: (limit_type, limit_type_period, limit_value) => dispatch(storeActions.setNewLimit(limit_type, limit_type_period, limit_value)),
        onRequestLimits: () => dispatch(storeActions.requestLimits()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Deposit)));
