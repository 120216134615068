import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Icons from "../../../assets/Icons/Icons";
import * as stateActions from "../../../store/actions";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import Utils from "../../../utils/Utils";


class RegisterBreadcrumb extends Component {

    goToStepHandler(step, force = false) {

        if (step === 0) {
            this.props.history.push('/');
        }

        if (this.props.currentStep > step || force) {
            this.props.setCurrentStep(step);
        }
    }

    render() {
        let checkmark = Icons.get('checkmarkFilled');
        let winnerFun = Utils.getQueryParam('winner_fun');

        if (winnerFun === 'true' && this.props.currendStep === 4) {
            return (<Fragment/>)
        }
        return (
            <div className={"breadcrumb"} >
                <div className={"breadcrumb-item" + (this.props.currentStep === 1 ? ' active' : '')} onClick={()=>this.goToStepHandler(1)}>
                    <div className="icon">
                        <span aria-hidden="true">01</span>
                        <div className={"done" + (this.props.currentStep > 1 ? '' : ' hide')}>
                            {checkmark}
                        </div>
                    </div>
                    <div className="name">
                        {this.props.t("Account")}
                    </div>
                </div>
                <div className={"breadcrumb-item"  + (this.props.currentStep === 2 ? ' active' : '')} onClick={()=>this.goToStepHandler(2)}>

                    <div className="icon">
                        <span aria-hidden="true">02</span>
                        <div className={"done" + (this.props.currentStep > 2 ? '' : ' hide')}>
                            {checkmark}
                        </div>
                    </div>
                    <div className="name">
                        {this.props.t("Address")}
                    </div>
                </div>

                <div className={"breadcrumb-item"  + (this.props.currentStep === 3 ? ' active' : '')} onClick={()=>this.goToStepHandler(3)}>
                    <div className="icon">
                        <span aria-hidden="true">03</span>
                        <div className={"done" + (this.props.currentStep > 3 ? '' : ' hide')}>
                            {checkmark}
                        </div>
                    </div>
                    <div className="name">
                        {this.props.t("Verification")}
                    </div>
                </div>
                <div className={"breadcrumb-item" + (this.props.signedUp ? "active focus" : '')} onClick={()=>this.goToStepHandler(4)}>

                    <div className="icon">
                        {Icons.get('diamond')}
                        <span aria-hidden="true">04</span>
                    </div>
                    <div className="name">
                        {this.props.t("BONUS")}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentStep: (step) => dispatch(stateActions.setCurrentStep(step)),
    };
};
const mapStateToProps = state => {

    return {
        currentStep: state.register.currentStep,
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(RegisterBreadcrumb)));
