export const listTypes = {
    EMPTY: 0,
    ALL: 1,
    NEW: 2,
    HOT: 3,
    FAVORITES: 4,
    PROMOTED: 5,
    JACKPOT: 6,
    RECENT: 7,
    TABLE_GAMES: 8,
};

export const listsMap = {
    0: { name: 'Empty', param: 'empty' },
    1: { name: 'All', param: 'all' },
    2: { name: 'New', param: 'new' },
    3: { name: 'Hot Favorite', param: 'hot' },
    4: { name: 'Favorites', param: 'favorites' },
    5: { name: 'Top Rated', param: 'promoted' },
    6: { name: 'Jackpot', param: 'jackpot' },
    7: { name: 'Latest Played', param: 'recent' },
    8: { name: 'Table Games', param: 'table-games' }
};
