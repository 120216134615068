import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../store/actions";
import "./CasinoGames.scss";
import TitleBar from './../UI/TitleBar/TitleBar';
import Collections from "../Collections/Collections";

const styles = theme => ({
    paper: {
        padding: theme.spacing(1),
        backgroundColor: '#26303c',
        color: '#6e767e',
    },
    container: {
        padding: theme.spacing(0.5, 0),
    },
});

const CasinoGames = (props) => {
    const { t } = useTranslation();
    const classes = props.classes;

    let pType = 'cazino';
    let pCode = 'jocuri';

    useEffect(() => {
        props.pageType(pType);
        props.pageCode(pCode);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const backHandler = () => {
        props.history.push("/");
    };

    return (
        <div className={`casinoGamesPage ${window.config.showTopMessageWebsiteSwitcher !== '1' ? '' : 'website_switcher'}`}>
            <div className={props.application.topMenuHeight}></div>
            <TitleBar pageTitle={t("Slots")} backHandler={backHandler} />
            <Collections />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        application: state.application,
        games: state.games,
        jackpot: state.jackpot,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        pageType: (pType) => dispatch(storeActions.setPageType(pType)),
        pageCode: (pCode) => dispatch(storeActions.setPageCode(pCode)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(CasinoGames)));
