import React, { Component } from 'react';
import Utils from "../../../../utils/Utils";
import {withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import PhoneNumberValidator from "../../../../utils/PhoneNumberValidator";
import * as storeActions from "../../../../store/actions";
import {connect} from "react-redux";
import Icons from "../../../../assets/Icons/Icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import GTM from "../../../../utils/GTM";


class Phone extends Component {
    constructor(props) {
        super(props);
        this.timerId = 'phoneComponent';
    }
    componentDidMount() {
        this.infoMessage = this.props.t("Insert your phone number");
    }



    state = {
        message: "",
        fieldInput: '',
        ok: false,
        serverSideValidationSent: false
    };

    keyPressedHandler(event) {
        // not number
        if (event.charCode < 48 || event.charCode > 57) {
            // check for " ", ".", "-", "+"
            if (event.charCode !== 45 && event.charCode !== 46 && event.charCode !== 32 && event.charCode !== 43) {
                event.preventDefault();
            }
        }
    }


    serverSideValidation(phone) {
        // let axios = BackendClient.getInstance();
        // axios({
        //     url: '/api/validate/phone',
        //     method: 'post',
        //     data: {
        //         phone: phone,
        //     }
        // }).catch(e => {
        //     console.log(`[CATCHED **PHONE VALIDATION** ERROR] => ${e}`);
        // });
        // this.setState({
        //     ...this.state,
        //     serverSideValidationSent: true,
        // });
        // this.props.onServerSideValidation();
    }

    triggerHandler() {
        let message = "";
        let valid = PhoneNumberValidator.validate(this.state.fieldInput);
        let ok = this.state.ok;

        if (valid) {
            this.props.validate(this.state.fieldInput);
            if (!window.config.cordova) {
               this.setState({
                   ...this.state,
                   serverSideValidationSent: true,
               });
            }
            if (!ok) {
                ok = true;
            }
        } else {

            message = this.props.t("Phone number invalid");
            if (ok) {
                ok = false;
            }
        }
        this.setState({
            ...this.state,
            message: message,
            ok: ok,
        });
        this.props.onChange({phone: this.state.fieldInput,validated: ok});
    }
    sendGAEvents() {
        GTM.pushGAEvent('Register_'+this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Phone" + (this.state.ok ? "" : " - " + this.state.message));
    }
    blurHandler() {
        Utils.delayedFunction('sendGAEvent',this.sendGAEvents.bind(this),50);
        Utils.delayedFunction(this.timerId,this.triggerHandler.bind(this),0);
        Utils.fixRegisterInputs('phone');
        this.props.setSpineSize('big');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.error !== prevProps.error) {

            if ((prevProps.error === false && this.props.error === '') || (prevProps.error === '' && this.props.error === false)) {
            } else {
                GTM.pushGAEvent('Register_'+ this.props.lang, 'Field - Core Error', "Phone");
            }
            this.setState({
                ...this.state,
                message: this.props.error,
                serverSideValidationSent: false
            })
        }

    }

    changeHandler(event){
        let fieldInput;

        if (null === event) {
            fieldInput = this.props.phone;
        } else {
            fieldInput = event.target.value;
        }
        if (fieldInput !== this.state.fieldInput && this.state.fieldInput !== '') {
            window.localStorage.removeItem('phoneValidationCode');
        }
        Utils.delayedFunction(this.timerId,this.triggerHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput,
        });

        this.props.onChange({phone: fieldInput,validated: false});
    }



    onFocusHandler(e) {

        Utils.fixRegisterInputs('phone');

        this.props.setSpineSize('small');
        this.setState({
            ...this.state,
            message: this.infoMessage,
            fieldInput: this.state.fieldInput === '' && this.props.phone ? this.props.phone : this.state.fieldInput
        });

    }

    render() {
        let errorMessage = this.state.message;
        if (errorMessage.type === "" && this.props.error !== "") {
            errorMessage = this.props.error; // error from server
        }
        let props = {
            error: (errorMessage !== "" && errorMessage !== this.infoMessage && errorMessage !== false),
            value: this.props.phone
        };

        let icon = Icons.get('register-phone');

        return (
            <div className={'field'}>
                {icon}
                <TextField
                    {...props}
                    label={this.props.t("Phone")}
                    id={"phone"}
                    type={"tel"}
                    className={''}
                    onKeyPress={this.keyPressedHandler.bind(this)}
                    onChange={this.changeHandler.bind(this)}
                    onFocus={this.onFocusHandler.bind(this)}
                    onBlur={this.blurHandler.bind(this)}
                    helperText={this.state.message}
                />
                {this.state.serverSideValidationSent && <div className="loader"><CircularProgress color="primary" /></div>}
            </div>


        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered({field: "phone", value: fieldInput.phone})),
        validate: (fieldInput) => dispatch(storeActions.validateField(fieldInput,'phone')),
        setSpineSize: (size) => dispatch(storeActions.setSpineSize(size))//this.props.setSpineSize('small');
        // requestSmsCode: () => dispatch(storeActions.requestSmsCode()),
    };
};

const mapStateToProps = state => {
    return {
        phone: state.register.fields.phone,
        error: state.register.errors.phone,
        lang: state.application.language
        // reward: state.registerWizard.rewards.phone,
        // pendingValidation: state.registerWizard.serverSideValidation.phone,

    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Phone));
