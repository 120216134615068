import CreateNewAccount from "./CreateNewAccount";
import PersonalDetails from "./PersonalDetails";
import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import Verification from "./Verification";
import RegisterFooter from "./RegisterFooter";
// import * as stateActions from "../../../store/actions";

class Steps extends Component {

    componentDidUpdate() {
        this.goToStep();
    }
    goToStep() {
        // if (this.props.step !== false) {
        //     // this.props.preventGoToStep();
        //     this.props.goToStep(this.props.step);
        // }
    }

    render() {

        const AllComponents = {
          CreateNewAccount: CreateNewAccount,
          PersonalDetails: PersonalDetails,
            Verification: Verification,
        };
        const Type = AllComponents[this.props.form];
        if (!Type) {
            return ;
        }
        return (
            <div>
                <Type />
                <RegisterFooter nextStep={this.props.nextStep} currentStep={this.props.currentStep} goToStep={this.props.goToStep}/>
            </div>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // preventGoToStep: () => dispatch(stateActions.goToStep(false))
    };
};
const mapStateToProps = state => {

    return {
        // step: state.registerWizard.goToStep,
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(Steps);
