import React from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import "./cta-promotions.scss";
import { ReactComponent as CalendarIcon } from "../assets/calendar.svg";
import SimpleTimer from "./simple_timer";
import evBusMain from "../../utils/evbus";

const urlPart = `https://${window.config.cdn_address}/${window.config.brand_name_short.toLowerCase()}/winter-time/img-bg-winterpromo-mobile.png`;

const style = {
  backgroundImage: `url(${urlPart})`
}

const WinterCTA = (props) => {
  const { t, winterStatus } = props;
  const [mounted, setMounted] = React.useState(false);

  const nodeRef = React.useCallback(node => {
    if (node !== null) {
      setMounted(true);
    }
  }, []);

  React.useEffect(() => {
    if (mounted) {
      const day = moment().format("D");
      const month = moment().format("MMM");

      let el = document.querySelector(".promo-winter-cta-promotions .winter-calendar-day");
      if (el) {
        el.innerHTML = day;
      }
      el = document.querySelector(".promo-winter-cta-promotions .winter-calendar-month");
      if (el) {
        el.innerHTML = month.toLocaleUpperCase();
      }
    }
  }, [mounted]);

  const reloadStatus = () => { };

  if (!winterStatus) return null;
  if (winterStatus && winterStatus.campaign_settings && winterStatus.campaign_settings.winter) {
    if (winterStatus.campaign_settings.winter.start_date && winterStatus.campaign_settings.winter.end_date) {
      const now = moment().valueOf();
      const start_start = winterStatus.campaign_settings.winter.start_date;
      const end_date = winterStatus.campaign_settings.winter.end_date;

      if (!(start_start <= now && now <= end_date)) {
        return null;
      }
    }
  }

  const checkUnlockState = () => {
    let unlock = true;

    const day = moment().format("D");
    if (parseInt(day) > 25) return false;

    if (winterStatus) {

      const prizes = {};

      if (winterStatus && winterStatus.winter_prizes) {
        Object.keys(winterStatus.winter_prizes).forEach(id => {
          prizes[winterStatus.winter_prizes[id].day] = false;
        });
      }

      if (winterStatus && winterStatus.winter_status && winterStatus.winter_status.prizes) {
        winterStatus.winter_status.prizes.forEach(id => {
          if (winterStatus && winterStatus.winter_prizes && typeof winterStatus.winter_prizes[id] !== "undefined") {
            if (winterStatus.winter_prizes[id].day) {
              prizes[winterStatus.winter_prizes[id].day] = true; // prize claimed on this day
            }
          }
        });
      }


      if (prizes[day.toString()]) { // already unlocked
        unlock = false;
      }
    }

    return unlock;
  };
  const canUnlock = checkUnlockState();
  const endOfDay = moment().endOf("day").valueOf();

  const openPromo = () => {
    props.history.push("/winter-time");
  };

  return (
    <div className="promo-winter-cta-promotions" style={style} ref={nodeRef} onClick={openPromo}>
      <div className="calendar">
        <CalendarIcon />
      </div>
      <div className="footer">
        {canUnlock &&
          <div className="promo-now">
            <div className="promo-button">{t("ACTIVATE AWARD")}</div>
            <div className="promo-arrow">
              <NavigateNextIcon />
            </div>
          </div>
        }
        {!canUnlock && <div className="promo-count-down">
          <div className="txt">{t("Next prize in")}</div>
          <div className="count"><SimpleTimer date={moment(endOfDay + 10, "x")} onLive={reloadStatus} /></div>
        </div>}
      </div>
    </div>
  );
}

const mapState = (state) => {
  return {
    winterStatus: state && state.promo && state.promo.winter && state.promo.winter.status ? state.promo.winter.status : null,
  }
};

export default withRouter(connect(mapState)(withTranslation()(WinterCTA)));