import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import Icons from '../../../assets/Icons/Icons';
import TitleBar from '../../UI/TitleBar/TitleBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Payment.scss';
import { connect } from "react-redux";
import * as storeActions from "../../../store/actions";
import MarketingEvents from "../../../utils/MarketingEvents";

import { openGameLauncher, setGameOpened } from '../../../store/actions';
const noop = () => { };

const Payment = (props) => {
    const [renderEvents, setRenderEvents] = useState(false);
    let urlString = window.location.href;
    let url = new URL(urlString);
    useEffect(() => {
        if (window.location.pathname === '/payment/success') {
            props.resetRequest('get-eligible-bonuses');
            registerDepositEvents();
        }
    }, []);

    const goToCustomerSupport = () => {
        props.history.push('/customer-support/about-us');
    };
    const goToWalletAndBonuses = () => {
        props.history.push('/profile/wallet');
    };
    const goToPendingWithdrawals = () => {
        props.history.push('/profile/pending-withdrawals');
    };
    const goToSlots = () => {
        let fromGame = localStorage.getItem("fromGame");
        if (fromGame) {
            fromGame = JSON.parse(fromGame);
        }

        if (fromGame?.game_id) {
            props.openGameLauncher(fromGame.game_id ? fromGame.game_id : fromGame.id);
            props.setGameOpened(fromGame);

            let url = '/play/' + (fromGame.game_id ? fromGame.game_id : fromGame.id);
            props.history.push(url);
            localStorage.removeItem("fromGame");
            return;
        }
        props.history.push('/cazino/jocuri');
    };
    const goToHome = () => {
        props.history.push('/');
    };
    const registerDepositEvents = () => {
        let totalAmount = url.searchParams.get("totalAmount");
        let currency = url.searchParams.get("currency");

        MarketingEvents.depositCompleted({
            amount: totalAmount ?? 0,
             currency: currency?? '',
        });

        setRenderEvents(true)
    };

    let pageContent = <CircularProgress className={'Loading'} />;

    switch (window.location.pathname) {
        case '/payment/success':
            let urlString = window.location.href;
            let url = new URL(urlString);
            let totalAmount = url.searchParams.get("totalAmount");
            let currency = url.searchParams.get("currency");

            pageContent = (
                <div className={"CheckoutContent"}>
                    <div className="Content Success">
                        <div className="icon-success">
                            {Icons.get('checkmarkFilled')}
                        </div>
                        <h6>{props.t('Deposit Successful!')}</h6>
                        <p>{props.t("Time to sit back, play and relax. Good luck!")}</p>
                        {props.bonusErrors && <p>
                            {props.t("Your deposit has been successfully completed, but an error occured while trying to add the bonus to your account. Please contact the Customer Service to fix this.")}
                        </p>}
                        <Button fullWidth className={'text'} onClick={goToWalletAndBonuses}>
                            {props.t("Wallet & Bonus")}
                        </Button>
                        <Button fullWidth variant="contained" size="large" className={'depositButton'} onClick={goToSlots}>
                            {props.t("Time to play")}
                        </Button>
                    </div>
                </div>
            );
            break;

        case '/payment/failure':
            pageContent = (
                <div className={"CheckoutContent"}>
                    <div className="Content Error">
                        <div className="icon-exclamation">
                            {Icons.get('exclamationFilled', `ExclamationIcon`)}
                        </div>
                        <h6>{props.t('Operation Failed')}</h6>
                        <div className="ModalContent">
                            <p>{props.t("An error has occured:")}</p>
                            <p>{props.t("(Error 202,1) This is an example of error description. The bank refused the transaction with this card. It may be expired or reported as stolen.")}</p>
                        </div>
                        <Button fullWidth className={'text'} onClick={goToCustomerSupport}>
                            {props.t("Customer Service")}
                        </Button>
                        <Button fullWidth variant="contained" size="large" className={'depositButton'} onClick={goToWalletAndBonuses}>
                            {props.t("I got it")}
                        </Button>
                    </div>
                </div>
            );
            break;

        case '/payment/pending':
            pageContent = (
                <div className={"CheckoutContent"}>
                    <div className="Content Pending">
                        <div className="icon-exclamation">
                            {Icons.get('exWarning', `ExclamationIcon`)}
                        </div>
                        <h6>{props.t('In Review')}</h6>
                        <div className="ModalContent">
                            <p>{props.t("For the safety of your account, this deposit requires review from our Risk & Payments department.")}</p>
                            <p><strong>{props.t("Your funds are safe. You will be notified when they will be added to your account.")}</strong></p>
                        </div>
                        <Button fullWidth className={'text'} onClick={goToPendingWithdrawals}>
                            {props.t("My pending funds")}
                        </Button>
                        <Button fullWidth variant="contained" size="large" className={'depositButton'} onClick={goToHome}>
                            {props.t("Okay")}
                        </Button>
                    </div>
                </div>
            );
            break;
        case '/payment/cancel':
            goToHome();
            break;
        default:
            pageContent = <CircularProgress className={'Loading'} />;
            break;
    }

    return (
        <div className={"PaymentScreen"}>
            <TitleBar pageTitle={props.t('Checkout')} backHandler={goToHome} closeHandler={goToHome} closeButton={true} />
            <div className={"PaymentContent"}>
                {pageContent}
            </div>
            {window.config.brand_name_short.toLowerCase() === 'winner' && renderEvents && props.player && props.player.client_player_id && <img style={{ "display": "none" }} src={"https://wlwinnerro.adsrv.eacdn.com/Processing/Pixels/Sales.ashx?Playerid=" + props.player.client_player_id + "&mid=2&saleid=&dpst=" + url.searchParams.get("totalAmount")}></img>}
        </div>
    );
};
const mapStateToProps = state => {

    return {
        bonusErrors: state.paymentCheckout.bonusError,
        player: state.profile
    };
};
const mapDispatchToProps = dispatch => {
    return {
        resetRequest: (id) => dispatch(storeActions.resetOneRequest(id)),
        openGameLauncher: (gameId) => dispatch(openGameLauncher(gameId)),
        setGameOpened: (game) => dispatch(setGameOpened(game)),
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment)));