import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from '../../../../../store/actions/index';
import Button from '../../../../UI/Button/Button';
import './SmsVerification.scss';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ResponseErrorMessages from "../../../../../store/errors/ResponseErrorMessages";
import CircularProgress from '@material-ui/core/CircularProgress';

class SmsVerification extends Component {

    state = {
        smsCode: '',
        success: false,
        message: '',
        ok: true,
    }

    componentDidUpdate(prevProps){
        if (this.props.profile !== prevProps.profile) {
            if(!this.props.profile.loading){
                if(Array.isArray(this.props.profile.receivedPhoneCode) && this.props.profile.receivedPhoneCode.length === 0){
                    this.setState({
                        ...this.state,
                        success: true,
                        message: 'Phone number changed',
                    });

                    setTimeout(() => {
                        this.props.history.push("/profile/account");
                    }, 2000);
                }else{
                    if(this.props.profile.receivedPhoneCode){
                        this.setState({
                            ...this.state,
                            message: ResponseErrorMessages.get(this.props.profile.receivedPhoneCode.ResponseCode),
                        });
                    }
                }
            }
        }
    }

    handleChange = prop => event => {
        this.setState({
            ...this.state, 
            [prop]: event.target.value
        })
    };

    validateHandler() {
        if(this.state.smsCode.length > 3){
            this.setState({
                ...this.state,
                ok: true,
            });
            this.props.onCheckPhoneCode(this.props.newPhoneNumber, this.state.smsCode);
        }else{
            this.setState({
                ...this.state,
                ok: false,
            });
        }
    }

    render() {

        let infoMessage = '';

        if(this.state.success && this.state.message !== ''){
            infoMessage = (
                <div className={'SuccessMessage'}>
                    <h2>{this.props.t(this.state.message)}!</h2>
                </div>
            );
        }else if(!this.state.ok){
            infoMessage = (
                <div className={'ErrorMessage'}>
                    <h2>{this.props.t('SMS Code must be more than 3 characters')}</h2>
                </div>
            );
        }else if(!this.state.success && this.state.message !== ''){
            infoMessage = (
                <div className={'ErrorMessage'}>
                    <h2>{this.props.t(this.state.message)}</h2>
                </div>
            );
        }

        return (
            <div className={"SmsVerificationScreen"}>
                <div className="SmsVerificationContent">
                    <FormControl className="SmsVerificationInput">
                        <InputLabel htmlFor="SmsVerificationField">{this.props.t('SMS Code')}</InputLabel>
                        <Input
                            id="SmsVerificationField"
                            type='smsCode'
                            value={this.state.smsCode}
                            onChange={this.handleChange('smsCode')}
                        />
                    </FormControl>
                </div>
                {infoMessage}
                <div>
                    <Button 
                        className={'gradient'} 
                        disabled={this.props.profile.loading} 
                        full 
                        click={this.validateHandler.bind(this)} 
                        value={
                            this.props.profile.loading 
                            ? <CircularProgress className={'Loading'} /> 
                            : this.props.t('Validate code')
                        }
                    />
                    <Button 
                        className={'active'} 
                        full 
                        click={() => this.props.next(false)} 
                        value={this.props.t('check number and resend code')}
                    />
                    <Button 
                        className={'gray'} 
                        full 
                        click={() => this.props.history.push("/profile/account")} 
                        value={this.props.t('Cancel')}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckPhoneCode: (phone, smsCode) => dispatch(storeActions.checkPhoneCode(phone, smsCode)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SmsVerification)));