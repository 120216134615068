import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import { Grid, List } from '@material-ui/core';
import { connect } from 'react-redux';
import * as storeActions from "./../../store/actions";
import './RightMenu.scss';
import Icons from "../../assets/Icons/Icons";
import LeftMenuItem from "./../LeftMenu/LeftMenuItem/LeftMenuItem";
import ProfileDetails from './Sections/ProfileDetails';
import SlideToggleContent from "../SlideToggleContent/SlideToggleContent";
import IconHide from '@material-ui/icons/VisibilityOff';
import IconShow from '@material-ui/icons/Visibility';
import ProfileWallet from './Sections/ProfileWallet';
import BonusWidget from "../GlobalWidgets/LoggedIn/Bonus/BonusWidget";
import {STATUS_APPROVED, STATUS_EXPIRED, TYPE_CARD, TYPE_CUSTOM} from "../Profile/ProfileActions/Documents/Documents";

const styles = theme => ({
    paper: {
        background: "#121921",
        color: "white",
    },
    margin: {
        margin: theme.spacing(4),
    },
    // hideProfile: {
    //     backgroundImage: `url(${HideProfileImage})`,
    //     backgroundColor: 'transparent',
    //     backgroundRepeat: 'no-repeat',
    //     border: '0px',
    //     backgroundPosition: 'center 0px',
    // }
});

const RightMenu = (props) => {
    const classes = props.classes;
    const { t } = useTranslation();
    const variant = 'temporary';

    useEffect(() => {
        props.onRequestPendingWithdrawals();
        // props.onRequestLocations();
    }, []);

    let showProfile = props.menu.showProfileSection;

    // const closeHandler = () => event => {
    //     console.log("Closing right menu!");
    //     props.closeRightMenu();
    // };

    const logoClickHandler = () => {
        props.history.push("/");
        props.closeRightMenu();
    };

    const toggleProfileHandler = (event) => {
        let show = false;
        if (!showProfile) {
            show = true;
        }

        props.toggleProfile(show);
    };

    const icons = {
        eldorado: (<img src={window.location.protocol + "//" + window.config.cdn_address + window.config.MOBILE_BRAND_LOGO} />),//Icons.get('casinoLogo', `casinoLogo`),
        closeMenu: Icons.get('closeIcon', 'CloseMenuIcon'),
    };

    let toggleProfileIcon = <IconHide />;
    if (!showProfile) {
        toggleProfileIcon = <IconShow />;
    }
    const verifyDocuments = () => {
        let documentsOk = true;
        if (!props.emailVerified) {
            documentsOk = false;
        } else if (props.documents) {
            if (props.documents.documentsRequest) {
                let documents = props.documents.documentsRequest;
                for (let i = 0; i < documents.length; i++) {
                    if (documents[i].status !== STATUS_APPROVED && documents[i].status !== STATUS_EXPIRED && documents[i].type !== TYPE_CUSTOM) {
                        documentsOk = false;
                        break;
                    }
                }
            }
        }
        return documentsOk;
    };

    // const verifyCards = () => {
    //     let cardsOK = true;
    //     if (props.documents) {
    //         if (props.documents.documentsRequest) {
    //             let documents = props.documents.documentsRequest;
    //             for (let i = 0; i < documents.length; i++) {
    //                 if (documents[i].status !== STATUS_APPROVED && documents[i].type === TYPE_CARD && documents[i].status !== STATUS_EXPIRED) {
    //                     cardsOK = false;
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //     return cardsOK;
    // };

    const verifyWithdrawals = () => {
        let withdrawalsAmount = 0;
        if (props.withdrawals) {
            if (props.withdrawals.approved) {
                withdrawalsAmount = props.withdrawals.approved.length;
            }
        }
        return withdrawalsAmount;
    };

    return (
        <Grid item >
            <div className={'rightMenuDrawer'}>
                {/*<Drawer anchor="right" variant={variant} open={props.menu.rightMenu} onClose={ closeHandler() } classes={{ paper: classes.paper }}>*/}
                <div className={`rightMenuHeader ${props.application.topMenuHeight} ${window.config.showTopMessageWebsiteSwitcher !== '1' ? '' : 'websiteSwitcher'}`} onClick={logoClickHandler}>
                    {icons.eldorado}
                    <ButtonBase
                        key={"closeButton"}
                        className={"closeButton"}
                        onClick={() => logoClickHandler()}
                        color="inherit"
                        aria-label="Close">
                        {icons.closeMenu}
                    </ButtonBase>
                </div>
                <List className={" noTopBorder profileAnimSupport"}>
                    <ProfileDetails />
                </List>
                <BonusWidget/>
                <div className="rightMenuDrawer walletDetails">
                    <div className={"toggleProfileSupport"}>
                        <button className={`${classes.hideProfile} toggleProfileHandler`} onClick={toggleProfileHandler}>
                            <span className="left"></span>
                            <span className="middle">
                                {toggleProfileIcon}
                            </span>
                            <span className="right"></span>
                        </button>
                    </div>
                    <SlideToggleContent isVisible={showProfile} forceSlideIn={false}>
                        <ProfileWallet />
                    </SlideToggleContent>
                    <SlideToggleContent isVisible={!showProfile} forceSlideIn={false}>
                        <span className="description">{t("Funds hidden")}</span>
                    </SlideToggleContent>
                </div>
                {/*<ProfileBonus />*/}
                <List className={"rightMenuDrawer accountMenuDrawer noTopBorder noTopPadding"}>
                    <LeftMenuItem pageType={'profile'} pageCode={'wallet'} forceIcon={'account'} buttonText={t('Wallet & Bonuses')} widgetType={'rightMenuMain'} className={"accountButton"} />
                    <LeftMenuItem pageType={'profile'} pageCode={'pending-withdrawals'} forceIcon={"transaction"} buttonText={t("Withdrawals")} widgetType={'rightMenuMain'} className={"accountButton"} alertNumber={verifyWithdrawals()} />
                    <LeftMenuItem pageType={'profile'} pageCode={'transactions'} forceIcon={"exchange"} buttonText={t("Transaction History")} widgetType={'rightMenuMain'} className={"accountButton"} />
                    {<LeftMenuItem pageType={'profile'} pageCode={'documents'} buttonText={t('Documents')} forceIcon={'identity'} widgetType={!verifyDocuments() ? 'withExclamationPoint' : 'rightMenuMain'} className={"accountButton"} />}
                    {/*{<LeftMenuItem pageType={'profile'} pageCode={'cards'} forcedSubpage buttonText={t('My Cards')} forceIcon={'identity'} widgetType={!verifyCards() ? 'withExclamationPoint' : 'rightMenuMain'} className={"accountButton"} />}*/}
                </List>
                <List className={"rightMenuDrawer noTopBorder"}>
                    {/*<LeftMenuItem pageType={'root-level'} pageCode={'locations'} buttonText={t("My Locations")} noIcon={true} customTextClass={"thinName secColor"} />*/}
                    <LeftMenuItem pageType={'profile'} pageCode={'responsibly'} buttonText={t("Limits")} noIcon={true} customTextClass={"thinName secColor"} />
                    <LeftMenuItem pageType={'profile'} pageCode={'take-a-break'} buttonText={t("Take a break")} noIcon={true} customTextClass={"thinName secColor"} />
                    <div className="logout">
                        <LeftMenuItem pageType={'connect'} pageCode={'authenticate'} buttonText={"Log In"} customTextClass={"thinName secColor underLine"} widgetType={"auth"} />
                    </div>
                </List>
                {/*</Drawer>*/}
            </div>
        </Grid>
    );
};

const mapStateToProps = state => {
    return {
        menu: state.menu,
        application: state.application,
        documents: state.documents,
        withdrawals: state.withdrawals,
        emailVerified: state.profile.email_verified
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openRightMenu: () => dispatch(storeActions.openRightMenu()),
        closeRightMenu: () => dispatch(storeActions.closeRightMenu()),
        toggleProfile: (show) => dispatch(storeActions.rightProfileSection(show)),
        onRequestPendingWithdrawals: () => dispatch(storeActions.requestPendingWithdrawals({cooldown: true})),
        // onRequestLocations: () => dispatch(storeActions.requestLocations()),
        showGlobalWidgets: () => dispatch(storeActions.globalWidgets(true))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RightMenu)));
