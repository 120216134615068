import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { Typography, Button } from "@material-ui/core";
import * as storeActions from './../../../../store/actions';
import './LogMessage.scss';
import TitleBar from "../../../UI/TitleBar/TitleBar";
import Game from "../Game/Game";
import Utils from "../../../../utils/Utils";

const LogMessage = (props) => {
    const { t } = useTranslation();

    const loginHandler = () => {
        props.closeRedirectLauncher(() => {
            localStorage.setItem("redirect_after_login", window.location.pathname);
            props.close();
            props.history.push("/connect");
        });
    };

    const registerHandler = () => {
        props.closeRedirectLauncher(() => {
            props.close();
            props.history.push("/register");
        });
    };

    // const cdnGamesUrl = window.location.protocol + '//' + window.config.cdn_address + '/' + window.config.cdn_games_path + '/small/egt';

    const close = () => {
        props.close();
        props.history.push("/cazino/jocuri");
    }

    return (

        <div className={"logMessage"}>
            <TitleBar pageTitle={t('Start Game')} backHandler={close} closeHandler={close}/>
            <div className="background" style={{ backgroundImage: `url(${window.config.front_url}/img/mobile/home/jackpot_bg.png)` }}> </div>

            <div className={"messageArea"}>
                <div className="icon">
                    {props.game ? <Game item={props.game} launchHandler={(gameItem, cardRef) => { return false; }} /> : ''}
                </div>
                <Typography component="h2">
                    {props.game.name}
                </Typography>
            </div>
            <div className={"buttonsArea"}>
                <Button variant="contained" size="large" color="primary" className={'register'} onClick={registerHandler}>{t("register")}</Button>
                <Button variant="contained" size="large" color="primary" className={'login'} onClick={loginHandler}>{t("Sign-in")}</Button>
            </div>
        </div>

    );
};
const mapStateToProps = state => {
    return {
        game: state.games.activeGame,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        closeRedirectLauncher: (callback) => dispatch(storeActions.closeAndRedirectGL(callback)),
        close: () => dispatch(storeActions.closeGameLauncher()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LogMessage));

