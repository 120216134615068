import HeaderFront from './HeaderFront';
import HeaderFrontVariant7 from './HeaderFrontVariant7';
import HeaderFrontVariant20 from './HeaderFrontVariant20';

let defaultComponent = HeaderFront;

if (window.config.jackpotFrontVariant === "7") {
  defaultComponent = HeaderFrontVariant7;
}
if (window.config.jackpotFrontVariant === "20") {
  defaultComponent = HeaderFrontVariant20;
}

export default defaultComponent;