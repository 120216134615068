import * as actionTypes from './../actions/actionTypes';
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";

const realInitialState = {
    errors: {
        phone: false,
        checkbox: false,
    },
    fields: {
        citizenship: "",
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        cnp: '',
        refferenceCode: '',
        phoneValidationCode: '',
        ip: '',
        address: '',/*{
            country_code: "",
            administrative_area_long: "",
            administrative_area_short: "",
            locality: "",
            street: "",
            street_no: "",
            latitude: "",
            longitude: "",
            formated_adress: "",
            postal_code: ""
        },*/
        code: '',
        marketing: false,
        marketing_email: false,
        marketing_sms: false,
        marketing_phone: false,
        marketing_partners: false,

        moid: '',
        lpid: '',
        paid: ''
    },
    validated: {},
    currentStep: 1,
    spineSize: 'big',
    bonusValue: 0,
    currency: 'lei',
    signedUp: null,
    signedUpErrors: false,
    SMSTimer: false,
    signUpInProgress: false,
    accountEmailValidated: null,
    maxBonus: window.config.WELCOME_BONUS_VALUE,
    winCoins: 0,
    verificationType: 1
};

const initialState =  realInitialState;

// const BONUS_PER_STEP = 111;
// const MAX_BONUS = 1000;


const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.register.VALIDATE_FIELD_RESPONSE:
            let errors = { ...state.errors };
            errors[action.field] = action.message;
            let validatedInfo = { ...state.validated };
            validatedInfo[action.field] = action.message === false;

            let bonus = 0;

            Object.keys(validatedInfo).forEach(function (key) {

                if (validatedInfo[key]) {
                    bonus += parseInt(state.maxBonus/9);
                    if (bonus === parseInt(state.maxBonus/9)*9 || bonus > state.maxBonus) {bonus = state.maxBonus}
                }
            });
            return {
                ...state,
                errors: errors,
                validated: validatedInfo,
                bonusValue: bonus
            };
        case actionTypes.register.FIELD_CHANGED:
            let fields = { ...state.fields };
            fields[action.fieldInfo.field] = action.fieldInfo.value;

            let validated = { ...state.validated };
            validated[action.fieldInfo.field] = action.fieldInfo.validated;

            let fieldsError = {...state.errors};
            fieldsError[action.fieldInfo.field] = "";
            return {
                ...state,
                fields: fields,
                validated: validated,
                errors: fieldsError
            };
        case actionTypes.register.SET_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.step
            };
        case actionTypes.register.SET_BONUS_COINS:
            let winCoins = 0;
            let bonusCoins = action.bonusCoins;
            if (action.bonusCoins === 1) {
                winCoins = 0;
            } else {
                winCoins = bonusCoins;
                bonusCoins = 1;
            }
            return {
                ...state,
                fields: {
                    ...state.fields,
                    bonusCoins: action.bonusCoins
                },
                winCoins: winCoins
            };
        case actionTypes.register.SIGN_UP_RESPONSE:
            let signedUp = true;
            let signedUpErrors = false;

            if(typeof action.errors !== "undefined" && typeof action.errors.result !== "undefined") {
                if(typeof action.errors.result.ResponseCode !== "undefined") {
                    signedUp = false;
                    signedUpErrors = action.errors.result.ResponseMessage;
                } else {
                    if (typeof action.errors.result.validation_messages !== "undefined") {
                        signedUp = false;
                        signedUpErrors = action.errors.result.validation_messages;
                    }
                }
            } else {
                signedUp = action.value;
                if (!signedUp) {
                    signedUpErrors = 'unknown';
                }
            }
            return {
                ...state,
                signedUp: signedUp,
                signedUpErrors: signedUpErrors,
                signUpInProgress: false,
            };
        case actionTypes.register.SET_SPINE_SIZE:
            return {
                ...state,
                spineSize: action.size
            };
        case actionTypes.register.CHECKBOX_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    checkbox: true,
                }
            };
        case actionTypes.register.START_SIGN_UP:
            let maxBonus = state.maxBonus;
            // console.log("maxBonus",maxBonus);
            return {
                ...state,
                signUpInProgress: true,
                bonusValue: maxBonus
            };
        case actionTypes.register.RESET:
            return realInitialState;
        case actionTypes.register.SET_TIMER:
            return {
                ...state,
                SMSTimer: action.value
            };
        case actionTypes.register.ACCOUNT_EMAIL_VALIDATED:
            return {
                ...state,
                accountEmailValidated: action.validated
            };
        case actionTypes.register.UPDATE_REGISTER_FIELDS:
            let fieldValues = { ...state.fields };
            Object.keys(action.data).forEach(fieldName => {
                switch (fieldName) {
                    case 'nin':
                        fieldValues['cnp'] = action.data[fieldName];
                        break;
                    case 'first_name':
                        fieldValues['firstName'] = action.data[fieldName];
                        break;
                    case 'last_name':
                        fieldValues['lastName'] = action.data[fieldName];
                        break;
                    case 'moid':
                    case 'lpid':
                        window.localStorage.setItem(fieldName,action.data[fieldName]);
                        fieldValues[fieldName] = action.data[fieldName];
                        break;
                    default:
                        fieldValues[fieldName] = action.data[fieldName];
                        break;

                }
            })


            return  {
                ...state,
                fields: fieldValues
            }
        case actionTypes.marketingOffer.SET:
            ExpiringLocalStorage.set('moid',action.mo ? action.mo.moid : '');
            return {
                ...state,
                fields: {
                    ...state.fields,
                    moid: action.mo ? action.mo.moid : ''
                }
            };
        case actionTypes.register.SET_VERIFICATION_TYPE:
            return {
                ...state,
                verificationType: parseInt(action.value,10)
            }
        default:
            return state;
    }
};

export default reducer;
