import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import Tile1 from './../../assets/Images/PromotionCards/img---homepage---tile---promo---welcomebonus@3x.jpg';
// import Tile2 from './../../assets/Images/PromotionCards/img---homepage---tile---promo---kycbonus@3x.jpg';

// import Bg from './../../assets/Images/PromotionCards/img_homepage_tile_promo_bg@3x.jpg';
import './PromotionCards.scss';
// import Utils from "../../utils/Utils";

class PromotionCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expand: false,
    };
  }
  handleClick = () => {
    if (this.state.expand) {
      console.log("go to promotions");
      this.goToPromotions();
    } else {
      this.setState({
        expand: true
      })
    }
  }

  goToPromotions = () => {
    this.props.history.push('/cazino/promotii');
  }

  render() {
    const { title1, title2, width, t } = this.props;

    const margins = 38;
    const paddings = 20;
    const tileRatio = 0.529;

    let maxHeight = (width - margins) * tileRatio + paddings;
    let clsName = "cards";

    if (this.state.expand) {
      // when expanded the cards wrapper must double in size we can have a nice animation of it
      maxHeight = 2 * maxHeight;
      clsName = "cards hover";
    }

    return (
      <div className="promotions-cards">
        <div className="wrapper" /*style={{ backgroundImage: `url(${Utils.getImageUrl(Bg)})` }}*/>
          <div className="header">
            <div className="title">{t("Don't miss the greatest")}</div>
            <div className="subtitle">{t("PROMOTIONS")}</div>
          </div>
          <div className={clsName} style={{ maxHeight: `${maxHeight}px` }}>
            <div className="card" onClick={this.handleClick}>
              <img src={Tile1} className="promo1" alt="" />
              <div className="text">{title1}</div>
            </div>
            <div className="card" onClick={this.handleClick}>
              {/*<img src={Tile2} className="promo2" alt="" />*/}
              {/*<div className="text">{title2}</div>*/}
            </div>
          </div>
          <div className="footer" onClick={this.goToPromotions}>
            {t("Details")}
          </div>
        </div>
      </div>
    );
  }
}

PromotionCards.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  t: PropTypes.func,
};

PromotionCards.defaultProps = {
  title1: "",
  title2: "",
};

export default withRouter(withTranslation()(withRouter(withResizeDetector(PromotionCards))));
