import React, { useEffect, useState, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as storeActions from "../../../../../store/actions";
import Icons from '../../../../../assets/Icons/Icons';
import Button from '../../../../UI/Button/Button';
import ProgressBar from '../../../../UI/ProgressBar/ProgressBar';
import './DocumentUpload.scss';

const DocumentUpload = (props) => {

    const [src, setSrc] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if(props.documentID && props.documentType && props.newDocument){

            const fileSizeMB = parseFloat(props.newDocument.size/1024/1024).toFixed(0);
            const maxFileSize = props.documents.documentsMaxFileSize.match(/(\d+)/);
            
            const reader = new FileReader();
            reader.addEventListener("load", () => setSrc(reader.result));
            reader.readAsDataURL(props.newDocument);

            if(parseInt(fileSizeMB) <= parseInt(maxFileSize[0])){
                props.onRequestDocumentsUpload(props.newDocument, props.documentType, props.documentID);
            }else{
                setError('Max file size allowed is ' + parseInt(maxFileSize[0]) + 'MB');
            }

        }
        // eslint-disable-next-line
    }, []);
    
    let progressContent = <ProgressBar className={'UploadProgress'} variant={"indeterminate"} />;

    let pageContent = '';

    if(!props.documents.loadingDocumentsUpload){

        if(Array.isArray(props.documents.documentsRequestUpload) && props.documents.documentsRequestUpload.length === 0){
            progressContent = (
                <div className={'SuccessMessage'}>
                    {Icons.get('pwSuccess', `ApprovedIcon`)}
                    <h4>{props.t('Upload Success')}</h4>
                </div>
            );
        }else{
            progressContent = (
                <div className={'ErrorMessage'}>
                    {Icons.get('exclamationFilled', `ExclamationIcon`)}
                    <h4>{props.t(error ? error : 'Upload Failed')}</h4>
                </div>
            );
        }

        pageContent = (
            <Fragment>
                <Button 
                    className={'active'} 
                    full
                    value={props.t('Finish')}
                    click={() => props.changePage('documents', props.documentType, props.documentID)}
                />
                <span onClick={() => props.changePage('selector', props.documentType, props.documentID)}>
                    {props.t('Upload another document')}
                </span>
            </Fragment>
        )
    }

    return(
        <div className={"DocumentUploadScreen"}>
            <img src={src} alt=""/>
            {progressContent}
            {pageContent}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        documents: state.documents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestDocumentsUpload: (file, docType, documentID) => dispatch(storeActions.requestDocumentsUpload(file, docType, documentID)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentUpload)));