import * as actionTypes from './../actions/actionTypes';

const initialState = {
    currentStep: 1,
    amount: 0,
    selectedLocation: false,
    locations: [],
    taxes: 0,
    opened: false,
    step: 1,
    errorCode: null,
    loading: false,
    skipLocation: false,
    isDeposit: false,
    isWithdraw: false,
    finished: false
};

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.withdraw_wizard.RECEIVED_TAXES:
            return {
                ...state,
                taxes: action.value,
            };
        case actionTypes.withdraw_wizard.SET_AMOUNT:
            return {
                ...state,
                amount: action.value,
            };
        case actionTypes.withdraw_wizard.SET_STEP:
            return {
                ...state,
                step: action.value
            };
        case actionTypes.withdraw_wizard.INIT_LOCATIONS:
            return {
                ...state,
                locations: action.value
            };
        case actionTypes.withdraw_wizard.SET_LOCATION:
            return {
                ...state,
                selectedLocation: action.value
            };
        case actionTypes.withdraw_wizard.OPEN_MODAL:
            return {
                ...state,
                opened: action.value
            };
        case actionTypes.withdraw_wizard.SKIP_LOCATION:
            return {
                ...state,
                skipLocation: action.value
            };
        case actionTypes.withdraw_wizard.IS_DEPOSIT:
            return {
                ...state,
                isDeposit: action.value
            };
        case actionTypes.withdraw_wizard.IS_WITHDRAW:
            return {
                ...state,
                isWithdraw: action.value,
                finished: false
            };
        case actionTypes.withdraw_wizard.SEND_DATA:
            return {
                ...state,
                errorCode: null,
                loading: true,
                finished: false,
            };
        case actionTypes.paymentCheckout.REQUEST_VIVA_WITHDRAW:
            return {
                ...state,
                finished: false
            }
        case actionTypes.withdraw_wizard.RECEIVED_WITHDRAW:
            if (Array.isArray(action.receivedData) && action.receivedData.length === 0) {
                return {
                    ...state,
                    loading: false,
                    errorCode: null,
                    finished: true
                };
            } else {
                if (action.receivedData.ResponseCode) {
                    return {
                        ...state,
                        loading: false,
                        errorCode: action.receivedData.ResponseCode,
                        finished: false
                    };
                } else {
                    return {
                        ...state,
                        loading: false,
                        errorCode: null,
                        finished: true
                    };
                }
            }

        default:
            return state;
    }
};

export default reducer;
