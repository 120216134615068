import * as actionTypes from './actionTypes';

export const requestWithdrawalLink = (amount) => {
    return {
        type: actionTypes.withdrawalCheckout.REQUEST_WITHDRAWAL_LINK,
        amount: amount,
    }
};

export const receivedWithdrawalLink = (data) => {
    return {
        type: actionTypes.withdrawalCheckout.RECEIVED_WITHDRAWAL_LINK,
        response: data,
    }
};