import React, { Component } from 'react';
import {connect} from "react-redux";
import RegisterHeader from "../Register/Steps/RegisterHeader";
import Steps from "../Register/Steps/Steps";
import StepWizard from "react-step-wizard";
import './Register.scss';
import ClaimBonus from "./Steps/ClaimBonus";
import RegisterBreadcrumb from "./Breadcrumb/RegisterBreadcrumb";
import Utils from "../../utils/Utils";
import RegisterV2 from "./RegisterV2/RegisterV2";
import {withTranslation} from "react-i18next";
import {productID} from "../../constants/Products";
import * as storeActions from "../../store/actions";
import MarketingEvents from "../../utils/MarketingEvents";

class Register extends Component {

    constructor(props) {
        super(props);
        MarketingEvents.signUpStarted()
    }

    componentDidMount() {
        const email = Utils.getQueryParam('emailYahoo');

        // autocomplete email from parameters
        if (email) {
            this.props.onChange({ field: 'email', value: email, validated: true })
        }
    }
    isOldRegister() {
        return Utils.getQueryParam('winner_fun') || this.props.productId === productID.WINNER_FUN || window.config.force_old_register === '1'
    }
    render() {
        if (this.isOldRegister()) {
            if (window.config && window.config.register_disabled === '1') {
                return (
                    <div className="registerWizard">
                        <RegisterHeader currentStep={this.props.currentStep}/>
                        <div className={"content register-disabled"}>
                            {this.props.t("Register is temporarily unavailable")}
                        </div>
                    </div>
                );
            }
            return (
                <div className="registerWizard">
                    <RegisterHeader currentStep={this.props.currentStep}/>
                    <div className="content">
                        <RegisterBreadcrumb/>
                        <div className="form" id={"register"}>
                            <StepWizard>
                                <Steps form="CreateNewAccount"/>
                                <Steps form="PersonalDetails"/>
                                <Steps form="Verification"/>
                                <ClaimBonus/>
                            </StepWizard>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    {/*<div className="development">DEV MODE ACTIVE!</div>*/}
                </div>
            );
        } else  {
            return (
                <RegisterV2/>
            )
        }
    }
}
const mapStateToProps = state => {

    return {
        productId: state.application.lastProductId
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered(fieldInput)),
    };
};

export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(Register));
