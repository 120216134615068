import * as actionTypes from './../actions/actionTypes';

const initialState = {
    complaintFormResponse: null,
    loadingComplaintForm: false,
    gameRulesLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.customer_support.SEND_COMPLAINT_FORM:
            return {
                ...state,
                loadingComplaintForm: true,
            };
        case actionTypes.customer_support.COMPLAINT_FORM_RESPONSE:
            let complaintFormResponse = false;
            if(Array.isArray(action.customerSupport) && action.customerSupport.length === 0){
                complaintFormResponse = true;
            }
            return {
                ...state,
                complaintFormResponse: complaintFormResponse,
                loadingComplaintForm: false,
            };
        case actionTypes.customer_support.CLEAN_COMPLAINT_FORM:
            return {
                ...state,
                complaintFormResponse: null,
                loadingComplaintForm: false,
            };
        case actionTypes.customer_support.REQUEST_GAME_RULES:
            return {
                ...state,
                gameRulesLoading: true,
            };
        case actionTypes.customer_support.RECEIVED_GAME_RULES:
            return {
                ...state,
                gameRules: action.items,
                groupedRules: action.groups,
                gameRulesLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;
