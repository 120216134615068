import React from "react";
//import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { getBetsState } from "../store/selectors/betData";

import BetSlip from "../components/BetSlip";

function BetSlipLayout({ classes, hhStatus, liveBets, prematchBets, selectError, errorMessage, winnerPlus, winnerPlusNeededEvents, hasMultiBets, isWinnerFun, betBuilder }) {

	let hasHHLive = false;
	let hasHHPreMatch = false;

	if (hhStatus) {
		if (typeof hhStatus === "object") {
			if (typeof hhStatus["bets-live"] !== "undefined" && hhStatus["bets-live"]) hasHHLive = true;
			if (typeof hhStatus["bets-prematch"] !== "undefined" && hhStatus["bets-prematch"]) hasHHPreMatch = true;
		}
	}

	const resHHStatus = {
		live: hasHHLive,
		prematch: hasHHPreMatch
	};

	return (
		<BetSlip
			liveBets={liveBets}
			prematchBets={prematchBets}
			selectError={selectError}
			errorMessage={errorMessage}
			winnerPlus={winnerPlus}
			winnerPlusNeededEvents={winnerPlusNeededEvents}
			hasMultiBets={hasMultiBets}
			hhStatus={resHHStatus}
			isWinnerFun={isWinnerFun}
			betBuilder={betBuilder}
		/>
	);
}

BetSlipLayout.propTypes = {};

BetSlipLayout.defaultProps = {};

const mapStateToProps = state => {
	const bst = getBetsState(state);
	const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

	return {
		liveBets: ct.live.selected,
		prematchBets: ct.prematch.selected,
		selectError: bst.betsSlip.selectError,
		errorMessage: ct.errorMessage,
		winnerPlus: ct.winnerPlus,
		winnerPlusNeededEvents: ct.winnerPlusNeededEvents,
		hasMultiBets: bst.betsSlip.multiTicket,
		hhStatus: state.happyHour.status,
		isWinnerFun: bst.app.isWinnerFun,
		betBuilder: state.bets.betBuilder,
	};
};

export default connect(mapStateToProps)(BetSlipLayout);
