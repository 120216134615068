import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
    inProgress: false,
    data: []
};



const druidReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.druid.SCAN_DOCUMENT: {
                draft.inProgress = true;
                break;
            }
            case actionTypes.druid.SCAN_COMPLETE: {
                draft.inProgress = false;
                draft.data = action.data;
                break;
            }

            default:
                break;
        }
    });

export default druidReducer;
