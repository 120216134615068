import i18next from "i18next";

import Utils from "./Utils";

export const FREEBET_LOTTO = 5;
export const FREEBET_SPORT = 9;
export const FREE_SPINS = 8;
export const BONUS_SCALAR = 1;
export const BONUS_PERCENTAGE = 2;

export const getSelectedBonusValue = (bonus, depositValue, retailSettings) => {
  if (!bonus) {
    return false;
  }

  let minValue = parseFloat(retailSettings.minValue);
  if (isNaN(minValue)) minValue = 0;
  let maxValue = parseFloat(retailSettings.maxValue);
  if (isNaN(maxValue)) maxValue = 0;
  let bonusPercentage = parseFloat(retailSettings.bonusPercentage);
  if (isNaN(bonusPercentage)) bonusPercentage = 0;

  let ta = depositValue && typeof depositValue === "string" ? parseFloat(depositValue) : depositValue;

  if (ta < retailSettings.minValue) {
    return 0;
  }

  if (ta > maxValue) {
    ta = maxValue;
  }

  let bonusAmount = ta * (bonusPercentage / 100);
  bonusAmount = Math.round(bonusAmount * 100) / 100;

  if (!bonusAmount) return bonusAmount;
  return Utils.formatAmount(bonusAmount, true, 'lei');

  /*
  const t = i18next.getFixedT(i18next.language);

  switch (bonus.bonus_available_type) {
    case FREEBET_SPORT:
    case FREEBET_LOTTO:
      if (typeof bonus.bonus_type_specific !== 'undefined' && typeof bonus.bonus_type_specific.tokenCount !== 'undefined' && typeof bonus.bonus_type_specific.tokenValue !== 'undefined') {
        bonusReceived = `${bonus.bonus_type_specific.tokenCount} x ${Utils.formatAmount(bonus.bonus_type_specific.tokenValue / 100, false, 'ron')}`;
      }
      break;
    case FREE_SPINS:
      if (typeof bonus.bonus_type_specific !== 'undefined' && typeof bonus.bonus_type_specific.fs_count !== 'undefined') {
        bonusReceived = `${bonus.bonus_type_specific.fs_count} ${t("Spins")}`;
      }
      break;
    case BONUS_SCALAR:
    case BONUS_PERCENTAGE:
      if (depositValue * (bonus.bonus_available_amount / 100) < bonus.bonus_available_max) {
        bonusReceived = Utils.formatAmount(depositValue * (bonus.bonus_available_amount / 100), true, "lei")
      } else {
        bonusReceived = Utils.formatAmount(bonus.bonus_available_max, true, 'lei')
      }

      break;
    default:
      break;
  }

  return bonusReceived;

  */
}

export default getSelectedBonusValue;