import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";
import Icons from '../../../../../../assets/Icons/Icons';
import TitleBar from '../../../../../UI/TitleBar/TitleBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import './WithdrawalCheckout.scss';

const WithdrawalCheckout = (props) => {

    const goToCustomerSupport = () => {
        props.history.push('/customer-support/about-us');
    }
    const goToWalletAndBonuses = () => {
        props.history.push('/profile/wallet');
    }
    const goToPendingWithdrawals = () => {
        props.history.push('/profile/pending-withdrawals');
    }
    const goToSlots = () => {
        props.history.push('/cazino/jocuri');
    }
    const goToHome = () => {
        props.history.push('/');
    }
    const backHandler = () => {
        props.history.push('/withdraw');
    }
    
    let pageContent = <CircularProgress className={'Loading'} />;

    switch (window.location.pathname) {
        case '/withdraw/success':
            let urlString = window.location.href;
            let url = new URL(urlString);
            let totalAmount = url.searchParams.get("totalAmount");
            let currency = url.searchParams.get("currency");
            pageContent = (
                <div className={"CheckoutContent"}>
                    <div className="Content Success">
                        <div className="icon-success">
                            {Icons.get('checkmarkFilled')}
                        </div>
                        <h6>{props.t('Withdrawal Successful!')}</h6>
                        <p>{props.t("Time to sit back, play and relax. Good luck!")}</p>
                        <Button fullWidth className={'text'} onClick={goToWalletAndBonuses}>
                            {props.t("Wallet & Bonus")}
                        </Button>
                        <Button fullWidth variant="contained" size="large" className={'withdrawalButton'} onClick={goToSlots}>
                            {props.t("Time to play")}
                        </Button>
                    </div>
                </div>
            );
            break;

        case '/withdraw/failure':
            pageContent = (
                <div className={"CheckoutContent"}>
                    <div className="Content Error">
                        <div className="icon-exclamation">
                            {Icons.get('exclamationFilled', `ExclamationIcon`)}
                        </div>
                        <h6>{props.t('Operation Failed')}</h6>
                        <div className="ModalContent">
                            <p>{props.t("An error has occured:")}</p>
                            <p>{props.t("(Error 202,1) This is an example of error description. The bank refused the transaction with this card. It may be expired or reported as stolen.")}</p>
                        </div>
                        <Button fullWidth className={'text'} onClick={goToCustomerSupport}>
                            {props.t("Customer Service")}
                        </Button>
                        <Button fullWidth variant="contained" size="large" className={'withdrawalButton'} onClick={goToWalletAndBonuses}>
                            {props.t("I got it")}
                        </Button>
                    </div>
                </div>
            );
            break;
            case '/withdraw/pending':
                pageContent = (
                    <div className={"CheckoutContent"}>
                        <div className="Content Pending">
                            <div className="icon-exclamation">
                                {Icons.get('exWarning', `ExclamationIcon`)}
                            </div>
                            <h6>{props.t('In Review')}</h6>
                            <div className="ModalContent">
                                <p>{props.t("For the safety of your account, this deposit requires review from our Risk & Payments department.")}</p>
                                <p><strong>{props.t("Your funds are safe. You will be notified when they will be added to your account.")}</strong></p>
                            </div>
                            <Button fullWidth className={'text'} onClick={goToPendingWithdrawals}>
                                {props.t("My pending funds")}
                            </Button>
                            <Button fullWidth variant="contained" size="large" className={'withdrawalButton'} onClick={goToHome}>
                                {props.t("Okay")}
                            </Button>
                        </div>
                    </div>
                );
                break;
            case '/withdraw/cancel':
                goToHome();
                break;
        default:
            pageContent = <CircularProgress className={'Loading'} />;
            break;
    }

    return (
        <div className={"WithdrawalCheckoutScreen"}>
            <TitleBar pageTitle={props.t('Checkout')} backButton={false} backHandler={backHandler} closeHandler={goToHome}/>
            <div className={"WithdrawalCheckoutContent"}>
                {pageContent}
            </div>
        </div>
    );
};

export default withTranslation()(withRouter(WithdrawalCheckout));