import { takeEvery, put } from "redux-saga/effects";
import * as actionTypes from "../../../store/actions/actionTypes";
import { loadedGames } from "../actions/games";
import axios from "axios";

const apiUrl = process.env.CFW.betsApiUrl + "/casinoapi/config/games";

function* loadGamesSagas() {
  try {
    const resp = yield axios.get(apiUrl + "/" + window.config.clientId + "?platformType=" + window.config.platformType);
    yield put(loadedGames(resp.data));
  } catch (err) {
    console.log("loadGamesSagas[ERR]:", err);
  }
}


export default function* watchLeaderBoardSaga() {
  yield takeEvery(actionTypes.application.INITIALIZE, loadGamesSagas);
}