import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Button from '../../../../UI/Button/Button';
import * as storeActions from "../../../../../store/actions/index";
import './CurrentPassword.scss';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Secured from '@material-ui/icons/LockOpen';
import FormHelperText from '@material-ui/core/FormHelperText';
import ResponseErrorMessages from "../../../../../store/errors/ResponseErrorMessages";
import CircularProgress from '@material-ui/core/CircularProgress';

class CurrentPassword extends Component {

    state = {
        password: '',
        showPassword: false,
        hasErrors: true,
        error: '',
    }

    handleChange = prop => event => {
        this.setState({
            ...this.state,
            [prop]: event.target.value,
        })
    };

    componentDidMount() {
        if (this.props.auth.auth_type !== "user") {
            this.props.history.push("/");
        }
        if (this.props.error) {
            this.setState({
                ...this.state,
                hasErrors: true,
                error: ResponseErrorMessages.get(this.props.error),
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.profile !== prevProps.profile) {
            if (!this.props.profile.loadingCheckPassword && this.props.profile.passwordIsValid !== null) {
                this.handleNextScreen(this.props.profile.passwordIsValid);
            }
        }
        if (this.state.password !== prevState.password) {
            this.handleLength();
        }
    }

    handleClickShowPassword = () => {
        this.setState({
            ...this.state,
            showPassword: !this.state.showPassword,
        })
    };

    handleNextScreen = (passwordIsValid) => {
        if (this.state.password.length > 3 && passwordIsValid) {
            this.setState({
                ...this.state,
                hasErrors: false,
                error: '',
            });
            this.props.onClearPasswordCache();
            this.props.next(true, this.state.password);
        } else {
            this.setState({
                ...this.state,
                hasErrors: true,
                error: this.props.t("Wrong password, try again!"),
            });
        }
    }

    handleConfirmAction = () => {
        this.props.onCheckPassword(this.state.password);
    };

    handleLength = () => {
        if (this.state.password.length < 3) {
            this.setState({
                ...this.state,
                hasErrors: true,
                error: '',
            });
        } else {
            this.setState({
                ...this.state,
                hasErrors: false,
                error: '',
            });
        }
    }


    render() {

        return (
            <div className={"CurrentPasswordScreen"}>
                <FormControl className="PasswordInput">
                    <Secured className={"inputLeftIcon " + (this.state.hasErrors && this.state.error !== '' ? 'ErrorField' : '')} />
                    <InputLabel htmlFor="CurrentPasswordField" error={this.state.hasErrors && this.state.error !== ''}>{this.props.t('Current Password')}</InputLabel>
                    <Input
                        id="CurrentPasswordField"
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        error={this.state.hasErrors && this.state.error !== ''}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPassword ? <Visibility className={'VisibilityIcon'} /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText error={this.state.hasErrors && this.state.error !== ''}>{this.state.error}</FormHelperText>
                </FormControl>
                <Button
                    className={'active'}
                    disabled={this.state.hasErrors || this.props.profile.loadingCheckPassword}
                    full click={this.handleConfirmAction}
                    value={this.props.profile.loadingCheckPassword ? <CircularProgress className={'Loading'} /> : this.props.t('Next')}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
        auth: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckPassword: (password) => dispatch(storeActions.checkPassword(password)),
        onClearPasswordCache: () => dispatch(storeActions.clearPasswordCache()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrentPassword)));
