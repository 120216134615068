import * as actionTypes from './../actions/actionTypes';

const initialState = {
    documentsRequest: null,
    documentsRequestUpload: null,
    documentsMaxFileSize: null,
    loadingDocuments: false,
    loadingDocumentsUpload: false,
    loadingDocumentsMaxFileSize: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.documents.REQUEST_DOCUMENTS:
            return {
                ...state,
                loadingDocuments: true,
                documentsRequest: null,
                documentsRequestUpload: null,
            };
        case actionTypes.documents.RECEIVED_DOCUMENTS:
            return {
                ...state,
                documentsRequest: action.documents,
                loadingDocuments: false,
            };
        case actionTypes.documents.REQUEST_DOCUMENTS_UPLOAD:
            return {
                ...state,
                loadingDocumentsUpload: true,
            };
        case actionTypes.documents.RECEIVED_DOCUMENTS_UPLOAD:
            return {
                ...state,
                documentsRequestUpload: action.documentsUpload,
                loadingDocumentsUpload: false,
            };
        case actionTypes.documents.REQUEST_DOCUMENTS_MAX_FILE_SIZE:
            return {
                ...state,
                loadingDocumentsMaxFileSize: true,
            };
        case actionTypes.documents.RECEIVED_DOCUMENTS_MAX_FILE_SIZE:
            let maxFileSize = '12Mb';
            
            if (action.documentsMaxFileSize.hasOwnProperty('maxSize')) {
                maxFileSize = action.documentsMaxFileSize['maxSize'];
            }
    
            return {
                ...state,
                documentsMaxFileSize: maxFileSize,
                loadingDocumentsMaxFileSize: false,
            };
        default:
            return state;
    }
};

export default reducer;
