import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import ArrowUp from "./assets/arrowUp.svg";

import { betsSlipClear, betsSlipComputeWin } from "../../store/actions/betsSlip";
import { getBetsState } from "../../store/selectors/betData";

import { formatCurrency, formatAmount2, formatTotalOddsValue } from "../../utils/formatters";

const useStyles = makeStyles({
  root: {
    fontFamily: "OpenSans, 'Open Sans'",
    padding: "0 10px 5px 20px"
  },
  title: {
    color: "#586682",
    fontSize: "10px",
    fontWeight: "600"
  },
  amount: {
    color: "#162A57",
    fontSize: "14px",
    fontWeight: "bold"
  },
  oTov: {
    color: "#01ae3b",
  },
  currency: {
    color: "#162A57",
    fontSize: "10px"
  },
  arrow: {
    height: "24px",
    width: "24px",
    zIndex: 1,
    backgroundColor: "#EEF0F1",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      width: "15px",
      height: "18px"
    }
  },
  maxWidthZero: {
    maxWidth: 0
  }
});

const BetMini = props => {
  const classes = useStyles();
  const { amount, totalOdds, winAmount, onToggle, wallet, isWinnerFun,
    dayMultiBetNumber, dayMultiBets, currentTicket, t } = props;

  let overrideTotalOdds = null;
  let overrideWinning = null;
  if (!isWinnerFun && dayMultiBetNumber > 0 && dayMultiBets && dayMultiBets.length && currentTicket.ticketType === "single") {
    const dayMultiBet = dayMultiBets.find(dmb => dmb.number === dayMultiBetNumber);
    if (dayMultiBet && dayMultiBet.express_factor) {
      overrideTotalOdds = totalOdds * dayMultiBet.express_factor;
      overrideWinning = winAmount * dayMultiBet.express_factor;
    }
  }

  return (
    <div className={classes.root}>
      <div
        className="d-flex flex-row flex-nowrap align-items-center justify-content-between"
        onClick={onToggle}
      >
        <div className={classes.maxWidthZero}>
          <div className={classes.arrow}>
            <img src={ArrowUp} alt="" />
          </div>
        </div>
        <div>
          <div className={classes.title}>{t("Total Odds")}</div>
          <div className={classes.blockHeight}>
            <span className={`${classes.amount} ${overrideTotalOdds ? classes.oTov : ''}`}>
              {overrideTotalOdds ? formatTotalOddsValue(overrideTotalOdds) : formatTotalOddsValue(totalOdds)}
            </span>
          </div>
        </div>
        <div>
          <div className={classes.title}>{t("Stake")}</div>
          <div className={classes.blockHeight}>
            <span className={classes.amount}>{formatAmount2(amount)}</span>{" "}
            <span className={classes.currency}>{isWinnerFun ? "W" : formatCurrency(wallet.currency)}</span>
          </div>
        </div>
        <div>
          <div className={classes.title}>{t("Winning")}</div>
          <div className={classes.blockHeight}>
            <span className={classes.amount}>{
              overrideWinning ? formatAmount2(overrideWinning) :
                formatAmount2(winAmount)
            }</span>{" "}
            <span className={classes.currency}>{isWinnerFun ? "W" : formatCurrency(wallet.currency)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const makeMapStateToProps = state => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    wallet: state.wallet,
    //stake: ct.stake,
    amount: ct.amount,
    totalOdds: ct.totalOdds,
    winAmount: ct.totalMaxWinAmount,
    isWinnerFun: bst.app.isWinnerFun,

    dayMultiBetNumber: ct.dayMultiBetNumber,
    dayMultiBets: bst.config.dayMultiBets,
    currentTicket: ct,
  };
};

const actionCreators = {
  clearSlip: betsSlipClear,
  computeWin: betsSlipComputeWin
};

export default withTranslation()(connect(makeMapStateToProps, actionCreators)(BetMini));
