import React, { useEffect, useState, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from '../../../../../../store/actions/index';
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import './ConfirmPassword.scss';

const ConfirmPassword = props => {

    const [popupContent, setPopupContent] = useState({
        error: false,
        errorMessage: '',
        firstChange: false,
    });

    const [currentPassword, setCurrentPassword] = useState('');

    useEffect(() => {
        handleError();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPassword]);

    useEffect(() => {
        if(props.profile.passwordIsValid === true){
            props.close(true);
        }
        if(props.profile.passwordIsValid === false){
            setPopupContent({
                ...popupContent,
                error: true,
                errorMessage: props.t('Wrong password, try again!'),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.profile.loadingCheckPassword]);

    const handleChange = event => {
        setCurrentPassword(event.target.value);
        if(!popupContent.firstChange){
            setPopupContent({
                ...popupContent,
                firstChange: true,
            });
        }
    };

    const handleError = () => {
        if(currentPassword.length < 3){
            setPopupContent({
                ...popupContent,
                error: true,
            });
        }else{
            setPopupContent({
                ...popupContent,
                error: false,
                errorMessage: '',
            });
        }
    }

    const handleConfirmAction = () => {
        setPopupContent({
            ...popupContent,
            loadingCheck: true,
        });

        props.onCheckPassword(currentPassword);
    };

    let pageContent = <Fragment />;

    if(!props.profile.loading){
        pageContent = (
            <Fragment>
                <DialogTitle id="form-dialog-title">{props.t('Confirm exclusion')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>{props.t('To complete your action, please confirm your password:')}</span>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        className={'PartialField'}
                        error={popupContent.error && popupContent.errorMessage !== '' && popupContent.firstChange}
                        helperText={popupContent.error && popupContent.errorMessage !== '' && popupContent.firstChange ? popupContent.errorMessage : ''}
                        onChange={handleChange}
                        type="password"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => props.close(false)} 
                        color="secondary"
                        >
                            {props.t('Cancel')}
                        </Button>
                    <Button 
                        onClick={() => handleConfirmAction()} 
                        disabled={popupContent.error || !popupContent.firstChange || props.profile.loadingCheckPassword} 
                        color="primary"
                        >
                            {props.profile.loadingCheckPassword ? <CircularProgress className={'Loading'} /> : props.t('Confirm')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    return (
        <div>
            <Dialog className={'ConfirmPassword'} open={props.open} onClose={() => props.close(false)} aria-labelledby="form-dialog-title">
                {pageContent}
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckPassword: (password) => dispatch(storeActions.checkPassword(password)),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword)));