import React, { Component, Fragment } from 'react';
import i18next from 'i18next';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import TestIcon from '@material-ui/icons/VerifiedUser';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LogInIcon from '@material-ui/icons/PermIdentity';
import LogOutIcon from '@material-ui/icons/PowerSettingsNew';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import "./LeftMenuItem.scss";
import * as storeActions from "../../../store/actions";
import SlideToggleContent from './../../SlideToggleContent/SlideToggleContent';
import Icons from './../../../assets/Icons/Icons';
import Utils from "../../../utils/Utils";

class LeftMenuItem extends Component {
    constructor(props) {
        super(props);

        this.urlPath = `${window.config.front_url}`;

        this.pageObject = null;
        this.parentObject = null;

        this.urlString = null;

        this.widgetType = null;

        this.forcedIcon = null;

        this.makeSettings();
        this.makeUrl();
    }

    state = {
        subpages: null,
        openSubmenu: false,
        selfClicked: false,
    }

    componentDidMount() {
        let subpages = null;
        let openSubmenus = false;
        // this.props.isRequestGameCollections();

        if (this.props.subpages && this.props.subpages.length > 0 && this.pageObject.submenus.length > 0) {
            subpages = [];
            this.props.subpages.forEach(({ code, text }) => {
                let pageItem = this.pageObject.submenus.find((item) => {
                    return item.code === code;
                });

                if (!pageItem) {
                    return;
                }

                let itemUrl = `/${this.pageObject.code}/${code}`;
                subpages.push({
                    url: itemUrl,
                    text: text,
                    ...pageItem,
                });
            });
        }

        this.setState({
            ...this.state,
            subpages: subpages,
            openSubmenu: openSubmenus,
        });
    }

    makeSettings() {
        if (this.props.widgetType) {
            this.widgetType = this.props.widgetType;
        }

        if (this.props.forceIcon) {
            this.forcedIcon = this.props.forceIcon;
        }

        let pType = this.props.pageType;
        let pCode = this.props.pageCode;

        if (pType === 'spribe-aviator') {
            this.pageObject = {
                code: pCode,
                url: '/' + pCode,
                name: '',
                icon: 'aviator-sign'
            };
            return;
        }

        if (pType === 'lotto') {
            this.pageObject = {
                code: 'lotto/home',
                url: '/lotto/home',
                name: 'lotto',
                icon: 'lotto',
            };
            return;
        }
        if (pType === 'winner-fun') {
            this.pageObject = {
                code: 'winner-fun',
                url: '/winner-fun',
                name: 'Winner FUN',
                icon: 'walletWinnerFunMenu',
            };
            return;
        }
        if (pType === 'black-friday') {
            this.pageObject = {
                code: 'casino/promotii',
                url: '/casino/promotii',
                name: 'Black Friday',
                icon: 'black-friday',
            };
            return;
        }
        if (pType === 'winter-time') {
            this.pageObject = {
                code: 'winter-time',
                url: '/winter-time',
                name: 'X-Mas Tree Promo',
                icon: 'winter-time',
            };
            return;
        }
        if (pCode === 'deposit-oktocash') {
            this.pageObject = {
                code: pCode,
                url: null,
                name: this.props.t('Cash Deposit'),
                icon: 'menuHotSymbol',
            };
            return;
        }

        if (pType === 'root-level') {
            try {
                if (!this.props.menu.items.hasOwnProperty(pCode)) {

                    throw new Error(`[MENU ITEM] ERROR: Element not found ${pCode}`);
                }
            } catch (e) {
                return Utils.delayedFunction("menu-load" + this.props.pageCode, this.makeSettings.bind(this), 500);
            }


            this.pageObject = this.props.menu.items[pCode];
            return;
        }

        if (pType === 'reset-password') {
            // ??? not now
            throw new Error(`[MENU ITEM] ERROR: ${pType} is not implemented!`);
        }

        if (pType === 'connect') {
            this.pageObject = {
                code: 'connect',
                url: '/connect',
                name: 'log in',
            };
            return;
        }

        if (pType === 'withdraw') {
            this.pageObject = {
                'code': 'withdraw',
                'url': '/profile/withdraw',
                'name': 'withdraw'
            }
        }

        if (pType === 'test') {
            this.pageObject = {
                code: 'test',
                url: '/test',
                name: 'test',
            };
            return;
        }
        if (pType === 'cazino') {

            if (window.config.lobbyEnabled === "1") {
                if (!this.props.menu.items.hasOwnProperty(pType)) {
                    pType = 'lobby';
                    if (window.config.rootPaths && window.config.rootPaths.casino) {
                        pType = window.config.rootPaths.casino;
                    }
                }
            }
        }
        try {
            if (!this.props.menu.items.hasOwnProperty(pType)) {
                throw new Error(`[MENU ITEM] ERROR: Element not found ${pCode}`);
            }
        } catch (e) {
            return Utils.delayedFunction("menu-load" + this.props.pageCode, this.makeSettings.bind(this), 500);
        }

        this.parentObject = this.props.menu.items[pType];
        if (!this.parentObject.hasOwnProperty('submenus')) {
            this.pageObject = this.parentObject;
            this.parentObject = null;
            return;
        }

        let subPage = this.parentObject.submenus.find(item => item.code === pCode);
        if (!subPage) {
            this.pageObject = this.parentObject;
            this.parentObject = null;
            return;
        }

        this.pageObject = subPage;
    }

    makeUrl() {
        if (this.pageObject === null) {
            return Utils.delayedFunction('make-url' + this.props.pageCode, this.makeUrl.bind(this));
        }
        let url = '';
        if (this.parentObject) {
            url += `/${this.parentObject.code}`;
        }

        if (this.props.anchor) {
            url += `/${this.pageObject.code}#${this.props.anchor}`;
        } else {
            url += `/${this.pageObject.code}`;
        }

        if (this.pageObject.code === 'jackpot') {
            if (window.config.lobbyEnabled === "1") {
                let defPath = 'lobby';
                if (window.config.rootPaths && window.config.rootPaths.casino) {
                    defPath = window.config.rootPaths.casino;
                }

                url = `/${defPath}/slots/egt-jackpot-cards`;
            } else {
                if (!this.props.games.loadingGameCollections && this.props.games.gameCollections && this.props.games.gameCollections.length > 0) {
                    let collections = this.props.games.gameCollections.filter(Boolean);
                    for (let i = 0; i < collections.length; i++) {
                        if (collections[i].jackpot_id) {
                            url = `/cazino/collection/` + collections[i].id;
                            break;
                        }
                    }
                }
            }
        }

        this.urlString = url;
    }

    clickHandler(event) {
        // if (this.props.widgetType === 'aviator') {
        //     window.config.spribe_promo_side_menu
        // };
        if (this.props.type === 'black-friday') {
            this.props.closeLeftMenu();
            this.props.history.push('/casino/promotii');
            return;
        }
        if ("auth" === this.widgetType) {
            if ("user" === this.props.authentication.auth_type) {
                this.props.logout();
                this.props.closeLeftMenu();
                this.props.history.push("/");
                return;
            }
        }
        if ("excelbutton" === this.widgetType) {
            return window.location.href = 'https://www.excelbet.ro/home';
        }

        if (this.pageObject.code === 'terms&conditions') {
            let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
            let win = window.open(url, '_blank');
            if (win !== null) {
                win.focus();
            }
            this.props.closeLeftMenu();
            return;
        }
        if (this.props.pageCode === 'bonus-rules') {
            let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/bonusRules/Regulament%20General%20pentru%20Bonusuri.pdf';
            let win = window.open(url, '_blank');
            if (win !== null) {
                win.focus();
            }
            this.props.closeLeftMenu();
            return;
        }



        if (this.state.subpages && this.state.subpages.length > 0) {
            if (!this.state.openSubmenu) {
                this.setState({
                    ...this.state,
                    openSubmenu: true,
                    selfClicked: true,
                });
                return;
            }

            this.setState({
                ...this.state,
                openSubmenu: false,
                selfClicked: false,
            });
            return;
        }

        this.props.closeLeftMenu();

        if (this.urlString === '/cazino_live') {
            this.props.history.push('/casino/livecasino');
            return;
        }

        this.props.history.push(this.urlString);
    }

    subpageHandler(url, event) {
        this.props.closeLeftMenu();
        this.props.history.push(url);
    }

    createSubPages(force = false) {
        if (this.pageObject === null) {
            return null;
        }
        if (!this.state.subpages) {
            return null;
        }

        let subpages = this.state.subpages;
        if (!(subpages.length > 0)) {
            return null;
        }

        const items = [];
        subpages.forEach((item) => {
            let linkItem = (
                <ListItem button key={`sml_${item.code}`} onClick={this.subpageHandler.bind(this, item.url)}>
                    <ListItemText inset primary={item.text} />
                </ListItem>
            );
            items.push(linkItem);
        });

        let open = this.state.openSubmenu;
        if (force) {
            open = true;
        }

        return (
            <SlideToggleContent isVisible={open} childrenClassName={"leftSubItems"} forceSlideIn={false}>
                {items}
            </SlideToggleContent>
        );
    }

    verifyDocuments() {
        let documentIcon = <Fragment />;
        if (this.pageObject === null) {
            return documentIcon;
        }
        let documentsOk = true;
        if (this.props.documents) {
            if (this.props.documents.documentsRequest) {
                let documents = this.props.documents.documentsRequest;
                for (var i = 0; i < documents.length; i++) {
                    if (documents[i].status !== 8) {
                        documentsOk = false;
                        break;
                    }
                }
            }
        }
        if (!documentsOk) {
            if (this.pageObject.icon) {
                if (this.pageObject.icon.length > 2) {
                    if ('account' === this.pageObject.icon) {
                        documentIcon = Icons.get('exclamationPoint', 'ExclamationIcon');
                    }
                }
            }
        }
        return documentIcon;
    }

    getIcon() {
        let icon = <Fragment />;
        if (this.props.noIcon) {
            return icon;
        }
        if ('excelbutton' === this.widgetType) {
            return Icons.get('pinMapExcel');
        }
        if ("auth" === this.widgetType) {
            icon = <LogInIcon />;
            if ("user" === this.props.authentication.auth_type) {
                icon = <LogOutIcon />;
            }
            return icon;
        }

        if ('aviator' === this.widgetType) {
            return Icons.get('aviator-sign');
        }

        if (this.forcedIcon) {
            icon = Icons.get(this.forcedIcon, `${this.forcedIcon} ${this.pageObject.icon}`);
            return icon;
        }

        icon = <TestIcon />;
        if (this.pageObject.icon.length > 2 && this.pageObject.icon !== "pinMapNew") {
            icon = Icons.get(this.pageObject.icon, this.pageObject.icon);
        }

        return icon;
    }

    render() {
        if (this.pageObject === null && this.widgetType !== 'excelbutton') return (<Fragment />);

        let arrowIcon = null;
        if (!this.props.application.pageType) {
            return null;
        }

        if (!this.props.application.pageCode) {
            return null;
        }

        let forceOpen = false;
        let itemClassName = ["leftMenuButton"];
        if (this.widgetType !== 'excelbutton' && [this.props.application.pageType, this.props.application.pageCode].includes(this.pageObject.code)) {
            if (!("jackpot" === this.props.application.pageCode && "cazino" === this.pageObject.code)) {
                forceOpen = true;
                itemClassName.push("itemSelected");
                arrowIcon = <ListItemIcon className={"arrowIconSupport"}><ArrowRight /></ListItemIcon>
            }
        }
        if (this.state.selfClicked) {
            forceOpen = true;
            itemClassName.push("itemClicked");
            arrowIcon = <ListItemIcon className={"arrowIconSupport"}><ArrowRight /></ListItemIcon>
        }

        if ("rightMenuMain" === this.widgetType) {
            arrowIcon = <ListItemIcon className={"arrowIconSupport"}><ArrowRight /></ListItemIcon>
        }

        if ("withExclamationPoint" === this.props.widgetType) {
            let iconExclamation = Icons.get('exclamationPoint', 'ExclamationIcon');
            arrowIcon = (<Fragment>
                <ListItemIcon className={"exclamationIconsSupport"}>{iconExclamation}</ListItemIcon>
                <ListItemIcon className={"arrowIconSupport"}><ArrowRight /></ListItemIcon>
            </Fragment>);
        }

        if (this.props.alertNumber > 0) {
            arrowIcon = (<Fragment>
                <ListItemIcon className={"AlertNumber"}><span>{this.props.alertNumber}</span></ListItemIcon>
                <ListItemIcon className={"arrowIconSupport"}><ArrowRight /></ListItemIcon>
            </Fragment>);
        }

        let buttonText = this.widgetType !== 'excelbutton' ? this.props.t(this.pageObject.name) : "unknown";
        buttonText = `${buttonText.charAt(0).toUpperCase()}${buttonText.slice(1)}`;
        if (this.props.buttonText && this.props.buttonText.length > 1) {
            buttonText = this.props.buttonText;
        }

        // if property widgetType -> add a cssClass with that string
        if (this.widgetType) {
            itemClassName.push(`widget-${this.widgetType}`);
            // if it is a authorisation widget --
            if ("auth" === this.widgetType) {
                buttonText = this.props.t("Log In");
                if ("user" === this.props.authentication.auth_type) {
                    buttonText = this.props.t("Log Out");
                }
            }
            if ('aviator' === this.widgetType) {
                buttonText = Icons.get('aviator-text');
            }
        }

        let customTextClass = ["leftMenuButtonName"];
        if (this.props.customTextClass) {
            let textClasses = this.props.customTextClass.split(" ");
            if (textClasses.length > 0) {
                textClasses.forEach((tClass) => {
                    customTextClass.push(tClass);
                });
            }
        }

        let supportClass = this.props.className;

        let key = this.widgetType !== 'excelbutton' ? `left_menu_${this.props.application.pageType}_${this.props.application.pageCode}` : 'left_menu_excelbutton';

        return (
            <div className={supportClass}>
                <ListItem className={itemClassName.join(" ")} button key={key} onClick={this.clickHandler.bind(this)}>
                    <ListItemIcon className={"menuButtonIcon"}>
                        {this.getIcon()}
                    </ListItemIcon>
                    <ListItemIcon className="DocumentIcon">
                        {this.verifyDocuments()}
                    </ListItemIcon>
                    <ListItemText className={customTextClass.join(" ")}>
                        {buttonText}
                    </ListItemText>
                    {arrowIcon}
                </ListItem>
                {this.createSubPages(forceOpen)}
            </div>
        );
    }
}

LeftMenuItem.propTypes = {
    pageType: function (props, propName, componentName) {
        let allowedPageTypes = props.menu.allowedPageTypes;
        if (!allowedPageTypes.includes(props[propName])) {
            if (props[propName] === "lotto") return;
            if (props[propName] === "winner-fun") return;
            //console.log(props[propName], propName, componentName);
            return new Error(`Invalid pageType property! Please use one of the following: '${allowedPageTypes.join("', '")}'`);
        }
    },
    pageCode: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    subpages: PropTypes.array,
    noIcon: PropTypes.bool,
    customTextClass: PropTypes.string,
    widgetType: PropTypes.string,
    forceIcon: PropTypes.string,
    className: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        menu: state.menu,
        application: state.application,
        authentication: state.authentication,
        games: state.games,
        documents: state.documents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeLeftMenu: () => {
            dispatch(storeActions.closeLeftMenu());
            dispatch(storeActions.closeRightMenu());
        },
        setPageType: (pType) => dispatch(storeActions.setPageType(pType)),
        setPageCode: (pCode) => dispatch(storeActions.setPageCode(pCode)),
        // isRequestGameCollections: () => dispatch(storeActions.requestGameCollections()),
        logout: () => dispatch(storeActions.logout()),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftMenuItem)));
