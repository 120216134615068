import axios from 'axios';
import * as storeActions from './../store/actions';

// account problem response message 21, 22 and 23
const ACCOUNT_SUSPENDED = 21;
const ACCOUNT_CLOSED = 22;
const ACCOUNT_SELF_EXCLUDED = 23;

class ClientAPI {
    constructor() {
        this.initialized = false;

        this.instance = null;
        this.store = null;
        this.appBaseUrl = window.config.front_url;
    }

    setStore(store) {
        this.store = store;

        this.init();
    }

    init() {
        if (!this.store) {
            return;
        }

        if (this.initialized) {
            return;
        }

        this.initialized = true;

        let self = this;
        this.instance = axios.create({
            baseURL: self.appBaseUrl
        });

        this.instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        this.instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        this.instance.defaults.headers.common['Platform-Type'] = window.config.platformType;

        this.activateRequestInterceptors();

        this.activateResponseInterceptors();
    }

    activateRequestInterceptors() {
        if (!this.instance) {
            return;
        }

        // let self = this;
        this.instance.interceptors.request.use(request => {
            if (!request.hasOwnProperty("data")) {
                request.data = {};
            }

            if (!request.data.hasOwnProperty("platformType")) {
                request.data.platformType = window.config.platformType;
            }


            return request;
        }, error => {
            console.error(`[ERROR] Server request error => ${error}`);
            console.error(`[NETWORK] Error, network disconnected!`);

            return Promise.reject(error);
        });
    }

    activateResponseInterceptors() {
        if (!this.instance) {
            return;
        }

        let self = this;
        this.instance.interceptors.response.use(response => {



            // check if responseType is blob
            if (response.hasOwnProperty('config') && response.config.hasOwnProperty('responseType') && "blob" === response.config.responseType) {
                if ("text/csv" === response.data.type) {
                    if (!(response.data.size > 3)) {
                        alert('empty');
                        return;
                    }
                    return self.store.dispatch(storeActions.downloadHistoryCSV(response));
                }
            }

            if (!response.hasOwnProperty('data')) {
                return;
            }
            let responseData = response.data;
            if (responseData.hasOwnProperty('coins')) {
                return responseData;
            }
            if (responseData.hasOwnProperty('rows')) {

                if (responseData.hasOwnProperty('success')) {
                    if (responseData.success) {
                        return responseData;
                    }
                }
            }

            if (!responseData.hasOwnProperty("type")) {
                console.info('Response object has no TYPE!!!');
                // return self.executeGeneralError();
                return;
            }

            if (responseData.hasOwnProperty('result')) {
                if (responseData.result.hasOwnProperty('ResponseCode') && [ACCOUNT_SUSPENDED, ACCOUNT_CLOSED, ACCOUNT_SELF_EXCLUDED].includes(responseData.result.ResponseCode)) {
                    let responseMessage = null;
                    if (responseData.result.hasOwnProperty('ResponseMessage')) {
                        responseMessage = responseData.result.ResponseMessage;
                    }
                    // this.store.dispatch(storeActions.logout());
                    this.store.dispatch(storeActions.openAccountProblemModal(responseData.result.ResponseCode, responseMessage));
                    // console.log(responseMessage);
                }
            }

            switch (responseData['type']) {
                case "menu":
                case "authorization":
                case "sms-code":
                case "refresh_token":
                case "wallet-preview":
                case "phone-validation":
                case "mail-validation":
                case "cnp-validation":
                case "profile":
                case "responsible-gaming":
                case "responsible-gaming-set-limit":
                case "set-limit":
                case "transactions-history":
                case "password":
                case "phone-number-validation":
                case "sms-code-validation":
                case "pending-withdrawals":
                case "pending-viva-withdrawals":
                case "locations":
                case "tax":
                case "cancel-withdraw":
                case "cancel-viva-withdraw":
                case "documents-preview":
                case "documents-uploaded":
                case "max-upload-size":
                case "games":
                case "game":
                case "post-complaint":
                case "game-rules":
                case "jackpot":
                case "jackpots":
                case "latest-winners-slots":
                case "latest-winners-jackpot":
                case "signup":
                case "get-chat-info":
                case "withdraw-request":
                case "reset_password_email_sent":
                case "reset-password-with-token":
                case "verify-phone":
                case "forfeit":
                case "token-email-validation":
                case "pay-checkout":
                case "withdraw-safecharge":
                case "resent_email_verification":
                case "promotions":
                case "eligible-bonuses":
                case "bonuses":
                case "game-collections":
                case "withdraw-code":
                case "free-bet-get-list":
                case "free-bet-get-history":
                case "free-bet-dump-history":
                case "viva-auth":
                case "viva-charge-token":
                case "viva-card-token":
                case "viva-withdraw":
                case "preeuro":
                case "redeem-code":
                case "bonus-available":
                case "claim-bonus":
                case "free-spin-get-list":
                case "free-spin-get-history":
                case "free-spin-dump-history":
                case "scan-document":
                case "marketing-offer":
                case "get-partial-account":
                case "set-partial-account":
                case "self-exclude":
                case "okto-create-payment-code":
                case "okto-get-customer":
                case "withdraw-payment-methods":
                case "withdraw-init":
                case "withdraw-history":
                case "withdraw-cancel":
                case "link-iban":
                case "bonus-calculator":
                    return responseData;
                default:
                    // call something when type is not recognized
                    break;
            }
        }, error => {
            const { response } = error;

            if (response && response.status === 401 && response.data && response.data.type === 'wallet-preview') {
                localStorage.removeItem('access_token');
                localStorage.removeItem('token_type');
                localStorage.removeItem('auth_type');
                localStorage.removeItem('token_type');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('auth_date');

                this.store.dispatch(storeActions.startAppAuth());
                return;
            }

            if (typeof response !== "undefined" && typeof response.data !== "undefined") {
                switch (response.data.type) {
                    case 'get-chat-info':
                        if (typeof response.data.result !== 'undefined') {
                            return response.data;
                        }
                        break;
                    case 'scan-document':
                    case 'marketing-offer':
                    case 'signup':
                    case 'okto-create-payment-code':
                    case "okto-get-customer":
                    case "withdraw-init":
                        return response.data;
                        break;
                    case 'game':

                        break;
                    default:
                        break;
                }
            } else {
                console.log(`[ERROR] Response status is ${response.status}`);

                console.log(response);
                return Promise.reject(error);
            }
        });
    }

    getInstance() {
        return this.instance;
    }

    getStore() {
        return this.store;
    }
}

export default new ClientAPI();
