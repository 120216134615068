import React, { useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from '../../../../store/actions/index';
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import './ConfirmRemove.scss';
import {forfeitMomentumBonus} from "../../../../momentum/store/actions/momentum";

const ConfirmRemove = props => {

    const handleConfirmAction = () => {
        if (props.isFreeBet) {
            props.onRemoveFreeBet(props.bonusID);

        } else if (props.isAfterWager) {
            props.onRemoveMomentumBonus(props.bonusID);
        } else {
            props.onRemoveBonus(props.bonusID);
        }
        props.close(true);
    };

    useEffect(() => {
        if (props.wallet.bonusRemoved === true) {
            props.onResetWallet();
            props.close(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.wallet.loadingRemoveBonus]);

    let pageContent = <Fragment />;

    if (!props.wallet.loadingWallet) {
        pageContent = (
            <Fragment>
                <DialogTitle id="form-dialog-title">{props.t('Remove bonus')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>{props.t('Are you sure you want to remove your bonus?')}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.close(false)} color="secondary">
                        {props.t('Cancel')}
                    </Button>
                    <Button onClick={() => handleConfirmAction()} color="primary">
                        {props.wallet.loadingRemoveBonus ? <CircularProgress className={'Loading'} /> : props.t('Confirm')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }

    return (
        <div>
            <Dialog className={'ConfirmRemove'} open={props.open} onClose={() => props.close(false)} aria-labelledby="form-dialog-title">
                {pageContent}
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        wallet: state.wallet,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRemoveBonus: (bonusID) => dispatch(storeActions.requestRemoveBonus(bonusID)),
        onRemoveFreeBet: (bonusID) => dispatch(storeActions.freeBetRemove(bonusID)),
        onRemoveMomentumBonus: (bonusID) => dispatch(forfeitMomentumBonus(bonusID)),
        onResetWallet: () => dispatch(storeActions.resetWallet()),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmRemove)));