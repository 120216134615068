import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";


import { getBetsState } from "../../store/selectors/betData";

import Bilete from "./assets/bilete.png";

import { betsSlipClear, betsSlipComputeWin } from "../../store/actions/betsSlip";
import { formatCurrency, formatAmount2, formatTotalOddsValue } from "../../utils/formatters";

const useStyles = makeStyles({
  root: {
    fontFamily: "OpenSans, 'Open Sans'",
    padding: "0 30px 15px 10px"
  },
  block: {
    width: "25px",
    height: "22px"
  },
  title: {
    color: "#586682",
    fontSize: "11px",
    fontWeight: "600"
  },
  amount: {
    color: "#162A57",
    fontSize: "15px",
    fontWeight: "bold"
  },
  oTov: {
    color: "#01ae3b",
  },
  currency: {
    color: "#162A57",
    fontSize: "12px"
  }
});

const BetSummary = props => {
  const classes = useStyles();
  const { numBets, amount, totalOdds, winAmount, onToggle, wallet, isWinnerFun,
    dayMultiBetNumber, dayMultiBets, currentTicket, t } = props;

  let overrideTotalOdds = null;
  let overrideWinning = null;
  if (!isWinnerFun && dayMultiBetNumber > 0 && dayMultiBets && dayMultiBets.length && currentTicket.ticketType === "single") {
    const dayMultiBet = dayMultiBets.find(dmb => dmb.number === dayMultiBetNumber);
    if (dayMultiBet && dayMultiBet.express_factor) {
      overrideTotalOdds = totalOdds * dayMultiBet.express_factor;
      overrideWinning = winAmount * dayMultiBet.express_factor;
    }
  }

  const anchorOrigin = {
    horizontal: "right",
    vertical: "bottom"
  };

  return (
    <div className={classes.root}>
      <div
        className="d-flex flex-row flex-nowrap align-items-center justify-content-between"
        onClick={onToggle}
      >
        <div>
          <Badge
            color="secondary"
            badgeContent={numBets}
            anchorOrigin={anchorOrigin}
            className={classes.margin}
          >
            <img src={Bilete} alt="" className={classes.block} />
          </Badge>
        </div>
        <div>
          <div className={classes.title}>{t("Total Odds")}</div>
          <div>
            <span className={`${classes.amount} ${overrideTotalOdds ? classes.oTov : ''}`}>
              {overrideTotalOdds ?
                formatTotalOddsValue(overrideTotalOdds) :
                formatTotalOddsValue(totalOdds)}
            </span>
          </div>
        </div>
        <div>
          <div className={classes.title}>{t("Stake")}</div>
          <div>
            <span className={classes.amount}>{amount}</span>{" "}
            <span className={classes.currency}>{isWinnerFun ? "W" : formatCurrency(wallet.currency)}</span>
          </div>
        </div>
        <div>
          <div className={classes.title}>{t("Winning")}</div>
          <div>
            <span className={classes.amount}>{
              overrideWinning ? formatAmount2(overrideWinning) : formatAmount2(winAmount)
            }</span>{" "}
            <span className={classes.currency}>{isWinnerFun ? "W" : formatCurrency(wallet.currency)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const makeMapStateToProps = state => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    wallet: state.wallet,
    numBets: ct.live.selected.length + ct.prematch.selected.length,
    //stake: ct.stake,
    amount: ct.amount,
    totalOdds: ct.totalOdds,
    winAmount: ct.totalMaxWinAmount,
    isWinnerFun: bst.app.isWinnerFun,

    dayMultiBetNumber: ct.dayMultiBetNumber,
    dayMultiBets: bst.config.dayMultiBets,
    currentTicket: ct,
  };
};

const actionCreators = {
  clearSlip: betsSlipClear,
  computeWin: betsSlipComputeWin
};

export default withTranslation()(connect(makeMapStateToProps, actionCreators)(BetSummary));
