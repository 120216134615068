import * as actionTypes from './../actions/actionTypes';

const initialState = {
    account_status: null,
    account_sub_status: null,
    address: null,
    birthday: null,
    client_player_id: null,
    date_created: null,
    email: null,
    first_name: null,
    global_player_id: null,
    last_name: null,
    nin: null,
    phone: null,
    newPhone: null,
    receivedPhoneCode: null,
    verified: null,
    email_verified: false,
    limits: null,
    loading: false,
    receivedChangePassword: null,
    loadingCheckPassword: false,
    passwordIsValid: null,
    ftd: 0,
    chat: {
        account: null,
        active: null,
        loading: false,
    },
    errorCode: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.profile.RECEIVED_ACCOUNT:
            let account = {};
            Object.keys(initialState).forEach((property) => {
                if (!action.account.hasOwnProperty(property)) {
                    return;
                }

                account[property] = action.account[property];
            });

            return {
                ...state,
                ...account,
                loading: false,
            };
        case actionTypes.profile.REQUEST_ACCOUNT:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.profile.RESET_ACCOUNT:
            return {
                ...initialState,
            };
        case actionTypes.profile.REQUEST_LIMITS:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.profile.RECEIVED_LIMITS:
            let limits = {};

            Object.keys(initialState).forEach((property) => {
                if (!action.limits.hasOwnProperty(property)) {
                    return;
                }

                limits[property] = action.limits[property];
            });

            return {
                ...state,
                ...limits,
                loading: false,
            };
        case actionTypes.profile.SET_NEW_LIMIT:
            return {
                ...state,
                limit_type: action.limit_type,
                limit_type_period: action.limit_type_period,
                limit_value: action.limit_value
            };
        case actionTypes.profile.SET_SELFEXCLUSION:
            return {
                ...state,
                limit_type: action.limit_type,
                limit_type_period: action.limit_type_period,
            };
        case actionTypes.profile.CHECK_PASSWORD:
        case actionTypes.profile.SELF_EXCLUDE:
            return {
                ...state,
                loadingCheckPassword: true,
                passwordIsValid: null,
                password: action.password,
            };
        case actionTypes.profile.RECEIVED_PASSWORD_CHECK:
            let passwordIsValid = action.data && action.data.ResponseCode !== 'undefined';
            let errorCode = action.data.ResponseCode !== 'undefined' ? action.data.ResponseCode : false;
            return {
                ...state,
                loadingCheckPassword: false,
                password: null,
                passwordIsValid: passwordIsValid,
                errorCode: errorCode
            };
        case actionTypes.profile.CLEAR_PASSWORD_CACHE:
            return {
                ...state,
                receivedChangePassword: null,
                passwordIsValid: null,
            };
        case actionTypes.profile.CHANGE_PASSWORD:
            return {
                ...state,
                currentPassword: action.currentPassword,
                newPassword: action.newPassword,
                loading: true,
            };
        case actionTypes.profile.RECEIVED_CHANGE_PASSWORD:
            return {
                ...state,
                receivedChangePassword: action.receivedChangePassword,
                loading: false,
            };
        case actionTypes.profile.CHECK_PHONE:
            return {
                ...state,
                newPhone: action.newPhone,
                loading: true,
                receivedPhoneCode: null,
            };
        case actionTypes.profile.RECEIVED_PHONE:
            return {
                ...state,
                receivedPhone: action.receivedPhone,
                loading: false,
            };
        case actionTypes.profile.CHECK_PHONE_CODE:
            return {
                ...state,
                newPhone: action.newPhone,
                code: action.code,
                loading: true,
            };
        case actionTypes.profile.RECEIVED_PHONE_CODE:
            let phone = state.phone;
            if (Array.isArray(action.receivedPhoneCode) && action.receivedPhoneCode.length === 0) {
                phone = state.newPhone;
            }
            return {
                ...state,
                receivedPhoneCode: action.receivedPhoneCode,
                phone: phone,
                loading: false,
            };
        case actionTypes.profile.RECEIVED_CHAT_INFO:
            return {
                ...state,
                chat: {
                    ...state.chat,
                    account: action.value.result.chat.account,
                    authkey: action.value.result.authkey,
                    active: action.value.result.chat.active,
                    loading: false,
                }
            };
        case actionTypes.profile.GET_CHAT_INFO:
            return {
                ...state,
                chat: {
                    account: null,
                    authkey: null,
                    active: false,
                    loading: true,
                }
            };
        default:
            return state;
    }
};

export default reducer;
