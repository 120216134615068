import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as storeActions from "../../store/actions";
import { openGameLauncher, setGameOpened } from '../../store/actions/games';
import { withRouter } from "react-router";
import TitleBar from '../UI/TitleBar/TitleBar';
import { Grid } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from '@material-ui/core/CircularProgress';
import Icons from '../../assets/Icons/Icons';
import './Deposit.scss';
import Utils from "../../utils/Utils";
import BonusWidget, { getSelectedBonusValue } from "../GlobalWidgets/LoggedIn/Bonus/BonusWidget";
import Viva from './Viva/Viva';
import UseNewPaymentMethod from "./NewPaymentMethod/UseNewPaymentMethod";
import BonusNotEligible from "./NewPaymentMethod/BonusNotEligible";
import paysafe from "../../assets/Images/Deposit/img_paysafe.png";
import oktocash from "../../assets/Images/Deposit/img_oktocash.png";
import aircash from "../../assets/Images/Deposit/img_logo_aircash.png";
import MarketingEvents   from "../../utils/MarketingEvents";


import Paysafe from "./NewPaymentMethod/Paysafe";
import Translate from "../../utils/Translate";
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";
import Okto from "./Okto/Okto";
import Aircash from "./Aircash/Aircash";
import ApplePay from "./Viva/ApplePay/ApplePay";

const MINIMUM_DEPOSIT_VALUE = typeof window.config !== "undefined" && typeof window.config.MINIMUM_DEPOSIT_VALUE !== "undefined" ? window.config.MINIMUM_DEPOSIT_VALUE : 20;
const MAXIMUM_DEPOSIT_VALUE = typeof window.config !== "undefined" && typeof window.config.MAXIMUM_DEPOSIT_VALUE !== "undefined" ? window.config.MAXIMUM_DEPOSIT_VALUE : 9000;

const PAYMENT_SAFECHARGE = 2;
const PAYMENT_OKTO = 4;
const PAYMENT_VIVA = 3;
const PAYMENT_CASH = 1;
const PAYMENT_SKRILL_SAFECHARGE = 5;
const PAYMENT_AIRCASH = 6;

const Deposit = (props) => {

    const [amountShown, setAmountShown] = useState(0);
    const [smallButtons, setSmallButtons] = useState(false);
    const [dailyLimit, setDailyLimit] = useState(0);
    const [checkoutPage, setCheckoutPage] = useState(false);
    const [checkoutVivaPage, setCheckoutVivaPage] = useState(false);
    const [maximumLimit, setMaximumLimit] = useState(false);
    const [depositTax, setDepositTax] = useState(0);
    const [step, setStep] = useState(0);
    const [selectedBonus, setSelectedBonus] = useState(false);
    const [bonusMin, setBonusMin] = useState(0);
    const [bonusMax, setBonusMax] = useState(0);
    const [bonusPercentage, setBonusPercentage] = useState(100);
    const [tempPlayerData, setTempPlayerData] = useState(null);
    const [vivaOnly, setVivaOnly] = useState(true);
    const [selectedBonusObj, setSelectedBonusObj] = useState(false);

    const timeoutBonusCalculator = React.useRef(null);
    const initTest = () => {
        // window.config.okto_enabled = '1';
        // window.config.platformType = '1';
        // setAmountShown(10);
        // setStep(7);
        // setSelectedBonus(60);
    }

    useEffect(() => {
        if (props.auth.auth_type !== "user" && props.auth.auth_type !== null) {
            props.history.push("/");
        } else {
            if (props.auth.auth_type === 'user') {
                props.onRequestLimits();
                props.onRequestTransactionsHistory();
                MarketingEvents.depositStarted({
                    playerId: props.profile?.client_player_id,
                    isFTD: props.isFTD,
                })
            }
        }
        props.resetPayment();



        initTest();
        return () => {
            props.onClearTaxServices();
            setDepositTax(0);
        }
    }, []);

    useEffect(() => {
        if (props.auth.auth_type !== "user" && props.auth.auth_type !== null) {
            props.history.push("/");
        } else {
            if (props.auth.auth_type === 'user') {
                props.onRequestLimits();
                props.onRequestTransactionsHistory();
                MarketingEvents.depositStarted({
                    playerId: props.profile?.client_player_id,
                    isFTD: props.isFTD,
                })

            }
        }
    }, [props.auth]);

    useEffect(() => {
        if (props.profile.limits !== null) {
            let limitAmount = 0;
            Object.keys(props.profile.limits).forEach((key) => {
                if (props.profile.limits[key].period_type === 1 || props.profile.limits[key].period_type === 15) {
                    limitAmount = props.profile.limits[key].limit_amount;
                }
            });
            setDailyLimit(limitAmount);
        }
    }, [!props.profile.loading && props.profile.limits !== null]);

    const loadedUrl = React.useRef("");

    useEffect(() => {
        if(!props.paymentCheckout.loading && props.paymentCheckout.iframeLink){
            // set localstorage for MarketingEvents
            const iframeLink = props.paymentCheckout.iframeLink;

            if (window.config.cordova && window.cordova && iframeLink.indexOf("safecharge.com") > -1 && loadedUrl.current === "" && window.cordova.InAppBrowser) {
                console.log("InAppBrowser: open the deposit link: " + iframeLink);

                if (window.config.cordova && window.cordova.InAppBrowser) {
                    const closebuttoncaption = props.t("Close");
                    let depositWindow = window.cordova.InAppBrowser.open(iframeLink, '_blank', `location=no,closebuttoncaption=${closebuttoncaption}`);

                    loadedUrl.current = iframeLink;

                    const onLoadStart = (params) => {
                        console.log("InAppBrowser[onLoadStart] Url: ", params.url);

                        if (params.url.indexOf("https://www.winner.ro") === 0 || params.url.indexOf("http://www.winner.ro") === 0) {

                            if (params.url.indexOf("/payment/success") > -1) {
                                onExit();
                                props.history.push("/payment/success");
                            } else if (params.url.indexOf("/payment/cancel") > -1) {
                                onExit();
                                //props.history.push("/payment/cancel");
                            } else if (params.url.indexOf("/payment/pending") > -1) {
                                onExit();
                                props.history.push("/payment/pending");
                            } else {
                                onExit();
                            }
                        }
                    };

                    const onExit = () => {
                        loadedUrl.current = "";

                        console.log("InAppBrowser[exit] remove events listener");
                        depositWindow.removeEventListener('loadstart', onLoadStart);
                        depositWindow.removeEventListener('exit', onExit);
                        depositWindow.close();
                        depositWindow = null;
                        props.onClearPaymentLink();
                        setCheckoutPage(false);
                    };

                    depositWindow.addEventListener('exit', onExit);
                    depositWindow.addEventListener('loadstart', onLoadStart);
                }
                return;
            }

            setTimeout(() => {
                window.location.href = props.paymentCheckout.iframeLink;
            },500);
        }
    }, [!props.paymentCheckout.loading && props.paymentCheckout.iframeLink]);

    useEffect(() => {
        if (props.withdrawals.depositTax) {
            setDepositTax(props.withdrawals.depositTax.toFixed(2))
        }
    }, [!props.withdrawals.loadingTax]);

    const getMaxLimit = () => {
        let max = false;
        if (props.profile.limits) {
            let limits = props.profile.limits;
            Object.keys(limits).forEach(function (key, value) {
                if (limits[key]) {
                    if (typeof limits[key].type !== "undefined" && limits[key].type === 1) {
                        if (limits[key].limit_amount) {
                            let available = limits[key].limit_amount - limits[key].accumulated_amount;

                            max = max !== false ? (max < available ? max : available) : available;
                        }
                    }
                }
            });
        }

        if ((max > MAXIMUM_DEPOSIT_VALUE || max === false) && MAXIMUM_DEPOSIT_VALUE) {
            max = MAXIMUM_DEPOSIT_VALUE;
        }

        if (max !== false && (max < 0 || isNaN(max))) {
            max = 0;
        }

        if (maximumLimit !== max) {
            setMaximumLimit(max);
        }
        return max;
    }

    const getLimitMessage = () => {
        let max = getMaxLimit();
        if (max !== false) {
            return (
                <FormHelperText error={true}>
                    {Utils.t('You can deposit minimum ${minVal} Lei and maximum ${maxVal} Lei', { maxVal: max, minVal: MINIMUM_DEPOSIT_VALUE })}
                </FormHelperText>
            )
        }
        return [];
    }

    const tempPlayerDataHandler = (playerData) => {
        if (playerData) {
            setTempPlayerData(playerData);
        }
    }

    const onChangeHandler = (e) => {
        let field;
        let time = 1500;
        if (typeof e.target === "undefined") {
            field = e;
            time = 1;
        } else {
            field = e.target.value;
        }

        field = parseInt(field);

        if (isNaN(field)) {
            field = 0;
        }

        if (maximumLimit !== false && maximumLimit < field) {
            field = maximumLimit;
        }

        if (field > 0) {
            Utils.delayedFunction("calculateTaxService", props.onRequestTaxService.bind(this, 2, field, 1), time);
            if (selectedBonus  > 0) {
                Utils.delayedFunction("bonusCalculator", props.getBonusCalculator.bind(this, selectedBonus, field), time);
            }
        }

        setAmountShown(field);
    }

    const setBonusMaxAndAmount = (value) => {
        if (maximumLimit !== false && maximumLimit < value) {
            value = maximumLimit;
        }
        setAmountShown(value);
        setBonusMax(value);
        Utils.delayedFunction("calculateTaxService", props.onRequestTaxService.bind(this, 2, value, 1), 1);
    }

    const onBlur = () => {
        if (amountShown === '') {
            setAmountShown(0);
        }
        Utils.delayedFunction("calculateTaxService", props.onRequestTaxService.bind(this, 2, amountShown, 1), 1);
        setSmallButtons(false);
    }

    const onFocus = () => {
        if (amountShown === 0) {
            setAmountShown('');
        }
        setSmallButtons(true);
    };

    const getValue = () => {
        return amountShown;
    };

    const goToTermsAndConditions = () => {
        props.history.push('/customer-support/terms&conditions');
    };

    const getButtons = () => {
        let comp = [];
        let currentValue = amountShown;
        let steps = typeof window.config !== "undefined" && typeof window.config.DEPOSIT_PRESETS !== "undefined" ? window.config.DEPOSIT_PRESETS.split('/') : "";
        let maxLimit = getMaxLimit();
        let minLimit = MINIMUM_DEPOSIT_VALUE;


        if (steps.length !== 6) {
            steps = [60, 120, 150, 250, 350, 450];
        }

        let minUsed = bonusMin === 0 || bonusMin === false;
        let maxUsed = bonusMax === 0 || bonusMax === false;

        steps.forEach((val, key) => {
            if (!minUsed && val >= bonusMin) {
                val = bonusMin;
                if (val < MINIMUM_DEPOSIT_VALUE) {
                    val = MINIMUM_DEPOSIT_VALUE;
                }
                minUsed = true;
            }

            if (!maxUsed && val >= bonusMax && minUsed) {
                val = bonusMax;
                maxUsed = true
            }

            if (comp.length === 5) {
                if (!minUsed) {
                    val = bonusMin;
                    minUsed = true;
                } else {
                    if (!maxUsed) {
                        val = bonusMax;
                        maxUsed = true;
                    }
                }
            }

            comp.push(
                <Grid item xs={4} key={"deposit_buttons_" + key}>
                    <Button
                        className={"depositButton " + (parseInt(currentValue, 10) === parseInt(val, 10) ? "active" : "")}
                        disabled={(dailyLimit < val && dailyLimit !== null)}

                        onClick={onChangeHandler.bind(this, val)}
                    >
                        <div>{val}</div>
                        {/*{val === bonusMin && (*/}
                        {/*    <div className={'desc'}>MIN BONUS</div>*/}
                        {/*)}*/}
                        {val === bonusMax && bonusMin !== bonusMax && (
                            <div className={'desc'}>MAX BONUS</div>
                        )}
                    </Button>
                </Grid>
            );
        });

        return comp;
    };

    const useOkto = () => {
        setStep(7);
        setCheckoutVivaPage(false);
    }

    const useAbon = () => {
        setStep(8);
        setCheckoutVivaPage(false);
    }

    const clickHandler = () => {
        if (maximumLimit !== false && maximumLimit < amountShown) {
            return;
        }

        if (!(window.config && window.config.vivaEnabled === '1')) {
            setCheckoutPage(true);
            clickSafeChargeHandler();
        } else {
            if (isEligibleForPayment(props.bonuses, PAYMENT_SAFECHARGE)) {

                setStep(1);

                setCheckoutVivaPage(false);
                setCheckoutPage(true);
            } else {
                // if (vivaOnly) {
                //     setStep(5);
                // } else {
                setStep(2);
                // }
                setCheckoutVivaPage(false);
                setCheckoutPage(true);
            }
        }
    };

    const showPaymentMethodPopup = () => {
        setStep(4);
    }

    const isEligibleForPayment = (bonuses, method) => {
        let eligible = true;

        if (selectedBonus > 0) {
            eligible = false;
            Object.keys(bonuses).forEach(function (index) {
                if (bonuses[index].bonus_available_id === selectedBonus) {
                    if (typeof bonuses[index].eligible_payment_methods !== "undefined" && bonuses[index].eligible_payment_methods.length) {
                        Object.keys(bonuses[index].eligible_payment_methods).forEach(function (key) {
                            if (bonuses[index].eligible_payment_methods[key] == method) {
                                eligible = true;
                                return;
                            }
                        })
                    } else {
                        eligible = true;
                    }
                    return;
                }
            })
        }

        return eligible;
    }
    const reqInProgress = () => {
        return ExpiringLocalStorage.get('loading_vctok') === '1';
    }
    const clickSafeChargeHandler = () => {
        if (reqInProgress()) {
            return;
        }
        ExpiringLocalStorage.set('loading_vctok', '1', 30);
        props.onRequestPaymentLink(amountShown, selectedBonus);
    }

    const clickVivaHandler = () => {
        setCheckoutPage(false);
        setStep(1);
        if (window.config && window.config.vivaEnabled === '1') {

            if (maximumLimit !== false && maximumLimit < amountShown) {
                return;
            }
            setCheckoutVivaPage(true);
        } else {
            if (window.config.disable_safecharge !== '1') {
                clickHandler();
            }
        }
    };

    const backPageHandler = () => {
        if (step === 0 || (step === 1 && !checkoutPage && !checkoutVivaPage)) {

            let fromGame = localStorage.getItem("fromGame");
            if (fromGame) {
                fromGame = JSON.parse(fromGame);
            }

            if (fromGame?.game_id) {
                props.openGameLauncher(fromGame.game_id ? fromGame.game_id : fromGame.id);
                props.setGameOpened(fromGame);

                let url = '/play/' + (fromGame.game_id ? fromGame.game_id : fromGame.id);
                props.history.push(url);
                localStorage.removeItem("fromGame");
            } else {
                props.history.goBack();
            }
            props.onClearTaxServices();
            setDepositTax(0);
            setAmountShown(0);
        } else {
            setStep(1);
            setCheckoutVivaPage(false)
            setCheckoutPage(false);
        }

        // if(checkoutVivaPage){
        //     setCheckoutVivaPage(!checkoutVivaPage);
        // }else{
        //     props.history.goBack();
        //     props.onClearTaxServices();
        //     setDepositTax(0);
        //     setAmountShown(0);
        // }
    };

    const onClickedBonus = (id, bonus) => {


        if (typeof bonus === 'undefined' || (bonus && typeof bonus.bonus_available_id === "undefined") || bonus === null) {
            setSelectedBonus(id);
            setSelectedBonusObj(null);
            setBonusMin(0);
            setBonusMax(0);
            setAmountShown(0);
        } else {
            setSelectedBonus(bonus.bonus_available_id);
            setSelectedBonusObj(bonus);
            if (bonus.bonus_available_type < 3) {
                // if(bonus.bonus_available_amount > 100) {
                //     setBonusMin(bonus.bonus_available_min);
                // } else {
                setBonusMin(Math.round(bonus.bonus_available_min * 100 / bonus.bonus_available_amount));
                // }
                setBonusMaxAndAmount(Math.round(bonus.bonus_available_max * 100 / bonus.bonus_available_amount));
                setBonusPercentage(bonus.bonus_available_type == 1 ? 100 : bonus.bonus_available_amount / 100);
            } else {
                setBonusMin(bonus.bonus_available_min);
                setAmountShown(bonus.bonus_available_min);
                setBonusMax(false);
                setBonusPercentage(false);
            }
        }
        switch (props.paymentMethod) {
            case PAYMENT_OKTO:
                setStep(7);
                setAmountShown(1);
                break;
            case PAYMENT_AIRCASH:
                setStep(8);
                setAmountShown(1);
                break;
            default:
                setStep(1);
                break;
        }
    }

    const changeBonusClick = () => {
        setStep(0);
        setSelectedBonus(false);
    }

    const closePopup = () => {
        setCheckoutPage(false);
        setCheckoutVivaPage(false);
        setStep(1);
    }

    const dropBonusOpenSafecharge = () => {


        props.onRequestPaymentLink(amountShown, -2)

    }

    const getSelectedBonusAmount = () => {
        if (!props.bonuses || selectedBonus === false || selectedBonus === -2) {
            return false;
        }
        let bonusValue = 0;
        Object.keys(props.bonuses).forEach((key) => {
            let bonus = props.bonuses[key];
            if (bonus.bonus_available_id === selectedBonus) {
                if (amountShown * (bonus.bonus_available_amount / 100) < bonus.bonus_available_max) {
                    bonusValue = amountShown * (bonus.bonus_available_amount / 100);
                } else {
                    bonusValue = bonus.bonus_available_max;
                }
            }
        })
        return bonusValue < bonusMin ? false : bonusValue;
    }

    const getPaysafePopup = () => {
        return (<Paysafe oldPaymentHandler={() => clickSafeChargeHandler()} closePopup={() => closePopup()} />);
    }

    let buttons = getButtons();
    let value = getValue();
    let pageContent = <CircularProgress className={'Loading'} />;
    let taxes = '';
    let amountWithTaxes = '';

    if (props.withdrawals.loadingTax) {
        taxes = <div className={'p'}><CircularProgress className={'Loading'} /></div>;
        amountWithTaxes = <div className={'p'}><CircularProgress className={'Loading'} /></div>;
    } else {
        if (depositTax > 0) {
            taxes = <p>- {depositTax} Lei</p>;
            amountWithTaxes = <p>{(amountShown - depositTax).toFixed(2)} Lei</p>;
        } else {
            taxes = <p>0.00 Lei</p>;
            amountWithTaxes = <p>{(amountShown - depositTax).toFixed(2)} Lei</p>;
        }
    }

    if (window.config && window.config.deposit_disabled === '1') {
        pageContent = (
            <p className={'deposit-disabled'}>
                {props.t("Deposit is temporarily unavailable")}
            </p>
        );
    } else if (step === 0) {
        pageContent = (<BonusWidget type={'list'} paymentMethod={props.paymentMethod} onClickedBonus={onClickedBonus.bind(this)} cta_text={props.t("Alege bonus")} addEmpty={true} />);
        getMaxLimit();
    } else if (!props.profile.loading && props.profile.limits !== null) {
        let disabledButton = (amountShown < MINIMUM_DEPOSIT_VALUE || ((maximumLimit !== false) && amountShown > maximumLimit)) || checkoutPage || checkoutVivaPage;
        if (step === 8) {
            pageContent = (<div className={"DepositOnlineScreen Okto"} key={'deposit-okto-main'}>
                <Grid container className={"DepositOnlineContent"}>
                    {/*<BonusWidget  paymentMethod={props.paymentMethod}  type={'compact'} bonusId={selectedBonus} changeBonusClick={changeBonusClick.bind(this)} grayscale={bonusMin > parseInt(value,10)} depositValue={value}/>*/}
                    <Aircash bonusMin={bonusMin}
                        bonusMax={bonusMax}
                        maximumLimit={maximumLimit}
                        minimumLimit={MINIMUM_DEPOSIT_VALUE}
                        dailyLimit={dailyLimit}
                        type={PAYMENT_AIRCASH === props.paymentMethod ? "deposit-aircash" : "deposit"}
                        // onRequestTaxService={props.onRequestTaxService.bind(this)}
                        limitMessage={getLimitMessage()}
                        amount={amountShown * 100}
                        bonusId={selectedBonus}
                        taxes={depositTax}
                    />
                </Grid>
            </div>);
        } else if (step === 7) {
            pageContent = (<div className={"DepositOnlineScreen Okto"} key={'deposit-okto-main'}>
                <Grid container className={"DepositOnlineContent"}>
                    {/*<BonusWidget  paymentMethod={props.paymentMethod}  type={'compact'} bonusId={selectedBonus} changeBonusClick={changeBonusClick.bind(this)} grayscale={bonusMin > parseInt(value,10)} depositValue={value}/>*/}
                    <Okto bonusMin={bonusMin}
                        bonusMax={bonusMax}
                        maximumLimit={maximumLimit}
                        minimumLimit={MINIMUM_DEPOSIT_VALUE}
                        dailyLimit={dailyLimit}
                        type={PAYMENT_OKTO === props.paymentMethod ? "deposit-okto" : "deposit"}
                        // onRequestTaxService={props.onRequestTaxService.bind(this)}
                        limitMessage={getLimitMessage()}
                        amount={amountShown * 100}
                        bonusId={selectedBonus}
                        taxes={depositTax}
                    />
                </Grid>
            </div>);

        } else if (step === 6) {
            pageContent = (<div className={"DepositOnlineScreen"} key={'deposit-select-payment-method'}>
                <Grid container className={"DepositOnlineContent"}>
                    <BonusWidget paymentMethod={props.paymentMethod} type={'compact'} bonusId={selectedBonus} changeBonusClick={changeBonusClick.bind(this)} grayscale={bonusMin > parseInt(value, 10)} depositValue={value} />
                    <div onClick={() => setStep(7)}>selfpay</div>
                    <div onClick={() => setStep(1)}>card/paysafe</div>
                </Grid>
            </div>);
        } else if (step === 5) {
            pageContent = getPaysafePopup();
        } else if (step === 2 && selectedBonus > 0) {
            pageContent = (<BonusNotEligible newPaymentHandler={() => { clickVivaHandler() }} oldPaymentHandler={() => dropBonusOpenSafecharge()} selectedBonus={selectedBonus} depositValue={value} />)
        } else if (step === 4) {
            pageContent = (<UseNewPaymentMethod closeHandler={() => closePopup()} />);
        } else if (checkoutPage && window.config && window.config.vivaEnabled === '1') {

            pageContent = getPaysafePopup();

        } else {

            pageContent = (
                <div className="DepositOnlineContent">
                    <BonusWidget paymentMethod={props.paymentMethod} type={'compact'} bonusId={selectedBonus} changeBonusClick={changeBonusClick.bind(this)} grayscale={bonusMin > parseInt(value, 10)} depositValue={value} />
                    <div className="field">
                        <FormControl>
                            {Icons.get('depositCoin', `DepositIcon`)}
                            <Input value={value} type={"tel"} pattern={"\d*"} onBlur={onBlur.bind(this)} onFocus={onFocus.bind(this)} onChange={onChangeHandler.bind(this)} />
                            {/*<i className="questionLink" onClick={goToTermsAndConditions}>{Icons.get('questionmarkCircle', 'questionmarkCircle')}</i>*/}
                            <FormHelperText>
                                {props.t("Deposit value") + " (Lei)"}
                            </FormHelperText>
                            {getLimitMessage()}
                        </FormControl>
                    </div>
                    <Grid container >
                        <Grid item xs={12} className={'buttons' + (smallButtons ? ' small-buttons' : '')}>
                            <Grid container >
                                {buttons}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'taxes'}>
                            <Grid container>
                                <Grid item xs={6} className={'left'}>
                                    {taxes}
                                    <span>{props.t("CDA 2% (GEO 114)")}</span>
                                </Grid>
                                <Grid item xs={6} className={'right'}>
                                    {amountWithTaxes}
                                    <span>{props.t("Net deposit")}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'actions'}>
                            <Grid container>
                                <Button fullWidth variant="contained" disabled={disabledButton} size="large" className={'nextButton'} onClick={clickVivaHandler.bind(this)}>
                                    {checkoutVivaPage || checkoutPage ? <CircularProgress className={'Loading'} /> : props.t("Confirm")}
                                </Button>
                            </Grid>
                        </Grid>
                        <div className="secure-transaction">{Icons.get('secureTransaction')} {props.t("Tranzactie securizata")}</div>
                    </Grid>
                </div>
            );
        }
    }
    let showSafecardAction = window.config && window.config.disable_safecharge !== '1' && window.config.vivaEnabled === '1' && step === 1 && checkoutVivaPage;
    let showOktoCashAction = window?.config?.okto_enabled === '1' && step === 1 && checkoutVivaPage;
    let showAircashAction = window?.config?.aircash_enabled === '1' && step === 1 && checkoutVivaPage;

    return (
        <div>
            <div className={"DepositOnlineScreen" + (showSafecardAction ? " safecard-actions" : "")}>
                <TitleBar pageTitle={props.t('Deposit')} backHandler={() => backPageHandler()} />
                {!checkoutVivaPage
                    ? pageContent
                    : <Viva
                        tempPlayerDataHandler={(playerData) => tempPlayerDataHandler(playerData)}
                        tempPlayerData={{
                            ...tempPlayerData,
                            amount: amountShown,
                            bonusId: (selectedBonus !== false && selectedBonus !== -2) ? selectedBonus : null
                        }}
                        amount={amountShown}
                        bonusAmount={bonusMin <= amountShown && selectedBonus > 0 ? getSelectedBonusValue(selectedBonusObj, amountShown, {
                            bonusAward: props.bonusAward
                        }) : "No Bonus"}
                        bonusId={(selectedBonus !== false && selectedBonus !== -2) ? selectedBonus : null}

                        backHandler={() => backPageHandler()}
                        forceSafecharge={() => clickHandler()}
                    />
                }

                {(showSafecardAction || showOktoCashAction || showAircashAction) && <div className={'actions-fixed'}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} className={'title'} style={{ "color": "#008ac9" }}>
                                {props.t("or make a deposit with")}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <div className="button-grouped">

                                {showAircashAction && <>
                                    <Button fullWidth variant="contained" size={"large"}
                                        disabled={amountShown < MINIMUM_DEPOSIT_VALUE || checkoutPage}
                                        onClick={useAbon.bind(this)}>
                                        <img src={aircash} alt={'aircash'} />
                                    </Button>

                                </>}

                                {showOktoCashAction && <>
                                    <Button fullWidth variant="contained" size={"large"} disabled={amountShown < MINIMUM_DEPOSIT_VALUE || checkoutPage} onClick={useOkto.bind(this)}>
                                        <img src={oktocash} alt={'oktocash'} />
                                    </Button>

                                </>}

                                {showSafecardAction && <>
                                    <Button fullWidth variant="contained" size={"large"} disabled={amountShown < MINIMUM_DEPOSIT_VALUE || checkoutPage} onClick={clickHandler.bind(this)}>
                                        <img src={paysafe} alt={paysafe} />
                                    </Button>
                                    <Grid item xs={12} >
                                        <div className={'description'}>
                                            <Translate text={"Pagina pentru finalizarea depunerii cu ${boldStart}PaysafeCard se va deschide intr-un tab nou${boldEnd}."} placeholder={{ boldStart: '<strong>Abon, Okto.CASH, ', boldEnd: '</strong>' }} />
                                            {Icons.get('newTab', 'newTab')}
                                        </div>
                                    </Grid>
                                </>}
                            </div>

                        </Grid>

                    </Grid>
                </div>}

            </div>
        </div>
    );
};

const mapStateToProps = state => {

    return {
        bonuses: state.application.eligibleBonuses,
        profile: state.profile,
        auth: state.authentication,
        paymentCheckout: state.paymentCheckout,
        withdrawals: state.withdrawals,
        transactions: state.transactions.depositsWithdrawals,
        games: state.games,
        isFTD: state.profile?.ftd === null,
        bonusAward: state.application.bonusAward
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestLimits: () => dispatch(storeActions.requestLimits()),
        onRequestTransactionsHistory: () => dispatch(storeActions.requestTransactionsHistory()),
        onRequestPaymentLink: (amount, selectedBonus) => dispatch(storeActions.requestPaymentLink(amount, selectedBonus)),
        onRequestTaxService: (taxType, amount, id) => dispatch(storeActions.requestTaxService(taxType, amount, id)),
        onClearTaxServices: () => dispatch(storeActions.clearTaxService()),
        resetPayment: () => dispatch(storeActions.resetWithdraw()),
        openGameLauncher: (gameId) => dispatch(openGameLauncher(gameId)),
        setGameOpened: (game) => dispatch(setGameOpened(game)),
        getBonusCalculator: (bonusId, amount) => dispatch(storeActions.getBonusCalculator(bonusId, amount)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Deposit)));