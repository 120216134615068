import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import Icons from "../../../../../assets/Icons/Icons";
import Button from "@material-ui/core/Button";
import Details from "./components/Details";
import { Grid } from "@material-ui/core";
import * as storeActions from "../../../../../store/actions";
import { withRouter } from "react-router";
import Modal from "@material-ui/core/Modal";
import ResponseErrorMessages from "../../../../../store/errors/ResponseErrorMessages";
import CircularProgress from '@material-ui/core/CircularProgress';
import CardList from "../../../../Deposit/Viva/CardList/CardList";
import i18next from "i18next";

class ConfirmStep extends Component {

    state = {
        confirmDisabled: false,
        modalOpened: false,
        errorModalOpened: false,
        errorCode: null,
        selectedCard: false,
        loading: false
    };

    componentDidMount() {

        if (typeof this.props.cardData !== 'undefined') {
            if (typeof this.props.cardData[0] !== 'undefined') {
                this.setState({
                    ...this.state,
                    selectedCard: this.props.cardData[0].card_token,
                    confirmDisabled: false,
                    loading: false,
                })
            }
        }
    }

    clickHandler(step) {
        this.props.goToStep(step);
        this.props.setStep(step);
        this.props.setLocation(false);
    }
    confirmHandler() {
        this.setState({
            ...this.state,
            confirmDisabled: true,
        });
        if (!this.props.location && this.state.selectedCard) {
            this.vivaWithdraw()
        } else {
            this.props.send({ amount: this.props.amount, locationId: this.props.location.id })
        }
    }

    vivaWithdraw() {
        this.props.requestVivaWithdraw(this.state.selectedCard,this.props.amount);
    }

    componentDidUpdate(prevProps) {
        if (this.props.withdrawWizard.loading !== prevProps.withdrawWizard.loading) {
            if (this.props.withdrawWizard.errorCode && !this.props.withdrawWizard.loading) {
                this.setState({
                    ...this.state,
                    errorCode: this.props.withdrawWizard.errorCode,
                    confirmDisabled: true,
                    errorModalOpened: true,
                });
            } else if (!this.props.withdrawWizard.errorCode && !this.props.withdrawWizard.loading) {
                this.setState({
                    ...this.state,
                    confirmDisabled: true,
                    modalOpened: true,
                });
            }
        }
        if (this.props.finished !== prevProps.finished && this.props.finished) {
            this.setState({
                ...this.state,
                selectedCard: false
            })
            this.props.getWallet();
            this.props.history.push('/withdraw/success');
        }

        if (this.props.cardData !== prevProps.cardData) {
            if (typeof this.props.cardData !== 'undefined') {
                if (typeof this.props.cardData[0] !== 'undefined') {
                    this.setState({
                        ...this.state,
                        selectedCard: this.props.cardData[0].card_token
                    })
                }
            }
        }
    }

    closeModal() {
        this.setState({
            ...this.state,
            modalOpened: false
        })
        this.props.history.push('/cazino/jocuri');
    }

    closeErrorModal() {
        this.setState({
            ...this.state,
            errorCode: null,
            confirmDisabled: false,
            errorModalOpened: false,
        });
    }

    goToPendingWithdrawals() {
        this.props.history.push('/profile/pending-withdrawals');
    }

    goToSupport() {
        this.props.history.push('/customer-support/about-us');
    }
    setCard(cardToken) {
        this.setState({
            ...this.state,
            selectedCard: cardToken
        })
    }
    render() {

        let loading  = this.props.withdrawWizard.loading || (!this.props.location && !this.state.selectedCard);

        return (
            <div className={'confirmation content'}>

                {this.props.location && <div className="title">{this.props.t('Location')}</div>}
                {!this.props.location && <div className="title">{this.props.t('Payment method')}</div>}

                {this.props.location && <div className="location">
                    {Icons.get('PinAddressIcon', 'PinAddressIcon')}
                    <div className="name">
                        {this.props.location.locationName}
                    </div>
                    <div className="address">
                        {this.props.location.formattedAddress}
                    </div>
                    <Button onClick={this.clickHandler.bind(this, 2)}>{this.props.t("Change")}</Button>
                </div>}
                {this.props.location && window.config && window.config[`in_location_withdraw_text_${i18next.language}`] && <div className={'text-withdraw'}>
                    {window.config[`in_location_withdraw_text_${i18next.language}`]}
                </div>}
                {!this.props.location && <div className={"CardListContainer"}>
                    <CardList cardData={this.props.cardData} setCard={this.setCard.bind(this)} selectedCard={this.state.selectedCard} noAddCard={true} disabled={this.state.confirmDisabled || loading}/>
                </div> }
                <div className="title mt2r">{this.props.t("Details")}</div>
                <div className="details">
                    {Icons.get('moneyBag')}
                    <div className="sum item">
                        {this.props.amount} Lei
                        <span className="description">{this.props.t('Withdrawal amount')}</span>
                    </div>
                    <div className="item">
                        {this.props.amount - this.props.taxes} Lei
                        <span className="description">{this.props.t('Withdrawal amount that will be received')}</span>
                    </div>
                    <div className="item">
                        {this.props.taxes} Lei
                        <span className="description">{this.props.t('Taxes due to the state')}</span>
                    </div>
                    <Button onClick={this.clickHandler.bind(this, 1)}>
                        {this.props.t("Change")}
                    </Button>
                </div>
                <Grid container >
                    <Grid item xs={12} className={'actions'}>
                        <Grid container className={'ConfirmButton'}>
                            <Button fullWidth variant="contained" disabled={this.state.confirmDisabled || loading} size="large" className={'nextButton'} onClick={this.confirmHandler.bind(this)}>
                                {loading ? <CircularProgress className={'Loading'} /> : this.props.t("Confirm withdrawal")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal open={this.state.modalOpened} backdrop="static" id={'modal-withdraw'}>
                    <div className="content">
                        <div className="icon-success">
                            {Icons.get('checkmarkFilled')}
                        </div>
                        <h6>{this.props.t('Success!')}</h6>
                        <p>{this.props.t("Your request for withdrawal has been registered. Soon the Payments Department will review it for approval.")}</p>
                        <p>{this.props.t('You will be notified when the money is ready to pick up from the chosen agency')}</p>
                        <Button fullWidth className={'text'} onClick={this.goToPendingWithdrawals.bind(this)}>
                            {this.props.t("Pending withdrawals")}
                        </Button>
                        <Button fullWidth variant="contained" size="large" className={'pending-withdrawals'} onClick={this.closeModal.bind(this)}>
                            {this.props.t("Close")}
                        </Button>
                    </div>
                </Modal>
                <Modal open={this.state.errorModalOpened} backdrop="static" id={'modal-withdraw-error'}>
                    <div className="content">
                        <div className="icon-exclamation">
                            {Icons.get('exWarning')}
                        </div>
                        <h6>{this.props.t('Request Failed')}</h6>
                        <div className="ModalContent">
                            <p>{this.props.t("We’ve encountered this error:")}</p>
                            <p>{ResponseErrorMessages.get(this.state.errorCode)}</p>
                            <p>{this.props.t('Please retry later or - should the error keeps appearing - contact the Customer Support.')}</p>
                        </div>
                        <Button fullWidth className={'text'} onClick={this.goToSupport.bind(this)}>
                            {this.props.t("Contact Support")}
                        </Button>
                        <Button fullWidth className={'text'} onClick={this.goToPendingWithdrawals.bind(this)}>
                            {this.props.t("Try Again")}
                        </Button>
                        <Button fullWidth variant="contained" size="large" className={'pending-withdrawals'} onClick={this.closeErrorModal.bind(this)}>
                            {this.props.t("Close")}
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setStep: (step) => dispatch(storeActions.setWithdrawWizardStep(step)),
        send: (data) => dispatch(storeActions.sendWithdraw(data)),
        requestVivaWithdraw: (token,amount) => dispatch(storeActions.requestVivaWithdraw(token,amount)),
        getWallet: () => dispatch(storeActions.requestWallet()),
        setLocation: (location) => dispatch(storeActions.withdrawSetLocation(location)),
        // hideWithdrawWizardModal: () => dispatch(storeActions.showWithdrawModal(false)),
    };
};

const mapStateToProps = state => {
    return {
        withdrawWizard: state.withdrawWizard,
        location: state.withdrawWizard.selectedLocation,
        amount: state.withdrawWizard.amount,
        taxes: state.withdrawWizard.taxes,
        cardData: state.paymentCheckout.cardData,
        finished: state.withdrawWizard.finished
    }
};

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConfirmStep)));
