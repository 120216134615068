import React from "react";
//import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { getLottoState, makeGetBonuses } from "../store/selectors/lottoData";

import BetSlip from "../components/BetSlip";
import TicketsDetails from "../components/TicketsDetails";
import evBus from "../utils/evbus";

function BetSlipLayout({ lottoTicket, selectError, errorMessage }) {

	const [tdState, setTdState] = React.useState({
		open: false,
		eventName: ""
	});

	const handleOpenTicketDetails = ({ event_name }) => {
		setTdState({
			open: true,
			eventName: event_name
		});
	};
	const handleCloseTicketDetails = () => {
		setTdState({
			open: false,
			eventName: ""
		});
	};

	React.useEffect(() => {
		evBus.on("OPEN_TICKET_DETAILS", handleOpenTicketDetails);
		return () => {
			evBus.remove("OPEN_TICKET_DETAILS", handleOpenTicketDetails);
		};
	}, []);

	return (
		<React.Fragment>
			<BetSlip
				selectError={selectError}
				errorMessage={errorMessage}
				lottoTicket={lottoTicket}
			/>
			{tdState.open && tdState.eventName && <TicketsDetails open={tdState.open} onClose={handleCloseTicketDetails} eventName={tdState.eventName} />}
		</React.Fragment>
	);
}

BetSlipLayout.propTypes = {};

BetSlipLayout.defaultProps = {};

const mapStateToProps = (state, props) => {
	const bst = getLottoState(state);

	return {
		lottoTicket: bst.betsSlip.lottoTicket,
		selectError: bst.betsSlip.selectError
	};
};

export default connect(mapStateToProps)(BetSlipLayout);
