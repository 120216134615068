/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 19/12/2018
 * Time: 17:55
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as stateActions from './../../../../store/actions/index';
import "./LanguageSwitcher.scss";
import Icons from "./../../../../assets/Icons/Icons";
import LanguageDetector from "i18next-browser-languagedetector";
import FormControl from "@material-ui/core/FormControl";
import Swipe from 'react-easy-swipe';
import {withTranslation} from "react-i18next";
import Utils from "../../../../utils/Utils";

class LanguageSwitcher extends Component {

    state = {
        languages: [],
        className: '',
    };

    componentDidMount() {
        this.setLanguage(true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentLanguage !== this.props.currentLanguage) {

            this._setCookie('language', this.props.currentLanguage, 365);
            this.props.i18n.changeLanguage(this.props.currentLanguage);
            this.setLanguageList(true);
        }
    }

    _setCookie(name,value,days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }

    _getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    setLanguage() {
        if (this.props.currentLanguage) {
            // this._setCookie('language', this.props.currentLanguage, 365);
            // this.props.i18n.changeLanguage(this.props.currentLanguage);
        } else {
            // console.log("********************************");

            let language = this._getCookie('language');
            if (language === null) {
                this.props.i18n.changeLanguage(process.env.TRANSLATE.defaultLanguage);
                return this.clickHandler(process.env.TRANSLATE.defaultLanguage); // force default language
                let langDetector = new LanguageDetector();
                let orderDetector = langDetector.options.order;
                for (let i=0; i<orderDetector.length; i++) {
                    let languages = undefined;
                    try {
                        languages = langDetector.detectors[orderDetector[i]].lookup();
                    } catch (e) {
                        // detector is not supported
                    }
                    // console.log("[languages]",languages,process.env.TRANSLATE);
                    if (typeof languages !== "undefined") {
                        for(let lng = 0; lng < languages.length; lng++) {
                            if (languages[lng].length < 5) {
                                language = languages[lng];

                                break;
                            }
                        }
                        break;
                    }
                }
            }


            if (typeof language !== "undefined") {
                let found = false;
                for (let i = 0;i < process.env.TRANSLATE.languages.length; i++) {
                    if (process.env.TRANSLATE.languages[i] === language) {
                        found = true;
                        this.clickHandler(language);
                        break;
                    }
                }

                if (!found) {
                    this.clickHandler(process.env.TRANSLATE.defaultLanguage);
                }

            }
        }
    }

    swipeRightHandler() {
        this.clickHandler(this.props.currentLanguage, undefined, 'swipe-right');
        this.props.setAccountLanguage(this.props.currentLanguage);
    }
    swipeLeftHandler() {
        this.clickHandler(this.props.currentLanguage);
        this.props.setAccountLanguage(this.props.currentLanguage);
    }

    clickHandler(event, lang,className) {

        let language = false;

        if ((typeof event).toLowerCase() === 'string') {
            language = event;
            if (lang === 'change') {
                this.props.setAccountLanguage(language === 'ro' ? 'en' : 'ro');
            }
        }

        if (language === this.props.currentLanguage) {
            language = this.state.languages[0]
        }
        if ( !language ) {

            if(typeof event.target === "undefined") {
                return;
            } else {

                language = event.target.value;

            }
        }

        this.setState({
            ...this.state,
            className: className ? className : 'swipe-left'
        });
        if (this.state.languages.length) {
            Utils.delayedFunction('updateLanguageList', this.setLanguageList.bind(this,true),300);
        } else {
            Utils.delayedFunction('updateLanguageList', this.setLanguageList.bind(this,true),5500);
        }
        this._setCookie('language', language, 365);
        this.props.changeLanguage(language);
    }

    setLanguageList(force) {
        let languages = process.env.TRANSLATE.languages;
        let current = this.props.currentLanguage;
        let newList = [];
        for (let i = 0; i < languages.length; i++) {
            if (languages[i] === current) {
                switch (i) {
                    case 0:

                        newList = [languages[languages.length - 1],languages[i],languages[i+1]];
                        break;

                    case languages.length - 1:
                        newList = [languages[i-1],languages[i],languages[0]];
                        break;
                    default:
                        newList = [languages[i-1],languages[i],languages[i+1]];
                        break;
                }
            }
        }

        if (this.state.languages !== newList || force) {
            if (!force) {
                Utils.delayedFunction('updateLanguageList', this.setLanguageList.bind(this,true),350);
                return newList;
            }
            this.setState({
                ...this.state,
                languages: newList,
                className: ''
            })
        }

        return newList;
    }

    getLanguageList() {

        if (!this.state.languages.length) {
            return this.setLanguageList();
        }

        return this.state.languages;
    }

    getFlags() {

        let comp = [];
        let active = [];
        let languages = this.getLanguageList();

        for (let i = 0; i < languages.length; i++) {

            let iconName = languages[i] + 'Flag';
            let icon = Icons.get(iconName,iconName);

            active.push(
                <div value={languages[i]} onClick={this.clickHandler.bind(this,languages[i],'change')} key={iconName + i}>
                    {icon} <span>{process.env.TRANSLATE.languageNames[languages[i]]}</span>
                </div>
            );

        }
        return active.concat(comp);
    }

    render() {
        if(this.props.initializationOnly === true) {
            return (<span></span>);
        }

        let flags = this.getFlags();

        return (
            <FormControl className={'language-container'}>
                <Swipe onSwipeLeft={this.swipeLeftHandler.bind(this)} onSwipeRight={this.swipeRightHandler.bind(this)}>
                    <div className={'language-select ' + this.state.className} >
                        {flags}
                    </div>
                </Swipe>
            </FormControl>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeLanguage: (language) => dispatch(stateActions.changeLanguage(language)),
        setAccountLanguage: (language) => dispatch(stateActions.changeAccountLanguage(language))
    };
};

const mapStateToProps = state => {
    return {
        currentLanguage: state.application.language,
    }
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher));


