import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import "./dialog-nickname.scss";
import Logo from "../assets/dialog-nickname-logo@3x.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogNickname = (props) => {
  const { open, onClose, t } = props;

  const [nick, setNick] = React.useState("");
  const handleTextChange = (e) => {
    setNick(e.target.value);
  };

  // don't insert anything into the DOM if we're not opened
  if (!open) return null;

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      className="missions-tournaments-dialog-nickname">
      <div className="mt-body">
        <div className="mt-logo">
          <img src={Logo} alt="" />
        </div>
        <div className="mt-title">{t("Choose a public name")}</div>
        <div className="mt-input">
          <div className="mt-icon"><PersonOutlineOutlinedIcon /></div>
          <div className="mt-input-field">
            <input value={nick} onChange={handleTextChange} type="text" placeholder={t("Public name")} />
          </div>
        </div>
        <div className="mt-subtitle">
          <div className="mt-icon">i</div>
          <div className="mt-text">{t("Anyone can see this name. It takes up to 48 hours until we approve it and it can become public.")}</div>
        </div>

        <div className="mt-nickname">WINNER_123456</div>
        <div className="mt-nickname-info">{t("YOUR TEMPORARY NAME")}</div>
        <div className="mt-button" onClick={onClose}>{t("Continue")}</div>
        <div className="mt-footer-text" onClick={onClose}>{t("I'll change it later")}</div>
      </div>
    </Dialog>
  );
}

/*
  "Choose a public name": "Alege-ti un nume public",
  "Anyone can see this name. It takes up to 48 hours until we approve it and it can become public.": "Oricine poate vedea acest nume. Dureaza pana la 48h pana il vom aproba si devine public.",
  "Nickname not approved yet.": "Numele public ales nu este inca aprobat.",
  "YOUR TEMPORARY NAME": "NUMELE TAU TEMPORAR",
  "Public name": "Nume public",
  "I'll change it later": "Il schimb mai tarziu",
*/

const mapStateToProps = (state, props) => {
  return {
  };
};

export default withTranslation()(connect(mapStateToProps)(DialogNickname));