import {put, takeEvery, takeLatest} from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import {druid} from "../actions/actionTypes";
import ExpiringLocalStorage from "../../utils/ExpiringLocalStorage";

export function* scanDocument(action) {
    const axios = ClientAPI.getInstance();
    // let formattedFiles = formatFiles(action.files);
    try {
        let uploadForm = new FormData();
        Object.keys(action.files).forEach((attrName) => {
            uploadForm.append("files[]", action.files[attrName]);
        });

        let paid = yield ExpiringLocalStorage.get('paid');
        uploadForm.append('paid', paid)

        const response = yield axios({
            url: '/api/documents/scan',
            method: 'post',
            data: uploadForm
        });

        if (!response) {
            throw new Error(`[ERROR] DRUID SCAN response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] DRUID SCAN  response has no 'result' property`);
        }

        yield put(actions.scanComplete(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

const formatFiles = (files) => {
    let formattedFiles = [];

    files.forEach((fileElement, index) => {
        // let fileElement = files[attribute];
        let file = {
            index: index,
            name: fileElement.name,
            size: fileElement.size,
            type: fileElement.type,
        };

        formattedFiles.push(file);
    });

    return formattedFiles;
};

export default function* watchFreeBetsSaga() {
    yield takeEvery(druid.SCAN_DOCUMENT, scanDocument);
}