// Libs
import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as storeActions from "./../store/actions";
import { Transition, animated } from 'react-spring/renderprops';
import * as easings from "d3-ease";

// Main Pages
import Home from "../components/Home/Home";
import Footer from "../components/Footer/Footer";
import Promotions from "./../components/Promotions/Promotions";

// Customer Support
//import ComplaintForm from "../components/CustomerSupport/ComplaintForm/ComplaintForm";
import TermsAndConditions from "../components/CustomerSupport/TermsAndConditions/TermsAndConditions";
import AboutUs from "../components/CustomerSupport/AboutUs/AboutUs";
import ResponsibleGaming from "../components/CustomerSupport/ResponsibleGaming/ResponsibleGaming";
import FAQ from "../components/CustomerSupport/FAQ/FAQ";
import GDPR from "../components/CustomerSupport/GDPR/GDPR";
import GameRules from "../components/CustomerSupport/GameRules/GameRules";
import PaymentMethods from "../components/CustomerSupport/PaymentMethods/PaymentMethods";

// Profile
import Profile from "../components/Profile/Profile";
import Documents from "../components/Profile/ProfileActions/Documents/Documents";
import ChangePassword from "../components/Profile/ProfileActions/ChangePassword/ChangePassword";
import ChangePhoneNumber from "../components/Profile/ProfileActions/ChangePhoneNumber/ChangePhoneNumber";
import ProfilePreferences from "../components/Profile/ProfileActions/ProfilePreferences/ProfilePreferences";
import Limits from "../components/Profile/ResponsibleGaming/Limits/Limits";
import Selfexclusion from "../components/Profile/ResponsibleGaming/Selfexclusion/Selfexclusion";
import TransactionsHistory from "../components/Profile/TransactionsHistory/TransactionsHistory";
import PendingWithdrawals from "../components/Profile/PendingWithdrawals/PendingWithdrawals";
import WalletBonus from "../components/Profile/WalletBonus/WalletBonus";
import ProfileMenu from "../components/Profile/ProfileMenu/ProfileMenu";

// Authentication
import Login from "../components/Login/Login";
import ResetPassword from "./../components/ResetPassword/ResetPassword";
import Register from "../components/Register/Register";
// import RegisterV2 from "../components/Register/RegisterV2/RegisterV2";

// Games
import CasinoGames from "../components/CasinoGames/CasinoGames";
import Jackpot from "../components/Jackpot/Jackpot";
import Collection from "../components/Collections/Collection/Collection";

// Coming Soon
import Betting from "./../components/ComingSoon/Betting/Betting";
import LiveCasinoComingSoon from "./../components/ComingSoon/LiveCasino/LiveCasino";
import PiratesTreasure from "./../components/ComingSoon/PiratesTreasure/PiratesTreasure";
import LiveCasino from "../live-casino/layout/LayoutMobile";

// Others
import Locations from "../components/GlobalWidgets/Both/Locations/Locations";
import Deposit from "../components/Deposit/Deposit";
import Payment from "../components/Deposit/Payment/Payment";
import WithdrawalCheckout from "../components/GlobalWidgets/LoggedIn/Withdraw/Steps/WithdrawalCheckout/WithdrawalCheckout.js";
import Withdraw from "../components/GlobalWidgets/LoggedIn/Withdraw/Withdraw";

import Token from "../components/Register/Token/Token";

import VirtualMain from "../virtuals/views/MainPageMob";
import VirtualGame from "../virtuals/views/GamePageMob";
import DepositFinal from "../components/Deposit/DepositFinal";
import ClaimBonus from "../components/ClaimBonus/ClaimBonus";

import NotificationsPreference from "../components/Notifications/NotificationsPreferenceMobile";

import Wheel from "../wheel/views/main-view";
import WinterTime from "../promo/views/main-view";
import PromoCalendar from "../promo-calendar/views/main-view";

import Raffle from "../raffle/views/main-view";

import DepositTicket from "../deposit-ticket/views/main-view";

import GamePage from "../tournaments-missions/views/game-page";

import MomentumTestPlayground from "../momentum/views/test";

class PageContent extends Component {
    componentDidUpdate(prevProps) {
        this.checkRoute();
    }

    componentDidMount() {
        this.checkRoute();
    }

    checkRoute() {
        if (!this.props.hasOwnProperty("location")) {
            return;
        }

        let routeLocation = this.props.location;
        if (!routeLocation.hasOwnProperty('pathname')) {
            return;
        }
        this.props.onRouteChanged(routeLocation.pathname);
    }

    render() {
        const routes = [];
        routes.push(<Route path="/test-game-page/:gameId" exact key="test-game-page" component={GamePage} />);
        routes.push(<Route path="/test-momentum-game-page/:gameId" exact key="test-momentum-game-page" component={MomentumTestPlayground} />);

        let defaultRoute = <Route path={['/', '/home']} exact key={'route_default'} component={Home} />;
        routes.push(defaultRoute);

        routes.push(<Route path="/test-game-page/:gameId" exact key="test-game-page" component={GamePage} />);

        if (window.config && window.config.depositTicketEnabled === "1") {
            routes.push(<Route path={['/deposit-ticket']} exact key={'route_deposit_ticket'} component={DepositTicket} />);
        }

        if (window.config && window.config.wheelEnabled === "1") {
            routes.push(<Route path={['/wheel']} exact key={'route_wheel'} component={Wheel} />);
        }

        let routeFun = <Route path={['/fun']} exact key={'route_fun'}>
            <Redirect to="/winner-fun" />
        </Route>;
        routes.push(routeFun);

        if (window.config && window.config.raffleEnabled === "1") {
            routes.push(<Route path={[window.config.raffleUrl]} exact key={'route_raffle_page'} component={Raffle} />);
        }

        if (window.config && window.config.promoEnabled === "1") {
            routes.push(<Route path={['/winter-time']} exact key={'route_winter_time'} component={WinterTime} />);
        }

        if (window.config && window.config.promoCalendarEnabled === "1") {
            routes.push(<Route path={['/promo-calendar']} exact key={'route_promo_calendar'} component={PromoCalendar} />);
        }

        // if (!(window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1"))) {
        let bettingRoute = <Route path={'/pariuri'} exact key={'route_betting'} component={Betting} />;
        routes.push(bettingRoute);

        if (window.config && window.config.liveCasinoEnabled === "1") {
            let liveCasinoRoute = <Route path={'/cazino_live'} key={'route_live_casino'} component={LiveCasino} />;
            routes.push(liveCasinoRoute);
        } else {
            let liveCasinoRoute = <Route path={'/cazino_live'} exact key={'route_live_casino'} component={LiveCasinoComingSoon} />;
            routes.push(liveCasinoRoute);
        }

        let piratesTreasureRoute = <Route path={['/cazino/comoara_piratului', 'comoara_piratului']} exact key={'route_pirates_treasure'} component={PiratesTreasure} />;
        routes.push(piratesTreasureRoute);

        if (!window.config.lobbyEnabled || window.config.lobbyEnabled !== "1") {
            let casinoGamesRoute = <Route path={['/cazino/jocuri', '/cazino', '/table-games']} exact key={'route_casino'} component={CasinoGames} />;
            routes.push(casinoGamesRoute);

            let jackpotRoute = <Route path={'/cazino/jackpot'} exact key={'route_jackpot'} component={Jackpot} />;
            routes.push(jackpotRoute);

            let collectionRoute = <Route path={['/cazino/collection', '/cazino/collection/:id?']} exact key={'route_collection'} component={Collection} />;
            routes.push(collectionRoute);

            let promotionsRoute = <Route path={'/cazino/promotii'} exact key={'route_promotions'} component={Promotions} />;
            routes.push(promotionsRoute);

            let promotionsWithIdRoute = <Route path={'/cazino/promotii/:id/:name'} exact key={'route_promotions_with_id'} component={Promotions} />;
            routes.push(promotionsWithIdRoute);
        }

        // let complaintFormRoute = <Route path={'/customer-support/complaint-form'} exact key={'route_complaintForm'} component={ComplaintForm} />;
        // routes.push(complaintFormRoute);

        let termsAndConditionsRoute = <Route path={'/customer-support/terms&conditions'} exact key={'route_termsAndConditions'} component={TermsAndConditions} />;
        routes.push(termsAndConditionsRoute);

        let aboutUsRoute = <Route path={'/customer-support/about-us'} exact key={'route_aboutUs'} component={AboutUs} />;
        routes.push(aboutUsRoute);

        let faqRoute = <Route path={'/customer-support/faq'} exact key={'route_faq'} component={FAQ} />;
        routes.push(faqRoute);

        let gdprRoute = <Route path={'/customer-support/politicadeconfidentialitate'} exact key={'route_GDPR'} component={GDPR} />;
        routes.push(gdprRoute);

        let gameRulesRoute = <Route path={'/customer-support/game-rules'} exact key={'route_gameRules'} component={GameRules} />;
        routes.push(gameRulesRoute);

        let responsibleGaming = <Route path={'/customer-support/responsible-gaming'} exact key={'route_responsibleGaming'} component={ResponsibleGaming} />;
        routes.push(responsibleGaming);

        let paymentMethods = <Route path={'/customer-support/payment-methods'} exact key={'route_paymentMethods'} component={PaymentMethods} />;
        routes.push(paymentMethods);

        let withdrawMethods = <Route path={'/customer-support/withdraw-methods'} exact key={'route_withdrawMethods'} component={PaymentMethods} />;
        routes.push(withdrawMethods);

        let profileRoute = <Route path={'/profile/account'} exact key={'route_profile'} component={Profile} />;
        routes.push(profileRoute);

        let profilePreferencesRoute = <Route path={'/profile/preferences'} exact key={'route_profile_preferences'} component={ProfilePreferences} />;
        routes.push(profilePreferencesRoute);

        let documentsRoute = <Route path={'/profile/documents'} exact key={'route_documents'} component={Documents} />;
        routes.push(documentsRoute);

        let cardManagement = <Route path={'/profile/cards'} exact key={'route_cardManagement'} component={Documents} />;
        routes.push(cardManagement);

        let notificationsRoute = <Route path={'/profile/notifications'} exact key={'route_wallet-bonus'} render={() => <NotificationsPreference />} />;
        routes.push(notificationsRoute);

        let changePasswordRoute = <Route path={'/profile/change-password'} exact key={'route_changePassword'} component={ChangePassword} />;
        routes.push(changePasswordRoute);

        let changePhoneNumberRoute = <Route path={'/profile/change-phone-number'} exact key={'route_changePhoneNumber'} component={ChangePhoneNumber} />;
        routes.push(changePhoneNumberRoute);

        let limitsRoute = <Route path={'/profile/responsibly'} exact key={'route_limits'} component={Limits} />;
        routes.push(limitsRoute);

        let selfexclusionRoute = <Route path={'/profile/take-a-break'} exact key={'route_selfexclusion'} component={Selfexclusion} />;
        routes.push(selfexclusionRoute);

        let transactionsHistoryRoute = <Route path={'/profile/transactions'} exact key={'route_transactions'} component={TransactionsHistory} />;
        routes.push(transactionsHistoryRoute);

        let pendingWithdrawalsRoute = <Route path={'/profile/pending-withdrawals'} exact key={'route_pendingWithdrawals'} component={PendingWithdrawals} />;
        routes.push(pendingWithdrawalsRoute);

        let walletBonusRoute = <Route path={'/profile/wallet'} exact key={'route_wallet-bonus'} render={() => <WalletBonus />} />;
        routes.push(walletBonusRoute);

        let loginRoute = <Route path={'/connect'} exact key={'route_login'} component={Login} />;
        routes.push(loginRoute);
        // if (Utils.getQueryParam('winner_fun')) {
        //
        //     let registerRoute = <Route path={'/register'} exact key={'route_register'} component={Register} />;
        //     routes.push(registerRoute);
        // } else {
        let registerRoute = <Route path={'/register'} exact key={'route_register'} component={Register} />;
        routes.push(registerRoute);
        // }

        let resetPasswordRoute = <Route path={['/reset-password', '/reset-password/:token?']} exact key={'route_reset_password'} component={ResetPassword} />;
        routes.push(resetPasswordRoute);

        let claimBonusRoute = <Route path={['/claim-bonus', '/claim-bonus/:bonus?']} exact key={'route_claim_bonus'} component={ClaimBonus} />;
        routes.push(claimBonusRoute);

        let depositRoute = <Route path={'/deposit'} exact key={'deposit_page'} component={Deposit} />;
        routes.push(depositRoute);

        if (window?.config?.okto_enabled === '1') {
            let depositOktoRoute = <Route path={'/deposit-oktocash'} exact key={'deposit_page'} component={() => <Deposit paymentMethod={4} />} />;
            routes.push(depositOktoRoute);
        }

        let depositFinalRoute = <Route path={'/deposit-final'} exact key={'deposit_final_page'} component={DepositFinal} />;
        routes.push(depositFinalRoute);

        let withdrawalRoute = <Route path={['/withdraw/success', '/withdraw/pending', '/withdraw/failure', '/withdraw/cancel']} exact key={'withdraw_page'} component={WithdrawalCheckout} />;
        routes.push(withdrawalRoute);

        let paymentRoute = <Route path={['/payment/success', '/payment/pending', '/payment/failure', '/payment/cancel']} exact key={'payment_page'} component={Payment} />;
        routes.push(paymentRoute);

        if (window.config?.hide_agencies !== '1' && window.config?.okto_enabled !== '1') {
            let locationsRoute = <Route path={'/locations'} exact key={'locations_page'} component={Locations} />;
            routes.push(locationsRoute);
        }

        let withdrawRoute = <Route path={'/withdraw'} exact key={'withdraw_page'} component={Withdraw} />;
        routes.push(withdrawRoute);

        let profileMenuPage = <Route path={'/profile/menu'} exact key={'route_profile_menu'} component={ProfileMenu} />;
        routes.push(profileMenuPage);

        let routeEmailValidation = <Route path={['/token_email_validation', `/token_email_validation/:token`]} exact key={'route_token_email_validation'} component={Token} />;
        routes.push(routeEmailValidation);

        if (window.config && window.config.virtualsEnabled === "1") {
            let routeVirtualMain = <Route path={'/virtuale'} exact key={'virtual-games'} component={VirtualMain} />;
            routes.push(routeVirtualMain);

            let routeVirtualGame = <Route path={'/virtuale/jocuri/:id'} exact key={'virtual-game'} component={VirtualGame} />;
            routes.push(routeVirtualGame);
        }

        // console.log(`[PAGE] CONTENT rendered!`);
        // console.log(this.props);

        let self = this;

        let hasFooter = false;

        if (this.props.location.pathname) {

            let footerPages = [
                'cazino/jocuri',
                'table-games',
                'customer-support',
                'home'
            ];

            footerPages.map(page => {
                if (this.props.location.pathname.search(page) > 0 || this.props.location.pathname === '/') {
                    hasFooter = true;
                }
                return true;
            })
        }

        return (
            <animated.div className={`pageContent`}>
                <Transition config={{ easing: easings.easeBackOut, duration: 800 }}
                    native
                    items={this.props.location}
                    from={{ life: '0%', opacity: 0, top: Math.ceil(window.innerHeight / 5) }}
                    enter={[{ opacity: 1, top: 0 }, { life: '100%' }]}
                    leave={
                        item => async (next, cancel) => {
                            await next({ life: '0%' })
                            await next([{ opacity: 0 }, { top: Math.ceil(window.innerHeight / 5) }])
                        }
                    }
                    // leave={{ opacity: 0, life: '0%', top: 100 }}
                    key={this.props.location.pathname}>
                    {loc => function (style) {
                        return (
                            <animated.div
                                style={style}
                                className={`animatedPageContent ${self.props.pageClassName}`}>
                                <Switch location={loc}>
                                    {routes}
                                </Switch>
                                {hasFooter && <Footer />}
                            </animated.div>
                        )
                    }}
                </Transition>
            </animated.div>
        );
    }
};

const mapStateToProps = state => {
    return {
        pageClassName: state.application.pageClassName,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRouteChanged: (newRoute) => dispatch(storeActions.appRouteChanged(newRoute)),
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(PageContent)));
