import React from "react";
import { withTranslation } from 'react-i18next';

import "./objective.scss";
import Type1 from "../assets/type1@3x.png";
import Type2 from "../assets/type2@3x.png";
import Type3 from "../assets/type3@3x.png";

const Objective = (props) => {
  const { objective, t } = props;

  let imgSrc;
  switch (objective.type) {
    case "1":
      imgSrc = Type1;
      break;
    case "2":
      imgSrc = Type2;
      break;

    case "3":
      imgSrc = Type3;
      break;
    default:
      imgSrc = "";
  }

  const progressStyle = {};
  if (objective.progress !== objective.total) {
    progressStyle.width = `${(objective.progress * 100) / objective.total}%`;
  }


  return (
    <div className="missions-tournaments-objective">
      <div className="mt-logo">
        <img src={imgSrc} alt="" />
      </div>
      <div className="mt-content">
        <div className="mt-title" dangerouslySetInnerHTML={{ __html: objective.title }}></div>
        <div className="mt-progress-text-wrapper">
          <div className="mt-progress-text">{t("Progress")}</div>
          {objective.progress !== objective.total && <div className="mt-progress-value">
            <span className="mt-on">{objective.progress}</span>&nbsp;/&nbsp;{objective.total}
            {!!objective.currency && <span>&nbsp;{objective.currency}</span>}
          </div>}
          {objective.progress === objective.total && <div className="mt-progress-value mt-done">
            {t("finalized")}
          </div>}
        </div>
        <div className="mt-progress-bar-wrapper">
          <div style={progressStyle} className={`mt-progress-bar ${objective.progress >= objective.total ? 'mt-done' : ''}`}></div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Objective);