import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import Cookies from "../GlobalWidgets/Both/Cookies/Cookies";
import './Footer.scss';
import LanguageSwitcher from "../GlobalWidgets/Both/LanguageSwitcher/LanguageSwitcher";
import Translate from '../../utils/Translate';
import Icons from '../../assets/Icons/Icons';
import SoundToggle from "../../sounds-toggle/component/SoundToggle";

const Footer = (props) => {

    const [cookies, setCookies] = useState(false);

    const handleCookies = () => {
        setCookies(!cookies);
    }

    const handleTermsAndConditions = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/termsAndConditions/' + i18next.language + '/Terms%20and%20Conditions.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
    }

    const handleBonusRules = () => {
        let url = window.location.protocol + "//" + window.config.cdn_address + '/documents/bonusRules/Regulament%20General%20pentru%20Bonusuri.pdf';
        let win = window.open(url, '_blank');
        if(win !== null){
            win.focus();
        }
    }

    // helper for poedit scanner
    const t = (text) => {
        return text;
    }

    const handlePrivacyPolicy = () => {

        const privacyPolicyUrl = window.location.protocol + "//" + window.config.cdn_address + '/documents/privacyPolicy/' + i18next.language + '/Privacy%20Policy.pdf';

        if (window.config.cordova) {
            window.cordova.InAppBrowser.open(privacyPolicyUrl, '_system');
            return;
        }

        let win = window.open(privacyPolicyUrl, '_blank');
        if (win !== null) {
            win.focus();
        }

    }

    const handleInfoCons = () => {
        const infoConsUrl = 'https://micros-p.b-cdn.net/Placheta%20numere%20utile%20ONJN-1717407665162.jpg';

        if (window.config.cordova) {
            window.cordova.InAppBrowser.open(infoConsUrl, '_system');
            return;
        }

        let win = window.open(infoConsUrl, '_blank');
        if (win !== null) {
            win.focus();
        }
    }


    return (
        <div className={"Footer"}>
            <div className="FooterContent">
                <div className="FooterHeader">
                    <div className="footer-language">
                        <LanguageSwitcher />
                    </div>
                    <SoundToggle />
                    <img className="FooterLogo" src={`${window.config.front_url}/img/mobile/footer/powered_newton.png`} alt="powered_newton" />
                </div>

                <div className="Links">
                    <div>
                        <h3><Translate text={t('Legal')} /></h3>
                        <ul>
                            <li onClick={() => props.history.push("/customer-support/about-us")}><Translate text={t('About us')} /></li>
                            <li onClick={() => handlePrivacyPolicy()}><Translate text={t('Privacy Policy')} /></li>
                            <li onClick={() => handleTermsAndConditions()}><Translate text={t('Terms & Conditions')} /></li>
                            <li onClick={() => handleBonusRules()}><Translate text={t('Bonus rules')} /></li>
                            <li onClick={() => handleCookies()}><Translate text={t('Cookies')} /></li>
                            <li onClick={() => props.history.push("/customer-support/game-rules")}><Translate text={t('Game Rules')} /></li>
                            <li onClick={() => handleInfoCons()}>Infocons</li>
                        </ul>
                    </div>
                    <div>
                        <h3><Translate text={t('Support')} /></h3>
                        <ul>
                            <li onClick={() => props.history.push("/customer-support/faq")}><Translate text={t('FAQ')} /></li>
                            {/*<li onClick={() => props.history.push("/customer-support/complaint-form")}><Translate text={t('Have an issue?')} /></li>*/}
                            <li onClick={() => props.history.push("/customer-support/about-us")}><Translate text={t('Customer support')} /></li>
                            <li onClick={() => props.history.push("/customer-support/responsible-gaming")}><Translate text={t('Responsible Gaming')} /></li>
                            {window.config && window.config.hide_agencies !== '1' && window.config.okto_enabled !== '1' && <li onClick={() => props.history.push("/locations")}><Translate text={t('Locations')} /></li>}
                            {window.config && window.config.okto_enabled === '1' && <li onClick={() => props.history.push("/deposit-oktocash")}><Translate text={t('Cash Deposit')} /></li>}
                        </ul>
                    </div>
                </div>
                <div className="PaymentMethods">
                    <h3>{props.t('Payment Methods')}</h3>
                    <div className="IconsContainer">
                        {Icons.get('maestro', 'paymentIcon')}
                        {Icons.get('masterCard', 'paymentIcon')}
                        {Icons.get('visa', 'paymentIcon')}
                        {window.config && window.config.hide_agencies !== '1'  && window.config.okto_enabled !== '1' && <>
                            {Icons.get('casinoIcon', 'paymentIcon')}
                        </>}
                        {window.config && window.config.okto_enabled === '1' && <>
                            {Icons.get('oktocash', 'paymentIcon')}
                        </>}
                        {window.config && window.config.aircash_enabled === '1' && <>
                            {Icons.get('abon', 'paymentIcon')}
                        </>}
                        {window.config && window.config.applePayEnabled === '1' && <>
                            {Icons.get('applePay', 'paymentIcon')}
                        </>}
                    </div>
                </div>
                <div className="Images">
                    <img src={`${window.config.front_url}/img/mobile/footer/egt_logo.png`} alt="egt_logo" />
                    <a href="http://onjn.gov.ro/" target="_blank" rel="noopener noreferrer">
                        <img src={`${window.config.front_url}/img/mobile/footer/onjn.png`} alt="onjn" />
                    </a>
                    <img src={`${window.config.front_url}/img/mobile/footer/18years.png`} alt="18years" />
                    <p><Translate text={t('Only for players over 18 years old')} /><br /><Translate text={t('Gambling may create addiction. Play Responsibly!')} /></p>
                </div>
                <div className="Images ANPC">
                    <div className="text">
                        <div>ANPC:</div>
                        <div>Solutionarea litigiilor</div>
                    </div>
                    <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">
                        <img src={`${window.config.front_url}/img/mobile/footer/SOL.png`} alt="SOL" />
                    </a>
                    <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener noreferrer">
                        <img src={`${window.config.front_url}/img/mobile/footer/pictograma-anpc.png`} alt="SAL" />
                    </a>
                </div>
                <div className="Text">
                    <p>
                        <Translate text={t("This website is operated by ${NOG_company_name}, a Romanian legal entity, registered with the Trade Register under no. ${NOG_company_cui}, and having its registered office located in ${NOG_company_address}. ${NOG_company_name} holds license no. ${NOG_license_number}, valid for 10 years from ${NOG_license_date_start} until ${NOG_license_date_end}, granted by the ${openTagOnjn}National Gambling Office${closeTag} through decision no. ${openTagDecision1}${NOG_decision1}${closeTag}, and the authorization to operate remote gambling obtained through decision no. ${openTagDecision2}${NOG_decision2}${closeTag}. Please be advised that gambling activity may be prohibited in the jurisdiction in which you are located. It is your responsibility to comply with the applicable legislation. Gambling activity can be addictive and may also have an impact on your financial situation. Please gamble responsibly!")} placeholder={{
                            NOG_company_name: window.config.NOG_company_name ?? process.env.CFW.company.name,
                            NOG_company_cui: window.config.NOG_company_cui ?? process.env.CFW.company.register_number,
                            NOG_company_address: window.config.NOG_company_address ?? process.env.CFW.company.address,
                            NOG_country: window.config.NOG_country ?? '',
                            openTagOnjn: '<a href="http://onjn.gov.ro/" target="_blank">',
                            openTagDecision1: `<a href="${window.config.NOG_decision_url1}" target="_blank">`,
                            openTagDecision2: `<a href="${window.config.NOG_decision_url2}" target="_blank">`,
                            closeTag: '</a>',
                            NOG_license_number: window.config.NOG_license_number,
                            NOG_license_date_start: window.config.NOG_license_date_start,
                            NOG_license_date_end: window.config.NOG_license_date_end,
                            NOG_decision1: window.config.NOG_decision1,
                            NOG_decision2: window.config.NOG_decision2,
                        }} />
                    </p>
                </div>
                <div id="FooterCookies">
                    {cookies && <Cookies show close={() => handleCookies()} />}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(withRouter(Footer));
