import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../../store/actions";
import Moment from "react-moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import './Game.scss';
import Icons from "../../../../assets/Icons/Icons";
import Button from "@material-ui/core/Button";

const REQUEST_TYPE = 1; // Spins

class Game extends Component {

    componentDidMount() {
        if(this.props.auth.auth_type !== "user"){
            this.props.history.push("/");
        }else{
            this.props.onRequestTransactionsHistory(REQUEST_TYPE);
        }
    }

    state = {
        exportDisabled: false,
    }

    formatCurrency = (value) => {
        value = parseFloat(value);
        if (isNaN(value)) return 0;
        return (
            value
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              .replace(',00', '')
          )
    }
    downloadCsv = () => {
        this.setState({
            ...this.state,
            exportDisabled: true,
        });
        this.props.downloadCSV();
    };
    render() {

        let gameList = <CircularProgress className={'Loading'} />;

        if(this.props.transactions.spins !== null && this.props.transactions.spins.length > 0){
            gameList = this.props.transactions.spins.map((game, index) => {

                return (
                    <div key={index} className={"SingleGame"}>
                        <div className="GameTime">
                            <span><Moment format={"DD/MM/YYYY"} unix>{game.spin_timestamp}</Moment></span>
                            <span><Moment format={"HH:mm"} unix>{game.spin_timestamp}</Moment></span>
                        </div>
                        <div className="GameDescription">
                            <p>{this.props.t(game.game)}</p>
                            <div>
                                <p className={"GameBet"}>{game.is_free === '1' ? "FREE SPIN" : this.formatCurrency(game.bet) + " RON"}</p>
                                <p className={"GameResult"}>
                                    {" | " + this.props.t("Result")}
                                    {
                                        game.win > 0 ? 
                                        <span className={"ResultAmount"}>{" + " + this.formatCurrency(game.win) + " RON"}</span> :
                                        <span>{" " + this.props.t("without winning")}</span>
        
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                );
            });
        }else if(this.props.transactions.show){
            gameList = <h5 className={"NoGames"}>{this.props.t("There is no games history at this moment")}.</h5>;
        }
        
        return (
            <div className={"GameScreen"}>
                {gameList}
                <Button className={'export'} onClick={ this.downloadCsv.bind(this)} disabled={this.state.exportDisabled}>
                    {Icons.get("historyCsvDownload", "historyCsvDownload")} {this.props.t("Download History for last 90 days")}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.transactions,
        auth: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestTransactionsHistory: (requestType) => dispatch(storeActions.requestTransactionsHistory(requestType)),
        downloadCSV: () => dispatch(storeActions.downloadCSV(1))
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Game)));
