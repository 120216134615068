import React from 'react';
import { connect } from 'react-redux';

import "./SoundToggle.scss";
import { toggleSounds } from "../store/actions/config";
import { ReactComponent as SoundOnSVG } from "../assets/sound-on.svg";
import { ReactComponent as SoundOffSVG } from "../assets/sound-off.svg";

const SoundToggle = (props) => {
  const { soundEnabled, toggleSounds } = props;

  const onToggleSounds = () => {
    toggleSounds()
  };

  let icon = <SoundOnSVG />;
  if (!soundEnabled) icon = <SoundOffSVG />;

  return <div id="sound-toggle" onClick={onToggleSounds}>
    {icon}
  </div>;
};

const mapStateToProps = state => {
  return {
    soundEnabled: state.sounds.config.enabled
  };
};

const mapActions = {
  toggleSounds
};

export default connect(mapStateToProps, mapActions)(SoundToggle);