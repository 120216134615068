import React from 'react';
import SwipeableViews from "react-swipeable-views";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import "./AllBonuses.scss";
import Bonus from "../Bonus/Bonus";
import FreeBet from "../FreeBet/FreeBet";
import FreeSpin from "../FreeSpin/FreeSpin";

const AllBonuses = (props) => {

  const [index, setIndex] = React.useState(0);

  const headers = [
    "Bonus Cash",
    "FreeBet",
    "Free Spins"
  ];

  const handleMenuChange = index => {
    setIndex(index);
  };

  const handleTransitionEnd = () => {
    if (index === 0) {
      const div = document.querySelector(
        ".match-details-swipeable>.react-swipeable-view-container"
      );
      if (div) {
        div.style.willChange = "";
        div.style.transform = "";
      }
    }
  };

  const handleAction = () => {
    setTimeout(() => {
      handleTransitionEnd();
    }, 0);
  };

  const decrement = () => {
    setIndex(v => {
      if (v - 1 >= 0) return v - 1;
      return v;
    });
  };
  const increment = () => {
    setIndex(v => {
      if (v + 1 < headers.length) return v + 1;
      return v
    });
  };

  return (
    <div className="all-bonuses">
      <div className="bonuses-header">
        <div className="left" onClick={decrement}><ArrowLeftIcon /></div>
        <div className="center">{headers[index]}</div>
        <div className="right" onClick={increment}><ArrowRightIcon /></div>
      </div>
      <div className="bonuses-sep"></div>
      <SwipeableViews
        index={index}
        onChangeIndex={handleMenuChange}
        action={handleAction}
        onTransitionEnd={handleTransitionEnd}
        className={`bonus-details-swipeable`}
        disabled={true}
      >
        <div>
          <Bonus tabIndex={index} />
        </div>
        <div>
          <FreeBet tabIndex={index} />
        </div>
        <div>
          <FreeSpin tabIndex={index} />
        </div>
      </SwipeableViews>
    </div>
  );
}

export default AllBonuses;