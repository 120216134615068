import * as actionTypes from '../actions/actionTypes';
import ResponseErrorMessages from '../errors/ResponseErrorMessages';

const initialState = {
	loading: false,
	token: false,
	error: false,
	receivedResetPassword: null,
	errorMessage: '',
	success: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.resetPassword.SEND_EMAIL:
			return {
				...state,
				loading: true,
			};
		case actionTypes.resetPassword.SEND_EMAIL_RESPONSE:

			if (typeof action.emailResponse.ResponseCode !== 'undefined') {
				return {
					...state,
					loading: false,
					error: true,
					errorMessage: ResponseErrorMessages.get(action.emailResponse.ResponseCode),
				};
			} else {
				return {
					...state,
					loading: false,
					error: false,
					token: 'sent',
				};
			}
		case actionTypes.resetPassword.SEND_NEW_PASSWORD:
			return {
				...state,
				loading: true,
			};
		case actionTypes.resetPassword.NEW_PASSWORD_RESPONSE:
			if (typeof action.receivedResetPassword.ResponseCode !== 'undefined') {
				return {
					...state,
					loading: false,
					error: true,
					errorMessage: ResponseErrorMessages.get(
						action.receivedResetPassword.ResponseCode
					),
				};
			} else {
				return {
					...state,
					loading: false,
					error: false,
					receivedResetPassword: action.receivedResetPassword,
				};
			}
		case actionTypes.resetPassword.SET_TOKEN:
			return {
				...state,
				token: action.token,
			};
		default:
			return state;
	}
};

export default reducer;
