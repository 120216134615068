import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    amount: null,
    iframeLink: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.withdrawalCheckout.REQUEST_WITHDRAWAL_LINK:
            return {
                ...state,
                amount: action.amount,
                loading: true,
            };
        case actionTypes.withdrawalCheckout.RECEIVED_WITHDRAWAL_LINK:
            return {
                ...state,
                iframeLink: action.response.link,
                loading: false,
            };
        default:
            return state;
    }
};

export default reducer;