import * as actionTypes from './actionTypes';

export const requestPaymentLink = (amount,selectedBonus) => {
    return {
        type: actionTypes.paymentCheckout.REQUEST_PAYMENT_LINK,
        amount: amount,
        bonus_id: selectedBonus
    }
};

export const receivedPaymentLink = (data) => {
    return {
        type: actionTypes.paymentCheckout.RECEIVED_PAYMENT_LINK,
        response: data,
    }
};

export const requestVivaToken = () => {
    return {
        type: actionTypes.paymentCheckout.REQUEST_VIVA_TOKEN,
    }
};

export const receivedVivaToken = (data) => {
    return {
        type: actionTypes.paymentCheckout.RECEIVED_VIVA_TOKEN,
        data: data,
    }
};

export const requestVivaCardToken = () => {
    return {
        type: actionTypes.paymentCheckout.REQUEST_VIVA_CARD_TOKEN
    }
};

export const requestVivaWithdraw = (token, amount) => {

    return {
        type: actionTypes.paymentCheckout.REQUEST_VIVA_WITHDRAW,
        token: token,
        amount: amount
    }
};

export const receivedVivaCardToken = (data) => {
    return {
        type: actionTypes.paymentCheckout.RECEIVED_VIVA_CARD_TOKEN,
        data: data,
    }
};

export const requestVivaChargeToken = (playerData) => {
    return {
        type: actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN,
        playerData: playerData
    }
};

export const sendVivaChargeToken = (data, amount, bonusId,holderName) => {
    return {
        type: actionTypes.paymentCheckout.SEND_VIVA_CHARGE_TOKEN,
        data: data,
        amount: amount,
        bonusId: bonusId,
        holderName: holderName
    }
};

export const receivedVivaChargeToken = (data) => {
    return {
        type: actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN,
        data: data,
    }
};

export const requestVivaChargeTokenWithCard = (cardToken, playerData) => {
    return {
        type: actionTypes.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD,
        cardToken: cardToken, 
        playerData: playerData
    }
};

export const receivedVivaChargeTokenWithCard = (data) => {
    return {
        type: actionTypes.paymentCheckout.RECEIVED_VIVA_CHARGE_TOKEN,
        data: data,
    }
};

export const oktoCreatePaymentCode = (amount, bonusId) => {
    return {
        type: actionTypes.paymentCheckout.OKTO_CREATE_PAYMENT_CODE,
        amount: amount,
        bonusId: bonusId
    }
}

export const oktoReceivedPaymentCode = (data) => {
    return {
        type: actionTypes.paymentCheckout.OKTO_RECEIVED_PAYMENT_CODE,
        data: data
    }
}

export const oktoGetCustomer = () => {
    return {
        type: actionTypes.paymentCheckout.OKTO_GET_CUSTOMER
    }
}

export const oktoReceivedCustomer = (data) => {
    return {
        type: actionTypes.paymentCheckout.OKTO_RECEIVED_CUSTOMER,
        data: data
    }
}

export const oktoReset = () => {
    return {
        type: actionTypes.paymentCheckout.OKTO_RESET
    }
}

export const aircashGetPaymentLink = (amount,bonusId) => {
    return {
        type: actionTypes.paymentCheckout.AIRCASH_REQUEST_PAYMENT_LINK,
        amount: amount,
        bonusId: bonusId
    }
}