import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from "../../../../store/actions";
import CNPValidator from "../../../../utils/CNPValidator";
import SocialSecurityNumberValidator from "../../../../utils/SocialSecurityNumberValidator";
import Utils from "../../../../utils/Utils";
import { withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Icons from "../../../../assets/Icons/Icons";
import GTM from "../../../../utils/GTM";

class CNP extends Component {
    constructor(props) {
        super(props);
        this.timerId = 'cnpComponent';
    }

    state = {
        message: "",
        ok: false,
        fieldInput: "",
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.cnp !== prevProps.cnp && this.props.cnp !== this.state.fieldInput) {
            this.setState({
                ...this.state,
                fieldInput: this.props.cnp
            })
            Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this),100);
        }
    }

    serverSideValidation(cnp) {
        // let axios = BackendClient.getInstance();
        // axios({
        //     url: 'api/validate/cnp',
        //     method: 'post',
        //     data: {
        //         cnp: cnp,
        //     },
        // }).catch(e => {
        //    console.log(`[CATCHED **CNP VALIDATION** ERROR] => ${e}`);
        // });
        //
        // this.props.onServerSideValidation();
    }

    triggerHandler() {
        let message = "";
        let valid = this.props.isRo ? CNPValidator.validate(this.state.fieldInput) : SocialSecurityNumberValidator.validate(this.state.fieldInput);
        let ok = this.state.ok;

        if (valid.type !== 'success') {
            switch (valid.text) {
                case 1:
                    message = this.props.t('CNP must be 13 characters long');
                    break;
                case 2:
                    message = this.props.t('CNP must contain only numbers');
                    break;
                case 3:
                    message = this.props.t("Minimum age accepted for registration is 18 years");
                    break;
                case 10:
                    message = this.props.t('Social Security Number is too short');
                    break;
                default:
                    message = this.props.t("Inserted value is invalid");
                    break;
            }

            if (ok) {
                ok = false;
            }
        } else {
            this.props.validate(this.state.fieldInput);
            if (!ok) {
                ok = true;
            }
        }

        this.setState({
            ...this.state,

            message: message,
        });

        this.props.onChange({cnp: this.state.fieldInput, validated: ok});
    }

    sendGAEvent() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "CNP" + (this.state.ok ? "" : " - " + this.state.message));
    }

    blurHandler() {
        Utils.delayedFunction('sendGAEvent',this.sendGAEvent.bind(this),50);
        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this), 0);
        this.props.setSpineSize('big');
        Utils.fixRegisterInputs('cnp');
    }

    onFocusHandler() {
        this.props.setSpineSize('small');
        Utils.fixRegisterInputs('cnp');

    }

    changeHandler(event){
        let fieldInput;
        if (null === event) {
            fieldInput = this.state.cnp;
        } else {
            fieldInput = event.target.value;
        }
        if (fieldInput !== this.state.fieldInput && this.state.fieldInput !== '') {
            this.props.resetOCR();
        }

        Utils.delayedFunction(this.timerId, this.triggerHandler.bind(this));

        this.setState({
            ...this.state,
            fieldInput: fieldInput,
        });

        this.props.onChange({cnp: fieldInput, validated: false});
    }

    keyPressedHandler(event) {
        if (this.props.isRo && (event.charCode < 48 || event.charCode > 57)) {
            event.preventDefault();
        }
    }

    render() {
        let errorMessage = this.state.message;
        if (this.props.error && this.props.error !== "") {
            errorMessage = this.props.error; // error from server
        }
        let props = {
            label: this.props.isRo ? this.props.t("CNP") : this.props.t("Social Security Number"),
            error: (errorMessage !== "" && errorMessage !== this.infoMessage && errorMessage !== false),
            value: this.props.cnp ? this.props.cnp : "",
        };

        return (
            <div className={'field'}>
                {Icons.get('cnp')}
                <TextField
                    id={"cnp"}
                    className={''}
                    type={'tel'}
                    onKeyPress={this.keyPressedHandler.bind(this)}
                    onChange={this.changeHandler.bind(this)}
                    onBlur={this.blurHandler.bind(this)}
                    onFocus={this.onFocusHandler.bind(this)}
                    helperText={errorMessage}
                    {...props}
                />
            </div>

        );
    }
}

function isRoumanian(citizenship, defaultCountry) {
    return true;//citizenship === "RO" || (citizenship === "" && defaultCountry === "RO");
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => {
            dispatch(stateActions.fieldEntered({field: "nin", value: fieldInput.cnp,validated: fieldInput.validated}))
            dispatch(stateActions.fieldEntered({field: "cnp", value: fieldInput.cnp,validated: fieldInput.validated}))
        },
        validate: (fieldInput) => {
            dispatch(stateActions.validateField(fieldInput,'nin'))
            dispatch(stateActions.validateField(fieldInput,'cnp'))
        },
        setSpineSize: (size) => dispatch(stateActions.setSpineSize(size)),
        resetOCR: () => dispatch(stateActions.fieldEntered({field:"ocr_scan",value:"",validated: true}))
    };
};

const mapStateToProps = state => {

    return {
        cnp: state.register.fields.nin,
        error: state.register.errors.cnp,
        isRo: isRoumanian(state.register.fields.citizenship, state.register.defaultCountry),
        lang: state.application.language
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CNP));
