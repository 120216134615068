import React from 'react';
import { useTranslation } from 'react-i18next';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import Icons from "../../../assets/Icons/Icons";
import './ProfileWallet.scss';
import Utils from './../../../utils/Utils';

const styles = theme => ({
    margin: {
        margin: theme.spacing(4),
    },
});

const ProfileWallet = (props) => {
    // const classes = props.classes;
    const {t} = useTranslation();

    let icons = {
        wallet: Icons.get("wallet", "rmWalletIcon"),
        realMoney: Icons.get("moneyBag", "rwMoneyBagIcon"),
        bonusMoney: Icons.get("moneyBonus", "rwMoneyBonusIcon"),
    };

    if (!("user" === props.authentication.auth_type)) {
        return null;
    }

    const goToWallet = () => {
        props.history.push("/profile/wallet");
    };

    return (
        <div className={"profileWallet"} onClick={goToWallet}>
            <Grid container className={"total pwRow"}>
                <Grid item xs={6} className={"pwColumn"}>
                    <span className={"rowIcon"}>{ icons.wallet }</span>
                    { t('Sold') }
                </Grid>
                <Grid item xs={6} className={"pwColumn"}>
                    { Utils.formatSmallDecimals(props.wallet.total, props.wallet.currency) }
                </Grid>
            </Grid>
            <Grid container className={"real pwRow"}>
                <Grid item xs={6} className={"pwColumn"}>
                    {/*<span className={"rowIcon"}>{ icons.realMoney }</span>*/}
                    { t('Available') }
                </Grid>
                <Grid item xs={6} className={"pwColumn"}>
                    { Utils.formatSmallDecimals(props.wallet.main + (props.wallet.ringFence ? props.wallet.ringFence : 0), props.wallet.currency) }
                </Grid>
            </Grid>
            <Grid container className={"bonus pwRow"}>
                <Grid item xs={6} className={"pwColumn"}>
                    {/*<span className={"rowIcon"}>{ icons.bonusMoney }</span>*/}
                    { t('BONUS') }
                </Grid>
                <Grid item xs={6} className={"pwColumn"}>
                    { Utils.formatSmallDecimals(props.wallet.bonus, props.wallet.currency) }
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        wallet: state.wallet,
        authentication: state.authentication,
    }
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(ProfileWallet)));
