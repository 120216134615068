import { put, call, delay } from 'redux-saga/effects';
import * as actions from './../actions';
import * as LoginMethods from './../../utils/LoginMethods';
import * as EmailValidator from 'email-validator';
import PhoneNumberValidator from './../../utils/PhoneNumberValidator';
import ClientAPI from './../../ClientAPI/ClientAPI';
import { authenticateSaga, clearAuthStorage } from './authentication';
import * as paths from './../../ClientAPI/paths';
import ClientDetection from "../../utils/ClientDetection";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";

import MarketingEvents from "../../utils/MarketingEvents";

const minUserLength = 4;
const minPasswordLength = 6;
const minCodeLenght = 4;
const smsRequestInterval = 20;

let uNameCount = 0;
let pEnterCount = 0;

export function* loginUsernameSaga(action) {
    let username = action.username.replace(/\s/g, "");
    let allowUsernameButton = false;
    let error = false;
    let method = yield validateMethod(username);

    if (method === LoginMethods.IS_UNDEFINED && uNameCount) {
        error = true;
    }

    if (!error && method > 0) {
        allowUsernameButton = true;
        uNameCount++;
    }

    yield put(actions.setLoginUsername(username, error, method, allowUsernameButton));
}

function* validateMethod(username) {
    let method = LoginMethods.IS_UNDEFINED;

    if (!(username.length > minUserLength)) {
        return method;
    }

    let validateEmail = yield EmailValidator.validate(username);
    if (validateEmail) {
        method = LoginMethods.IS_EMAIL;
        return method;
    }

    let validateNumber = yield PhoneNumberValidator.validate(username);
    if (validateNumber) {
        method = LoginMethods.IS_PHONE;
        return method;
    }

    return method;
}

export function* stepChangeSmsRequest(action) {
    if (!(2 === action.step)) {
        return;
    }

    yield call(requestSmsSaga);
}

export function* requestSmsSaga() {
    const storeState = ClientAPI.getStore().getState();
    if (!storeState.login.allowSMS) {
        return;
    }

    let loginMethod = storeState.login.method;
    if (!(LoginMethods.IS_PHONE === loginMethod)) {
        return;
    }
    let phoneNumber = storeState.login.username;

    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.authentication.SMS,
            method: 'post',
            data: {
                phone: phoneNumber,
            }
        });

        if (!response.status) {
            throw new Error(`[ERROR] Login SMS missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] Login SMS message not sent!`);
        }

        yield put(actions.allowSmsRequest(false));
    } catch (error) {
        console.log(error);
        yield put(actions.allowSmsRequest(true));
        return;
    }


    yield put(actions.startSmsCountdown(smsRequestInterval + 1));
    yield delay(smsRequestInterval * 1000);
    yield put(actions.allowSmsRequest(true));
}

export function* loginPasswordSaga(action) {
    let password = action.password;
    let allowLoginButton = false;
    let error = false;
    let hasMinLength = false;

    const storeState = ClientAPI.getStore().getState();
    const loginMethod = storeState.login.method;

    switch (loginMethod) {
        case LoginMethods.IS_EMAIL:
            if (!(password.length >= minPasswordLength)) {
                hasMinLength = false;
                if (pEnterCount) {
                    error = true;
                }
                break
            }

            hasMinLength = true;
            break;
        case LoginMethods.IS_PHONE:
            password = password.replace(/[^0-9a-zA-Z]+/g, "");
            if (!(password.length >= minCodeLenght)) {
                hasMinLength = false;
                if (pEnterCount) {
                    error = true;
                }
                break;
            }

            hasMinLength = true;
            break;
        default:
            throw new Error(`[ERROR][LOGIN] LoginMethod is not accepted ${loginMethod}!`);
    }

    if (hasMinLength && !error) {
        allowLoginButton = true;
        pEnterCount++;
    }

    yield put(actions.setLoginPassword(password, error, allowLoginButton));
}

export function* requestUserAuthentication(action) {
    const storeState = ClientAPI.getStore().getState();
    let method = storeState.login.method;

    yield put(actions.inRequest(true));

    let authData = {};

    const axios = ClientAPI.getInstance();

    let data = ClientDetection.getAllInfo();
    data.username = storeState.login.username;
    data.password = storeState.login.password;
    data.platformType = window.config.platformType;

    let pac = PlayerAbuseChecker.getInfo();
    data = {
       ...pac,
       ...data
    }

    try {
        const response = yield axios({
            url: paths.authentication.AUTH,
            method: 'post',
            data: data
        });

        if (!response.status) {
            console.log(`[AUTH] User auth error`);
            return
        }

        if (!("OK" === response.status)) {
            console.log(`[AUTH] User auth error`);
            return
        }

        authData = response.result;
        yield call(authenticateSaga, authData);

        yield localStorage.setItem('username', data.username);
        yield localStorage.setItem('method', method);

        yield put(actions.inRequest(false));
        yield put(actions.setLoginPassword("", false, false));
    } catch (error) {
        yield put(actions.inRequest(false));
        yield put(actions.setLoginPassword("", false, false));
        yield put(actions.loginStep(1));
        yield put(actions.loginUsernameError(true));
        yield put(actions.loginPasswordError(true));
        return;
    }
}

export function* logoutSaga(action) {
    yield call(clearAuthStorage);
    yield put(actions.clearAuthentication());
    yield put(actions.startAppAuth());
    MarketingEvents.logout();
}

export function* updateSmsEta(action) {
    const storeState = ClientAPI.getStore().getState();

    if (storeState.login.allowSMS) {
        return;
    }

    if ("user" === storeState.authentication.auth_type) {
        return;
    }

    let eta = (action >= 0) ? action : action.eta;
    let newEta = eta - 1;
    if (newEta < 0) {
        return;
    }
    yield put(actions.smsEtaValue(newEta));
    yield delay(1000);
    yield call(updateSmsEta, newEta);
}
