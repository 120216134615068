import React, { Component } from 'react';
import { connect } from 'react-redux';
import PasswordStrength from "./PasswordStrength/PasswordStrength";
import PasswordValidator, {
    DIGITS,
    LENGTH,
    LOWER_CASE,
    SPECIAL_CHAR,
    UPPER_CASE
} from "../../../../utils/PasswordValidator";
import "./Password.scss";
import * as storeActions from "../../../../store/actions";
import Utils from "../../../../utils/Utils";
import Icons from "../../../../assets/Icons/Icons";
import { withTranslation} from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Input} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import GTM from "../../../../utils/GTM";

class Password extends Component {

    constructor(props) {
        super(props);
        this.timerId = 'passwordComponent';
        this.timerIdConfirm = this.timerId + '.ConfirmInput';

    }

    componentDidMount() {
        this.infoMessage = this.props.t("The passwords doesn't match");
    }

    state = {
        message: "", // used for password strength error
        errorMessage: "", // used for general error
        messageConfirm: "",
        ok: false,
        okConfirm: false,
        confirmInput: "",
        showPasswordStrength: false,
        showPassword: false,
        showConfirmPassword: false
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.confirmInput !== prevState.confirmInput) {
            Utils.delayedFunction(this.timerIdConfirm, this.triggerConfirmHandler.bind(this),0);
        }
    }

    focusHandler() {
        Utils.fixRegisterInputs('confirm-password');
        Utils.fixRegisterInputs('register-password');
        this.setState({
            ...this.state,
            showPasswordStrength: true,
            errorMessage: {},
        });
        this.props.setSpineSize('small');
    }

    onFocusConfirmHandler() {
        Utils.fixRegisterInputs('confirm-password');
        Utils.fixRegisterInputs('register-password');
        if (this.state.confirmInput === "") {

            this.setState({
                ...this.state,
                messageConfirm: this.infoMessage,
            })
        }
        this.props.setSpineSize('small');
    }

    changePasswordState(state) {
        if (typeof state === "undefined") {
            let message = "";
            if (!this.state.message['success']) {
                message = this.props.t("The password doesn't respect the minimum criteria");
            }
            this.setState({
                ...this.state,
                errorMessage: message,
                showPasswordStrength: this.state.ok,
            })
        } else {
            if (state === "toggle") {
                this.setState({
                    ...this.state,
                    showPassword: !this.state.showPassword,
                    showPasswordStrength: true,
                    errorMessage: "",
                })
            }
        }
    }

    blurHandler() {

        GTM.pushGAEvent('Register_'+ this.props.lang,'Field - ' + (this.state.ok ? 'OK' : 'Error'), "Password" + (this.state.ok ? "" : " - " + this.state.errorMessage));
        Utils.delayedFunction("changePasswordState",this.changePasswordState.bind(this),100);
        Utils.fixRegisterInputs('confirm-password');
        Utils.fixRegisterInputs('register-password');
        this.triggerConfirmHandler();
        if ( !(this.state.showPassword || this.state.showConfirmPassword)) {
            this.props.setSpineSize('big');
        }
    }
    blurConfirmHandler() {
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - ' + (this.state.okConfirm ? 'OK' : 'Error'), "Confirm Password" + (this.state.okConfirm ? "" : " - " + this.state.errorMessage));
    }

    changeHandler(event){
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.password;
        } else {
            fieldInput = event.target.value;
        }

        fieldInput = fieldInput.trim();
        let ok = this.state.ok;
        let valid = PasswordValidator.validate(fieldInput);

        if (valid['success']) {
            if (!ok) {
                ok = true;
            }
        } else {
            if (ok) {
                ok = false;
            }
        }


        this.setState({
            ...this.state,
            message: valid,
            ok: ok,
        });
        this.props.onChange({password: fieldInput, validated: ok});
    }
    handleClickShowPassword = () => {
        this.setState({
            ...this.state,
            showPassword: !this.state.showPassword,
        })
    };

    handleClickShowConfirmPassword = () => {
        this.setState({
            ...this.state,
            showConfirmPassword: !this.state.showConfirmPassword
        })
    };

    triggerConfirmHandler() {
        let ok = this.state.okConfirm;
        let message = "";
        if (this.state.confirmInput === this.props.password && this.props.password !== "") {
            if (!ok) {
                ok = true;
            }
        } else {
            if (this.props.password === "" && this.state.confirmInput === "") {
                message = "";
            } else {
                message = this.props.t("The passwords doesn't match");
            }
            if (ok) {
                ok = false;
            }
        }
        this.setState({
            ...this.state,
            messageConfirm: message,
            okConfirm: ok,
        });

        this.props.onConfirmChange({confirmPassword: this.state.confirmInput,validated: ok});
    }

    changeConfirmHandler(event){
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.confirmPassword;
        } else {
            fieldInput = event.target.value;
        }
        fieldInput = fieldInput.trim();
        this.setState({
            ...this.state,
            confirmInput: fieldInput,
        });
        this.props.onConfirmChange({confirmPassword: fieldInput,validated: false});
    }

    togglePassword() {
        // document.getElementById("Password").focus();
        Utils.delayedFunction("changePasswordState",this.changePasswordState('toggle'),0);
    }

    togglePasswordConfirm() {
        // document.getElementById("PasswordConfirm").focus();
        this.setState({
            ...this.state,
            showPasswordConfirm: !this.state.showPasswordConfirm
        })
    }


    onKeyPressHandler() {
        // if (this.state.confirmInput !== "") {
        //     this.setState({
        //         ...this.state,
        //         confirmInput: "",
        //     });
        // }
    }

    getPasswordStrengthComponent() {
        let comp = [];
        let checkmark = Icons.get('checkmark','checkmark');
        let checkmarkClassic = Icons.get('checkmark-classic','checkmark-classic active');
        let strength = this.state.showPasswordStrength || this.state.ok ? ' ' : 'hide';
        let notValid = "";
        let progress = 0;
        let style = [{width: 0,},{ width: 0,},{width: 0,}];


        if (this.props.password !== "" && this.state.showPasswordStrength) {

            notValid = "";
            if(this.state.message[LENGTH] === "success") {
                progress +=20;
            }
            if(this.state.message[LOWER_CASE]  === "success") {
                progress +=20;
            }
            if(this.state.message[UPPER_CASE]  === "success") {
                progress +=20;
            }
            if (this.state.message[DIGITS]  === "success") {
                progress += 20;
            }
            if (this.state.message[SPECIAL_CHAR]  === "success") {
                progress += 20;
            }
            switch (progress) {

                case 60:
                    strength = 'Low';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'red';
                    break;
                case 80:
                    strength = 'Medium';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'orange';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'orange';
                    break;
                case 100:
                    strength = 'Maximum';
                    style[0].width = '100%';
                    style[0].backgroundColor = 'green';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'green';
                    style[2].width = '100%';
                    style[2].backgroundColor = 'green';
                    break;
                case 0:
                case 20:
                case 40:
                default:
                    strength = 'Very Low';
                    strength = 'Low';
                    style[0].width = (progress * 1.65) + '%';
                    style[0].backgroundColor = 'red';
                    break;
            }
        }

        let content = [];
        if (!this.state.ok) {
            content.push(<ul className="list" key={"password_strength_list"}>
                <li className={"title " + this.state.groupClassName}>{this.props.t("Password Requirements:")}</li>
                <li className={(this.state.message[UPPER_CASE] || this.state.message[LOWER_CASE] ? "success" : notValid )}>
                    {checkmark}
                    {this.props.t("Include at least 1 letter")}
                </li>
                <li className={this.state.message[LENGTH] ? this.state.message[LENGTH] : notValid}>
                    {checkmark}
                    {this.props.t("Have at least 6 characters in length")}
                </li>
                <li className={this.state.message[DIGITS] ? this.state.message[DIGITS] : notValid}>
                    {checkmark}
                    {this.props.t("Include at least 1 number")}
                </li>
                <div className="space"> </div>
            </ul>);
        } else {
            content.push(<div key={"password_strength_grand_finale"}>
                <div className="clearfix"> </div>
                <div className={"congrats password"}>
                    <div className="circles">
                        <div className="circle"> </div>
                        <div className="circle"> </div>
                        <div className="circle"> </div>
                        {checkmarkClassic}
                    </div>

                </div>
            </div>)
        }
        comp.push(<div className="ColumnMessage col text-left" key={"password_strength_comp"}>
            <div className={(strength === 'hide' ? strength : "") + " password-strength" + (this.state.groupClassName.indexOf('valid') !== -1 ? ' valid' : '')}>
                <div className={strength + " progress-0 header"}>

                    {this.props.t("Security Level:")}
                    <span className="right">{strength}</span>
                    <div className="clearfix"> </div>
                    <div className="lines">
                        <div className="line">
                            <div className="fill" style={style[0]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[1]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[2]}> </div>
                        </div>
                    </div>
                </div>
                {content}
                <div className="clearfix"> </div>
            </div>
        </div>);

        return comp;
    }

    render() {

        // let passwordStrength = this.getPasswordStrengthComponent();

        let error = (typeof this.state.message === "string" && this.state.message !== "") || (typeof this.state.message === "object" && !this.state.message['success']);
        let props = {
            type: this.state.showPassword ? 'text' : 'password'
        };

        let propsConfirm = {
            error: this.state.messageConfirm !== "",
            type: this.state.showConfirmPassword ? 'text' : 'password'
        };

        return (
            <div>
                <div className="passwordField field">

                    <FormControl className="PasswordInput">
                        {Icons.get('register-password')}
                        <InputLabel htmlFor="NewPasswordField">{this.props.t('Password')}</InputLabel>
                        <Input
                            id={"register-password"}
                            className={''}
                            {...props}
                            onKeyPress={this.onKeyPressHandler.bind(this)}
                            onBlur={this.blurHandler.bind(this)}
                            onFocus={this.focusHandler.bind(this)}
                            onChange={this.changeHandler.bind(this)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onMouseDown={this.handleClickShowPassword.bind(this)}
                                    >
                                        {this.state.showPassword ? <Visibility  className={'VisibilityIcon'}/> : <VisibilityOff className={'VisibilityIcon'} />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>

                <PasswordStrength showPasswordStrength={this.state.showPasswordStrength || error} password={this.props.password} ok={this.state.ok}/>
                <div className="clearfix"> </div>

                {window.config.register_disable_confirm_password !== '1' && <div className="passwordField field">
                    <FormControl className="PasswordInput">
                        {Icons.get('register-password')}
                        <InputLabel htmlFor="NewPasswordField">{this.props.t('Password')}</InputLabel>
                        <Input
                            {...propsConfirm}
                            label={this.props.t("Confirm Password")}
                            id={"confirm-password"}
                            className={''}
                            onFocus={this.onFocusConfirmHandler.bind(this)}
                            onBlur={this.blurConfirmHandler.bind(this)}
                            onChange={this.changeConfirmHandler.bind(this)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onMouseDown={this.handleClickShowConfirmPassword}
                                    >
                                        {this.state.showConfirmPassword ? <Visibility className={'VisibilityIcon'}/> : <VisibilityOff className={'VisibilityIcon'}/> }
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText error={this.state.messageConfirm !== ""}>{this.state.messageConfirm}</FormHelperText>
                    </FormControl>
                </div>}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(storeActions.fieldEntered({field: "password", value: fieldInput.password, validated: fieldInput.validated})),
        onConfirmChange: (fieldInput) => dispatch(storeActions.fieldEntered({field: "confirmPassword", value: fieldInput.confirmPassword, validated: fieldInput.validated})),
        setSpineSize: (size) => dispatch(storeActions.setSpineSize(size))
    };
};

const mapStateToProps = state => {
    return {
        password: state.register.fields.password,
        lang: state.application.language
        // confirmPassword: state.registerWizard.fields.confirmPassword,
        // reward: state.registerWizard.rewards.password,
        // rewardConfirm: state.registerWizard.rewards.confirmPassword,
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Password));
