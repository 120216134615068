import React from 'react';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../store/actions";
import HideProfileImage from './../../../assets/Images/Profile/hide_player_details.png';
import './ProfileMenu.scss';

import RightMenu from "../../RightMenu/RightMenu";


const styles = theme => ({
    paper: {
        background: "#121921",
        color: "white",
    },
    margin: {
        margin: theme.spacing(4),
    },
    marginButton: {
        margin: theme.spacing(2),
    },
    hideProfile: {
        backgroundImage: `url(${HideProfileImage})`,
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        border: '0px',
        backgroundPosition: 'center 0px',
    },
});

const ProfileMenu = (props) => {


    return (
        <div className={"profileMenu"}>
            <RightMenu/>
        </div>

    );
};

const mapStateToProps = state => {
    return {
        menu: state.menu,
        application: state.application,
        documents: state.documents,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleProfile: (show) => dispatch(storeActions.rightProfileSection(show)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileMenu)));
