import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withTranslation } from "react-i18next";
import localization from "moment/locale/ro.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TournamentHeader from "../../components/tournament-header";
import DateHeader from "../../components/date-header";
import MatchRow from "../../components/match-row-prematch";
import Search from "../../components/search";

import {
  makeGetCategories,
  getBetsState,
  getPrematchSportsListRetails,
  getPrematchTournaments,
  getTopSportsList,
  getTopTournamentsList,
  makeGetTournamentGroups
} from "../../../bets/store/selectors/betData";
import { prematchFetchSportByDay } from "../../../bets/store/actions/prematch";
import { nSoftPrematch } from "../../../bets/api/config/nsoft";
import NewtonLoader from "../../../newton-loader";

import { sortArrayByKey, testValues } from "../../utils";

const Home = props => {
  const { prematchTournaments, topSports, topTournaments, tournamentGroups, matches, categories, bets, i18n, t, prematchFetchSportByDay, loadingState } = props;

  //console.log("Home", props);
  const isDesktop = useMediaQuery("(min-width: 600px)");

  const [source, setSource] = useState({
    sports: [],
    tournaments: [],
    tournamentMatches: {},
    tournamentBets: {},
    allMatches: [],
  });

  const calendarMatches = {};
  for (let i = 0; i < 7; i++) {
    const ts = moment().add(i, 'days').startOf("day").valueOf();
    calendarMatches[ts] = true;
  }

  const dates = [
    { t: 0, label: "All" }, // t("All")
    { t: 1, label: "3 days" }, // t("3 days")
    { t: 2, label: "Today" }, // t("Today")
    { t: 3, label: "Soon (+3h)" }, // t("Soon")
  ];
  const filterFormat = "YYYY.MM.DD";
  const dayFormat = "DD/MM/YYYY";

  Object.keys(calendarMatches).sort().forEach(t => {
    if (moment(parseInt(t, 10)).format(filterFormat) === moment().format(filterFormat)) return;
    dates.push({
      t: t
    });
  });

  const dateList = dates;
  const [state, setState] = React.useState({
    currentIndex: 2,
    sort: 0,
    filter: "",
    collapsed: {},
    collapsedDay: {},
  });
  const [fetch, setFetch] = React.useState(0);

  const toggleCollapsed = React.useCallback((e) => {
    e.stopPropagation();

    const target = e.currentTarget;

    if (target) {
      const id = target.dataset.id;

      setState(v => {
        const collapsed = { ...v.collapsed };
        if (typeof collapsed[id] === "undefined") {
          collapsed[id] = true;
        } else {
          collapsed[id] = !collapsed[id];
        }

        return { ...v, collapsed };
      });
    }
  }, []);

  const toggleCollapsedDay = (day) => () => {
    const collapsedDay = { ...state.collapsedDay };
    if (typeof collapsedDay[day] === "undefined") {
      collapsedDay[day] = true;
    } else {
      collapsedDay[day] = !collapsedDay[day];
    }

    setState(v => ({ ...v, collapsedDay }));
  };

  const handleSearchChange = (type, val) => {
    setState(v => {
      const tmp = { ...v };
      if (type === "tab") {
        tmp.currentIndex = val;
      } else if (type === "filter") {
        tmp.filter = val;
      } else if (type === "sort") {
        tmp.sort = val;
      }
      return tmp;
    });

    if (type === "tab") {
      setFetch(v => v + 1);
    }
  };

  const oldFetch = React.useRef(-1);
  React.useEffect(() => {
    if (oldFetch.current !== fetch && loadingState === null && dateList.length) {
      let day;
      let noDays = 1;
      if (state.currentIndex === 0) {
        day = 0;
      } else if (state.currentIndex === 1) {
        day = moment().startOf("day").valueOf() + "";
        noDays = 3;
      } else if (state.currentIndex === 2 || state.currentIndex === 3) {
        day = moment().startOf("day").valueOf() + "";
      } else {
        day = moment(parseInt(dateList[state.currentIndex].t, 10)).startOf("day").valueOf() + "";
      }
      //console.log("prematchFetchSportByDay", { day, noDays, idSport });
      prematchFetchSportByDay({ day, noDays, idSport: nSoftPrematch.defaultSportId });
      oldFetch.current = fetch;
    }
  }, [fetch, dateList, state.currentIndex, loadingState, prematchFetchSportByDay]);

  const filterByDate = (m, currentDate) => {
    if (state.currentIndex === 0) {
      // "All" matches
      return true;
    } else if (state.currentIndex === 1) {
      // "Three days" matches
      return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) < moment().startOf("day").add(3, 'days').startOf("day").format(filterFormat);
    } else if (state.currentIndex === 2) {
      // "Today" matches
      return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) === moment().format(filterFormat);
    } else if (state.currentIndex === 3) {
      // "Soon" matches
      return m.matchDateTime <= moment().add(3, 'hours').valueOf();
    }

    // "[DAY_OF_THE_WEEK]" matches
    return moment(parseInt(m.matchDateTime, 10)).format(filterFormat) === moment(currentDate).format(filterFormat);
  };

  useEffect(() => {
    // is top offer selected?

    //console.log("props", props);

    let sports = [],
      tournaments = [],
      ms = [];

    sports = topSports;
    tournaments = topTournaments;

    const currentDate = parseInt(dates[state.currentIndex].t, 10);

    // gather matches for tournaments
    ms = Object.values(matches).filter(m => {
      let res = false;
      if (tournaments.find(t => t.idTournament === m.idTournament)) {
        res = true;
      } else {
        res = false;
      }

      return res;
    });

    ms = ms.filter(m => filterByDate(m, currentDate));
    if (state.filter) {
      ms = ms.filter(m => `${m.team1Name} - ${m.team2Name}`.toLowerCase().indexOf(state.filter.toLowerCase()) > -1);
    }

    // sort after start time
    sortArrayByKey(ms, "matchDateTime");

    // split matches on tournaments and day when they're played
    const tournamentMatches = ms.reduce((acc, m) => {
      const d = moment(m.matchDateTime).format("DD/MM/YYYY");
      const tid = m.idTournament;

      acc[tid] = acc[tid] || {
        match: m
      };
      acc[tid][d] = acc[tid][d] || [];
      acc[tid][d].push(m);


      return acc;
    }, {});

    const tmpTournaments = [];

    // generate default bets for each tournament
    Object.values(tournamentMatches).forEach(tm => {
      const tournament = tournaments.find(t => t.idTournament === tm.match.idTournament);
      if (tournament) {
        tmpTournaments.push(tournament);
      }

      delete tm.match;
    });

    setSource({
      sports,
      tournaments: tmpTournaments,
      tournamentMatches,
      allMatches: ms,
    });
  }, [topTournaments, topSports, matches, bets, state]); // eslint-disable-line


  const buildMatches = (matches, mCurrentDate, category, tournament, tournamentGroupName) => {
    const t = tournament.idTournament;
    const c = category.idCategory;

    let nowDate = state.currentIndex <= 3 ? moment(parseInt(matches[0].matchDateTime, 10)).format(dayFormat) : mCurrentDate;

    const mg = `mg_sort_category_category_${c}_${t}${tournamentGroupName ? `_${tournamentGroupName}` : ''}`;

    let hasMatches = false;
    let displayMatches = [];

    displayMatches.push(<DateHeader key={`dateHeader_${c}_${t}_${nowDate}`} date={nowDate} matchGroup={mg} matches={matches} />);

    matches.forEach((m, i) => {
      if (
        state.filter &&
        `${m.team1Name} - ${m.team2Name}`.toLowerCase().indexOf(state.filter.toLowerCase()) === -1
      )
        return null;
      hasMatches = true;

      const newDate = moment(parseInt(m.matchDateTime, 10)).format(dayFormat);
      if (newDate !== nowDate) {
        nowDate = newDate;
        displayMatches.push(<DateHeader key={`dateHeader_${c}_${t}_${nowDate}`} date={nowDate} matchGroup={mg} matches={matches} />)
      }

      displayMatches.push(
        <MatchRow
          key={"pm_" + m.idMatch}
          idMatch={m.idMatch}
          matchGroup={mg}
        />
      );
    });
    if (!hasMatches) return null;

    const collapsedKey = `${tournament.idTournament}${tournamentGroupName ? tournamentGroupName : ''}`;

    if (state.collapsed[collapsedKey]) displayMatches = [];

    return (
      <div className="tournaments" key={`tournament_${tournament.idTournament}${tournamentGroupName ? `_${tournamentGroupName}` : ''}`}>
        <TournamentHeader
          idTournament={tournament.idTournament}
          brId={tournament.brId}
          name={`${category.categoryName} / ${tournament.tournamentName}${tournamentGroupName ? ` / ${tournamentGroupName}` : ''}`}
          onClick={toggleCollapsed}
          collapsed={typeof state.collapsed[collapsedKey] !== "undefined" ? state.collapsed[collapsedKey] : false}
          matchGroup={mg}
          matches={matches}
          collapsedKey={collapsedKey}
        />
        {displayMatches}
      </div>
    );
  };

  let cnt = null;
  if (source.sports.length) {
    if (state.sort === 0) {
      cnt = source.sports.map((notUsed, si) => (
        <React.Fragment key={`sport_id_${si}`}>
          <div className={`sport-header sport-color-${source.sports[si].idSport}`}>
            {source.sports[si].sportName}
          </div>
          <div className="tournaments">
            {source.tournaments
              .filter(t => t.idSport === source.sports[si].idSport)
              .map(t => {
                const currentDate = parseInt(dates[state.currentIndex].t, 10);
                const mCurrentDate = state.currentIndex > 3 ? moment(currentDate).format(dayFormat) : moment().format(dayFormat);

                const category = categories[t.idCategory];
                let entries = source.tournamentMatches[t.idTournament];
                let matches = [];
                Object.entries(entries).forEach(([d, ms]) => {
                  matches = [...matches, ...ms];
                });

                matches.sort((a, b) => a.matchDateTime - b.matchDateTime);

                if (!t) return null;
                if (!category) return null;

                const tmpGroups = {};
                let hasGroups = false;
                matches.forEach(m => {
                  if (typeof tmpGroups[m.idTournamentGroup] === "undefined") {
                    tmpGroups[m.idTournamentGroup] = [];
                  }

                  tmpGroups[m.idTournamentGroup].push(m);
                  if (m.idTournamentGroup !== "0") hasGroups = true;
                });

                const groups = [];

                const tournament = { ...t };
                tournament.brId = prematchTournaments[t.idTournament] ? prematchTournaments[t.idTournament].brId : null;

                if (hasGroups) {
                  const keys = Object.keys(tmpGroups);

                  keys.sort((a, b) => {
                    const posA = a !== "0" && tournamentGroups[a] ? tournamentGroups[a].tournamentGroupPosition : null
                    const posB = b !== "0" && tournamentGroups[b] ? tournamentGroups[b].tournamentGroupPosition : null
                    return testValues(posA, posB);
                  });

                  keys.forEach(gID => {
                    if (tournamentGroups[gID])
                      groups.push({ id: gID, groupName: tournamentGroups[gID].tournamentGroupName, m: tmpGroups[gID] });
                  });

                  const list = [];

                  groups.forEach(g => {
                    const res = buildMatches(g.m, mCurrentDate, category, tournament, g.groupName);

                    if (res) list.push(res);
                  });

                  if (list.length === 0) return null;

                  return <React.Fragment key={`tournament_groups_for_${t.idTournament}`}>
                    {list}
                  </React.Fragment>

                } else {
                  return buildMatches(matches, mCurrentDate, category, tournament);
                }
              })}
          </div>
        </React.Fragment>
      ));
    } else {
      let nowDate = source.allMatches.length ? parseInt(source.allMatches[0].matchDateTime, 10) : moment().valueOf();
      let cDate = moment(nowDate).format(dayFormat);
      let d;
      if (i18n.language === "ro") {
        d = moment(nowDate).locale("ro", localization);
      } else {
        d = moment(nowDate).locale("en");
      }
      let mCurrentDay = d.format("dddd");

      if (d.format("YYYYMMDD") === moment().format("YYYYMMDD")) {
        mCurrentDay = t("Today");
      } else if (d.format("YYYYMMDD") === moment().add(1, "day").format("YYYYMMDD")) {
        mCurrentDay = t("Tomorrow");
      }

      cnt = [];

      const mg = `mg_sort_leagues_home_${cDate}_${mCurrentDay}`;

      source.sports.forEach((notUsed, si) => {
        const sportMatches = source.allMatches.filter(m => m.idSport === source.sports[si].idSport);

        if (sportMatches && sportMatches.length) {

          cnt.push(<div key={`sport_${si}`} className={`sport-header sport-color-${source.sports[si].idSport}`}>
            {source.sports[si].sportName}
          </div>);

          let tmpMatches = [];
          sportMatches.forEach((m, i) => {
            let newDate = moment(parseInt(m.matchDateTime, 10)).format(dayFormat);

            if (cDate !== newDate) {
              if (tmpMatches.length) {

                let collapsedContent = null;
                if (!state.collapsedDay[cDate]) {
                  collapsedContent = <React.Fragment>
                    <DateHeader date={cDate} matchGroup={mg} matches={sportMatches} />
                    {tmpMatches}
                  </React.Fragment>;
                }

                cnt.push(
                  <div className="tournaments" key={`day_${cDate}_${mCurrentDay}`}>
                    <TournamentHeader
                      name={mCurrentDay}
                      onClick={toggleCollapsedDay(cDate)}
                      collapsed={typeof state.collapsedDay[cDate] !== "undefined" ? state.collapsedDay[cDate] : false}
                      matchGroup={mg}
                      matches={sportMatches}
                    />
                    {collapsedContent}
                  </div>
                );
              }

              cDate = newDate;

              let d;
              if (i18n.language === "ro") {
                d = moment(parseInt(m.matchDateTime, 10)).locale("ro", localization);
              } else {
                d = moment(parseInt(m.matchDateTime, 10)).locale("en");
              }
              mCurrentDay = d.format("dddd");

              if (d.format("YYYYMMDD") === moment().format("YYYYMMDD")) {
                mCurrentDay = t("Today");
              } else if (d.format("YYYYMMDD") === moment().add(1, "day").format("YYYYMMDD")) {
                mCurrentDay = t("Tomorrow");
              }

              tmpMatches = [];
            }

            let newFilterDate = moment(parseInt(m.matchDateTime, 10)).format(filterFormat);
            let futureDay = moment().add(8, "days").format(filterFormat);
            if (newFilterDate >= futureDay) {// allow only for maxim 7 days
              return;
            }

            tmpMatches.push(<MatchRow
              key={"pm_" + m.idMatch}
              idMatch={m.idMatch}
              matchGroup={mg}
              extended={true}
            />);

          });

          if (tmpMatches.length) {

            let collapsedContent = null;
            if (!state.collapsedDay[cDate]) {
              collapsedContent = <React.Fragment>
                <DateHeader date={cDate} matchGroup={mg} matches={sportMatches} />
                {tmpMatches}
              </React.Fragment>;
            }

            cnt.push(
              <div className="tournaments" key={`day_${cDate}_${mCurrentDay}`}>
                <TournamentHeader
                  name={mCurrentDay}
                  onClick={toggleCollapsedDay(cDate)}
                  collapsed={typeof state.collapsedDay[cDate] !== "undefined" ? state.collapsedDay[cDate] : false}
                  matchGroup={mg}
                  matches={sportMatches}
                />
                {collapsedContent}
              </div>
            );
          }
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Search
        className="prematch"
        dateList={dateList}
        currentIndex={state.currentIndex}
        sort={state.sort}
        filter={state.filter}
        onChange={handleSearchChange}
      />



      <div className="elements">
        {cnt}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = () => {
  const getCategories = makeGetCategories();
  const getTournamentGroups = makeGetTournamentGroups();
  return state => {
    const bst = getBetsState(state);
    const init = { mType: "prematch" };

    return {
      loadingState: state.bets.prematch.loadingState,
      prematchSports: getPrematchSportsListRetails(state, init),
      prematchTournaments: getPrematchTournaments(state),
      topSports: getTopSportsList(state),
      topTournaments: getTopTournamentsList(state),
      tournamentGroups: getTournamentGroups(state, init),
      matches: bst.prematch.matches,
      categories: getCategories(state, init),
      bets: bst.prematch.bets,
    };
  };
};

const actionCreators = {
  prematchFetchSportByDay
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(Home));
