import React, { Component } from 'react';
import PasswordValidator, {
    DIGITS,
    LENGTH,
    LOWER_CASE,
    SPECIAL_CHAR,
    UPPER_CASE
} from "../../../../../utils/PasswordValidator";
import Icons from "../../../../../assets/Icons/Icons";
import { withTranslation} from "react-i18next";
import '../../Reward/Reward.scss';

class PasswordStrength extends Component {

    getPasswordStrengthComponent() {

        let comp = [];
        let checkmark = Icons.get('checkmark','checkmark');
        let checkmarkClassic = Icons.get('checkmark-classic','checkmark-classic active');
        let strength = this.props.showPasswordStrength ? ' ' : 'hide';
        let notValid = "";
        let progress = 0;
        let style = [{width: 0,},{ width: 0,},{width: 0,}];

        let validations = PasswordValidator.validate(this.props.password);
        if (this.props.password !== "" && this.props.showPasswordStrength) {

            notValid = "";
            if(validations[LENGTH] === "success") {
                progress +=20;
            }
            if(validations[LOWER_CASE]  === "success") {
                progress +=20;
            }
            if(validations[UPPER_CASE]  === "success") {
                progress +=20;
            }
            if (validations[DIGITS]  === "success") {
                progress += 20;
            }
            if (validations[SPECIAL_CHAR]  === "success") {
                progress += 20;
            }
            switch (progress) {

                case 60:
                    strength = this.props.t('Low');
                    style[0].width = '100%';
                    style[0].backgroundColor = 'red';
                    break;
                case 80:
                    strength = this.props.t('Medium');
                    style[0].width = '100%';
                    style[0].backgroundColor = 'orange';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'orange';
                    break;
                case 100:
                    strength = this.props.t('Maximum');
                    style[0].width = '100%';
                    style[0].backgroundColor = 'green';
                    style[1].width = '100%';
                    style[1].backgroundColor = 'green';
                    style[2].width = '100%';
                    style[2].backgroundColor = 'green';
                    break;
                case 0:
                case 20:
                case 40:
                default:
                    strength = this.props.t('Very Low');
                    strength = this.props.t('Low');
                    style[0].width = (progress * 1.65) + '%';
                    style[0].backgroundColor = 'red';
                    break;
            }
        }

        let content = [];
        if (!this.props.ok) {
            content.push(<ul className="list" key={"password_strength_list"}>
                <li className={"title"}>{this.props.t("Password Requirements:")}</li>
                <li className={(validations[UPPER_CASE] || validations[LOWER_CASE] ? "success" : notValid )}>
                    {checkmark}
                    {this.props.t("Include at least 1 letter")}
                </li>
                <li className={validations[LENGTH] ? validations[LENGTH] : notValid}>
                    {checkmark}
                    {this.props.t("Have at least 6 characters in length")}
                </li>
                <li className={validations[DIGITS] ? validations[DIGITS] : notValid}>
                    {checkmark}
                    {this.props.t("Include at least 1 number")}
                </li>
                <div className="space"> </div>
            </ul>);
        } else {
            content.push(<div key={"password_strength_grand_finale"}>
                <div className="clearfix"> </div>
                <div className={"congrats password"}>
                    <div className="circles">
                        <div className="circle"> </div>
                        <div className="circle"> </div>
                        <div className="circle"> </div>
                        {checkmarkClassic}
                    </div>

                </div>
            </div>)
        }
        comp.push(<div className="ColumnMessage col text-left" key={"password_strength_comp"}>
            <div className={(strength === 'hide' ? strength : "") + " password-strength"}>
                <div className={strength + " progress-0 header"}>

                    {this.props.t("Security Level:")}
                    <span className="right">{strength}</span>
                    <div className="clearfix"> </div>
                    <div className="lines">
                        <div className="line">
                            <div className="fill" style={style[0]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[1]}> </div>
                        </div>
                        <div className="line">
                            <div className="fill" style={style[2]}> </div>
                        </div>
                    </div>
                </div>
                {content}
                <div className="clearfix"> </div>
            </div>
        </div>);

        return comp;
    }

    render() {

        let passwordStrength = this.getPasswordStrengthComponent();

        return (
            <div>
                {passwordStrength}
            </div>


        );
    }
}

export default withTranslation()((PasswordStrength));
