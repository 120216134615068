import React from 'react';
import { withTranslation } from "react-i18next";
import TitleBar from "../../UI/TitleBar/TitleBar";
import DinamicImage from "../../UI/DinamicImage/DinamicImage";
import './LiveCasino.scss';

const LiveCasino = (props) => {

    return (
        <div className={"LiveCasinoScreen"}>
            <TitleBar pageTitle={props.t("Live Casino")} pageSubTitle={props.t("Coming Soon")}/>
            <div className={"LiveCasinoScreenContent"}>
                <div className={'MainContent'}>
                    <h1 className={'PageText'}>{props.t('VIP experience')}</h1>
                    <span className={'MedalButton'}>{props.t('coming soon')}</span>
                </div>
                <div className={'MainImage'}>
                    <DinamicImage 
                        folder={"coming-soon"}
                        size={"large"}
                        placeholder
                        imgName={"live-casino.jpg"}
                        title={"Live Casino"}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(LiveCasino);