
import React, {useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import Icons from '../../../assets/Icons/Icons';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import BonusWidget from "../../GlobalWidgets/LoggedIn/Bonus/BonusWidget";
import './BonusNotEligible.scss';


const BonusNotEligible = (props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const newPaymentMethodHandler = () => {
        setLoading(true);
        props.newPaymentHandler();
    }
    const oldPaymentMethodHandler = () => {
        setLoading(true);
        props.oldPaymentHandler();
    }

    return (
        <Grid container id={'bonus-not-eligible-step'}>
            <Grid item xs={12} className={'header'}>
                {Icons.get('exWarning')}
                <h2>{t("Bonus will be lost")}</h2>
            </Grid>
            <Grid item xs={12} className={'header'}>
                <Grid container>
                    <BonusWidget type={'compact'} bonusGroupId={props.selectedBonus} depositValue={props.depositValue} />
                </Grid>
            </Grid>
            <Grid item xs={12} className={'info'}>
                <Grid container>

                    <Grid item xs={12} className={'red'}>{Icons.get('bullet_point')} {t("This BONUS can only be obtained through the NEW METHOD.")}</Grid>
                    <Grid item xs={12} className={'green'}>{Icons.get('bullet_point')} {t("The new payment method requires adding a card again (once), but it comes with benefits.")}</Grid>
                    <Grid item xs={12} className={'green'}>{Icons.get('bullet_point')} {t("Withdrawals are approved in less than 1 MINUTE and you can cancel a request in progress at your convenience.")}</Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} className={'actions'}>
                <Grid container>
                    <Button fullWidth variant="contained" size="large"  className={'old'} onClick={oldPaymentMethodHandler}>
                        {loading ? <CircularProgress className={'Loading'} /> :  t("Continue without bonus")}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Button fullWidth variant="contained" size="large"  className={'new'} onClick={newPaymentMethodHandler}>
                    {loading ? <CircularProgress className={'Loading'} /> : t("DEPOSIT WITH BONUS")}
                </Button>
            </Grid>
        </Grid>
    );
};

export default withTranslation()(BonusNotEligible);