import React from 'react';
import PropTypes from 'prop-types';
import { Transition, animated } from 'react-spring/renderprops';
 
function TabList(props) {
  return (
    <animated.div>
      <Transition
        native
        from={{life: '0%', opacity: 0}}
        enter={[{ opacity: 1 }, {life: '100%'}]}
        leave={
          () => async (next) => {
            await next({life: '0%'})
            await next({opacity: 0})}
        }
      >
        {
          () => function (style) {
            return (
                <animated.div style={style} >
                  {props.children}
                </animated.div>
            )
          }
        }

      </Transition>
  </animated.div>
  );
}
TabList.propTypes = {
  children: PropTypes.node.isRequired,
};
 
export default TabList;