import React, { useEffect, useState, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from '../../../../../store/actions/index';
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import './ConfirmPopup.scss';
import Translate from "../../../../../utils/Translate";

const ConfirmPopup = props => {

    const [popupContent, setPopupContent] = useState({
        title: '',
        questionText: '',
        amountText: '',
        cancelButtonText: '',
        successButtonText: '',
        error: false,
        errorMessage: '',
    });

    const [depositAmount, setDepositAmount] = useState('');

    // Don't remove this! Javascript throws error if we use props.t, so we are using Translate component.
    const t = (text) => {
        return <Translate text={text} />;
    }

    useEffect(() => {
        handlePopupContent(props.content.type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.content.type]);

    useEffect(() => {
        handleError();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depositAmount]);

    const handleChange = event => {
        let depositInput = event.target.value.replace(/[^0-9]/g, '');
        if (Number.isInteger(parseInt(depositInput)) || depositInput === '') {
            if (props.content.amount - depositInput < 20) {
                depositInput = props.content.amount;
            }
            setDepositAmount(depositInput);
        }
    };

    const handleError = () => {
        if (depositAmount > props.content.amount) {
            setPopupContent({
                ...popupContent,
                error: true,
                errorMessage: t('Exceeds max amount allowed'),
            });
        } else if (depositAmount < 1 || depositAmount === '') {
            setPopupContent({
                ...popupContent,
                error: true,
                errorMessage: t('Must enter some value'),
            });
        } else {
            setPopupContent({
                ...popupContent,
                error: false,
                errorMessage: '',
            });
        }
    }

    const handlePopupContent = (type) => {
        if (type === 'partial') {
            setPopupContent({
                ...popupContent,
                title: t('Partial Deposit'),
                questionText: t('Deposit from withdrawal request'),
                amountText: t('Available amount:'),
                cancelButtonText: t('Close'),
                successButtonText: t('Deposit'),
                error: true,
                errorMessage: '',
            });
        }
        if (type === 'cancel') {
            setPopupContent({
                ...popupContent,
                title: t('Cancel Withdrawal'),
                questionText: t('Are you sure that you want to cancel this withdrawal request?'),
                amountText: t('Amount to cancel:'),
                cancelButtonText: t('No'),
                successButtonText: t('Yes'),
                error: false,
                errorMessage: '',
            });
        }
    }

    const handleConfirmAction = (type, src) => {
        //default cancel whole withdraw
        let amount = props.content.amount;
        if (type === 'partial') {
            amount = depositAmount
        }
        if (props.content.src === 'pending-viva-withdrawals') {
            props.onRequestCancelVivaWithdrawal(amount, props.content.id);
        } else {
            props.onRequestCancelWithdrawal(amount, props.content.id);
        }
    };

    let pageContent = (
        <Fragment>
            <DialogContent>
                <CircularProgress className={'Loading'} />
            </DialogContent>
        </Fragment>
    );
    if (!props.withdrawals.loadingCancel) {
        pageContent = (
            <Fragment>
                <DialogTitle id="form-dialog-title">{popupContent.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>{popupContent.questionText}</span>
                        <br /><br />
                        <span>{popupContent.amountText}&nbsp;<strong>{props.content.amount + ' Lei'}</strong></span>
                        {
                            props.content.type === 'partial' && <span><br/>{props.t('Minimum withdrawal:')} 20 Lei</span>
                        }
                    </DialogContentText>
                    {
                        props.content.type === 'partial'
                            ? <TextField
                                autoFocus
                                className={'PartialField'}
                                error={popupContent.error && popupContent.errorMessage !== ''}
                                helperText={popupContent.errorMessage}
                                onChange={handleChange}
                                value={depositAmount}
                                type="tel"
                                fullWidth
                            />
                            : null
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="secondary">{popupContent.cancelButtonText}</Button>
                    <Button onClick={() => handleConfirmAction(props.content.type)} disabled={popupContent.error} color="primary">{popupContent.successButtonText}</Button>
                </DialogActions>
            </Fragment>
        );
    }

    return (
        <div>
            <Dialog className={'ConfirmPopup'} open={props.content.open} onClose={props.close} aria-labelledby="form-dialog-title">
                {pageContent}
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestCancelWithdrawal: (amount, id) => dispatch(storeActions.requestCancelWithdrawal(amount, id)),
        onRequestCancelVivaWithdrawal: (amount, id) => dispatch(storeActions.requestCancelVivaWithdrawal(amount, id)),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPopup)));
