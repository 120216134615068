import React from 'react';
import Utils from "../../utils/Utils";

class ResponseErrorMessages {

    get(errorCode ) {
        errorCode = errorCode ? errorCode : 0;
        switch (errorCode.toString()) {
            case '1' :
                return Utils.t("The application has encountered an unknown error.");
            case '2' :
                return Utils.t("This player is already registered.");
            case '3' :
                return Utils.t("Invalid phone number.");
            case '4' :
                return Utils.t("Invalid email address.");
            case  '5' :
                return Utils.t("The minimum age requirement is not met.");
            case '6' :
                return Utils.t("The cnp is not valid.");
            case '7' :
                return Utils.t("Email is already registered");
            case '8' :
                return Utils.t("Phone number is already registered.");
            case '9' :
                return Utils.t("The application has encountered a saving error.");
            case  '10':
                return Utils.t("Inserted code is invalid.");
            case '11':
                return Utils.t("Current password is invalid.");
            case '12':
                return Utils.t("Authentication expired! Please login and try again.");
            case '13':
                return Utils.t("Invalid token.");
            case '14':
                return Utils.t("Phone number SMS limit was reached.");
            case '15':
                return Utils.t("Insufficient funds.");
            case '16':
                return Utils.t("Invalid request.");
            case '17':
                return Utils.t("Duplicate request.");
            case '18':
                return Utils.t("Negative amount.");
            case '19':
                return Utils.t("Cannot withdraw while having active bonuses.");
            case '20':
                return Utils.t("This email address is not registered");
            case '21':
                return Utils.t("This account is suspended. Please contact support.");
            case '22':
                return Utils.t("This account is closed. Please contact support.");
            case '23':
                return Utils.t("This account is self excluded.");
            default:
                return Utils.t("The application has encountered an unknown error.");
        }
    }
};

export default new ResponseErrorMessages();
