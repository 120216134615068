import React from 'react';
import { withTranslation } from 'react-i18next';
import './TransactionsHistory.scss';
import TitleBar from "../../UI/TitleBar/TitleBar";
import TabContainer from "../../UI/TabContainer/TabContainer";
import DepositAndWithdrawals from "./DepositAndWithdrawals/DepositAndWithdrawals";
import AllBonuses from "./AllBonuses/AllBonuses";
import Game from "./Game/Game";
import Sports from "./../../../bets/components/TransactionsHistorySports/Sports";
import Virtuals from "./../../../virtuals/components/TransactionsHistoryVirtuals/Virtuals";
import Lotto from "./../../../lotto/components/TransactionsHistoryLotto/Lotto";

const TransactionsHistory = props => {

    const tabs = [
        {
          id: 1,
          label: props.t('IN & OUT'),
      component: <DepositAndWithdrawals key={"DepositAndWithdrawals"} />,
        },
        {
          id: 2,
          label: props.t('transaction_history_bonus_title'),
      component: <AllBonuses key={"DepositAndWithdrawals"} />,
        },
        {
          id: 3,
          label: props.t('Game'),
      component: <Game key={"Game"} />,
        },
    ]

    if (window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")) {
    	tabs.push({
    		id: 4,
    		label: props.t('Sports'),
      component: <Sports key={"Sports"} />,
    	})
    }

  if (window.config && (window.config.lottoEnabled === "1")) {
    tabs.push({
      id: 6,
      label: props.t('Lotto'),
      component: <Lotto key={"Lotto"} />,
    })
  }

    if (window.config && window.config.virtualsEnabled === "1") {
      tabs.push({
        id: 5,
        label: props.t("Virtuals"),
      component: <Virtuals key={"Virtuals"} />
      })
    }

    return (
        <div className={"TransactionsHistoryScreen"}>
            <TitleBar pageTitle={props.t('Transactions History')}/>
            <TabContainer tabs={tabs} />
        </div>
    );
}

export default withTranslation()(TransactionsHistory);
