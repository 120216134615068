import React, {Component, Fragment} from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as storeActions from "../../store/actions";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ResponseErrorMessages from "../../store/errors/ResponseErrorMessages";
import Moment from "react-moment";
import './AccountProblem.scss';

class AccountProblem extends Component {
    close() {
        this.props.closeModal();
        this.props.onLogout();
    }

    supportHandler(event) {
        this.props.closeModal();
        this.props.onLogout();
        this.props.history.push("/customer-support/about-us")
    }

    getMessageAdditions(code) {
        let htmlMessage = null;

        if (23 === code) {
            if (this.props.authentication.accountProblemMessage) {
                let exclusionInterval = this.props.authentication.accountProblemMessage.split("-");
                htmlMessage = (
                    <Fragment>
                        <span>
                            {this.props.t("Self exclusion period") + ' ' + this.props.t("from") + ' '}
                            <Moment format={"DD/MM/YYYY"} unix>{exclusionInterval[0]}</Moment>
                            {' ' + this.props.t("to") + ' '}
                            <Moment format={"DD/MM/YYYY"} unix>{exclusionInterval[1]}</Moment>.
                        </span>
                        <br/>
                        <span>{this.props.t("If you want to cancel this action, please contact support.")}</span>
                    </Fragment>
                );
            }
        }

        return htmlMessage;
    }

    render() {
        if (!this.props.authentication.accountProblemModal) return null;

        return (
            <div>
                <Dialog
                    open={this.props.authentication.accountProblemModal}
                    className="AccountProblemModal"
                    onClose={this.close.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{ResponseErrorMessages.get(this.props.authentication.accountProblemCode)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.getMessageAdditions(this.props.authentication.accountProblemCode)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close.bind(this)} color="secondary">{this.props.t("Close")}</Button>
                        <Button className={"verifyButton"} onClick={this.supportHandler.bind(this)} color="primary">{this.props.t("Open support")}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(storeActions.closeAccountProblemModal()),
        onLogout: () => dispatch(storeActions.logout()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps,mapDispatchToProps)(AccountProblem)));