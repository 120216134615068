import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as storeActions from "../../../../store/actions";
import Utils from "../../../../utils/Utils";
import "./BonusWidget.scss";
import Slider from "react-slick";
import Icons from "../../../../assets/Icons/Icons";

import { withTranslation } from "react-i18next";
import { getSelectedBonusValue } from "./BonusWidget";
import ClaimPopup from "../../../ClaimBonus/ClaimPopup/ClaimPopup";

class BonusAvailableWidget extends Component {

    state = {
        bonus: false,
        time: 0
    }

    componentWillMount() {
        // if (!this.props.promotions.length) {
        this.props.getPromotions();
        let hash = window.location.hash;

        if (hash.length) {
            if (this.props.onClickedBonus) {

                this.setState({
                    ...this.state,
                    bonus: parseInt(hash.substr(1), 10)
                });
                this.props.onClickedBonus(parseInt(hash.substr(1), 10), this.props.onClickedBonus);
            }
        }

        // }
        // this.props.requestTransactionsHistory();
    }

    componentDidMount() {
        if (this.state.bonus) {
            window.location.hash = ''
        }
    }
    initTimer(time) {
        this.setState({
            ...this.state,
            time: this.props.bonus.bonus_available_claim_expiration
        })
    }

    decrementTimer() {
        this.setState({
            ...this.state,
            time: this.state.time + 2
        })
    }

    getTimer() {
        return (<div className="timer" key={"bonustimer"}></div>);
        let time = parseInt(this.state.time);
        let now = new Date().getTime() / 1000;
        let days = parseInt((time - now) / 86400, 10);

        let diffSeconds = parseInt((time - now - (days * 86400)), 10);
        if (diffSeconds < 0) {
            diffSeconds = -diffSeconds;
        }
        let hour = parseInt(diffSeconds / 3600, 10);
        let minutes = parseInt((diffSeconds - (hour * 3600)) / 60, 10);
        let seconds = parseInt(diffSeconds - (hour * 3600) - (minutes * 60), 10);
        let daysHTML = []

        if (days) {
            daysHTML.push(<div className="days" key={"bonusdays"}>{-days} zile</div>)
        }

        Utils.delayedFunction('decrementTimer', this.decrementTimer.bind(this), 1000);

        return (
            <div className="timer" key={"bonustimer"}>
                {daysHTML}
                <div className={"time"} > {hour < 10 ? "0" + hour : hour}:{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds} sec</div>
            </div>
        )

        return (
            <div className="timer" key={"bonustimer"}>

                <div className={"time"} >{this.props.t("Time left:")}</div> {this.props.t("Unlimited")}
            </div>
        )
    }

    clickBonus(bonus) {

        if (typeof bonus.bonus_conflicts !== "undefined") {
            if (Object.keys(bonus.bonus_conflicts).length > 0) {
                return;
            }
        }

        if (this.props.onClickedBonus) {
            this.props.onClickedBonus(bonus.bonus_available_id, bonus)
        } else {
            this.props.history.push('/deposit#' + bonus.bonus_available_id)
        }
    }
    forceBonus(bonusId) {
        console.log('-------------------------------')
        console.log(bonusId)
        console.log('-------------------------------')
        if (bonusId > 0) {
            this.props.claimBonus(bonusId, true);
        }
    }
    render() {

        let bonusList = [];
        let counter = 0;
        let bonusNotAvailableCounter = 0
        if (this.props.bonuses) {
            Object.keys(this.props.bonuses).forEach((key) => {
                counter++;
                let skip = false;
                if (this.props.type === "compact") {
                    if (this.props.bonuses?.[key]?.bonus_available_id !== this.props.bonusAvailableId) {
                        skip = true
                    }
                } else if (bonusList.length > 0 && this.props.type === 'promo-page') {
                    skip = true;
                }
                if (Object.keys(this.props.bonuses?.[key]?.bonus_conflicts).length) {
                    bonusNotAvailableCounter++;
                }
                if (!skip) {
                    let bonus = this.props.bonuses[key]
                    let products = [];
                    if (bonus) {
                        if (bonus.eligible_products) {
                            bonus.eligible_products.forEach((key) => {
                                switch (key) {
                                    case 3:
                                    case "3":
                                        products.push(this.props.t("Lotto"));
                                        break;
                                    case 2:
                                    case "2":
                                        products.push(this.props.t("Sportsbook"));
                                        break;
                                    case 1:
                                    case "1":
                                        products.push(this.props.t("Casino"));
                                        break;
                                    default:
                                        break;

                                }
                            })
                        }

                        let bonusReceived;
                        if (!this.props.grayscale) {
                            bonusReceived = getSelectedBonusValue(bonus, this.props.depositValue,{bonusAward: this.props.bonusAward});
                            if (this.props.type === "compact") {
                                bonusReceived = `+${bonusReceived} Bonus`;
                            }
                        }

                        let bonusConflicts = [];

                        if (bonus.bonus_conflicts) {
                            Object.keys(bonus.bonus_conflicts).forEach((key) => {
                                switch (key) {
                                    case 6:
                                    case "6":
                                        bonusConflicts.push(<div> * {this.props.t("Wager the active bonuses first")}</div>);
                                        break;
                                    case 19:
                                    case "19":
                                        bonusConflicts.push(<div> * {this.props.t("Requires email verification")}</div>);
                                        break;
                                    case 20:
                                    case "20":
                                        bonusConflicts.push(<div> * {this.props.t("Requires identity verification")}</div>);
                                        break;
                                    default:
                                        bonusConflicts.push(<div> * {this.props.t("Temporarily unavailable")}</div>);
                                        break
                                }
                            })
                        }

                        bonusList.push(<div
                            className={"BonusWidget" + (this.props.type === "compact" ? " compact" : "") /*+ (this.props.grayscale ? " grayscale" : "")*/} key={'bonus-widget-' + bonus.bonus_available_id}>
                            <div className="details" /*onClick={() => this.props.history.push('/cazino/promotii')}*/>
                                <div className="img">
                                    <img
                                        src={this.props.type === "compact" ? bonus.deposit_wizard_image : bonus.icon_image}
                                        alt="" />
                                </div>
                                <div className="other-details"
                                    style={this.props.type === "compact" ? { background: "linear-gradient(90deg, transparent 30%, #f5f8fa 60%)" } : bonus.deposit_wizard_gradient ? { background: bonus.deposit_wizard_gradient } : {}}>
                                    <h2 className="title">
                                        {this.props.t("FREE")}
                                    </h2>
                                    {this.props.type === "compact" && (
                                        <div className={'deposit-details'}>
                                            {/*{this.props.grayscale && (*/}
                                            {/*    <div className={'alert'}>*/}
                                            {/*        <div className="atitle">{this.props.t("Bonus requirements:")}</div>*/}
                                            {/*        <div className="list">{this.props.t(this.props.t("Minimum deposit:"))} <strong>{Utils.formatAmount(bonus.bonus_available_min * 100 / bonus.bonus_available_amount, false, "Lei")}</strong></div>*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                            {!this.props.grayscale && (
                                                <div>
                                                    <span className={"icon"}>
                                                        {Icons.get('bonusValue')}
                                                    </span>
                                                    <span>{bonusReceived}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {this.props.type !== "compact" && (<div className="bonus">
                                        <div className="name">
                                            {bonus.bonus_available_name}
                                            <div className="type">
                                                {products.join(', ')}
                                            </div>
                                        </div>
                                        {this.props.type !== "compact" && (<div className="value">
                                            {bonusReceived}
                                        </div>)}
                                    </div>)}
                                </div>
                            </div>
                            {bonusConflicts.length > 0 && <div className="bonus-conflicts">
                                <div>{Icons.get('exWarning')} Conditii de activare neindeplinite:</div>
                                {bonusConflicts}
                            </div>}
                            {this.props.type !== "compact" && (<div className="cta">
                                {this.getTimer(key)}
                                <a className={'button' + (bonusConflicts.length > 0 ? " disabled" : "")}
                                    onClick={this.clickBonus.bind(this, bonus)} >{this.props.cta_text ? this.props.cta_text : this.props.t("GET BONUS")}</a>
                                {bonus.terms_url && <a href={bonus.terms_url} className={'bonus-terms'} target={"_blank"}>{this.props.t("Terms & conditions")}</a>}
                            </div>)}
                        </div>);
                    }
                }

            })

        }

        if (this.props.bonuses === null && this.props.type !== 'promo-page') {
            bonusList = (<div className={"BonusWidget loading"}>
                <div className="details" /*onClick={() => this.props.history.push('/cazino/promotii')}*/>
                    <div className="img" style={{ backgroundImage: "url(" + window.location.protocol + '//' + window.config.cdn_address + "/public/bonus/img-bonuscard-nobonus-luminosity@3x.png)" }}>

                    </div>
                    <span></span>
                    <div className="message">
                        Se verifica bonusurile....
                    </div>
                </div>
            </div>)
        }

        if (this.props.type === 'list' || this.props.type === "compact" || this.props.type === 'promo-page') {
            let more = [];
            //console.log('selected', this.props.selected);
            more.push(<ClaimPopup key="ClaimPopup" selectedBonus={this.props.selected} popup={true} getBonus={() => this.forceBonus(this.props.selected ? this.props.selected.bonus_available_id : false)} />)
            if (counter > 0) {

                if (this.props.type === 'promo-page') {
                    let bonusAvailableCounter = counter - bonusNotAvailableCounter;
                    more.push(<div key="bonus-available-more" className={'bonus-available-more' + (bonusAvailableCounter < 2 ? ' grayscale' : '')} onClick={() => this.props.history.push('/claim-bonus')}>
                        <div>
                            <span>
                                {(bonusAvailableCounter < 2 ? 0 : '+' + bonusAvailableCounter - 1)}
                            </span>
                        </div>
                        <div>{this.props.t('MORE_PROMOTIONS')}</div>
                        <div>
                            <div>
                                {Icons.get('profileMenuArrow')}
                            </div>
                        </div>
                    </div>)
                }
            }
            return (<>
                {more.length > 1 && <h1>{Icons.get('hotGameIcon')} {this.props.t('Ready to CLAIM')}</h1>}
                <div className={'bonusAvailable'}>
                    {bonusList}
                    {more}
                </div>
            </>)
        }
        const slick_settings = {
            dots: true,
            arrows: false,
            autoplay: false,
            easing: "easeOutQuart",
            draggable: true,
            infinite: false,
            slidesToShow: 1.1,
            slidesToScroll: 1,
        };
        return (
            <Slider className={"bonusCarousel"} {...slick_settings} >
                {bonusList}
            </Slider>
        );
    }

};

const mapStateToProps = state => {
    let bonusA = [];
    let bonusB = [];
    if (state.application.availableBonuses) {
        Object.keys(state.application.availableBonuses).forEach((key) => {
            if (Object.keys(state.application.availableBonuses[key].bonus_conflicts).length === 0) {
                bonusA.push(state.application.availableBonuses[key]);
            } else {
                bonusB.push(state.application.availableBonuses[key]);
            }
        })
    }
    return {
        bonuses: bonusA.concat(bonusB),
        profile: state.profile,
        transactions: state.transactions,
        wallet: state.wallet,
        bonusAward: state.application.bonusAward,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        closeRightMenu: () => dispatch(storeActions.closeRightMenu()),
        getPromotions: () => dispatch(storeActions.getEligibleBonuses()),
        // requestTransactionsHistory: () => dispatch(storeActions.requestTransactionsHistory(3))
        claimBonus: (bonusId, force) => dispatch(storeActions.claimBonus(bonusId, force)),

    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)((BonusAvailableWidget))));
