import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation} from "react-i18next";
import {TextField, MenuItem} from "@material-ui/core";
import Icons from "../../../../assets/Icons/Icons";
import GTM from "../../../../utils/GTM";

class Citizenship extends Component {
    constructor(props) {
        super(props);
        this.getDefaultCountry();
        this.getCountries();
    }
    getDefaultCountry() {
        // let axios = BackendClient.getInstance();
        // axios({
        //     url: 'api/countries/default',
        //     method: 'get',
        // }).catch(e => {
        //     console.log(`[CATCHED **GET COUNTRIES** ERROR] => ${e}`);
        // });
    }
    getCountries() {
        // let axios = BackendClient.getInstance();
        // axios({
        //     url: 'api/countries',
        //     method: 'get',
        // }).catch(e => {
        //     console.log(`[CATCHED **GET COUNTRIES** ERROR] => ${e}`);
        // });
    }

    changeHandler(event){
        let fieldInput;
        if (null === event) {
            fieldInput = this.props.citizenship;
        } else {
            fieldInput = event.target.value;
        }
        if (fieldInput === "") {
            fieldInput = this.props.defaultCountry;
        }
        GTM.pushGAEvent('Register_' + this.props.lang,'Field - OK', "Citizenship");

        this.props.onChange(fieldInput);
    }

    render() {

        let countries =  [{value: 'RO', label: "Romania"}];

        return (
            <div className={'field hide-select-icon'}>
                {Icons.get('world-globe')}
                <TextField
                    id="citizenship"
                    select
                    label={this.props.t("Citizenship")}
                    className={""}
                    value={"RO"}
                >
                    {countries.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

const mapStateToProps = state => {
    return {
        lang: state.application.language
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Citizenship));
