import React from 'react';
import Button from "@material-ui/core/Button";
import connect from "react-redux/es/connect/connect";
import { withTranslation } from 'react-i18next';
import Moment from "react-moment";
import moment from "moment";

import "./FreeSpin.scss";
import Icons from "../../../../assets/Icons/Icons";
import { freeSpinsFetchHistory } from "../../../../store/actions/free_spins";
import { fetchFreeSpinsDumpHistory } from "../../../../store/sagas/free_spins";

const visibleStyle = {
  overflow: "visible"
};

const exportToCsv = (filename, rows) => {
  const processRow = row => {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const formatCurrency = (value, small) => {
  if (small === 1) value = value / 100;

  return (
    value
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      .replace(',00', '')
  )
};

const processDump = (list, t) => {
  const listData = [];

  const bonusStatus = {
    0: t("other"),
    1: t("active"),
    2: t("inactive"),
    3: t("expired"),
    4: t("canceled"),
    6: t("collected"),
  };

  const handleList = (bonus) => {
    let name = bonus.bonus_name;

    let title = `${bonus.remaining_free_spins}/${bonus.total_free_spins} ${t("Spins")}`;
    let amount = ``;

    listData.push([
      moment.unix(bonus.insert_date).format("DD/MM/YYYY HH:mm:ss"),
      t(name),
      title,
      amount,
      t(bonusStatus[bonus.bonus_status])
    ]);
  };

  list.forEach(handleList);

  listData.sort((ta, tb) => {
    if (ta[0] < tb[0]) {
      return -1;
    }
    if (ta[0] > tb[0]) {
      return 1;
    }
    return 0;
  });

  listData.unshift([t("Event Date"), t("Name"), t("Tokens"), t("Result"), t("Status")]);

  exportToCsv("free_bet_history.csv", listData);
};

const FreeSpin = (props) => {
  const { items, hasMore, freeSpinsFetchHistory, tabIndex, t } = props;

  const bonusStatus = {
    0: t("other"),
    1: t("active"),
    2: t("inactive"),
    3: t("expired"),
    4: t("canceled"),
    6: t("collected"),
  };

  const historic = [];
  items.forEach((bonus, i) => {

    let name = bonus.bonus_name;

    // let title = null;
    // if (bonus.status === 4) {
    //   title = <React.Fragment>
    //     <span className={"BonusAmount"}>{bonus.remaining_fs}/<small>{bonus.total_fs}</small></span>
    //     {/*<span className={"BonusGranted"}>- {formatCurrency(bonus.amount * bonus.count, bonus.amount_small) + " RON"}</span>*/}
    //   </React.Fragment>;
    // } else if (bonus.status === 6) {
    //   title = <React.Fragment>
    //     <span className={"BonusAmount"}>{bonus.remaining_fs}/<small>{bonus.total_fs}</small></span>
    //     {/*<span className={"BonusGranted"}>{formatCurrency(bonus.collected, bonus.collected_small) + " RON"}</span>*/}
    //   </React.Fragment>;
    // }

    historic.push(<div key={i} className={"SingleBonus"}>
      <span className={"BonusDate"}><Moment format={"DD/MM/YYYY"} unix>{bonus.insert_date}</Moment></span>
      <div className="BonusDescription">
        <span>{t(name)}</span>
        <div className="BonusTotals">
          <span className={"BonusAmount"}>{bonus.remaining_fs}<small>/{bonus.total_fs}</small> {t("Spins")}</span>
        </div>
      </div>
      <div className={"BonusStatus"}>
        <span className={"BonusStatus-" + bonus.bonus_status_id}>{t(bonusStatus[bonus.bonus_status_id])}</span>
      </div>
    </div>)
  });

  const [exportDisabled, setExportDisabled] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(() => {
    freeSpinsFetchHistory(currentPage)
  }, [currentPage, freeSpinsFetchHistory]);

  const downloadCsv = () => {
    const historyDumpReq = fetchFreeSpinsDumpHistory();

    historyDumpReq.then(list => {
      processDump(list, t);
    })
      .catch(e => {
        //console.log("failed to get tickets", e);
      });
  };

  const changePage = () => {
    setCurrentPage(v => {
      return v + 1;
    });
  }

  let style = null;
  if (tabIndex !== 1) {
    style = visibleStyle;
  }

  return (
    <div className={"BonusScreen free-spins-history"} style={style}>
      {historic}
      {/*{hasMore && <div className={"fbh-show-more"} onClick={changePage}>*/}
      {/*  {t("Show More")}*/}
      {/*</div>}*/}
      <Button className={'export'} onClick={downloadCsv} disabled={exportDisabled}>
        {Icons.get("historyCsvDownload", "historyCsvDownload")} {t("Download History for last 90 days")}
      </Button>
    </div>
  );
}

const mapPropsToState = () => {
  return (state, props) => {
    return {
      items: state.freeSpins.freeSpinsHistory,
      hasMore: state.freeSpins.freeSpinsHistoryHasMore,
    };
  }
}

const mapActions = {
  freeSpinsFetchHistory
};

export default connect(mapPropsToState, mapActions)(withTranslation()(FreeSpin));
