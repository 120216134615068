import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import InputBase from "@material-ui/core/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";

import { getLottoState, makeGetBonuses } from "../../store/selectors/lottoData";
import Delete from "./assets/delete.svg";
import {
  betsSlipClearLottoTicket,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betSlipLottoTicketCreate,
  betSlipLottoInitializeTickets,
  betSlipLottoBonusRequestEvaluation,
  betSlipLottoFreeBetClear
} from "../../store/actions/betsSlip";
import TicketModal from "./TicketModal";

import avatar from "./../NavBar/assets/user.svg";
import BounceButton from "../../elements/BounceButton";
//import TicketModal from "./TicketModal";
import DeleteModal from "./DeleteModal";
import evaluateTicket from "../../utils/evaluateTicket";

import { doLogin } from "../../../store/actions/authentication";

const useStyles = makeStyles({
  root: {
    position: "relative",
    paddingBottom: "15px"
  },
  infoWrapper: {
    width: "calc(100% - 22px)"
  },
  round: {

    fontSize: "11px",
    fontWeight: "600",
    color: "#06D2BD",
    marginRight: "4px",
    position: "relative",
    top: "1px"
  },
  time: {

    fontSize: "12px",
    fontWeight: "600",
    color: "#009ED9",
    marginRight: "6px"
  },
  teamFlag: {
    marginRight: "8px",
    "& > img": {
      display: "block",
      width: "11px",
      height: "11px",
      position: "relative",
      top: 3
    }
  },
  teamName: {

    fontSize: "12px",
    fontWeight: "600",
    color: "#17214D",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
    //flex: "1 1 0px"
  },
  betHolder: {
    marginTop: "8px"
  },
  betName: {
    fontWeight: "bold",

    fontSize: "14px",
    color: "#586682"
  },
  betWrapper: {
    minWidth: "90px"
  },
  betType: {
    fontWeight: "bold",

    fontSize: "14px",
    color: "#586682"
  },
  betValue: {
    fontWeight: "bold",

    fontSize: "14px",
    color: "#586682"
  },
  divider: {
    margin: "0 7px",
    fontSize: "12px"
  },
  delete: {
    "& img": {
      width: "14px",
      height: "18px"
    }
  },
  payHolder: {
    marginTop: "8px"
  },
  but: {
    width: "28px",
    height: "28px",
    textAlign: "center",
    lineHeight: "28px",
    color: "#fff",
    backgroundColor: "#009ED9",
    borderRadius: "50%",
    fontWeight: "bold",

    fontSize: "14px",

    "&.Mui-disabled": {
      opacity: ".5",
      pointerEvents: "none"
    }
  },
  amount: {
    backgroundColor: "#ECF9FF",
    border: "1px solid #009ED9",
    borderRadius: "10px",
    padding: "0",
    fontWeight: "bold",

    fontSize: "14px",
    color: "#17214D",
    margin: "0 5px"
  },
  amountInput: {
    fontSize: "16px",
    textAlign: "center",
    maxWidth: "40px",
    color: "#17214D",
    height: "32px",
  },
  amountEnd: {
    marginLeft: "4px",
    paddingRight: "4px",
    "& p": {
      fontWeight: 300,
      fontSize: "11px",
      whiteSpace: "nowrap"
    }
  },
  payButton: {
    padding: "6px 20px 5px 20px",
    background: "linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%)",

    color: "#fff",
    borderRadius: "11px",
    boxShadow: "0px 3px 6px rgba(0,0,0,.4)",
    minHeight: "43px",
    minWidth: "171px"
  },
  loginButton: {
    padding: "6px 10px 5px 10px",
    background: "#0430D5",

    color: "#fff",
    borderRadius: "11px",
    boxShadow: "0px 3px 6px rgba(0,0,0,.4)",
    minHeight: "43px"
  },
  payButtonDisabled: {
    background: "linear-gradient(90deg, #A7A7A7 0%, #DBDBDB 100%)",
    boxShadow: "none"
  },
  hr: {
    backgroundColor: "#B7CDD8",
    height: "1px",
    width: "100%",
    marginTop: "5px",
    marginBottom: "5px"
  },
  p0: {
    padding: 0,
    height: "18px",
    "&>img": {
      height: "100%"
    }
  },
  buttonTitle: { fontWeight: "600", fontSize: "14px" },
  buttonSubtitle: { fontSize: "9px" },
  ball: {
    width: "29px",
    height: "29px",
    borderRadius: "50%",
    border: "1.5px solid #3D4D8E",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "600",
    color: "#121934",
    lineHeight: "24px",
    margin: "0 2px 3px 0",
    background: "#fff",
    "&:first-child": {
      marginLeft: "0px"
    },
    "&.ln": {
      background: "radial-gradient(circle at 50% 0%, rgba(248,159,28,1) 0%, rgba(255,124,58,1) 23%, rgba(255,124,58,1) 25%, rgba(255,63,33,1) 65%, rgba(246,44,44,1) 67%, rgba(154,36,0,1) 100%)",
      border: "1.5px solid #FFD258",
      color: "#fff",
    },
    "&.ln.ds": {
      background: "linear-gradient(180deg, #919191 0%, #525252 100%)",
      position: "relative",
      "&::after": {
        position: "absolute",
        borderRadius: "4px",
        width: "12px",
        height: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#FF7600",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "10px",
        content: '"!"',
        top: "-8px",
        right: "-5px",
        border: "1px solid #fff",
      }
    }
  },
  ballNoValue: {
    border: "1.5px solid #E9E9E9",
    opacity: ".6",
    color: "#C7CCE1"
  },
  drawsText: {
    textAlign: "center",
    fontSize: "12px",
    color: "#5A6798",
    marginBottom: "5px"
  }
});

const twoDecimalsFloat = vf => {
  let v = parseFloat(vf)
  if (isNaN(v)) {
    return v;
  }
  v = parseFloat(v.toFixed(2));
  return v;
}

const BetLine = props => {
  const classes = useStyles();

  const {
    amount,
    stakeInc,
    stakeDec,
    stakeSet,
    clearBet,
    wallet,
    profile,
    auth,
    walletBonusEvaluate,
    lottoTicket,
    betSlipLottoInitializeTickets,
    freeBets,
    selectedFreeBet,
    betSlipLottoFreeBetClear,
    bonuses,
    requestBonusEvaluation,
    t
  } = props;

  const [stakeAmount, setStakeAmount] = React.useState(amount);
  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const [amounts, setAmounts] = React.useState({
    totalLines: 0,
    winAmount: 0,
    minWinAmount: 0,
  });

  React.useEffect(() => {
    //console.log("wallet update", wallet);
    requestBonusEvaluation();
  }, [wallet, profile, lottoTicket, amount, requestBonusEvaluation]); // eslint-disable-line

  const roundThousands = (value) => {
    if (typeof value == "string") return value;
    if (!value) return "0.00";

    const decimals = 2;
    const tmp = value / 10000;

    let res = Number(
      Math.round(tmp + "e" + decimals) + "e-" + decimals
    ).toFixed(decimals);

    return parseFloat(res);
  };

  React.useEffect(() => {
    if (lottoTicket) {
      const systems = lottoTicket.systems;
      if (systems.length) {
        const ticket = Object.assign({}, lottoTicket.event, {
          stake_amount: Math.round(amount * 100) / 100,
          stk: amount,
          tx: 0,
          max_winning: 100000,
          numbers: [...lottoTicket.numbers].sort(),
          systems: [...systems].sort(),
        });
        const res = evaluateTicket(ticket);

        setAmounts({
          totalLines: res.lines,
          winAmount: roundThousands(res.max_winning),
          minWinAmount: roundThousands(res.min_winning),
        });
      } else {
        setAmounts({
          totalLines: 0,
          winAmount: 0,
          minWinAmount: 0,
        });
      }
    }
  }, [lottoTicket, amount]);

  const handlePlaceBetClose = type => {
    clearBet();
    setOpenPlaceBet(false);
    document.body.classList.remove("no-scroll", "betslip-on", "quick-bet", "lotto-quick-bet", "summary-bet", "mini-bet", "full-bet");
    if (type === true) {
      window.location.hash = "#tickets-details";
    }
  };

  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    setStakeAmount(amount);
  }, [amount]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYesClose = () => {
    setOpen(false);
    clearBet();
  };

  const onStakeInc = () => {
    betSlipLottoFreeBetClear();
    stakeInc(-1);
  };

  const onStakeDec = () => {
    betSlipLottoFreeBetClear();
    stakeDec(-1);
  };
  const handleStakeChange = e => {
    let ev = e.target.value;
    if (selectedFreeBet !== -1) {
      betSlipLottoFreeBetClear();
    };

    // let them input an empty string
    if (ev === "") {
      setStakeAmount("");
      stakeSet("");
      return;
    }

    // don't let them put an amount < 1
    if (ev === "0") {
      return;
    }

    // convert , to .
    ev = ev.split(",").join(".");

    // only one .
    const sev = ev.split(".");
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      return;
    }

    // only valid numbers
    if (isNaN(+ev)) {
      return;
    }

    let v = "";

    if (ev !== "") {
      // amounts ending with . have the same value
      if (ev.endsWith(".")) {
        setStakeAmount(ev);
        return;
      }

      // convert amount
      v = parseFloat(ev);
      if (isNaN(v)) return;
    }

    // update amount
    setStakeAmount(ev);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const createTicket = () => {
    const tickets = [];
    lottoTicket.allSelectedEvents.forEach((s, i) => {
      const ticketData = {
        numbers: lottoTicket.numbers,
        systems: lottoTicket.systems,
        event_id: s,
        amount: amount,
        date: lottoTicket.allSelectedEventsFull[i].event_date
      };

      if (lottoTicket.extra) {
        const tmp = { ...lottoTicket.extra };

        if (selectedFreeBet > -1) {
          const freeBet = freeBets[selectedFreeBet];
          tmp.free_bet = freeBet.code;
        }

        const bonus_user_data = [];

        if (tmp.free_bet) {
          bonus_user_data.push({
            type: "free_bet",
            id: tmp.free_bet,
            data: "{}"
          });
        } else {
          if (tmp.luckyNumber && bonuses && typeof bonuses.lotto_lucky_number !== "undefined") {
            bonus_user_data.push({
              type: "lotto_lucky_number",
              id: bonuses.lotto_lucky_number.code,
              data: JSON.stringify({
                number: tmp.luckyNumber,
              })
            });
          }

          if (bonuses && typeof bonuses.lotto_chance !== "undefined") {
            bonus_user_data.push({
              type: "lotto_chance",
              id: bonuses.lotto_chance.code,
              data: "{}"
            });
          }
        }

        //console.log("bonus_user_data", bonus_user_data);

        ticketData.bonus_user_data = JSON.stringify(bonus_user_data);
      }
      tickets.push(ticketData);
    });

    const event = {
      event_name: lottoTicket.event.event_name,
    };

    betSlipLottoInitializeTickets({ event, tickets });
    setOpenPlaceBet(true);
  };

  const handleLogin = () => {
    localStorage.setItem("redirect_after_login", location.pathname);
    history.push("/connect");
  };

  let loggedIn = false;
  if (auth && auth.auth_type === "user") {
    loggedIn = true;
  }

  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  if (window.config.useBonusEvaluation === "1") {
    if (walletBonusEvaluate && walletBonusEvaluate.success && walletBonusEvaluate.data && walletBonusEvaluate.data.bonus) {
      bLimit = twoDecimalsFloat(walletBonusEvaluate.data.free_money_used);
      valid = walletBonusEvaluate.data.valid;
    } else {
      valid = bLimit >= amount;
    }
  }

  let hasUsedBonusMoney = false;
  if (walletBonusEvaluate && walletBonusEvaluate.data && walletBonusEvaluate.data.eligible && walletBonusEvaluate.data.valid) {
    let bonus_used = 0;
    walletBonusEvaluate.data.bonus.forEach(b => {
      const bu = parseFloat(b.balance_used);
      if (!isNaN(bu) && bu > 0) {
        bonus_used += bu;
      }
    });

    if (bonus_used > 0) {
      hasUsedBonusMoney = true;
    }
  }

  return (
    <div className={classes.root}>
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div className={`${classes.infoWrapper} d-flex flex-row flex-wrap flex-grow-1`}>
          {[...Array(lottoTicket.event.k).keys()].map(i => {
            if (i < lottoTicket.numbers.length) {
              const isLN = lottoTicket && lottoTicket.extra && lottoTicket.extra.luckyNumber && lottoTicket.extra.luckyNumber === lottoTicket.numbers[i];

              return (
                <div key={i} className={`${classes.ball} ${isLN ? 'ln' : ''} ${isLN && hasUsedBonusMoney ? 'ds' : ''}`}>
                  {lottoTicket.numbers[i]}
                </div>
              );
            }
            return (
              <div key={i} className={`${classes.ball} ${classes.ballNoValue}`}>
                -
              </div>
            );
          })}
        </div>
        <div>
          <div className={classes.p0} onClick={handleClickOpen}>
            <img src={Delete} alt="" />
          </div>
        </div>
      </div>
      <div className={classes.hr}></div>

      <div
        className={`{classes.payHolder} d-flex flex-row flex-nowrap align-items-end justify-content-between`}
      >
        <div>
          <div className={classes.drawsText}>
            {selectedFreeBet !== -1 ? (freeBets[selectedFreeBet] && freeBets[selectedFreeBet].name ? freeBets[selectedFreeBet].name : "N/A") : ''} {amount * lottoTicket.allSelectedEvents.length} Lei {t("total")}
          </div>
          <div className={`${classes.payAmount} d-flex flex-row flex-nowrap align-items-center`}>
            <BounceButton>
              <ButtonBase onClick={onStakeDec} variant="contained" className={classes.but} disabled={selectedFreeBet !== -1}>
                -
              </ButtonBase>
            </BounceButton>
            {/*<div className={classes.amount}>{amount} lei</div>*/}
            <InputBase
              type="text"
              onChange={handleStakeChange}
              className={`${classes.amount} ${amount < 2 ? classes.warning : ""}`}
              value={stakeAmount}
              inputProps={{
                className: classes.amountInput,
                "aria-label": "stake",
                pattern: "[0-9\.]*"
              }}
              endAdornment={
                <InputAdornment
                  className={`${classes.amountEnd} ${amount < 2 ? classes.warning : ""}`}
                  position="end"
                >
                  lei
                </InputAdornment>
              }
            />
            <BounceButton>
              <ButtonBase onClick={onStakeInc} variant="contained" className={classes.but} disabled={selectedFreeBet !== -1}>
                +
              </ButtonBase>
            </BounceButton>
          </div>
        </div>
        <div className={classes.payButtonHolder}>
          {loggedIn && (
            <ButtonBase
              variant="contained"
              className={`${classes.payButton} ${(lottoTicket && lottoTicket.allSelectedEvents.length === 0) || !amount || (!valid && selectedFreeBet === -1) ? classes.payButtonDisabled : ""}`}
              onClick={createTicket}
              disabled={(lottoTicket && lottoTicket.allSelectedEvents.length === 0) || !amount || (!valid && selectedFreeBet === -1)}
            >
              <div>
                {lottoTicket.allSelectedEvents.length <= 1 && <div className={classes.buttonTitle}>{t("PLACE TICKET")}</div>}
                {lottoTicket.allSelectedEvents.length > 1 && <div className={classes.buttonTitle}>{t("PLACE TICKETS")}</div>}
                <div className={classes.buttonSubtitle}>
                  {t("Potential winning")} {amounts && amounts.winAmount && typeof amounts.winAmount.toFixed === "function" ? amounts.winAmount.toFixed(2) : "0.00"} lei
                </div>
              </div>
            </ButtonBase>
          )}
          {!loggedIn && (
            <ButtonBase
              variant="contained"
              className={`${classes.loginButton}`}
              onClick={handleLogin}
            >
              <div>
                <div className={classes.buttonTitle}>
                  <img src={avatar} alt="" className={classes.avatar} /> {t("ENTER YOUR ACCOUNT")}
                </div>
              </div>
            </ButtonBase>
          )}
        </div>
      </div>
      <DeleteModal open={open} handleClose={handleClose} handleYesClose={handleYesClose} />
      <TicketModal openPlaceBet={openPlaceBet} handlePlaceBetClose={handlePlaceBetClose} />
    </div>
  );
};

BetLine.propTypes = {
  bet: PropTypes.object
};

BetLine.defaultProps = {
  bet: {}
};

const mapStateToProps = () => {
  const getBonuses = makeGetBonuses();

  return (state, props) => {
    const bst = getLottoState(state);
    const ct = bst.betsSlip;

    let event_id = "";
    if (bst.betsSlip.lottoTicket && bst.betsSlip.lottoTicket.event && bst.betsSlip.lottoTicket.event.event_id) {
      event_id = bst.betsSlip.lottoTicket.event.event_id;
    }

    return {
      amount: bst.betsSlip.amount,
      wallet: state.wallet,
      profile: state.profile,
      walletBonusEvaluate: ct.lottoTicket && ct.lottoTicket.bonusEvaluate ? ct.lottoTicket.bonusEvaluate : null,
      auth: state.authentication,
      freeBets: state.freeBets.freeBets,
      selectedFreeBet: ct.selectedFreeBet,
      bonuses: event_id !== "" ? getBonuses(state, { event_id }) : []
    };
  };
};

const actionCreators = {
  clearBet: betsSlipClearLottoTicket,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  lottoTicketCreate: betSlipLottoTicketCreate,
  betSlipLottoInitializeTickets: betSlipLottoInitializeTickets,
  doLogin: doLogin,
  requestBonusEvaluation: betSlipLottoBonusRequestEvaluation,
  betSlipLottoFreeBetClear: betSlipLottoFreeBetClear,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(BetLine));
