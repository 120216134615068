import React, { useState } from 'react';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import TitleBar from '../UI/TitleBar/TitleBar';
import EmailConfirmation from "./Steps/EmailConfirmation/EmailConfirmation";
import NewPassword from "./Steps/NewPassword/NewPassword";
import CircularProgress from '@material-ui/core/CircularProgress';
import './ResetPassword.scss';

const ResetPassword = (props) => {

    const [currentStep, setCurrentStep] = useState(1);

    const currentStepHandler = (step) => {
        if (step === 0) {
            props.history.push('/');
        } else {
            setCurrentStep(step);
        }
    }

    let contentHandler = <CircularProgress className={'Loading'} />;

    if (currentStep === 1) {
        contentHandler = <EmailConfirmation nextStep={(nextStep) => currentStepHandler(nextStep)} />;
    }

    if (currentStep === 2) {
        contentHandler = <NewPassword />;
    }

    return (
        <div className={"ResetPasswordScreen"}>
            <TitleBar
                backButton={currentStep !== 1}
                pageTitle={props.t('Reset Password')}
                backHandler={() => currentStepHandler(currentStep - 1)}
                backButton={currentStep === 2 ? false : true}
                closeButton={true}
                closeHandler={() => currentStepHandler(0)}
            />
            <div className="ResetPasswordScreenContent">
                {contentHandler}
            </div>
        </div>
    );
};

export default withTranslation()(withRouter(ResetPassword));