import React from 'react';
import { withTranslation } from 'react-i18next';
import './PaymentMethods.scss';
import TitleBar from "../../UI/TitleBar/TitleBar";
import TabContainer from "../../UI/TabContainer/TabContainer";
import Deposit from "./Deposit/Deposit";
import Withdrawal from "./Withdrawal/Withdrawal";

const PaymentMethods = props => {

    const tabs = [
        {
          id: 1,
          label: props.t('Deposit'),
          component: <Deposit />,
        },
        {
          id: 2,
          label: props.t('Withdrawal'),
          component: <Withdrawal />,
        },
        
      ]

    return (
        <div className={"PaymentMethodsScreen"}>
            <TitleBar pageTitle={props.t('Legal')}/>
            <TabContainer tabs={tabs} />
        </div>
    );
}

export default withTranslation()(PaymentMethods);