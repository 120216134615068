import React from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import LanguageSwitcher from "../../../GlobalWidgets/Both/LanguageSwitcher/LanguageSwitcher";

const SingleAction = (props) => {

    return (
        <div className="singleAction">
            <ButtonBase className={"actionHandler"} onClick={props.link ? () => props.history.push(props.link) : null}>
                <div className="actionIcon">
                    {props.icon}
                </div>
                <div className="actionContent">
                    <h4>{props.title}</h4>
                    <span className={!props.docsOk && props.id === 'idCard' ? 'RequiresVerification' : ''}>{props.subtitle}</span>
                </div>
                <div className="actionEdit">
                    {
                        props.id === 'language'
                            ? <div className="LanguageSwitcherAction">
                                <LanguageSwitcher />
                            </div>
                            : <span className="editAction">{props.action}</span>
                    }
                </div>
            </ButtonBase>
        </div>
    );
};

export default (withRouter(SingleAction));