import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../store/actions";
// import RecentWinners from './../Jackpot/RecentWinners';
// import TickerWinners from './../Jackpot/TickerWinners';
import HeaderV2 from './../Jackpot/HeaderV2';
import WinHistory from '../Jackpot/WinHistory';
import Game from '../CasinoGames/sections/Game/Game';
import CircularProgress from '@material-ui/core/CircularProgress';
import WinHistoryEgtLogo from './../../assets/Images/Jackpot/img-jackpotlogo-winhistory-egt@3x.png';
import './Collections.scss';

const SINGLE_ROW = 1;
const DOUBLE_ROW = 2;
const DOUBLE_ROW_NO_PROMOTED = 3;

const Collections = (props) => {
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        props.isRequestGameCollections();
    }, []);

    const launchHandler = (gameItem) => {
        props.openGame(typeof gameItem.game_id !== "undefined" ? gameItem.game_id : gameItem.id );
        props.setGame(gameItem);
    };

    const goToCollection = (id) => {
        props.history.push('/cazino/collection/' + id);
    };

    const getGameCollections = () => {
        let mainContent = <CircularProgress className={'Loading'} />;
        if (!props.games.loadingGameCollections && props.games.gameCollections && props.games.gameCollections.length > 0) {
            let collections = props.games.gameCollections.filter(Boolean);
            for (let i = 0; i < collections.length; i++) {
                if(collections[i].jackpot_id){
                    collections[i].firstJackpot = true;
                    break;
                }
            }
            mainContent = collections.map((collection, index) => {
                if(!collection.type) {
                    collection.type  = DOUBLE_ROW;
                }
                
                let gameContent = [];
                let jackpotContent = '';
                if (collection.items) {
                    if (collection.jackpot_id) {
                        collection.type = DOUBLE_ROW;
                        gameContent.push(
                            <div className={"GameItem FirstGame"} key={collection.items.length + 'Jackpot'}>
                                <HeaderV2
                                    integration={"mobile"}
                                    jackpotId={collection.jackpot_id}
                                    collectionId={collection.id}
                                    front={true}
                                    onClick={() => setShowModal(true)}
                                    name={"EGT"}
                                    value={props.jackpot.maxValue}
                                    jackpot={props.jackpot}
                                    currency={props.jackpot.currency}
                                />
                            </div>
                        );
                    }

                    let gamesCount = 8;
                    if(collection.jackpot_id){ gamesCount = 7; }
                    if(collection.type === SINGLE_ROW){ gamesCount = 5; }
                    if(collection.type === DOUBLE_ROW_NO_PROMOTED){ gamesCount = 11; }

                    for (let i = 0; i < collection.items.length; i++) {
                        if (i < gamesCount) {
                            gameContent.push(
                                <div className={"GameItem" + (i === 0 && !collection.jackpot_id && collection.type === DOUBLE_ROW ? ' FirstGame' : '')} key={i}>
                                    <Game item={collection.items[i]} launchHandler={launchHandler} />
                                </div>
                            )
                        } else {
                            break;
                        }
                    }
                    gameContent.push(
                        <div className={"GameItem ShowMore"} onClick={() => goToCollection(collection.id)} key={collection.items.length + 1}>
                            <p>...</p>
                        </div>
                    );
                    gameContent.push(<div className={"GameItem EmptyColumn"} key={collection.items.length + 2} />);
                }

                // if (collection.jackpot_id && collection.firstJackpot) {
                //     jackpotContent = (
                //         <div className="JackpotContent">
                //             <TickerWinners winners={props.jackpot.latestWinners} style={{ marginBottom: 0 }} title={t('Jackpots')} subtitle={t('Latest winners')} />
                //         </div>
                //     );
                // }
                
                let collectionType = '';
                let collectionColumns = 0;
                switch (collection.type) {
                    case SINGLE_ROW:
                        collectionType = ' SingleRow';
                        collectionColumns = collection.items.length + 2;
                        if ((collection.items.length) > 5) {
                            collectionColumns = 7;
                        }
                        break;
                    case DOUBLE_ROW:
                        collectionType = ' DoubleRow';
                        collectionColumns = Math.floor((collection.items.length + 4) / 2) + 1;
                        if ((collection.items.length >= 7 && collection.jackpot_id) || (collection.items.length >= 8 && !collection.jackpot_id)) {
                            collectionColumns = 6;
                        }
                        break;
                    case DOUBLE_ROW_NO_PROMOTED:
                        collectionType = ' DoubleRowNoPromoted';
                        collectionColumns = Math.floor(collection.items.length / 2) + 1;
                        if (collection.items.length > 8) {
                            collectionColumns = 6;
                        }
                        break;
                }

                return (
                    <div className="GamesCollection" key={index}>
                        <div className="CollectionContainer">
                            <div className="CollectionHeader">
                                <h2 className={"CollectionTitle"} onClick={() => goToCollection(collection.id)}>
                                    {collection.title} <span>({collection.items.length})</span>
                                </h2>
                                <p className={"CollectionLink"} onClick={() => goToCollection(collection.id)}>{t("See all")}</p>
                            </div>
                            <div className={"GamesContainer Columns-" + collectionColumns + collectionType}>
                                {gameContent}
                            </div>
                        </div>
                        {jackpotContent}
                    </div>
                )
            });
            // mainContent.push(
            //     <div className="RecentWinners">
            //         <RecentWinners winners={props.jackpot.latestWinners} style={{ marginBottom: 0 }} />
            //     </div>
            // );
        }

        return mainContent;
    }

    return (
        <div className={"CollectionsScreen"}>
            {getGameCollections()}
            <WinHistory
                show={showModal}
                closeModal={() => setShowModal(false)}
                logo={WinHistoryEgtLogo}
                name={"EGT Spades"}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        games: state.games,
        jackpot: state.jackpot,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        isRequestGameCollections: () => dispatch(storeActions.requestGameCollections()),
        openGame: (gameId) => dispatch(storeActions.openGameLauncher(gameId)),
        setGame: (gameObject) => dispatch(storeActions.setGameOpened(gameObject)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Collections));