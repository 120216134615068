import React, { Fragment, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../../UI/PageTitle/PageTitle';
import Button from '../../../../UI/Button/Button';
import Icons from '../../../../../assets/Icons/Icons';
import './DocumentType.scss';
import Translate from "../../../../../utils/Translate";
import Utils from "../../../../../utils/Utils";

const TYPE_ID = 1;
const TYPE_UTILITIES_BILL = 2;
const TYPE_CARD = 3;
const TYPE_PASSPORT = 4;

const STATUS_PENDING = 2;
const STATUS_EXPIRED = 4;
const STATUS_REQUESTED = 6;
const STATUS_APPROVED = 8;
const STATUS_REJECTED_CANNOT_READ = 9;
const STATUS_REJECTED_WRONG_IMAGE = 10;
const STATUS_REJECTED_REASON = 16;
const STATUS_MANUAL_REVIEW = 17;

const REASON_DETAILS_NOT_VISIBLE = 1;
const REASON_DOCUMENT_CANNOT_READ = 2;
const REASON_DOCUMENT_WRONG_IMAGE = 3;
const REASON_DOCUMENT_SHOULD_VISIBLE = 4;
const REASON_DOCUMENT_OCR_BULK_VALIDATION_FAILED = 5;
const REASON_DOCUMENT_WRONG_DETAILS = 6;

const DocumentType = (props) => {

    // Don't remove this! Javascript is ignoring translation on the entire page if we use props.t, so we are using the Translate component.
    const t = (text,placeholder) => {
        return <Translate text={text} placeholder={placeholder}/>; // will render <span className="translate">{props.text}</span>
    }

    const [typeContent, setTypeContent] = useState({
        titleIcon: '',
        title: '',
    });

    const [statusContent, setStatusContent] = useState({
        subtitle: '',
        returnStatus: '',
        buttonText: '',
        waitingTimeText: '',
        waitingTime: '',
        iconMessage: '',
        messageText: '',
    });

    useEffect(() => {
        if(props.type){
            handleDocumentTypeContent(props.type);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(props.status){
            handleDocumentStatusContent(props.status, props.reason, props.comment);
        }
        // eslint-disable-next-line
    }, [typeContent]);

    const handleDocumentTypeContent = (type) => {
        if(type === TYPE_ID) {
            setTypeContent({
                ...typeContent,
                titleIcon: Icons.get('identity', `IdentityIcon`),
                title: props.t('ID Card'),
            });
        }
        if(type === TYPE_UTILITIES_BILL){
            setTypeContent({
                ...typeContent,
                titleIcon: Icons.get('horseShoe', `IdentityIcon`),
                title: props.t('Utilities Bill'),
            });
        }
        if(type === TYPE_CARD){
            setTypeContent({
                ...typeContent,
                titleIcon: Icons.get('identity', `IdentityIcon`),
                title: getCardNumber(props.name),
            });
        }
        if(type === TYPE_PASSPORT){
            setTypeContent({
                ...typeContent,
                titleIcon: Icons.get('moneyWithdraw', `IdentityIcon`),
                title: props.t('Passport'),
            });
        }
    }
    const getCardNumber = (n) => {
        let name = props.t('Bank Card');

        if(n) {
            const doc = n.split(' ');
            name = doc[0] + ' ' + Utils.formatCardNumber(doc[1]);
        }
        return name;
    }
    const handleDocumentStatusContent = (status, reason, comment) => {
        if(status === STATUS_REQUESTED) {
            setStatusContent({
                ...statusContent,
                subtitle: t('Please upload a picture of your ${title}.', {title: typeContent.title.toLowerCase()}),
                buttonText: t('Upload document'),
                waitingTimeText: t('Average waiting time'),
                waitingTime: t('${number_of_minutes} minutes',{number_of_minutes: 10}),
            });
        }
        if(status === STATUS_PENDING || status === STATUS_MANUAL_REVIEW){
            setStatusContent({
                ...statusContent,
                subtitle: t('The document will be reviewed soon'),
                buttonText: t('Upload a new photo'),
                waitingTimeText: t('Estimated time left'),
                waitingTime: t('${number_of_minutes} minutes',{number_of_minutes: 4}),
            });
        }
        if(status === STATUS_APPROVED){
            setStatusContent({
                ...statusContent,
                returnIcon: Icons.get('pwSuccess', `ApprovedIcon`),
                returnStatus: t('Verified'),
            });
        }
        if(status === STATUS_REJECTED_CANNOT_READ){
            setStatusContent({
                ...statusContent,
                returnIcon: Icons.get('exclamationFilled', `ExclamationIcon`),
                returnStatus: t('The document was rejected'),
                iconMessage: Icons.get('closeIcon', `CloseIcon`),
                messageText: t('File cannot be opened'),
                buttonText: t('Upload a new photo'),
                waitingTimeText: t('Average waiting time'),
                waitingTime: t('${number_of_minutes} minutes',{number_of_minutes: 12}),
            });
        }
        if(status === STATUS_REJECTED_WRONG_IMAGE){
            setStatusContent({
                ...statusContent,
                returnIcon: Icons.get('exclamationFilled', `ExclamationIcon`),
                returnStatus: t('The document was rejected'),
                iconMessage: Icons.get('closeIcon', `CloseIcon`),
                messageText: t('Photo does not match the required document'),
                buttonText: t('Upload a new photo'),
                waitingTimeText: t('Average waiting time'),
                waitingTime: t('${number_of_minutes} minutes',{number_of_minutes: 12}),
            });
        }
        if(status === STATUS_EXPIRED){
            setStatusContent({
                ...statusContent,
                returnIcon: Icons.get('exclamationFilled', `ExclamationIcon`),
                returnStatus: t('The document was rejected'),
                iconMessage: Icons.get('closeIcon', `CloseIcon`),
                messageText: t('Validity date has expired'),
                buttonText: t('Upload a new photo'),
                waitingTimeText: t('Average waiting time'),
                waitingTime: t('${number_of_minutes} minutes',{number_of_minutes: 12}),
            });
        }
        if(status === STATUS_REJECTED_REASON){
            let messageReasons = [];
            let messageComment = '';
            if(comment !== null && comment.replace(/\s/g, '').length){
                messageComment = comment;
            }
            if(reason) {
                reason.map(singleReason => {
                    if(parseInt(singleReason) === REASON_DETAILS_NOT_VISIBLE){
                        messageReasons.push(t('Details are not visible'));
                    }
                    if(parseInt(singleReason) === REASON_DOCUMENT_CANNOT_READ){
                        messageReasons.push(t('Document cannot read'));
                    }
                    if(parseInt(singleReason) === REASON_DOCUMENT_WRONG_IMAGE){
                        messageReasons.push(t('Document wrong image'));
                    }
                    if(parseInt(singleReason) === REASON_DOCUMENT_SHOULD_VISIBLE){
                        messageReasons.push(t('Document should be fully visible in the photo'));
                    }
                    if (parseInt(singleReason) === REASON_DOCUMENT_WRONG_DETAILS) {
                        messageReasons.push(this.props.t('The document does not contain the requested details.'));
                    }
                });
            }
            setStatusContent({
                ...statusContent,
                returnIcon: Icons.get('exclamationFilled', `ExclamationIcon`),
                returnStatus: t('The document was rejected'),
                iconMessage: Icons.get('closeIcon', `CloseIcon`),
                messageText: messageComment,
                messageReasons: messageReasons,
                buttonText: t('Upload a new photo'),
                waitingTimeText: t('Average waiting time'),
                waitingTime: t('${number_of_minutes} minutes',{number_of_minutes: 12}),
            });
        }
    }

    let documentReasons = '';
    if(statusContent.messageReasons){
        documentReasons = statusContent.messageReasons.map((reason, index) => (
            <div key={index}>
                {statusContent.iconMessage}
                <span>{reason}</span>
            </div>
        ));
    }

    return (
        <section className={"DocumentBox"}>
            <div className="BoxTitle">
                <PageTitle icon={typeContent.titleIcon} title={typeContent.title} subtitle={statusContent.subtitle} />
            </div>
            <div className="BoxContent">
                {
                    props.status !== STATUS_PENDING || props.status !== STATUS_REQUESTED || props.status !== STATUS_MANUAL_REVIEW
                    ?   <div className="MessageBox">
                            <div className={'Message Status-' + props.status}>
                                {statusContent.returnIcon}
                                <h4>{statusContent.returnStatus}</h4>
                            </div>
                            {documentReasons && (
                                <div className={"DocumentReasons"}>
                                    {documentReasons}
                                </div>
                            )}
                            {statusContent.messageText && (
                                <div>
                                    {statusContent.iconMessage}
                                    <span>{statusContent.messageText}</span>
                                </div>
                            )}
                            
                        </div>
                    :   null
                }
                {
                    props.status !== STATUS_APPROVED
                    ?   <Fragment>
                            <Button 
                                className={'active'} 
                                value={statusContent.buttonText}
                                click={() => props.changePage('selector', props.type, props.documentID)}
                            />
                            <div className={"WaitingTime"}>
                                <div>
                                    {Icons.get('timer', `TimerIcon`)}
                                </div>
                                <div>
                                    <h5>{statusContent.waitingTimeText}</h5>
                                    <h4>{statusContent.waitingTime}</h4>
                                </div>
                            </div>
                        </Fragment>
                    :   null
                }
            </div>
        </section>
    );
}

export default withTranslation()(DocumentType);