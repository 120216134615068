import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleMapContainer from "./Helpers/GoogleMapContainer";
import * as actions from "../../../../store/actions"
import './Locations.scss';
import { withTranslation } from "react-i18next";
import Icons from "../../../../assets/Icons/Icons";
import Swipe from 'react-easy-swipe';
import Button from "@material-ui/core/Button";
import TitleBar from "../../../UI/TitleBar/TitleBar";
import { withRouter } from "react-router";
import Translate from "../../../../utils/Translate";
import Div100vh from 'react-div-100vh';

class Locations extends Component {

    state = {
        listSize: 'small',
        locations: [],
        selectedLocation: null,
        classes: {
            label: '',
            inputContainer: '',
            helperText: ''
        },
        searchCoord: false,
        nearby: false,
        depositMessage: false,
    };

    componentDidMount() {
        this.props.getLocations();

    }

    componentWillUnmount() {
        this.props.isNotDeposit();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.locations !== this.props.locations) {

            this.setState({
                ...this.state,
                locations: this.props.locations
            })
        }

        if (prevProps.step !== this.props.step && this.props.step === 2) {
            // sort locations by nearest one
            // window.navigator.geolocation.getCurrentPosition(this.callbackFunc);
        }
    }

    onChange = (event) => {
        let input = event.target.value;
        let  filter, ul, li, item, i, txtValue;

        filter = input.toUpperCase();
        ul = document.getElementById("LocationsContainer");
        li = ul.getElementsByClassName("location");

        for (i = 0; i < li.length; i++) {
            item = li[i];
            txtValue = item.textContent || item.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    };

    onFocusHandler() {

        this.setState({
            ...this.state,
            listSize: 'big',
            classes: {
                ...this.state.classes,
                inputContainer: 'Mui-focused',
            },

        });
    }

    onBlurHandler(e) {

        this.setState({
            ...this.state,
            classes: {
                ...this.state.classes,
                inputContainer: '',
            },
        })
    }

    setLocation(location) {
        this.setState({
            ...this.state,
            selectedLocation: location,
        })
    }

    onSwipeUp() {

        this.setState({
            ...this.state,
            listSize: 'big'
        })
    }

    onSwipeDown() {
        if (typeof document.activeElement !== 'undefined') {
            document.activeElement.blur();
        }
        // document.getElementById('input').blur();
        this.setState({
            ...this.state,
            listSize: 'small'
        })
    }
    activateScroll() {
        return false;
    }

    decodeHTML(html) {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    getLocationList() {
        let locations = [];
        let icon = Icons.get('PinAddressIcon', 'PinAddressIcon');
        let locationsList = this.state.locations;

        locationsList.forEach((location, index) => {
            let message = [];
            let nearby = { ...this.state.nearby };

            if (this.state.nearby && nearby.id === location.id) {
                message.push(
                    <div className="message" key={'nearby_location_withdraw_modal'}>
                        {this.props.t("Closest location")}
                    </div>
                )
            }
            if (location.active) {
                locations.push(
                    <li className={'location item_' + index + (message.length > 0 ? '' : ' no-message')} key={"location_" + index} onClick={this.setLocation.bind(this, location)}>
                        {message}
                        {icon}
                        <div className="name">
                            {location.locationName}
                        </div>
                        <div className="address">
                            {this.decodeHTML(location.formattedAddress)}
                        </div>
                        <div className="details">
                            {this.props.t('Maximum 4000 Lei')}
                        </div>
                    </li>
                )
            }
        });
        if (locations.length > 1) {
            locations.unshift(
                <li key={'location.search'} className={'search'}>
                    <div className="MuiFormControl-root MuiTextField-root">
                        <div
                            className={"MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl " + this.state.classes.inputContainer}>
                            <input
                                placeholder={this.props.t("Search location")}
                                lang="ro"
                                required="required"
                                onChange={this.onChange.bind(this)}
                                onFocus={this.onFocusHandler.bind(this)}
                                onBlur={this.onBlurHandler.bind(this)}
                                className={'MuiInputBase-input MuiInput-input'}
                                id={"address"}
                            />
                        </div>
                    </div>
                </li>
            );
        }
        return locations;
    }

    resetLocation() {
        this.setState({
            ...this.state,
            selectedLocation: null
        })
    }

    acceptLocation() {
        this.props.setLocation(this.state.selectedLocation);
        this.props.goToStep(this.props.currentStep + 1);
        this.props.setStep(this.props.currentStep + 1);
    }

    deposit() {
        this.setState({
            ...this.state,
            depositMessage: true
        })
    }
    goToMaps() {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.state.selectedLocation.latitude},${this.state.selectedLocation.longitude}&travelmode=driving`)

        return false;
    }

    goToWithdraw() {
        this.props.setLocation(this.state.selectedLocation);
        this.props.skipLocation(true);
        this.props.history.push('/withdraw');
    }

    goToDepositOnline() {
        this.props.history.push('/deposit');
    }
    getSelectedLocation() {
        let location = [];
        if (this.state.selectedLocation) {
            let icon = Icons.get('PinAddressIcon', 'PinAddressIcon');
            let moneyWithdrawIcon = Icons.get('pendingWithdrawals', 'moneyWithdraw');
            let button = [];
            if (typeof this.props.goToStep === 'undefined') {
                if (!this.props.isDeposit) {

                    button.push(
                        <div className="grouped all" key={'grouped_button_selected_location'}>
                            <Button size="large" color="primary" className={'withdrawWizardButton'} onClick={this.goToMaps.bind(this)}>{Icons.get('mapRouteBtn')}</Button>
                            <Button size="large" color="primary" className={'withdrawWizardButton withdraw'} onClick={this.goToWithdraw.bind(this)}>{this.props.t("Withdraw")}</Button>
                            <Button size="large" color="primary" className={'withdrawWizardButton'} onClick={this.deposit.bind(this)}>{this.props.t("Deposit")}</Button>
                        </div>
                    );
                } else {
                    button.push(
                        <div className="grouped deposit" key={'grouped_button_selected_location'}>
                            <Button size="large" color="primary" className={'withdrawWizardButton'} onClick={this.goToMaps.bind(this)}>{Icons.get('mapRouteBtn')}</Button>
                            <Button size="large" color="primary" className={'withdrawWizardButton'} onClick={this.deposit.bind(this)}>{this.props.t("Deposit")}</Button>
                        </div>
                    );
                }
            } else {
                button.push(<Button variant="contained" size="large" color="primary" key={'withdraw_button_selected'} className={'withdrawWizardButton'} onClick={this.acceptLocation.bind(this)}>{moneyWithdrawIcon} {this.props.t("withdraw here")} {this.props.amount} Lei</Button>);
            }

            location.push(<li className={'location'} key={"location_selected"}>
                <Button onClick={this.resetLocation.bind(this)} className={'closeButton'}>X</Button>
                {icon}
                <div className="name">
                    {this.state.selectedLocation.locationName}
                </div>
                <div className="address">
                    {this.decodeHTML(this.state.selectedLocation.formattedAddress)}
                </div>
                {button}
            </li>);
        }

        let className = !this.state.selectedLocation ? '' : " show";


        return (<Div100vh className={"list selectedLocation small" + className}>
            {location}
        </Div100vh>)
    }

    getLocationsContainer() {

        let locationsList = this.getLocationList();

        return (<Swipe onSwipeUp={this.onSwipeUp.bind(this)} onSwipeDown={this.onSwipeDown.bind(this)} onSwipeMove={this.activateScroll.bind(this)}>
            <Div100vh className={'list ' + this.state.listSize + (this.state.selectedLocation ? ' hide' : '')} id={"LocationsContainer"}>
                    {locationsList}
            </Div100vh>
        </Swipe>)
    }

    getMapProps() {
        return {
            zoom: this.state.selectedLocation !== null ? 16 : this.state.searchCoord ? 15 : false,
            initialCenter: this.state.selectedLocation !== null ? { lat: this.state.selectedLocation.latitude, lng: this.state.selectedLocation.longitude } : this.state.searchCoord ? this.state.searchCoord : false
        }
    }

    t(text, placeholder) {
        return <Translate text={text} placeholder={placeholder} />;
    }

    getDepositMessage() {
        let comp = [];
        comp.push(<div className={"deposit-message"} style={this.state.depositMessage ? { 'height': '100vh' } : { 'height': 0, 'overflow': 'hidden' }} key={'locations_deposit-message'}>
            <TitleBar pageTitle={this.props.t('Deposit in location')} />
            <h6>{this.props.t('Just with ID card')}</h6>
            <div className="subtitle"><span>{this.props.t('Deposit in any location')}</span></div>
            <img className="smiley" src={`${window.config.front_url}/img/mobile/deposit/img_smiley_face.png`} />
            <div className="description">
                {this.t("Go in any ${brand} Agency and you can deposit any sum with your ID. No headaches, no cards.", { brand:  window.config.brand_name_full })}
            </div>

            <div className="img-id">
                <img src={`${window.config.front_url}/img/mobile/deposit/img_deposit_Logo_elite.png`} />
                <div className="title">
                    <span className="red">{this.props.t('Just with')}</span>
                    <span className="blue">{this.props.t('ID card')}</span>
                </div>
                <div className="gray">
                    {this.props.t('You decide. Anywhere. Anytime')}
                </div>
            </div>
            <Button variant="contained" size="large" color="primary" className={`deposit-online`} onClick={this.goToDepositOnline.bind(this)}>
                {this.props.t("Deposit Online")}
            </Button>
            <Button variant="contained" size="large" color="primary" className={`locations-button`} onClick={this.closeDeposit.bind(this)}>
                {this.props.t("Map with locations")}
            </Button>
        </div>)

        return comp;
    }

    closeDeposit() {
        this.setState({
            ...this.state,
            depositMessage: false
        })
    }

    render() {
        let props = this.getMapProps();
        let locationsElement = this.getLocationsContainer();
        let selectedLocation = this.getSelectedLocation();
        let depositMessage = this.getDepositMessage();
        if (typeof this.props.goToStep === 'undefined') {
            return (
                <div>
                    {depositMessage}
                    <TitleBar pageTitle={this.props.t('Locations')} />
                    <div className={"locations"}>
                        <GoogleMapContainer locations={this.props.locations} {...props} setLocation={this.setLocation.bind(this)} />
                        {locationsElement}
                        {selectedLocation}
                    </div>
                </div>

            )
        }
        return (
            <div className={'locations' + (this.props.step !== 2 ? " hide" : "")}>
                <div className="title"></div>
                <GoogleMapContainer locations={this.props.locations} {...props} setLocation={this.setLocation.bind(this)} />
                {locationsElement}
                {selectedLocation}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLocations: () => dispatch(actions.withdrawGetLocations()),
        setLocation: (location) => dispatch(actions.withdrawSetLocation(location)),
        skipLocation: (skip) => dispatch(actions.skipLocation(skip)),
        setStep: (step) => dispatch(actions.setWithdrawWizardStep(step)),
        isNotDeposit: () => dispatch(actions.isDeposit(false))
    };
};

const mapStateToProps = state => {
    return {
        locations: state.withdrawWizard.locations,
        amount: state.withdrawWizard.amount,
        step: state.withdrawWizard.step,
        isDeposit: state.withdrawWizard.isDeposit
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations)));
