import * as actionTypes from './../actions/actionTypes';

const initialState = {
    access_token: null,
    expires_in: null,
    refresh_token: null,
    token_type: null,
    auth_type: null,
    auth_date: null,
    accountProblemModal: false,
    accountProblemCode: 0,
    accountProblemMessage: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.authentication.AUTHENTICATE:
            return {
                ...state,
                access_token: action.accessToken,
                expires_in: action.expiresIn,
                refresh_token: action.refreshToken,
                token_type: action.tokenType,
                auth_type: action.authType,
                auth_date: action.authDate,
            };
        case actionTypes.authentication.CLEAR:
            return {
                ...initialState
            };
        case actionTypes.authentication.ACCOUNT_PROBLEM:
            return {
                ...state,
                accountProblemModal: action.show,
                accountProblemCode: action.code,
                accountProblemMessage: action.message,
            };
        default:
            return state;
    }
};

export default reducer;