/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 06/11/2018
 * Time: 15:22
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import * as stateActions from "../../../store/actions";
import CircularProgress from '@material-ui/core/CircularProgress';
import Utils from "../../../utils/Utils";
import {withRouter} from "react-router";
// import Button from '@material-ui/core/Button';
import TitleBar from '../../UI/TitleBar/TitleBar';
import Button from '../../UI/Button/Button';
import Icons from '../../../assets/Icons/Icons';
import './Token.scss';

class Token extends Component {

    componentDidMount () {
        const { token } = this.props.match.params;
        Utils.delayedFunction('validateEmailRequest', this.validateEmail.bind(this,token));

    }

    validateEmail(token) {
        this.props.validateEmail(token);
    }

    clickHandler() {
        this.props.history.push('/');
    }

    render() {

        let pageContent = <CircularProgress className={'Loading'} />;
   
        if (this.props.validated !== null) {
            if (this.props.validated) {
                pageContent = (
                    <div className="TokenContent">
                        <h1>{this.props.t('Account Verification')}</h1>
                        <i>{Icons.get('pwSuccess', `ApprovedIcon`)}</i>
                        <div className="AlertBox">
                            <h2>{this.props.t('Email verified!')}</h2>
                            <p>{this.props.t('Congratulations! Your account is now more secure.')}</p>
                            <p>{this.props.t('Good luck and have fun!')}</p>
                        </div>
                        <Button className={'gradient'} value={this.props.t('Go to homepage')} click={this.clickHandler.bind(this)}/>
                    </div>
                );
            } else {
                pageContent = (
                    <div className="TokenContent">
                        <h1>{this.props.t('Account Verification')}</h1>
                        <i>{Icons.get('exclamationFilled', `ExclamationIcon`)}</i>
                        <div className="AlertBox Error">
                            <h2>{this.props.t("The email couldn't be verified")}</h2>
                            {/* <p>{this.props.t('Some error message here')}</p> */}
                        </div>
                        <Button className={'gradient'} value={this.props.t('Go to homepage')} click={this.clickHandler.bind(this)}/>
                    </div>
                );
            }
        }


        return (
            <div className={"TokenScreen"}>
                <TitleBar pageTitle={this.props.t("Email Validation")} backHandler={() => this.props.history.push('/')}/>
                {pageContent}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        validateEmail: (token) => dispatch(stateActions.validateEmail(token))
    };
};

const mapStateToProps = state => {

    return {
        validated: state.register.accountEmailValidated,
    }
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Token)));
