import * as actions from "../actions";
import ClientAPI from "../../ClientAPI/ClientAPI";
import { put } from 'redux-saga/effects';
import * as paths from "../../ClientAPI/paths";
import Utils from "../../utils/Utils";

export function* getTaxSaga(action) {

    let axios = ClientAPI.getInstance();

    try {
        let data = {};
        data = {
            amount: action.value,
            type: 1
        };

        const response = yield axios({
            url: paths.withdrawals.REQUEST_TAX_SERVICE,
            method: 'post',
            data: data
        });
        if (!response.status) {
            throw new Error(`[ERROR] ${action.field} WITHDRAW WIZARD TAXES missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] ${action.field} WITHDRAW WIZARD TAXES not triggered!`);
        }

        yield put(actions.taxResponseReceived(response))
    } catch (error) {
        console.log(error);
        return;
    }

}

export function* getLocationsSaga(action) {

    let axios = yield Utils.getAxiosWithToken();

    try {
        const response = yield axios({
            url: paths.withdrawals.REQUEST_LOCATIONS,
            method: 'get',
        });
        if (!response.status) {
            throw new Error(`[ERROR] WITHDRAW WIZARD LOCATIONS missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] WITHDRAW WIZARD LOCATIONS not triggered!`);
        }

        yield put(actions.withdrawWizardReceivedLocations(response));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* sendWithdraw(action) {
    let axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.withdraw_wizard.WITHDRAW_REQUEST,
            method: 'post',
            data: action.value
        });
        if (!response.status) {
            throw new Error(`[ERROR] WITHDRAW WIZARD LOCATIONS missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] WITHDRAW WIZARD LOCATIONS not triggered!`);
        }

        yield put(actions.receivedWithdraw(response.result));
        // yield put(actions.withdrawWizardReceivedLocations(response));


    } catch (error) {
        console.log(error);
        return;
    }
}
