import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Utils from './../../../../../utils/Utils';
import Icons from "../../../../../assets/Icons/Icons";
import './WalletBoxItem.scss';
import * as storeActions from "../../../../../store/actions";
import {getAllPendingWithdrawals} from "../../../../../store/actions";

const PENDING_WITHDRAWALS = 'withdrawCoin';
const PENDING_DEPOSITS = 'depositCoin';

const WalletBoxItem = (props) => {
    const { bonus, type, currency, enabled, amount, freeBets, title, noIcon, t } = props;

    useEffect(() => {
        if (type === PENDING_WITHDRAWALS) {
            props.getAllPendingWithdrawals()
        }
    }, [])

    let freeBetsCount = 0;
    freeBets && freeBets.forEach(fb => {
        if (type === "walletSportsbook" && (fb.product === "sport" || fb.product === "sport.live" || fb.product === "sport.prematch")) {
            freeBetsCount += 1;
        } else if (type === "walletLotto" && fb.product === "lotto") {
            freeBetsCount += 1;
        } else if (type === "walletCasinoLive" && fb.product === "casino-live") {
            freeBetsCount += 1;
        } else if (type === "walletCasino" && fb.product === "casino") {
            freeBetsCount += 1;
        } else if (type === "walletVirtuals" && fb.product === "virtuals") {
            freeBetsCount += 1;
        }
    });

    let bonusAmount = <span className="BonusAmount">{t('No bonus')}</span>;
    if (bonus !== false) {
        bonusAmount = (
            <span className="BonusAmount">
                {Utils.formatSmallDecimals(bonus, null)}
                <span className="Currency">{Utils.formatCurrency(currency)}</span>
            </span>
        );
    }

    let boxAmount = <h2 className="BoxAmount">{t('Coming Soon')}</h2>;
    let boxBonus = <div className="BoxBonus" />;
    if (enabled !== false) {
        if (type === PENDING_WITHDRAWALS) {
            boxAmount = (
                <h2 className="BoxAmount Withdrawls">
                    {amount > 0 ? '-' : ''}
                    {Utils.formatSmallDecimals(amount, null)}
                    <span className="Currency">{Utils.formatCurrency(currency)}</span>
                </h2>
            );
            boxBonus = '';
        } else if (type === PENDING_DEPOSITS) {
            boxAmount = (
                <h2 className="BoxAmount Deposits">
                    {amount > 0 ? '+' : ''}
                    {Utils.formatSmallDecimals(amount, null)}
                    <span className="Currency">{Utils.formatCurrency(currency)}</span>
                </h2>
            );
            boxBonus = '';
        } else {
            boxAmount = (
                <h2 className="BoxAmount">
                    {Utils.formatSmallDecimals(amount, null)}
                    <span className="Currency">{Utils.formatCurrency(currency)}</span>
                </h2>
            );
            boxBonus = (
                <div className={"BoxBonus " + (bonus !== false ? '' : 'NoBonus')}>
                    {freeBetsCount !== 0 && <div className="FreeBetContent" title="Lucky Bet">{Icons.get("freeBet", "BonusIcon")}</div>}
                    <div className="BonusContent">
                        {!noIcon && Icons.get("diamond", "BonusIcon")}
                        {bonusAmount}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={"ProductBox " + (enabled ? '' : 'ComingSoon')}>
            <div className="BoxIcon">
                {Icons.get(type, "ProductIcon " + type)}
            </div>
            <div className="BoxContent">
                <p className="BoxTitle">{t(title)}</p>
                {boxAmount}
                {boxBonus}
            </div>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        getAllPendingWithdrawals: () => dispatch(storeActions.getAllPendingWithdrawals())
    }
}
const mapStateToProps = state => {
    return {
        freeBets: state.freeBets.freeBets,

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(WalletBoxItem)));