import axios from 'axios';

class VivaAPI {
    constructor() {
        this.initialized = false;
        this.instance = null;
        this.store = null;
        this.vivaBaseUrl = process.env.CFW.vivaApiUrl;
        this.accessToken = null;
    }

    setStore(store) {
        this.store = store;
        this.init();
    }
    
    setToken(token) {
        if(token){
            this.accessToken = token;
            this.init();
        }
    }

    getToken() {
        return this.accessToken;
    }

    init() {
        if(this.accessToken){
            this.instance.defaults.headers.common['content-type'] = 'application/json';
            this.instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            this.instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            this.instance.defaults.headers.common['authorization'] = 'Bearer ' + this.accessToken;
        }

        if (!this.store) {
            return;
        }

        if (this.initialized) {
            return;
        }
        

        this.initialized = true;

        let self = this;
        this.instance = axios.create({
            baseURL: self.vivaBaseUrl
        });
        
        this.activateRequestInterceptors();

    }
    
    activateRequestInterceptors() {
        if (!this.instance) {
            return;
        }

        this.instance.interceptors.request.use(request => {
            if (!request.hasOwnProperty("data")) {
                request.data = {};
            }

            return request;
        }, error => {
            console.error(`[ERROR] Server request error => ${error}`);
            console.error(`[NETWORK] Error, network disconnected!`);

            return Promise.reject(error);
        });
    }

    getInstance() {
        return this.instance;
    }

    getStore() {
        return this.store;
    }
}

export default new VivaAPI();
