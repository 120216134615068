import "./dev.js";

import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";
import "./index.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import applicationReducer from "./store/reducers/application";
import menuReducer from "./store/reducers/menu";
import authenticationReducer from "./store/reducers/authentication";
import loginReducer from "./store/reducers/login";
import walletReducer from "./store/reducers/wallet";
import registerReducer from "./store/reducers/register";
import resetPasswordReducer from "./store/reducers/resetPassword";
import profileReducer from "./store/reducers/profile";
import transactionsReducer from "./store/reducers/transactions";
import paymentCheckoutReducer from "./store/reducers/paymentCheckout";
import withdrawalCheckoutReducer from "./store/reducers/withdrawalCheckout";
import withdrawalsReducer from "./store/reducers/withdrawals";
import withdrawWizardReducer from "./store/reducers/withdraw_wizard";
import documentsReducer from "./store/reducers/documents";
import gamesReducer from "./store/reducers/games";
import customerSupportReducer from "./store/reducers/customer_support";
import jackpotReducer from "./store/reducers/jackpot";
import happyHourReducer from "./store/reducers/happy_hour";
import configReducer from "./store/reducers/config";
import layoutReducer from "./store/reducers/layout";
import freeBetsReducer from "./store/reducers/free_bets";
import voucherCodeReducer from "./store/reducers/voucherCode";
import requestManagerReducer from "./store/reducers/requestManager";
import freeSpinsReducer from "./store/reducers/free_spins";
import alertsReducer from "./store/reducers/alerts";
import druidReducer from "./store/reducers/druid";
import marketingOfferReducer from "./store/reducers/marketing_offer";

import ClientAPI from "./ClientAPI/ClientAPI";
import { rootSaga } from "./store/sagas";
import AccountProblem from "../src/Alerts/AccountProblem/AccountProblem";
import ScrollToTop from "./components/UI/ScrollToTop/ScrollToTop";

import { setStore as betsSetStore } from "./bets/store";
import { betsRootReducer } from "./bets/store/reducers";

import { setStore as virtualsSetStore } from "./virtuals/store";
import { virtualsRootReducer } from "./virtuals/store/reducers";

import { setStore as lottoSetStore } from "./lotto/store";
import { lottoRootReducer } from "./lotto/store/reducers";
import VivaAPI from "./ClientAPI/VivaAPI.js";

import { installNotchHandler } from "./utils/layout";
import { setStore as liveCasinoSetStore } from "./live-casino/store";
import liveCasinoReducer from "./live-casino/store/reducers";

import MaintenanceGateway from "./maintenance/MaintenanceGateway";
import MaintenanceModal from "./maintenance/MaintenanceModal";
import { setStore as maintenanceSetStore } from "./maintenance/store"
import { maintenanceRootReducer } from "./maintenance/store/reducers";

import { soundsRootReducer } from "./sounds-toggle/store/reducers";

import { winnerFunRootReducer } from "./winner-fun/store/reducers";
import { setStore as winnerFunSetStore } from "./winner-fun/store";

import { setStore as lobbySetStore } from "./lobby/store";
import { lobbyRootReducer } from "./lobby/store/reducers";

import { setStore as promoSetStore } from "./promo/store";
import { promoRootReducer } from "./promo/store/reducers";

import { setStore as promoCalendarSetStore } from "./promo-calendar/store";
import { promoCalendarRootReducer } from "./promo-calendar/store/reducers";

import { setStore as momentumSetStore } from "./momentum/store";
import { momentumRootReducer } from "./momentum/store/reducers";

import history from "./history";

import jackpotListReducer from "./store/reducers/jackpotList";

import { setStore as wheelSetStore } from "./wheel/store";
import { wheelRootReducer } from "./wheel/store/reducers";

import { setStore as tournamentsGroupsSetStore } from "./tournaments-missions/store";
import { tournamentsMissionsRootReducer } from "./tournaments-missions/store/reducers";

import { setStore as depositTicketSetStore } from "./deposit-ticket/store";
import { depositTicketRootReducer } from "./deposit-ticket/store/reducers";

if (
    process.env.NODE_ENV === "development" ||
    (window.config.environment && window.config.environment !== "production")
) {
    console.log("!!!! THIS IS A STAGING ENVIRONMENT !!!!");
}

const rootReducer = combineReducers({
    application: applicationReducer,
    menu: menuReducer,
    authentication: authenticationReducer,
    login: loginReducer,
    wallet: walletReducer,
    register: registerReducer,
    resetPassword: resetPasswordReducer,
    profile: profileReducer,
    transactions: transactionsReducer,
    withdrawalCheckout: withdrawalCheckoutReducer,
    paymentCheckout: paymentCheckoutReducer,
    withdrawals: withdrawalsReducer,
    documents: documentsReducer,
    games: gamesReducer,
    withdrawWizard: withdrawWizardReducer,
    customerSupport: customerSupportReducer,
    jackpot: jackpotReducer,
    bets: betsRootReducer,
    virtuals: virtualsRootReducer,
    lotto: lottoRootReducer,
    happyHour: happyHourReducer,
    config: configReducer,
    liveCasino: liveCasinoReducer,
    layout: layoutReducer,
    sounds: soundsRootReducer,
    freeBets: freeBetsReducer,
    voucherCode: voucherCodeReducer,
    requestManager: requestManagerReducer,
    freeSpins: freeSpinsReducer,
    alerts: alertsReducer,
    druid: druidReducer,
    winnerFun: winnerFunRootReducer,
    lobby: lobbyRootReducer,
    maintenance: maintenanceRootReducer,
    jackpotList: jackpotListReducer,
    wheel: wheelRootReducer,
    promo: promoRootReducer,
    promoCalendar: promoCalendarRootReducer,
    marketingOffer: marketingOfferReducer,
    tournamentsMissions: tournamentsMissionsRootReducer,
    depositTicket: depositTicketRootReducer,
    momentum: momentumRootReducer,
});

const asyncDispatchMiddleware = store => next => action => {
    let syncActivityFinished = false;
    let actionQueue = [];

    function flushQueue() {
        actionQueue.forEach(a => store.dispatch(a)); // flush queue
        actionQueue = [];
    }

    function asyncDispatch(asyncAction) {
        actionQueue = actionQueue.concat([asyncAction]);

        if (syncActivityFinished) {
            flushQueue();
        }
    }

    const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

    const res = next(actionWithAsyncDispatch);
    syncActivityFinished = true;
    flushQueue();

    return res;
};

let storeVariable;
const sagaMiddleware = createSagaMiddleware();
if (window.config.environment === 'staging') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    storeVariable = createStore(
        rootReducer, composeEnhancers(applyMiddleware(thunk, asyncDispatchMiddleware, sagaMiddleware)),
    );
} else {
    storeVariable = createStore(
        rootReducer,
        applyMiddleware(thunk, asyncDispatchMiddleware, sagaMiddleware)
    );
}
export const store = storeVariable;

ClientAPI.setStore(store);
VivaAPI.setStore(store);

if (window.config && (window.config.betsEnabled === "1" || window.config.nsoftEnabled === "1")) {
    betsSetStore(store);
}

if (window.config && window.config.virtualsEnabled === "1") {
    virtualsSetStore(store);
}

if (window.config && window.config.lottoEnabled === "1") {
    lottoSetStore(store);
}


if (window.config && window.config.winnerFunEnabled === "1") {
    winnerFunSetStore(store);
}
if (window.config && window.config.liveCasinoEnabled === "1") {
    liveCasinoSetStore(store);
}

if (window.config && window.config.maintenanceModuleEnabled === "1") {
    maintenanceSetStore(store);
}

if (window.config && window.config.lobbyEnabled === "1") {
    lobbySetStore(store);
}

if (window.config && window.config.wheelEnabled === "1") {
    wheelSetStore(store);
}

if (window.config && window.config.promoEnabled === "1") {
    promoSetStore(store);
}

if (window.config && window.config.promoCalendarEnabled === "1") {
    promoCalendarSetStore(store);
}

if (window.config && window.config.momentumEnabled === "1") {
    momentumSetStore(store);
}

if (window.config && window.config.tournamentsMissionsEnabled === "1") {
    tournamentsGroupsSetStore(store);
}

if (window.config && window.config.depositTicketEnabled === "1") {
    depositTicketSetStore(store);
}

installNotchHandler();

sagaMiddleware.run(rootSaga);
window._store = store;
window._history = history;
const app = (
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <Router history={history}>
                <MaintenanceGateway>
                    <ScrollToTop>
                        <App />
                    </ScrollToTop>
                    <AccountProblem />
                    <MaintenanceModal />
                </MaintenanceGateway>
            </Router>
        </Provider>
    </I18nextProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
