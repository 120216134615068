import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { connect } from "react-redux";

import JackpotCounter from "../NewCounter";

import BackgroundImage from "./../../../assets/Images/Jackpot/img-jackpots-pagebg@3x.png";
import "./WinHistory.scss";
import { withTranslation } from "react-i18next";
import Utils from "../../../utils/Utils";
import { formatDate, formatNumber, ucFirst } from "./../utils/formatters";

ReactModal.setAppElement("#root");

const modalStyle = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.75)"
    },
    content: {
        top: "calc(56px + .5em)",
        bottom: ".5em",
        left: ".5em",
        right: ".5em",
        borderRadius: "5px",
        border: "1px solid #46196D",
        backgroundColor: "#300058",
        backgroundImage: `url(${Utils.getImageUrl(BackgroundImage)})`,
        backgroundPositionX: "100%",
        backgroundSize: "100% auto",
        backgroundRepeat: "no-repeat"
    }
};

class WinHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            levels: this.processLevels()
        };
    }

    processLevels() {
        const levels = [...this.props.levels];

        levels.sort((a, b) => b.current - a.current);
        levels.forEach(l => {
            if (
                typeof l.largestWin === "undefined" ||
                l.largestWin === null ||
                l.largestWin === 0
            ) {
                l.largestWinUser = "";
                l.largestWinDate = "N/A";
            }

            if (typeof l.lastWin === "undefined" || l.lastWin === null || l.lastWin === 0) {
                l.lastWinUser = "";
                l.lastWinDate = "N/A";
            }
        });

        return levels;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.levels !== this.props.levels) {
            this.setState({
                levels: this.processLevels()
            });
        }
    }

    sendClose = () => {
        const content = document.getElementsByClassName("ReactModal__Content")[0];
        content.classList.remove("ReactModal__Content--after-open");
        content.classList.add("ReactModal__Content--before-close");

        window.setTimeout(() => {
            this.props.closeModal();
        }, 250);
    };

    render() {
        const currency = ucFirst(this.props.currency);

        return (
            <div onClick={this.sendClose}>
                <ReactModal
                    isOpen={this.props.show}
                    style={modalStyle}
                    htmlOpenClassName={"jackpot-win-history-modal--open"}
                >
                    {/*<div className="jackpot-img">
                        <img src={this.props.logo} alt="Jackpot Logo" />
                    </div>*/}
                    <div className="jackpot-name">{this.props.name}</div>

                    {this.state.levels.map((level, index) => (
                        <div className="jackpot-wrapper" key={index}>
                            <div className="jackpot-counter">
                                <JackpotCounter
                                    value={level.current / 100}
                                    storageKey={`EGT_${index}`}
                                />
                                <span className="currency">{level.currency}</span>
                            </div>
                            <div className="biggestWin">
                                <div>
                                    <div className="muted-text">
                                        {this.props.t("jackpot_history_highest_win")}
                                    </div>
                                    <div className="amount">
                                        {formatNumber(level.largestWin / 100)}{" "}
                                        <span className="cap">{currency}</span>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className="muted-text">{level.largestWinUser}</div>
                                    <div className="date">
                                        {formatDate(level.largestWinDate, this.props.i18n)}
                                    </div>
                                </div>
                            </div>
                            <div className="latestWin">
                                <div>
                                    <div className="muted-text">
                                        {this.props.t("jackpot_history_recent_win")}
                                    </div>
                                    <div className="amount">
                                        {formatNumber(level.lastWin / 100)}{" "}
                                        <span className="cap">{currency}</span>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className="muted-text">{level.lastWinUser}</div>
                                    <div className="date">
                                        {formatDate(level.lastWinDate, this.props.i18n)}
                                    </div>
                                </div>
                            </div>

                            {index < this.props.levels.length - 1 && (
                                <div className="delimiter"></div>
                            )}
                        </div>
                    ))}
                </ReactModal>
            </div>
        );
    }
}

WinHistory.propTypes = {
    name: PropTypes.string,
    logo: PropTypes.string,
    levels: PropTypes.arrayOf(
        PropTypes.shape({
            current: PropTypes.number,
            largestWin: PropTypes.number,
            largestWinUser: PropTypes.string,
            largestWinDate: PropTypes.string,
            lastWin: PropTypes.number,
            lastWinUser: PropTypes.string,
            lastWinDate: PropTypes.string
        })
    ),
    closeModal: PropTypes.func,
    show: PropTypes.bool
};

WinHistory.defaultProps = {
    name: "",
    logo: "",
    levels: [],
    closeModal: () => { },
    show: false
};

const mapStateToProps = (state, props) => {
    return {
        levels: props.jackpotId && typeof state.jackpotList.list[props.jackpotId] !== "undefined" ? state.jackpotList.list[props.jackpotId].levels : state.jackpot.levels,
        currency: props.jackpotId && typeof state.jackpotList.list[props.jackpotId] !== "undefined" ? state.jackpotList.list[props.jackpotId].currency : state.jackpot.currency
    };
};

export default withTranslation()(connect(mapStateToProps)(WinHistory));
