import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import './Limits.scss';
import * as storeActions from "../../../../store/actions/index";
import connect from "react-redux/es/connect/connect";
import TitleBar from "../../../UI/TitleBar/TitleBar";
import TabContainer from "../../../UI/TabContainer/TabContainer";
import Deposit from "./Deposit/Deposit";
import WageredAmount from "./WageredAmount/WageredAmount";
import Loss from "./Loss/Loss";

const Limits = props => {
    useEffect(() => {
        let pType = 'root-level';
        let pCode = 'Limits';

        props.pageType(pType);
        props.pageCode(pCode);
        // eslint-disable-next-line
    }, []);

    const goToHome = () => {
        props.history.push('/');
    }

    const tabs = [
        {
          id: 1,
          label: props.t('Deposit'),
          component: <Deposit />,
        },
        {
          id: 2,
          label: props.t('Wagered Amount'),
          component: <WageredAmount />,
        //   disabled: true,
        },
        {
          id: 3,
          label: props.t('Loss'),
          component: <Loss />,
        //   disabled: true,
        },
        
      ]

    return (
        <div className={"limitsScreen"}>
            <TitleBar pageTitle={props.t('Responsible Gaming')} pageSubTitle={props.t('Limits')} closeHandler={goToHome} closeButton={true}/>
            <TabContainer tabs={tabs} />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        application: state.application,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        pageType: (pType) => dispatch(storeActions.setPageType(pType)),
        pageCode: (pCode) => dispatch(storeActions.setPageCode(pCode)),
    };
};

export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(Limits));