export const menu = {
    CREATE_PAGE_TYPES: "MENU_CREATE_PAGE_TYPES",
    LEFT_OPEN: "LEFT_MENU_OPEN",
    LEFT_CLOSE: "LEFT_MENU_CLOSE",
    REQUEST_MENU: "REQUEST_MENU",
    RECEIVED_MENU: "RECEIVED_MENU",
    TOP_MENU_SHOW: "TOP_MENU_SHOW",
    TOP_MENU_HIDE: "TOP_MENU_HIDE",
    RIGHT_OPEN: "RIGHT_MENU_OPEN",
    RIGHT_CLOSE: "RIGHT_MENU_CLOSE",
    SHOW_PROFILE_SECTION: "RIGHT_MENU_SHOW_PROFILE_SECTION"
};

export const application = {
    INITIALIZE: "APPLICATION_INITIALIZE",
    REINITIALIZE: "APPLICATION_REINITIALIZE",
    SET_MENU_HEIGHT: "APPLICATION_SET_TOP_MENU_HEIGHT",
    SET_PAGE_CODE: "APPLICATION_SET_PAGE_CODE",
    SET_PAGE_TYPE: "APPLICATION_SET_PAGE_TYPE",
    ROUTE_CHANGED: "APPLICATION_ROUTE_CHANGED",
    RESIZE: "APPLICATION_RESIZE",
    SET_PAGE_CLASSNAME: "APPLICATION_SET_PAGE_CLASSNAME",
    SHOW_GLOBAL_WIDGET: "APPLICATION_SHOW_GLOBAL_WIDGET",
    HIDE_GLOBAL_WIDGET: "APPLICATION_HIDE_GLOBAL_WIDGET",
    CHANGE_LANGUAGE: "APPLICATION_CHANGE_LANGUAGE",
    CHANGE_ACCOUNT_LANGUAGE: "APPLICATION_CHANGE_ACCOUNT_LANGUAGE",
    GET_PROMOTIONS: "APPLICATION_GET_PROMOTIONS",
    SET_PROMOTIONS: "APPLICATION_SET_PROMOTIONS",
    GET_ELIGIBLE_BONUSES: "APPLICATION_GET_ELIGIBLE_BONUSES",
    GET_BONUS_AVAILABLE: "APPLICATION_GET_BONUS_AVAILABLE",
    CLAIM_BONUS: "APPLICATION_CLAIM_BONUS",
    BONUS_CLAIMED: "APPLICATION_BONUS_CLAIMED",
    RESET_BONUS_CLAIM_STATUS: "APPLICATION_RESET_BONUS_STATUS",
    SET_ELIGIBLE_BONUSES: "APPLICATION_SET_ELIGIBLE_BONUSES",
    SET_AVAILABLE_BONUSES: "APPLICATION_SET_AVAILABLE_BONUSES",
    GET_PREEURO: "APPLICATION_GET_PREEURO",
    SET_PREEURO: "APPLICATION_SET_PREEURO",
    SET_LAST_PRODUCT_ID: "APPLICATION_SET_LAST_PRODUCT_ID",
    REDEEEM_CODE: "APPLICATION_REDEEM_CODE",
    REDEEEM_CODE_RESET: "APPLICATION_REDEEM_CODE_RESET",
    REDEEEM_CODE_RESPONSE: "APPLICATION_REDEEM_CODE_RESPONSE",
    PUSH_EVENT_LOG: 'APPLICATION_PUSH_EVENT_LOG',
    SET_ROUTER_HISTORY: 'APPLICATION_SET_ROUTER_HISTORY',
};

export const bonuses = {
    GET: "BONUSES_GET",
    SET: "BONUSES_SET",
    GET_BONUS_CALCULATOR: 'GET_BONUS_CALCULATOR',
    SET_AWARD_BONUS: 'APPLICATION_SET_AWARD_BONUS',
};

export const authentication = {
    START_APP_AUTH: "AUTHENTICATION_START_APP_AUTH",
    AUTHENTICATE: "AUTHENTICATION_AUTHENTICATE",
    CHECK_TOKEN_EXPIRATION: "AUTHENTICATION_CHECK_TOKEN_EXPIRATION",
    REFRESH_AUTH_TOKEN: "AUTHENTICATION_REFRESH_AUTH_TOKEN",
    CLEAR: "AUTHENTICATION_CLEAR",
    ACCOUNT_PROBLEM: "AUTHENTICATION_ACCOUNT_PROBLEM"
};

export const login = {
    USERNAME: "LOGIN_USERNAME",
    SET_USERNAME: "SET_LOGIN_USERNAME",
    PASSWORD: "LOGIN_PASSWORD",
    SET_PASSWORD: "SET_LOGIN_PASSWORD",
    METHOD: "LOGIN_METHOD",
    STEP: "LOGIN_STEP",
    ERROR_USERNAME: "LOGIN_ERROR_USERNAME",
    ERROR_PASSWORD: "LOGIN_ERROR_PASSWORD",
    REQUEST_AUTHENTICATION: "LOGIN_REQUEST_AUTHENTICATION",
    IN_REQUEST: "LOGIN_IN_REQUEST",
    LOGOUT_USER: "LOGIN_LOGOUT_USER",
    ALLOW_SMS_REQUEST: "LOGIN_ALLOW_SMS_REQUEST",
    ETA_SMS_REQUEST: "LOGIN_ETA_SMS_REQUEST",
    SMS_ETA: "LOGIN_SMS_ETA",
    START_SMS_COUNTDOWN: "LOGIN_START_SMS_COUNTDOWN",
    REQUEST_SMS_CODE: "LOGIN_REQUEST_SMS_CODE"
};

export const wallet = {
    REQUEST_WALLET: "REQUEST_WALLET",
    RECEIVED_WALLET: "RECEIVED_WALLET",
    RESET_WALLET: "RESET_WALLET",
    REQUEST_REMOVE_BONUS: "REQUEST_REMOVE_BONUS",
    RECEIVED_REMOVE_BONUS: "RECEIVED_REMOVE_BONUS",
    CORE_SET_BALANCE: "WALLET_CORE_SET_BALANCE"
};

export const register = {
    VALIDATE_FIELD: "REGISTER_VALIDATE_FIELD",
    VALIDATE_FIELD_RESPONSE: "REGISTER_VALIDATE_FIELD_RESPONSE",
    FIELD_CHANGED: "REGISTER_FIELD_CHANGED",
    SEND_SMS: "REGISTER_SEND_SMS",
    SIGN_UP: "REGISTER_SIGN_UP",
    SET_CURRENT_STEP: "REGISTER_SET_CURRENT_STEP",
    SET_SPINE_SIZE: "REGISTER_SPINE_SIZE",
    SIGN_UP_RESPONSE: "REGISTER_SIGN_UP_RESPONSE",
    CHECKBOX_ERROR: "REGISTER_CHECKBOX_ERROR",
    SET_PARTIAL_ACCOUNT: "REGISTER_SET_PARTIAL_ACCOUNT",
    SAVE_REGISTER_FIELDS: "REGISTER_SAVE_REGISTER_FIELDS",
    GET_PARTIAL_ACCOUNT: "REGISTER_GET_PARTIAL_ACCOUNT",
    RESET: "REGISTER_RESET",
    SET_TIMER: "REGISTER_SET_TIMER",
    SET_VERIFICATION_TYPE: "REGISTER_SET_VERIFICATION_TYPE",
    START_SIGN_UP: "REGISTER_START_SIGN_UP",
    VALIDATE_EMAIL: "REGISTER_VALIDATE_EMAIL_ADDRESS",
    ACCOUNT_EMAIL_VALIDATED: "ACCOUNT_EMAIL_VALIDATED",
    SET_BONUS_COINS: "REGISTER_SET_BONUS_COINS",
    GET_BONUS_COINS: "REGISTER_GET_BONUS_COINS",
    UPDATE_REGISTER_FIELDS: "REGISTER_UPDATE_FIELDS",
    VALIDATE_PHONE: "REGISTER_VALIDATE_PHONE"
};

export const resetPassword = {
    SEND_EMAIL: "RESET_PASSWORD_SEND_EMAIL",
    SEND_EMAIL_RESPONSE: "RESET_PASSWORD_SEND_EMAIL_RESPONSE",
    SEND_NEW_PASSWORD: "RESET_PASSWORD_SEND_NEW_PASSWORD",
    NEW_PASSWORD_RESPONSE: "RESET_PASSWORD_NEW_PASSWORD_RESPONSE",
    SET_TOKEN: "RESET_PASSWORD_SET_TOKEN"
};

export const profile = {
    REQUEST_ACCOUNT: "PROFILE_REQUEST_ACCOUNT",
    RECEIVED_ACCOUNT: "PROFILE_RECEIVED_ACCOUNT",
    RESET_ACCOUNT: "PROFILE_RESET_ACCOUNT",
    REQUEST_LIMITS: "PROFILE_REQUEST_LIMITS",
    RECEIVED_LIMITS: "PROFILE_RECEIVED_LIMITS",
    SET_NEW_LIMIT: "PROFILE_SET_NEW_LIMIT",
    /**  @deprecated */
    SET_SELFEXCLUSION: "PROFILE_SET_SELFEXCLUSION",
    SELF_EXCLUDE: "PROFILE_SELF_EXCLUDE",
    CHECK_PASSWORD: "PROFILE_CHECK_PASSWORD",
    RECEIVED_PASSWORD_CHECK: "PROFILE_RECEIVED_PASSWORD_CHECK",
    CLEAR_PASSWORD_CACHE: "PROFILE_CLEAR_PASSWORD_CACHE",
    CHANGE_PASSWORD: "PROFILE_CHANGE_PASSWORD",
    RECEIVED_CHANGE_PASSWORD: "PROFILE_RECEIVED_CHANGE_PASSWORD",
    CHECK_PHONE: "PROFILE_CHECK_PHONE",
    RECEIVED_PHONE: "PROFILE_RECEIVED_PHONE",
    CHECK_PHONE_CODE: "PROFILE_CHECK_PHONE_CODE",
    RECEIVED_PHONE_CODE: "PROFILE_RECEIVED_PHONE_CODE",
    GET_CHAT_INFO: "PROFILE_GET_CHAT_INFO",
    RECEIVED_CHAT_INFO: "PROFILE_RECEIVED_CHAT_INFO",
    RESEND_VERIFICATION_EMAIL: "PROFILE_RESEND_VERIFICATION_EMAIL",
    SEND_SELF_EXCLUSION_CODES: "PROFILE_SEND_SELF_EXCLUSION_CODES"
};

export const transactions = {
    RECEIVED_TRANSACTIONS_HISTORY: "TRANSACTIONS_RECEIVED_TRANSACTIONS_HISTORY",
    REQUEST_TRANSACTIONS_HISTORY: "TRANSACTIONS_REQUEST_TRANSACTIONS_HISTORY"
};

export const paymentCheckout = {
    REQUEST_PAYMENT_LINK: "PAYMENTCHECKOUT_REQUEST_PAYMENT_LINK",
    RECEIVED_PAYMENT_LINK: "PAYMENTCHECKOUT_RECEIVED_PAYMENT_LINK",
    REQUEST_VIVA_TOKEN: "PAYMENTCHECKOUT_REQUEST_VIVA_TOKEN",
    RECEIVED_VIVA_TOKEN: "PAYMENTCHECKOUT_RECEIVED_VIVA_TOKEN",
    REQUEST_VIVA_CARD_TOKEN: "PAYMENTCHECKOUT_REQUEST_VIVA_CARD_TOKEN",
    REQUEST_VIVA_WITHDRAW: "PAYMENTCHECKOUT_REQUEST_VIVA_WITHDRAW",
    RECEIVED_VIVA_WITHDRAW: "PAYMENTCHECKOUT_RECEIVED_VIVA_WITHDRAW",
    RECEIVED_VIVA_CARD_TOKEN: "PAYMENTCHECKOUT_RECEIVED_VIVA_CARD_TOKEN",
    REQUEST_VIVA_CHARGE_TOKEN: "PAYMENTCHECKOUT_REQUEST_VIVA_CHARGE_TOKEN",
    SEND_VIVA_CHARGE_TOKEN: "PAYMENTCHECKOUT_SEND_VIVA_CHARGE_TOKEN",
    RECEIVED_VIVA_CHARGE_TOKEN: "PAYMENTCHECKOUT_RECEIVED_VIVA_CHARGE_TOKEN",
    REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD: "PAYMENTCHECKOUT_REQUEST_VIVA_CHARGE_TOKEN_WITH_CARD",
    RECEIVED_VIVA_CHARGE_TOKEN_WITH_CARD: "PAYMENTCHECKOUT_RECEIVED_VIVA_CHARGE_TOKEN_WITH_CARD",
    RESET_PAYMENT_CHECKOUT: "PAYMENT_CHECKOUT_RESET",
    OKTO_CREATE_PAYMENT_CODE: "PAYMENTCHECKOUT_OKTO_CREATE_PAYMENT_CODE",
    OKTO_RECEIVED_PAYMENT_CODE: "PAYMENTCHECKOUT_OKTO_RECEIVED_PAYMENT_CODE",
    OKTO_GET_CUSTOMER: "PAYMENTCHECKOUT_OKTO_GET_CUSTOMER",
    OKTO_RECEIVED_CUSTOMER: "PAYMENTCHECKOUT_OKTO_RECEIVED_CUSTOMER",
    OKTO_RESET: "PAYMENTCHECKOUT_OKTO_RESET",
    AIRCASH_REQUEST_PAYMENT_LINK: "PAYMENTCHECKOUT_AIRCASH_REQUEST_PAYMENT_LINK",
};

export const withdrawalCheckout = {
    REQUEST_WITHDRAWAL_LINK: "WITHDRAWALCHECKOUT_REQUEST_WITHDRAWAL_LINK",
    RECEIVED_WITHDRAWAL_LINK: "WITHDRAWALCHECKOUT_RECEIVED_WITHDRAWAL_LINK"
};

export const withdrawals = {
    RECEIVED_PENDING_WITHDRAWALS: "WITHDRAWALS_RECEIVED_PENDING_WITHDRAWALS",
    REQUEST_PENDING_WITHDRAWALS: "WITHDRAWALS_REQUEST_PENDING_WITHDRAWALS",
    REQUEST_PENDING_VIVA_WITHDRAWALS: "WITHDRAWALS_REQUEST_PENDING_VIVA_WITHDRAWALS",
    REQUEST_LOCATIONS: "WITHDRAWALS_REQUEST_LOCATIONS",
    RECEIVED_LOCATIONS: "WITHDRAWALS_RECEIVED_LOCATIONS",
    CLEAR_TAX_SERVICE: "WITHDRAWALS_CLEAR_TAX_SERVICE",
    REQUEST_TAX_SERVICE: "WITHDRAWALS_REQUEST_TAX_SERVICE",
    RECEIVED_TAX_SERVICE: "WITHDRAWALS_RECEIVED_TAX_SERVICE",
    REQUEST_CANCEL_WITHDRAWAL: "WITHDRAWALS_REQUEST_CANCEL_WITHDRAWAL",
    REQUEST_CANCEL_VIVA_WITHDRAWAL: "WITHDRAWALS_REQUEST_CANCEL_VIVA_WITHDRAWAL",
    RECEIVED_CANCEL_WITHDRAWAL: "WITHDRAWALS_RECEIVED_CANCEL_WITHDRAWAL",
    DOWNLOAD_CSV: "WITHDRAWALS_DOWNLOAD_CSV",
    CLEAN_VERIFICATION_CODE: "WITHDRAWALS_CLEAN_VERIFICATION_CODE",
    REQUEST_VERIFICATION_CODE: "WITHDRAWALS_REQUEST_VERIFICATION_CODE",
    RECEIVED_VERIFICATION_CODE: "WITHDRAWALS_RECEIVED_VERIFICATION_CODE",
    GET_PAYMENT_METHODS: "WITHDRAWALS_GET_PAYMENT_METHODS",
    RECEIVED_PAYMENT_METHODS: "WITHDRAWALS_RECEIVED_PAYMENT_METHODS",
    INIT_WITHDRAWAL: "WITHDRAWALS_INIT_WITHDRAWAL",
    LINK_IBAN: "WITHDRAWALS_LINK_IBAN",
    WITHDRAW: "WITHDRAWALS_WITHDRAW",
    REQUEST_WITHDRAW_HISTORY: "WITHDRAWALS_REQUEST_WITHDRAW_HISTORY",
};

export const documents = {
    REQUEST_DOCUMENTS: "DOCUMENTS_REQUEST_DOCUMENTS",
    RECEIVED_DOCUMENTS: "DOCUMENTS_RECEIVED_DOCUMENTS",
    REQUEST_DOCUMENTS_UPLOAD: "DOCUMENTS_REQUEST_DOCUMENTS_UPLOAD",
    RECEIVED_DOCUMENTS_UPLOAD: "DOCUMENTS_RECEIVED_DOCUMENTS_UPLOAD",
    REQUEST_DOCUMENTS_MAX_FILE_SIZE: "DOCUMENTS_REQUEST_DOCUMENTS_MAX_FILE_SIZE",
    RECEIVED_DOCUMENTS_MAX_FILE_SIZE: "DOCUMENTS_RECEIVED_DOCUMENTS_MAX_FILE_SIZE"
};

export const games = {
    REQUEST_GAMES_LIST: "GAMES_REQUEST_GAMES_LIST",
    RECEIVED_GAMES_LIST: "GAMES_RECEIVED_GAMES_LIST",
    SHOW_LOADING: "GAMES_SHOW_LOADING",
    HIDE_LOADING: "GAMES_HIDE_LOADING",
    SET_LIST_TYPE: "GAMES_SET_LIST_TYPE",
    OPEN_GAME_LAUNCHER: "GAMES_OPEN_GAME_LAUNCHER",
    SET_GAME_ID: "GAMES_SET_GAME_ID",
    SET_GAME_LAUNCHER: "GAMES_SET_GAME_LAUNCHER",
    REQUEST_GAME_URL: "GAMES_REQUEST_GAME_URL",
    RECEIVED_GAME_URL: "GAMES_RECEIVED_GAME_URL",
    CLOSE_GAME_LAUNCHER: "GAMES_CLOSE_GAME_LAUNCHER",
    CLOSE_REDIRECT_GAME_LAUNCHER: "GAMES_CLOSE_REDIRECT_GAME_LAUNCHER",
    SET_GAME_OBJECT: "GAMES_OPEN_GAME_LAUNCHER_SET_GAME_OBJECT",
    REQUEST_GAME_COLLECTIONS: "GAMES_REQUEST_GAME_COLLECTIONS",
    RECEIVED_GAME_COLLECTIONS: "GAMES_RECEIVED_GAME_COLLECTIONS",
};

export const withdraw_wizard = {
    REQUEST_TAXES: "WITHDRAW_WIZARD_REQUEST_TAXES",
    RECEIVED_TAXES: "WITHDRAW_WIZARD_RECEIVED_TAXES",
    SET_AMOUNT: "WITHDRAW_WIZARD_SET_AMOUNT",
    SET_LOCATION: "WITHDRAW_WIZARD_SET_LOCATION",
    GET_LOCATIONS: "WITHDRAW_WIZARD_GET_LOCATIONS",
    SET_STEP: "WITHDRAW_WIZARD_SET_STEP",
    INIT_LOCATIONS: "WITHDRAW_WIZARD_INIT_LOCATIONS",
    SEND_DATA: "WITHDRAW_WIZARD_SEND_DATA",
    RECEIVED_WITHDRAW: "WITHDRAW_WIZARD_RECEIVED_WITHDRAW",
    OPEN_MODAL: "WITHDRAW_WIZARD_OPEN_MODAL",
    SKIP_LOCATION: "WITHDRAW_WIZARD_SKIP_LOCATION",
    IS_DEPOSIT: "WITHDRAW_WIZARD_IS_DEPOSIT",
    IS_WITHDRAW: "WITHDRAW_WIZARD_IS_WITHDRAW"
};

export const customer_support = {
    SEND_COMPLAINT_FORM: "CUSTOMER_SUPPORT_SEND_COMPLAINT_FORM",
    COMPLAINT_FORM_RESPONSE: "CUSTOMER_SUPPORT_COMPLAINT_FORM_RESPONSE",
    REQUEST_GAME_RULES: "CUSTOMER_SUPPORT_REQUEST_GAME_RULES",
    RECEIVED_GAME_RULES: "CUSTOMER_SUPPORT_RECEIVED_GAME_RULES",
    CLEAN_COMPLAINT_FORM: "CUSTOMER_SUPPORT_CLEAN_COMPLAINT_FORM"
};

export const jackpot = {
    REQUEST_DETAILS: "JACKPOT_REQUEST_DETAILS",
    RECEIVED_DETAILS: "JACKPOT_RECEIVED_DETAILS",
    REQUEST_LATEST_WINNERS: "JACKPOT_REQUEST_LATEST_WINNERS",
    RECEIVED_LATEST_WINNERS: "JACKPOT_RECEIVED_LATEST_WINNERS",
    REQUEST_LATEST_JACKPOT_WINNERS: "JACKPOT_REQUEST_LATEST_JACKPOT_WINNERS",
    RECEIVED_LATEST_JACKPOT_WINNERS: "JACKPOT_RECEIVED_LATEST_JACKPOT_WINNERS",
    START_FETCH_CYCLE: "JACKPOT_START_FETCH_CYCLE",
    INC_REF_CNT: "JACKPOT_INC_REF_CNT",
    DEC_REF_CNT: "JACKPOT_DEC_REF_CNT",
    UPDATE_LATEST_WINNERS_LAST_FETCH_TIME: "JACKPOT_UPDATE_LATEST_WINNERS_LAST_FETCH_TIME",
    UPDATE_LATEST_JACKPOT_WINNERS_LAST_FETCH_TIME: "JACKPOT_UPDATE_LATEST_JACKPOT_WINNERS_LAST_FETCH_TIME"
};

export const jackpotList = {
    RECEIVED_DETAILS: "JACKPOT_LIST_RECEIVED_DETAILS",
}

export const happyHour = {
    STATUS: "HAPPY_HOUR_STATUS",
    UPDATE_STATUS: "HAPPY_HOUR_UPDATE_STATUS",
    STATUS_COMPLETED: "HAPPY_HOUR_STATUS_COMPLETED",
    CLEAR_STATUS: "HAPPY_HOUR_CLEAR_STATUS",
    CLEAR_STATUS_COMPLETED: "HAPPY_HOUR_CLEAR_STATUS_COMPLETED",
    PRIZE_WON: "HAPPY_HOUR_PRIZE_WON",
    CLEAR_PRIZE_WON: "HAPPY_HOUR_CLEAR_PRIZE_WON",
    BET_INFO: "HAPPY_HOUR_BET_INFO",
    HIDE_INFO: "HAPPY_HOUR_HIDE_INFO",
    SHOW_INFO: "HAPPY_HOUR_SHOW_INFO"
};

export const config = {
    SET: "CONFIG_SET",
    LOAD: "CONFIG_LOAD",
};

export const layout = {
    UPDATE_NOTCH: "LAYOUT_UPDATE_NOTCH",
    UPDATE_ORIENTATION: "LAYOUT_UPDATE_ORIENTATION"
};

export const alerts = {
    SET_NOTIFICATIONS_SEEN: "ALERTS_SET_NOTIFICATIONS_SEEN",
    SET_NOTIFICATION_READ: "ALERTS_SET_NOTIFICATION_READ",
    SET_MESSAGE_READ: "ALERTS_SET_MESSAGE_READ",
    SET_NOTIFICATION_SETTING: "ALERTS_SET_NOTIFICATION_SETTING",
    SET_MARKETING_AGREEMENTS: "ALERTS_SET_MARKETING_AGREEMETNS"
};

export const freeBets = {
    FREE_BETS_LOADED: "FREE_BETS_LOADED",
    FREE_BETS_FILTERS_LOADED: "FREE_BETS_FILTERS_LOADED",
    FREE_BETS_FILTERS_UPDATE: "FREE_BETS_FILTERS_UPDATE",
    FREE_BETS_FETCH: "FREE_BETS_FETCH",
    FREE_BETS_FETCH_FILTERS: "FREE_BETS_FETCH_FILTERS",
    FREE_BET_REMOVE: "FREE_BETS_REMOVE",
    FREE_BETS_FETCH_HISTORY: "FREE_BETS_FETCH_HISTORY",
    FREE_BETS_SET_HISTORY: "FREE_BETS_SET_HISTORY",
};

export const requestManager = {
    CREATE_REQUEST: "REQUEST_MANAGER_CREATE_REQUEST",
    FAILED_REQUEST: "REQUEST_MANAGER_FAILED_REQUEST",
    RESET_ONE: "REQUEST_MANAGER_RESET_ONE",
    RESET: "REQUEST_MANAGER_RESET"
}

export const freeSpins = {
    FREE_SPINS_LOADED: "FREE_SPINS_LOADED",
    FREE_SPINS_FETCH: "FREE_SPINS_FETCH",
    FREE_SPIN_REMOVE: "FREE_SPIN_REMOVE",
    FREE_SPINS_FETCH_HISTORY: "FREE_SPINS_FETCH_HISTORY",
    FREE_SPINS_SET_HISTORY: "FREE_SPINS_SET_HISTORY",
};

export const druid = {
    SCAN_DOCUMENT: "DRUID_SCAN_DOCUMENT",
    SCAN_COMPLETE: "DRUID_SCAN_COMPLETE"
}

export const marketingOffer = {
    GET: "MARKETING_OFFER_GET",
    SET: "MARKETING_OFFER_SET"
}