import React, {useEffect, useRef, useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {withRouter, Link} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import connect from "react-redux/es/connect/connect";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Secured from '@material-ui/icons/LockOpen';
import IconNext from '@material-ui/icons/TrendingFlat';
import IconLoading from '@material-ui/icons/Cached';
import IconWait from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import * as storeActions from "../../../store/actions";
import { IS_PHONE } from './../../../utils/LoginMethods';
import * as LoginMethods from "../../../utils/LoginMethods";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    margin: {
        margin: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
});

const Password = (props) => {
    const classes = props.classes;
    const {t} = useTranslation();
    const [values, setValues] = useState({
        password: '',
        showPassword: false,
        focused: false,
        fTimer: 0,
    });
    const inputElementRef = useRef(null);

    const stepNumber = 2;

    useEffect(() => {
        // do something on componentDidMount
        // console.log(props);
        // console.log(props.isActive);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!props.isActive) {
            return;
        }

        if (values.focused) {
            return;
        }

        if (props.login.inRequest) {
            return;
        }

        clearTimeout(values.fTimer);
        let fTimer = setTimeout(focusField, 1000);
        setValues({ ...values, focused: true, fTimer: fTimer });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isActive, values]);

    useEffect(() => {
        if (!(stepNumber === props.currentStep)) {
            return;
        }

        if (1 === props.login.step) {
            return props.previousStep();
        }
    });

    const focusField = () => {
        if (!inputElementRef.current) {
            clearTimeout(values.fTimer);
            return;
        }
        inputElementRef.current.click();
        setTimeout(() => {
            if (!props.isActive) {
                return;
            }

            if(!inputElementRef.current) {
                return;
            }

            inputElementRef.current.focus();
        }, 200);
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
        inputElementRef.current.focus();
    };

    const passwordHandler = (event) => {

        if (props.login.method === LoginMethods.IS_UNDEFINED) {
            return;
        }

        let password = event.target.value;
        if (!password) {
            password = "";
        }

        props.onPasswordChange(password);
    };

    let passwordValue = "";
    if (props.login.password) {
        passwordValue = props.login.password;
    }

    let buttonDisabled = true;
    if (props.login.allowLoginButton) {
        buttonDisabled = false;
    }

    let error = false;
    if (props.login.errorPassword) {
        error = true;
    }

    let buttonIcon = <IconNext className={classes.rightIcon} />;
    if (props.login.inRequest) {
        buttonIcon = <IconLoading className={`${classes.rightIcon} spin`} />
    }

    const enterKeyHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!inputElementRef.current) {
            return;
        }

        if (!(stepNumber === props.currentStep)) {
            return;
        }

        if (buttonDisabled) {
            return;
        }

        loginHandler(event);
    };

    const loginHandler = (event) => {
        if (buttonDisabled) {
            return;
        }

        setValues({ ...values, focused: false });
        props.startLogin();
    };

    const requestSmsCode = (event) => {
        event.preventDefault();
        event.stopPropagation();

        props.requestSmsCode();

        return false;
    };

    let smsAreaContent = (
        <Link to={"/register"}>{ t('No account yet?') }</Link>
    );
    if (IS_PHONE === props.login.method) {
        smsAreaContent = (
            <a href={"/mata"} className={"smsRequest"} onClick={ requestSmsCode }>{ t('I want to receive a SMS code for login.') }</a>
        );

        if (!props.login.allowSMS) {
            smsAreaContent = (
                <Grid container>
                    <Grid item xs={2}><IconWait className={"smsWaitIcon"} /></Grid>
                    <Grid item xs={10} className={"smsMessage"}>
                        { t("If you did not receive the SMS code, you can request another one in ") }
                        { ` ${props.login.etaSmsRequest} ` }
                        { t("seconds.") }
                    </Grid>
                </Grid>
            );
        }
    }

    return (
        <div className={"usernameStep password"}>
            <div className={"usernameSupport"}>
                <div className={"inputArea"}>
                    <form className={classes.container} noValidate autoComplete="off" onSubmit={enterKeyHandler}>
                        <Secured className={"loginPasswordLeftIcon"} />
                        <TextField
                            autoFocus
                            error={error}
                            label={t("Password or SMS code")}
                            id={"password"}
                            type={values.showPassword ? 'text' : 'password'}
                            className={`${classes.textField} usernameField`}
                            value={passwordValue}
                            onChange={ passwordHandler }
                            inputRef={inputElementRef}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            aria-label="Toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form>
                </div>
                <div className={"resetPasswordSupport"}>
                    <Link to={"/reset-password"}>{ t('Forgot password?') }</Link>
                </div>
                <div className={"nextSupport"}>
                    <Button disabled={buttonDisabled} variant="contained" size="large" color="primary" className={classes.margin} onClick={loginHandler}>
                        { (props.login.inRequest) ? t("LOADING...") : t("LOG IN") }
                        { buttonIcon }
                    </Button>
                </div>
                <div className={"toRegister"}>
                    { smsAreaContent }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        login: state.login,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeStep: (stepNumber) => dispatch(storeActions.loginStep(stepNumber)),
        onPasswordChange: (password) => dispatch(storeActions.loginPassword(password)),
        startLogin: () => dispatch(storeActions.loginStart()),
        requestSmsCode: () => dispatch(storeActions.requestSmsCode()),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Password))));
