import { configConstants } from "../constants";

export const enableSounds = () => ({
  type: configConstants.SOUNDS_ENABLED,
});

export const disableSounds = () => ({
  type: configConstants.SOUNDS_DISABLED
});

export const toggleSounds = () => ({
  type: configConstants.SOUNDS_TOGGLE_STATE
});