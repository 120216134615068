import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from "../../../../../../store/actions/index";
import connect from "react-redux/es/connect/connect";
import TitleBar from "../../../../../UI/TitleBar/TitleBar";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PageTitle from '../../../../../UI/PageTitle/PageTitle';
import Button from '../../../../../UI/Button/Button';
import Translate from "../../../../../../utils/Translate";
import ResponseErrorMessages from "../../../../../../store/errors/ResponseErrorMessages";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import './ConfirmEmail.scss';


const NOT_AUTHENTICATED = 12;
const INVALID_EMAIL_CODE = 43;
const INVALID_SMS_CODE = 44;

class ConfirmEmail extends Component {

    state = {
        password: '',
        passwordHasErrors: true,
        passwordError: '',
        emailCode: '',
        emailCodeHasErrors: true,
        emailCodeError: '',
        smsCode: '',
        smsCodeHasErrors: true,
        smsCodeError: '',
        agree: false,
        showPassword: false,
    }

    constructor(props) {
        super(props);
        this.redirectURL = '/';
    }

    /**
     * help poedit to scan and find the text
     */
    t(string){
        return string;
    }

    componentDidMount() {
        if (this.props.error) {
            this.setState({
                ...this.state,
                passwordHasErrors: true,
                passwordError: ResponseErrorMessages.get(this.props.error),
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.profile !== prevProps.profile) {
            if (!this.props.profile.loadingCheckPassword && this.props.profile.passwordIsValid !== null) {
                this.handleConfirmSelfExclusion();
            }
        }
        if (this.state.password !== prevState.password) {
            this.handleLength();
        }
    }

    handleLength = () => {
        if (this.state.password.length < 3) {
            this.setState({
                ...this.state,
                passwordHasErrors: true,
                passwordError: '',
            });
        } else {
            this.setState({
                ...this.state,
                passwordHasErrors: false,
                passwordError: '',
            });
        }
    }

    handleExclusionEnd(periodID){
        let today = new Date();
        switch (periodID) {
            case 7: today.setMonth(today.getMonth() + 6); break; // 6 Months from now
            case 8: today.setFullYear(today.getFullYear() + 1); break; // 1 Year from now
            case 9: today.setFullYear(today.getFullYear() + 3); break; // 3 Years from now
            default: break;
        }
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = today.toLocaleString('default', { month: 'long' });
        let yyyy = today.getFullYear();
        return dd + ' ' + mm + ' ' + yyyy;
    }

    handleChange = prop => event => {
        this.setState({
            ...this.state,
            [prop]: event.target.value,
        })
    };

    logoutHandler() {
        this.props.history.push(this.redirectURL);
        this.props.onLogout();
    }

    handleClickShowPassword = () => {
        this.setState({
            ...this.state,
            showPassword: !this.state.showPassword,
        })
    };

    getRealAgree() {
        return document.getElementById("confirm-checkbox").checked;
    }

    onClickHandler() {
        this.setState({
            ...this.state,
            agree: this.getRealAgree(),
        });
    }

    handleConfirmAction = () => {
        // this.props.onCheckPassword(this.state.password);
        // this.handleConfirmSelfExclusion(true);
        this.props.selfExclude({
            limit_type: 2,
            limit_type_period: this.props.activeChoice,
            password: this.state.password,
            sms_code: this.state.smsCode,
            email_code: this.state.emailCode
        })
    };

    handleConfirmSelfExclusion = () => {

        if (this.props.profile.errorCode) {
            this.setState({
                passwordHasErrors: this.props.profile.errorCode === NOT_AUTHENTICATED,
                passwordError: this.props.profile.errorCode === NOT_AUTHENTICATED ? this.props.t("Wrong password, try again!") : '',
                emailCodeHasErrors: this.props.profile.errorCode === INVALID_EMAIL_CODE,
                emailCodeError: this.props.profile.errorCode === INVALID_EMAIL_CODE ? this.props.t("Wrong code, try again!") : '',
                smsCodeHasErrors: this.props.profile.errorCode === INVALID_SMS_CODE,
                smsCodeError: this.props.profile.errorCode === INVALID_SMS_CODE ? this.props.t("Wrong code, try again!") : '',
            });
        } else {
            this.logoutHandler();
        }
    }

    render() {

        return (
            <div className={"ConfirmEmailScreen"}>
                <TitleBar pageTitle={this.props.t('Responsible Gaming')} backHandler={() => this.props.return(false)}/>
                
                <AppBar position="static" className="SubtitleBar">
                    <Typography variant="subtitle1"><Translate text={this.t('Self-Exclusion')}/></Typography>
                </AppBar>
                
                <div className="ConfirmEmailContent">
                    <PageTitle icon={<NotInterestedIcon className={"iconSettings"} />} title={this.props.title} subtitle={this.props.subtitle} />
                    <div className="TextContent">
                        <p className={'EmailSent'}>
                            <Translate text={this.t('We sent you an email with the next steps required to activate your self-exclusion.')}/>
                        </p>
                        <p>
                            <Translate 
                                text={this.t('By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}${brandFull}${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.')}
                                placeholder={{pinkBoldStart: '<span class="pinkBold">', pinkBoldEnd: '</span>',brandFull: window.config.brand_name_full}}
                            />
                        </p>
                        <p>
                            <Translate 
                                text={this.t('To complete the process, ${customBoldStart}please confirm your identity below. The exclusion begins immediately after.${customBoldEnd}')} 
                                placeholder={{customBoldStart: '<span class="customBold">', customBoldEnd: '</span>'}}
                            />
                        </p>
                    </div>
                    <div className="AlertContent">
                        <h3>
                            <Translate text={this.t('Exclusion ends:')} /><br/>
                            <span className="ExclusionDate">{this.handleExclusionEnd(this.props.activeChoice)}</span>
                        </h3>
                        <FormControl className="FormInput">
                            <PersonOutlineOutlinedIcon className={"inputLeftIcon " + (this.state.passwordHasErrors && this.state.passwordError !== '' ? 'ErrorField' : '')} />
                            <InputLabel htmlFor="CurrentFormField" error={this.state.passwordHasErrors && this.state.passwordError !== ''}>{this.props.t('Confirm password')}</InputLabel>
                            <Input
                                id="CurrentFormField"
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                error={this.state.passwordHasErrors && this.state.passwordError !== ''}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowPassword}
                                        >
                                            {this.state.showPassword ? <Visibility className={'VisibilityIcon'} /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error={this.state.passwordHasErrors && this.state.passwordError !== ''}>{this.state.passwordError}</FormHelperText>
                        </FormControl>
                        <FormControl className="FormInput">
                            <PersonOutlineOutlinedIcon className={"inputLeftIcon " + (this.state.emailCodeHasErrors && this.state.emailCodeError !== '' ? 'ErrorField' : '')} />
                            <InputLabel htmlFor="CurrentFormField" error={this.state.emailCodeHasErrors && this.state.emailCodeError !== ''}>{this.props.t('Confirm email code')}</InputLabel>
                            <Input
                                id="CurrentFormField"
                                type={'tel'}
                                value={this.state.emailCode}
                                onChange={this.handleChange('emailCode')}
                                error={this.state.emailCodeHasErrors && this.state.emailCodeError !== ''}
                            />
                            <FormHelperText error={this.state.emailCodeHasErrors && this.state.emailCodeError !== ''}>{this.state.emailCodeError}</FormHelperText>
                        </FormControl>
                        <FormControl className="FormInput">
                            <PersonOutlineOutlinedIcon className={"inputLeftIcon " + (this.state.smsCodeHasErrors && this.state.smsCodeError !== '' ? 'ErrorField' : '')} />
                            <InputLabel htmlFor="CurrentFormField" error={this.state.smsCodeHasErrors && this.state.smsCodeError !== ''}>{this.props.t('Confirm SMS Security Code')}</InputLabel>
                            <Input
                                id="CurrentFormField"
                                type={'tel'}
                                value={this.state.smsCode}
                                onChange={this.handleChange('smsCode')}
                                error={this.state.smsCodeHasErrors && this.state.smsCodeError !== ''}
                            />
                            <FormHelperText error={this.state.smsCodeHasErrors && this.state.smsCodeError !== ''}>{this.state.smsCodeError}</FormHelperText>
                        </FormControl>
                    </div>
                    {/*<span className={'ResendSMS'} onClick={() => console.log('Resend SMS code')}>*/}
                    {/*    <Translate text={this.t('Resend SMS code')} />*/}
                    {/*</span>*/}
                    <div className="Terms">
                        <Checkbox
                            id={'confirm-checkbox'}
                            onClick={this.onClickHandler.bind(this)}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={this.state.agree}
                            className={"Checkbox"}
                        />
                        <label htmlFor={'confirm-checkbox'} className="ContentText">
                            {this.props.t("I agree to be restricted to my online casino account for the period specified above.")}
                        </label>
                    </div>
                    <Button 
                        type={"button"} 
                        full 
                        className={"active"} 
                        disabled={!(this.state.password !== '' && this.state.emailCode !== '' && this.state.smsCode !== '' && this.state.agree) || this.props.profile.loadingCheckPassword  }
                        click={this.handleConfirmAction}
                        value={this.props.profile.loadingCheckPassword ? <CircularProgress className={'Loading'} /> : this.props.t('Self-exclude now!')}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onSaveChoice: (limit_type, limit_type_period) => dispatch(storeActions.setSelfexclusion(limit_type, limit_type_period)),
        onCheckPassword: (password) => dispatch(storeActions.checkPassword(password)),
        onClearPasswordCache: () => dispatch(storeActions.clearPasswordCache()),
        onLogout: () => dispatch(storeActions.logout()),
        selfExclude: (data) => dispatch(storeActions.selfExclude(data))
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)));
