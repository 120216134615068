import React, { useEffect, useState, Fragment } from 'react';
import { withTranslation } from "react-i18next";
import './TimeSpendPopup.scss';
import Button from '../../UI/Button/Button';
import Icons from "../../../assets/Icons/Icons";
const TimeSpendPopup = (props) => {

    const [popupActive, setPopupActive] = useState(false);

    useEffect(() => {
        window.localStorage.setItem('TimeSpendPopup', 0);

        //Increment the time counter every minute.
        setInterval(TimeSpendHandler, 60000); // 1 minute

    }, [])

    const TimeSpendHandler = () => {
        let time = window.localStorage.getItem('TimeSpendPopup');
        time = parseInt(time) + 1;
        if (time >= 60) {
            setPopupActive(true);
        } else {
            window.localStorage.setItem('TimeSpendPopup', time);
        }
    };

    const closePopupHandler = () => {
        window.localStorage.setItem('TimeSpendPopup', 0);
        setPopupActive(false);
    }

    if (popupActive) {
        return (
            <div className={"TimeSpendPopupScreen"}>
                <div className="TimeSpendPopup">
                    {Icons.get('timer', 'timer')}
                    <h1>{props.t('60 Minutes')}</h1>
                    <p>{props.t('60 minutes spent on website from last notification')}</p>
                    <Button full click={closePopupHandler} value={props.t('Close')} />
                </div>
            </div>
        );
    } else {
        return <Fragment />;
    }

};

export default withTranslation()(TimeSpendPopup);
