import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import Icons from '../../../../../assets/Icons/Icons';
import Moment from "react-moment";
import * as stateActions from "../../../../../store/actions";
import Utils from "../../../../../utils/Utils";

const STATUS_REJECTED = 3;

const STATUS_READY_FOR_CASH_IN = 2;

const STATUS_PROCESSING_ONLINE = 2;
export const TYPE_DEPOSIT_CASH = 1;
export const TYPE_DEPOSIT_SAFECHARGE = 24;
export const TYPE_HAPPY_HOUR = 30;
export const TYPE_DEPOSIT_VIVA = 53;
export const TYPE_WITHDRAW_VIVA = 54;
export const TYPE_WITHDRAW_CANCEL_VIVA = 55;
export const TYPE_WITHDRAW_PROCESS_VIVA = 56;
export const TYPE_RETAIL_TICKET_CASH_IN = 85;

const SingleTransaction = (props) => {
    
    const formatCurrency = (value) => {
        value = parseFloat(value);
        return (
            value
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              .replace(',00', '')
          )
    }

    let transactionStatus = {
        0: props.t("Unknown"),
        1: props.t("Pending Approval"),
        2: props.t("Ready For Cash-in"),
        3: props.t("Failed"),
        4: props.t("Collected"),
        5: props.t("Cancelled"),
    };

    let transactionTypes = {
        0: "unknown",
        1: "deposit",
        2: "withdraw",
        10: "withdraw",
        11: "withdraw",
        12: "withdraw",
        24: "deposit",
        53: "deposit",
        54: "withdraw",
        55: "deposit",
        56: "withdraw",
        85: "deposit",
        114: "deposit",
        130: "deposit",
        140: "deposit",
    };

    const requestTaxes = () => {
        if (typeof props.taxes[props.transaction.id] === "undefined") {
            props.requestTaxService(transactionTypes[props.transaction.type] === 'deposit' ? 1 : 2, props.transaction.amount, props.transaction.id);
        }
    }

    const getSingleTransaction = () => {
        if(props.transaction){
            let transaction = props.transaction;
            let transactionAmount = parseInt(transaction.amount, 10);

            let addAmounts = false;
            if (!(transactionAmount > 0)) {
                addAmounts = true;
            }

            let currentTax = props.t('Calculating...');
            if(transaction.tax === null && (transaction.status === 3 || transaction.status === 5 || transaction.status === 1)){
                currentTax = "0";
            }
            if(!props.withdrawals.loadingTax && Object.entries(props.withdrawals.taxes).length > 0 && props.withdrawals.taxes !== undefined){
                if((transaction.status !== 3 && transaction.status !== 5 && transaction.status !== 1)){
                    if(props.withdrawals.taxes[transaction.id] !== undefined && Object.entries(props.withdrawals.taxes[transaction.id]).length > 0){
                        currentTax = props.withdrawals.taxes[transaction.id].tax;
                    } else {
                        Utils.delayedFunction("requestTax"+transaction.id,requestTaxes,0)
                    }
                }
            }
            
            let details = '';
            if (transaction.cancelled && transaction.cancelled.length > 0) {
                details = transaction.cancelled.map((detail, index) => {
                    if(detail.insert_date){
                        let cancelType = "Partial cancellation";
                        if (addAmounts) {
                            transactionAmount += parseInt(detail.cancelled_amount, 10);
                            if (!(transaction.cancelled.length > 1)) {
                                cancelType = "Full cancellation";
                            }
                        }

                        if (STATUS_REJECTED === transaction.status && !(transaction.cancelled.length > 1)) {
                            cancelType = "Full cancellation";
                        }
                        return (
                            <div key={index} className="TransactionDetails">
                                <span className={"DetailsDate"}><Moment format={"DD / MM / YYYY - HH:mm:ss"} unix>{detail.insert_date}</Moment></span>
                                <p className="DetailsDescription">
                                    {props.t(cancelType)}&nbsp;|&nbsp;
                                    <strong>&nbsp;{"+" + formatCurrency(detail.cancelled_amount)}&nbsp;RON</strong>
                                </p>
                            </div>
                        )
                    }
                    return false;
                });
            }

            if (transaction.type === TYPE_WITHDRAW_VIVA || transaction.type === TYPE_DEPOSIT_VIVA || transaction.type === TYPE_WITHDRAW_CANCEL_VIVA || transaction.type === TYPE_WITHDRAW_PROCESS_VIVA) {
                transaction.location_name = false;
            } else if(transaction.type === TYPE_RETAIL_TICKET_CASH_IN) {
                transaction.location_name = props.t("Retail ticket payment");
            }

            return (
                
                <div className={"SingleTransaction"}>
                    <i>{transactionTypes[transaction.type] === 'withdraw' ? Icons.get('depositCoin', `depositCoin`) : Icons.get('withdrawCoin', `withdrawCoin`)}</i>
                    <span className={"TransactionDate"}><Moment format={"DD / MM / YYYY - HH:mm:ss"} unix>{transaction.insert_date}</Moment></span>
                    <p className="TransactionMethod">
                        {transaction.location_name ? transaction.location_name : (transaction.type == TYPE_HAPPY_HOUR ? 'Happy Hour' : 'Online')}&nbsp;|&nbsp;
                        <strong className={transactionTypes[transaction.type]}>
                            &nbsp;
                            {
                                transactionTypes[transaction.type] === 'deposit'
                                ? transactionAmount > 0 ? "+" + formatCurrency(transactionAmount) : formatCurrency(transactionAmount) 
                                : transactionAmount > 0 ? "-" + formatCurrency(transactionAmount) : formatCurrency(transactionAmount)
                            }
                            &nbsp;RON
                        </strong>
                    </p>
                    <div className={"TransactionState"}>
                        <span className={"StatusType-" + transaction.status}>{transaction.type == TYPE_HAPPY_HOUR ? "" : (transaction.status === STATUS_PROCESSING_ONLINE && !transaction.location_name ? props.t("Processing...") : props.t(transactionStatus[transaction.status]))}</span>
                        {transaction.status === STATUS_REJECTED ? <a href="/" className="FailDetails">&nbsp;|&nbsp;{props.t('Details')}</a> : null}
                    </div>
                    {details}
                    <span className={"TransactionTax"}>{props.t('Tax')}:&nbsp;{currentTax}&nbsp;RON</span>
                </div>
            );
        }
    };
    return getSingleTransaction();
};

const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
        taxes: state.transactions.taxes
    }
};
const mapDispatchToProps = dispatch => {
    return {
        // cancelAmount: (id, amount) => dispatch(stateActions.requestCancelWithdrawal(id, amount)),
        requestTaxService: (type, amount,id) => dispatch(stateActions.requestTaxService(type, amount,id)),
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleTransaction)));