import React, { useState, useEffect } from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import { connect } from 'react-redux';
import Icons from '../../../../../assets/Icons/Icons';
import Button from '../../../../UI/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './DocumentSelector.scss';

const TYPE_ID = 1;
const TYPE_UTILITIES_BILL = 2;
const TYPE_CARD = 3;
const TYPE_PASSPORT = 4;

const DocumentSelector = (props) => {
    const { t } = useTranslation();
    const [typeContent, setTypeContent] = useState({
        titleIcon: '',
        title: '',
        subtitle: '',
    });

    const [newDocument, setNewDocument] = useState('');

    const [userMedia, setUserMedia] = useState(null);

    useEffect(() => {
        if(props.documentType){
            handleDocumentTypeContent(props.documentType);
            // hasGetUserMedia();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(newDocument){
            props.changePage('camera', props.documentType, props.documentID, newDocument);
        }
        // eslint-disable-next-line
    }, [newDocument]);

    const hasGetUserMedia = () => {
        navigator.mediaDevices.getUserMedia({video: true})
         .then(function(stream) {
             setUserMedia(true);
             stream.getTracks()[0].stop();
         })
         .catch(function(err) {
             setUserMedia(false);
         });
     }

    const handleDocumentTypeContent = (type) => {
        if(type === TYPE_ID) {
            setTypeContent({
                ...typeContent,
                titleIcon: Icons.get('identity', `IdentityIcon`),
                title: t('ID Card'),
                subtitle: t('Please upload a picture of your id card.'),
            });
        }
        if(type === TYPE_UTILITIES_BILL){
            setTypeContent({
                ...typeContent,
                titleIcon: Icons.get('horseShoe', `IdentityIcon`),
                title: t('Utilities Bill'),
                subtitle: t('Please upload a picture of your utilities bill.'),
            });
        }
        if(type === TYPE_CARD){
            setTypeContent({
                ...typeContent,
                titleIcon: Icons.get('operation', `IdentityIcon`),
                title: t('Bank Card'),
                subtitle: t('Please upload a picture of your bank card.'),
            });
        }
        if(type === TYPE_PASSPORT){
            setTypeContent({
                ...typeContent,
                titleIcon: Icons.get('moneyWithdraw', `IdentityIcon`),
                title: t('Passport'),
                subtitle: t('Please upload a picture of your passport.'),
            });
        }
    }

    const handleGallery = () => {
        document.getElementById('gallery').click();
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          const file = e.target.files[0];
          reader.addEventListener("load", () =>
            setNewDocument(file)
          );
          reader.readAsDataURL(file);
        }
    };

    return(
        <div className={"DocumentSelectorScreen"}>
            <div className={'TypeIcon'}>
                {typeContent.titleIcon}
            </div>
            <h4>{typeContent.title}</h4>
            <span>{typeContent.subtitle}</span>
            <Button 
                className={'active'} 
                full
                disabled={!userMedia}
                // value={userMedia === null ? <CircularProgress className={'Loading'} /> : props.t('Use the camera')}
                value={props.t('Use the camera')}
                click={() => props.changePage('camera', props.documentType, props.documentID)}
            />
            <input type="file" accept={"image/png, image/jpeg" + (props.documentType !== 1 ? ', application/pdf' : '')} hidden id="gallery" onChange={onSelectFile}/>
            <Button 
                className={'gradient'} 
                full
                value={props.t('Select document from gallery')}
                click={handleGallery.bind(this)}
            />
            {props.documentType !== 1 &&
                <span>PNG, JPEG, {props.t('or')} PDF ({props.t('Max size: ') + props.documents.documentsMaxFileSize})</span>
            }
            {props.documentType === 1 &&
                <span>PNG {props.t('or')} JPEG ({props.t('Max size: ') + props.documents.documentsMaxFileSize})</span>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        documents: state.documents,
    }
};

export default withTranslation()(connect(mapStateToProps, null)(DocumentSelector));
