import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import * as paths from '../../ClientAPI/paths';

export function* requestWithdrawalLinkSaga(action) {
    const axios = ClientAPI.getInstance();
    try {
        const response = yield axios({
            url: paths.withdrawalCheckout.WITHDRAWAL_CHECKOUT,
            method: 'post',
            data: {
                amount: action.amount,
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Withdrawal Checkout response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Withdrawal Checkout response has no 'result' property`);
        }

        yield put(actions.receivedWithdrawalLink(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}