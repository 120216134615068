import React, { useEffect, useState, Fragment } from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../store/actions";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "./Okto.scss";
import Utils from "../../../utils/Utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Translate from "../../../utils/Translate";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Icons from "../../../assets/Icons/Icons";

const Okto = (props) => {
    const { t } = useTranslation();
    const [checks, setChecks] = useState({
        terms: { checked: false, message: '' },
        privacy: { checked: false, message: '' },
    });

    const [amount, setAmount] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [buttons, setButtons] = useState([]);
    const [isDefaultValue, setIsDefaultValue] = useState(false);


    const { responsePayload, profile,type, loading,  bonusId, createPaymentCode,oktoGetCustomer, oktoReset , onRequestTaxService,limitMessage, minimumLimit,dailyLimit,bonusMax,bonusMin,maximumLimit, taxes } = props;
    const {paymentCode,qrPayload,customer} = responsePayload;

    const {okto_terms_accepted,amount_can_deposit} = customer;

    const isFirstTime = okto_terms_accepted === null ? true : !okto_terms_accepted;

    const values = [20, 50, 100, 150, 250,500, 1000, 1500, 2500, 3000, 4500];

    const buttonsAreaType = type === 'deposit' ? 2 : 1; // 1 - all buttons, 2 - only min and max buttons

    const initButtons = (parentAmount) => {

        if (buttonsAreaType === 1) {
            setButtons(values);
            let amnt = minimumLimit <= maximumLimit ? minimumLimit : maximumLimit;
            Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, amnt, 1),1);
            setAmount(amnt * 100);
            return false;
        }

        // find if amount is in values
        let current =  parentAmount ? parentAmount/100 : amount/100;
        // prevent selecting bigger value than maximumLimit
        if (maximumLimit !== false && maximumLimit < current) {
            current = maximumLimit;
        }
        let preselected = current;
        let preselectedIndex = 0;
        let tempButtons = [];
        let def = false;
        values.every((val,index) => {

            if (current === val) {
                // if is default value stop and do nothing else;
                def=true;
                preselectedIndex = index;
                return false;
            } else if (current > val && current < values[index+1]) {
                // if is between two values, select 6 buttons from current value
                tempButtons = values.slice(index,index+6);
                // preselect button with the next value, if exists;
                if (typeof values[index+1] === "undefined") {
                    preselectedIndex = index;
                } else {
                    preselectedIndex = index+1;
                }
                // if there are not enough buttons, select 6 buttons from previous value
                if (tempButtons.length < 6) {
                    tempButtons = values.slice(index-(6 - tempButtons.length),index+(tempButtons.length));
                }
                return false;
            } else if (current < val) {
                preselectedIndex = index;
                tempButtons = values.slice(index,index+6);

                return false;
            }

            return true;
        } );

        if (typeof tempButtons[0] === "undefined" && !def) {
            tempButtons = values.slice(values.length-6,values.length);
            preselectedIndex = values.length-1;
        }

        while(values[preselectedIndex] > maximumLimit && preselectedIndex > 0) {
            preselectedIndex--;
        }

        preselected = values[preselectedIndex];

        setButtons(tempButtons);
        Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, preselected, 1),1);
        setAmount(preselected*100);
        setIsDefaultValue(def);
    }

    useEffect(() => {
        initButtons();
        // if (amount_can_deposit === null) {
            oktoGetCustomer();
        // } else {
        //     if (!isFirstTime) {
        //         createPaymentCode(amount,bonusId);
        //     }
        // }
        return () => {
            oktoReset();
        }
    }, []);

    useEffect(() => {
        setAmount(props.amount);
        initButtons(props.amount);
    }, [props.amount]);


    useEffect(() => {

        if (amount_can_deposit !== null) {
            if (!isFirstTime) {
                if (isDefaultValue) {
                    onConfirmHandler();
                }
            }
        }
    },[amount_can_deposit]);

    useEffect(() => {
        if (!loading && paymentCode) {
            responseReceived();
        }
    },[paymentCode]);

    const responseReceived = () => {
        setShowResult(true);
    }

    const toggleCheckbox = (e) => {
        let state = { ...checks };
        state[e.target.value] = {
            checked: e.target.checked,
        };
        setChecks(state);
    }


    const onConfirmHandler = () => {
        createPaymentCode(amount,bonusId > 0 ? bonusId: null);
    }

    const onChangeHandler = (e) => {
        let field;
        let time = 1500;
        if (typeof e.target === "undefined") {
            field = e;
            time = 100;
        } else {
            field = e.target.value;
        }

        field = parseInt(field);

        if (isNaN(field)) {
            field = 0;
        }

        if (maximumLimit !== false && maximumLimit < field) {
            field = maximumLimit;
        }

        if(field > 0) {
            Utils.delayedFunction("calculateTaxService",() => onRequestTaxService(2, field, 1),time);
        }

        setAmount(field*100);
    }

    if (window?.config?.okto_enabled !== '1' ) {
        return (<div className={"content register-disabled"} >
            {props.t("Deposit is temporarily unavailable")}
        </div>)
    } else if (loading) {
        return (<div className={"content "} >
            <CircularProgress  className={'Loading'} />
        </div>)
    }

    let buttonDisabled = true;
    if((amount === 0)) {
        buttonDisabled = true;
    } else if (isFirstTime) {
        if (checks.terms.checked && checks.privacy.checked) {
            buttonDisabled = false;
        }
    } else {
        buttonDisabled = false;
    }

    let termsUrl = 'https://oktocash.eu/service-terms-of-use/';
    let privacyUrl = 'https://oktocash.eu/privacy-policy/';
    if (props.language === "ro") {
        termsUrl = 'https://oktocash.eu/ro/termenii-de-utilizare-a-serviciului/';
        privacyUrl = 'https://oktocash.eu/ro/politica-de-confidentialitate/';
    }

   return (
        <>

            {showResult &&
                <Grid item xs={12} key={'okto-deposit-result'}>
                    <Grid container>

                        <Grid item xs={12} className={'result'}>
                            <div className="icon">
                                {Icons.get('pwSuccess','pwSuccess')}
                            </div>
                            <div className={'title'}>{t("Ready to deposit")}</div>
                            <div className={'subtitle'}>{t("Visit any OKTO.CASH payment station and use the code below to deposit with cash.")}</div>
                            <div className={"description"}>
                                {t('At the OKTO.CASH point, please select Winner from the menu, then introduce the payment code below. The funds will be delivered to your Winner account shortly after.')}
                            </div>
                            <div className={'code'}>{paymentCode}</div>
                        </Grid>
                        <Grid item xs={12} className={'user-details'}>
                            <Grid container>
                                <Grid item xs={6} className={'left'}>
                                    <div className={'value'}>{profile.first_name} {profile.last_name}</div>
                                </Grid>
                                <Grid item xs={6} className={'right'}>
                                    <div className={'value'}>{profile.nin}</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'nearest-locations'}>

                            <a target={'_blank'} href={'https://www.oktopayments.com/store-locator/oktocash-romania/'}>
                                {t("Find here the closest OKTO.CASH payment station")}
                                {Icons.get('newTab','newTab')}
                            </a>

                        </Grid>
                        <Grid item xs={12} className={'actions'}>
                                <NavLink to={"/"} className={'DepositButton'}>
                                    <Button fullWidth variant="contained"  size="large"  className={'active gradient btn-danger w-100 nextButton'}>
                                        {t("I have saved the code")}
                                    </Button>
                                </NavLink>

                        </Grid>

                    </Grid>
                </Grid>
            }



            {!showResult && (buttonsAreaType === 2 || buttonsAreaType === 1) && <>
                    <Grid container
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}
                          key={"okto-deposit-buttons-container"}
                    >
                        <Grid item xs={12} className={'DepositAmount'}>
                            <FormControl className="PlayerDataInput text-center">
                                <InputLabel htmlFor="amount">{t('Deposited amount')}</InputLabel>
                                <Input
                                    id="amount"
                                    type='text'
                                    disabled={true}
                                    value={(amount/100) + ' Lei'}
                                />
                            </FormControl>

                        </Grid>
                    </Grid>
                <Grid item xs={12} className={'accepted-values'} key={"okto-deposit-title"}>
                    {t("Amounts available via OKTO.CASH:")}
                </Grid>
                    <Grid container
                                                               alignItems="center"
                                                               justifyContent="center"
                                                               className={'buttons  small-buttons'}
                                                               spacing={2}
                                                               key={"okto-deposit-buttons"}>




                    {buttons && buttons.map(val => (
                        <Grid item xs={4} key={"deposit_buttons_okto" + val}>
                            <Button
                                className={"depositButton " + (parseInt(amount/100,10) === parseInt(val,10) ? "active" : "")}
                                disabled={(dailyLimit < val && dailyLimit !== null) || minimumLimit > val || maximumLimit < val}
                                onClick={() => onChangeHandler(val)}
                            >
                                <div>{val}</div>
                                {val === bonusMin && (
                                    <div className={'desc'}>MIN BONUS</div>
                                )}
                                {val === bonusMax && bonusMin !== bonusMax && (
                                    <div className={'desc'}>MAX BONUS</div>
                                )}
                            </Button>
                        </Grid>))}



                </Grid>
                <Grid item xs={12} className={'taxes'}>
                    <Grid container>
                        <Grid item xs={6} className={'left'}>
                            <p>{Utils.formatAmount(taxes,0,'RON')}</p>
                            <span>{props.t("CDA 2% (GEO 114)")}</span>
                        </Grid>
                        <Grid item xs={6} className={'right'}>
                            <p>{Utils.formatAmount(amount/100 - taxes,0,'RON')}</p>
                            <span>{props.t("Net deposit")}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </>}

            {!showResult && isFirstTime && <>
                <Grid item xs={12} className={'actions'} key={'okto-actions-button'}>
                    <Grid container>
                        <FormControl className={'field terms'}>
                            <FormControlLabel
                                control={<Checkbox checked={checks.terms.checked} onChange={(e) => toggleCheckbox(e)} value="terms" />}
                                label={<div>
                                    <div className="checkbox-title">
                                        {t("Terms & Conditions")}
                                    </div>
                                    <div className="checkbox-subtitle">
                                        <Translate text={"I have read, understood and accept the OKTO.CASH Service ${startUrl}Terms of Use${endUrl} and I agree to the transfer of my data to Oktopay Ltd and Sureswipe EMI PLC."}
                                            placeholder={
                                                {
                                                    startUrl: `<a target="_blank" href="${termsUrl}">`,
                                                    endUrl: '</a>'
                                                }
                                            }
                                        />

                                    </div>
                                </div>}
                                className={checks.terms.checked ? "no-error" : "error"}
                            />
                            {(!checks.terms.checked && checks.terms.message !== '') && <FormHelperText error className={'CheckBoxError'}>{checks.terms.message}</FormHelperText>}
                        </FormControl>
                        <FormControl className={'field privacy'}>
                            <FormControlLabel
                                control={<Checkbox checked={checks.terms.privacy} onChange={(e) => toggleCheckbox(e)} value="privacy" />}
                                label={<div>
                                    <div className="checkbox-title">
                                        {t("Privacy policy")}
                                    </div>
                                    <div className="checkbox-subtitle">
                                        <Translate text={"I have read, understood, and accept the ${startUrl}OKTO Privacy Policy${endUrl}"}
                                                placeholder={
                                                    {
                                                        startUrl: `<a target="_blank" href="${privacyUrl}">`,
                                                        endUrl: '</a>'
                                                    }
                                                }
                                        />
                                    </div>
                                </div>}
                                className={checks.privacy.checked ? "no-error" : "error"}
                            />
                            {(!checks.privacy.checked && checks.privacy.message !== '') && <FormHelperText error className={'CheckBoxError'}>{checks.privacy.message}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

            </>}
            {!showResult && <Grid item xs={12} className={'actions'}>
                <Grid container>
                    <Button fullWidth variant="contained" disabled={buttonDisabled} size="large"  className={'active gradient btn-danger w-100 nextButton'} onClick={ onConfirmHandler}>
                        Confirm
                    </Button>
                </Grid>
            </Grid>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        responsePayload: state.paymentCheckout.okto,
        loading: state.paymentCheckout.loading,
        language: state.application.language,
        profile: state.profile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createPaymentCode: (amount,bonusId) => dispatch(storeActions.oktoCreatePaymentCode(amount,bonusId)),
        oktoGetCustomer: () => dispatch(storeActions.oktoGetCustomer()),
        oktoReset: () => dispatch(storeActions.oktoReset()),
        onRequestTaxService: (taxType, amount, id) => dispatch(storeActions.requestTaxService(taxType, amount, id)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Okto)));