import * as actionTypes from './actionTypes';

export const layoutUpdateNotch = notch => ({
    type: actionTypes.layout.UPDATE_NOTCH,
    notch
});

export const layoutUpdateOrientation = orientation => ({
    type: actionTypes.layout.UPDATE_ORIENTATION,
    orientation
});
