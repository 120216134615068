import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import { withRouter } from "react-router";
import TitleBar from '../UI/TitleBar/TitleBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Deposit.scss';
import * as storeActions from "../../store/actions";

const DepositFinal = (props) => {
    const [amount, setAmount] = useState(0);
    const [depositData,setDepositData] = useState([]);
    const [bonusId,setBonusId] = useState(-1);



    useEffect(() => {
        let templayerData = window.localStorage.getItem('deposit');
        window.localStorage.removeItem('deposit');
        try {
            templayerData = JSON.parse(templayerData);
            setDepositData(templayerData.data);
            setBonusId(templayerData.bonusId);
            setAmount(templayerData.amount);
        } catch (e) {
            // props.history.push('/');
        }
    }, []);

    useEffect(() => {
        if (props.profile.client_player_id !== null && amount) {
            props.sendDeposit(amount, depositData, bonusId);
        }
    }, [props.profile.client_player_id, amount, depositData, bonusId]);
    useEffect(() => {
        if (props.paymentCheckout.serverResponse === 'success') {
            props.getWallet();
            props.history.push('/payment/success?currency=RON&totalAmount=' + (amount/100))
        }
    }, [props.paymentCheckout.serverResponse]);

    return (<div className={"DepositOnlineScreen-final"}>
        <TitleBar pageTitle={props.t('Deposit')}/>
        {props.paymentCheckout.serverResponse
            ? (<div className="ReturnMessage"><p>{props.paymentCheckout.serverResponse}</p></div>)
            : <CircularProgress className={'Loading'}/>
        }
        {props.paymentCheckout.serverResponse && <button className={'nextSCButton'} onClick={() => props.history.push('/deposit')}>
            {props.t("Retry")}
        </button>}
    </div>)
}
const mapStateToProps = state => {
    return {
        profile: state.profile,
        paymentCheckout: state.paymentCheckout,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        sendDeposit: (amount,data,bonusId) => dispatch(storeActions.sendVivaChargeToken(data,amount,bonusId)),
        getWallet: () => dispatch(storeActions.requestWallet())
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositFinal)));