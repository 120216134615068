import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import BackgroundImage from "./../../../assets/Images/Jackpot/img-bg-jackpot-header-front@3x.png";
import SimpleCounter from "../NewCounter";
import "./HeaderFront.scss";
import Utils from "../../../utils/Utils";

class HeaderFront extends Component {
  render() {
    let maxValue = <>&nbsp;</>;
    let heartsValue = <>&nbsp;</>;
    if (this.props.jackpot.maxValue) {
      maxValue = (
        <>
          <SimpleCounter
            theme="simple-counter"
            digits={5}
            value={this.props.jackpot.maxValue / 100}
            storageKey={`EGT_0`}
          />
        </>
      );
    }
    if (this.props.jackpot.levelsValues[1]) {
      heartsValue = (
        <>
          <SimpleCounter
            theme="simple-counter"
            digits={5}
            value={this.props.jackpot.levelsValues[1] / 100}
            storageKey={`EGT_1`}
          />
        </>
      );
    }
    return (
      <div className="jackpot-header-front">
        <div
          className="wrapper"
          style={{ backgroundImage: `url(${Utils.getImageUrl(BackgroundImage)})` }}
        >
          <div className="title">{this.props.name} JACKPOT</div>
          <div className="counter">{maxValue}</div>
        </div>
        <div
          className="wrapper"
          style={{ backgroundImage: `url(${Utils.getImageUrl(BackgroundImage)})` }}
        >
          <div className="title">{this.props.name} HEARTS</div>
          <div className="counter">{heartsValue}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    jackpot: state.jackpot
  };
};

HeaderFront.propTypes = {
  jackpot: PropTypes.object,
  name: PropTypes.string
};

HeaderFront.defaultProps = {
  name: ""
};

export default connect(mapStateToProps)(HeaderFront);
