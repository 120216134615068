import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IframeResizer from "iframe-resizer-react";
import Div100vh from "react-div-100vh";

import "./GamePageMob.scss";
import { getSportsbookToken } from "../../store/actions/authentication";
import Games from "./games";
import TitleBar from "../../components/UI/TitleBar/TitleBar";

function innerHeight() {
  //if (!navigator.userAgent.match(/iphone|ipod|ipad/i)) {
  //  return window.screen.availHeight;
  //}

  return window.innerHeight;
}

class GamePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sbToken: null,
      oldHeight: 0,
      innerHeight: 0
    };
    this.iframeRef = React.createRef();
    this.rulerRef = React.createRef();
    this.intervalID = 0;
  }

  hideLoader = () => {
    setTimeout(() => {
      //this.props.contentLoaded();
    }, 2000);
  };

  backHandler = () => {
    this.props.history.push("/virtuale");
  };

  doAuth = () => {
    const auth = this.props.authentication;

    if (
      auth.access_token === null ||
      !(auth && ["user", "token"].indexOf(auth.auth_type) > -1)
    ) {
      this.setState(s => ({
        ...s,
        sbToken: {
          tempToken: null,
          id: null,
        }
      }));

      return;
    }

    if (auth && ["user", "token"].indexOf(auth.auth_type) > -1) {
      getSportsbookToken(auth, data =>
        this.setState(s => ({
          ...s,
          sbToken: data
        }))
      );
    }
  };

  componentDidMount() {
    this.doAuth();

    const calculate = () => {
      const height = innerHeight();

      this.setState(s => ({
        ...s,
        oldHeight: s.innerHeight !== height ? s.innerHeight : s.oldHeight,
        innerHeight: height
      }));
    };

    this.intervalID = setInterval(() => {
      calculate();
    }, 500);
    calculate();

    //window.addEventListener('message', this.handleIframeMessage);
  }

  componentDidUpdate(prevProps) {
    //console.log("props changed", prevProps, this.props);

    if (
      this.props.authentication.auth_type !== prevProps.authentication.auth_type ||
      this.props.application.language !== prevProps.application.language
    ) {
      this.doAuth();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    //window.removeEventListener('message', this.handleIframeMessage);
  }

  onResize = data => {
    //console.log("onResize", data);
  };

  /*
  handleIframeMessage = e => {
    console.log("iframe message", e);
  }
  */

  onMessage = data => {
    //console.log("onMessage", data);
    if (data.message && data.message.type) {
      switch (data.message.type) {
        case "contentLoaded": {
          this.hideLoader();
          break;
        }
        case "loginRequired": {
          localStorage.setItem("redirect_after_login", this.props.location.pathname);
          this.props.history.push("/connect");
          break;
        }
        default: {
          /*noop*/
        }
      }
    }
  };

  render() {
    const { sbToken, oldHeight, innerHeight } = this.state;

    if (sbToken === null) return null;

    const { match } = this.props;
    const { id } = match.params;

    const game = Games.find(game => game.id === id);

    let iframeURL = `${game.iframeURL}?auth=b2b&logout=true&lang=${this.props.application.language}`;

    if (sbToken.tempToken !== null) {
      iframeURL = `${game.iframeURL}?auth=b2b&token=${sbToken.tempToken}&id=${sbToken.id}&authStrategy=tempToken&lang=${this.props.application.language}`;
    }

    let offset = 112;
    //if (navigator.userAgent.match(/iphone|ipod|ipad/i)) {
    //  offset = 112;
    //if (innerHeight > oldHeight) {
    //  offset = 112 + 15;
    //}
    //}

    const heightL = innerHeight - offset;
    //console.log(innerHeight, " - ", offset, " = ", heightL);

    setTimeout(() => {
      const elem = document.querySelector(`.-iframe.-id-${id}`);
      if (elem) {
        elem.style.minHeight = `${heightL}px`;
        elem.style.height = `${heightL}px`;
      }
    }, 0);

    //const style = {
    //  height: `${heightL}px`
    //};

    if (innerHeight < oldHeight) {
      window.setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    }

    return (
      <Div100vh>
        <div className={this.props.application.topMenuHeight}></div>
        <TitleBar
          pageTitle={game.name}
          backHandler={this.backHandler}
          closeHandler={this.backHandler}
        />
        <div className="virtuals-game-cs">
          {/*
          <iframe
            className={`-iframe -id-${id}`}
            src={iframeURL}
            onLoad={this.hideLoader}
            frameBorder="0"
            scrolling="no"
            title={game.name}
            style={style}
          ></iframe>
          */}
          <IframeResizer
            autoResize={false}
            checkOrigin={false}
            forwardRef={this.iframeRef}
            //heightCalculationMethod="grow"
            //inPageLinks
            //log
            onMessage={this.onMessage}
            onResized={this.onResize}
            src={iframeURL}
            className={`-iframe -id-${id}`}
            frameBorder="0"
            title={game.name}
            minHeight={heightL}
          />
        </div>
      </Div100vh>
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    application: state.application
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //contentLoaded: () => dispatch(stateActions.hideContentLoader()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GamePage));
