import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { withTranslation } from "react-i18next";
import TitleBar from "../../../UI/TitleBar/TitleBar";
import Icons from '../../../../assets/Icons/Icons';
import './ProfilePreferences.scss';

const ProfilePreferences = (props) => {

    return (
        <div className={"ProfilePreferencesScreen"}>
            <TitleBar pageTitle={props.t('Preferences')} />
            <div className="ProfilePreferencesContent">
                <div className={"ContainerBox"}>
                    <ButtonBase className={"ContainerItem"}>
                        <div>
                            <p>{props.t('Auto-accept odds changes')}</p>
                            <span>{props.t('None, only higher, any change')}</span>
                        </div>
                        <div>
                            {Icons.get('profileMenuArrow', 'RightArrow')}
                        </div>
                    </ButtonBase>
                </div>
                <div className={"ContainerBox"}>
                    <ButtonBase className={"ContainerItem"}>
                        <div>
                            <p>{props.t('Cookies')}</p>
                            <span>{props.t('Enable all')}</span>
                        </div>
                        <div>
                            {Icons.get('profileMenuArrow', 'RightArrow')}
                        </div>
                    </ButtonBase>
                    <ButtonBase className={"ContainerItem"}>
                        <div>
                            <p>{props.t('Receive news and promotions')}</p>
                            <span>{props.t('Enable all')}</span>
                        </div>
                        <div>
                            {Icons.get('profileMenuArrow', 'RightArrow')}
                        </div>
                    </ButtonBase>
                    <ButtonBase className={"ContainerItem"}>
                        <div>
                            <p>{props.t('Personalized bonus and offers')}</p>
                            <span>{props.t('Enabled')}</span>
                        </div>
                        <div>
                            {Icons.get('profileMenuArrow', 'RightArrow')}
                        </div>
                    </ButtonBase>
                    <ButtonBase className={"ContainerItem"}>
                        <div>
                            <p>{props.t('Contact channels')}</p>
                            <span>{props.t('E-mail, Phone, SMS, Push Notification')}</span>
                        </div>
                        <div>
                            {Icons.get('profileMenuArrow', 'RightArrow')}
                        </div>
                    </ButtonBase>
                </div>
                <div className={"ContainerBox"}>
                    <ButtonBase className={"ContainerItem"}>
                        <div>
                            <p>{props.t('GDPR My personal data')}</p>
                            <span>{props.t('Enable all')}</span>
                        </div>
                        <div>
                            {Icons.get('profileMenuArrow', 'RightArrow')}
                        </div>
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(ProfilePreferences);