import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import BackgroundImageT1 from "./assets/t1-spades-homepage@3x.png";
import BackgroundImageT2 from "./assets/t4-clubs-homepage@3x.png";
import SimpleCounter from "../NewCounter";
import "./HeaderFrontVariant7.scss";
import Utils from "../../../utils/Utils";

class HeaderFront extends Component {
  render() {
    let maxValue = <>&nbsp;</>;
    let minValue = <>&nbsp;</>;
    if (this.props.jackpot.maxValue) {
      maxValue = (
        <>
          <SimpleCounter
            theme="simple-counter"
            digits={5}
            value={this.props.jackpot.maxValue / 100}
            storageKey={`EGT_0`}
          />
        </>
      );
    }
    if (this.props.jackpot.minValue) {
      minValue = (
        <>
          <SimpleCounter
            theme="simple-counter"
            digits={5}
            value={this.props.jackpot.minValue / 100}
            storageKey={`EGT_3`}
          />
        </>
      );
    }
    return (
      <div className="jackpot-header-front-variant-7">
        <div className="wrapper">
          <img src={Utils.getImageUrl(BackgroundImageT1)} alt="" />
          <div className="counter top">{maxValue}</div>
        </div>
        <div className="wrapper">
          <img src={Utils.getImageUrl(BackgroundImageT2)} alt="" />
          <div className="counter bottom">{minValue}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    jackpot: state.jackpot
  };
};

HeaderFront.propTypes = {
  jackpot: PropTypes.object,
  name: PropTypes.string
};

HeaderFront.defaultProps = {
  name: ""
};

export default connect(mapStateToProps)(HeaderFront);
