import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardActionArea, CardContent, CircularProgress } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";

import { showMaintenanceModal } from "../../../../maintenance/store/actions/config";
import DinamicImage from "../../../UI/DinamicImage/DinamicImage";
import './Game.scss';
import Utils from "../../../../utils/Utils";
import { itemThumbnail } from "../../../../lobby/utils/functions";

const styles = theme => ({
    card: {
        padding: theme.spacing(0),
        position: "relative",
    },
    cardContent: {
        position: 'absolute',
        bottom: 0,
        padding: 0,
    },
    progressColorPrimary: {
        color: '#ffb400',
    },
    name: {
        position: "absolute",
        fontSize: "12px",
        fontWeight: "600",
        color: "#fff",
        bottom: "0",
        left: "0",
        right: "0",
        padding: "10px 5px 5px",
        textAlign: "left",
        background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,.8) 100%)"
    },
    greyedOut: {
        filter: "grayscale(100%)"
    }
});

const clone = (data) => {
    return JSON.parse(JSON.stringify(data));
};

const Game = (props) => {
    const classes = props.classes;
    const cardRef = useRef();

    let gameItem = props.item;
    const [gameImage, setImage] = useState(gameItem.name);

    useEffect(() => {
        setImage(gameItem.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameItem]);
    let gameId = typeof gameItem.game_id !== "undefined" ? gameItem.game_id : gameItem.id;

    const clickHandler = (event) => {
        if ((gameItem.type === "virtual" && typeof props.virtualsMaintenance[gameItem.id] !== "undefined") || typeof props.slotsMaintenance[gameItem.id] !== "undefined" || typeof props.providersMaintenance[gameItem.provider] !== "undefined") {
            if (gameItem.type === "virtual" && typeof props.virtualsMaintenance[gameItem.id] !== "undefined") {
                const data = clone(props.virtualsMaintenance[gameItem.id]);
                data.image = gameItem.img;
                data.name = gameItem.name;
                props.showMaintenanceModal(data);
            } else if (typeof props.slotsMaintenance[gameItem.id] !== "undefined") {
                props.showMaintenanceModal(clone(props.slotsMaintenance[gameItem.id]));
            } else {
                const data = clone(props.providersMaintenance[gameItem.provider]);
                data.image = gameItem.name;
                props.showMaintenanceModal(data);
            }
            return;
        }

        if (gameId) {
            props.history.push('/play/' + gameId);
        }

        props.launchHandler(gameItem, cardRef);

    };

    if (gameId === parseInt(window.location.hash.substr(1))) {
        Utils.delayedFunction('launchfromurl', clickHandler, 300);
    }

    let inMaintenance = false;
    if ((gameItem.type === "virtual" && typeof props.virtualsMaintenance[gameItem.id] !== "undefined") || typeof props.slotsMaintenance[gameItem.id] !== "undefined" || typeof props.providersMaintenance[gameItem.provider] !== "undefined") {
        inMaintenance = true;
    }

    /* PO-5429 */
    if (gameItem.provider && gameItem.name) {
        const img = itemThumbnail({ providerId: gameItem.provider, name: gameItem.name, type: "slot_game" });
        if (img) {
            gameItem.img = img;
        }
    }

    return (
        <Card className={'GameCard'} ref={cardRef}>
            <CardActionArea className={`${classes.card} ${inMaintenance ? classes.greyedOut : ''}`} onClick={clickHandler}>
                <DinamicImage
                    folder={"games"}
                    size={"small"}
                    imgName={gameImage !== null ? gameImage.replace(/\s/g, "-").toLowerCase() + ".jpg" : "game-placeholder.jpg"}
                    imgLink={gameItem.img ? gameItem.img : null}
                    title={gameImage !== null ? gameImage + ' (' + gameImage.replace(/\s/g, "-").toLowerCase() + ".jpg" + ')' : "slot games"}
                    className={'CardImage'}
                />
                {gameItem.showName && <div className={classes.name}>{gameItem.name}</div>}
            </CardActionArea>
        </Card>
    );
};

Game.propTypes = {
    item: PropTypes.object.isRequired,
    launchHandler: PropTypes.func.isRequired,
    itemType: PropTypes.string,
};

const mapStateToProps = state => {
    const maintenance = state.maintenance && state.maintenance.config && state.maintenance.config ? state.maintenance.config : null;

    return {
        slotsMaintenance: maintenance && maintenance.slots ? maintenance.slots : {},
        virtualsMaintenance: maintenance && maintenance.virtuals ? maintenance.virtuals : {},
        providersMaintenance: maintenance && maintenance.providers ? maintenance.providers : {},
    };
};

const mapActions = {
    showMaintenanceModal
};

export default connect(mapStateToProps, mapActions)(withStyles(styles)(withRouter(Game)));
