import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from "../../ClientAPI/ClientAPI";
import * as paths from "../../ClientAPI/paths";
import Utils from "../../utils/Utils";
import RequestManager from "../../utils/RequestManager";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";

export function* routeListenerSaga(action) {

    let authType = yield localStorage.getItem('auth_type');
    if (authType === 'user') {
        let authDate = yield localStorage.getItem('auth_date');
        let expiresIn = localStorage.getItem('expires_in');
        if (!expiresIn) {
            localStorage.setItem("redirect_after_login", action.pathname);
            yield put(actions.logout());
            window.location.pathname = '/connect';
        } else {
            let actualDate = yield Date.now();
            let timePassed = actualDate - authDate;
            if (timePassed > expiresIn * 1000) {
                localStorage.setItem("redirect_after_login", action.pathname);
                yield put(actions.logout());
                window.location.pathname = '/connect';
            }
        }
    }

    let pathname = action.pathname;
    let routePath = pathname.split("/");
    if (!(routePath.length && routePath.length > 1)) {
        return;
    }

    let pageType = 'root-level';
    let pageCode = routePath[1];
    if (routePath.length > 2) {
        pageCode = routePath[2];
        pageType = routePath[1];
    }

    if ('conectare' === pageCode) {
        pageType = 'authentication';
        pageCode = 'login';
    }

    if ("" === pageCode) {
        pageType = "root-level";
        pageCode = "home";
    }

    if ("cazino" === pageCode) {
        pageType = "cazino";
        pageCode = "jocuri";
    }

    let pageClassName = 'lighten';
    if ("home" === pageCode) {
        pageClassName = 'darken';
    }
    if ("jocuri" === pageCode) {
        pageClassName = 'white';
    }

    yield put(actions.setPageType(pageType));
    yield put(actions.setPageCode(pageCode));
    yield put(actions.setPageClassName(pageClassName));
}

export function* changeLanguageSaga(action) {

    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.profile.CHANGE_LANGUAGE,
            method: 'post',
            data: {
                iso: action.language,
            }
        });

        if (!response.status) {
            throw new Error(`[ERROR] CHANGE LANGUAGE missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] CHANGE LANGUAGE message not sent!`);
        }

    } catch (error) {
        return;
    }
}

export function* getPromotionsSaga(action) {
    const axios = yield Utils.getAxiosWithToken();

    try {
        const response = yield axios({
            url: paths.promotions.GET,
        });

        if (!response.status) {
            throw new Error(`[ERROR] GET_PROMOTIONS missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] GET_PROMOTIONS message not sent!`);
        }


        yield put(actions.setPromotions(response.result));

    } catch (error) {
        return;
    }
}

export function* getPreEuroSaga(action) {
    const axios = yield Utils.getAxiosWithToken();

    try {
        let today = new Date();
        today.setHours(0, 0, 0);

        // We start the week with Monday, we assign 7 when its a Sunday (0)
        let dayToday = today.getDay() || 7;

        // Calculate milliseconds elapsed since Monday
        let msSinceMonday = ((dayToday - 1) * 86400000);

        // Subtract today midnight with the diff to get midnight of the Monday
        let mondayTime = today.getTime() - msSinceMonday;

        const response = yield axios({
            url: process.env.CFW.casinoApiUrl + '/player/happy-hours-awards/list',
            method: 'post',
            data: {
                startTime: parseInt(mondayTime / 1000).toString(),
                endTime: parseInt(new Date().getTime() / 1000).toString()
            }
        });

        if (!response.success) {
            throw new Error(`[ERROR] HAPPY HOURS LIST missing status!`);
        }

        let tickets = 0;
        response.rows.map(function (value, index) {
            if (value.prize_category == '2') {
                tickets += value.special_amount;
            }
        })

        yield put(actions.setPreEuro(tickets));

    } catch (error) {
        return;
    }
}

function* callback_getEligibleBonuses(response,id) {
    try {
        if (!response.status) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES message not sent!`);
        }
        if (!response.status) {
            throw new Error(`[ERROR] GET_ELIGIBLE_BONUSES missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] GET_ELIGIBLE_BONUSES message not sent!`);
        }

        yield put(actions.setEligibleBonuses(response.result));
    } catch (error) {
        yield put(actions.requestFailed(id))
    }

}

export function* getEligibleBonusesSaga(action) {

    let paymentMethods = [];

    if (action.paymentMethod) {
        paymentMethods.push(action.paymentMethod);
    } else {
        paymentMethods.push(2);//safecharge;

        if (window.config && window.config.vivaEnabled) {
            paymentMethods.push(3); // viva
        }

        if (window.config && window.config.okto_enabled === '1') {
            paymentMethods.push(4); // okto
        }

        if (window.config && window.config.skrill_paysafe_enabled === '1') {
            paymentMethods.push(5); // skrill paysafe
        }

        if (window.config && window.config.aircash_enabled === '1') {
            paymentMethods.push(6); // aircash
        }
    }
    try {
        const reqid = 'get-eligible-bonuses'  + paymentMethods.join('-');
        yield RequestManager.request(reqid,{
            url: paths.eligible_bonuses.GET,
            method: 'post',
            data: {
                platform: window.config.platformType,
                paymentMethodList: paymentMethods
            }
        },callback_getEligibleBonuses);

    } catch (error) {
        return;
    }
}

function* callback_getBonusAvailable(response, id) {
    try {
        if (!response.status) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES message not sent!`);
        }

        yield put(actions.setAvailableBonuses(response.result));

    } catch (error) {
        yield put(actions.requestFailed(id))
    }

}

export function* getBonusAvailableSaga(action) {
    // const axios = yield Utils.getAxiosWithToken();

    try {
        const response = yield RequestManager.request("get-bonus-available", {
            url: paths.bonus_available.GET,
            method: 'post',
            data: {
                platform: window.config.platformType
            }
        }, callback_getBonusAvailable);

    } catch (error) {
        return;
    }
}
function* callback_getBonuses(response, id) {
    try {
        if (!response.status) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES message not sent!`);
        }

        yield put(actions.setBonuses(response.result));

    } catch (error) {
        yield put(actions.requestFailed(id))
    }

}
export function* getBonusesSaga(action) {
    const state = ClientAPI.getStore().getState();
    if ((["user", "token"].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null)) {
        return;
    }
    // const axios = yield Utils.getAxiosWithToken();
    try {
        const platformType = window.config.cordova ? window.config.native_platform === "ios" ? 3 : 4 : window.config.platformType;
        let data = action.data;
        data.platform = platformType;

        const response = yield RequestManager.request("get-bonuses", {
            url: paths.bonuses.GET,
            method: 'post',
            data: data
        }, callback_getBonuses);
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* claimBonusSaga(action) {
    const axios = yield Utils.getAxiosWithToken();

    try {
        let data = {
            bonusAvailableId: action.bonusId
        }
        if (action.force) {
            data.force = 1;
        }

        let pac = PlayerAbuseChecker.getInfo();
        data = {
            ...pac,
            ...data
        }

        const response = yield axios({
            url: paths.bonus_available.CLAIM,
            method: 'post',
            data: data
        });

        if (!response.status) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES missing status!`);
        }

        if (!("OK" === response.status)) {
            throw new Error(`[ERROR] GET_AVAILABLE_BONUSES message not sent!`);
        }

        if ((typeof response.result.ResponseCode !== 'undefined' && response.result.ResponseCode !== 6) || typeof response.result.http !== 'undefined') {

            if (typeof response.result.detail === "undefined" || response.result.detail === null) {throw new Error(`[ERROR] GET_AVAILABLE_BONUSES server error`)
}
        }

        yield put(actions.bonusClaimed(response.result));

    } catch (error) {

        yield put(actions.bonusClaimed({ResponseCode: 1}));

        return;
    }
}


export function* getBonusCalculatorSaga(action) {
    const axios = yield Utils.getAxiosWithToken();

    try {
        let data = {
            bonusAvailableId: action.bonusId,
            currency: 'RON',
            depositAmount: action.depositAmount * 100,
        };

        const response = yield axios({
            url: paths.bonuses.GET_BONUS_CALCULATOR,
            method: 'post',
            data: data,
        });

        if (!response.status) {
            throw new Error('[ERROR] GET_AVAILABLE_BONUSES missing status!');
        }

        if (!('OK' === response.status)) {
            throw new Error('[ERROR] GET_AVAILABLE_BONUSES message not sent!');
        }

        yield put(actions.setAwardBonus(action.bonusId, response.result));
    } catch (error) {
        return;
    }
}