import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import moment from "moment";

import PromoIcon from "../assets/promotions-icon.svg";
import WheelIcon from "../../../wheel/assets/wheel-icon.png";
import { ReactComponent as WinterIcon } from "../../../promo/assets/icon-calendar.svg";
import { getBonusAvailable } from "../../../common/claim-bonus-exports";

const useStyles = makeStyles({
  root: {
    height: "42px",
    width: "50px",
    backgroundColor: "rgba(0,0,0,0.4)",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    margin: "0 0 0 .25rem",
    justifyContent: "center",
  },
  promotions: {
    color: "#fff",
    marginLeft: "3px",
    width: "38px",
    "& img": {
      left: "0!important",
      width: "19px!important",
    },
    "& img.wheel": {
      left: "0!important",
      width: "21px!important",
    }
  },
  anchorOriginBottomLeftRectangular: {
    transform: "scale(1) translate(-50%, 0)",
    left: "0",
    bottom: "0",
    transformOrigin: "0% 100%"
  },
  badge: {
    transform: "scale(.75) translate(50%, -50%)",
    /*
    height: "15px",
    display: "flex",
    padding: "0 3px",
    zIndex: "1",
    position: "absolute",
    flexWrap: "wrap",
    fontSize: "0.65rem",
    minWidth: "15px",
    boxSizing: "border-box",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    fontWeight: "500",
    lineHeight: "1",
    alignContent: "center",
    borderRadius: "10px",
    flexDirection: "row",
    justifyContent: "center",
    bottom: "-3px",
    */
  },
  notAvailable: {
    backgroundColor: "#575757",
  },
  isMobile: {
    backgroundColor: "transparent",
    height: "46px",
    width: "40px",
    "&>button": {
      paddingBottom: "1px!important",
    }
  },
  dNone: {
    position: "absolute",
    overflow: "hidden",
    width: "0",
    height: "0",
    opacity: "0",
    fontFamily: "Oswald"
  },
  margin: {
    "& svg": {
      width: "30px",
      height: "30px",
      position: "relative",
      top: "-3px",
    }
  }
});

const PromotionsButton = (props) => {
  const classes = useStyles();
  const { mobile, t, availableBonuses, wheelStatus, winterStatus } = props;

  React.useEffect(() => {
    props.getBonuses();
  }, []); // eslint-disable-line

  const navigateToPromo = () => {
    props.history.push("/cazino/promotii");
  };

  let countAvailable = 0;
  let countNotAvailable = 0;

  availableBonuses && Object.keys(availableBonuses).forEach((bid) => {
    const bonus = availableBonuses[bid];

    if (bonus && bonus.bonus_conflicts && Object.keys(bonus.bonus_conflicts).length) {
      countNotAvailable += 1;
    } else {
      countAvailable += 1;
    }
  });

  const [mounted, setMounted] = React.useState(false);

  const nodeRef = React.useCallback(node => {
    if (node !== null) {
      setMounted(true);
    }
  }, []);

  React.useEffect(() => {
    if (mounted) {
      const day = moment().format("D");
      let el = document.querySelector(".menu-promo-icon .winter-calendar-day");
      if (el) {
        el.innerHTML = day;
      }
    }
  }, [mounted]);

  const checkUnlockState = () => {
    if (!(window.config && window.config.promoEnabled === "1")) {
      return false;
    }

    let unlock = true;

    const day = moment().format("D");
    if (parseInt(day, 10) > 25) return false;

    if (winterStatus) {

      const prizes = {};

      if (winterStatus && winterStatus.winter_prizes) {
        Object.keys(winterStatus.winter_prizes).forEach(id => {
          prizes[winterStatus.winter_prizes[id].day] = false;
        });
      }

      if (winterStatus && winterStatus.winter_status && winterStatus.winter_status.prizes) {
        winterStatus.winter_status.prizes.forEach(id => {
          if (winterStatus && winterStatus.winter_prizes && typeof winterStatus.winter_prizes[id] !== "undefined") {
            if (winterStatus.winter_prizes[id].day) {
              prizes[winterStatus.winter_prizes[id].day] = true; // prize claimed on this day
            }
          }
        });
      }


      if (prizes[day.toString()]) { // already unlocked
        unlock = false;
      }
    }

    return unlock;
  };
  let canUnlock = checkUnlockState();

  const hasWheelAvailable = wheelStatus && (wheelStatus.free_spin?.status === "available" || wheelStatus.keys_spin?.status === "available");
  if (hasWheelAvailable) countAvailable += 1;

  const icon = hasWheelAvailable ? WheelIcon : PromoIcon;

  if (hasWheelAvailable) {
    canUnlock = false;
  }

  if (canUnlock && !hasWheelAvailable) {
    countAvailable = 0;
    countNotAvailable = 0;
  }

  return (<div className={`${classes.root} ${mobile ? classes.isMobile : ''}`} ref={nodeRef}>
    <ButtonBase className={classes.promotions} onClick={navigateToPromo}>
      <div>
        <div className={`${classes.pb} menu-promo-icon`}>
          <Badge
            classes={{
              badge: `${classes.badge} ${countAvailable ? '' : countNotAvailable ? classes.notAvailable : ''}`,
              anchorOriginBottomLeftRectangular: classes.anchorOriginBottomLeftRectangular,
            }}
            color="secondary"
            badgeContent={countAvailable ? countAvailable : countNotAvailable}
            anchorOrigin={{
              horizontal: mobile ? "right" : "left",
              vertical: mobile ? "top" : "bottom"
            }}
            className={`${classes.margin}`}
            overlap="rectangular"
          >
            {canUnlock && <WinterIcon className={classes.block} />}
            {!canUnlock && <img src={icon} alt="" className={`${classes.block} ${hasWheelAvailable ? 'wheel' : ''}`} />}
          </Badge>
        </div>
        {mobile && <div>{t("Promo")}</div>}
      </div>
      <div className={classes.dNone}>T</div>
    </ButtonBase>
  </div>);
}

const mapStateToProps = state => ({
  availableBonuses: state.application.availableBonuses,
  wheelStatus: state.wheel && state.wheel.wheel && state.wheel.wheel.status ? state.wheel.wheel.status : null,
  winterStatus: state && state.promo && state.promo.winter && state.promo.winter.status ? state.promo.winter.status : null,
});
const actionCreators = {
  getBonuses: getBonusAvailable,
};
export default withRouter(withTranslation()(connect(mapStateToProps, actionCreators)(PromotionsButton)));