import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
    freeSpins: [],
    freeSpinsHistory: [],
    freeSpinsHistoryHasMore: true,
};

const freeBetsReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.freeSpins.FREE_SPINS_LOADED: {
                draft.freeSpins = action.freeSpins;
                break;
            }
            case actionTypes.freeSpins.FREE_SPINS_SET_HISTORY: {
                if (action.page === 0) {
                    draft.freeSpinsHistory = action.items;
                    draft.freeSpinsHistoryHasMore = action.items.length > 0 ? true : [];
                } else {
                    if (action.items.length) {
                        draft.freeSpinsHistory = [ ...action.items];
                        draft.freeSpinsHistoryHasMore = true;
                    } else {
                        draft.freeSpinsHistoryHasMore = false;
                    }
                }

                break;
            }
            default:
                break;
        }
    });

export default freeBetsReducer;
