import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as storeActions from "../../../../../store/actions/index";
import Button from '../../../../UI/Button/Button';
import './NewPassword.scss';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Secured from '@material-ui/icons/LockOpen';
import FormHelperText from '@material-ui/core/FormHelperText';
import PasswordStrength from '../../../../Register/Form/Password/PasswordStrength/PasswordStrength';
import PasswordValidator from "../../../../../utils/PasswordValidator";
import CircularProgress from '@material-ui/core/CircularProgress';

class NewPassword extends Component {

    state = {
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
        showPasswordStrength: false,
        passwordMatch: true, 
        ok: false,
        success: false
    }

    handleChange = prop => event => {
        this.setState({
            ...this.state, 
            [prop]: event.target.value,
        })

        if(prop === 'password'){
            let fieldInput;
            if (null === event) {
                fieldInput = this.props.password;
            } else {
                fieldInput = event.target.value;
            }
            let ok = this.state.ok;
            let valid = PasswordValidator.validate(fieldInput);

            if (valid['success']) {
                if (!ok) {
                    ok = true;
                }
            } else {
                if (ok) {
                    ok = false;
                }
            }

            this.setState({
                ...this.state,
                [prop]: event.target.value,
                ok: ok,
            });
        }
    };

    componentDidUpdate(prevProps){
        if (this.props.profile !== prevProps.profile) {
            if(!this.props.profile.loading){
                if(Array.isArray(this.props.profile.receivedChangePassword) && this.props.profile.receivedChangePassword.length === 0){
                    this.setState({
                        ...this.state,
                        success: true,
                    });
                }else if(this.props.profile.receivedChangePassword){
                    if(this.props.profile.receivedChangePassword.ResponseCode){
                        this.props.validate(false, this.props.profile.receivedChangePassword.ResponseCode);
                    }
                }
            }
        }
    }
    
    handleClickShowPassword = () => {
        this.setState({
            ...this.state, 
            showPassword: !this.state.showPassword,
        })
    };

    handleClickShowConfirmPassword = () => {
        this.setState({
            ...this.state, 
            showConfirmPassword: !this.state.showConfirmPassword
        })
    };

    focusHandler() {
        this.setState({
            ...this.state,
            showPasswordStrength: true,
        });
    }

    blurHandler() {
        if(this.state.password === ''){
            this.setState({
                ...this.state,
                showPasswordStrength: false,
            });
        }
    }

    handleSubmit() {
        if(this.state.password !== this.state.confirmPassword){
            this.setState({
                ...this.state, 
                passwordMatch: false,
            })
        }else{
            this.setState({
                ...this.state, 
                passwordMatch: true,
            })
            this.props.onChangePassword(this.props.currentPassword, this.state.password);
        }
    }
    
    render() {

        let passwordMatchError = '';
        let successMessage = '';

        if(!this.state.passwordMatch){
            passwordMatchError = <FormHelperText error={true}>{this.props.t("Passwords don't match")}</FormHelperText>;
        }

        if(this.state.success){
            successMessage = (
                <div className={'SuccessMessage'}>
                    <h2>{this.props.t('Password changed')}!</h2>
                </div>
            );

            setTimeout(() => {
                this.props.history.push("/profile/account");
            }, 2000);
        }
        
        return (
            <div className={"NewPasswordScreen"}>
                <div className={"NewPasswordContent"}>
                    <FormControl className="PasswordInput">
                        <Secured className={"inputLeftIcon"} />
                        <InputLabel htmlFor="NewPasswordField">{this.props.t('New Password')}</InputLabel>
                        <Input
                            id="NewPasswordField"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            onChange={this.handleChange('password')}
                            onFocus={this.focusHandler.bind(this)}
                            onBlur={this.blurHandler.bind(this)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                    >
                                        {this.state.showPassword ? <Visibility  className={'VisibilityIcon'}/> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <PasswordStrength showPasswordStrength={this.state.showPasswordStrength} password={this.state.password} ok={this.state.ok}/>
                    <FormControl className="PasswordInput">
                        <Secured className={"inputLeftIcon " + (!this.state.passwordMatch ? 'ErrorField' : '')} />
                        <InputLabel htmlFor="ConfirmPasswordField" error={!this.state.passwordMatch}>{this.props.t('Confirm Password')}</InputLabel>
                        <Input
                            id="ConfirmPasswordField"
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            value={this.state.confirmPassword}
                            onChange={this.handleChange('confirmPassword')}
                            error={!this.state.passwordMatch}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirmPassword visibility"
                                        onClick={this.handleClickShowConfirmPassword}
                                    >
                                        {this.state.showConfirmPassword ? <Visibility  className={'VisibilityIcon'}/> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {passwordMatchError}
                    </FormControl>
                </div>
                {successMessage}
                <div>
                    <Button 
                        className={'gradient'} 
                        disabled={!this.state.ok || this.props.profile.loading} 
                        full 
                        click={this.handleSubmit.bind(this)} 
                        value={
                            this.props.profile.loading 
                            ? <CircularProgress className={'Loading'} /> 
                            : this.props.t('Finish')
                        }
                    />
                    <Button 
                        className={'gray'}
                        full 
                        click={() => this.props.history.push("/profile/account")} 
                        value={this.props.t('Cancel')}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onChangePassword: (currentPassword, newPassword) => dispatch(storeActions.changePassword(currentPassword, newPassword)),
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPassword)));
