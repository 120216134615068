let trans = {};
const configTranslate = process.env.TRANSLATE;
for (let i = 0; i < configTranslate.languages.length; i++) {
    try {
        trans[configTranslate.languages[i]] = { translations: require("./" + configTranslate.languages[i] + ".js")[configTranslate.languages[i]] }
    } catch (e) {
        console.error("A aparut o eroare la incarcarea fisierului de traduceri pentru limba:", configTranslate.languages[i]);
    }
}
export const translations = trans;

function t() {
    return true;
}

t("Partial cancellation"); t("Full cancellation"); t("Pending approval"); t("Processed"); t("Welcome"); t("This account is closed. Please contact support.");
t("Your new limit is now set to ${value} RON"); t("New Limit of ${value} will be activated over 48H"); t('Your limit already is ${value} RON'); t('Your limit will be removed over 48H');
t("Visit the section Account ${pathSymbol} Wallet & Bonus ${pathSymbol} Withdraw or directly from the “Locations” section. Your request will be processed as soon as possible."); t("Visit the section Account ${pathSymbol} Withdrawals. For every withdrawal there is an option to cancel funds partially or completely. This will not affect the approval time.");
t("The subject should have minimum 5 characters"); t("The body should have minimum 30 characters"); t("Withdrawals"); t("Transaction History"); t("My Locations"); t("Take a break"); t("Log Out"); t("Table Games"); t("Don't miss the greatest"); t("PROMOTIONS"); t("Sign-in");t('slots_lobby_title_ticker');t('slots_lobby_subtitle_ticker')
t("homepage_table_games_big_font");t("homepage_table_games_small_font");
// Promotions Translations
t("Welcome to Elite! We know that a high stake generates high earnings. For you to enjoy your betting without any restraints, ${boldStart}we give you a BONUS, for each deposit, up to a total limit of 1,000 Lei.${boldEnd} Make a deposit now, we give you a prize and you can start playing, right away!");
t("First deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}500 lei BONUS.${boldEnd}");
t("Second deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}200 Lei BONUS.${boldEnd}");
t("Third deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}300 Lei BONUS.${boldEnd}");

// Cookies Translations
t("We use cookies and similar technologies to ${boldStart}recognize your repeat visits and preferences${boldEnd}, as well as to be able to ${boldStart}offer the service authentication and communication${boldEnd}.");
t("To learn more about cookies and the data we store, view our ${boldStart}Privacy Policy${boldEnd}.");t("Invalid code.");t("What will you play?");

//Login translations
t('Forgot password?'); t('No account yet?');
// About us
t("If you want to post a complaint, please click");
t("If we are unable to solve your complaint within 14 days, you will have the right to contact the National Gambling Office (ONJN).");

t("Account Details");
t("All");t("Top Rated"); t("Roulette & Cards"); t("wins");
t('This category is empty!')

t("See details");t("Next ball color"); t("Next ball even or odd");t("Even");t("My tickets");t("Sports");
t("virtual games");t("Placing the ticket in progress...");t("Match details");t("Waiting for events...");
t("Search bet");t("Placing failed");t("An error occurred");t("If the problem persists, please contact the Customer Support departement");
t("Live tickets can only be played online");t("There are no top leagues");t("Most played");t("By Kick-off");t("By league");
t("Home");t("My Filtru");t("Event bets locked");t("All Championships");t("By Hour");t("You haven't selected any bets");
t("Balance");t("Choose Stake");t("Min. Winning");t("Place ticket online");t("Automatically accept odds changed");
t("Online ticket");t("Ticket in shop");t("Enter now");t("The tournament begins");t("Event bets locked");t("High Odds matches");
t("Pull and come into play");t("Cazino");
