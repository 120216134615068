import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import ClearIcon from '@material-ui/icons/Clear';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import DialogInfo from "../../../../../tournaments-missions/dialogs/dialog-switch-tournament";

import "./TitleBar.scss";

const TitleBar = (props) => {
  const [openDialogInfo, setOpenDialog] = React.useState(false);
  const handleCloseDialogInfo = (e) => {
    if (e) e.stopPropagation();
    setOpenDialog(false);
  };

  const handleClick = () => {
    localStorage.setItem("fromGame", JSON.stringify(props.activeGame));
    props.closeHandler(true);
    props.hideLoader();
  };

  const style = React.useMemo(() => {
    return {
      // background: window.config.DESKTOP_MAIN_BAR_BG_COLOR,
      // color: window.config.DESKTOP_MAIN_BAR_TEXT_NORMAL_COLOR,
      background: window.config.MOBILE_MAIN_BAR_BG_COLOR,
      color: window.config.MOBILE_MAIN_BAR_TEXT_NORMAL_COLOR
    };
  }, []);

  return <div className="game-header-title-bar screen-title" style={style}>
    <div className="back" onClick={() => props.closeHandler()}>
      <div className="back-inner">
        <ClearIcon />
      </div>
    </div>
    <div className="flx">
      <div className="deposit-but" onClick={handleClick}>{props.t("Deposit")}</div>
      <div className="more" onClick={() => setOpenDialog(true)}>
        <div className="back-inner">
          <MoreIcon />
        </div>
      </div>
    </div>
    {openDialogInfo && <DialogInfo open={openDialogInfo} onClose={handleCloseDialogInfo} />}
  </div>;
}

const mapStateToProps = (state) => {
  return {
    activeGame: state.games.activeGame,
  };
};

const hideLoader = () => {
  return {
    type: "HIDE"
  };
}

const actions = {
  hideLoader
};

export default withTranslation()(connect(mapStateToProps, actions)(withRouter(TitleBar)));