import ClientAPI from "../ClientAPI/ClientAPI";
import isEqual from "lodash/fp/isEqual";
import { layoutUpdateNotch, layoutUpdateOrientation } from "../store/actions/layout";

const getNotchSize = () => {
    // get the <html> element
    const root = document.documentElement;

    // add CSS variables so we can read them back
    root.style.setProperty('--notch-top', 'env(safe-area-inset-top, 0px)');
    root.style.setProperty('--notch-top-offset', 'calc(env(safe-area-inset-top) - 7px)');
    root.style.setProperty('--notch-right', 'env(safe-area-inset-right, 0px)');
    root.style.setProperty('--notch-bottom', 'env(safe-area-inset-bottom, 0px)');
    root.style.setProperty('--notch-left', 'env(safe-area-inset-left, 0px)');

    // get runtime styles
    var style = window.getComputedStyle(root);

    // read env values back and check if we have any values
    const cnd = {
        top: parseInt(style.getPropertyValue('--notch-top') || '0', 10),
        topOffset: parseInt(style.getPropertyValue('--notch-top-offset') || '0', 10),
        right: parseInt(style.getPropertyValue('--notch-right') || '0', 10),
        bottom: parseInt(style.getPropertyValue('--notch-bottom') || '0', 10),
        left: parseInt(style.getPropertyValue('--notch-left') || '0', 10)
    };

    if (cnd.top === 0) {
        root.style.setProperty('--notch-top-offset', '0px');
    }

    const orientation = (window.innerHeight > window.innerWidth) ? 'portrait' : 'landscape';

    const state = ClientAPI.getStore().getState();

    if (!isEqual(state.layout.notch, cnd)) {
        ClientAPI.getStore().dispatch(layoutUpdateNotch(cnd));
    }

    if (state.layout.orientation !== orientation) {
        ClientAPI.getStore().dispatch(layoutUpdateOrientation(orientation));
    }
}

let tid = 0;

const handleResize = () => {
    //console.log("resize");

    if (tid !== 0) {
        clearTimeout(tid);
    }

    tid = setTimeout(() => {
        getNotchSize();
        tid = 0;
    }, 300);
}

export const installNotchHandler = () => {
    getNotchSize();

    window.setTimeout(() => {
        getNotchSize();
    }, 1000);

    window.addEventListener('resize', handleResize);
}
