import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as stateActions from "../../../../store/actions";
import {withTranslation} from "react-i18next";
import TitleBar from "../../../UI/TitleBar/TitleBar";
import Phone from "../Phone/Phone";
import "./VerificationInput.scss";
import Button from "@material-ui/core/Button";
import GTM from "../../../../utils/GTM";
import TextField from "@material-ui/core/TextField";

const CODE_LENGTH = 5;

class VerificationInput extends Component {

    constructor(props) {
        super(props);

        for (let i=0;i<CODE_LENGTH;i++) {
            this.state.code[i] = "-";
        }
    }

    state= {
        code: [],
        message: {
            type: "",
            text: "",
        },
        ok: false,
        pendingRequest: false,
        modal: false
    };

    getErrors() {
        if (this.props.type === 'simple' && !this.props.oldRegister) {
            return [];
        }
        let errors = [];
        // if errors are not for validation code
        // this case shouldn't appear, but... just to be sure we catch this errors and show them in the UI
        if (typeof this.props.signedUpErrors !== 'string' && typeof this.props.signedUpErrors !== "undefined") {
            let origErrors = this.props.signedUpErrors;
            // add each error in queue
            Object.keys(this.props.signedUpErrors).forEach(function (key) {

                // it's possible to receive an object or a string
                // verify and push each error in the array
                if (typeof origErrors[key] === 'string') {
                    errors.push({
                        type: "error",
                        text: origErrors[key]
                    })
                } else {
                    Object.keys(origErrors[key]).forEach( function (nKey) {
                        if (typeof origErrors[key][nKey] === 'string') {
                            errors.push({
                                type: "error",
                                text: origErrors[key][nKey]
                            });
                        } else {
                            // errors['undefined'] = {type: "error", text: ResponseErrorMessages.get(0)};
                        }
                    });
                }
            });
        } else {
            errors.push({
                type: "error",
                text: this.props.signedUpErrors !== "unknown" ? this.props.signedUpErrors : this.props.t("Something went wrong")
            })
        }
        if (typeof errors[0] !== "undefined") {
            GTM.pushGAEvent("Register_" + this.props.lang,"Field - Error", "Verification Code - " + errors[0].text);
        }
        return typeof errors[0] !== "undefined"? errors[0].text : '';
    }

    componentDidUpdate(prevProps) {


        if (this.props.signedUp !== prevProps.signedUp) {
            GTM.pushGAEvent("Register_" + this.props.lang,"Field - OK", "Verification Code")
            this.setState({
                ...this.state,
                pendingRequest: false,
            })
        }
    }

    refreshCode(code, index) {
        let arrCode = this.state.code;
        arrCode[index] = code;
        let finalCode = "";
        for (let i = 0; i<CODE_LENGTH; i++) {
            if (!isNaN(arrCode[i])) {
                finalCode += arrCode[i];
            } else {
                finalCode += '-';
            }
        }

        this.setState({
            ...this.state,
            code: arrCode,
        });

        return finalCode;
    }

    serverSideValidation(fieldInput) {
        // let fields = BackendClient.getStore().getState().registerWizard.fields;
        // let axios = BackendClient.getInstance();
        //
        // if (typeof fields.cnp !== "undefined") {
        //     fields.nin = fields.cnp;
        // }
        //
        // if (!fieldInput) {
        //     let code = "";
        //     for(let i = 0; i < CODE_LENGTH; i++) {
        //         code += this.state.code[i];
        //     }
        //
        //     fields.phoneValidationCode = code;
        // } else {
        //     fields.phoneValidationCode = fieldInput;
        // }
        //
        // if (fields.phoneValidationCode.indexOf('-') !== -1) {
        //     return;
        // }
        //
        // axios({
        //     url: '/api/register',
        //     method: 'post',
        //     data: fields,
        // }).catch(e => {
        //     console.log(`[CATCHED **SIGN UP** ERROR] => ${e}`);
        // });

        this.setState({
            ...this.state,
            pendingRequest: true,
        })
    }

    validate(code) {
        let length = CODE_LENGTH;

        if (this.props.verificationType !== 1) {
            length++;
        }
        if (this.props.type === 'simple') {
            return code.length === length;
        }
        for (let i = 0; i < length; i++) {
            if (isNaN(code[i])) {
                return false;
            }
        }

        return true;
    }

    onKeyPressedHandler(event) {
        // not number
        if ((event.charCode < 48 || event.charCode > 57) &&  this.props.verificationType === 1) {
            event.preventDefault();
        }
    }

    onChangeHandler(event){
        let fieldInput;

        if (null === event) {
            fieldInput = this.props.code;

        } else {
            if (this.props.type === 'simple') {
                fieldInput = event.target.value;
            } else {
                let code = event.target.value;

                let targetInput = event.target.getAttribute('data-target');

                if (targetInput !== CODE_LENGTH) {
                    let elem = document.getElementById("target_" + targetInput);

                    if (elem !== null) {
                        elem.focus();
                        elem.value = "";
                    } else {
                        elem = document.getElementById("target_" + (parseInt(targetInput, 10) - 1) );
                        if (elem !== null) {
                            elem.blur()
                        }
                    }
                }

                fieldInput = this.refreshCode(code, parseInt(targetInput, 10) - 1);
            }
        }

        let valid = this.validate(fieldInput);
        let ok = this.state.ok;
        let message = "";
        if (valid) {
            if (!ok) {
                ok = true;
            }
        } else {
            // errors are catched in componentDidUpdate, and they come from api request
            // theoretically, in this version, the user will not enter on this section
            if (ok) {
                ok = false;
            }
        }

        this.setState({
            ...this.state,
            message: {
                ...this.state.message,
                type: (message !== "" ? "error" : ""),
                text: message,
            },
            ok: ok,
        });
        let length = CODE_LENGTH;
        if (this.props.verificationType !== 1) {
            length++;
        }
        this.props.onChange({field: 'phoneValidationCode',value: fieldInput.substring(0,length), validated: ok});
    }

    onFocusHandler(event) {

        if (!(this.props.type === 'simple')) {
            let index = event.target.getAttribute('data-target') - 1;
            if (index < 0) index = 0;
            let code = this.state.code;
            let length = CODE_LENGTH;

            if (this.props.verificationType !== 1) {
                length++;
            }
            for(let i = index; i < length; i++) {
                document.getElementById("target_" + i).value = "";
                code[i] = "-";
            }
            this.setState({
                ...this.state,
                code: code
            });
        }

    }

    toggleModal(save) {
        if (!this.state.modal) {
            this.initialPhone = this.props.phone;
        }

        if (typeof save === "undefined" && this.state.modal) {
            this.props.onChange({field: 'phone',value: this.initialPhone, validated: 1});
        }

        this.setState({
            ...this.state,
            modal: !this.state.modal
        })
    }


    savePhone() {
        this.toggleModal(true);
        this.props.resendSMS();
    }


    clickHandler() {
        this.props.resendSMS();
    }


    render() {
        if (this.state.modal) {
            return (
                <div className={"changePhoneNumber"}>
                    <TitleBar pageTitle={this.props.t('Change Phone Number')} backHandler={this.toggleModal.bind(this,undefined)}/>
                    <div className="content">
                        <Phone />
                        <Button variant="contained" size="large" color="primary" disabled={!this.props.phoneValidated} className={''} onClick={this.savePhone.bind(this)}>
                            { this.props.t("Save") }
                        </Button>
                        <Button variant="contained" size="large" color="secondary" className={''} onClick={this.toggleModal.bind(this,undefined)}>
                            { this.props.t("Cancel") }
                        </Button>
                    </div>
                </div>
            );
        }

        let HTMLInputs = [];
        if (this.props.type === 'simple') {
            HTMLInputs.push(<TextField
                {...this.props}
                id={"verification-code"}
                type={this.props.verificationType !== 1 ? "text" : "tel"}
                placeholder="*****"
                className={''}
                autocomplete="one-time-code"
                onKeyPress={this.onKeyPressedHandler.bind(this)}
                onChange={this.onChangeHandler.bind(this)}
                onFocus={this.onFocusHandler.bind(this)}
                disabled={this.state.pendingRequest}
                key={"key-simple-verification-code"}
                value={this.props.code}
            />)
        } else {
            let length = CODE_LENGTH;

            if (this.props.verificationType !== 1) {
                length++;
            }
            for (let i = 0; i < length; i++) {
                HTMLInputs.push(
                    <div className="input-group" key={"key_" + i}>
                        <input type="tel"
                               id={"target_" + i}
                               required
                            // placeholder="&#9679;"
                               data-target={i+1}
                               onFocus={this.onFocusHandler.bind(this)}
                               onKeyPress={this.onKeyPressedHandler.bind(this)}
                               onChange={this.onChangeHandler.bind(this)}
                               disabled={this.state.pendingRequest}
                        />
                    </div>
                );
            }
        }



        return (
            <div className={'field' + (typeof this.props.type !== "undefined" ? " " + this.props.type : "") }>
                <p className={'verification-details subtitle'}>{this.props.t("Insert the verification code received via SMS")}</p>
                {HTMLInputs}
                <div className={'change'}>
                    {this.props.phone} |
                    <a className="right" onClick={this.toggleModal.bind(this)}>
                        {this.props.t("Change Phone Number")}
                    </a>
                </div>
                <div className="errors">
                    {this.getErrors()}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onChange: (fieldInput) => dispatch(stateActions.fieldEntered(fieldInput)),
        resendSMS: () => dispatch(stateActions.sendSMS())
    };
};

const mapStateToProps = state => {
    return {
        code: state.register.fields.phoneValidationCode,
        phoneValidated: state.register.validated.phone,
        signedUp: state.register.signedUp,
        phone: state.register.fields.phone,
        signedUpErrors: state.register.signedUpErrors,
        lang: state.application.language,
        verificationType: state.register.verificationType
    }
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VerificationInput));
