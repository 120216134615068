export const authentication = {
	AUTH: '/api/auth/login',
	SMS: '/api/auth/sms',
	APP: '/api/auth/index',
	TOKEN: '/api/auth/refresh-token',
};

export const withdraw_wizard = {
	WITHDRAW_REQUEST: '/api/profile/withdrawRequest',
};

export const menu = {
	ALL: '/mobile/menu',
};

export const wallet = {
	GET: '/api/profile/wallet',
	REQUEST_REMOVE_BONUS: '/api/profile/bonusForfeit',
};

export const validation = {
	phone: '/api/validate/phone',
	email: '/api/validate/email',
	cnp: '/api/validate/cnp',
	nin: '/api/validate/cnp',
};

export const profile = {
	ACCOUNT: '/api/profile/account',
	RESPONSIBLY: '/api/profile/responsibly',
	SET_LIMIT: '/api/profile/setLimit',
	CHANGE_PASSWORD: '/api/profile/changePassword',
	CHECK_PHONE: '/api/profile/validate-phone',
	CHECK_PHONE_CODE: '/api/profile/validateCode',
	GET_CHAT_INFO: '/api/profile/chatAuth',
	CHANGE_LANGUAGE: '/api/profile/changeLanguage',
	RESEND_VERIFICATION_EMAIL: '/notifications/email/resendVerification',
	SEND_SELF_EXCLUSION_CODES: '/api/profile/sendSelfExclusionCodes',
	SELF_EXCLUDE: '/api/profile/selfExclude',
};

export const transactions = {
	TRANSACTIONS_HISTORY: '/api/profile/transactions',
};

export const paymentCheckout = {
	PAYMENT_CHECKOUT: '/api/pay-checkout',
	VIVA_TOKEN: '/api/pay-checkout/viva-auth',
	VIVA_CARD_TOKEN: '/api/pay-checkout/card-token',
	SEND_VIVA_TOKEN: '/api/pay-checkout/charge-token',
	REQUEST_VIVA_CHARGE_TOKEN: '/nativecheckout/v2/chargetokens',
	JS_VIVA_PAYMENT: "/web/checkout/v2/js",
	VIVA_WITHDRAW: '/api/pay-checkout/viva-withdraw',
	OKTO_CREATE_PAYMENT_CODE: '/api/pay-checkout/okto-create-payment-code',
	OKTO_GET_CUSTOMER: '/api/pay-checkout/okto-get-customer',
	SKRILL_PAYSAFE_CHECKOUT: '/api/pay-checkout/skrill-paysafe-checkout',
	AIRCASH_REQUEST_PAYMENT_LINK: '/api/pay-checkout/aircash-checkout',
};

export const withdrawalCheckout = {
	WITHDRAWAL_CHECKOUT: '/api/profile/withdrawRequest',
};

export const withdrawals = {
	PENDING_WITHDRAWALS: '/api/profile/pending-withdrawals',
	PENDING_VIVA_WITHDRAWALS: '/api/profile/pending-viva-withdrawals',
	REQUEST_LOCATIONS: '/api/profile/locations',
	REQUEST_TAX_SERVICE: '/api/profile/calculate-tax',
	REQUEST_CANCEL_WITHDRAWAL: '/api/profile/cancel-withdraw',
	REQUEST_CANCEL_VIVA_WITHDRAWAL: '/api/profile/cancel-viva-withdraw',
	REQUEST_VERIFICATION_CODE: '/api/profile/withdraw-code',
	DOWNLOAD_CSV: '/api/profile/transactions',
	GET_PAYMENT_METHODS: '/api/withdraw/getPaymentMethods',
	LINK_IBAN: '/api/withdraw/linkIban',
	WITHDRAW: '/api/withdraw/init',
	GET_WITHDRAW_HISTORY: '/api/withdraw/getHistory',

};

export const documents = {
	REQUEST_DOCUMENTS: '/api/profile/documents',
	REQUEST_DOCUMENTS_UPLOAD: '/api/documents/upload',
	REQUEST_DOCUMENTS_MAX_FILE_SIZE: '/api/documents/max-upload-size',
};

export const games = {
	LIST_GAMES: '/api/casino/list-games',
	LAUNCH_URL: '/api/game/launch',
	GAME_COLLECTIONS: '/api/casino/get-collections',
};

export const resetPassword = {
	RESET_PASSWORD: '/api/register/resetPassword',
};

export const customer_support = {
	SEND_COMPLAINT_FORM: 'notifications/email/reamaze',
	REQUEST_GAME_RULES: '/api/customer-support/game-rules',
};

export const jackpot = {
	DETAILS: '/api/casino/jackpot',
	JACKPOTS: '/api/casino/jackpots',
	LATEST_WINNERS: '/api/casino/latest-winners',
};

export const promotions = {
	GET: '/api/casino/promotions'
}

export const eligible_bonuses = {
	GET: '/api/casino/getBonuses'
}

export const bonus_available = {
	GET: '/api/casino/getBonusAvailable',
	CLAIM: '/api/casino/claimBonus'
}

export const bonuses = {
	GET: '/api/casino/getPlayerBonuses',
	GET_BONUS_CALCULATOR: '/api/casino/getBonusCalculator',
}

export const preeuro = {
	GET: '/winner/playerTickets'
}

export const marketing_offer = {
	GET: '/api/casino/marketingOffer'
}

export const ip2location = {
	POST: '/api/ip2location'
};
