import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Icons from '../../../../../assets/Icons/Icons';
import PageTitle from '../../../../UI/PageTitle/PageTitle';
import './WageredAmount.scss';

class WageredAmount extends Component {

    render() {

        const icons = {
            WageredAmount: Icons.get('default', `iconSettings`),
        };

        return (
            <div className={"WageredAmountScreen"}>
                <PageTitle icon={icons.WageredAmount} title={this.props.t('Wagered Amount')} subtitle={this.props.t('Coming Soon')} />
            </div>
        );
    }
}

export default withTranslation()(WageredAmount);