import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import TitleBar from "../../../../UI/TitleBar/TitleBar";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PageTitle from '../../../../UI/PageTitle/PageTitle';
import Button from '../../../../UI/Button/Button';
import Translate from "../../../../../utils/Translate";
import ConfirmEmail from './ConfirmEmail/ConfirmEmail';
import Icons from '../../../../../assets/Icons/Icons';
import './SelfexclusionConfirmation.scss';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../../../store/actions";

class SelfexclusionConfirmation extends Component {

    state = {
        inactiveButton: true,
        buttonTime: 10,
        confirmEmail: false,
    }

    constructor(props) {
        super(props);
    }

    /**
     * help poedit to scan and find the text
     */
    t(string){
        return string;
    }

    componentDidMount() {

        this.props.sendCodes();

        let buttonCountdown = setInterval(() => {
            if (this.state.buttonTime === 1) {
                this.setState({
                    ...this.state,
                    inactiveButton: false,
                });
                clearInterval(buttonCountdown);
            }else{
                this.setState({
                    ...this.state,
                    buttonTime: (this.state.buttonTime - 1),
                });
            }
        }, 1000);
    }

    triggerConfirmation(value) {
        this.setState({
            ...this.state,
            confirmEmail: value,
        })
    }

    render() {

        if(!this.state.confirmEmail){
            return (
                <div className={"SelfexclusionConfirmationScreen"}>
                    <TitleBar pageTitle={this.props.t('Responsible Gaming')} backHandler={() => this.props.return(false)}/>
                    
                    <AppBar position="static" className="SubtitleBar">
                        <Typography variant="subtitle1"><Translate text={this.t('Self-Exclusion')}/></Typography>
                    </AppBar>
                    
                    <div className="SelfexclusionConfirmationContent">
                        <PageTitle icon={<NotInterestedIcon className={"iconSettings"} />} title={this.props.title} subtitle={this.props.subtitle} />
                        <div className="TextContent">
                            <p className={'EmailSent'}>
                                <Translate text={this.t('We sent you an email with the next steps required to activate your self-exclusion.')}/>
                            </p>
                            <p>
                                <Translate 
                                    text={this.t('By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}${brandFull}${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.')}
                                    placeholder={{pinkBoldStart: '<span class="pinkBold">', pinkBoldEnd: '</span>',brandFull: window.config.brand_name_full}}
                                />
                            </p>
                            <p>
                                <Translate text={this.t('Inform yourself to full extent about this decision. You will not be able to revert it.')} />
                            </p>
                        </div>
                        <div className="AlertContent">
                            <p>
                                {Icons.get('closeIcon', 'closeIcon')}
                                <Translate text={this.t('! CANNOT BE REVERTED !')} />
                            </p>
                            <p>
                                {Icons.get('closeIcon', 'closeIcon')}
                                <Translate text={this.t('! You will be excluded from all ${NOG_license_friendly_name} Network casinos !')}
                                           placeholder={{NOG_license_friendly_name: window.config.NOG_license_friendly_name}}
                                />
                            </p>
                            <p>
                                {Icons.get('closeIcon', 'closeIcon')}
                                <Translate text={this.t('! You will not regain access to your account, without exceptions !')} />
                            </p>
                        </div>
                        <Button 
                            type={"button"} 
                            full 
                            className={"active"} 
                            disabled={this.state.inactiveButton}
                            value={(this.state.buttonTime === 1 && !this.state.inactiveButton) ? this.props.t('Identity Confirmation') : this.state.buttonTime}
                            click={() => this.triggerConfirmation(true)}
                        />
                    </div>
                </div>
            );
        }else{
            return (
                <Fragment>
                    <ConfirmEmail 
                        return={(value) => this.triggerConfirmation(value)}
                        title={this.props.t('Confirm self-exclusion')} 
                        subtitle={this.props.t('Got a gambling addiction?')} 
                        activeChoice={this.props.activeChoice}
                    />
                </Fragment>
            );
        }
    }
}
const mapStateToProps = state => {
    return {
        withdrawals: state.withdrawals,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        sendCodes: () => dispatch(storeActions.sendSelfExclusionCodes()),
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SelfexclusionConfirmation)));
