// eslint-disable-next-line
export const ro = {
	"404": "404",
	"Self exclusion period": "Perioada de auto-excludere",
	"from": "de la",
	"to": "până la",
	"If you want to cancel this action, please contact support.":
		"Contactați serviciul de Suport Jucători pentru mai multe informații.",
	"Cancel": "Anulare",
	"Open support": "Contactează Suport Jucători",
	"50 LEI FREE": "50 LEI GRATIS",
	"Friends with benefits": "Prieteni cu beneficii",
	"Money for free, once we know each other": "Bani gratis, după ce ne cunoaștem",
	"TAKE 1,000 LEI BONUS": "Ia 1000 Lei BONUS",
	"What's your number?": "Cât vrei să iei cu tine?",
	"Mega Jackpots. Prizes. Tournaments.": "Mega Jackpots. Premii speciale. Concursuri.",
	"Slots Lobby": "Sloturi Lobby",
	"Start Game": "Începe jocul",
	"register": "Înregistrare",
	"Sign-in": "Conectare",
	"Betting": "Pariuri",
	"Coming Soon": "În curând",
	"Better odds. Bet easier.": "Cote mai bune. Pariați mai ușor.",
	"coming soon": "în curând",
	"Live Casino": "Cazino LIVE",
	"VIP experience": "Experiență VIP",
	"Pirates Treasure": "Comoara piratului",
	"Here you will embark on an adventure": "Aici vei porni într-o aventură",
	"Legal": "Legal",
	"If you need any help, do not hesitate to get in touch with us using one of the methods below.":
		"Dacă aveți nevoie de ajutor, nu ezitați să luați legătura cu noi folosind una dintre metodele de mai jos.",
	"LIVE CHAT": "CHAT LIVE",
	"Service not available": "Serviciu indisponibil.",
	"Average waiting time:": "Timp mediu de așteptare:",
	"E-MAIL": "E-MAIL",
	"CALL ME BACK": "SUNĂ-MĂ ÎNAPOI",
	"About Us": "Contact",
	"This website is operated by ${companyName}, registered in ${countryName} with number ${registerNumber} at the address listed below. In Romania, ${companyName} operates under license number ${onjnLicenseNumber} obtained on ${date} by the Romanian Gambling Office (ONJN), valid though ${licenseExpirationNumber}.":
		"Acest website este operat de ${companyName}, inregistrata in ${countryName} cu numarul ${registerNumber} la adresa listata mai jos. In Romania, ${companyName} are drept de functionare cu ajutorul licentei ${onjnLicenseNumber} obtinuta la data de ${date} de la Organizatia Nationala a Jocurilor de Noroc (ONJN), fiind valida pana la data de ${licenseExpirationNumber}.",
	"Gambling is prohibited under age of 18. Gambling might be prohibited in your area. It is the player’s responsibility to comply with the law regulations.":
		"Jocurile de noroc sunt interzise sub 18 ani. Jocurile de noroc pot fi interzise în zona dvs. Este responsabilitatea jucătorului să respecte reglementările legale.",
	"Gambling may become addictive and impact your financials. Read about responsible gaming":
		"Jocurile de noroc pot deveni dependente și pot avea impact asupra finanțelor tale. Citiți despre jocurile responsabile",
	"here": "aici",
	"Address:": "Adresa:",
	"Invalid email": "Email invalid",
	"Message sent": "Mesaj trimis",
	"Our Customer Support will contact you shortly. Thank you for your paticence.":
		"Echipa Suport Clienți te va contacta în curând. Îți mulțumim pentru răbdare.",
	"Back to casino": "Înapoi la casino",
	"Message not sent": "Mesajul nu a fost trimis",
	"Failed": "Eşuat",
	"Sorry! I could not send your email.": "Ne pare rău! Nu am putut să vă trimit e-mailul.",
	"Please try again later.": "Incercați din nou mai târziu.",
	"Post a Complaint": "Trimite cerere",
	"Complaint Form": "Detalii",
	"Name": "Nume",
	"Email": "Email",
	"Subject": "Subiect",
	"Body": "Conținut",
	"Submit": "Trimite",
	"Customer Support": "Relații Clienți",
	"FAQ": "FAQ",
	'What is "Deposits & Withdrawals in Location"?':
		"Ce înseamnă “Depuneri și Retrageri în Agenție”?",
	"We made it simpler so you can play as you want. If you want to stay away from Credit Cards and other digital payment systems, you are free to deposit and withdraw in any casino location using only your ID card.":
		"Este sistemul nostru inovativ pentru cei care nu vor să folosească card-uri bancare sau alte metode de plată electronice. Poți efectua operațiuni din orice agenție doar cu buletinul.",
	"Can I cash-in in any Location?": "Pot retrage în orice Agenție?",
	"You can cash-in up to 200 Lei per day in any location. Withdrawal amounts greater than 200 Lei can only be cashed-in in the selected location.":
		"Poți retrage din orice agenție în limita a 200 Lei zilnic. Retragerile individuale mai mari de 200 Lei pot fi retrase doar din agenția aleasă pentru ridicare.",
	"How do I Deposit in Location?": "Cum depun în Agenție?",
	"Visit any casino location with the money and your ID card. Your account will receive the funds instantly and you will receive confirmation by SMS.":
		"Vino cu banii și cu buletinul de identitate la ghișeul oricărei agenții. Contul tău va fi alimentat imediat și vei primi confirmarea prin SMS.",
	"How do I initiate a Withdrawal?": "Cum solicit o retragere?",
	"How do I cash-in my withdrawn money in location?": "Cum ridic banii din agenție?",
	"You will receive a SMS Code once your withdrawn has been approved. Go in the chosen location":
		"Veți primi un cod SMS odată ce retragerea dvs. a fost aprobată. Accesați locația aleasă.",
	"I want to cancel a withdrawal and return the funds to my play account.":
		"Cum anulez o cerere de retragere și returnez fondurile  în contul meu de jucător?",
	"I'm trying to initiate a withdrawal without success.":
		"De ce nu pot înregistra o cerere de retragere?",
	"In most of the cases there will be an error message explaining the reason. The main causes are (1) you have not completed your Identity Verification process or (2) you have active BONUSES that have wager requirements in progress.":
		"În majoritatea cazurilor vei primi un mesaj de eroare care explică motivele. Cauzele principale sunt (1) nu ai completat procesul de Verificare a Identității sau (2) ai BONUS-uri active care nu au îndeplinit înca cerințele de pariere.",
	"What kind of currency can be used to deposit and withdraw?": "Ce valute pot utiliza?",
	"Right now, the following currency can be used": "În acest moment poți folosi doar",
	"Lei (RON)": "Lei (RON)",
	"What taxes apply on withdrawals?": "Ce taxe se aplică la retrageri?",
	"Starting from 1st of August 2022, according to the Government Ordinance no. 16/2022, amending and supplementing the Law no. 227/2015 on the Fiscal Code, gambling income obtained by individuals as a result of participating in online gambling, is taxed according to the following thresholds":
		"Începând cu 1 august 2022, conform Ordonanței Guvernului nr. 16/2022, pentru modificarea şi completarea Legii nr. 227/2015 privind Codul fiscal, veniturile din jocuri de noroc, obținute de persoane fizice ca urmare a participării la jocuri de noroc online, se impozitează conform următoarei grile",
	"From": "De la",
	"inclusive": "inclusiv",
	"applied to the amount over": "pentru ceea ce depășește suma de",
	"Over": "Peste",
	"I have cashed-in less money than the amount requested in the withdrawal.":
		"De ce am primit mai puțini bani decat am retras?",
	"We do not tax you in any way; the difference comes from the state taxes accordingly to the law.":
		"Noi nu reținem nici un comision, diferența fiind dată de impozitul la sursă conform legilor în vigoare.",
	"My wallet received less money than the deposit value.":
		"De ce am primit mai puțini bani decat am depus?",
	"Based on the Government’s Emergency Decree 114/2018, every deposit is taxed 2% of its value, transferred to the State Budget.":
		"Ca urmare a Ordonanței de Urgență a Guvernului 114/2018, fiecare depunere în contul de jucator este taxată cu 2% din valoare, acești bani fiind virați la Bugetul de Stat.",
	"Privacy Policy": "Politica de Confidentialitate",
	"Introduction": "Introducere",
	"As an on-line gambling Organizer, PLAY ONLINE SOLUTIONS LTD (“We”) will process the personal data of the participants by registering the game account on the website and is committed to protecting your personal information and processing it fairly and transparently in accordance with the provisions of EU Regulation 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (“GDPR/the Regulation”).":
		"Ca organizator de jocuri de noroc online, PLAY ONLINE SOLUTIONS LTD („Noi”) va prelucra datele personale ale participanților prin înregistrarea contului de joc pe site-ul web și se angajează să vă protejeze informațiile personale și să le prelucreze în mod corect și transparent, în conformitate cu prevederile Regulamentului UE 2016/679 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și libera circulație a acestor date, prin care se abrogă Directiva 95/46 / CE („GDPR / Regulamentul”).",
	"Above all, we are faithful to the following key data processing principles:":
		"În primul rând, respectăm următoarele principii cheie de prelucrare a datelor:",
	"Lawfulness, fairness and transparency": "Legalitate,  echitate și transparență",
	"we process personal data on legal grounds, fairly and in a transparent manner;":
		"prelucrăm  datele  cu  caracter  personal  în  baza  unui  temei  legal,  în  mod echitabil și transparent;",
	"Purpose limitation": "Limitări legate de scop",
	"we collect personal data for specified, explicit and legitimate purposes;":
		"colectăm date cu caracter personal în scopuri specifice, explicite și legitime;",
	"Data minimization": "Reducerea la minimum a datelor",
	"we only collect and keep personal data that is adequate, relevant and limited to what is necessary in relation to the purposes for which it is processed and according to the legal provisions in force;":
		"colectăm și păstrăm numai datele cu caracter personal care sunt adecvate, relevante și limitate la ceea ce este necesar în raport cu scopurile în care sunt prelucrate;",
	"Accuracy": "Exactitate",
	"we ensure that the personal data we keep is accurate, kept up to date or otherwise erased or rectified;":
		" ne asigurăm că  datele  cu  caracter  personal  pe  care  le păstrăm  sunt  exacte,  actualizate  sau șterse, respectiv rectificate;",
	"Storage limitation": "Limitări legate de stocare",
	"we ensure that personal data is stored only for the period of time that is strictly necessary for the fulfilment of our purposes or is otherwise erased or anonymized;":
		"ne asigurăm că stocăm datele cu caracter personal pe o perioadă care nu depășește perioada  strict  necesară  îndeplinirii  scopurilor  în  care  sunt  prelucrate  datele;  altfel,  datele  sunt  șterse  sau anonimizate;",
	"Integrity and confidentiality": "Integritate și confidențialitate",
	"we ensure appropriate security by implementing organizational measures and adequate technical solutions which are harmoniously combined as to guard personal data against unauthorized or unlawful processing and against accidental loss, destruction or damage;":
		"asigurăm securitatea adecvată a datelor cu caracter personal prin luarea unor măsuri tehnice sau organizatorice corespunzătoare, combinate armonios în vederea protejării datelor cu caracter personal  împotriva prelucrării  neautorizate  sau  ilegale și  împotriva  pierderii,  a  distrugerii  sau  a deteriorării accidentale;",
	"Accountability": "Responsabilitate",
	"we recognize our responsibility for ensuring the lawful processing of personal data.":
		"recunoaștem că  suntem  responsabili  pentru  asigurarea prelucrării  în  legalitate  a  datelor  cu caracter personal.",
	"Your personal information belongs to you and we respect this. It is your right to be fully informed about the processing operations we perform with the personal data you provide us or we collect about you. In order to make available to you all this information in a way that is as accessible and concise as possible, we have drafted this privacy policy (“Privacy Policy”) applicable to online personal data processing operations.":
		"Informațiile  cu  caracter  personal  sunt  proprietatea  dumneavoastră și respectăm  acest  lucru.  Aveți  dreptul  la  o informare detaliată cu privire la operațiunile de prelucrare la care sunt supuse datele cu caracter personal pe care ni le puneți  la  dispoziție  sau  pe  care  le  colectăm.  Am  redactat  prezenta  politică  de  confidențialitate  („Politica  de confidențialitate”), aplicabilă operațiunilor  de  prelucrare  online  a  datelor  cu  caracter  personal,  pentru  a vă  pune  la dispoziție toate aceste informații, într-o formă cât mai accesibilă și cât mai succintă.",
	"Thus, this Privacy Policy gives you detailed information on the personal data we process, how we collect it, the purposes for which we use personal data, and how we keep it safe. This Privacy Policy also describes what your rights as data subject are, so please review it alongside the Terms and Conditions section.":
		"Prin urmare, prin prezenta Politică de confidențialitate vi se oferă informații detaliate cu privire la datele cu caracter personal  pe  care  le prelucrăm,  modul  în  care  le colectăm,  scopurile  în  care  le utilizăm și  modul  în  care asigurăm securitatea  lor.  În  prezenta Politică  de confidențialitate  sunt  descrise și  drepturile dumneavoastră  în  calitate  de persoană vizată,așadar vă rugăm să o parcurgeți, împreună cu secțiunea „Termeni și condiții”.",
	"To facilitate your understanding of this Privacy Policy, please find below definitions and explanations of the specific notions used:":
		"În cele ce urmează puteți regăsi definițiile și explicațiile termenilor specifici utilizați, în vederea facilitării înțelegerii prezentei Politici de confidențialitate:",
	"Personal data": "Datele mele personale GDPR ",
	"any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.":
		"orice  informații  privind  o  persoană fizică identificată  sau  identificabilă  („persoana vizată”); o persoană fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în special prin referire la un element de identificare, cum ar fi un nume, un număr de identificare, date de localizare, un identificator online, sau la unul sau mai multe elemente   specifice,   proprii  identității   sale   fizice,   fiziologice,   genetice,   psihice, economice, culturale sau sociale.",
	"Data subject": "Persoana vizată",
	"an identified or identifiable natural person whose personal data is processed.":
		"o persoană fizică identificată  sau identificabilă,  ale cărei  date  cu  caracter  personal  se prelucrează.",
	"Processing": "Prelucrare",
	"means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.":
		"înseamnă  orice operațiune  sau  set  de operațiuni  efectuate  asupra  datelor  cu  caracter personal  sau  asupra  seturilor  de  date  cu  caracter  personal,  cu  sau fără  utilizarea  de mijloace  automatizate,  cum  ar  fi  colectarea,  înregistrarea,  organizarea,  structurarea, stocarea,  adaptarea  sau  modificarea,  extragerea,  consultarea,  utilizarea,  divulgarea  prin transmitere,  diseminarea  sau  punerea  la  dispoziție  în  orice  alt  mod,  alinierea  sau combinarea, restricționarea,ștergerea sau distrugerea;",
	"Controller": "Operator",
	"the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data.":
		"persoana fizică sau juridică, autoritatea publică, agenția sau alt organism care, singur sau împreună cu altele, stabilește scopurile și mijloacele de prelucrare a datelor cu caracter personal.",
	"Processor": "Persoana împuternicită de operator",
	"a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.":
		"persoana fizică sau juridică, autoritatea publică,agenția sau alt organism care prelucreazădatele cu caracter personal în numele operatorului.",
	"Recipient": "Destinatar",
	"a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not.":
		"persoana fizică sau juridică, autoritatea publică, agenția sau alt organism căreia (căruia) îi sunt divulgate datele cu caracter personal, indiferent dacă este sau nu o parte terță",
	"Consent": "Consimțământ",
	"Freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.":
		"orice  manifestare  de  voință liberă, specifică, informată și lipsită  de  ambiguitate  a persoanei  vizate  prin  care  aceasta acceptă,  printr-o declarație  sau  printr-o acțiune fără echivoc, ca datele cu caracter personal care o privesc să fie prelucrate.",
	"Online identifiers": "Identificatori online",
	"internet protocol addresses, cookie identifiers or other identifiers such as radio frequency identification tags provided by data subject devices, applications, tools and protocols. These may leave traces which, in particular when combined with unique identifiers and other information received by the servers, may be used to create profiles of natural persons and identify them.":
		"adresele IP, identificatorii cookie sau alți identificatori precum etichetele de identificare prin frecvențe radio pe care persoanele fizice îi furnizează, prin dispozitivele, aplicațiile, instrumentele și protocoalele lor. Aceștia pot lăsa urme care, în special atunci când sunt combinate  cu  identificatori  unici și  alte informații  primite  de  servere,  pot  fi  utilizate pentru crearea de profiluri ale persoanelor fizice și pentru identificarea lor.",
	"Who we are?": "Cine suntem noi?",
	"Your personal data are being processed by PLAY ONLINE SOLUTIONS LTD, acting as controller of your personal data:":
		"Datele dumneavoastră cu caracter personal sunt prelucrate de PLAY ONLINE SOLUTIONS LTD, care acționează în calitate de operator al datelor dumneavoastră cu caracter personal:",
	"PLAY ONLINE SOLUTIONS LTD": "PLAY ONLINE SOLUTIONS LTD",
	"is a Maltese limited liability company, registered in ${countryName} under number C59636, having its registered office in 3 Advance Business Centre, Triq G. Flores, St. Venera SVR1950, Malta":
		"este o companie malteză cu răspundere limitată,înregistrată în ${countryName} cu numărul C59636, având sediul social în Elite Business Centre, Trejqa Ta’ Box Box, Msida MSD 1840, Malta",
	"How can you contact us?": "Cum ne puteți contacta?",
	"In order to ask us questions about this Privacy Policy or to submit us request for the exercise of your rights as data subject, please write to us or call us using the following contact details":
		"Pentru a ne adresa întrebări cu privire la prezenta Politică de confidențialitate sau pentru a face o solicitare cu privire la  exercitarea  drepturilor dumneavoastră  în  calitate  de persoanăvizată,  ne puteți  contacta  în  scris  sau  telefonic, utilizând următoarele date de contact:",
	"E-mail address": "Adresa email:",
	"Headquarters address": "Adresa sediului:",
	"4 Șoseaua Pipera| 1st District | Bucharest| Romania":
		"Șoseaua Pipera nr 4| Sector 1| București| Romania",
	"Phone number": "Telefon mobil:",
	"Contact person": "Persoana de contact:",
	"Mrs. Irina Crăciun, appointed Data Protection Officer":
		"Irina Crăciun, Responsabil cu protecția datelor desemnat",
	"What personal data do we process, when and for what purposes?":
		"Ce date cu caracter personal prelucrăm, când prelucrăm și în ce scop?",
	"PERSONAL DATA PROCESSED WHEN YOU CARRY OUT YOUR ACTIVITY ON OUR WEBSITE":
		"Date cu caracter personal pe care le prelucrăm când ne accesați website-ul",
	"We collect your personal information when you register your game account by using online forms and when you provide your details and documents by email. We also collect information about the transactions you make, excluding the details of the cards used to make the payments:":
		"Colectăm informațiile  dvs.  personale  atunci  când vă înregistrați  contul  de  joc  folosind  formulare  online și  când vă furnizați  datele și  documentele  prin  e-mail.  De  asemenea, colectăm informații  despre tranzacțiile  pe  care  le faceți, excluzând detaliile cardurilor utilizate pentru efectuarea plăților:",
	"The data included in your ID card,": "Datele incluse în cartea de identitate,",
	"Copy of your ID card,": "Copia cărții de identitate,",
	"Copy of your Utility Bill,": "Copie după factura de utilități,",
	"When registering on our website, your browser could automatically send us information about:":
		"Când accesați website-ul nostru, este posibil ca browser-ul dumneavoastră să ne trimită automat informații cu privire la:",
	"IP address of your device,": "adresa IP a dispozitivului dumneavoastră,",
	"Date and time of access,": "data și ora accesării,",
	"used browser,": "browser-ul pe care l-ați utilizat,",
	"the operating system of your device,": "sistemul de operare al dispozitivului dumneavoastră,",
	"information in relation to your Internet service provider,":
		"informații cu privire la furnizorul dumneavoastră de servicii de internet,",
	"status and amount of data transferred during the visit of our websites.":
		"status-ul și cantitatea de date transferate în timpul accesării website-ului nostru.",
	"We process the mentioned data for the following purposes:":
		"Prelucrăm datele menționate în următoarele scopuri:",
	"to comply with legal requirements applicable to online gambling,":
		"în vederea respectării cerințelor legale aplicabile jocurilor de noroc online,",
	"to set up and manage your account and data (including processing data on deposits and withdrawals),":
		"în  vederea configurării și gestionării  contului și  datelor  dvs.  (inclusiv  procesarea  datelor  privind depozitele și retragerile),",
	"receive and answer to communications and requests from you or Legal Authorities,":
		"în vederea primirii și formulării răspunsului la comunicările și cererile de la dvs. sau de la autoritățile legale,",
	"to notify you of any updates about the Software and / or Services,":
		"în vederea notificării cu privire la orice actualizări despre software și / sau servicii,",
	"to ensure, including through periodic reviews of the information you provide us, that the activity on the gaming platform is conducted in accordance with mandatory legal provisions,":
		"în  vederea asigurării,  inclusiv  prin  analize  periodice  a informațiilor  pe  care  ni  le furnizați, că activitatea de pe platforma de jocuri se desfășoară în conformitate cu dispozițiile legale obligatorii,",
	"investigate and assist the competent authorities in analyzing any activity that is suspected of being illegal, fraudulent or inappropriate in relation to the Services,":
		"în vederea investigării și oferirea suportului necesar autorităților competente în analizarea oricărei activități suspectate de a fi ilegale, frauduloase sau necorespunzătoare în legătură cu Serviciile;",
	"to conduct promotional campaigns and market studies,":
		"pentru desfășurarea campaniilor promoționale și studiilor de piață,",
	"to draft statistics on your use of the Services by you and other clients,":
		"în vederea elaborării statisticilor privind utilizarea serviciilor dvs. de către dvs. și alți clienți,",
	"to periodically notify you on information on the available Services and Campaigns, change of terms applicable to Casino activity, technical updates and changes to Terms & Conditions,":
		"pentru  a vă anunța  periodic  cu  privire  la informațiile  despre  Serviciile și  Campaniile  disponibile, modificarea  condițiilor  aplicabile  activității  cazinoului,  actualizări  tehnice  și modificări  ale Termenilor și condițiilor,",
	"to ensure the fulfillment of our contractual obligations towards the participants,":
		"în vederea asigurării îndeplinirii obligațiilor noastre contractuale față de participanți,",
	"to ensure a smooth connection to our website and proper use of our website,":
		"pentru a asigura o conexiune optimă la site-ul nostru web și utilizarea corectă a site-ului nostru web,",
	"for evaluating system security and stability,":
		"pentru evaluarea securității și stabilității sistemului,",
	"for further administrative purposes.": "în alte scopuri administrative.",
	"The grounds of processing such data are the performance of the contract for providing you our services and our legitimate interest to ensure that our website functions adequately.":
		"Temeiul  legal  pentru  prelucrarea  acestor  date  este  reprezentat  de  executarea  contractului  prin  care vă  punem  la dispoziție website-ul nostru, precum și de interesul nostru legitim de a asigura funcționarea corespunzătoare a website-ului).",
	"Also, when visiting our website, we install cookies and other tracking technologies on your device and use analysis services. For further details, please refer to section 10 hereto representing our Cookie Policy.":
		"În plus, la accesarea website-ului nostru, instalăm module „cookie” și alte tehnologii de monitorizare pe dispozitivul dumneavoastră și utilizăm servicii de analiză. Pentru informații suplimentare, vă rugăm să consultați secțiunea 10 din prezentul document, care prezintă Politica noastră cu privire la Modulele „Cookie”.",
	"PERSONAL DATA PROCESSED WHEN REGISTERING AND LEAVING COMMENTS ON OUR CHAT":
		" DATE PERSONALE PROCESATE CÂND VĂ ÎNREGISTRAȚI ȘI LASAȚI COMENTARII PE CHAT-ul NOSTRU",
	"When registering or leaving comments on our online chat your browser will provide us with the following information:":
		"Când înregistrați sau lăsați comentarii pe chat-ul nostru online, browser-ul nostru ne va oferi următoarele informații:",
	"username": "Nume de utilizator",
	"name,": "numele,",
	"e-mail address,": "adresa e-mail;",
	"date": "data",
	"time": "timp",
	"location": "Agenție",
	"comment,": "comentariu,",
	"your site URL.": "adresa URL a site-ului dvs.",
	"IP address": "Adresa IP",
	"We process such data exclusively for operating the chat section of our website and for granting you the possibility to interact with our support team, based on our legitimate interests to obtain feedback and to help you in your online activity, by interacting with our staff.":
		"Procesăm  astfel  de  date  exclusiv  pentru  operarea  secțiunii  de  chat  a  site-ului  nostru  web  și  pentru  a  vă  oferi posibilitatea  de  a interacționa  cu  echipa noastră  de asistență,  pe  baza  intereselor  noastre  legitime  pentru  a obține feedback și pentru a vă ajuta în activitatea dvs. online, interacționând cu personalul nostru.",
	"We draw your attention to the fact the comments are a public section, therefore you should avoid disclosing personal information in your comments.":
		"Vă atragem atenția asupra faptului că comentariile sunt o secțiune publică, prin urmare, ar trebui să evitați divulgarea informațiilor personale în comentariile dvs.",
	"We shall keep the personal data above up to a period of 1 year.":
		"Vom păstra datele personale mai sus până la o perioadă de 1 an.",
	"PROCESSING OF SENSITIVE DATA OR MINORS’ DATA":
		"Prelucrarea datelor sensibile sau ale datelor cu privire la minori ",
	"We shall not collect through our website sensitive information, unless legally required for specific purposes.":
		"Nu vom colecta date sensibile prin intermediul website-ului nostru, cu excepția situației în care legislația impune acest lucru în mod specific.",
	"Our website and its content is not intended for or addressed to minors. Thus, we shall not deliberately collect or maintain personal data about minors.":
		"Website-ul  nostru și conținutul  aferent  nu  se adresează  minorilor.  Prin  urmare,  nu  vom  colecta  sau păstra,  în  mod intenționat, date cu caracter personal despre minori.",
	"On what grounds do we process personal data?":
		"În baza cărui temei prelucrăm date cu caracter personal?",
	"When processing your personal data, we rely on the following legal grounds:":
		"Prelucrăm datele dumneavoastră cu caracter personal în baza următoarelor temeiuri legale:",
	"Consent, as per article 6 (1) a) GDPR":
		"Acordarea consimțământului,  conform  Articolului  6  alin.  (1),  lit.  a)  din  RGPD ",
	"– we may (but usually do not) need your consent to use your personal information. You can exercise your right of consent withdrawal by contacting us as described below.":
		"-  este  posibil  (dar  de obicei  nu  este  cazul)  să  avem  nevoie  de  consimțământul dumneavoastră  pentru  a  utiliza  informațiile dumneavoastră cu caracter personal. Vă puteți exercita dreptul de retragere a consimțământului, contactându-ne în modul descris în cele ce urmează.",
	"Performance of a contract ": "Executarea  unui  contract ",
	"or to take steps at your request prior to entering into a contract with us, as per article 6 (1) b) GDPR – we may need to collect and use your personal information in order to take steps for the conclusion of a contract, to conclude a contract with you (Terms and Conditions), to perform our obligations under a contract with you or otherwise execute the contract. The Contract referred to herein is the Terms and Conditions on our website.":
		"sau  luarea  unor măsuri  solicitate  de dumneavoastră  înainte  de  semnarea unui  contract  cu  noi,  conform  Articolului  6  alin.  (1),  lit.  b)  din  RGPD  -  este  posibil să  fie  necesar să colectăm și să utilizăm informațiile dumneavoastră cu caracter personal în vederea efectuării demersurilor pentru  încheierea  unui  contract,  pentru  încheierea  unui  contract  cu dumneavoastră  (Termeni și Condiții), pentru îndeplinirea obligațiilor noastre contractuale față de dumneavoastră sau pentru derularea în alt mod a contractului. Contractul la care se face referire în prezentul document este Termeni și condiții de pe site-ul nostru web.",
	"Compliance with law or regulation, as per article 6 (1) c) GDPR":
		"Conformitatea cu legislația sau regulamentul, conform Articolului 6 alin. (1), lit. c) din RGPD",
	"– we may use your personal data in order to comply with an applicable law or regulation.":
		"- este posibil să utilizăm  datele dumneavoastră  cu  caracter  personal  în  vederea respectării  normelor  legale  sau reglementărilor aplicabile.",
	"Legitimate interest, as per article 6 (1) f) GDPR":
		"Interesul legitim, conform Articolului 6 alin. (1), lit. f) din RGPD",
	"– we may use your personal information for our legitimate interests, some examples of which are given above.":
		"- este posibil să utilizăm informațiile dumneavoastră  cu  caracter  personal  în  scopul  îndeplinirii  intereselor  noastre  legitime,  printre  care și  cele enumerate anterior.",
	"To whom do we disclose or transfer personal data?":
		"Cui divulgăm sau transferăm date cu caracter personal?",
	"Your personal information will be mainly disclosed to our employees from specific departments and to the companies that are providers of PLAY ONLINE SOLUTIONS LTD, as it will prove to be necessary.":
		"În  principal,  informațiile dumneavoastră  cu  caracter  personal  vor  fi  divulgate  angajaților noștri  din  anumite departamente și societăților care sunt furnizori ai PLAY ONLINE SOLUTIONS LTD, în funcție de necesități.",
	"When justified and/or necessary, we may also share your personal information outside our company. This may include:":
		"În situațiile în care acest lucru este justificat și/sau necesar, este posibil să distribuim informațiile dumneavoastră cu caracter personal și în afara companiei. Printre posibilii destinatari se numără următoarele categorii:",
	"Third party agents/suppliers or contractors bound by obligations of confidentiality. This may include, without limitation, IT and communications service providers.":
		"Agenți terți/furnizori  sau contractanți, aflați  sub incidența obligației păstrării confidențialității .Această categorie poate include, fără a se limita la, furnizorii de servicii IT și de comunicare.",
	"Third parties relevant to the services that we provide. This may include, without limitation, counterparties to online gaming, public authorities and institutions, as empowered by the law to request information on personal data collected during PLAY ONLINE SOLUTIONS LTD’s specific activity.":
		"Terțe părți  relevante  pentru  serviciile  pe  care  le prestăm. Această  categorie  poate  include, fără  limitare, contrapartide la jocurile online, autoritățile și instituțiile publice, astfel cum este împuternicită prin lege să solicite informații  cu  privire  la  datele  cu  caracter  personal  colectate  în  timpul activității  specifice  PLAY ONLINE SOLUTIONS LTD.",
	"To the extent required by law, search warrant or court order, for example, if we are under a duty to disclose your personal information in order to comply with any legal obligation.":
		"În măsura impusă prin lege, sub incidența unui mandat de percheziție sau a unei hotărâri judecătorești, spre exemplu, dacă  suntem obligați să divulgăm informațiile dumneavoastră  cu  caracter  personal  în  vederea respectării oricărei obligații legale.",
	"In case it will be necessary to transfer your personal information outside the European Economic Area, we will ensure that it is protected and transferred in a manner consistent with legal requirements, including the following:":
		"În  cazul  în  care  va  fi  necesar să transferăm informațiile dumneavoastră  cu  caracter  personal  în  afara Spațiului Economic  European,  ne  vom  asigura că  acestea  sunt  protejate și  transferate  într-un  mod  care să  respecte cerințele legale, inclusiv după cum urmează:",
	"the European Commission has issued a decision recognizing the adequate character of data protection in the envisaged third country or where the recipient is located in the US, it may be a certified member of the EU-US Privacy Shield scheme;":
		"Comisia Europeană a emis o decizie prin care recunoaște caracterul adecvat al nivelului de protecție al datelor din statul terț preconizat pentru transfer sau în cazul în care destinatarul este localizat în SUA, acesta poate fi un membru certificat prin planul Scutului de confidențialitate UE-SUA;",
	"the recipient has signed a contract based on “standard contractual clauses” approved by the European Commission, obliging them to protect your personal information, or":
		"Destinatarul a semnat un contract pe bază de „clauze contractuale standard”, aprobat de Comisia Europeană, prin care se obligă să protejeze informațiile dumneavoastră cu caracter personal, sau",
	"we have obtained your prior explicit consent.":
		"Am obținut consimțământul dumneavoastră explicit în prealabil.",
	"In all cases, however, any transfer of your personal information will be compliant with applicable data protection law.":
		"Cu  toate  acestea,  în  toate situațiile,  orice  transfer  al informațiilor dumneavoastră  cu  caracter  personal  va  respecta legislația aplicabilă în materie de protecție a datelor.",
	"You can obtain more details of the protection given to your personal information in case of transfer outside the European Economic Area (including a sample copy of the standard contractual clauses) by contacting us using the details set in section 3 above.":
		"Puteți primi mai multe detalii despre protecția acordată informațiilor dumneavoastră cu caracter personal, în caz de transfer  în  afara  Spațiului  Economic  European  (inclusiv  o  mostră  din  clauzele  contractuale  standard),  dacă  ne contactați utilizând informațiile din Secțiunea 3 de mai sus.",
	"Where and for how long do we store personal data?":
		"Unde și pentru cât timp stocăm datele cu caracter personal?",
	"Your personal data is stored by PLAY ONLINE SOLUTIONS LTD mainly on servers located within the European Economic Area.":
		"Datele dumneavoastră  cu  caracter  personal  sunt  stocate,  preponderent,  pe  servere  localizate  în Spațiul  Economic European.",
	"We process and retain personal data only for as long as is necessary to fulfil our purposes, contractual obligations and other legal obligations of storage / archiving, as the case may be.":
		"Prelucrăm și reținem datele dumneavoastră cu caracter personal numai atât timp cât ne este necesar pentru îndeplinirea scopurilor, a obligațiilor contractuale și a altor obligații legale în materie de stocare/arhivare, după caz.",
	"We shall retain the data only for as long as is necessary and / or prescribed by law for that purpose. For example:":
		"Vom reține datele numai atât timp cât este necesar și/sau stipulat în legislație pentru scopul respectiv. De exemplu:",
	"Data processed for anti-money laundering purposes and supporting documents will be kept for a period of 5 years, as the case may be, according to the Anti-money LaunderingLaw no. 589/18.07.2019;":
		"Datele prelucrate în vederea facturării și documentele contabile justificative se vor păstra pe o perioadă de 5 ani, după caz, conform Legii pentru prevenirea spălării banilor 589/18.07.2019;",
	"Data processed under your consent will be processed during the validity period of your consent or until you choose to withdraw your consent, or the data is no longer necessary;":
		"Datele   prelucrate   cu  consimțământul dumneavoastră   se   vor   prelucra   în   perioada   de   valabilitate   a consimțământului dumneavoastră sau până când alegeți să vă retrageți consimțământul sau până când datele nu mai sunt necesare;",
	"Data processed under our legitimate interest will be processed for a maximum period of 1 year, after which it will be anonymized and processed for statistical purposes.":
		"Datele prelucrate în interesul nostru legitim se vor prelucra pe o perioadă de maximum 1 an, după care vor fi anonimizate și prelucrate în scopuri statistice.",
	"What are your rights as data subject?":
		"Care sunt drepturile dumneavoastră în calitate de persoană vizată?",
	"RIGHT OF ACCESS": "Dreptul de acces",
	"Upon your request, we will confirm that we process your personal data and, if so, we will provide you with a copy of your personal data that is subject to our processing and the following information:":
		"La solicitarea dumneavoastră, vă vom confirma că vă prelucrăm datele cu caracter personal și, în acest caz, vă vom pune  la dispoziție  o  copie  a  datelor dumneavoastră  cu  caracter  personal  care  sunt  supuse prelucrării,  precum și următoarele informații:",
	"the purposes of the processing;": "scopurile prelucrării;",
	"the categories of personal data concerned;":
		"categoriile de date cu caracter personal avute în vedere;",
	"the recipients or categories of recipients to whom personal data has been or is to be disclosed, in particular recipients from third countries or international organizations;":
		"destinatarii sau categoriile de destinatari cărora le-au fost sau le vor fi divulgate date cu caracter personal, în special destinatarii din state sau organizații internaționale terțe;",
	"where possible, the period for which personal data are to be stored or, if that is not possible, the criteria used to determine that period;":
		"dacă  este  posibil,  perioada  de  stocare  a  datelor  cu  caracter  personal,  iar dacă  nu  este  posibil,  criteriile  de stabilire a respectivei perioade;",
	"the existence of the right to require the operator to rectify or erase personal data or to restrict the processing of personal data relating to the data subject or the right to object to processing;":
		"existența  dreptului  de  a  solicita  operatorului să  rectifice  sau să șteargă  datele  cu  caracter  personal  sau să restricționeze prelucrarea datelor cu caracter personal, cu privire la persoana vizată, sau existența dreptului de a se opune prelucrării;",
	"the right to lodge a complaint with a supervisory authority;":
		"dreptul de a depune plângere la autoritatea de supraveghere;",
	"where personal data are not collected from the data subject, any available information on their source;":
		"în cazul în care datele cu caracter personal nu sunt colectate de la persoana vizată, orice informații disponibile despre proveniența acestora;",
	"the existence of an automated decision-making process including the creation of profiles and, in those cases, relevant information on the logic used and the significance and expected consequences of such a processing for the data subject.":
		"existența  unui  proces  decizional  automatizat  care  include  crearea  de  profiluri  și,  cel  puțin  în  cazurile respective, a unor informații pertinente privind logica utilizată, dar și importanța și consecințele preconizate ale unei astfel de prelucrări pentru persoana vizată.",
	"If we transfer your data outside of the European Economic Area or to an international organization you have the right to be informed of the appropriate safeguards applied.":
		"Dacă transferăm datele dumneavoastră în afara Spațiului Economic European sau către o organizație internațională, aveți dreptul să fiți informat(ă) cu privire la garanțiile adecvate aplicate.",
	"The first copy of your personal data is provided free of charge. For additional specimens, we may charge a reasonable additional charge, taking into account the related administrative costs.":
		"Prima copie a datelor dumneavoastră cu caracter personal vă este pusă la dispoziție gratuit. Pentru copii suplimentare, este posibil să percepem o taxă suplimentară rezonabilă, în funcție de costurile administrative aferente.",
	"RIGHT TO RECTIFICATION AND/OR COMPLETION OF PERSONAL DATA":
		"Dreptul la rectificarea și/sau completarea datelor cu caracter personal",
	"If the data we hold about you is inaccurate or incomplete, you are entitled to correct it or to complete. In order to do so, you can submit a request using the contact details provided above. Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your rectification or completion of your data. Upon your request, we will inform you of those recipients.":
		"În cazul în care datele pe care le avem despre dumneavoastră sunt inexacte sau incomplete, aveți dreptul să solicitați corectarea  sau  completarea  acestora.  În  acest  sens, puteți  face  o  solicitare,  utilizând  datele  de  contact menționate anterior.  Cu excepția situației  în  care  acest  lucru  se dovedește  imposibil  sau  impune  eforturi  mult  prea  mari,  vom înștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la rectificarea sau completarea acestora. La cerere, vă vom informa care sunt respectivii destinatari.",
	"In order to keep personal data accurate, we may request you to reconfirm/renew your personal data from time to time.":
		"În  vederea actualizării și  a păstrării exactității  datelor,  este  posibil să vă solicităm  periodic să reconfirmați/ să reactualizați datele dumneavoastră cu caracter personal.",
	"RIGHT TO ERASURE OF PERSONAL DATA (“RIGHT TO BE FORGOTTEN”)":
		"Dreptul la ștergerea datelor („dreptul de a fi uitat”)",
	"You may ask us to delete your personal information and we will respond to your request without undue delay, if one of the following situation applies:":
		"Este posibil să ne solicitați să ștergem informațiile dumneavoastră cu caracter personal și vom răspunde neîntârziat unei astfel de solicitări, în oricare dintre următoarele situații:",
	"Data are no longer required for the purposes for which they were collected or processed;":
		"Datele nu mai sunt necesare în scopul pentru care au fost colectate sau prelucrate;",
	"You withdraw consent to the processing of your data when your data processing is based on your consent and there is no other legal basis on which to process your personal data;":
		"Vă retrageți consimțământul pentru prelucrare a datelor, în situația în care prelucrarea datelor dumneavoastră se întemeiază pe consimțământ și nu există niciun alt temei legal pentru prelucrarea datelor dumneavoastră cu caracter personal;",
	"You oppose the processing of your data on our legitimate interest, including the creation of profiles based on this ground, or you oppose data processing for direct marketing purposes, including the creation of profiles for direct marketing purposes;":
		"Vă opuneți prelucrării datelor dumneavoastră în interesul nostru legitim, inclusiv creării de profiluri în baza acestui temei, sau vă opuneți prelucrării datelor în scopuri de marketing direct, inclusiv creării de profiluri care să aibă legătură cu scopurile de marketing direct;",
	"your data has been processed unlawfully;": "Datele dumneavoastră au fost prelucrate ilegal;",
	"Personal data should be deleted to comply with a legal obligation under Union law or national law;":
		"Datele  cu  caracter  personal  ar  trebui șterse,  în  vederea respectării obligațiilor  legale  impuse  prin  dreptul Uniunii sau prin dreptul intern;",
	"Personal data have been collected in connection with the provision of information services to children and the basis of processing is consent.":
		"Datele cu caracter personal au fost colectate în legătură cu furnizarea de servicii informative către copii, iar temeiul prelucrării îl constituie consimțământul.",
	"Unless this proves impossible or involves disproportionate efforts, we will notify each recipient to whom your data has been disclosed of your deletion of your data. Upon your request, we will inform you of those recipients.":
		"Cu excepția situației  în  care  acest  lucru  se dovedește  imposibil  sau  impune  eforturi  mult  prea  mari,  vom înștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la ștergerea acestora. La cerere, vă vom informa care sunt respectivii destinatari.",
	"We reserve the right to refuse deletion your data when processing is required for:":
		"Ne rezervăm dreptul de a refuza ștergerea datelor, în situațiile în care prelucrarea este necesară pentru:",
	"For the exercise of the right to free expression and information;":
		"exercitarea dreptului la liberă exprimare și informare;",
	"In order to comply with a legal obligation that applies to us as a personal data operator;":
		"respectarea unei obligații legale aplicabile nouă, în calitate de operator de date cu caracter personal;",
	"for purposes of archiving in the public interest, scientific or historical research or for statistical purposes, insofar as the deletion of the data is likely to render impossible or seriously impair the achievement of the objectives of the processing;":
		"arhivarea în interes public, științific sau în scopuri statistice sau care țin de cercetare istorică, atât timp cât există probabilitatea ca ștergerea datelor să facă imposibilă sau afecteze în mod grav realizarea obiectivelor prelucrării;",
	"To establish, exercise or defend a right in court.":
		"constatarea, exercitarea sau apărarea unui drept în instanță.",
	"RIGHT TO RESTRICTION OF PROCESSING": " Dreptul la restricționarea prelucrării",
	"You may ask us to block and restrict the processing of your personal information if one of the situations below applies:":
		"Ne puteți solicita să blocăm sau să restricționăm prelucrarea informațiilor dumneavoastră cu caracter personal, în cazul în care se aplică una dintre următoarele situații:",
	"Contest the accuracy of the data – in this case, at your request, we will restrict the processing for the period we perform the necessary checks on the accuracy of your data;":
		"Contestați  exactitatea  datelor  -  în  acest  caz,  la  solicitarea dumneavoastră,  vom restricționa  prelucrarea  pe perioada în care efectuăm demersurile necesare verificării exactității datelor dumneavoastră;",
	"data processing is illegal, and you do not want to delete your data;":
		"Prelucrarea datelor este ilegală și nu doriți să ștergeți datele dumneavoastră;",
	"We no longer need your data for processing, but processed data about you is necessary to establish, exercise or defend a right in court;":
		"Nu mai avem nevoie de datele dumneavoastră pentru prelucrare, însă datele despre dumneavoastră prelucrate sunt necesare în vederea constatării, exercitării sau apărării unui drept în instanță;",
	"You opposed your processing of your data under our legitimate interest, including the creation of profiles based on this basis – in this case, at your request, we will restrict the processing for the period in which we verify that our legitimate rights do not prevail over your rights.":
		"-ați  opus prelucrării  datelor dumneavoastră  sub incidența  interesului  nostru  legitim,  inclusiv creării  de profiluri pe această bază - în acest caz, la solicitarea dumneavoastră, vom restricționa prelucrarea pe perioada cât verificăm dacă drepturile noastre legitime nu prevalează asupra drepturilor dumneavoastră.",
	"If your data processing has been restricted, we will only be able to store your data. Any other way of processing out of storage will be done only:":
		"Dacă s-a restricționat prelucrarea datelor dumneavoastră, noi vom putea numai să stocăm datele dumneavoastră. Orice alt tip de prelucrare, în afară de stocare, se va realiza numai:",
	"after obtaining your consent;": "ulterior obținerii consimțământului dumneavoastră;",
	"for finding, exercising or defending a right in court;":
		"în vederea constatării, exercitării sau apărării unui drept în instanță.",
	"to protect the rights of another natural or legal person;":
		"în vederea apărării drepturilor unei alte persoane fizice sau juridice;",
	"for reasons of public interest of the Union or of a Member State.":
		"din motive de interes public al Uniunii sau al unui Stat Membru.",
	"We will inform you before lifting any processing restriction as set out above.":
		"Vă vom informa înainte de ridicarea oricăror restricții de prelucrare stabilite în modurile enunțate anterior.",
	"Unless this proves impossible or involves disproportionate efforts, we will communicate to each recipient to whom your data has been disclosed restricting the processing of such data. At your request, we will inform you of those recipients.":
		"Cu excepția situației  în  care  acest  lucru  se dovedește  imposibil  sau  impune  eforturi  mult  prea  mari,  vom înștiința fiecare destinatar căruia i-au fost divulgate datele dumneavoastră cu privire la restricționarea prelucrării acestora. La cerere, vă vom informa care sunt respectivii destinatari.",
	"RIGHT TO DATA PORTABILITY": "Dreptul la portabilitatea datelor",
	"You have the right to receive the data that concerns you and that you have provided us with in order to transmit such data to another controller, in the following circumstances:":
		"Aveți dreptul de a primi datele cu caracter personal care vă privesc și pe care ni le-ați furnizat, în vederea transmiterii acestora către un alt operator, în următoarele împrejurări:",
	"Your data processing is based on your consent or on a contract between us and you;":
		"Prelucrarea  datelor dumneavoastră  are  la bază consimțământul dumneavoastră  sau  un  contract  încheiat  cu noi;",
	"Your data is processed by automatic means.":
		"Prelucrarea datelor dumneavoastră se efectuează prin mijloace automatizate.",
	"We will provide your personal data in a structured, commonly used and machine-readable format.":
		"Vă vom pune la dispoziție datele dumneavoastră cu caracter personal într-un format structurat, utilizat în mod curent și care poate fi citit automat.",
	"If technically feasible, you can request that your personal data be transmitted directly to the controller indicated by you.":
		"În cazul în care acest lucru este fezabil din punct de vedere tehnic, puteți solicita ca datele cu caracter personal să fie transmise direct operatorului indicat de dumneavoastră.",
	"RIGHT TO OBJECT AND AUTOMATED INDIVIDUAL DECISION-MAKING":
		"Dreptul la opoziție și procesul decizional individual automatizat",
	"You may request us not to further process your personal information for reasons relating to your particular circumstances and if the processing of your data is based on our legitimate interest. We will cease processing of your data unless we demonstrate that we have legitimate and compelling reasons that justify processing and those reasons prevail over your interests, rights and freedoms, or whether the purpose of the processing is to establish, exercise or defend a right in court.":
		"Aveți dreptul de a ne solicita să nu mai prelucrăm informațiile dumneavoastră cu caracter personal din motive legate de situația particulară în care vă aflați și dacă prelucrarea datelor dumneavoastră are la bază interesul nostru legitim. Nu  vom  mai  prelucra  datele dumneavoastră  cu  caracter  personal,  cu excepția  cazului  în  care demonstrăm că  avem motive legitime și imperioase, care justifică prelucrarea și care prevalează asupra intereselor, drepturilor și libertăților dumneavoastră, sau că scopul prelucrării este constatarea, exercitarea sau apărarea unui drept în instanță.",
	"In addition to the above, you may request that we no longer process your personal data for direct marketing purposes, including the creation of profiles related to that direct marketing.":
		"În plus față de cele menționate anterior, puteți să solicitați să nu vă mai prelucrăm datele cu caracter personal în scopuri de marketing direct, inclusiv crearea de profiluri care țin de marketing direct.",
	"RIGHTS IN RELATION TO AUTOMATED INDIVIDUAL DECISION-MAKING, INCLUDING PROFILING":
		" Drepturi care țin de procesul decizional individual automatizat, inclusiv crearea de profiluri",
	"You have the right not to be subject to a decision when it is based on automatic processing, including not being profiled, if the automatic decision or profiling has legal effects or significantly affects you, except in the following cases:":
		"Aveți dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată, inclusiv crearea de profiluri, care produce efecte juridice care vă afectează semnificativ, cu următoarele excepții:",
	"automatic decision is required to conclude or execute a contract between you and us;":
		"decizia bazată pe prelucrare automată este necesară pentru încheierea sau executarea unui contract cu noi;",
	"the automatic decision is authorized by European Union or national law applicable to PLAY ONLINE SOLUTIONS LTD and also provides for appropriate measures to protect the legitimate rights, freedoms and interests of the data subject;":
		"decizia bazată pe prelucrare automată este autorizată prin dreptul Uniunii Europene sau prin dreptul intern care  se aplică  PLAY  ONLINE  SOLUTIONS  LTD și  care  prevede,  de  asemenea, măsuri corespunzătoare pentru protejarea drepturilor, libertăților și intereselor legitime ale persoanei vizate;",
	"Automatic decision is based on your express consent.":
		"decizia bazată pe prelucrare automată are la bază consimțământul dumneavoastră explicit.",
	"If the cases indicated in (1) and (3) above apply, you may request that you exercise the following correlative rights:":
		"Dacă  se aplică situațiile  descrise  anterior  la  punctele  (1) și  (3), puteți  solicita  exercitarea următoarelor  drepturi corespunzătoare:",
	"the right to obtain human intervention on our part;":
		"dreptul de a obține intervenție umană din partea noastră;",
	"the right to express your point of view;": "dreptul de a vă exprima punctul de vedere;",
	"the right to challenge the automatic decision.":
		"dreptul de a contesta decizia bazată pe prelucrare automată.",
	"RIGHT TO WITHDRAW CONSENT": "Dreptul de a retrage consimțământul",
	"If we rely on your consent (or explicit consent) as the legal basis for processing your personal information, you have the right to withdraw your consent at any time and we will cease processing your personal data.":
		"Dacă  temeiul  legal  pe  care  ne  bazăm  pentru  a  prelucra  informațiile dumneavoastră  cu  caracter  personal  este consimțământul (sau consimțământul explicit), aveți dreptul de a vă retrage în orice moment consimțământul, iar noi nu vom mai prelucra datele dumneavoastră cu caracter personal.",
	"Withdrawal of consent does not affect the lawfulness of the processing of your personal data on the basis of your consent prior to its withdrawal.":
		"Retragerea consimțământului nu afectează legalitatea prelucrării datelor dumneavoastră cu caracter personal în baza consimțământului, efectuate anterior retragerii acestuia.",
	"RIGHT TO LODGE A COMPLAINT WITH NATIONAL SUPERVISORY AUTHORITY FOR PERSONAL DATA PROCESSING OF ROMANIA (“ANSPDCP”)":
		" Dreptul de a depune plângere la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP)",
	"You have the right to contact the ANSPDCP if you believe the processing of your data is not in compliance with the applicable law. More information about ANSPDCP can be obtained by visiting http://www.dataprotection.ro/.":
		"Aveți dreptul de a contacta ANSPDCP în situația în care considerați că prelucrarea datelor dumneavoastră nu respectă legislația aplicabilă. Pentru informații suplimentare despre ANSPDCP accesați:http://www.dataprotection.ro/.",
	"RIGHT TO SEEK JUDICIAL REMEDY": "Dreptul la o cale de atac judiciară",
	"Without prejudice to any other administrative or non-judicial remedy, you have the right to pursue an effective judicial remedy against a legally binding decision of ANSPDCP.":
		"Fără a aduce atingere vreunei căi de atac administrative sau nejudiciare disponibile, aveți dreptul de a exercita o cale de atac judiciară eficientă, în baza unei decizii opozabile a ANSPDCP.",
	"How can you exercise your rights?": "Cum vă puteți exercita drepturile?",
	"Submitting a request.": "Înaintarea unei solicitări.",
	"For the exercise of any rights above, please submit your request in writing or by phone, using the contact details indicated in section 3 above.":
		"În vederea exercitării oricăruia dintre drepturile menționate anterior, vă rugăm să înaintați solicitarea dumneavoastră în scris, utilizând datele de contact oferite în Secțiunea 3 de mai sus. ",
	"Identification of the applicant.": "Identificarea solicitantului.",
	"In order to be able to properly manage your request, we urge you to identify yourself as completely as possible. In case we have reasonable doubts as to the identity of the applicant, we will ask for further information to confirm your identity.":
		"Identificarea  solicitantului. În  vederea gestionării corespunzătoare  a solicitării dumneavoastră, vă rugăm  să vă identificați cât mai complet posibil. În situația în care avem rezerve rezonabile cu privire la identitatea solicitantului, vom cere informații suplimentare pentru a confirma identitatea dumneavoastră.",
	"Response time.": "Timp de răspuns.",
	"We will respond to your requests without undue delay, and in any case within one month from the receipt of the request. Insofar as your application is complex or we are in a position to process a large number of requests, we may reasonably postpone the delivery of your response for up to two months with your prior notice.":
		"Timpul de răspuns. Vom răspunde solicitărilor dumneavoastră neîntârziat, în orice caz, în termen de o lună de la data primirii solicitării. În eventualitatea în care solicitarea dumneavoastră este una complexă, iar noi ne găsim în situația de  a  prelucra  un număr  mare  de solicitări,  este  posibil să amânăm  rezonabil  acordarea  unui răspuns  cu  privire  la solicitarea dumneavoastră, cu până la maximum două luni și cu notificare prealabilă.",
	"Providing our answer.": "Oferirea unui răspuns.",
	"We will provide you with our response and any requested information in electronic format, unless you request them to be provided in another format.":
		"Vă vom oferi răspunsul nostru, precum și orice informații solicitate, în format electronic, cu excepția cazului în care le solicitați într-un alt format.",
	"Refusal.": "Refuzul.",
	"In so far as we refuse to meet your request, we will inform you of the reasons which led to such a decision and of the possibility to submit a complaint to ANSPDCP and to apply for a judicial remedy.":
		"În cazul în care refuzăm să dăm curs solicitării dumneavoastră, vă vom prezenta motivele care au generat această decizie, precum și posibilitatea de a depune plângere la ANSPDCP și de a iniția exercitarea unei căi de atac judiciare.",
	"Taxes. Exercising your rights as a data subject is free. However, to the extent that your claims are manifestly unfounded or excessive, especially in the light of their repetitiveness, we reserve the right to levy a fee or to refuse the fulfilment of the request.":
		"Taxele. Exercitarea drepturilor dumneavoastră în calitate de persoană vizată este gratuită. Cu toate acestea, în măsura în  care solicitările dumneavoastră  sunt vădit  nefondate și  excesive,  mai  ales  prin  prisma  caracterului  repetitiv,  ne rezervăm dreptul de a percepe o taxă sau de a refuza să dăm curs solicitării.",
	"Cookies Policy": "Politica privind Modulele „Cookie”",
	"WHAT ARE COOKIES?": " Ce sunt modulele „cookie”?",
	"Cookies are small files of letters and numbers that are stored on your computer, mobile terminal, or other equipment that you use to access the internet. There are two main types of cookies:":
		"Modulele  „cookie”  sunt fișiere  de  mici  dimensiuni,  care conțin  litere și  numere  stocate  pe  calculatorul,  terminalul mobil sau un alt echipament pe care îl utilizați pentru a vă conecta la internet. Există două tipuri de module „cookie”:",
	"Session cookies – temporary cookies which allow website operators to link the actions of a visitor during a browser session. They are activated when the browser window is opened. Once you close the browser, all session cookies are deleted.":
		"Module  „cookie”  temporare  –  modul  „cookie”  temporar  permite  operatorului  website-ului să  realizeze conexiuni  între acțiunile  unui  utilizator,  pe  durata  unei  sesiuni  de  navigare  prin  browser.  Se activează  la deschiderea ferestrei browser-ului. La închiderea ferestrei browser-ului, toate modulele „cookie” temporare se șterg.",
	"Persistent cookies – remain on a user’s device for a set period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.":
		"Module „cookie” permanente - rămân pe dispozitivul utilizatorului pe o durată determinată, specificată în modulul „cookie”. Se activează de fiecare dată când utilizatorul accesează website-ul care a creat respectivul modul „cookie”.",
	"Cookies are installed through the request of our web-server to your browser (eg Internet Explorer, Chrome) and do not contain software, viruses or spyware, and cannot access information from your hard drive.":
		"Modulele „cookie” se instalează prin solicitarea trimisă de serverul nostru web către browser-ul dumneavoastră (cum ar  fi  Internet  Explorer,  Chrome) și  nu conțin  software, viruși  sau  programe  spyware și  nu  pot  accesa informațiile stocate pe hard-disk-ul dumneavoastră.",
	"WHAT TYPES OF COOKIES DO WE USE AND FOR WHAT PURPOSES?":
		"Ce tipuri de module „cookie” utilizăm și în ce scopuri?",
	"Strictly necessary cookies – are essential to navigate around our website and to use its features. These cookies do not gather personal information about you.":
		"Module „cookie” strict necesare - sunt esențiale pentru navigarea pe website-ul nostru și pentru utilizarea caracteristicilor  acestuia.  Aceste  module  „cookie”  nu colectează informații  cu  caracter  personal  despre dumneavoastră.",
	"Performance cookies – collect data for statistical purposes on how visitors use our website. They do not contain personal information such as names and email addresses and are used to improve your experience on our website. Information supplied by performance cookies can help us understand how you use the website; for example, whether or not you have visited before, what you looked at or clicked on and how you found us. Such data will be used to further improve our services.":
		"Module  „cookie”  de performanță  - colectează  date  cu  privire  la  modul  în  care  utilizatorii accesează website-ul nostru, în scopuri statistice. Nu conțin informații cu caracter personal cum ar fi nume și adrese de e-mail și se utilizează pentru a îmbunătăți experiența dumneavoastră de utilizator al website-ului. Informațiile oferite de modulele „cookie” de performanță ne pot ajuta să înțelegem cum utilizați website-ul; de exemplu, dacă l-ați mai accesat în trecut, la ce v-ați uitat, pe ce ați dat click și cum ne-ați găsit.  Ne vom folosi de astfel de date pentru a continua îmbunătățirea serviciilor pe care le oferim.",
	"Analytics cookies – cookies generated by the Google Analytics software to account the activity of visitors, and the frequency of accessing the Site. We have taken care that the data transmitted by these cookies does not lead to your identification.":
		"Module  „cookie”  de  analiză  -  module  „cookie”  generate  de  software-ul  Google  Analytics  pentru  a monitoriza  activitatea  utilizatorilor,  precum și frecvența accesării  website-ului.  Ne-am  asigurat că  datele transmise de aceste module „cookie” nu atrag dezvăluirea identității dumneavoastră.",
	"Purpose": "Scop",
	"Expiry": "Expirare",
	"Analytics": "Denumire",
	"months": "%s luni",
	"month": "%s lună",
	"years": "%s ani",
	"Functionality cookies – remember usernames, language preferences and regions, thus allowing user to customize how our website looks for them.":
		"Module „cookie” de funcționalitate- memorează numele de utilizator, preferințele lingvistice sau regiunile din care se face accesarea, permițând astfel utilizatorului să personalizeze interfața website-ului.",
	"language": "Limbă",
	"year": "%s an",
	"Advertising and targeting cookies – are used to deliver more relevant advertisements to you, but can also limit the number of times you see an advertisement, and be used to chart the effectiveness of an ad campaign by tracking users’ clicks.":
		"Module „cookie” în scopuri publicitare și de analiză a comportamentului utilizatorului - se utilizează în  vederea prezentării  unor  reclame  mai  relevante  pentru dumneavoastră, însă  pot  servi și  la  limitarea numărului  de rulări  ale  unui  spot  publicitar și  la  cuantificarea eficienței  unei  campanii  publicitare,  prin monitorizarea click-urilor utilizatorilor.",
	"HOW CAN YOU REFUSE OR DEACTIVATE COOKIES?": "Cum puteți refuza sau dezactiva module „cookie”?",
	"With the opening pop-up message, you can select which cookies you want to use, except for the strictly necessary cookies. Deactivating strictly necessary cookies will disable essential website services rendering it unusable.":
		"În  mesajul  tip  „pop-up”  care  apare  pe  ecran, aveți  posibilitatea  de  a  selecta  modulele  „cookie”  pe  care doriți să  le utilizați, cu excepția modulelor „cookie” strict necesare. Dezactivarea modulelor „cookie” strict necesare va dezafecta și servicii esențiale ale website-ului și va face utilizarea acestuia imposibilă.",
	"You can also disable cookies by changing your browser settings. For more information about this, please visit your browser’s settings page.":
		"Modulele  „cookie”  pot  fi  dezactivate și  prin  modificarea setărilor  browser-ului.  Pentru informații  suplimentare  pe acest subiect, vă rugăm să accesați pagina de setări a browser-ului.",
	"SOCIAL MEDIA TECHNOLOGIES": "Tehnologiile aferente platformelor de comunicare socială",
	"Facebook Like and Share plugins – allow visitors to Like the PLAY ONLINE SOLUTIONS LTD Facebook page or share PLAY ONLINE SOLUTIONS LTD websites with friends. If a person is connected to Facebook and visits the Site, the browser sends this information to Facebook in order for Facebook upload content on that page. Among the data that can be sent to Facebook are: the user ID, the site you visit, the date and time, and other browser-related information. If you are disconnected from your Facebook account less information shall be transferred to Facebook.":
		"Plug-in-urile Facebook Apreciere și Distribuire („Like and Share”) - permit utilizatorilor să aprecieze pagina de Facebook  a  PLAY  ONLINE  SOLUTIONS  LTD  sau  să  distribuie  prietenilor  website-urile  PLAY  ONLINE SOLUTIONS  LTD.  În  cazul  în  care  o persoană conectată  la  Facebook accesează  website-ul,  browser-ul  trimite informații către  Facebook,  pentru  ca  Facebook să  încarce conținut  pe  pagina respectivă.  Printre  datele  care  pot  fi trimise către  Facebook  se numără:  numele  de  utilizator,  website-ul  pe  care  îl accesați,  data și  ora accesării și  alte informații care țin de browser. În cazul în care nu sunteți conectat(ă) la contul de Facebook, informațiile transferate către Facebook vor fi mai puține.",
	"This personal information can be used wholly or in part by Facebook to improve Facebook products and services and to provide personalized ads. For more information you can consult Facebook privacy policy.":
		"Facebook poate utiliza integral sau parțial aceste informații cu caracter personal, în vederea îmbunătățirii produselor și  serviciilor  Facebook și  a prezentării  de  sporturi  publicitare  personalizate.  Pentru informații  suplimentare, puteți consulta Politica de confidențialitate a Facebook.",
	"Google+ plugin – when you visit a site that has a Google Plug-in, your browser sends a series of information to Google, such as the URL of the page you’re visiting and your IP address. Google can access and read the cookies stored in the visitor’s device. Apps that use Google’s advertising services also share information with Google, such as the app’s name and a unique identifier for advertising.":
		"Plug-in Google+ - în cazul în care accesați un website care are un plug-in Google, browser-ul dumneavoastră trimite o serie de informații către Google, cum ar fi URL-ul paginii pe care ați accesat-o și adresa dumneavoastră IP. Google poate  accesa și  citi  modulele  „cookie”  stocate  pe  dispozitivul  utilizatorului. Aplicațiile  care utilizează  serviciile  de publicitate ale Google partajează și ele informații cu Google, respectiv numele aplicației și identificatorul unic pentru publicitate.",
	"“Share with LinkedIn” plugin – when a person visits a site that has integrated such a plugin, LinkedIn receives the following visitor information: the URL of the aside from which it came and the one to which it is going. We also receive information about IP address, proxy server, operating system, web browser and add-ons, device ID and / or ISP and / or mobile phone identifier and features. If the site is accessed from a mobile device, the device will send us your location data according to the visitor’s phone settings.":
		"Plug-in „Distribuie pe LinkedIn” -  când cineva accesează un website care are integrat un astfel de plug-in, LinkedIn primește următoarele informații despre utilizator: URL-ul website-ului accesat anterior și cel al website-ului accesat imediat după de utilizator. Noi primim informații și despre adresa IP, serverul proxy, sistemul de operare, browser-ul web și extensiile, datele de identificare ale dispozitivului și/sau furnizorului de servicii de internet și/sau identificatorul și caracteristicile telefonului mobil. În cazul în care website-ul este accesat de pe un dispozitiv mobil, acesta ne va trimite datele de localizare conform setărilor telefonului utilizatorului.",
	"Tweet plugin – when a person visits a site that has integrated such a plugin, Tweeter can receive the following personal information about the visitor: Visited Web page, IP address, browser type, operating system, and cookie information. This information is collected to improve Tweeter products and services, including personalized customized ads and personalized ads.":
		"Plug-in  Twitter  -  când  cineva accesează  un  website  care  are  integrat  un  astfel  de  plug-in,  Twitter  poate  primi următoarele informații  cu  caracter  personal  despre  utilizator:  Pagina  web accesată,  adresa  IP,  tipul  de  browser, sistemul de operare, informațiile aferente modulelor „cookie”. Aceste informații se colectează în vederea îmbunătățirii produselor și serviciilor Twitter, inclusiv a spoturilor publicitare și a reclamelor personalizate.",
	"Ensuring personal data security": "Asigurarea securității datelor cu caracter personal",
	"For ensuring safety of this website we use the SSL (Secure Socket Layer) method in connection with the website visit, in conjunction with the highest encryption level supported by your browser. In general, this is a 256-bit encryption.  Whether a single page of our website is encrypted is indicated by the closed representation of the key or lock icon in the status bar of your browser.":
		"În vederea asigurării securității acestui website, utilizăm protocolul SSL („Secure Socket Layer”) în ceea ce privește accesarea  website-ului, împreună  cu  nivelul  maxim  de  criptare  pe  care  îl acceptă  browser-ul dumneavoastră. În general, este vorba despre criptare cu cheie de 256 biți. Faptul că o singură pagină din website-ul nostru este criptată este indicat de pictograma unui lacăt încuiat, prezentă în bara de status a browser-ului dumneavoastră.",
	"At organizational level, we have adopted and instructed our employees to follow internal procedures aimed at preventing loss or unauthorized access or disclosure. All persons, including our employees, processing your personal data under our authority have pledged to preserve the confidentiality and security of all such personal data.":
		"La nivel de organizație, am adoptat și ne-am instruit angajații să urmeze proceduri interne de prevenire a pierderii, a divulgării  datelor  cu  caracter  personal  sau  a  accesului  neautorizat  la  acestea.  Toate  persoanele,  inclusiv angajații, implicate în prelucrarea datelor dumneavoastră cu caracter personal sub îndrumarea noastră s-au angajat să păstreze confidențialitate și securitatea tuturor acestor date cu caracter personal.",
	"We have also implemented adequate security measures to protect your data against informatics treats and other potential data breaches. Such security measures shall be continuously improved in line with technological developments.":
		"În plus, am pus în aplicare măsuri de securitate corespunzătoare, în vederea protejării datelor dumneavoastră împotriva atacurilor cibernetice și a altor potențiale încălcări ale securității datelor. Vom îmbunătăți continuu astfel de măsuri de securitate, în pas cu dezvoltarea tehnologiei.",
	"Final provisions": "Dispoziții finale",
	"This Privacy Policy is applicable starting with 25th of May 2018.":
		"Prezenta Politică de confidențialitate intră în vigoare începând cu data de 25 mai 2018.",
	"PLAY ONLINE SOLUTIONS LTD reserves the right to modify or amend this Privacy Policy at any time by publishing an updated version here.":
		"17PLAY ONLINE SOLUTIONS LTD își rezervă dreptul de a aduce modificări și amendamente prezentei Politici de confidențialitate, în orice moment, prin publicarea unei versiuni actualizate aici.",
	"Game Rules": "Regulament de Joc",
	"Functionality Description": "Descrierea funcționalității",
	"This page contains information about the rules of the games that are sorted in alphabetical order. If you have any questions or suggestions, please contact us":
		"Această pagină conține informații despre regulile jocurilor care sunt sortate în ordine alfabetică. Dacă aveți întrebări sau sugestii, vă rugăm să ne contactați",
	"Slots": "Slots",
	"Payment Methods": "Metode de Plată",
	"Cash in Location": "Pot retrage în orice Agenție?",
	"Walk into any casino location to deposit easily and quickly ONLY with your ID-card.":
		"Accesați orice locație a cazinoului pentru a vă depozita ușor și rapid DOAR cu cartea de identitate.",
	"COMMISION": "COMISION",
	"Free": "Gratis",
	"PROCESS TIME": "TIMP DE PROCESARE",
	"Instant": "Instant",
	"LIMITS": "LIMITE",
	"Min: 20 Lei": "Min: 20 Lei",
	"Max: 9.000 Lei": "Max: 9.000 Lei",
	"Visa / MasterCard Credit Card": "Visa / MasterCard Credit Card",
	"Online payment through credit and debit cards.":
		"Plati online prin card de credit sau de debit.",
	"Taxes & Commisions": "Termeni & Condiții",
	"Accordingly to ": "În consecință",
	"EGO 114/2018, art. 53 ": "EGO 114/2018, art. 53 ",
	"and NGO Order 38 (National Gambling Office), ":
		"și Ordinul ONG 38 (Oficiul Național pentru Jocuri de Noroc),",
	"an administration tax of 2 percent is applied to every deposit.":
		"pentru fiecare depozit se aplică o taxă de administrare de 2%.",
	"EXAMPLE:": "EXEMPLU:",
	"A deposit of 100 Lei will credit the player’s wallet with 98 Lei, the other 2 Lei being directed to the State Budget.":
		"Un depozit de 100 de lei va credita portofelul jucătorului cu 98 de lei, ceilalți 2 Lei fiind direcționați la bugetul de stat.",
	"Rounding of tax value is done by ": "Rotunjirea valorii fiscale se face prin",
	"rounding up (ceiling) to a multiple of 1 Leu for fractions equal or above 0.5 Lei and by rounding down (floor) for fractions up to 0.49 Lei, inclusive.":
		"rotunjire (tavan) la un multiplu de 1 Leu pentru fracții egale sau peste 0,5 Lei și prin rotunjire (podea) pentru fracții de până la 0,49 Lei, inclusiv.",
	"Every withdrawal will be taxed accordingly to the following table:":
		"Fiecare retragere va fi impozitată în conformitate cu următorul tabel:",
	"WITHDRAWAL VALUE": "VALOARE DE RETRAGERE",
	"TAX": "IMPOZIT",
	"up to": "până la",
	"Above": "Mai sus",
	"Deposit": "Depune",
	"Withdrawal": "Retragere",
	"Walk into any location to withdraw easily and quickly ONLY with your ID-card and your phone.":
		"Mergeți în orice locație pentru a vă retrage ușor și rapid DOAR cu cartea de identitate și telefonul.",
	"0-4 days after approval": "0-4 zile de la aprobarea",
	"Responsible Gaming": "Joc Responsabil",
	"We are committed to deliver the best time you can have in our casino. Placing stakes and winning is a lot of fun. But losing is also part of the gambling thrill. Many people believe that gamble gaming is high-reward activity with low risk; it cannot be further from the truth. While Jackpots do hit and huge winnings occur, remember that mathematically the odds favor the house. People come up with strategies and lucky totems that guarantee the wins, some games involve some element of skill, but slots, blackjack, roulette and sports betting are all games of chance and no one can influence the outcome. ":
		"Ne-am angajat să oferim cel mai bun timp pe care îl puteți avea în cazinoul nostru. Plasarea mizelor și câștigarea este foarte distractivă. Dar pierderea face parte și din fiorul jocurilor de noroc. Mulți oameni consideră că jocurile de noroc sunt activități cu recompense mari, cu risc redus; nu poate fi mai departe de adevăr. În timp ce Jackpot-urile ating și câștiguri uriașe, nu uitați că matematic șansele favorizează casa. Oamenii vin cu strategii și totemuri norocoase care garantează câștigurile, unele jocuri implică un anumit element de îndemânare, dar sloturile, blackjack-ul, ruleta și pariurile sportive sunt jocuri de noroc și nimeni nu poate influența rezultatul.",
	"We strongly encourage that you play only when you feel 100% confident in your decisions and only the budget that you can afford to lose. Never play money that you need for a healthy life, for your bills and your loving ones. If you ever feel the urge to keep playing to recoup your loss, then STOP! If you can’t tell or feel guilty to tell other people how much money you have gambled, please consider you have some gambling addiction.":
		"Încurajăm cu tărie să joci doar atunci când te simți 100%  încrezător în deciziile tale și doar bugetul pe care îți poți permite să îl pierzi. Nu juca niciodată banii de care ai nevoie pentru o viață sănătoasă, pentru facturile tale și pentru cei dragi. Dacă simți vreodată nevoia de a continua să joci pentru a-ți recupera recuperarea, atunci STOP! Dacă nu puteți spune sau vă simțiți vinovat să spuneți altor oameni câți bani ați pariat, vă rugăm să considerați că aveți dependență de jocuri de noroc.",
	"To find out to which degree you are addicted to gambling, please take the anonymous test at":
		"Pentru a afla în ce măsură sunteți dependent de jocuri de noroc, vă rugăm să faceți testul anonim la",
	"If you think you have a gambling addiction or you think a family member or a friend has a gambling problem, consider getting qualified help from":
		"Dacă credeți că aveți o dependență de jocuri de noroc sau credeți că un membru al familiei sau un prieten are o problemă de jocuri de noroc, luați în considerare să primiți ajutor calificat de la",
	"Looking for immediate help? Call the free number":
		"Cauți ajutor imediat? Sunați la numărul gratuit",
	"Monday to Friday": "De Luni până Vineri",
	"and a specialist will answer and help you!":
		"iar un specialist vă va răspunde și vă va ajuta!",
	"Also, have a look at the following tips that may help you have a healthy, pleasing gaming.":
		"De asemenea, aruncați o privire la următoarele sfaturi care vă pot ajuta să aveți un joc sănătos și plăcut.",
	"Play for fun, not to make money.": "Joacă-te pentru distracție, nu pentru a câștiga bani.",
	"Gaming is never a solution to any financial problems. Remember that the odds favor the house.":
		"Jocurile de noroc nu sunt niciodată o soluție pentru probleme financiare. Amintiți-vă că șansele favorizează casa.",
	"Stick to a budget.": "Controlează-ți bugetul.",
	"Set a budget, stick to it and never play for more than you can safely afford. You should never play more than your disposable income; that is the remaining money after you deduct from your income the daily-living expenses, rent, food, your loving ones’ needs, bills, etc. Also, never play with other people’s money and never burrow money to gamble.":
		"Setați un buget, respectați-l și nu jucați niciodată mai mult decât vă puteți permite în siguranță. Niciodată nu ar trebui să joci mai mult decât venitul disponibil; aceștia sunt banii rămași după ce deduceți din venituri cheltuielile de viață zilnică, chiria, mâncarea, nevoile persoanelor iubitoare, facturile etc. De asemenea, nu vă jucați niciodată cu banii altor persoane și niciodată nu împrumutați bani pentru a paria.",
	"Use limits.": "Folosește limitele.",
	"Don’t get carried away in the heat of play and deposit more than you planned initially. Use the built-in tools to set deposit limits to maximize the fun and minimize your frustration. If you are connected, you can set up":
		"Nu vă lăsați purtat de lovitura jocului și nu depuneți mai mult decât ați planificat inițial. Utilizați instrumentele încorporate pentru a stabili limitele depozitelor pentru a maximiza distracția și a vă reduce frustrarea. Dacă sunteți conectat, puteți configura",
	"Deposit Limits from here.": "Limitele de Depozit de aici.",
	"Play when you are 100% clear headed.": "Joacă când ai 100% mintea liberă.",
	"Never play when you had alcohol or drugs because they will affect your decision making. Never play when you are sad or tired because you will not be able to think clearly.":
		"Nu vă jucați niciodată când aveți alcool sau droguri, deoarece acestea vă vor afecta luarea deciziilor. Nu juca niciodată când ești trist sau obosit, deoarece nu vei putea gândi clar.",
	"Don’t raise the stakes to artificially create excitement.":
		"Nu ridicați miza pentru a crea artificial emoție.",
	"If you get bored of gaming, raising stakes to artificially inflate the thrill is never a good idea; it will only lead to losing your funds faster. It’s much better to stand up and have a walk or meet some friends. The excitement will return when you place the original stake after a break.":
		"Dacă vă plictisiți de jocuri, creșterea mizelor pentru a umfla artificial fiorul nu este niciodată o idee bună; aceasta va duce doar la pierderea mai rapidă a fondurilor. Este mult mai bine să te ridici în picioare și să te plimbi sau să întâlnești niște prieteni. Entuziasmul va reveni când poziționați miza originală după o pauză.",
	"Spend your time wisely!": "Petreceți-vă timpul cu înțelepciune!",
	"Remember to get out in the real world and spend time and money with your friends and your loving ones as well. The key to happiness is a balanced life. way you can enjoy all the activities more.":
		"Nu uitați să ieșiți în lumea reală și să petreceți timp și bani cu prietenii și cu cei dragi. Cheia fericirii este o viață echilibrată felul în care vă puteți bucura mai mult de toate activitățile.",
	"It is illegal for anyone under the age of 18 to gamble in our casino.":
		"Este ilegal ca oricine sub 18 ani să joace în cazinoul nostru.",
	"You have reached your deposit limit.": "Ti-ai atins limita de depunere.",
	"You can deposit minimum ${minVal} Lei and maximum ${maxVal} Lei":
		"Poti depune minim ${minVal} Lei si maxim ${maxVal} Lei",
	"You can deposit minimum ${minVal} Lei":
		"Poti depune minim ${minVal} Lei",
	"Deposit value": "Valoare depunere",
	"checkout": "confirma",
	"checkout (SafeCharge)": "confirma (SafeCharge)",
	"checkout (Viva Wallet)": "confirma (Viva Wallet)",
	"Deposit Successful!": "Operatiunea a avut succes",
	"Time to sit back, play and relax. Good luck!":
		"Acum te poți intoarce relaxat la joc. Mult noroc!",
	"Everything is well! Your account has been credited with the amount of ": "Totul este in regula! Contul tau a fost creditat cu suma de ",
	"as 2% deposit tax": "taxa 2%",
	". Time to sit back, play and relax. Good luck!": ". Acum te poți intoarce relaxat la joc. Mult noroc!",
	"Wallet & Bonus": "Portofel și Bonusuri",
	"Time to play": "Joaca acum",
	"Operation Failed": "Operatiune eșuată",
	"An error has occured:": "A intervenit o eroare:",
	"(Error 202,1) This is an example of error description. The bank refused the transaction with this card. It may be expired or reported as stolen.":
		"(Error 202,1) This is an example of error description. The bank refused the transaction with this card. It may be expired or reported as stolen.",
	"Customer Service": "Relații Clienți",
	"I got it": "Am inteles",
	"In Review": "In verificare",
	"For the safety of your account, this deposit requires review from our Risk & Payments department.":
		"Pentru siguranta contului tau, aceasta creditare necesita verificari din partea departamentului de Risc si plati.",
	"Your funds are safe. You will be notified when they will be added to your account.":
		"Fondurile tale sunt in siguranta. Vei fi notificat cand suma va fi adaugata in contul tau.",
	"My pending funds": "Fonduri în așteptare",
	"Okay": "Am înțeles",
	"Checkout": "Confirmare",
	"About us": "Contact",
	"Terms & Conditions": "Termeni & Condiții",
	"Cookies": "Cookies",
	"Support": "Suport",
	"Have an issue?": "Aveți o problemă?",
	"Customer support": "Relații Clienți",
	"Locations": "Agenții",
	"Only for players over 18 years old": "Doar pentru jucătorii care au peste 18 ani",
	"Gambling may create addiction. Play Responsibly!":
		"Jocurile de noroc pot provoca dependență. Joacă responsabil!",
	"This website is operated by ${companyName}, registered in ${countryName} under number ${companyRegNumber}, at ${companyAddress}. In Romania, ${companyName} owns license number ${license_number}, issued on ${license_date}, by the National Gambling Office (${openTagOnjn}http://onjn.gov.ro/${closeTagOnjn}), valid until ${license_expiration_date}. Please be informed that gambling might be prohibited in your area. It is your responsibility to obey the law. Gambling may be addictive and impact your financials. Please play responsibly!":
		"Acest website este operat de ${companyName}, înregistrată în ${countryName} sub nr. ${companyRegNumber}, având sediul social în ${companyAddress}. În România, ${companyName} deține licența nr. ${license_number}], emisă la data de ${license_date} de Oficiul Național pentru Jocuri de Noroc (${openTagOnjn}http://onjn.gov.ro/${closeTagOnjn}), valabilă până la data de ${license_expiration_date}. Va informăm cu privire la posibilitatea ca activitatea de jocuri de noroc să fie interzisă în jurisdicția în care sunteti localizat. Este responsabilitatea dumneavoastră să respectați legea. Activitatea de jocuri de noroc poate cauza dependență și să aibă un impact asupra situației dumneavoastră financiare. Va rugăm să jucați responsabil!",
	"Review our cookie policy": "Despre politica noastra de cookies",
	"What do we use cookies for?": "Pentru ce folosim cookies?",
	'By clicking "I accept" or "X" on this banner, or using our site, you consent to the use of cookies.':
		"Apasand “De acord” sau “X” sau folosind in continuare site-ul, esti de acord in mod explicit cu folosirea cookies.",
	"I accept": "De acord",
	"Closest location": "Locația cea mai apropiată",
	"Maximum 4000 Lei": "Maxim 4000 Lei",
	"Search location": "Caută agenție",
	"Go in any ${brand} Agency and you can deposit any sum with your ID. No headaches, no cards.":
		"Mergi în orice agenție ${brand} și depune cât vrei doar cu buletinul. Fără bătăi de cap, fără carduri.",
	"Just with": "Doar cu",
	"ID card": "Buletinul",
	"You decide. Anywhere. Anytime": "Tu decizi. Oriunde. Oricând",
	"Deposit Online": "Depune Online",
	"Map with locations": "Harta cu agențiile",
	"Withdraw value": "Valoare retragere",
	"Minimum withdrawal: ${minVal} lei":
		"Retragere minima: ${minVal} lei",
	"Maximum value is": "Disponibil:",
	"withdraw_available_funds_button_text": "Alegere agenţie",
	"cash-in online": "online",
	"Location": "Agenție",
	"Change": "Modifică",
	"Withdrawal amount": "Valoare totală",
	"Withdrawal amount that will be received": "Suma care va fi primită",
	"Taxes due to the state": "Impozite datorate statului",
	"Confirm withdrawal": "Confirmă retragerea",
	"Success!": "Succes!",
	"Your request for withdrawal has been registered. Soon the Payments Department will review it for approval.":
		"Cererea dumneavoastră de retragere a fost înregistrată. În scurt timp, Departamentul Plăţi o va revizui spre aprobare.",
	"You will be notified when the money is ready to pick up from the chosen agency":
		"Veţi fi informat când banii sunt gata de ridicare din agenţia aleasă",
	"Pending withdrawals": "Retrageri în așteptare",
	"Close": "Inchide",
	"Request Failed": "Cerere Nereusita",
	"We’ve encountered this error:": "Am întâlnit această eroare:",
	"Please retry later or - should the error keeps appearing - contact the Customer Support.":
		"Încercați mai târziu sau - în cazul în care eroarea continuă să apară - contactați serviciul relații clienți.",
	"Contact Support": "Relații Clienți",
	"Try Again": "Incearcă din nou",
	"Withdrawal Successful!": "Retrageri in asteptare",
	"Available Funds": "Fonduri disponibile",
	"Taxes and Fees": "Taxe și impozite",
	"Attention: Account not verified": "Atenție: Contul nu este verificat",
	"Accordingly to legal requirements, please verify your identity prior to withdrawing any money.":
		"Conform cu legea in vigoare, va rugam sa va verificati identitatea inainte de a retrage bani.",
	"Your identity documents you provided are under review.":
		"Documentele trimise de către tine sunt în proces de verificare.",
	"Attention: Active bonuses": "Atenție: BONUS-uri active",
	"Blocked withdrawal": "Anulare retragere",
	"BONUS": "BONUS",
	"According to the Terms and Conditions, any withdrawal implies ${begin_red}forfeit${end_red} for bonus funds (${begin_red}-${bonus}${end_red}) and active bonuses.":
		"Conform Termenilor și condițiilor, orice retragere implică ${begin_red} renunțare la ${end_red} pentru fonduri bonus (${begin_red}-${bonus} ${end_red}) și bonusuri active.",
	"The forfeit is permanent.": "Confiscarea este permanentă.",
	"The bonuses cannot be reactivated, even by the subsequent cancellation of the withdrawal.":
		"Bonusurile nu pot fi reactivate, chiar și prin anularea ulterioară a retragerii.",
	"See bonuses": "Portofel și bonusuri",
	"Withdraw": "Retrage",
	"60 Minutes": "60 Minute",
	"60 minutes spent on website from last notification":
		"60 minute petrecute pe site de la ultima notificare",
	"Welcome": "Bine ai venit!",
	"LIVE": "LIVE",
	"homepage_table_games_big_font": "Jocuri",
	"homepage_table_games_small_font": "de masă",
	"RECEIVE 1000 LEI BONUS!": "Ia 1000 Lei BONUS",
	"FREE 50 LEI": "GRATIS 50 Lei",
	"Don't miss the hottest": "Nu rata cele mai tari",
	"Promotions": "Promoții",
	"You receive 50% more": "Primesti cu 50% mai mult",
	"All Jackpots": "Jackpots",
	"Jackpots": "Jackpot",
	"Latest winners": "Ultimii câștigători",
	"Closing left menu!": "Inchidere meniu stanga!",
	"Log in": "Conectare",
	"Log In": "Conectare",
	"LOG IN": "Conectare",
	"E-mail or Phone Number": "E-mail sau Numar de Telefon",
	"Ready for cash-in": "Gata pentru a ridica banii",
	"Walk into the selected location with your ID":
		"Du-te în orice locație selectată cu Cartea de Identitate",
	"Waiting for approval": "Se așteaptă aprobarea",
	"Your requests will be reviewed shortly": "Cererile dvs. vor fi examinate în scurt timp",
	"Recent transactions": "Tranzacții recente",
	"Withdrawals history": "Istoric retrageri",
	"No pending withdrawals": "Nicio retragere în așteptare",
	"Total Available Funds for Withdrawal": "Total fonduri disponibile pentru retragere",
	"START A WITHDRAWAL": "ÎNCEPE O RETRAGERE",
	"See Transaction History": "Istoric tranzacții",
	"Exceeds max amount allowed": "Depășește suma maximă permisă",
	"Must enter some value": "Trebuie să introduceți o valoare",
	"Partial Deposit": "Depunere rapidă",
	"Deposit from withdrawal request": "Agenţii",
	"Available amount:": "Fonduri disponibile:",
	"Cancel Withdrawal": "Anulare Retragere",
	"Are you sure that you want to cancel this withdrawal request?":
		"Sunteți sigur că doriți să anulați această solicitare de retragere?",
	"Amount to cancel:": "Suma anulată:",
	"Calculating...": "Calculează...",
	".00 Lei": ".00 Lei",
	"View": "Vezi",
	"Tax: ": "Impozit: ",
	"ID Card": "Carte de Identitate",
	"Verified": "Verificat, totul e în regulă.",
	"Requires verification": "Necesită verificare",
	"Edit": "Modifică",
	"Password": "Parolă",
	"Mobile number": "Număr de telefon mobil",
	"Address": "Adresă",
	"Language": "Limbă",
	"Change Password": "Schimbă parolă",
	"STEP ": "Pasul ",
	" OF 2": " din 2",
	"Wrong password, try again!": "Parola greșită, încercați din nou!",
	"Wrong code, try again!": "Cod greșit, încercați din nou!",
	"Current Password": "Parolă actuală",
	"Next": "Pasul următor",
	"Passwords don't match": "Parolele nu corespund",
	"Password changed": "Parolă schimbată!",
	"New Password": "Noua parolă",
	"Confirm Password": "Confirmare parolă",
	"Finish": "Finalizează",
	"Change Phone Number": "Schimbă Numărul de Telefon",
	"New phone number": "Noul număr de telefon",
	"SMS Code must be more than 3 characters": "Acest câmp trebuie sa aibă minim 3 caractere",
	"SMS Code": "Retrimite codul prin SMS",
	"Validate code": "Validare cod",
	"check number and resend code": "verifică numărul și retrimite codul",
	"Upload document": "Incarcă document",
	"Please upload a picture of your id card.": "Încărcați o fotografie a cărții de identitate.",
	"Utilities Bill": "Factură utilități",
	"Please upload a picture of your utilities bill.":
		"Încărcați o fotografie a facturii de utilități.",
	"Bank Card": "Card bancar",
	"Please upload a picture of your bank card.": "Încărcați o fotografie cardului bancar.",
	"Passport": "Pașaport",
	"Please upload a picture of your passport.": "Încărcați o fotografie cu pașaportului.",
	"Use the camera": "Folosește camera",
	"Select document from gallery": "Selectați documentul din galerie",
	"or": "sau",
	"Max size: ": "Dimensiune maximă imagini:",
	"Please upload a picture of your ${title}.": "Încărcați o fotografie cu ${title}.",
	"Average waiting time": "Timp mediu de așteptare:",
	"${number_of_minutes} minutes": "${number_of_minutes} minute",
	"The document will be reviewed soon": "Documentul dvs. va fi examinat în scurt timp",
	"Upload a new photo": "Încarcă alt document",
	"Estimated time left": "Timp estimat",
	"The document was rejected": "Documentul a fost respins",
	"File cannot be opened": "Fișierul nu poate fi deschis",
	"Photo does not match the required document":
		"Fotografia nu se potrivește cu documentul necesar",
	"Validity date has expired": "Data de valabilitate a expirat",
	"Details are not visible": "Detaliile nu sunt clare",
	"Document cannot read": "Documentul este ilizibil",
	"Document wrong image": "Nu este documentul cerut",
	"Document should be fully visible in the photo":
		"Imaginea documentului nu trebuie sa fie taiata",
	"Upload Success": "Incărcare cu succes",
	"Upload Failed": "Incărcare eșuată",
	"Upload another document": "Incarcă alt document",
	"You are all good!": "Totul este în regulă!",
	"Future document requests will appear here and we shall notify you.":
		"Cererile de documente viitoare vor apărea aici și vă vom anunța.",
	"My Cards": "Cardurile mele",
	"documents_title": "Verificare identitate",
	"Verify my card": "Verificare card",
	"Documents": "Documente",
	"Preferences": "Preferinţe",
	"Auto-accept odds changes": "Acceptarea automată a modificărilor de cote",
	"None, only higher, any change": "Niciuna, doar mai mare, orice schimbare",
	"Enable all": "Permite tuturor",
	"Receive news and promotions": "Primește știri și promoții",
	"Personalized bonus and offers": "Bonus personalizat și oferte",
	"Enabled": "Activat",
	"Contact channels": "Contact",
	"E-mail, Phone, SMS, Push Notification": "E-mail, telefon, SMS, notificare push",
	"GDPR My personal data": "Datele mele personale GDPR ",
	"Daily": "Zilnică",
	"Weekly": "Săptămânală",
	"Monthly": "Lunară",
	"Consumed": "Consumați",
	"Set the Limit to ": "Activează o limită de",
	"Remove Limit": "Anulează limita",
	"Your Limits": "Limitele tale",
	"Current settings": "Stare curentă",
	"Wagered Amount": "Rulaj",
	"Loss": "Pierderi",
	"Limits": "Limite",
	"%s day": "%s zi",
	"%s days": "%s zile",
	"%s months": "%s luni",
	"%s year": "%s an",
	"%s years": "%s ani",
	"Your Identity verification period will expire after %s days.":
		"Perioada dvs. de verificare a identității va expira după %s zile.",
	"If you self-exclude, you will be unable to complete it before the deadline and, accordingly to the legal requirements, you account will be closed and the funds will be sent to the State Budget.":
		"Dacă vă excludeți singur, nu veți putea să îl finalizați înainte de termen și, în consecință, conform cerințelor legale, contul dvs. va fi închis, iar fondurile vor fi trimise la bugetul de stat.",
	"Your account and your funds are safe.": "Contul și fondurile tale sunt în siguranță.",
	"You can come back when the chosen period expires":
		"Vei putea reveni în cazinou după expirarea perioadei de auto-excludere.",
	"Take a Break": "Vreau o pauză",
	"Steer away from gaming for a few days": "Stai departe de jocuri pentru câteva zile",
	"For how long do you want to take a break?": "Pentru cât timp vrei să iei o pauză?",
	"Self-exclusion": "Auto-excludere",
	"Got a gambling addiction?": "Eşti dependent de jocuri de noroc?",
	"For how long do you want to self-exclude?": "Pentru cât timp vrei să te auto-excluzi?",
	"More options": "Mai multe opțiuni",
	"Take a short break instead": "Faceți o scurtă pauză în schimb",
	"Self-Exclusion": "Auto-excludere ",
	"It is important for us to have fun when playing in our online casino. If you're worried about playing more than you can afford, then take a break for a short period of time. After the self-exclusion period expires, you will be able to return to play.":
		"Este important pentru noi ca tu să te distrezi în cazino. Dacă ești îngrijorat că joci prea mult, gândește-te sa iei o pauză pentru o perioadă scurtă de timp. După ce perioada de auto-excludere expiră, te poți întoarce să joci.",
	"I agree to be restricted to my online casino access for the period specified above.":
		"Sunt de acord să îmi fie restricționat accesul în contul meu pentru perioada de mai sus.",
	"Lock account for ": "Restricționează accesul la cont pentru ",
	"Confirm exclusion": "Confirmă excluderea",
	"To complete your action, please confirm your password:":
		"Pentru a finaliza acțiunea, vă rugăm să confirmați parola:",
	"Restrict Access": "Acces restricționat",
	"Auto - Exclusion": "Auto-excludere",
	"Duration": "Durată",
	"Lock the account for ": "Restricționează accesul la cont pentru ",
	"Back": "Înapoi",
	"other": "altul",
	"active": "activ",
	"inactive": "inactiv",
	"expired": "expirat",
	"forfeited": "renunţat",
	"wagered": "rulat",
	"consumed": "consumat",
	"There is no bonus history at this moment": "Nu există un istoric de bonusuri în acest moment",
	"Download History for last 90 days": "Descarcă istoric pentru ultimele 90 de zile",
	"Pending": "În așteptare",
	"There is no transactions history at this moment":
		"Nu există un istoric de tranzacții în acest moment",
	"Unknown": "Necunoscut",
	"Pending Approval": "În curs de aprobare",
	"Ready For Cash-in": "Gata pentru a ridica banii",
	"Collected": "Colectat",
	"Cancelled": "Anulat",
	"Tax": "Taxe",
	"Result": "Rezultat",
	"without winning": "fără câștig",
	"There is no games history at this moment": "Nu există un istoric al jocurilor în acest moment",
	"IN & OUT": "IN & OUT",
	"transaction_history_bonus_title": "Bonus",
	"Game": "Joc",
	"Transactions History": "Istoric tranzacții",
	"Remove bonus": "Şterge bonus",
	"Are you sure you want to remove your bonus?": "Eşti sigur că vrei să ştergi acest bonus?",
	"Day ago": "Cu o zi în urmă",
	"Days ago": "Zile în urmă",
	"ago": "în urmă",
	"Day": "Zi",
	"There is no bonus active yet": "Nu există încă un bonus activ",
	"Visit ": "Viziteaza",
	" to get one.": " pentru a primi unul.",
	"Current Prize": "Stare curentă",
	"Wager requirements": "Cerinte de rulaj",
	"Terms and Conditions": "Termeni & Condiții",
	"Expired": "Expirat",
	"There are no pending witdrawals": "Nicio retragere în așteptare",
	"Sold & Bonuses": "Portofel și Bonusuri",
	"Total balance available for play": "Suma totală disponibilă pentru joc",
	"Cash": "Bani",
	"Your Bonuses": "Bonusurile tale",
	"Current Status": "Stare curentă",
	"Wallet & Bonuses": "Portofel și Bonusuri",
	"SEE ALL RULES, T&C": "VEZI TOATE REGULILE, T&C",
	"Deposit with this bonus": "Depuneți cu acest bonus",
	"Account": "Contul meu",
	"Verification": "Verificare",
	"Address from Identity Card": "Adresa de reședintă",
	"CNP must be 13 characters long": "CNP-ul trebuie sa aibă minim 13 caractere",
	"CNP must contain only numbers": "CNP-ul trebuie sa conțină doar cifre",
	"Minimum age accepted for registration is 18 years":
		"Vârsta minimă acceptată pentru înregistrare este de 18 ani",
	"Social Security Number is too short": "Seria Pașaportului este prea scurtă",
	"Inserted value is invalid": "Valoarea introdusă este invalidă",
	"CNP": "CNP",
	"Social Security Number": "Serie Pașaport",
	"Citizenship": "Cetățenie",
	"Insert a valid email address": "Introdu o adresă de email validă",
	"Email invalid": "Email invalid",
	"First Name from Identity Card": "Numele mic din buletin",
	"This field contains invalid characters.": "Acest câmp conține caractere invalide.",
	"This field must have minimum 3 characters": "Acest câmp trebuie să aibă minim 3 caractere",
	"Inserted value is not valid": "Valoarea introdusă nu este validă",
	"First Name": "Prenume",
	"Last Name": "Nume",
	"This field contains invalid characters": "Acest câmp conține caractere invalide",
	"This field must have minimum 2 characters": "Acest câmp trebuie să aibă minim 2 caractere",
	"Inserted field is invalid": "Valoarea introdusă este invalidă",
	"Change number?": "Schimbi număr?",
	"Save": "Salvează",
	"Resend SMS Code": "Retrimite codul prin SMS",
	"This phone number is already registered": "Acest număr de telefon este deja înregistrat",
	"You can request a new SMS code in %s seconds": "Poți cere un nou cod prin SMS în %s secunde",
	"The passwords doesn't match": "Parolele nu corespund",
	"The password doesn't respect the minimum criteria": "Parola nu respectă condițiile minime",
	"Password Requirements:": "Cerințe parolă:",
	"Include at least 1 letter": "Include minim o literă",
	"Have at least 6 characters in length": "Are cel puțin 6 caractere lungime",
	"Include at least 1 number": "Include minim un număr",
	"Security Level:": "Nivel Securitate:",
	"Low": "Mic",
	"Medium": "Mediu",
	"Maximum": "Maxim",
	"Very Low": "Foarte mic",
	"Insert your phone number": "Introdu numărul de telefon",
	"Phone number invalid": "Număr de telefon invalid",
	"Phone": "Telefon",
	"Reference Code": "Cod de referință",
	"Your Deposit Bonus": "Bonusul tău de depunere",
	"You are moments away from collecting your bonus and immediately start playing at the":
		"Ești la câteva momente de a colecta bonusul tău și de a începe să joci la",
	"and": "și",
	"LIVE Casino": "Cazino LIVE",
	"See Terms & Conditions": "Vezi Termeni & Condiții",
	"PLAY": "Joacă",
	"SAVE Bonus for Later": "Ia BONUS mai tarziu!",
	"Your BONUS will remain available for 30 days":
		"BONUSUL tău va rămâne disponibil pentru 30 zile",
	"DEPOSIT": "DEPUNE",
	"DEPOSIT WITH CARD": "DEPUNE CU CARD",
	"or make a deposit with": "sau depune cu",
	"CLAIM Bonus Now": "SOLICITĂ Bonusul Acum",
	"Something went wrong": "A apărut o problemă",
	"Insert the verification code received via SMS":
		"Introduceți codul de verificare primit prin SMS",
	"This field is mandatory": "Acest camp este obligatoriu",
	"I am older than 18 years": "Am peste 18 ani",
	"I want to receive bonuses and marketing materials that are relevant to me.":
		"Vreau sa fiu informat despre promotiile relevante pentru mine.",
	"I read and I agree with ${linkStartTC}T&C${linkEnd} and ${linkStartGDPR}Privacy Policy${linkEnd}.":
		"Am citit si sunt de acord cu ${linkStartTC}Termenii si Conditiile${linkEnd} de utilizare a serviciilor si cu ${linkStartGDPR}Politica de Confidentialitate${linkEnd} a cazinoului.",
	"You can resend a SMS Code in": "Poți cere un nou cod prin SMS în ",
	"seconds": "secunde",
	"Click here to resend SMS Code": "Retrimite codul prin SMS",
	"Play": "Joacă",
	"Save bonus for later": "Salvează bonusul pentru mai târziu",
	"get BONUS now!": "Ia BONUS acum!",
	"Account Verification": "Verificare cont",
	"Email verified!": "Email validat!",
	"Congratulations! Your account is now more secure.":
		"Felicitari! Contul tau este mai securizat.",
	"Good luck and have fun!": "Mult noroc si spor la joc!",
	"Go to homepage": "Acasa",
	"The email couldn't be verified": "Email-ul nu a putut fi verificat.",
	"Email Validation": "Validare email",
	"Reset Password": "Resetează parola",
	"Type in the field the email address associated with the account":
		"Tastează adresa de email asociată contului",
	"You will receive a link to the email associated with the account. You can reset your password from it.":
		"Vei primi un link pe adresa de email asociată contului tău. Poţi reseta parola cu ajutorul acestuia.",
	"We sent you an email with the link to change password":
		"Ti-am trimis un email cu link-ul pentru schimbarea parolei",
	"Verification Code": "Cod de verificare",
	"Send email": "Trimite email",
	"Type in the field the verification code sent to you through email":
		"Tastează codul de verificare primit pe email",
	"After identity verification you can choose a new password. Please, check your email.":
		"După verificarea identităţii vei putea allege o nouă parole. Verifică emailul.",
	"Welcome Bonus": "Bonus de bun venit!",
	"Pending funds": "Fonduri în așteptare",
	"LEARN MORE": "AFLAȚI MAI MUTLE",
	"Profile details": "Detalii personale",
	"Sold": "Sold",
	"GET BONUS": "OBȚINE BONUS",
	"Missing Page Card": "Pagină lipsă",
	"This page is missing from the system!": "Această pagină lipseşte din system!",
	"days": "zile",
	"day": "zi",
	"sec": "sec",
	"Loading": "Încărcare...",
	"Partial cancellation": "Anulare parţială",
	"Full cancellation": "Anulare completă",
	"Pending approval": "În curs de aprobare",
	"Processed": "Procesat",
	"This account is closed. Please contact support.":
		"Acest cont este închis. Vă rugăm să contactați suportul.",
	"Your new limit is now set to ${value} RON": "Limita este setată acum la ${value} RON",
	"New Limit of ${value} will be activated over 48H":
		"Noua limită de ${value} RON va fi activată peste 48h",
	"Your limit already is ${value} RON": "Limita este deja setată la ${value} RON",
	"Your limit will be removed over 48H": "Limita va fi ştearsă în 48H",
	"Visit the section Account ${pathSymbol} Wallet & Bonus ${pathSymbol} Withdraw or directly from the “Locations” section. Your request will be processed as soon as possible.":
		"Accesați secțiunea Cont ${pathSymbol} Portofel și bonus ${pathSymbol} Retragere sau direct din secțiunea „Locații”. Cererea dvs. va fi procesată cât mai curând posibil.",
	"Visit the section Account ${pathSymbol} Wallet & Bonus ${pathSymbol} Withdraw. Your request will be processed as soon as possible":
		"Accesați secțiunea Cont ${pathSymbol} Portofel și bonus ${pathSymbol} Retragere. Cererea dvs. va fi procesată cât mai curând posibil.",
	"Visit the section Account ${pathSymbol} Withdrawals. For every withdrawal there is an option to cancel funds partially or completely. This will not affect the approval time.":
		"Accesați secțiunea Cont ${pathSymbol} Retrageri. Pentru fiecare retragere există opțiunea de a anula fonduri parțial sau complet. Acest lucru nu va afecta timpul de aprobare.",
	"The subject should have minimum 5 characters": "Subiectul trebuie să aibă minim 5 caractere",
	"The body should have minimum 30 characters": "Conţinutul trebuie să aibă minim 30 caractere",
	"Withdrawals": "Retrageri",
	"Transaction History": "Istoric tranzacții",
	"My Locations": "Agenții",
	"Take a break": "Vreau o pauză",
	"Log Out": "Deconectare",
	"Table Games": "Jocuri de masă",
	"Don't miss the greatest": "Nu rata cele mai mari",
	"PROMOTIONS": "PROMOȚII",
	"slots_lobby_title_ticker": "Câştiguri în cazino",
	"slots_lobby_subtitle_ticker": "Cele mai relevante",
	"Welcome to Elite! We know that a high stake generates high earnings. For you to enjoy your betting without any restraints, ${boldStart}we give you a BONUS, for each deposit, up to a total limit of 1,000 Lei.${boldEnd} Make a deposit now, we give you a prize and you can start playing, right away!":
		"Bine ai venit la Elite! Știm ca o miza mare aduce câștiguri mari. Ca sa te bucuri fără rețineri, ${boldStart}primești BONUS la fiecare depunere in limita totala a 1.000 Lei.${boldEnd} Depune acum, te premiem si începi sa joci imediat!",
	"First deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}500 lei BONUS.${boldEnd}":
		"Prima depunere: ${boldStart}100%${boldEnd} din suma depusa in limita maxima de ${boldStart}500 Lei BONUS.${boldEnd}",
	"Second deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}200 Lei BONUS.${boldEnd}":
		"A doua depunere: ${boldStart}100%${boldEnd} din suma depusa in limita maxima de ${boldStart}200 Lei BONUS.${boldEnd}",
	"Third deposit: ${boldStart}100%${boldEnd} of the deposited amount, up to a maximum limit of ${boldStart}300 Lei BONUS.${boldEnd}":
		"A treia depunere: ${boldStart}100%${boldEnd} din suma depusa in limita maxima de ${boldStart}300 Lei BONUS.${boldEnd}",
	"We use cookies and similar technologies to ${boldStart}recognize your repeat visits and preferences${boldEnd}, as well as to be able to ${boldStart}offer the service authentication and communication${boldEnd}.":
		"Folosim cookies si tehnologii similare pentru a ${boldStart}recunoaste vizitele tale repetate si preferintele${boldEnd}, dar si pentru a ${boldStart}oferi serviciile de conectare in contul tau si pentru comunicatie${boldEnd}.",
	"To learn more about cookies and the data we store, view our ${boldStart}Privacy Policy${boldEnd}.":
		"Mai multe informatii despre cookies si despre ce date stocam, poti afla in ${boldStart}Politica de Confidentialitate${boldEnd}.",
	"Invalid code.": "Cod eronat.",
	"What will you play?": "Ce vrei să joci?",
	"Forgot password?": "Ai uitat parola?",
	"No account yet?": "Nu ai încă cont?",
	"Password or SMS code": "Parola sau cod SMS",
	"If you want to post a complaint, please click":
		"Dacă doriți să postați o plângere, vă rugăm să faceți click pentru mai multe informatii",
	"If we are unable to solve your complaint within 14 days, you will have the right to contact the National Gambling Office (ONJN).":
		"Dacă nu putem rezolva reclamația dvs. în termen de 14 zile, veți avea dreptul să contactați Oficiul Național pentru Jocuri de Noroc (ONJN).",
	"Account Details": "Detalii cont",
	"Top Rated": "Top",
	"Roulette & Cards": "Ruletă şi cărţi",
	"wins": "a câştigat",
	"This category is empty!": "Această categorie este goală!",
	"The application has encountered an unknown error.": "A aparut o eroare.",
	"This player is already registered.": "Acest jucător este deja înregistrat",
	"Invalid phone number.": "Număr de telefon invalid.",
	"Invalid email address.": "Adresă de email invalidă",
	"The minimum age requirement is not met.": "Varsta minima nu este atinsa.",
	"The cnp is not valid.": "Valoarea introdusă nu este validă",
	"Email is already registered": "Acest email este deja înregistrat",
	"Phone number is already registered.": "Acest număr de telefon este deja înregistrat",
	"The application has encountered a saving error.": "A aparut o eroare.",
	"Inserted code is invalid.": "Valoarea introdusă este invalidă",
	"Current password is invalid.": "Parola actuală nu este validă.",
	"Authentication expired! Please login and try again.":
		"Autentificarea a expirat. Te rugam sa intri din nou in contul tau.",
	"Invalid token.": "Token invalid",
	"Phone number SMS limit was reached.": "Limita de SMS-uri a fost atinsa.",
	"Insufficient funds.": "Fonduri insuficiente",
	"Invalid request.": "Cerere invalida.",
	"Duplicate request.": "Duplicare cerere.",
	"Negative amount.": "Suma negativa",
	"Cannot withdraw while having active bonuses.":
		"Nu se poate face o retragere cat timp ai bonusuri active.",
	"This account is suspended. Please contact support.":
		"Acest cont este suspendat. Vă rugăm să contactați suportul.",
	"This account is self excluded.": "Acest cont este auto-exclus.",
	"Decide who goes home with the trophy": "Decide who goes home with the trophy",
	"Not Available": "Indisponibil",
	"EVENT BETS LOCKED": "Pariuri Închise",
	"FREE BONUS 50 Lei": "BONUS 50 Lei",
	"How much do you want to take with you?": "Cat vrei sa iei cu tine?",
	"Mega Jackpots. Special prizes. Competitions.": "Mega Jackpots. Premii speciale. Concursuri.",
	"TAKE BONUS 1.000 LEI": "Ia 1000 Lei BONUS",
	'search "final" or "goluri"': 'caută "final" sau "goluri"',
	"Recommended": "Recomandări",
	"Total Odds": "Cota Finala",
	"Winning": "Castig",
	"Unavailable event - Remove": "Unavailable event - Remove",
	"Odds changed - Click to accept the changes": "Odds changed - Click to accept the changes",
	"Ticket verification in progress": "Ticket verification in progress",
	"Ticket Info": "Ticket Info",
	"Verification failed": "Verificare esuata",
	"We're checking the ticket status. Please wait.":
		"We're checking the ticket status. Please wait.",
	"Cashbacked": "Cashbacked",
	"ONLINE TICKET": "Bilet Online",
	"TICKET IN SHOP": "Bilet in agentie",
	"Default Layout": "Default Layout",
	"UK Layout": "UK Layout",
	"By Hour": "By Hour",
	"Event View": "Event View",
	"[Today]": "[Today]",
	"[Tomorrow]": "[Tomorrow]",
	"[Yesterday]": "[Yesterday]",
	"Scan": "Scan",
	"Pariuri": "Pariuri",
	"Opened": "In Asteptare",
	"Settled": "Decise",
	"Home": "Acasa",
	"Highlights": "Highlights",
	"Favorite": "Favorite",
	"Top League": "Ligi de top",
	"navigare": "navigare",
	"Start": "Start",
	"Goals": "Goals",
	"Own Goals": "Own Goals",
	"Goals by Head": "Goals by Head",
	"Goals by Penalty": "Goals by Penalty",
	"Yellow Cards": "Yellow Cards",
	"Red Cards": "Red Cards",
	"Yellow/Red Cards": "Yellow/Red Cards",
	"Shots On Target": "Shots On Target",
	"Shots Off Target": "Shots Off Target",
	"Assists": "Assists",
	"Points": "Points",
	"Top Scorers": "Top Scorers",
	"Top Penalty Cards": "Top Penalty Cards",
	"Top Shots": "Top Shots",
	"Top Other Details": "Top Other Details",
	"Information is not available right now": "Nu sunt informatii disponibile in acest moment",
	"Matches day": "Matches day",
	"Ranking": "Ranking",
	"Player Stats": "Player Stats",
	"Results": "Results",
	"Important Players": "Important Players",
	"Odds": "Cote",
	"H2H": "H2H",
	"Teams": "Echipe",
	"Yesterday": "Yesterday",
	"There are no Ultra Odds matches": "Momentan nu sunt evenimente in Ultra Cote",
	"There are no Winner Plus matches": "Momentan nu sunt evenimente in Winner Plus",
	"withdraw here": "Retrage aici",
	"Just with ID card": "Doar cu Buletinul",
	"NEXT": "URMĂTORUL",
	"Ooops!": "Ooops!",
	"Page not found.": "Page not found.",
	"Back to home page": "Back to home page",
	"Verify your account and identity before you can self-exclude":
		"Verifică-ți identitatea înainte de auto-excludere",
	"By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}Elite Slots${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.":
		"By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}Elite Slots${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.",
	"To complete the process, ${customBoldStart}please confirm your identity below. The exclusion begins immediately after.${customBoldEnd}":
		"Pentru a finaliza procesul, ${customBoldStart}vă rugăm să vă confirmați identitatea mai jos. Excluderea începe imediat după.${customBoldEnd}",
	"Exclusion ends:": "Excluderea se încheie:",
	"Confirm password": "Confirma parola",
	"Confirm email code": "Confirma codul primit pe email",
	"Confirm SMS Security Code": "Confirma codul primit prin SMS",
	"Resend SMS code": "Retrimite codul prin SMS",
	"I agree to be restricted to my online casino account for the period specified above.":
		"Sunt de accord să îmi fie restricționat accesul în contul meu  pentru perioada de mai sus.",
	"Self-exclude now!": "Autoexcludere acum!",
	"By continuing the process, you agree to the terms below regarding your full exclusion from ${pinkBoldStart}${brandFull}${pinkBoldEnd} and from the ${pinkBoldStart}ASSOCIATED CASINO PARTNERS NETWORK${pinkBoldEnd}.":
		"Continuand procesul, esti de acord cu termenii de mai jos in legatura cu excluderea permanenta din ${pinkBoldStart}${brandFull}${pinkBoldEnd} si pana la ${pinkBoldStart}toata reteaua partenerilor${pinkBoldEnd}.",
	"Inform yourself to full extent about this decision. You will not be able to revert it.":
		"Informeaza-te despre aceasta decizie. Aceasta actiune este ireversibila.",
	"! CANNOT BE REVERTED !": "! Nu poate fi inversata !",
	"! You will be excluded from all Play Online Network casinos !":
		"! Excluderea se aplica pe toata reteaua Play Online !",
	"! You will not regain access to your account, without exceptions !":
		"! Nu vei mai avea acces la contul tau !",
	"Identity Confirmation": "Identitate Confirmata",
	"Confirm self-exclusion": "Confirma auto-excludere",
	"See details": "Vezi detalii",
	"Next ball color": "Culoarea urmatoarei bile",
	"Next ball even or odd": "Urmatoarea bila PAR/IMPAR",
	"Even": "PAR",
	"My tickets": "Biletele mele",
	"Placing the ticket in progress...": "Bilet in curs de plasare...",
	"Match details": "Detalii Eveniment",
	"Waiting for events...": "Se asteapta evenimente...",
	"Search bet": "Cauta pariul",
	"If the problem persists, please contact the Customer Support departement":
		"În cazul în care problema persistă, vă rugăm să luați legătura cu Departamentul Servicii Clienți.",
	"Live tickets can only be played online":
		"Biletele cu evenimente LIVE se pot plasa doar ONLINE",
	"There are no top leagues": "Momentan nu sunt evenimente in aceasta sectiune",
	"Most played": "Cele mai jucate",
	"By league": "Dupa liga",
	"My Filtru": "Filtrul meu",
	"Event bets locked": "Pariuri inchise",
	"All Championships": "Toate ligile",
	"You haven't selected any bets": "Nu ai selectat niciun pariu",
	"Choose Stake": "Alege miza",
	"Min. Winning": "Castig minim",
	"Automatically accept odds changed": "Accepta automat modificarile de cote",
	"Online ticket": "Bilet online",
	"Ticket in shop": "Bilet in agentie",
	"Enter now": "Pariaza acum",
	"The tournament begins": "Turneul incepe",
	"High Odds matches": "Meciuri cu cote marite",
	"Pull and come into play": "TRAGE si intra in joc",
	"Promoted": "Promovate",
	"Bets": "Pariuri",
	"Search": "Caută",
	"Pre-match BetSlip": "Bilet Pre-Meci",
	"System": "Sistem",
	"Balance": "Balanță",
	"Place ticket online": "Plasează bilet online",
	"Automatically accept odds changes": "Acceptă automat modificările de cote",
	"Place ticket in shop": "Plasează bilet în agenție",
	"All leagues": "Ligi",
	"My Filter": "Filtrul meu",
	"Statistics": "Statistici",
	"In Bets": "Pariuri",
	"Odds changed - click to accept the changes":
		"Cotă schimbată – Click pentru a accepta schimbarea",
	"Unavailable event - remove": "Eveniment indisponibil - Șterge",
	"Live Betslip": "Bilet Live",
	"You must accept the odd changes in order to continue":
		"Acceptă schimbările de cotă pentru a continua",
	"Live tickets can only played online": "Evenimentele live se pot paria doar ONLINE",
	"No valid odds found": "Cotă invalidă",
	"Live and Pre-match hevents cannot be mixed on the same betslip":
		"Evenimentele Live și Pre-Meci nu se pot plasa pe același bilet",
	"Default layout": "Model Clasic",
	"UK layout": "Model UK",
	"Deposit and withdrawals": "Depuneri și Retrageri",
	"The match doesn't exists": "Evenimentul nu există",
	"category": "Categorie",
	"Matches": "Evenimente",
	"By Countries": "După Categorie",
	"Leagues": "Ligi",
	"Search for championships": "Caută Liga",
	"No selection": "Nicio selecție",
	"Search League": "Caută Liga",
	"Your championships": "Ligile tale",
	"Confirm": "Confirmă",
	"events ongoing": "în desfășurare",
	"a ticket contain only one Winner Plus bet": "Poți paria un singur eveniment Winner Plus",
	"No result": "Niciun rezultat",
	"Eligile Bonuses": "Bonusuri",
	"Progressive Bonus": "Bonus Progresiv",

	// Traduceri 23.05
	"ENTER YOUR ACCOUNT": "INTRĂ ÎN CONT",
	"PLACE TICKET IN SHOP": "PLASEAZĂ BILET ÎN AGENŢIE",
	"PLACE TICKET ONLINE": "PLASEAZĂ BILET ONLINE",
	"You must select at least one system": "Trebuie să selectezi cel puţin un sistem",
	"Cota Finala": "Cotă Finală",
	"Castig": "Câştig",
	"Castig minim": "Câştig minim",
	"Pre-match and LIVE events cannot be mixed on the same betslip":
		"Evenimentele Pre-Meci şi LIVE nu se pot combina",
	"Delete the bet?": "Ştergi Pariul?",
	"All the bets from the ticket will be deleted": "Toate pariurile de pe bilet vor fi şterse",
	"Are you sure?": "Eşti sigur(ă)",
	"NO": "NU",
	"YES": "DA",
	"A ticket can contain only one Winner Plus bet":
		"Un bilet poate conţine un singur eveniment Winner Plus",
	"Toate pariurile de pe bilet vor fi sterse": "Toate pariurile de pe bilet vor fi şterse",
	"Esti sigur(a)": "Eşti sigur(ă)",
	"You must add more bets in order to place this ticket":
		"Trebuie să mai adaugi evenimente pentru a putea plasa pariul",
	"All Leagues": "Ligi",
	"All Days": "Toate",
	"Offer": "Ofertă",
	"Statistics not available": "Statistici indisponibile",
	"Search for Championships": "Caută Ligă",
	"You can follow up to 5 championships at the same time":
		"Poţi urmări până la 5 ligi în acelaşi timp",
	"PRE-MATCH": "PRE-MECI",
	"Enter a team or championship name to start the search":
		"Scrie un nume de echipă sau de ligă pentru a începe căutarea",
	"BETS": "PARIURI",
	"Soccer": "Fotbal",
	"Tennis": "Tenis",
	"IN BETS": "HOT",
	"NAVIGARE": "JOACĂ",
	"My Tickets": "Biletele Mele",
	"Total odds": "Cotă finală",
	"Show More": "Mai Mult",
	"Ticket Details": "Detalii Bilet",
	"See more details": "Vezi mai mult",
	"FIXED": "FIX",
	"events...": "evenimente...",
	"Eligible Bonuses": "Bonus",
	"PLACE TICKET": "PLASEAZĂ BILET",
	"Potential Winning": "Câştig",
	"Live tickets can only be played online.": "Biletele LIVE se plasează doar ONLINE",
	"Filtered championships": "Ligi găsite",
	"Visible": "afişate",
	"ODDS CHANGED - CLICK TO ACCEPT THE CHANGES": "COTĂ SCHIMBATĂ - CLICK PENTRU A ACCEPTA",
	"UNAVAILABLE EVENT - REMOVE": "EVENIMENT INDISPONIBIL - ŞTERGE",
	"NO BETS AVAILABLE": "Pariuri indisponibile",
	"SOCCER": "FOTBAL",
	"TENNIS": "TENIS",
	"EVENT VIEW": "Detalii Meci",
	"OPEN": "DESCHIS",
	"Miza": "Miză",
	"Possible Winner": "Câştig Potenţial",
	"No results": "Niciun rezultat",
	//Sfarsit Traduceri 23.05

	// Traduceri 24.05
	"Statistics not available.": "Statistici indisponibile.",
	"Search in Sports Betting": "Caută in pariuri sportive",
	"No events available": "Nu există evenimente",
	"Match statistics": "Statistica meciului",
	"You haven't selected any bets.": "Nu ai selectat niciun pariu.",
	"bets": "pariuri",
	"in bets": "în pariuri",
	"Tickets": "Bilete",
	"The bets are on": "Stare de pariuri",
	"Admin rejected ticket": "Biletul a fost respins",
	"Not enough money": "Fonduri insuficiente",

	// Traduceri 26.05
	"Show more": "Mai mult",
	"Potential winning": "Câștig",
	"Pre-Meci": "Sport",
	"Category": "Categorie",
	"Enter a team or championship name to start the search.":
		"Scrie numele unei echipe sau a unei ligi pentru a începe căutarea",
	"There are no favorite matches": "Nu există evenimente Favorite",
	"Pre-match and LIVE cannot be mixed on the same betslip.":
		"Evenimentele LIVE și Pre-Meci nu se pot combina",
	"Placing the ticket in progress": "Bilet în curs de plasare",
	"A intervenit o eroare": "Mesaj:",
	"If the problem persists, please contact the Customer Support department.":
		"Pentru mai multe detalii, contacteaza Serviciul Clienti.",
	"MY TICKETS": "BILETELE MELE",
	"visible": "Afișate",
	"Show All": "Arată tot",
	"You can follow up to 5 championships at the same time.":
		"Poți urmări până la 5 Ligi în același timp",
	"Cazino": "Casino",
	"Search Bets": "Caută pariu",
	"common to X events": "pentru X evenimente",
	"BET NOT AVAILABLE": "Pronostic Indisponibil",
	"ODDS CHANGED-CLICK TO ACCEPT THE CHANGES": "COTĂ SCHIMBATĂ-APASĂ PENTRU A ACCEPTA",
	"UNAVAILABLE EVENT-REMOVE": "EVENIMENT INDISPONIBIL-ȘTERGE",
	// Sfarsit Traduceri 26.05

	// Traduceri 29.05
	"Cannot place ticket": "Biletul nu a putut fi plasat",
	"Details": "Detalii",
	"Minim stake of": "Miza minimă este",
	"AVAILABLE": "DISPONIBIL",
	"Insufficient Funds": "Fonduri insuficiente",

	// Traduceri 10.06
	"Top Offer": "Ligi de Top",
	"Pre-Match": "Sport",
	"BALANCE": "BALANȚĂ",
	"Ticket reoffered": "Bilet Modificat",
	"The ticket was reoffered. Please check the bet slip for changes":
		"Biletul tău a fost modificat. Te rugăm să îl verifici înainte de a-l plasa din nou.",
	"LIVE and Pre-match events cannot be mixed on the same betslip.":
		"Evenimentele LIVE și Pre-Meci nu se pot combina",
	"Most Played": "Cele mai pariate",
	"By Kick-off": "După oră",
	"By League": "După Ligă",
	"Ticket not found": "Bilet inexistent",
	// Sfarsit Traduceri 10.06

	//Traduceri 19.06
	"RECOVER": "RECUPEREAZĂ",
	"Place the same ticket without losing matches":
		"Plasează acelaşi bilet fără evenimentele pierdute",
	"All days": "Toate",
	"There are no favorites matches": "Nu există evenimente favorite",
	"Cashback": "Câştig BONUS",
	"Ticket verification in progress": "Verificare bilet în curs",
	"We're checking the ticket status. Please wait.": "Se caută biletul. Te rugăm să aştepţi.",
	"Register": "CONT NOU",
	//Sfarsit traduceri 19.06

	// Traduceri 19.06 - bets classic / nsoft clone
	All: "Toate",
	Days: "Zile",
	Today: "Azi",
	Soon: "In curand",
	Sports: "Sport",
	"Ticket verification": "Verificare bilet",
	Verify: "Verifica",
	"Prematch BetSlip": "Bilet Pre-Meci",
	Simple: "Simplu",
	"TOTAL ODDS": "COTA FINALA",
	"Total stake": "Miză Totală",
	"POSSIBLE WINNING": "CASTIG POSIBIL",
	"PAYIN TAX": "T.A.",
	"Number of combinations": "Numar de combinatii",
	"Ticket Info": "Info Bilet",
	SIMPLE: "SIMPLU",
	"Ticket Code": "Serie Bilet",
	Match: "Meci",
	"Date/Hour": "Data/Ora",
	Bet: "Pariu",
	Outcome: "Pronostic",
	Odd: "Cota",
	Stake: "Suma pariata",
	"Possible Winning": "Castig Posibil",
	"PAID OUT": "PLATIT",
	Winnings: "Castig",
	Payout: "Suma Platita",
	"Search Bet": "Cauta Pariu",
	Ticket: "Biletul",
	"was copied to": "a fost copiat în",
	"Ticket Verification": "Verificare bilet",
	"Type in bellow your ticket code": "Introdu seria biletului",
	"ticket code": "Serie bilet",
	"Search Ticket": "Cauta Bilet",
	LOST: "PIERDUT",
	WON: "CASTIGĂTOR",
	SYSTEM: "SISTEM",
	Overview: "Lista evenimente",
	"Event view": "Detalii Meci",
	"LIVE BetSlip": "Bilet LIVE",
	"Odds changed-Click to accept the changes": "Cota modificata-Apasa pentru a accepta",
	"Unavailable event-Remove": "Eveniment indisponibil-Sterge",
	No: "Nu",
	Yes: "Da",
	"Currently there are no bets available for this match! Stay tuned":
		"Nu sunt pronosticuri disponibile in acest moment.",
	"Placing failed": "Plasare esuata",
	"An error occurred": "Mesajul",
	"Your ticket will be placed in a few moments. Please wait.":
		"Biletul tau o sa fie plasat in cateva momente. Te rugam sa astepti.",
	Success: "Succes",
	'The ticket was placed successfully and you can find it in the "My Tickets" section.':
		'Biletul a fost plasat cu succes si il poti regasi in sectiunea "Biletele Mele".',
	"The ticket was placed successfully.": "Bilet plasat cu succes.",
	BetSlip: "Bilet",
	Mo: "LU",
	Tu: "MA",
	We: "MI",
	Th: "JO",
	Fr: "VI",
	Sa: "SA",
	Su: "DU",
	"Soon (+3h)": "În curând (+3ore)",
	"Place automatically without unavailable events": "Plaseaza automat fara evenimentele indisponibile",
	// Sfarsit Traduceri 19.06

	// Traduceri 22.06
	"Search Bet": "Caută Pariu",
	"CODE": "COD",
	"3 days": "3 Zile",
	"Today": "Azi",
	"Soon": "În curând",
	"The ticket is empty. Click on odds in order to bet": "Nu ai selectat niciun pariu.",
	"Curently there is no match for selected parameters!": "Nu sunt evenimente disponibile",
	"BetSlip": "Bilet",
	"Pre-Match BetSlip": "Bilet Pre-Meci",
	"LIVE BetSlip": "Bilet LIVE",
	"More": "Mai mult",
	"Pre-match and LIVE events cannot be mixed on the same betslip.":
		"Evenimentele LIVE şi Pre-Meci nu se pot combina",
	"Maximum number of bets reached": "Numar maxim de evenimente atins",
	"Total Stake": "Sumă Pariată",
	"Payin Tax": "T.A.",
	"Tomorrow": "Mâine",
	"Currently there are no bets available for this matc! Stay tuned.":
		"Momentan nu sunt pariuri disponibile",
	"See other bets.": "Vezi alte pariuri",
	// Sfarsit Traduceri 26.06

	"Invalid ticket code": "Cod bilet incorect",
	"Position the barcode from the ticket in order to read it":
		"Pozitionează codul de bare de pe bilet pentru citire cu camera",
	"Keep Ticket": "Păstrează Bilet",
	"New Ticket": "Bilet Nou",
	"No camera present or no permission to use the camera. Please check your browser settings.":
		"Nu este prezentă nicio cameră sau nu sunt permisiuni pentru a folosi camera. Va rugăm sa verificați setările browserului.",
	"Use old site": "Vechiul site este disponibil aici",
	"Old site": "Site vechi",
	"Sports": "Sport",
	"LIVE\nBetting": "Pariuri\nLIVE",
	"markets available": "pariuri disponibile",

	// Traduceri 13.07
	"Bonuses of the Day": "Bonusurile zilei",
	"Currently there is no match for selected parameters!":
		"Nu sunt pariuri disponibile în acest moment!",
	"Start Page": "Pagină Principală",
	"Odds changed - Click to accept the changes": "Cotă modificată - Apasă pentru a accepta",
	// Sfarsit Traduceri 13.07

	"There are no tickets": "Nu există bilete",
	"There are no opened tickets": "Nu sunt bilete în aşteptare",
	"There are no reserved tickets": "Nu sunt bilete rezervate",
	"There are no settled tickets": "Nu sunt bilete decise",

	"virtual games": "jocuri virtuale",
	"Virtual Games": "Jocuri Virtuale",
	"virtuals": "virtuale",
	"Virtual": "Virtuale",

	"Latest wins": "Ultimele câştiguri",
	"withdrew": "a retras",
	"Withdrawals in casino": "Retrageri din casino",
	"top withdrawals": "TOP Retrageri",
	"withdrawn by": "retras de",
	"Maximum number of lines exceeded (200,000)": "Numărul maxim de combinații permise depășit (200,000)",
	"Attention: Withholding taxes for withdrawals": "Atentie: Impozit pe retragere",
	"State tax": "Impozit stat",
	"According to Government Ordinance no. 16/2022, the Organizer has the obligation to withhold and remit, on behalf of the participants, the tax related to the income made by individuals as a result of participating in online gambling, depending on the value of the transaction, starting with the minimum tax threshold of 3%.": "Potrivit Ordonanței Guvernului nr. 16/2022, Organizatorul are obligația de a reține și de a vira, în numele participanților, impozitul aferent veniturilor realizate de persoanele fizice ca urmare a participării la jocurile de noroc online, în funcție de valoarea tranzacției, începând cu pragul minim de impozitare de 3%.",
	"Tax and fees information": "Informatii taxe si impozite",
	"Withdraw later": "Retrag mai tarziu",
	"Pay the fee and continue": "Platesc taxa si continui",
	"CDA 2% (GEO 114)": "CDA 2% (conform OUG 114)",
	"Net deposit": "Depunere neta",

	"There are no matches for ": "Nu există meciuri pentru ",
	"There are no matches for this sport": "Nu există meciuri pentru acest sport",
	"Currently there are no matches for selected parameters!": "Nu au fost găsite meciuri pentru parametrii selectați",
	"This bet is not available for this match!": "Acest pariu nu mai este disponibil!",
	"Currently there are no bets available for this match! Stay tuned.": "În acest moment nu sunt pariuri disponibile",

	"RECEIVE 1500 LEI BONUS!": "IA 1500 LEI BONUS",
	"TAKE 1,500 LEI BONUS": "IA 1,500 LEI BONUS",

	"Winnings Volcano!": "Erupe cu premii!",
	"Volcano Wealth + 6 Reels on Burning Hot and 40 Burning Hot": "Volcano Wealth + 6 role la Burning Hot si 40 Burning Hot",
	"Have a spin at them": "Încearcă acum noile jocuri",
	"PLAY NOW": "JOACĂ ACUM",

	"Be the victim of the next win!": "Fii victima următorului câștig!",
	"See all": "Toate",
	"Collection Unavailable": "Colectie indisponibila",
	"The game collection you are looking for is temporarily unavailable. Please try again later.": "Colectia de jocuri pe care o cautati este temporar indisponibila. Va rugam sa incercati mai tarziu.",
	"BACK TO THE CASINO": "JOCURI DE CAZINOU",

	// Traducere modulul Happy Hour
	"NEXT_EVENT": "Următorul",
	"Missed prizes": "Premii ratate",
	"Prizes won": "Premii câştigate",
	"finalized": "finalizat",
	"You won": "Ai câştigat",
	"You won prize": "Ai câştigat premiul",
	"A player": "Un jucător",
	"has won prize": "a câştigat premiul",
	"Minimum Bet": "Pariu minim",
	"Minimum bet for": "Pariu minim pentru",
	"Deployment interval": "Interval desfăşurare",
	"Total prizes": "Premii totale",
	"Events in progress": "Evenimente în curs",
	"Enter to play": "Joacă acum!",
	"If you did not receive the SMS code, you can request another one in ": "Dacă nu ai primit codul SMS, poți solicita unul nou în ",
	"seconds.": "secunde.",
	"Product Balance": "Balanţă produse",
	"Account Balance": "Sold Cont",
	"Available": "Disponibil",
	"Reserved": "Rezervat",
	"Bonus": "Bonus",
	"Balance per Product": "Sold disponibil în fiecare zonă de joc",
	"Sportsbook": "Pariuri",
	"Casino": "Casino",
	"Casino LIVE": "Cazino LIVE",
	"Virtuals": "Virtuale",
	"Lotto": "Loto",
	"No bonus": "Fără bonus",
	"Coming Soon": "În curând",
	"Pending Transactions": "Tranzacţii în aşteptare",
	"Withdrawls": "Retrageri",
	"Deposits": "Depuneri",
	"Bonus requirements:": "Cerinţe Bonus:",
	"Minimum deposit:": "Depunere minimă:",
	"Show verification code": 'Arata cod de verificare',
	"Time left:": "Timp ramas:",
	"Deposit without bonus": "Depune fara bonus",
	"Change bonus": "Schimba bonus",
	"Unlimited": 'Nelimitat',
	"I want to receive a SMS code for login.": "Doresc să primesc un cod de autentificare prin SMS.",
	"Unlimited": 'Nelimitat',
	"Verification code (expires in ": 'Cod verificare retragere (expira in ',
	"SPECIAL PRIZE": "PREMIU SPECIAL",
	"Special prize": "Premiu special",
	"TERMS AND CONDITIONS": "TERMENI ŞI CONDIŢII",
	"I understand": "Am inţeles",
	"Rules for participation": "Reguli de participare",
	"No minimum stake for the prize": "Fără miza minimă pentru premiul",
	"Everyone participates.": "Toată lumea participa.",
	"When the prize is active, any spin can determine the winner. Watch out!": "Când premiul este activ, orice spin poate determina caştigătorul. Fii pe fază!",

	// lotto translation texts
	"Done": "Terminat",
	"Registering": "Înregistrare",
	"Manual Authorization": "Autorizare Manuală",
	"To late to place ticket for this event": "Prea târziu pentru a mai plasa un bilet pentru acest eveniment",
	"Registered successfully": "Înregistrat cu succes",
	"Failed to register": "Nu s-au înregistrat",
	"ticket": "bilet",
	"tickets": "bilete",
	"TICKETS": "BILETE",
	"Latest draws:": "Ultimile extrageri:",
	"See draws history": "Vezi istoric extrageri",
	"I want to play the next draws:": "Vreau sa joc la următoarele extrageri:",
	"Play more draws": "Joacă mai multe extrageri",
	"Favorites": "Favorite",
	"Size Table": "Marime tabla",
	"Random": "La noroc",
	"Lottery Draws": "Extrageri Loterie",
	"draws": "extrageri",
	"PLACE TICKETS": "PLASEAZĂ BILETE",
	"another": "incă",
	"Max. Winning per Ticket": "Câştig Max. per Bilet",
	"Event doesn't exist": "Nu există evenimentul",
	"There are no details about this event": "Nu există detalii despre acest eveniment",
	"WITHOUT FAVORITE NUMBERS": "FĂRĂ NUMERE FAVORITE",
	"HOURS": "ORE",
	"MIN": "MIN",
	"SEC": "SEC",
	"The numbers have been added to favorites": "Numerele au fost adaugate la favorite",
	"FAST BETTING": "PARIURI RAPIDE",
	"All Lotteries": "Toate Loteriile",
	"Next Draws": "Urmatoarele Extrageri",
	"Favorite Numbers": "Numere Favorite",
	"There are no favorite numbers for this lottery": "Nu există numere favorite pentru această extragere",
	"Next ticket in": "Următorul bilet în",
	"Search Lottery Systems": "Caută Sisteme Loterii",
	"search lottery systems": "caută sisteme loterii",
	"Registered": "Înregistrat",
	"Drawing begins in:": "Extragerea începe în",
	"Next draw at": "Următoarea extragere la",
	"in": "în",
	"Drawing": "Se extrage",
	"Draw": "Extragere",
	"Reserved Code": "Cod Rezervat",
	"lost": "necâştigător",
	"paid": "plătit",
	"won": "câştigător",
	"open": "deschis",
	"unknow": "necunoscut",
	"You haven't selected any lottery numbers": "Nu ați selectat niciun număr de loterie",
	"Ticket Status": "Status Bilet",
	"Hour": "Ora",
	"Lucky Number": "Bila Norocoasă",
	"Lotto Chance": "Loto Şansă",
	"Good luck!": "Mult noroc!",
	"How it works?": "Cum functioneaza?",
	"Any of the numbers played can be Lucky Number. Choose your lucky number.": "Oricare dintre numerele jucate poate fi Lucky Number. Alege numarul tau norocos.",
	"If you lose the ticket, but your lucky number is extra, you win a Lucky Bet of 1 Leu value.": "Daca pierzi biletul, dar numarul tau norocos este extras, ai castigat un Lucky Bet de 1 Leu.",
	"If you lose the ticket, but your lucky number is extra, you win a {{FREE_BET_NAME}} of {{FREE_BET_COUNT}} x {{FREE_BET_VALUE}} Leu value.": "Daca pierzi biletul, dar numarul tau norocos este extras, ai castigat un {{FREE_BET_NAME}} de {{FREE_BET_COUNT}} x {{FREE_BET_VALUE}} Leu.",
	"Next draw:": "Urmatoarea extragere:",
	"historic": "istoric",
	"Created At": "Create La",
	"Serial": "Serial",
	"Status": "Stare",
	"Event Name": "Nume Eveniment",
	"Event Time": "Data Extragere",
	"Selected Numbers": "Numere Selectate",
	"Gross Winning Amount": "Castig Brut",
	"Event Date": "Data Eveniment",
	"Tokens": "Token-uri",
	"Place the same ticket for the next available event": "Plasati acelasi bilet pentru urmatorul eveniment disponibil",
	"Place again": "Plaseaza din nou",
	"You guess and you win a {{FREE_BET_NAME}}": "Ghicesti si castigi {{FREE_BET_NAME}}",
	"Search events etc.": "Cauta evenimente etc.",
	"Check ticket code": "Verifica bilet",
	"Placing partially failed": "Plasare eşuată parţial",
	"Hide": "Ascunde",
	"Show": "Afişează",
	"draw": "extragere",
	"Delete Ticket?": "Ştergi Biletul?",
	"The ticket selection will be deleted.": "Toate pariurile de pe bilet vor fi şterse.",
	"Are you sure?": "Eşti sigur(ă)?",
	"YES": "DA",
	"NO": "NU",
	"There are no open tickets associated with this lottery event": "Nu există bilete asociate cu această extragere",


	// maintenance
	"Pause for improvements": "Pauză de îmbunătăţiri",
	"Do you want to know immediately what you can play?": "Vrei sa afli imediat ce poţi juca?",
	"We apologize for this situation and we are working to fix the problem as soon as possible.": "Ne cerem scuze pentru această situaţie şi lucrăm să remediem problema în cel mai scurt timp.",
	"I WANT to be notified": "VREAU sa fiu anunţat",
	"I DON'T WANT to be notified": "NU VREAU sa fiu anunţat",
	"The website is currently unavailable.": "Website-ul nu este disponibil momentant.",
	"Please upgrade to the latest version of the application.": "Vă rugăm să faceți upgrade la cea mai recentă versiune a aplicației.",
	"The section is currently unavailable.": "Secțiunea nu este disponibilă momentan.",
	'"LIVE Betting" events are currently unavailable.': 'Evenimentele de "Pariuri LIVE" sunt momentan indisponibile.',
	'"Prematch Betting" events are currently unavailable.': 'Evenimentele de "Pariuri Prematch" sunt momentan indisponibile.',
	'"Casino" games are currently unavailable.': 'Jocurile "Casino" nu sunt disponibile momentan.',
	'"Casino LIVE" games are currently unavailable.': 'Jocurile "Casino LIVE" nu sunt disponibile momentan.',
	'"Lotto" events are currently unavailable.': 'Evenimentele de "Loto" sunt momentan indisponibile',
	'"Virtual" games are currently unavailable.': 'Jocurile "Virtuale" nu sunt disponibile momentan.',
	"This game is currently unavailable.": "Acest joc nu este disponibil momentan.",
	'"{{PROVIDER}}" games are currently unavailable.': 'Jocurile "{{PROVIDER}}" nu sunt disponibile momentan.',
	'"{{NAME}}" is temporarily unavailable': '"{{NAME}}" este temporar indisponibil',
	'Game temporarily unavailable': 'Joc temporar indisponibil',
	"Do you want to know immediately when you can play?": "Vrei sa afli imediat cand poti juca?",
	"maintenanceModalContent": 'Fii <strong>primul care reintra</strong> in joc! Iti vom trimite o notificare imediat ce <strong>{{name}}</strong> devine disponibil.',
	"Your casino is getting better": "Cazinoul dvs. se îmbunătățește",
	"We'll be back online soon!": "Ne vom întoarce online în curând!",
	"THANK YOU FOR YOUR PATIENCE!	YOU WILL RETURN TO AN UPGRADED SITE.": "VĂ MULȚUMIM PENTRU RĂBDAREA DVS! VEȚI REVENI LA UN SITE ACTUALIZAT.",

	// cashout
	"Confirm cashout for amount": "Confirma cashout pentru suma",
	"Confirm cashout for new amount": "Confirma cashout pentru noua suma",
	"There has been an error": "A aparut o eroare",
	"If the problem persists, please contact the Customer Service department.": "Daca problema persisita, va rugam sa va adresati departementului de Servicii Clienti.",
	"You have recovered {{AMOUNT}} {{CURRENCY}} by Cash-out. The amount has been added to your account.": "Ai recuperat {{AMOUNT}} {{CURRENCY}} prin Cash-out. Suma a fost adaugata in contul tau.",
	"ticket_not_found": "Nu exista biletul",
	"cashout_unavailable": "Nu exista cashout",
	"cashout_amount_changed": "S-a schimbat valoarea cashout",
	"ticket_cashout_rejected": "Cashout respins",
	"Cashout - amount modified": "Cashout - suma modificata",
	"Cashout available": "Cashout disponibil",
	"Cashout not available": "Cashout indisponibil momentan",

	"Event details": "Detalii eveniment",
	"The event you are looking for has expired or is no longer available": "Acest eveniment a expirat sau este indisponibil",

	"LIVE Betting": "Pariuri LIVE",

	// loto
	"luckyBetWarning1": "Minim 3 numere pentru a folosi <1>Lucky Bet/Free Bet</1>",
	"luckyBetWarning2": "Ai nevoie de un bilet simplu pentru <1>Lucky Bet/Free Bet</1>",
	"luckyNumberWarning1": "Minim 3 numere pentru a debloca <1>Bila Norocoasa</1>",
	"luckyNumberWarning2": "Ai nevoie de un bilet simplu pentru <1>Bila Norocoasa</1>",
	"Pick 3 numbers to unlock {{FREE_BET_NAME}}": "Minim 3 numere pentru {{FREE_BET_NAME}}",

	// Winner Fun
	"Winners Daily": "Winners Zilnic",
	"Winners at 3 hours": "Winners la 3 ore",
	"Enter and collect daily Winners for free. Three consecutive days rewards you extra.": "Intra si colecteaza zilnic Winners gratis. Trei zile consecutive te premiaza extra.",
	"wf.day": "Ziua",
	"wf.days": "zile",
	"NEXT IN": "URMATORUL IN",
	"Calendar": "Calendar",
	"See calendar": "Vezi calendar",
	"DETAILS": "DETALII",
	"Time remaining": "Timp ramas",
	"Available in": "Disponibil in",
	"Closed": "Inchis",
	"Freebies": "Gratuitati",
	"Score": "Clasament",
	"Raffle": "Tombola",
	"Missions": "Misiuni",
	"Calendar of Events": "Calendarul Evenimentelor",
	"RANKING": "CLASAMENT",
	"AWARDS": "PREMII",
	"REGULATIONS": "REGULAMENT",
	"POS": "LOC",
	"PLAYER": "JUCATOR",
	"AWARD": "PREMIU",
	"TOTAL WIN": "CATIG TOTAL",
	"A new season has started!": "A inceput un nou sezon!",
	"Play for free and win real money": "Joci gratis si castigi bani reali",
	"Season ended": "Sezon incheiat",
	"Your Raffle Tickets": "Biletele tale de Tombola",
	"In the ballot box": "In urna",
	"You've collected {{AMOUNT}} WinCoins": "Ai colectat {{AMOUNT}} WinCoins",
	"Choose a public name": "Alege-ti un nume public",
	"Anyone can see this name. It takes up to 48 hours until we approve it and it can become public.": "Oricine poate vedea acest nume. Dureaza pana la 48h pana il vom aproba si devine public.",
	"Nickname not approved yet.": "Numele public ales nu este inca aprobat.",
	"YOUR TEMPORARY NAME": "NUMELE TAU TEMPORAR",
	"Public name": "Nume public",
	"I'll change it later": "Il schimb mai tarziu",
	"Collect WinCoins daily for free.": "Colecteaza zilnic WinCoins gratis.",
	"POINTS": "PUNCTE",
	"COLLECT": "COLECTEAZA",
	"The bag is full!": "S-a umplut punga!",
	"Empty the bag in time to make room for other automatically gathering WinCoins.": "Goleste punga la timp pentru a face loc altor WinCoins care se strang automat.",
	"COMING SOON": "IN CURAND",
	"Collect": "Colecteaza",
	"MAIN BALANCE": "BALANTA PRINCIPALA",
	"ranking pts.": "pct. clasament",
	"Thank you": "Multumesc",
	"Your balance": "Balanta ta",
	"Season": "Sezon",
	"SEASON": "SEZON",
	"Ticket is not eligible for event, not enough wincoins in main balance": "Biletul nu este eligibil pentru eveniment. Nu sunt destui wincoins in balanta principala",
	"Insufficient balance": "Balanta insuficienta",
	"EVENT": "EVENIMENT",
	"Points": "Puncte",
	"win multiplier": "multiplicator castig",
	"BALANCE": "BALANȚĂ",
	"Tournament": "Turneu",
	"Competition": "Competitie",
	"COMPETITION": "COMPETITIE",
	"The public name already exists. You may choose: {{SUGGEST}}": "Numele ales exista deja. Poti alege: {{SUGGEST}}",
	"Invite a friend to join the game and receive WinCoins": "Invita un prieten sa intre in joc si primesti WinCoins",
	"CLOSE": "INCHIDE",
	"BEGIN": "INCEPE",
	"OPEN ": "DESCHIDE ",
	"Send this link to your friends": "Trimite acest link prietenilor tai",
	"Empty": "Liber",
	"Friends": "Prieteni in echipa",
	"See below when they made an account": "Vezi mai jos cand si-au facut cont",
	"Collect and Play": "Colecteaza si Joaca",
	"The active events have their own balance. Here you'll find all the winnings and all the WinCoins collected.": "Sezoanele si competitiile active au propria balanta. Aici se duc WinCoins colectate pe durata evenimentului și castigurile obtinute de pe urma utilizarii acestor fonduri.",
	"Nothing is lost! At the end of the competition, the WinCoins are moved to the main balance.": "Nu se pierde nimic! La finalul competitiei, WinCoins-urile cumulate se varsa in balanta principala.",
	"We do the calculations. You enjoy the game!": "Facem noi calculele. Tu bucura-te de joc!",
	"For events highlighted with the competition symbol, your winning is multiplied by the value displayed on the ticket.": "Pentru evenimentele evidentiate cu simbolul competitiei, castigul tau se multiplica cu valoarea afisata in bilet.",
	"Higher odds bring faster progress! Good luck!": "Cotele mai mari aduc progrese mai rapide! Succes!",
	"Invite a friend with the link below": "Invita un prieten cu link-ul de mai jos",
	"Copy and send this link on Whatsapp, Facebook, Instagram, Messenger or by mail": "Copiaza si trimite acest link pe Whatsapp, Facebook, Instagram, Messenger sau prin posta",
	"Good luck": "Mult noroc",
	"Invite": "Invita",
	"CURRENT": "CURENT",
	"HISTORIC": "ISTORIC",
	"MY AWARDS": "PREMIILE MELE",
	"A new tournament has started!": "A inceput un nou turneu!",
	"The top ranked players win real money.": "Primii jucatori clasati castiga bani reali.",
	"Tournament ended": "Turneul s-a terminat",
	"Congratulations and good luck in the future!": "Felicitari si mult noroc pe viitor!",
	"The ranking will be completed soon. See if you won any awards by navigating to": "Clasamentul se va finaliza in curand. Vezi daca ai castigat premii in",
	"History": "Istoric",
	"BOOST READY!": "BOOST reincarcat!",
	"Collect the boost in order to start recharging the next one.": "Colecteaza booster-ul pentru a porni reincarcarea urmatorului.",
	"Use Boost to place tickets to make room for the next ones.": "Foloseste Boost la plasarea biletelor pentru a face loc la urmatoarele.",
	"Win more": "Castigi mai mult",
	"Tickets that qualify for \"{{EVENT_NAME}}\" can benefit from increased Winner BOOST winnings!": "Biletele care se califica pentru \"{{EVENT_NAME}}\" pot beneficia de castiguri marite Winner BOOST!",
	"Collect Boosters to use when you see fit.": "Colecteaza Boostere pentru a le folosi cand crezi de cuviinta.",
	"The payout multiplier increases in proportion to the odds of the ticket. Higher odds bring even bigger gains!": "Multiplicatorul de castig creste proportional cu cota biletului. Cotele mai mari aduc castiguri si mai mari!",
	"Winner Boost: cannot be applied to some events on the ticket": "Winner Boost: nu se poate aplica unor evenimente de pe bilet",
	"Winner Boost: the minimum odds for the multiplier is {{MIN_ODD}}": "Winner Boost: cota minima pentru multiplicator este {{MIN_ODD}}",
	"BOOST Odds": "Cota BOOST",
	"win BOOST": "castig BOOST",
	"You've already used Boost on the event: {{EVENT_NAME}}": "Ai folosit deja Boost pe evenimentul: {{EVENT_NAME}}",
	"STARTING IN": "INCEPE IN",
	"Collect Winner BOOST and use them on your betslip to INCREASE the WIN. Begin collecting as soon as the next FUN event begins.": "Mareste-ti CASTIGUL de pe bilet cu Winner BOOST. Incepe sa colectezi imediat ce incepe urmatorul eveniment FUN din sezonul curent.",
	"SEE YOUR TEAM": "VEZI ECHIPA",

	// Evolution
	"All": "Toate",
	"Other": "Altele",

	// VIVA
	"Use the old method": "Depune prin metoda veche",
	"New card": "Card nou",
	"Payment Details": "Detalii de plată",
	"Proceeding will imply the acceptance of secure storing of your card data.": "Continuarea presupune acceptul stocării securizate a datelor cardului dumneavoastră.",
	"CARD DETAILS": "DETALII CARD",
	"Name as shown of the card": "Numele așa cum apare pe card",
	"Card number": "Număr card",
	"MM/YY": "LL/AA",
	"Deposited amount": "Suma depusă",
	"Deposit Bonus": "Bonus la depunere",
	"Simple and convenient. Complete your transaction just with the CVV.": "Simplu și convenabil. Finalizezi tranzacția doar cu CVV-ul.",
	"CVV CODE": "COD CVV",
	"The 3 or 4 digit code on the back of the card.": "Codul de 3 sau 4 cifre de pe spatele card-ului.",
	"Old Method": "Metoda Veche",
	"AMOUNT CONFIRM": "CONFIRMARE",
	"Simple and convenient. Complete your transaction just with the amount.": "Simplu si convenabil. Finalizeaza tranzactia acum.",
	"Something went wrong. Please verify the card data.": "A aparut o eroare. Verificati daca datele cardului au fost introduse corect.",
	"Payment method": "Metodă de plată",
	"NEW METHOD WITH BENEFITS": "METODA NOUA CU AVANTAJE",
	"Old method": "Metoda veche",
	"TELL ME MORE ABOUT THE BENEFITS": "CE INSEAMNA METODA NOUA / VECHE?",
	"DEPOSIT WITH THE NEW BENEFITS": "DEPUNE CU NOILE AVANTAJE",
	"TELL ME MORE ABOUT THE BENEFITS 2": "CE AVANTAJE AM?",
	"Bonus will be lost": "Vei depune fara bonus!",
	"This BONUS can only be obtained through the NEW METHOD.": "ACEST BONUS functioneaza DOAR prin METODA NOUA.",
	"The new payment method requires adding a card again (once), but it comes with benefits.": "Metoda noua presupune reintroducerea datelor cardului, dar iti ofera beneficii.",
	"Withdrawals are approved in less than 1 MINUTE and you can cancel a request in progress at your convenience.": "Primesti banii la retragere in mai putin de 1 MINUT si poti anula retrageri in asteptare dupa cum doresti.",
	"Continue without bonus": "Continua fara bonus",
	"DEPOSIT WITH BONUS": "VREAU BONUS",
	"New vs old payments": "Metoda noua vs veche",
	"Benefits of each": "Ce primesti in plus?",
	"Card deposits & withdrawals": "Depui si retragi cu cardul",
	"Auto-approved withdrawals! Receive your money in less than 1 MINUTE": "Retrageri automate! Primesti banii in mai putin de 1 MINUT",
	"Cancel withdrawals at your convenience": "Anulezi retragerile oricand vrei",
	"New exclusive bonuses": "Bonusuri noi, exclusive",
	"New method": "Metoda noua",
	"Continue with old method": "Continua cu vechea metoda",
	"NEW method": "Metoda NOUA",
	"OLD method": "Metoda VECHE",

	// statscore stats prematch
	"Wide": "Global",
	"League": "Competitie",
	"Away": "In deplasare",
	"Top Scorers": "Golgheteri",
	"Form": "Forma",
	"Overall": "Per total",
	"Assists": "Asistari",
	"Cards": "Cartonase",
	"Halftime": "Pauza",
	"ATP Ranking": "ATP",
	"WTA Ranking": "WTA",
	"ATP Doubles": "Dublu ATP",
	"WTA Doubles": "Dublu WTA",
	"Standings": "Clasamente",
	"Funds hidden": "Fonduri ascunse",

	"Olympics": "Olimpiada",
	"Choose Bonus": "Alege bonus",
	"The ticket number is not valid": "Numarul biletului nu este valid",

	//PO-4240
	"Your bonus": "Bonusul tau",
	"FREE": "GRATIS",
	"CLAIM NOW": "ACTIVEAZA",
	"Cancel active bonus?": "Anulezi bonus-urile existente?",
	"CLAIM LATER": "RENUNTA",
	"Bonus conflict": "Bonus-uri in conflict",
	"The requested bonus cannot be active at the same time with the bonuses currently active in your account.": "Bonus-ul cerut nu poate fi activ in acelasi timp cu cele existente in contul tau.",
	"WALLET & BONUS": "VEZI BONUS-URI ACTVE",
	"CLAIM LATER 2": "OBTINE MAI TARZIU",
	"LET'S PLAY": "HAI LA JOC",
	"We apologize for this inconvenience. We have run into a problem and the bonus has not been added to your account. Please try again later. If the issue continues, please contact the Customer Support.": "Ne cerem scuze! Momentan bonus-ul nu a putut fi adaugat in contul tau. Iti recomandam sa incerci mai tarziu. Daca situatia persista, contacteaza departamentul de relatii clienti.",

	// po4635
	'Bonus for you': 'Bonus pentru tine',
	//po4638
	"${bonusName} has been successfully been awarded to your account! GOOD LUCK!": "${bonusName} a fost adaugat cu succes in contul tau. MULT NOROC!",
	//PO-4186
	"Minimum withdrawal:": "Retragere minima:",

	/* PO-4505: winner fun teams */
	"Enter in your favorite team!": "Intra in echipa favorita!",
	"Support your favorite and help her win the title! Choose your team from the list below.": "Fa galerie preferatei tale si ajut-o sa castige titlul! Alege-ti echipa din lista de mai jos.",
	"Team ranking": "Clasament echipe",
	"Support your favorite team": "Sustine-ti echipa favorita",
	"Top {{TOP}} best players on this team": "Top {{TOP}} cei mai buni jucatori din aceasta echipa",
	"The team receives one star for each season won.": "Echipa primeste o steluta pentru fiecare sezon castigat. ",
	"PLACE": "LOCUL",
	"Find more about": "Afla mai multe despre",
	"You can change the team until the first ticket of a season is placed. After placing a ticket, it is no longer allowed to change the team until the end of the season.": "Poti schimba echipa pana la plasarea primului bilet dintr-un sezon. Dupa plasarea unui bilet, nu mai este permisa schimbarea echipei pana la finalizarea sezonului.",
	"LEAVE TEAM": "PARASESTE ECHIPA",
	"Join the group of team fans:": "Intri in grupul suporterilor echipei:",
	"No players": "Fara jucatori",
	"TEAM": "ECHIPA",

	"Invalid country. You can only login from Romania": "Țară invalidă. Vă puteți conecta numai din România",

	"Sport Betting": "Pariuri Sport",

	"Winner Application is here": "Aplicatia Winner este aici",
	"Faster betting and winning": "Pariaza si castiga mai rapid",
	"FUN BEGINS": "INCEPE DISTRACTIA",

	"Product Categories": "Categorii Produse",
	"Live Betting": "Pariuri Live",

	"Authentification with {{AUTH_TYPE}}?": "Autentificare cu {{AUTH_TYPE}}?",
	"You can enter the application quickly and easily using {{AUTH_TYPE}}. Do you want to activate it now? You can change your mind later in the Profile - Settings menu.": "Poţi intra rapid şi usor în aplicaţie folosind {{AUTH_TYPE}}. Vrei să îl activezi acum? Te poţi răzgândi mai târziu din meniul Profil - Setări.",
	"Later": "Mai târziu",
	"Yes, enable": "Da, activează",
	"Try again": "Incearcă din nou",
	"Failed to configure {{AUTH_TYPE}} successfully.": "Configurarea {{AUTH_TYPE}} eşuată.",
	"{{AUTH_TYPE}} configured successfully. You can change settings related to {{AUTH_TYPE}} in Profile - Settings.": "{{AUTH_TYPE}} configurat cu succes. Puteți modifica setările legate de {{AUTH_TYPE}} din Profil - Setări.",
	"Activate Push Notification": "Activeză Push Notification",
	"We can send you notifications directly to your phone using push notifications": "Vă putem trimite notificări direct pe telefon folosind push notifications",
	"Push Notifications Settings": "Setări push notification",
	"Authentification": "Autentificare",
	"Activate {{AUTH_TYPE}}": "Activeză {{AUTH_TYPE}}",
	"Use {{AUTH_TYPE}} for authentification": "Folosește {{AUTH_TYPE}} pentru autentificare",

	"Games": "Jocuri",
	"Don't miss": "Nu pierde",
	"Your Promotions!": "Promotiile tale!",
	"Retry": "Reincercati",
	"A temporary error occurred during the transaction. Try again later.": "A aparut o eroare temporara in timpul tranzactiei. Incercati din nou mai tarziu.",
	"Notifications": "Notificari",
	"Tournaments & Events": "Turnee si evenimente",

	"Activate notifications": "Activati notificarile",
	"You will always be up to date with the latest news and promotions.": "Veti fi mereu la curent cu ultimele stiri si promotii.",
	"Your opinion matters!": "Opinia dumneavoastra conteaza!",
	"Leave us a message with what you didn't find on the site.": "Lasa-ne un mesaj cu ceea ce nu ai gasit pe site.",

	"Your e-mail address": "Adresa ta de email",
	"Invalid e-mail address. Enter your account using a valid e-mail address and a password": "Adresa email invalida. Introduceti contul dvs. utilizand o adresa de email valida si o parola",
	"Invalid country. You can only login from Romania": "Tara nevalida. Va puteti conecta numai din Romania",
	"Can't find your favorite game?": "Nu iti gasesti jocul preferat?",
	"Go to the Winner site and find all the games.": "Acceseaza site-ul Winner si gaseste toate jocurile.",
	"All games": "Toate jocurile",

	"Failed to select bet": "Selectarea pariului nu a reusit",
	"There are no notifications!": "Nu sunt notificari!",
	"No messages.": "Nu sunt mesaje.",

	"News": "Stiri",

	"This email address is not registered": "Aceasta adresa de email nu este inregistrata",
	"Bet in location": "Pariati in locatii",

	"excelbet_slots_menu": "Cazinou",
	"Excelbet_slots_menu": "Cazinou",

	"Resend verification email": "Retrimitere e-mail de verificare",
	"Not Verified": "Neverificat",
	"Verified": "Verificat",

	"To perform a complete search please select the \"All\" option from the menu.": "Pentru a efectua o căutare completă vă rugăm selectați opțiunea \"Toate\" din meniu.",

	"Lost": "Necastigator",
	"Paid": "Platit",
	"Won": "Castigator",
	"Open": "Deschis",

	"common to {{COUNT}} events": "comun la {{COUNT}} evenimente",

	"CALL US": "APELEAZĂ UN AGENT",
	"Available from 10:00 to 22:00": "Serviciu disponibil în intervalul orar 10:00-22:00",
	"Later": "Mai tarziu",

	"Place ticket with": "Plasare miza",

	"How and where you can use it": "Cum si unde il poti folosi",

	"Spins": "Rotiri",
	"Wager the active bonuses first": "Necesita rulajul bonusului din cont",
	"Requires email verification": "Necesita verificarea email-ului",
	"Requires identity verification": "Necesita verificarea identitatii",
	"Temporarily unavailable": "Motiv indisponibil temporar",
	"Refresh": "Reincarca",
	"Invalid code. Please verify the value": "Cod invalid. Verificati inca o data valoarea introdusa.",
	"This code has reached its maximum number of activations": "Acest cod nu mai permite activari",
	"An error has occurred. Please try again later.": "A aparut o eroare. Va rugam sa incercati mai tarziu.",

	"Got a voucher code?": "Ai un cod de voucher?",
	"Enter code": "Introdu cod",
	"Enter the code to claim your prize": "Introdu codul si vezi ce ai castigat",
	"The claimed prize is available in 'My Bonuses'": "Premiul revendicat este disponibil in 'Bonusurile mele'",
	"You have already participated in this voucher campaign.": "Ati participat deja in aceasta campanie promotionala.",
	"VOUCHER CODE": "COD PROMOTIONAL",
	"ONGOING": "IN CURS",
	"Group by ticket": "Grupeaza dupa bilet",
	"There are no matches in progres": "Nu exista meciuri in desfasurare",

	/* PO-4039 - Lobby UI V2 */
	"Recently played": "Jucate recent",
	"results": "rezultate",
	"We could not find any results for \"{{QUERY}}\".": "Nu am gasit nici un rezultat pentru \"{{QUERY}}\".",
	"Try another word.": "Incearca alt cuvant.",
	"Search in CASINO and LIVE CASINO": "Cauta in Cazino si LIVE Cazino",

	"See event": "Vezi eveniment",

	"Last win": "Ultimul castig",

	"cntLabel": "Cont",
	"Search in Casino": "Cauta in Cazino",
	"MORE_PROMOTIONS": "Bonus-uri activabile imediat",
	"Ready to CLAIM": "Bonus gata de activare",
	"Deposit is temporarily unavailable": "Depunerea este temporar indisponibila",
	"Register is temporarily unavailable": "Inregistrarea este temporar indisponibila",
	"Set up your options": "Configurează-ți opțiunile",
	"Phone calls": "Apel telefonic",
	"Your deposit has been successfully completed, but an error occured while trying to add the bonus to your account. Please contact the Customer Service to fix this.":
		"Depunerea a reusit, dar nu am reusit sa adaugam bonus-ul selectat in contul dumneavoastra. Va rugam sa contactati serviciul Relatii Clienti pentru remediere.",

	/* Wheel */
	"MULTIPLIER": "MULTIPLICATOR",
	"DOUBLE": "DUBLU",
	"Win two multipliers. They MULTIPLY TOGETHER!!!": "Castigi doua casute pe roata de multiplicator. Rezultatele se INMULTESC!!!!",
	"All multipliers are increased by +1.": "Toate casutele de pe roata de multiplicator isi maresc valoarea cu +1.",
	"EXTRA CHANCE": "INCA O SANSA",
	"WILDCARD": "WILDCARD",
	"If you don't like what you won, you will be able to give up on that and try again.": "Daca nu iti place ce ai castigat, poti renunta la el si mai invarti o data.",
	"WIN TWICE": "CASTIGI DE DOUA ORI",
	"SPIN TWICE": "TWICE SPIN",
	"Spin twice today. Guaranteed win at two different products.": "Ai doua invartiri pe ziua de azi. Castigi GARANTAT la doua produse diferite.",
	"MAKE YOUR OWN LUCK": "FA-TI NOROCUL CU",
	"OPT OUT": "OPT OUT",
	"Opt out TWO PRODUCTS from the wheel. Guaranteed win one of the remaining ones.": "Elimini doua PRODUSE de pe roata. Castigi garantat din cele ramase.",
	"MEGA OPT OUT": "MEGA OPT OUT",
	"Opt out THREE PRODUCTS from the wheel. Guaranteed win one of the remaining ones.": "Elimini TREI PRODUSE de pe roata. Castigi garantat din cele ramase.",
	"Spin now!": "Roteste!",
	"SPIN NOW": "INVARTE ACUM",
	"Not eligible? Find out why": "Afla de ce nu esti eligibil",
	"Necessary conditions": "Conditii necesare",
	"Solve Now": "Rezolva Acum",
	"Activity Reward": "Rasplata Activitate",
	"Spin": "Rotire",
	"Spin with multiplier": "Rotire cu multiplicator",
	"Surprise prize at 3 spins with multiplier!": "Premiu surpriza la 3 rotiri cu multiplicator!",
	"I'll try again": "Mai incerc o data",
	"Activate prize": "Activeaza premiu",
	"Keys": "Chei",
	"How it works": "Cum functioneaza",
	"I chose, spin it!": "Am ales, roteste",
	"Award": "Castig",
	"I was unable to contact {{brand}} Wheel.": "Nu am putut contacta serviciul {{brand}} Wheel.",
	"Most likely this is a temporary problem. Please try again later.": "Cel mai probabil este o problema temporara. Va rugam sa incercati mai tarziu.",
	"Try again": "Incearca din nou",
	"The prize will be automatically applied in": "Premiul va fi aplicat automat in",
	"rotiri gratuite": "free spins",
	"One more spin left": "Mai ai o invartire",
	"Agree to receive marketing information from us": "Iti dai acordul sa afli imediat ce esti eligibil pentru bonus-uri si promotii.",
	"Play activity required: {{AMOUNT}} left": "Indeplineste activitatea minima necesara: {{AMOUNT}} Lei ramasi",
	"Verify your email address": "Ai adresa de email verificata",
	"Verify your identity": "Ai identitatea verificata",
	"There should not be any active bonuses in your account": "Nu trebuie sa ai bonus-uri active in cont",
	"To spin the wheel, you must:": "Ca sa invarti roata, trebuie sa:",
	"No limit": "Fara limita",

	"Discover today's prize": "Descopera premiul de azi",
	"Today's prize": "Premiul de azi",
	"Unlock": "Deblocheaza",
	"Unlocked prize": "Premiu deblocat",
	"Deposit Now": "Depune Acum",
	"Choose with which bonus you want to deposit: Free Bet or Free Spins.": "Alege cu ce bonus vrei sa depui: Free Bet sau Free Spins.",
	"You can choose one bonus, the other one will become unavailable after deposit.": "Poti alege un singur bonus, celalalt va deveni indisponibil dupa depunere.",
	"Activate the bonus until midnight before the offer expires.": "Activeaza bonus-ul pana la miezul noptii inainte sa expire oferta.",
	"Unlocked": "Deblocat",
	"An error occurred ": "A aparut o eroare",
	"Customer service": "Relatii cu clientii",
	"The operation may have been successful. If today's bonus is not available, please contact customer service.": "E posibil ca operatiunea sa fi reusit. Daca bonusul asociat acestei zile nu este disponibil, te rugam sa contactezi serviciul de relatii cu clientii.",
	"ACTIVATE AWARD": "ACTIVEAZA PREMIUL",
	"Next prize in": "Urmatorul premiu in",

	/* Raffle */
	"Surname": "Prenume",
	"Ticket Serial": "Serie Bilet",
	"I agree to be contacted": "Sunt de acord sa fiu contactat",
	"I want to be informed about promotions and bonuses.": "Vreau sa fiu informat despre promotii si bonus-uri.",
	"Time left": "Timp ramas",
	"Add ticket": "Adauga bilet",
	"Check active tickets": "Verificare bilete active",
	"The SMS verification is required only first time to be eligible for prizes.": "Verificarea este necesara o singura data si este obligatorie pentru a fi considerat eligibil pentru premiere.",
	"Phone check": "Verificare telefon",
	"Enter the verification code received via SMS:": "Introdu codul de verificare primit prin SMS:",
	"Change number": "Shimba numarul",
	"ADD A NEW TICKET": "ADAUGA INCA UN BILET",
	"The ticket was registered for the raffle.": "Biletul a fost inregistrat pentru tombola.",
	"Add more tickets for more chances to win.": "Adauga mai multe bilete pentru mai multe sanse de castig.",
	"Invalid verification code": "Cod de verificare invalid",
	"Check tickets": "Verificare bilete",
	"Ownership check": "Verificare titular",
	"Raffle tickets": "Bilete in tombola",
	"Tickets list": "Lista bilete",
	"Start page": "Pagina de start",
	"Winner Raffle": "Tombola Winner",
	"Join the WINNER LEAGUE!": "Intra in LIGA WINNER!",
	"More tickets added will bring you closer to the big win. Keep the tickets so you can claim the prize.": "Inscrie cat mai multe serii si fii mai aproape de marele castig. Pastreaza biletele pentru a putea revendica premiul.",
	"Keep the ticket so you can claim the prize.": "Pastreaza biletul pentru a putea revendica premiul.",
	"Ticket serial already exists": "Seria a fost deja inscrisa",
	"Missing required field": "Lipseste camp obligatoriu",
	"Cancel Scan": "Anuleaza Scanarea",
	"SMS limit exceeded": "Limita SMS depasita",
	"You can check the ticket list maximum 3 times per day": "Poti verifica lista de maxim 3 ori pe zi",
	"See the game rules": "Vezi regulamentul",
	"You must wait one minute to receive the SMS verification code": "Trebuie sa astepti 1 minut pentru a primi SMS-ul de verificare",
	"By signing up for the campaign, you agree to the terms of the GDPR.": "Prin inscrierea in campanie, iti exprimi acordul privind termenii GDPR.",

	"Available at": "Disponibil la ora",
	"Your winnings if the spin was with the keys": "Castigul tau daca rotirea era cu chei",
	"{{brand}} Keys": "Cheile {{brand}}",
	"wheelKeysStatus1": " <0>Joci sau pariezi la {{brand}}</0> la produsul tau preferat.",
	"wheelKeysStatus2": " Primesti <1>EXTRA PROGRES</1> plasand pariuri pre-meci si live.",
	"wheelKeysStatus3": " <1>Se contorizeaza doar banii reali</1> plasati in conditiile obisnuite ale activitatilor promotionale (fara ruleta, cota minima 1.5 la sport, etc).",
	"wheelKeysStatus4": " <0>Deblochezi multiplicatorul de castig</0> folosind cateva chei la fiecare invartire {{brand}} Wheel.",
	"wheelKeysStatus5": " <0>Joci sau pariezi la {{brand}}</0> la produsul tău preferat si obții chei din rulaj.",
	"wheelKeysStatus6": " <1>Folosești chei</1> pentru a activa Roata de Multiplicatori.",
	"wheelKeysStatus7": " <1>La trei învârtiri cu multiplicator</1> deblochezi unul din premiile speciale puzzle.",
	"wheelKeysStatus8": " <1>Premiile speciale puzzle</1>: multiplicatori măriți, învârti si câștigi de doua ori la doua produse diferite sau mai încerci o data daca nu iți place ce ai câștigat. Premiul puzzle se aplica imediat la acea învârtire.",

	"Simple spin": "Invartire simpla",
	"Lucky Bet is available only for the real-money tickets.": "Lucky Bet este disponibil doar biletelor plasate cu bani reali.",
	"The Lucky Number will be considered a regular ball.": "Bila jucata va fi considerata bila normala.",
	"luckyBetBonusWarning": " <1>Lucky Bet</1> este disponibil doar biletelor plasate cu bani reali.",
	"How to activate multiplier?": "Cum obtin premiul cu multiplicator?",

	"Tranzactie securizata": "Tranzacție securizată",

	"Eligibility conditions": "Cerinte de eligibilitate",
	"Play activity for the next key:": "Activitate pentru urmatoarea cheie:",

	"Events": "Evenimente",

	"EVENT BETS ARE UPDATING": "PARIURILE SE ACTUALIZEAZA",

	"Total BONUS funds in your account:": "Ai fonduri BONUS in cont in valoare de:",
	"T&C agreement prevents activating two bonuses at the same time. Cancel ${totalValue} and claim this bonus?": "T&C previne activarea la doua bonusuri in acelasi timp. Renunta la ${totalValue} si activeaza acest bonus",
	"CLAIM BONUS": "ACTIVEAZA BONUSUL",
	"Bonus rules": "Regulament bonusuri",

	"SEE OTHER OFFERS": "VEZI ALTE OFERTE",
	"Terms and conditions - in full": "Termeni si conditii - integral",

	// register v2
	"I agree with the ${linkStartTC}Terms and Conditions${linkEnd} and I declare that I am over 18 years old and that I am not a publicly exposed person.":
		"Am citit si sunt de acord cu ${linkStartTC}T&C${linkEnd} si declar ca am peste 18 ani si nu sunt persoană expusă public.",
	"NEW": "NOU",
	"Verified identity": "Identitate Verificata",
	"Quit registration?": "Renunți la înregistrare?",
	"Are you sure you want to give up on the progress made so far?": "Ești sigur ca vrei sa renunți la progresul realizat?",
	"Give up": "Da, renunț",
	"Continue": "Continui",

	// po-5821
	'What is "PEP"?': 'Ce este "PEP"?',
	'“Publicly exposed person” (in the sense of the AML Law) means a natural person who is or who has been entrusted with prominent public functions. Prominent public functions mean:':
		"“Persoană expusă public” (în sensul Legii CSB) înseamnă o persoană fizică căreia i se încredințează sau i s-au încredințat funcții publice importante. Funcții publice proeminente înseamnă:",
	"head of State, heads of government, ministers and deputy ministers or state secretaries;":
		"șef de stat, șefi de guvern, miniștri și adjuncți sau secretari de stat;",
	"members of parliament or of similar central legislative bodies;": "membri ai parlamentului sau ai organelor legislative centrale similare;",
	"members of the governing bodies of political parties;": "membri ai organelor de conducere ale partidelor politice;",
	"members of supreme courts, of constitutional courts or of other high-level judicial bodies, the decisions of which are not subject to further appeal, except in exceptional circumstances;":
		"membri ai curților supreme, ai curților constituționale sau ai altor organe judiciare de nivel înalt, ale căror decizii nu sunt supuse recursului, cu excepția unor circumstanțe excepționale;",
	"members of governing bodies of courts of auditors or of the boards of central banks;":
		"membri ai organelor de conducere ale curților de conturi sau ai consiliilor băncilor centrale;",
	"ambassadors, chargés d'affaires and high-ranking officers in the armed forces;":
		"ambasadorii, oamenii de afaceri și ofițerii de rang înalt din forțele armate;",
	"members of the administrative, management or supervisory bodies of State-owned enterprises, regie autonomous or national companies;":
		"membri ai organelor administrative, de conducere sau de supraveghere ale întreprinderilor de stat, autonome sau naționale;",
	"directors, deputy directors and members of the board or equivalent function of an international organisation.":
		"directori, directori adjuncți și membri ai consiliului de administrație sau funcție echivalentă a unei organizații internaționale.",
	"No public function referred to in points (a) to (h) shall be understood as covering middle-ranking or more junior officials.":
		"Nicio funcție publică menționată la literele (a)-(h) nu poate fi înțeleasă ca acoperind funcționari de rang mediu sau mai mici.",
	"“family members” of a publicly exposed person are (in the sense of the AML Law):":
		"„Membrii de familie” ai unei persoane expuse public sunt (în sensul Legii CSB):",
	"the spouse of a publicly exposed person or the person considered to be equivalent to a spouse of a publicly exposed person;":
		"soțul unei persoane expuse public sau persoana considerată a fi echivalentă cu soțul unei persoane expuse public;",
	"the children and their spouses, or persons considered to be equivalent to a spouse, of a publicly exposed person;":
		"copiii și soții acestora, sau persoanele considerate a fi echivalente cu un soț, ale unei persoane expuse publicului;",
	"the parents of a publicly exposed person.": "părinții unei persoane expuse publicului.",
	"“persons known to be close associates” of a publicly exposed person are (in the sense of the AML Law):":
		"„Persoane despre care se știe că sunt apropiați” unei persoane expuse public sunt (în sensul Legii CSB):",
	"natural persons who are known to have joint beneficial ownership of legal entities or legal arrangements, or any other close business relations, with a publicly exposed person;":
		"persoane fizice despre care se știe că au drepturi de proprietate în comun de persoane juridice sau aranjamente juridice sau orice alte relații de afaceri apropiate cu o persoană expusă publicului;",
	"natural persons who have sole beneficial ownership of a legal entity or legal arrangement which is known to have been set up for the de facto benefit of a publicly exposed person.":
		"persoane fizice care dețin unicul proprietar efectiv al unei entități juridice sau al unui aranjament juridic despre care se știe că a fost înființat, de fapt, în beneficiul al unei persoane expuse publicului.",
	// register v2
	"I agree with the ${linkStartTC}Terms and Conditions${linkEnd} and I declare that I am over 18 years old and that I am not a publicly exposed person.":
		"Am citit și sunt de acord cu ${linkStartTC}T&C${linkEnd} și declar că am peste 18 ani și nu sunt persoană expusă public.",
	"NEW": "NOU",
	"Verified identity": "Identitate Verificată",
	"Quit registration?": "Renunți la înregistrare?",
	"Are you sure you want to give up on the progress made so far?": "Ești sigur că vrei să renunți la progresul realizat?",
	"Quit": "Renunț",
	"Continue": "Continuă",
	"Scan_2": "Scan",
	"100% SAFE": "100% SIGUR",
	"Take a picture of your ID card and have your account verified quickly": "Fa o poza cartii de identitate si ai contul verificat rapid",
	"We sent you an email with the next steps required to activate your self-exclusion.": "Ti-am trimis un email cu urmatorii pasi pentru activarea auto-excluderii.",
	"Inform yourself to full extent about the decision. You will not be able to revert it": "Informeaza-te despre aceasta decizie. Aceasta actiune este ireversibila.",

	// PO-5858
	"Objectives": "Obiective",
	"Next begins in:": "Urmatorul incepe in:",
	" Temporarily unavailable": " Temporar indisponibil",
	"MAIN": "PRINCIPAL",
	"awards": "premii",
	"your place": "locul tau",
	"scores": "punctaj",
	"Join": "Participă",
	"Unblock": "Deblocheaza",
	"scoring system": "sistem de punctaj",
	"Recommended games for this tournament": "Jocuri recomandate pentru acest turneu",
	"Upcoming Tournaments": "Turnee Viitoare",
	"Deployment program": "Program de desfasurare",
	"premieres": "premiere",
	"Several tournaments will appear here as the start time approaches": "Mai multe turnee vor aparea aici pe masura ce se apropie ora inceperii",
	"Tournament history": "Istoric turnee",
	"The result of the completed tournaments": "Rezultatul turneelor finalizate",
	"Tournaments history completed more than 90 days ago is not available": "Istoricul turneelor finalizate cu mai mult de 90 de zile in urma nu este disponibil",
	"TOURNAMENT": "TURNEU",
	"TOURNAMENT DETAILS": "DETALII TURNEU",
	"ACTIVE NOW": "ACTIV ACUM",
	"FUTURE": "VIITOR",
	"You are not enrolled yet": "Nu esti inca inscris",
	"The tournament is blocked for you": "Turneu blocat pentru tine",
	"Begins in": "Incepe in",
	"Ends in": "Se termina in",
	"Starts in": "Incepe in",
	"Tournament rules": "Reguli turneu",
	"available games": "jocuri disponibile",
	"The tournament rankings will appear soon": "Clasamentul turneului va aparea in curand",
	"DAY": "ZILE",
	"HOUR": "ORE",
	"your position": "pozitia ta",
	"No players yet": "Niciun jucator",
	"games": "jocuri",
	"Position": "Locul",
	"Tournament History": "Istoric Turneu",
	"You have a prize waiting for you": "Ai un premiu in asteptare",
	"Claim": "Colecteaza",
	"The prize can only be claimed after the end of the active bonus.": "Premiul poate fi revendicat doar după finalizarea bonusului activ.",
	"The prize": "Bonusul",

	// PO-5976 - Retail Tickets
	"Cash it online": "Incaseaza online",
	"Ticket collection": "Incasare bilet",
	"Submission in progress": "Depunere in progres",
	"Successful deposit": "Incasare cu succes",
	"Unsuccessful deposit": "Incasare nereusita",
	"Everything is well! Your account has been credited with the amount of {{AMOUNT}} ({{TAX}} lei as {{PERCENTAGE}}% deposit tax). Time to sit back, play and relax. Good luck!":
		"Totul este bine! Contul dvs. a fost creditat cu suma de {{AMOUNT}} ({{TAX}} lei ca {{PERCENTAGE}}% taxa de depozit). E timpul sa stai pe spate, sa te joci si sa te relaxezi. Noroc!",
	"Try another ticket": "Incearca alt bilet",
	"Deposit in progress": "Incasare in curs",
	"Your money is safe. They will be added to your account in minutes.": "Banii tai sunt in siguranta. Vor fi adaugati in contul tau in cateva minute.",
	"The following problem occurred:": "A aparut urmatoarea problema:",
	"there was an internal error. Please try again later": "a aparut o eroare interna. Va rugam sa incercati mai tarziu",
	"missing brand setup. Please contact customer support": "configuratie invalida. Va rugam sa contactati suport clienti",
	"account kyc missing - not eligible. Please contact customer support": "identitatea contului nu este verificata. Va rugam sa contactati suport clienti",
	"missing ticket delivery platform. Please contact customer support": "bilet - platforma transmitere lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket delivery platform. Please contact customer support": "bilet - platforma transmitere invalida. Va rugam sa contactati suport clienti",
	"missing ticket product. Please contact customer support": "bilet - produs lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket product. Please contact customer support": "bilet - produs invalid. Va rugam sa contactati suport clienti",
	"missing ticket status. Please contact customer support": "bilet - stare lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket status. Please contact customer support": "bilet - stare invalida. Va rugam sa contactati suport clienti",
	"missing ticket amount. Please contact customer support": "bilet - valoare lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket amount. Please contact customer support": "bilet - valoare invalida. Va rugam sa contactati suport clienti",
	"missing ticket pin. Please contact customer support": "bilet - pin lipsa. Va rugam sa contactati suport clienti",
	"invalid ticket pin. Please contact customer support": "bilet - pin invalid. Va rugam sa contactati suport clienti",
	"ticket was already claimed by a different player. Please contact customer support": "biletul a fost deja incasat de un alt jucator. Va rugam sa contactati suport clienti",
	"ticket was unsuccessfully claimed too many times. Please contact customer support": "incasarea biletului a esuat de prea multe ori. Va rugam sa contactati suport clienti",
	"agreeRetailTicketsTerms": "Sunt de acord cu <1>conditiile</1> de achitare a biletului",
	"Ticket payout": "Incasare castig",
	"According to art. 13, para. (2) of Law no. 129 of 18.07.2019, to prevent and combat money laundering and terrorist financing": "Conform art. 13, alin. (2) din Legea nr. 129 din 18.07.2019 pentru prevenirea şi combaterea spălării banilor şi finanţării terorismului",
	"I am the REAL BENEFICIARY of the amount won by participating in fixed odds betting games": "Sunt BENEFICIARUL REAL al sumei castigate prin participarea la jocurile de noroc de tip pariuri in cota fixa",
	"I am A PUBLIC EXPOSED PERSON": "Sunt PERSOANA EXPUSA PUBLIC",
	"I am a member of a family in which is a person that is publicly exposed or of a person known as an associate of a publicly exposed": "Detin  calitatea  de  membru  al  familiei  al  unei  persoane  expuse  public  ori  de  persoana  cunoscuta  ca  asociat  al unei persoane expuse public",
	"I declare that the amount collected represents a gain from participating in the fixed-odds betting game.": "Declar ca suma incasata reprezinta castig din participarea la jocul de noroc tip pariuri in cota fixa.",
	"I have read and agree that the above information constitutes a statement according to law no. 129 of 18.07.2019": "Am citit si sunt de acord ca informatiile de mai sus sa constituie o declaratie conform legii nr. 129 din 18.07.2019",
	"Deposit Ticket": "Depunere Bilet",
	"Deposit with bonus": "Depunere cu bonus",
	"You cannot get other BONUSES right now. Please try again later.": "Nu poti obtine alte BONUS-uri chiar acum. Incearca din nou mai tarziu.",
	"AML form missing or incomplete. Please try again": "Formularul AML lipseste sau este incomplet. Incearca din nou mai tarziu.",
	"verify your identity": "verifică-ţi identitatea",
	"Cashout in shop": "Cashout in agentie",
	"Cashout online": "Cashout online",

	// PO-6252_Sport_Tournaments
	"See Details": "Vezi Detalii",
	"the following events qualify": "se califica urmatoarele evenimente",
	"tournament": "turneu",
	"This ticket contributes to the tournament.": "Acest bilet contribuie la turneu.",
	"This ticket does not contribute points to the tournament": "Acest bilet nu contribuie cu puncte la turneu",
	"Tournament points": "Puncte turneu",
	"TOURNAMENT: This ticket does not contribute points to the tournament": "TURNEU: Acest bilet nu contribuie cu puncte la turneu",
	"This type of bet ({{PARM0}}) is not accepted": "Acest tip de pariu ({{PARM0}}) nu este accceptat",
	"This event ({{PARM0}}) is not accepted": "Acest eveniment ({{PARM0}}) nu este accceptat",
	"This tournament ({{PARM0}}) is not accepted": "Acest turneu ({{PARM0}}) nu este accceptat",
	"This category ({{PARM0}}) is not accepted": "Aceasta categorie ({{PARM0}}) nu este accceptata",
	"This type of sport ({{PARM0}}) is not accepted": "Acest tip de sport ({{PARM0}}) nu este accceptat",
	"Minimum odd '{{PARM0}}'": "Cota minima '{{PARM0}}",
	"Show less": "Arata mai putin",
	"Find out why?": "Afla de ce?",
	"Tournament Sport": "Turneu Sport",
	"Bet already placed on event, different market": "Parierea in contra-partida nu va contribui la turneu",
	"LOCKED": "BLOCAT",
	"Current score": "Scor curent",
	"Your award": "Premiul tau",
	"pts": "pct",
	"This bet does not contribute points to the tournament": "Acest pariu nu contribuie cu puncte la turneu",

	// PO-6748
	"A ticket can contain only one Excel Plus bet":
		"Un bilet poate conţine un singur eveniment Excel Plus",
	"The document does not contain the requested details.": "Documentul nu conține detaliile solicitate.",
	"Retail ticket payment": "Încasare bilet agenție",

	// PO-6682
	"Deposit is not possible": "Incasarea nu este posibila",
	"Deposit limits exceeded": "Limite de depunere depasite",
	"Ticket value": "Valoare bilet",
	"Available limit": "Limita disponibila",
	"Limit type": "Tip de limita",
	"requires identity verification": "necesita verificarea identitatii",
	"requires the modification of the deposit limits set by you. The limit increase will be applied 48 hours after the change":
		"necesita modificarea limitelor de depunere setate de dumneavoastra. Marirea limitei se va aplica la 48 ore de la momentul schimbarii",
	"Solve now": "Rezolva acum",
	"dCancel": "Renunt",

	"The maximum file size is ${size}": "Dimensiunea maximă acceptată pentru fișier este ${size}",

	"PARTICIPATE IN THE WINNER RAFFLE!!": "Participă la TOMBOLA WINNER!!",
	"Enter as many eligible series as you can and your chances of winning the Grand Prize increase.": "Înscrie cât mai multe serii eligibile și șansele tale de a câștiga Marele Premiu cresc.",
	"Keep your tickets to claim your prize.": "Păstrează biletele pentru a-ți putea revendica premiul.",

	"This campaign is organized exclusively by DREAM BETTING S.R.L. All the personal data of the campaign participants, registered through the winner.ro platform, are processed and stored exclusively by DREAM BETTING S.R.L.":
		"Prezenta campanie este organizata exclusiv de catre DREAM BETTING S.R.L. Toate datele cu caracter personal ale participantilor la campanie, inregistrati prin platforma winner.ro, sunt prelucrate si stocate exclusiv de catre DREAM BETTING S.R.L.",
	"Processing...": "Se procesează...",
	"Approved": "Aprobat",
	"I have read, understood and accept the OKTO.CASH Service ${startUrl}Terms of Use${endUrl} and I agree to the transfer of my data to Oktopay Ltd and Sureswipe EMI PLC.":
		"Am citit, am înţeles şi accept ${startUrl}Termenii de utilizare${endUrl} a serviciului OKTO.CASH şi sunt de acord cu transferul datelor mele personale către Oktopay Ltd. şi Sureswipe EMI Plc.",
	"I have read, understood, and accept the ${startUrl}OKTO Privacy Policy${endUrl}":
		"Am citit, am înţelesşi accept ${startUrl}Politica de confidenţialitate${endUrl} a OKTO.",
	"Visit any OKTO.CASH payment station and use the code below to deposit with cash.": "Mergi la orice Statie de Plata OKTO.CASH si depune numerar cu ajutorul codului de mai jos.",
	"At the OKTO.CASH point, please select Winner from the menu, then introduce the payment code below. The funds will be delivered to your Winner account shortly after.": "Cand vizitezi Statia de Plata OKTO.CASH, selecteaza Winner din meniu si introdu codul de plata. Fondurile vor aparea in contul tau Winner la scurt timp dupa finalizarea platii.",
	"Find here the closest OKTO.CASH payment station": "Apasa aici si gaseste cea mai apropiata Statie de Plata OKTO.CASH",
	"Ready to deposit": "Actiune reusita",
	"I have saved the code": "Am salvat codul",
	"Amounts available via OKTO.CASH:": "Valori acceptate de OKTO.CASH:",
	"Top up your betting account with cash quickly and directly through OKTO.CASH, no bank card needed.": "Alimentează-ți contul de pariuri cu cash, direct și rapid, fără să ai nevoie de un card bancar, prin OKTO.CASH.",


	"What is OKTO.CASH?": "Ce este OKTO.CASH?",
	"OKTO.CASH is a payment method that allows you to fund your betting account with cash, without the need for a bank card.": "OKTO.CASH este o metodă de plată cu ajutorul căreia îți alimentezi contul de pariuri cu bani cash, fără să ai nevoie de un card bancar.",
	"How can I find an OKTO.CASH location?": "Cum pot identifica o locație OKTO.CASH?",
	"Access the ${startUrl}OKTO.CASH${endUrl} section from the homepage. Then, depending on your location, select the nearest terminal.": "Accesează din pagina principală secțiunea ${startUrl}OKTO.CASH${endUrl}. Apoi, în funcție de adresa ta, selectează cel mai apropiat terminal.",
	"How do I make a deposit through OKTO.CASH?": "Cum efectuez o depunere prin OKTO.CASH?",
	"Open the Winner app.": "Deschide aplicația Winner.",
	"Go to the Deposit section.": "Accesează secțiunea Depunere.",
	"Choose the OKTO.CASH payment method.": "Selectează metoda de plată prin OKTO.CASH.",
	"Enter the amount you wish to deposit. You can only deposit fixed amounts, according to the following values: 20, 50, 100, 150, 250, 500, 1000, 1500, 2500, 3000, 4500 Lei.": "Introdu suma pe care dorești să o depui. Poți depune doar sume fixe, conform următoarelor valori: 20, 50, 100, 150, 250, 500, 1000, 1500, 2500, 3000, 4500 Lei.",
	"Find the nearest terminal where you can make the deposit.": "Identifică cel mai apropiat terminal unde poți depune suma respectivă.",
	"Save the payment code generated in your player account.": "Salvează codul de plată generat în contul de jucător.",
	"On the terminal, select the Gift Card & Games category - Betting - Winner.": "Selectează, pe terminal, categoria Gift Card & Games - Betting - Winner.",
	"Type the payment code.": "Introdu codul de plată.",
	"Your funds will instantly reach your player account.": "Banii vor ajunge instant în contul tău de jucător.",
	"Cash Deposit": "Depuneri Cash",
	"Access your account and you can initiate a withdrawal from the 'Wallet' section by selecting the amount you wish to withdraw. Your request will be processed as quickly as possible.":
		"Intra în contul tău și poți iniția o retragere din secțiunea “Portofel”, selectând suma pe care vrei să o retragi. Cererea ta va fi procesată în cel mai scurt timp.",

	// cashout new
	"<div>Checking cashout</div><div>availability</div>": "<div>Verificare disponibilitate</div><div>CASHOUT</div>",
	"<div>Cashout temporarily</div><div>not available</div>": "<div>Cashout momentan</div><div>indisponibil</div>",

	// tickets placing errors
	"An error occurred: Error creating ticket": "Mesajul: Eroare la plasarea biletului",
	"Maximum stake accepted: {{LIMIT}} Lei": "Miza maxima acceptata: {{LIMIT}} Lei",

	// promo calendar
	"Congratulations!": "Felicitari!",
	"You have won these prizes!": "Ai activat premiile de astazi!",
	"There was an error": "A fost o eroare",
	"Attention!": "Atentie!",
	"Unknown error happened!": "Eroare necunoscuta!",
	"You already have active bonuses in your account. This bonus is not lost, you just have to claim it in:": "",
	"WALLET AND BONUSES": "PORTOFEL SI BONUSURI",
	"Important: You must activate it today in order not to lose the bonus!": "Important: Trebuie sa il activezi astazi pentru a nu pierde bonusul!",
	"Back to calendar": "Inapoi la calendar",
	"Deposit now": "Depune acum",
	"Collected prizes": "Premii colectate",
	"You qualify for the grand prize": "Te califici pentru marele premiu",
	"Award of the Day": "Premiul zilei",
	"Open prize": "Deschide premiu",
	"Claim now": "Revendica acum",
	"Tomorrow's prize": "Premiul de maine",
	"Come back tomorrow": "Revino maine",
	"We are preparing something special": "Iti pregatim un alt premiu",
	"Total Prizes": "Premii totale",
	"Rules": "Regulament",
	"The prize has been activated": "Premiul a fost activat",
	"Your prize": "Premiul tau",
	"Prizes in waiting": "In asteptare",

	// popup preset
	"I never want to miss a bonus, keep me informed": "Nu vreau sa ratez nici un bonus",
	"Partners": "Parteneri",
	"I want to find out first about promotions and bonuses": "Vreau sa fiu informat despre promotii si bonusuri",

	//Footer
	"This website is operated by ${NOG_company_name}, a Romanian legal entity, registered with the Trade Register under no. ${NOG_company_cui}, and having its registered office located in ${NOG_company_address}. ${NOG_company_name} holds license no. ${NOG_license_number}, issued on ${NOG_license_date_start} by the ${openTagOnjn}National Gambling Office${closeTagOnjn}, valid until ${NOG_license_date_end}. Please be advised that gambling activity may be prohibited in the jurisdiction in which you are located. It is your responsibility to comply with the applicable legislation. Gambling activity can be addictive and may also have an impact on your financial situation. Please gamble responsibly!":
		"Acest website este operat de ${NOG_company_name}, persoană juridică română, înregistrată la Registrul Comerțului sub nr. ${NOG_company_cui}, și având sediul social situat în ${NOG_company_address}. ${NOG_company_name} deține licența nr. ${NOG_license_number}, emisă la data de ${NOG_license_date_start} de ${openTagOnjn}Oficiul Național pentru Jocuri de Noroc${closeTagOnjn}, valabilă până la data de ${NOG_license_date_end}. Vă informăm cu privire la faptul că activitatea de jocuri de noroc poate fi interzisă în jurisdicția în care sunteti localizat. Este responsabilitatea dumneavoastră să respectați legislația în vigoare. Activitatea de jocuri de noroc poate cauza dependență și, totodată, poate avea un impact asupra situației dumneavoastră financiare. Va rugăm să jucați responsabil!",

	"This website is operated by ${NOG_company_name}, registered in ${NOG_country}  with number ${NOG_company_cui}  at the address listed below. ${NOG_company_name} operates under license number ${NOG_license_number}, issued by the Romanian Gambling Office (ONJN), valid from ${NOG_license_date_start} until ${NOG_license_date_end}.":
		"Acest website este operat de ${NOG_company_name}, înregistrat în ${NOG_country} cu numărul ${NOG_company_cui} la adresa menționată mai jos. ${NOG_company_name} operează sub numărul de licență ${NOG_license_number}, valabil de la data de ${NOG_license_date_start} de către Oficiul Național de Jocuri de Noroc (ONJN), cu valabilitate până la data de ${NOG_license_date_end}.",

	// ticket of the day
	"Odds+ Tickets of the Day": "Cote+ Biletele Zilei",
	"Play a prebuilt ticket-of-the-day for boosted odds": "Joaca un bilet predefinit si ai cote marite",
	"Played between": "Se joaca in intervalul",
	"seeAllTicket": "Vezi tot biletul (+{{COUNT}} pariuri)",
	"Play this ticket": "Joaca bilet",
	"Total boosted odds": "Cota totala marita",
	"Ticket of the Day": "Biletul zilei",
	"Play this ticket for boosted odds": "Joaca acest bilet si ai cote marite",
	"Odds+": "Cote+",
	"auto accept amount changes": "accepta automat modificarile de suma",
	"before": "anterior",
	"Selected on the betslip": "Adaugat pe betslip",
	"Ultra Odds": "Ultra Cote",


	// aircash
	"Amounts available via aircash:": "Valori acceptate de aircash:",
	"What is A-Bon and how can I make a deposit using this payment method?":
		"Ce este A-Bon și cum pot efectua o depunere prin această metodă de plată?",
	"A-Bon is a payment method through which you fund your account with cash based on a voucher issued at a payment station.":
		"A-Bon este o metodă de plată prin intermediul căreia îți alimentezi contul cu bani cash pe baza unui voucher emis la o stație de plată.",
	"Identify ${startUrl}here${endUrl} the location of a payments terminal near you.":
		"Identifică ${startUrl}aici${endUrl} locația unui terminal de plăți în apropiere de tine.",
	"Steps to deposit through A-Bon":
		"Pașii pentru a depune prin A-Bon",
	"Access the Deposit section":
		"Accesează secțiunea Depunere.",
	"Select the amount you want to deposit. You can only deposit fixed amounts, namely: 25, 50, 100, 150, 200, or 300 Lei.":
		"Selecteaza suma pe care dorești să o depui. Poți depune doar sume fixe, și anume: 25, 50, 100, 150, 200 sau 300 Lei.",
	"Subsequently, you will be redirected to the payments page.":
		"Ulterior, vei fi redirecționat pe pagina de plată.",
	"Enter the 16-digit code from the voucher.":
		"Introdu codul, de 16 cifre, de pe voucher.",
	"Upon validating the code, the money will instantly be credited to your player account.":
		"Odată cu validarea codului, banii vor ajunge instant în contul tău de jucator.",
	"Top up your betting account with cash quickly and directly through A-Bon, no bank card needed.":
		"Alimentează-ți contul de pariuri cu cash, direct și rapid, fără să ai nevoie de un card bancar, prin A-Bon.",

	// live tv
	"STREAMING IS NOT AVAILABLE!": "STREAMING-UL NU ESTE DISPONIBIL!",
	"Sign in to watch live matches.": "Intra in cont pentru a urmari meciurile live.",


	"Bank Transfer": "Transfer bancar",
	"IBAN is not valid": "IBAN-ul este invalid",
	"Beneficiary Name is not valid": "Numele beneficiarului este invalid",
	"Friendly Name is not valid": "Alias-ul nu este valid",
	"Beneficiary Name": "Nume beneficiar",
	"Friendly Name": "Alias",

	"This website is operated by ${NOG_company_name}, a Romanian legal entity, registered with the Trade Register under no. ${NOG_company_cui}, and having its registered office located in ${NOG_company_address}. ${NOG_company_name} holds license no. ${NOG_license_number}, valid for 10 years from ${NOG_license_date_start} until ${NOG_license_date_end}, granted by the ${openTagOnjn}National Gambling Office${closeTag} through decision no. ${openTagDecision1}${NOG_decision1}${closeTag}, and the authorization to operate remote gambling obtained through decision no. ${openTagDecision2}${NOG_decision2}${closeTag}. Please be advised that gambling activity may be prohibited in the jurisdiction in which you are located. It is your responsibility to comply with the applicable legislation. Gambling activity can be addictive and may also have an impact on your financial situation. Please gamble responsibly!":
		"Acest website este operat de ${NOG_company_name}, persoană juridică română, înregistrată la Registrul Comerțului sub nr. ${NOG_company_cui}, și având sediul social situat în ${NOG_company_address}. ${NOG_company_name} deține licența nr. ${NOG_license_number}, valabilă pentru 10 ani, începând cu data de ${NOG_license_date_start} și până la data de ${NOG_license_date_end}, acordată de ${openTagOnjn}Oficiul Național pentru Jocuri de Noroc${closeTag} prin decizia nr. ${openTagDecision1}${NOG_decision1}${closeTag}, și autorizația de exploatare a jocurilor de noroc la distanţă obținută prin decizia nr. ${openTagDecision2}${NOG_decision2}${closeTag}. Vă informăm cu privire la faptul că activitatea de jocuri de noroc poate fi interzisă în jurisdicția în care sunteti localizat. Este responsabilitatea dumneavoastră să respectați legislația în vigoare. Activitatea de jocuri de noroc poate cauza dependență și, totodată, poate avea un impact asupra situației dumneavoastră financiare. Va rugăm să jucați responsabil!",


	// bet builder texts
	"Bet Builder": "Bet Builder",
	"Combine 2 or more selections in one single match in a single bet!": "Combină 2 sau mai multe selecții dintr-un meci pe același bilet!",
	"Build your own bet": "Construiește-ți propriul pariu",
	"Learn how to place a bet using": "Învață cum poți plasa un pariu folosind",
	"Try Bet Builder!": "Încearcă Bet Builder!",
	"Combine 2 or more selections from the same event into one bet!": "Combină 2 sau mai multe selecții din același eveniment într-un bilet bilet!",
	"Choose your selections": "Alege selecțiile",
	"Combine 2 or more selections from an event by pressing the desired odds. To remove the selection, click on the selected share again.": "Combină 2 sau mai multe selecţii dintr-un eveniment, alegând cotele dorite. Pentru a şterge selecţia, apasă click pe combinaţia selectată.",
	"Non-combinable odds": "Cotele nu se pot combina",
	"Depending on the selected selections, some odds will no longer be able to be added. They will become unavailable and you will no longer be able to select them.": "În funcţie de selecţiile alese, anumite cote vor deveni indisponibile şi nu vor mai putea fi selectate.",
	"Add to ticket": "Adaugă pe bilet",
	"Once you've chosen the selections you want to combine, add them to the ticket. These will appear on your ticket combined into one bet.": "După ce ai ales selecţiile pe care doreşti să le combini, le poţi adăuga pe bilet. Acestea vor apărea pe biletul tău combinate într-o singură selecţie.",
	"Add to betslip": "Adăugă pe biletul de pariere",
	"Now available in Bet Builder": "Ai acum in Bet Builder",
	"Max 6 bets combined at a time": "Maxim 6 pariuri combinate in acelasi timp",
	"At least 10 bets combined": "Cel putin 10 pariuri combinate",
	"Bet on Scorer market": "Pariuri pe marcatori",
	"New sports: Basketball, Handball and more others": "Baschet, handbal si alte sporturi",
	"Bet Builder not available for this event": "Bet Builder indisponibil la acest eveniment",
	"There are currently no bets eligible for Bet Builder on this event. Please come back later.": "Momentan nu exista pariuri eligibile pentru Bet Builder la acest eveniment. Te rugam sa revii mai tarziu.",

	"Winning Ticket": "Bilet Castigator",
	"Lost Ticket": "Bilet Pierdut",
	"Bet Pending": "In Curs",
	"Bet Won": "Pariu Castigat",
	"Bet Lost": "Pariu Pierdut",
	"Cancelled Bet": "Pariu Anulat",
	"Pending Bet": "Pariu In Curs",

	'There are already max number (4) of "BetBuilder" bets on the betslip.': 'Ai atins numarul maxim (4) de pariuri "Bet Builder" pe betslip.',
	'If you want to create another "BetBuilder", please remove one from the betslip.': 'Daca vrei sa adaugi un nou pariu "BetBuilder", trebuie sa elimini unul de pe betslip',
	'Select at least 2 outcomes to enable Bet Builder': 'Alege cel putin 2 pariuri pentru a activa "BetBuilder"',

	"Choose your daily bonus": "Azi ai un premiu la alegere din",
	"Choose prize": "Alege premiul",

	//Popup Webswitch translations
	"POPUP_TXT_1": "UN <0>NOU</0> <1>WINNER</1>",
	"POPUP_TXT_2": "Participă la activităti cu premii și bonusuri in <0>Noul Rewards Hub.</0>",
	"POPUP_TXT_3": "Incearcă <0>noul Smart Search!</0>",
	"Faster betting experience!": "Experiență de pariere mai rapidă!",
	"A modern look!": "Un look modern!",
	"DISCOVER": "DESCOPERĂ",

	"momentumInfo1": "Daca vrei sa cunosti detaliile complete despre acest bonus, te rugam sa citesti cu atentie sectiunea de Conditii Generale de mai jos, sau accesezi acest",
	"momentumInfo2": "{{BONUS_CASH}} este un bonus acordat sub formă de bani, utilizabil pe oricare dintre secțiunile platformei. Fondurile de tip bonus cash pot fi transformate în bani reali (disponibili pentru retragere) doar după îndeplinirea cerințelor de rulaj, comunicate în cadrul condițiilor specifice ale campaniei.",
	"momentumInfo3": "Condiții generale de rulaj pentru Bonusurile oferite în secțiunea CASINO: • Factorul de rulaj aplicat valorii bonusului în bani este între 0,01X și 100X. • Factorul de rulaj aplicat valorii câștigului net generat de Free Spins este între 1X și 100X. • Jocurile eligibile pentru utilizarea Free Spin-urilor, rulajul bonusului în bani sau a câștigului net generat de Free Spins, sunt specificate în descrierea fiecărei campanii de bonusare, împreună cu alte condiții speciale în această privință. • Unele jocuri pot avea cerințe de rulare diferite, astfel că valoarea care contribuie la rulajul bonusului poate fi cuprinsă între 1% și 100%, din valoarea pariului plasat. Condițiile specifice de rulaj vor fi comunicate în prealabil jucătorilor de pe platformă.",
	"Collect the stars": "Adună stelele",
	"Win the prize!": "Câștigă premiul!",
	"Spin to fill the bar": "Rulează ca să umpli bara",
	"Fill the bar to collect stars": "Umple bara ca să aduni stele",
	"Collect all the stars to claim the prize!": "Adună toate stelele ca să câștigi premiul!",
	"You've won": "Ai câștigat",
	"CLAIM PRIZE": "REVENDICĂ PREMIUL",
	"Continue playing to activate the cash prize!": "Continuă să joci pentru a activa premiul în bani!",
	"Time's up!": "Timpul a expirat!",
	"Come back next time to try again!": "Mult noroc data viitoare!",
	"Play on!": "Joacă în continuare!",
	"Progress still tracked!": "Progresul este contorizat",
	"Keep playing!": "Continuă să joci!",
	"We are experiencing some temporary technical difficulties and we can't display your progress.": "Momentan, progresul nu poate fi afișat din cauza unor probleme tehnice.",
	"Don't worry!": "Nicio grijă!",
	"Your progress is still being tracked.": "Progresul tău este contorizat în continuare.",
	"What about the prize?": "Ce se întâmplă cu premiul?",
	"If you complete the wagering requirements you will see your prize in your account as soon as full service functionality is restored.": "Dacă îndeplinești cerințele de rulaj, vei vedea premiul în contul tău deîndată ce funcționarea serviciilor este reluată.",
	"Back to game": "Inapoi la joc",
	"Cancel bonus": "Anuleaza bonusul",
	"This game is not eligible": "Acest joc nu este eligibil",
	"This game is eligible": "Acest joc este eligibil",
	"PAID": "A PLĂTIT",
	"For further information, please contact the customer support team.": "Pentru mai multe informații, contactează echipa de suport clienți.",
	"momentumInfo4": "Pentru mai multe detalii te rugăm să accesezi <1>termenii și condițiile</1> campaniei.",
	"YOU WON": "AI CÂȘTIGAT",

	//Banner Webswitch translations
	"BANNER_TXT_1": "NOUL WINNER <0>NEW</0>",
	"Participate in activities with prizes and bonuses in the New Rewards Center": "Participă la activităti cu premii și bonusuri in Noul Rewards Hub",
	"Try the New Smart Search!": "Incearcă noul Smart Search!",
	"Play Hot & Cold games, now available!": "Joacă jocurile Hot & Cold, acum la îndemână!",

	"Expires soon": "Expiră în",

	//Layout Messages translations
	"MSG_TXT_1": "<0>DESCOPERĂ</0> <1>UN NOU WINNER</1>"

};
