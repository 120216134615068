import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import LoggedIn from "./LoggedIn/LoggedIn";
import './GlobalWidgets.scss';
import Both from "./Both/Both";
import LoggedOut from "./LoggedOut/LoggedOut";
import LanguageSwitcher from "./Both/LanguageSwitcher/LanguageSwitcher";

class GlobalWidgets extends Component {
    render() {
        let comp = [];
        if ("user" === this.props.authentication.auth_type) {
            comp.push(<LoggedIn key={'logged_in_widgets'}/>);
        } else {
            comp.push(<LoggedOut key={'logged_out_widgets'}/>);
        }
        return (
            <Fragment>
                <div id={"global-widgets"} className={this.props.show ? '' : 'hide'}>
                    {comp}
                    <Both/>

                </div>
            </Fragment>

        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
        show: state.application.showGlobalWidgets,
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(GlobalWidgets);
