/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 19/11/2018
 * Time: 13:22
 */
import React, { Component } from 'react';
import './Reamaze.scss';
import ChatBubble from "./Parts/ChatBubble";

class LiveChatContainer extends Component {

    render() {
        return ( <div id={'live-chat-menu-button'}>
            {window.config && window.config.live_chat_left_menu_button_id_MOBILE && <ChatBubble parentId={'live-chat-menu-button'} buttonId={window.config.live_chat_left_menu_button_id_MOBILE}/>}
        </div> );
    }
}

export default LiveChatContainer;

