import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import VivaAPI from '../../ClientAPI/VivaAPI';
import * as paths from '../../ClientAPI/paths';
import Utils from "../../utils/Utils";
import PlayerAbuseChecker from "../../utils/PlayerAbuseChecker";

export function* requestPaymentLinkSaga(action) {
    const axios = ClientAPI.getInstance();
    const state = ClientAPI.getStore().getState();
    try {
        let data = {
            amount: window.config.skrill_paysafe_enabled === '1' ? action.amount * 100 : action.amount,
            bonus_id: action.bonus_id,
            currencyCode: state.wallet.currency,
            languageCode: state.application.language,
            clientId: window.config.clientId,
            events: getLogEvents(),
        };

        let pac = PlayerAbuseChecker.getInfo();
        data = {
            ...pac,
            ...data
        };
        console.log(paths.paymentCheckout);
        const platformType = window.config.cordova ? window.config.native_platform === "ios" ? 3 : 4 : window.config.platformType;
        data.platformType = platformType;

        const response = yield axios({
            url: window.config.skrill_paysafe_enabled === '1' ? paths.paymentCheckout.SKRILL_PAYSAFE_CHECKOUT : paths.paymentCheckout.PAYMENT_CHECKOUT,
            method: 'post',
            data: data
        });

        if (!response) {
            throw new Error(`[ERROR] Payment Checkout response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Payment Checkout response has no 'result' property`);
        }
        if(window.config.skrill_paysafe_enabled === '1') {
            if (response.result.hasOwnProperty('sessionId')) {
                response.result = {
                    link: `https://pay.skrill.com/?sid=${response.result.sessionId}&return_url=${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/deposit-final`
                }
            }
        }
        yield put(actions.receivedPaymentLink(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

function getLogEvents(max = 50) {

    let events = ClientAPI.getStore().getState().application.eventLog;
    if (events) {
        events.slice(-50);
    } else {
        events = [];
    }

    return events;
}

export function* requestVivaTokenSaga(action) {
    const axios = ClientAPI.getInstance();

    try {
        const response = yield axios({
            url: paths.paymentCheckout.VIVA_TOKEN,
            method: 'get',
            data: {
                events: getLogEvents()
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Request Viva Token response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Viva Token response has no 'result' property`);
        }

        if (response.result && response.result.access_token) {
            VivaAPI.setToken(response.result.access_token);
        }

        yield put(actions.receivedVivaToken(true));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestVivaCardTokenSaga(action) {
    const axios = yield Utils.getAxiosWithToken();
    try {
        const response = yield axios({
            url: paths.paymentCheckout.VIVA_CARD_TOKEN,
            method: 'get',
            data: {
                events: getLogEvents()
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Request Viva Card Token response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Viva Card Token response has no 'result' property`);
        }

        yield put(actions.receivedVivaCardToken(response));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestVivaWithdrawSaga(action) {
    const axios = ClientAPI.getInstance();
    try {
        let data = {
            amount: action.amount,
            cardToken: action.token,
            events: getLogEvents(),
        };
        let pac = PlayerAbuseChecker.getInfo();
        data = {
            ...pac,
            ...data
        }
        const response = yield axios({
            url: paths.paymentCheckout.VIVA_WITHDRAW,
            method: 'POST',
            data: data
        });

        if (!response) {
            throw new Error(`[ERROR] Request Viva Card Token response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Viva Card Token response has no 'result' property`);
        }
        yield put(actions.receivedWithdraw(response.result));
        yield put(actions.requestWallet());
        yield put(actions.requestPendingVivaWithdrawals());
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* sendVivaTokenSaga(action) {
    const axios = ClientAPI.getInstance();
    let amount = null;
    let bonusId = null;
    let chargeToken = null;
    let redirectToACSForm = null;

    if (action && action.data && action.amount) {
        amount = action.amount;
        chargeToken = action.data.chargeToken;
        if (action.bonusId) {
            bonusId = action.bonusId;
        }
    }

    try {
        let data = {
            "chargeToken": chargeToken,
            // "redirectToACSForm": redirectToACSForm,
            "amount": amount,
            "bonusId": bonusId ? bonusId : -1,
            "holderName": action.holderName,
            events: getLogEvents(),
        }
        let pac = PlayerAbuseChecker.getInfo();
        data = {
            ...pac,
            ...data
        }
        const response = yield axios({
            url: paths.paymentCheckout.SEND_VIVA_TOKEN,
            method: 'post',
            data: data
        });

        if (!response) {
            throw new Error(`[ERROR] Send Viva Token response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Send Viva Token response has no 'result' property`);
        }

        yield put(actions.receivedVivaChargeToken(response));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* requestVivaChargeTokenSaga(action) {
    const axios = VivaAPI.getInstance();

    let amount = 0;
    let bonusGroupId = null;
    let playerData = action.playerData;
    let data = {
        amount: playerData.amount,
        cvc: playerData.cvc,
        number: parseInt(playerData.number.replace(/\s/g, '')),
        holderName: playerData.holderName,
        expirationYear: parseInt("20" + playerData.monthYear.substring(3, 5)),
        expirationMonth: parseInt(playerData.monthYear.substring(0, 2))
    };

    if (action.playerData.amount) {
        amount = action.playerData.amount;
    }

    if (action.playerData.bonusGroupId) {
        bonusGroupId = action.playerData.bonusGroupId;
    }

    try {
        yield put(actions.logEvent('request viva charge token: ' + amount));

        const response = yield axios({
            url: paths.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN,
            method: 'post',
            data: JSON.stringify(data)
        });

        if (!response) {
            throw new Error(`[ERROR] Viva Charge Token response is empty!`);
        }

        if (!response.hasOwnProperty('data')) {
            throw new Error(`[ERROR] Viva Charge Token response has no 'data' property`);
        }

        yield put(actions.sendVivaChargeToken(response.data, amount, bonusGroupId));
    } catch (error) {
        console.log(error);
        yield put(actions.receivedVivaChargeToken(error));
    }
}
export function* requestVivaChargeTokenWithCardSaga(action) {

    const axios = VivaAPI.getInstance();
    let cardToken = action.cardToken;
    let playerData = action.playerData;
    let amount = playerData.amount * 100;
    let bonusId = null;
    if (action.playerData.bonusId) {
        bonusId = action.playerData.bonusId;
    }

    try {
        yield put(actions.logEvent('request viva charge token with card: ' + amount));
        const response = yield axios({
            url: paths.paymentCheckout.REQUEST_VIVA_CHARGE_TOKEN,
            method: 'POST',
            data: {
                "token": cardToken,
                "amount": amount,
                "sessionRedirectUrl": window.location.protocol + '//' + window.location.hostname + (window.location.port ? ":" + window.location.port : '') + '/deposit-final',
            }
        });

        if (!response) {
            throw new Error(`[ERROR] Viva Charge Token With Card response is empty!`);
        }

        if (!response.hasOwnProperty('data')) {
            throw new Error(`[ERROR] Viva Charge Token With Card response has no 'chargeToken' property`);
        }
        if (response.data.hasOwnProperty('redirectToACSForm') && response.data.redirectToACSForm) {


            window.localStorage.setItem('deposit', JSON.stringify({
                data: {
                    chargeToken: response.data.chargeToken
                },
                amount: amount,
                bonusId: bonusId
            }));

            let wrapper = document.getElementById('validation3ds');
            wrapper.className = 'show';
            let range = document.createRange();
            let documentFragment = range.createContextualFragment(response.data.redirectToACSForm);
            wrapper.appendChild(documentFragment);
            // window.jQuery('validation3ds').append(response.data.redirectToACSForm);
            // let form = document.getElementById("tdsMmethodForm");
            // if(form) {
            //     form.submit()
            // };

        } else {
            yield put(actions.sendVivaChargeToken(response.data, amount, bonusId));
        }
    } catch (error) {
        console.log(error);
        yield put(actions.receivedVivaChargeToken(error));
    }
}

export function* requestOktoCreatePaymentCodeSaga(action) {
    const axios = ClientAPI.getInstance();
    try {

        let data = {
            amount: action.amount,
            currencyId: 1,
        };

        if (action.bonusId) {
            data.bonusId = action.bonusId;
        }

        let pac = PlayerAbuseChecker.getInfo();
        data = {
            ...pac,
            ...data
        };

        const platformType = window.config.cordova ? window.config.native_platform === "ios" ? 3 : 4 : window.config.platformType;
        data.platformType = platformType;

        const response = yield axios({
            url: paths.paymentCheckout.OKTO_CREATE_PAYMENT_CODE,
            method: 'post',
            data: data
        });

        if (!response) {
            throw new Error(`[ERROR] Request Okto Create Payment Code response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Okto Create Payment Code response has no 'result' property`);
        }

        yield put(actions.oktoReceivedPaymentCode(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}

export function* oktoGetCustomerSaga(action) {
    const axios = ClientAPI.getInstance();
    try {

        const response = yield axios({
            url: paths.paymentCheckout.OKTO_GET_CUSTOMER,
            method: 'GET',
        });

        if (!response) {
            throw new Error(`[ERROR] Request Okto GET Customer response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Request Okto GET CUSTOMER response has no 'result' property`);
        }

        yield put(actions.oktoReceivedCustomer(response.result));
    } catch (error) {
        console.log(error);
        // yield put(actions.oktoReceivedCustomer({
        //     "active_auto_exclusion": null,
        //     "amount_can_deposit": 4500,
        //     "account_verified": 1,
        //     "okto_terms_accepted": 1,
        //     "okto_terms_modified": 1697447934,
        //     "okto_customer_date": 1697447934
        // }));
        return;
    }
}


export function* aircashGetPaymentLinkSaga(action) {
    const axios = ClientAPI.getInstance();
    const state = ClientAPI.getStore().getState();
    try {
        let data = {
            amount: action.amount,
            bonus_id: action.bonusId,
            currencyCode: state.wallet.currency,
            currencyId: 1,
            languageCode: state.application.language,
            clientId: window.config.clientId,
            events: getLogEvents(),
        };

        let pac = PlayerAbuseChecker.getInfo();
        data = {
            ...pac,
            ...data
        };
        const platformType = window.config.cordova ? window.config.native_platform === "ios" ? 3 : 4 : window.config.platformType;
        data.platformType = platformType;

        const response = yield axios({
            url: paths.paymentCheckout.AIRCASH_REQUEST_PAYMENT_LINK,
            method: 'post',
            data: data
        });

        if (!response) {
            throw new Error(`[ERROR] Payment Checkout response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Payment Checkout response has no 'result' property`);
        }

        if (response.result.hasOwnProperty('url')) {
            response.result = {
                link:response.result.url,
            }
        }

        yield put(actions.receivedPaymentLink(response.result));
    } catch (error) {
        console.log(error);
        return;
    }
}
