import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withTranslation } from "react-i18next";

import Drawer from "./Drawer";

import LottoQuickBet from "./LottoQuickBet";
import LottoBetsFull from "./LottoBetsFull";
import { ReactComponent as BetSlipPreMatch } from "./assets/betslipPrematch.svg";
import EvBus from "../../../utils/evbus";

const useStyles = makeStyles({
  root: {},
  modal: {
    position: "relative",
    margin: "10px auto 0 auto",
    backgroundColor: "white",
    width: "100%",
    maxWidth: "400px",
    minHeight: "95%",
    borderTopLeftRadius: "23px",
    borderTopRightRadius: "23px",
    boxShadow: "0px -2px 8px 0px rgba(0,0,0,0.35)",
    overflow: "visible"
  },
  mt30: {
    marginTop: `calc(30px + var(--notch-top, 0px))`
  },
  betSlip: {
    backgroundColor: "#fff",
    borderRadius: "23px 23px 0 0",
    width: "100%",
    boxShadow: "0px -2px 12px 0px rgba(0,0,0,0.75)"
  },
  modalFull: {
    backgroundColor: "#EEF0F1",
    background: "linear-gradient(to bottom, #EEF0F1 30%, #D2DBE7 30%)",
    height: `calc(100% - 30px - var(--notch-top, 0px))`
  },
  betSlipContainer: {
    padding: "40px 20px 0 20px",
    height: "100px"
  },
  toggle: {
    backgroundColor: "#B7CDD8",
    borderRadius: "3px",
    margin: "8px auto 20px auto",
    width: "67px",
    height: "5px",
    cursor: "pointer",
    position: "absolute",
    top: "0",
    left: "calc(50% - 34px)"
  },
  wrapperToggle: {
    //margin: "-5px -14px 0px -14px",
    borderTopLeftRadius: "23px",
    borderTopRightRadius: "23px",
    //padding: "0 14px",
    position: "relative"
  },
  toggleError: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "14px",
    right: "14px",
    borderTopLeftRadius: "23px",
    borderTopRightRadius: "23px"
  },
  toggleText: {
    color: "#fff",
    borderTopLeftRadius: "23px",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: "11px",
    paddingLeft: "20px",
    display: "inline-block",
    height: "24px",
    lineHeight: "24px",
    position: "relative",
    top: "-1px",
    paddingRight: "5px"
  },
  togglePreMatch: {
    backgroundColor: "#1F84E6"
  },
  toggleLive: {
    backgroundColor: "#ff196e"
  },
  toggleBorderSvg: {
    position: "absolute",
    height: "24px",
    width: "auto",
    top: "0",
    right: "-65px"
  },
  barBottom: {
    backgroundColor: "#fff",
    position: "absolute",
    height: "650px",
    bottom: "-640px",
    left: 0,
    right: 0
  },
  barBottomFull: {
    backgroundColor: "#D2DBE7",
    height: "90px",
    bottom: "-80px"
  },
  card: {
    padding: "5px 14px 0px 14px"
  },
  cardFull: {
    //padding: "5px 0 20px",
    height: "100%",
    "& .toggle": {
      margin: "2px auto 5px auto"
    }
  },
  h100: {
    height: "100%"
  },
  transitionHeight: {
    transition: "height 150ms ease-out"
  },
  heightLottoQuickBet: {
    height: "154px"
  },
  mb5: {
    marginBottom: "5px"
  },
  mt0: {
    marginTop: "-1px"
  },
  errorSelection: {
    color: "#ff196e",
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "center",
    padding: "6px 0 4px"
  },
  errorSelectionLive: {
    backgroundColor: "#fff0f6"
  },
  errorSelectionLiveB: {
    borderBottom: "1px solid #ffb2d3"
  },
  errorSelectionPreMatch: {
    backgroundColor: "#E8F4FF"
  },
  errorSelectionPreMatchB: {
    borderBottom: "1px solid #82B7E9"
  },
  winnerWrapper: {
    position: "absolute",
    top: "4px",
    right: "30px"
  },
  xtra: {
    position: "absolute",
    top: 0,
    right: "30px",
    fontSize: "11px",
    color: "#2C324B",
    "& .-betslip-highlight": {
      fontSize: "13px",
      fontWeight: "bold"
    }
  }
});

let lastScroll = 0;

const BetSlip = props => {
  const classes = useStyles();
  const { selectError, lottoTicket, t, location } = props;

  const root = document.body;
  const isDesktop = useMediaQuery('(min-width:1024px');

  const [toggle, setToggle] = React.useState(false);
  const [opened, setOpened] = React.useState(false);
  const [inited, setInited] = React.useState(false);

  const handleToggle = (event, newValue) => {
    setToggle(!toggle);
  };

  React.useEffect(() => {
    if (lottoTicket || selectError) {
      setOpened(true);
    } else {
      setOpened(false);
      setToggle(false);
    }

    if (lottoTicket && !inited) {
      setInited(true);
      if (lottoTicket.fastBetting)
        setToggle(true);
    }
  }, [lottoTicket, selectError, inited]); // eslint-disable-line

  const hideBet = () => {
    setOpened(false);
  };

  let modalClass = classes.modal;
  let barBottomClass = classes.barBottom;
  let stateWrapper = "";

  const openDrawers = {
    reduced: false,
    full: false,
    state: {
      quickLotto: false,
    }
  };

  root.classList.remove(
    "no-scroll",
    "betslip-on",
    "quick-bet",
    "lotto-quick-bet",
    "bet-builder",
    "summary-bet",
    "mini-bet",
    "full-bet"
  );

  const cleanBodyStyle = () => {
    if (root.style.position === "fixed") {
      root.style.position = "";
      root.style.top = "";
      root.style.bottom = "";
      root.style.left = "";
      root.style.right = "";
    }
  };

  if (location.pathname.indexOf("/profile") > -1) {
    cleanBodyStyle();
    if (toggle) setToggle(false);
    return null;
  }

  if (location.pathname.indexOf("/connect") > -1) {
    cleanBodyStyle();
    if (toggle) setToggle(false);
    return null;
  }
  if (location.pathname.indexOf("/deposit") > -1) {
    cleanBodyStyle();
    if (toggle) setToggle(false);
    return null;
  }
  if (location.pathname.indexOf("/nsoft") > -1) {
    cleanBodyStyle();
    return null;
  }
  if (isDesktop && location.pathname.indexOf("/lotto") > -1) {
    cleanBodyStyle();
    return null;
  }

  if (opened) {
    if (lottoTicket) {
      if (!toggle) {
        openDrawers.reduced = true;
        if (lottoTicket) {
          root.classList.add("betslip-on", "lotto-quick-bet");
          openDrawers.state.quickLotto = true;
          stateWrapper = classes.heightLottoQuickBet;
        }
      } else if (toggle) {
        openDrawers.full = true;
        modalClass = `${classes.modal} ${classes.modalFull} ${classes.mt30}`;
        barBottomClass = `${classes.barBottom} ${classes.barBottomFull}`;
      }
    } else {
      if (toggle) {
        setToggle(!toggle);
      }
    }
  }

  if (openDrawers.full) {
    lastScroll = -1 * document.documentElement.scrollTop;
    root.style.position = "fixed";
    root.style.top = `${lastScroll}px`;
    root.style.bottom = "0";
    root.style.left = "0";
    root.style.right = "0";
    root.classList.add("no-scroll", "full-bet");
    EvBus.emit('BET-SLIP-FULL', true);
  } else {
    if (root.style.position === "fixed") {
      root.style.position = "";
      root.style.top = "";
      root.style.bottom = "";
      root.style.left = "";
      root.style.right = "";
      document.documentElement.scrollTop = -1 * lastScroll;
      EvBus.emit('BET-SLIP-FULL', false);
    }
  }

  const handleDragOpen = willOpen => {
    if (willOpen) {
      if (opened && (lottoTicket) && !toggle) {
        setToggle(true);
      }
    }
  };

  return (
    <React.Fragment>
      <Drawer
        open={openDrawers.reduced}
        modalElementClass={modalClass}
        top={false}
        onRequestClose={hideBet}
        notifyWillOpen={handleDragOpen}
        openOffset={-100}
      >
        {(openDrawers.state.quick || openDrawers.state.multi || openDrawers.state.quickLotto) && (
          <React.Fragment>
            <div className={classes.wrapperToggle}>
              <div
                className={`${classes.toggleText} ${classes.togglePreMatch}`}
              >
                <span>{lottoTicket ? `${t("Lotto")} ${lottoTicket.event.r}/${lottoTicket.event.n}` : ""} </span>
                <BetSlipPreMatch className={classes.toggleBorderSvg} />
              </div>
              <div className={classes.toggle} onClick={handleToggle}></div>
              <div className={classes.xtra}><span className="-betslip-highlight">{lottoTicket.allSelectedEvents.length}</span> {t("draws")}</div>
            </div>
          </React.Fragment>
        )}
        <div className={classes.card}>
          <div className={`${classes.transitionHeight} ${stateWrapper}`}>
            {openDrawers.state.quickLotto && <LottoQuickBet
              lottoTicket={lottoTicket}
              onToggle={handleToggle}
            />}
          </div>
        </div>
        <div className={barBottomClass}></div>
      </Drawer>
      <Drawer
        open={openDrawers.full}
        modalElementClass={modalClass}
        top={true}
        onRequestClose={handleToggle}
        dontApplyListeners={true}
      >
        <div className={classes.cardFull}>
          <div className={`${classes.wrapperToggle} ${classes.mb5}`}>
            <div
              className={`${classes.toggleText} ${classes.togglePreMatch}`}
            >
              <span>{lottoTicket ? `${t("Lotto")} ${lottoTicket.event.r}/${lottoTicket.event.n}` : ""}</span>
              <BetSlipPreMatch className={classes.toggleBorderSvg} />
            </div>
          </div>
          <div className={classes.h100}>
            {lottoTicket && <LottoBetsFull onToggle={handleToggle} />}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

BetSlip.propTypes = {
  liveBets: PropTypes.array,
  prematchBets: PropTypes.array
};

BetSlip.defaultProps = {
  liveBets: [],
  prematchBets: []
};

export default withRouter(withTranslation()(BetSlip));
