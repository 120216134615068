import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, ListItem, ListItemIcon } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Img from 'react-image';
import ArrowRight from "@material-ui/core/SvgIcon/SvgIcon";
import Icons from "../../../assets/Icons/Icons";
import * as storeActions from "../../../store/actions";

const styles = theme => ({
    margin: {
        margin: theme.spacing(4),
    },
});

const ProfileDetails = (props) => {
    const classes = props.classes;
    const { t } = useTranslation();

    if (!props.profile.email) {
        return null;
    }

    let profileMale = `${window.config.front_url}/img/mobile/profile/icon_profile_male.svg`;
    let profileFemale = `${window.config.front_url}/img/mobile/profile/icon_profile_female.svg`;
    let avatarURL = profileMale;
    if (typeof props.profile !== "undefined") {
        if (typeof props.profile.nin !== 'undefined' && props.profile.nin !== null) {
            avatarURL = props.profile.nin[0] % 2 ? profileMale : profileFemale;
        }
    }

    const clickHandler = (event) => {
        props.closeLeftMenu();
        props.history.push('/profile/account');
    };

    const goToDeposit = (event) => {
        props.closeLeftMenu();
        props.history.push('/deposit');
        props.isDeposit();
    }

    const goToWithdraw = (event) => {
        props.closeLeftMenu();
        props.history.push('/withdraw');
        props.isWithdraw();
    }

    const verifyDocuments = () => {
        let documentsOk = true;

        if (!props.profile.email_verified) {
            documentsOk = false;
        } else if (props.documents) {
            if (props.documents.documentsRequest) {
                let documents = props.documents.documentsRequest;
                for (let i = 0; i < documents.length; i++) {
                    if (documents[i].status !== 8 && documents[i].type !== 5) {
                        documentsOk = false;
                        break;
                    }
                }
            }
        }

        return documentsOk;
    };

    return (
        <div className={"rightMenuDrawer profileDetails"}>
            <ListItem button onClick={clickHandler}>
                <Grid container spacing={0}>
                    <Grid item xs={4} sm={2} className={"playerImgArea"}>
                        <Img src={avatarURL} alt="profileImg" className="profileImg" />
                    </Grid>
                    <Grid item xs={8} sm={10} className={"playerName"}>
                        <div className="NameRow">
                            <h4>{props.profile.first_name} {props.profile.last_name}</h4>
                            <div className="RightIcon">
                                {!verifyDocuments() && Icons.get('exclamationPoint', 'ExclamationIcon')}
                                <svg className="MuiSvgIcon-root icon-description" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                                    role="presentation">
                                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                </svg>
                            </div>
                        </div>
                        <p>{t("Profile details")}</p>
                    </Grid>
                </Grid>
            </ListItem>
            <div className={"ActionsButtons"}>
                <button className={"WithdrawButton"} onClick={goToWithdraw}>
                    {Icons.get('withdrawCoin', `WithdrawIcon`)}
                    <span>{t('Withdraw')}</span>
                </button>
                <button className={"DepositButton"} onClick={goToDeposit}>
                    {Icons.get('depositCoin', `DepositIcon`)}
                    <span>{t('Deposit')}</span>
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        profile: state.profile,
        documents: state.documents,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        closeLeftMenu: () => {
            dispatch(storeActions.closeLeftMenu());
            dispatch(storeActions.closeRightMenu());
        },
        isDeposit: () => dispatch(storeActions.isDeposit(true)),
        isWithdraw: () => dispatch(storeActions.isWithdraw(true))
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileDetails)));
