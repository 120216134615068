import React, { useEffect, Fragment, useState } from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import CircularProgress from '@material-ui/core/CircularProgress';
import TitleBar from '../UI/TitleBar/TitleBar';
import Icons from '../../assets/Icons/Icons';
import SingleAction from './ProfileActions/SingleAction/SingleAction';
import Img from 'react-image';
import './Profile.scss';
import {STATUS_APPROVED, TYPE_CUSTOM} from "./ProfileActions/Documents/Documents";

const Profile = props => {
  const [t] = useTranslation();
  useEffect(() => {
    if (props.auth.auth_type !== "user") {
      props.history.push("/");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    verifyDocuments();
    // eslint-disable-next-line
  }, [props.documents]);

  let profileBG = `${window.config.front_url}/img/mobile/profile/profile_header_image.jpg`;
  let profileMale = `${window.config.front_url}/img/mobile/profile/icon_profile_male.svg`;
  let profileFemale = `${window.config.front_url}/img/mobile/profile/icon_profile_female.svg`;

  let avatarURL = profileMale;
  if (typeof props.profile !== "undefined") {
    if (typeof props.profile.nin !== 'undefined' && props.profile.nin !== null) {
      avatarURL = props.profile.nin[0] % 2 ? profileMale : profileFemale;
    }
  }

  const [docsOk, setDocsOk] = useState(true);

  const verifyDocuments = () => {
    if (!props.profile.email_verified) {
      setDocsOk(false);
    } else if (props.documents) {
      if (props.documents.documentsRequest) {
        let documents = props.documents.documentsRequest;
        for (let i = 0; i < documents.length; i++) {
          if (documents[i].status !== STATUS_APPROVED && documents[i].type !== TYPE_CUSTOM) {
            setDocsOk(false);
            break;
          }
        }
      }
    }
    return true;
  }

  let pageContent = <CircularProgress className={'Loading'} />;
  if (!props.profile.loading) {

    const actionList = [
      {
        id: 'idCard',
        link: '/profile/documents',
        icon: Icons.get('card', 'ProfileIcon'),
        title: props.t('Documents'),
        subtitle: docsOk ? props.t('Verified') : props.t('Requires verification') + '!',
        actionText: props.t('Edit'),
        docsOk: docsOk,
      },
      {
        id: 'password',
        link: '/profile/change-password',
        icon: Icons.get('lock', 'ProfileIcon'),
        title: props.t('Password'),
        subtitle: '*************',
        actionText: props.t('Edit'),
        docsOk: docsOk,
      },
      {
        id: 'phone',
        link: '/profile/change-phone-number',
        icon: Icons.get('smartphone', 'ProfileIcon'),
        title: props.t('Mobile number'),
        subtitle: props.profile.phone,
        actionText: props.t('Edit'),
        docsOk: docsOk,
      },
      {
        id: 'address',
        link: null,
        icon: Icons.get('address', 'ProfileIcon'),
        title: props.t('Address'),
        subtitle: props.profile.address,
        actionText: '',
        docsOk: docsOk,
      },
      {
        id: 'language',
        link: null,
        icon: Icons.get('flag', 'ProfileIcon'),
        title: props.t('Language'),
        subtitle: '',
        actionText: '',
        docsOk: docsOk,
      },
      {
          id: 'preferences',
          link: '/profile/notifications',
          icon: Icons.get('settings', 'ProfileIcon'),
          title: props.t('Preferences'),
          subtitle: props.t('Set up your options'),
          actionText: Icons.get('profileMenuArrow', 'EditArrow'),
          docsOk: docsOk,
      },
    ];

    let pageList = '';
    pageList = actionList.map((listItem, index) => (
      <SingleAction
        key={index}
        id={listItem.id}
        link={listItem.link}
        icon={listItem.icon}
        title={listItem.title}
        subtitle={listItem.subtitle}
        action={listItem.actionText}
        docsOk={listItem.docsOk}
      />
    ));
    pageContent = (
      <Fragment>
        <div className={"profileInfoContainer"} style={{ backgroundImage: `url(${profileBG})` }}>
          <div className="profileAvatar">
            <div className="profileAvatarOuter">
              <div className="profileAvatarInner">
                <Img src={avatarURL} alt="profileImg" className="profileImg" />
              </div>
            </div>
          </div>
          <div className="profileInfo">
            <h2>{props.profile.first_name + ' ' + props.profile.last_name}</h2>
            <span>{props.profile.email}</span>
            {/*<span>{props.t('Account number')}: {props.profile.client_player_id}</span>*/}
          </div>
        </div>
        <div className="profileActions">
          {pageList}
        </div>
      </Fragment>
    );
  }
  return (
    <div className={"profileScreen"}>
      <TitleBar pageTitle={props.t('Account Details')} />
      {pageContent}
    </div>
  );

}

const mapStateToProps = state => {
  return {
    application: state.application,
    profile: state.profile,
    auth: state.authentication,
    documents: state.documents,
  }
};


export default withTranslation()(withRouter(connect(mapStateToProps, null)(Profile)));
