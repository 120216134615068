import * as actionTypes from '../actions/actionTypes';

const initialState = {
    notch: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    orientation: "portrait"
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.layout.UPDATE_NOTCH:
            //console.log("new notch size", action);

            return {
                ...state,
                notch: action.notch,
            };
        case actionTypes.layout.UPDATE_ORIENTATION:
            //console.log("new orientation", action);

            return {
                ...state,
                orientation: action.orientation,
            };
        default:
            return state;
    }
};

export default reducer;
