import React, { Fragment, Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import TitleBar from '../UI/TitleBar/TitleBar';
import Promotion from './Promotion/Promotion';
import './Promotions.scss';
import connect from "react-redux/es/connect/connect";
import BonusAvailableWidget from "../GlobalWidgets/LoggedIn/Bonus/BonusAvailableWidget";
import * as storeActions from "../../store/actions";
import Icons from "../../assets/Icons/Icons";
// import HorizontalScrollingMenu from "../UI/HorizontalScrollingMenu/HorizontalScrollingMenu";

import WheelCTAPromotions from "../../wheel/components/cta-promotions";
import WinterCTAPromotions from "../../promo/components/cta-promotions";

const FEATURED_CARD_STEP = 3;

class Promotions extends Component {

	// componentDidMount() {
	// 	if (!this.props.promotions.length) {
	// 		this.props.getPromotions();
	// 	}
	// }
	state = {
		bonusSelected: false
	}
	onClickedBonus(bonusId, bonus) {
		this.props.claimBonus(bonusId);
		this.setState({
			...this.state,
			bonusSelected: bonus
		})
	}

	render() {
		let pageContent = [];


		if (this.props.promotions.length) {

			for (let i = 0; i < this.props.promotions.length; i++) {
				pageContent.push(<Promotion promotion={this.props.promotions[i]} featured={(i) % FEATURED_CARD_STEP === 0} key={"promotions_" + i} />)
			}
		} else {
			pageContent = (
				<Fragment>
					<Promotion dummy featured />
					<Promotion dummy />
					<Promotion dummy />
				</Fragment>

			);
		}

		// let menuItems = [
		// 	{
		// 		"to": '/cazino/promotii',
		// 		"name": "test",
		// 		"icon": "cherries",
		// 		"color": "#ff0000"
		// 	},
		// ];

		return (
			<div className={'PromotionsScreen'}>
				<TitleBar pageTitle={this.props.t('Promotions')} />
				{/*<HorizontalScrollingMenu items={menuItems}/>*/}
				<div className='PromotionsScreenContent'>
					{(window.config && window.config.promoEnabled === "1") && <WinterCTAPromotions />}
					{(window.config && window.config.wheelEnabled === "1") && <WheelCTAPromotions />}
					<BonusAvailableWidget selected={this.state.bonusSelected} type={'promo-page'} onClickedBonus={this.onClickedBonus.bind(this)} cta_text={this.props.t("CLAIM NOW")} />
					<h1>{Icons.get('hotGameIcon')} {this.props.t('Promotions')}</h1>
					{pageContent}
				</div>
			</div>
		);
	}
};
const mapStateToProps = state => {
	return {
		promotions: state.application.promotions
	}
};

const mapDispatchToProps = dispatch => {
	return {
		claimBonus: (bonusId, force) => dispatch(storeActions.claimBonus(bonusId, force)),
	};
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Promotions)));
