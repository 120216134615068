import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import * as storeActions from "../../../../store/actions";
import Icons from '../../../../assets/Icons/Icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import './DepositAndWithdrawals.scss';
import Button from '@material-ui/core/Button';
import SingleTransaction from './SingleTransaction/SingleTransaction';

const REQUEST_TYPE = 2; // Deposit and Withdrawals
const STATUS_PENDING_APPROVAL = 1;
const TYPE_DEPOSIT_CASH = 1;
const TYPE_DEPOSIT_SAFECHARGE = 24;
const TYPE_DEPOSIT_VIVA = 53;

class DepositAndWithdrawals extends Component {
    
    constructor(props) {
        super(props);
    }

    state = {
        exportDisabled: false
    }

    componentDidMount() {
        if(this.props.auth.auth_type !== "user"){
            this.props.history.push("/");
        }else{
            this.props.onRequestTransactionsHistory(REQUEST_TYPE);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.transactions.depositsWithdrawals !== prevProps.transactions.depositsWithdrawals) {
            this.props.transactions.depositsWithdrawals.map(transaction => {
                if (transaction.tax === null && (transaction.status !== 3 && transaction.status !== 5)) {
                    let transactionType = 1;
                    
                    if(transaction.type === TYPE_DEPOSIT_CASH || transaction.type === TYPE_DEPOSIT_SAFECHARGE || transaction.type === TYPE_DEPOSIT_VIVA){
                        transactionType = 2;
                    }
                    this.props.onRequestTaxService(transactionType, transaction.amount, transaction.id);
                }
                return true;
            });
        }
    }

    formatCurrency = (value) => {
        value = parseFloat(value);
        return (
            value
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              .replace(',00', '')
          )
    }

    downloadCsv = () => {
        this.setState({
            ...this.state,
            exportDisabled: true,
        });
        this.props.downloadCSV();
    };
    
    render() {
        let transactionList = <CircularProgress className={'Loading'} />;
        let pendingTransactionList = '';
        let allTransactionsList = '';

        if(this.props.transactions.depositsWithdrawals !== null && this.props.transactions.depositsWithdrawals.length > 0){
            pendingTransactionList = this.props.transactions.depositsWithdrawals.map((transaction, i) => {
                if(transaction.status === STATUS_PENDING_APPROVAL && (transaction.type === TYPE_DEPOSIT_CASH || transaction.type === TYPE_DEPOSIT_SAFECHARGE || transaction.type === TYPE_DEPOSIT_VIVA)){
                    return <SingleTransaction transaction={transaction} key={`${transaction.id}_${i}`} />;
                }else{
                    return null;
                }
            });
            allTransactionsList = this.props.transactions.depositsWithdrawals.map((transaction, i) => {
                if(transaction.status !== STATUS_PENDING_APPROVAL){
                    return <SingleTransaction transaction={transaction} key={`${transaction.id}_${i}`} />;
                }
            });
            
            pendingTransactionList = pendingTransactionList.filter(el => (el !== null));
            allTransactionsList = allTransactionsList.filter(el => (el !== null));
            
            if(Array.isArray(pendingTransactionList) && pendingTransactionList.length > 0){
                pendingTransactionList = (
                    <Fragment>
                        <h3 className="TransactionTitle">{this.props.t("Pending")}</h3>
                        {pendingTransactionList}
                    </Fragment>
                );
            }else{
                pendingTransactionList = '';
            }
            if(Array.isArray(allTransactionsList) && allTransactionsList.length > 0){
                allTransactionsList = (
                    <Fragment>
                        <h3 className="TransactionTitle">{this.props.t("All")}</h3>
                        {allTransactionsList}
                    </Fragment>
                );
            }else{
                allTransactionsList = '';
            }

            transactionList = <Fragment>{pendingTransactionList}{allTransactionsList}</Fragment>;
        }else if(this.props.transactions.show){
            transactionList = <h5 className={"NoTransactions"}>{this.props.t("There is no transactions history at this moment")}.</h5>;
        }
        
        return (
            <div className={"DepositAndWithdrawalsScreen"}>
                {transactionList}
                <Button className={'export'} onClick={ this.downloadCsv.bind(this)} disabled={this.state.exportDisabled}>
                    {Icons.get("historyCsvDownload", "historyCsvDownload")} {this.props.t("Download History for last 90 days")}
                </Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        transactions: state.transactions,
        auth: state.authentication,
        withdrawals: state.withdrawals,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestTransactionsHistory: (requestType) => dispatch(storeActions.requestTransactionsHistory(requestType)),
        onRequestTaxService: (taxType, amount, id) => dispatch(storeActions.requestTaxService(taxType, amount, id)),
        downloadCSV: () => dispatch(storeActions.downloadCSV(2))
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DepositAndWithdrawals)));
